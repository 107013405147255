import { useSelector } from "src/store";

const useClientNumber = () => {
  const contacts = useSelector(state => state.lists.contacts);

  const refreshClientNumber = () => {
    const clientNumbers = contacts.map((contact) => contact.client_number).sort((a, b) => {
      return a - b;
    });
    
    let firstMissing = clientNumbers[clientNumbers.length - 1] + 1;
    for(var i = 1; i < clientNumbers.length; i++) {
      if(clientNumbers[i] - clientNumbers[i-1] != 1) {
        firstMissing = (clientNumbers[i - 1] + 1);
        break;
      }
    }

    return firstMissing;    
  }

  const clientNumberExists = (clientNumber: string) => {
    const num = contacts.find((contact) => contact.client_number === parseInt(clientNumber));
    return !!num;
  }

  return {
    refreshClientNumber,
    clientNumberExists
  }
}
export default useClientNumber;