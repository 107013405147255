import { AnyAction, Store } from 'redux';
import { AxiosOptions, useAxios } from 'src/hooks/general/useAxios';
import { addChatMessage } from 'src/slices/chatMessages';
import { setClientReload } from 'src/slices/misc';
import type { RootState } from 'src/store';
import type { AppDispatch } from 'src/store/index';
import type { ChatMessage } from 'src/types/api/chatMessage';
import { TAppContext } from 'src/App';
import { setGeneralGroupStorage } from 'src/slices/general/group';
import { setMenu } from 'src/slices/general/menu';
import { AxiosInstance } from 'axios';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions,
  appContext: TAppContext | null,
  axios: AxiosInstance
) => {
  channel.listen('.chatmessage', (data: any)=>{
    const message = data.data.response as ChatMessage;

    const avatars = store.getState().misc.avatars;

    try {
      if (Notification.permission === "granted") {
        new Notification(message.content, {
          // image: `data:image/jpeg;base64,${avatars[message.sender_id]}`,
          badge: `data:image/jpeg;base64,${avatars[message.sender_id]}`,
          icon: `data:image/jpeg;base64,${avatars[message.sender_id]}`,
        });
      }else{
        Notification.requestPermission();
      }
    } catch(e){}
    dispatch(addChatMessage(message, message.sender_id));
  });
  channel.listen('.reload_client', (data: any) => {
    console.log('reload client');
    dispatch(setClientReload(true));
    window.location.reload();
  });
  channel.listen('.logoff_device', (data: any) => {
    appContext?.setApiToken({
      token: null,
      is_device: false
    });
  });
  channel.listen('.update_used_group_storage', (data: any) => {
    const value = data.data.response as number;
    dispatch(setGeneralGroupStorage(value));
  });
  channel.listen('.update_menu', (data: any) => {
    axios.get('general/menu', axiosOptions.apiConfig)
    .then((response) => {
      dispatch(setMenu(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
  });
  
}