import { MenuItem, StandardTextFieldProps, TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from 'src/i18n';
import { useSelector } from "src/store";

interface Props {
  value: string | null;
  onChange: (language_locale: string) => void;
  textFieldProps?: StandardTextFieldProps;
}

const LanguageSelect = ({
  value,
  onChange,
  textFieldProps
}: Props) => {
  const {t} = useTranslation();
  const languages = useSelector(state => state.general.languages);
  const activeLanguages = useMemo(() => {
    return languages.filter(language => language.active);
  }, [languages]);

  const selectedValue = useMemo(() => {
    const selectedLanguage = activeLanguages.find(language => language.locale === value);
    if(selectedLanguage){
      return selectedLanguage.locale;
    }
    return '';
  }, [activeLanguages, value]);

  if(!languages){
    console.error('no languages were found (permission probably not included!)...');
    return null;
  }

  return (
    <TextField
      label={`${t('lang.language')}`}
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      variant='standard'
      select
      {...textFieldProps}
    >
      <MenuItem value="">
        {/* Translation todo ! */}
        <em>None</em>
      </MenuItem>
      {activeLanguages.map((language) => (
        <MenuItem key={language.id} value={language.locale}>{language.name}</MenuItem>
      ))}
    </TextField>
  )
}
export default LanguageSelect;