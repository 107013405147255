import { useTheme, getContrastRatio, darken } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.75rem',
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 400,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    whiteSpace: 'nowrap',
    position: 'relative'
  },
  textWrapper: {
    // paddingBottom: theme.spacing(0.5),
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    // paddingTop: theme.spacing(0.5),
    padding: `3px 7px`,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  }
}));

interface TagProps {
  children?: React.ReactNode;
  color?: string;
  className?: string;
  handleDelete?: (e: React.MouseEvent<HTMLDivElement>) => void;
  [key: string]: any;
}

const Tag = ({
  color,
  children,
  className,
  removeButton,
  handleDelete,
  ...rest
}: TagProps) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const getMuiContrastText = (background: string) => {
    const contrastThreshold = 3;
    const contrastRatio = getContrastRatio(background, '#fff');

    if(contrastRatio >= contrastThreshold){
      return '#fff';
    }

    return 'rgba(0, 0, 0, 0.87)'; 
  }

  const bgColor = color ? color : theme.palette.primary.main;
  const finalColor = getMuiContrastText(bgColor);

  return (
    <span
      className={clsx(classes.root, className)}
      {...rest}
      style={{ 
        backgroundColor: bgColor,
        color: finalColor,        
        ...rest.style
      }}
    >
      <div className={classes.textWrapper} style={{ display: 'flex' }}>
        {children}
      </div>
      {handleDelete &&
        <div 
          className={classes.textWrapper}
          style={{ backgroundColor: darken(bgColor, 0.2) }}
          onClick={handleDelete}
        >
          <CloseIcon style={{ fontSize: 11, position: 'relative', top: 2 }} />
        </div>
      }
      </span>
  );
};

export default Tag;