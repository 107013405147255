import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Project, Step, Task } from 'src/types/api/project';
import { useAxios } from 'src/hooks/general/useAxios';
import type { AxiosOptions } from 'src/hooks/general/useAxios';
import { AxiosInstance } from 'axios';

type StateType = {
  projects: Project[];
  tasks: Task[];
}
// test data
const initialState: StateType = {
  projects: [],
  tasks: []
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // projects
    setProjects(state: StateType, action: PayloadAction<Project[]>){
      state.projects = action.payload;
    },
    createProject(state: StateType, action: PayloadAction<Project>){
      state.projects.push(action.payload);
    },
    updateProject(state: StateType, action: PayloadAction<Project>){
      state.projects = state.projects.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteProject(state: StateType, action: PayloadAction<Project>){
      state.projects = state.projects.filter((val) => val.id !== action.payload.id);
    },

    // steps
    createProjectStep(state: StateType, action: PayloadAction<Step>){
      const projToUpdate = state.projects.find((proj) => proj.id === action.payload.project_id);
      if(projToUpdate){
        let finishStep = null;
        const newSteps = projToUpdate.steps.map((step) => {
          if(step.is_finish){
            finishStep = step;
            return action.payload;
          } 
          return step;
        })
        if(finishStep !== null){
          newSteps.push(finishStep)
        }
        projToUpdate.steps = newSteps;
      }
    },
    updateProjectStep(state: StateType, action: PayloadAction<Step>){
      const projToUpdate = state.projects.find((proj) => proj.id === action.payload.project_id);
      if(projToUpdate){
        projToUpdate.steps = projToUpdate.steps.map((step) => step.id !== action.payload.id ? step : action.payload);
      }
    },
    deleteProjectStep(state: StateType, action: PayloadAction<Step>){
      const projToUpdate = state.projects.find((proj) => proj.id === action.payload.project_id);
      if(projToUpdate){
        projToUpdate.steps = projToUpdate.steps.filter((step) => step.id !== action.payload.id)
      }
    },
    moveProjectStep(state: StateType, action: PayloadAction<{ 
      project_id: string, 
      step_id: string, 
      destination_index: number 
    }>){
      // sort steps
      const project = state.projects.find((val) => val.id === action.payload.project_id);
      if(project){
        const stepToPush = project.steps.find((step) => step.id === action.payload.step_id);
        if(stepToPush){
          project.steps = project.steps.filter((step) => step.id !== stepToPush.id);
          project.steps.splice(action.payload.destination_index, 0, stepToPush);
          project.steps = project.steps.map((step, index) => ({ ...step, sort_order: index }))
        }
      }
    },

    // tasks
    setTasks(state: StateType, action: PayloadAction<Task[]>){
      state.tasks = action.payload;
    },
    createTask(state: StateType, action: PayloadAction<Task>){
      state.tasks.push(action.payload);
    },
    updateTask(state: StateType, action: PayloadAction<Task>){
      if(!!action.payload.archived_at){
        state.tasks = state.tasks.filter((val) => val.id !== action.payload.id);
      }else{
        state.tasks = state.tasks.map((val) => val.id !== action.payload.id ? val : action.payload);
      }
    },
    deleteTask(state: StateType, action: PayloadAction<Task>){
      state.tasks = state.tasks.filter((val) => val.id !== action.payload.id);
    },

    moveProjectTask(state: StateType, action: PayloadAction<{ 
      task_id: string, 
      destination_step_id: string, 
      destination_index: number 
    }>){
      const task_ref = state.tasks.find((val) => val.id === action.payload.task_id);
      if(task_ref){
        const task_clone = {...task_ref, step_id: action.payload.destination_step_id };

        const cpy = [
          ...state.tasks.filter((val) => val.id !== action.payload.task_id && val.step_id === action.payload.destination_step_id)
          .sort((a,b) => {
            if(a.sort_order > b.sort_order){ return 1; }
            if(a.sort_order < b.sort_order){ return -1; }
            return 0;
          })
        ];
        cpy.splice(action.payload.destination_index, 0, task_clone);
        const result = cpy.map((val, i) => ({ ...val, sort_order: i }));
        state.tasks = state.tasks.map((task) => {
          const resultMatch = result.find((t) => task.id === t.id);
          return !!resultMatch ? resultMatch : task;
        });

      }
    },

    titleSortStepTasks(state: StateType, action: PayloadAction<{ step_id: string, sort: 'title' | 'priority' | 'start_date', direction: 'asc' | 'desc' }>){
      const step_id = action.payload.step_id;
      const sort = action.payload.sort;
      
      const tasksToSort = state.tasks.filter((task) => task.step_id === step_id)

      const direction_value = action.payload.direction === 'asc' ? 1 : -1;
      tasksToSort.sort((a,b) => {
        // check if string | number etc...

        if(a[sort] > b[sort]){ return direction_value; }
        if(a[sort] < b[sort]){ return -direction_value; }
        return 0;
      });

      const sortedTasks = tasksToSort.map((task, index) => ({ ...task, sort_order: index }));

      state.tasks = state.tasks.map((task) => {
        let task_to_return = task;
        sortedTasks.map((task2) => {
          if(task2.id === task.id){
            task_to_return = task2;
          }
        });
        return task_to_return;
      });
    }

  }
})

export const { reducer } = slice;

// projects
export const setProjects = (data: Project[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setProjects(data));
}
export const createProject = (data: Project): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createProject(data));
}
export const updateProject = (data: Project): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateProject(data));
}
export const deleteProject = (data: Project): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteProject(data));
}

// project_steps
export const createProjectStep = (data: Step): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createProjectStep(data));
}
export const updateProjectStep = (data: Step): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateProjectStep(data));
}
export const deleteProjectStep = (data: Step): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteProjectStep(data));
}
export const moveProjectStep = (data: { project_id: string, step_id: string, destination_index: number }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.moveProjectStep(data));
}

//tasks
export const setTasks = (data: Task[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTasks(data));
}
export const createTask = (data: Task): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createTask(data));
}
export const updateTask = (data: Task): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTask(data));
}
export const deleteTask = (data: Task): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteTask(data));
}
export const moveProjectTask = (data: { task_id: string, destination_step_id: string, destination_index: number }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.moveProjectTask(data));
}

// experimental
export const refetchTask = (task_id: string, axiosOptions: AxiosOptions, axios: AxiosInstance): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get<Task>(`tasks/${task_id}`, axiosOptions.apiConfig);
    dispatch(slice.actions.updateTask(response.data));
  }catch(err){
    console.log(err);
  }
}

export const titleSortStepTasks = (data: {step_id: string, sort: 'title' | 'priority' | 'start_date', direction: 'asc' | 'desc' }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.titleSortStepTasks(data));
}

export default slice;