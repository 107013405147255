import { lighten, Typography } from "@mui/material";
import clsx from 'clsx';
import { makeStyles } from "src/theme/makeStyles";
import { Service } from "src/types/api/service";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    // borderTop: disableBorder ? 'none' : `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    transition: 'background-color 0.5s',
    // '&:hover': {
    //   backgroundColor: lighten(theme.palette.background.paper, 0.1),
    // }
    '&:hover': {
      background: theme.custom.backgroundDark
    }
  },
  rootSelected: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  rootNoTopBorder: {
    borderTop: 'none'
  },
  avatar: {
    height: 24,
    width: 24,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12
  }
}));

interface RowProps {
  service: Service;
  onClick: () => void;
  disableBorderTop?: boolean;
  selected?: boolean;
}

const ServiceRow = ({
  service,
  onClick,
  disableBorderTop = false,
  selected = false
}: RowProps) => {
  const { classes, theme } = useStyles();

  return (
    <div
      className={clsx(
        classes.root, 
        selected && classes.rootSelected,
        disableBorderTop && classes.rootNoTopBorder  
      )}
      onClick={onClick}
    >
      <div style={{ marginLeft: theme.spacing(1) }}>
        <Typography
          color="inherit"
          variant="h6"
        >
          {service.title}
        </Typography>
      </div>
    </div>
  )
}
export default ServiceRow;