import { StandardTextFieldProps, TextField } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'src/i18n';
import mergeRefs from "react-merge-refs";
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';
import useAutofocus from '../logic/useAutofocus';

const normalize = (value: string) => value.split(' ').join('').toLowerCase();

const Email = forwardRef<HTMLDivElement, StandardTextFieldProps>((props, forwardedRef) => {
  const { t } = useTranslation();

  const { ref: caretRef, updateCaret} = useCaretPosition();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    updateCaret();
    e.target.value = normalize(e.target.value);
    props.onChange && props.onChange(e);
  }

  const focusRef = useAutofocus(!!props.autoFocus, props.value);

  const combinedRefs = mergeRefs(props.inputRef ? [props.inputRef, caretRef, focusRef] : [caretRef, focusRef]);
  return (
    <TextField
      ref={forwardedRef}
      variant='standard'
      label={`${t('lang.email')}`}
      {...props}
      helperText={props.helperText}
      inputRef={combinedRefs}
      onChange={handleChange}
    />
  )
});

export default Email;