import { AnyAction, Store } from 'redux';
import { useAxios, AxiosOptions } from 'src/hooks/general/useAxios';
import { setMenu } from 'src/slices/general/menu';
import { setGeneralUser } from 'src/slices/general/user';
import { createGroup, deleteGroup, updateGroup } from 'src/slices/lists/groups';
import { setCurrentGroup } from 'src/slices/current';
import { setGeneralGroup } from 'src/slices/general/group';
import type { AppDispatch } from 'src/store/index';
import type { Group, GroupSmall } from 'src/types/api/group';
import type { RootState } from 'src/store';
import { AxiosInstance } from 'axios';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions,
  axios: AxiosInstance
  ) => {
  channel.listen('.create_group', (data: any) => {
    const group = data.data.response as GroupSmall;
    dispatch(createGroup(group));
  });
  channel.listen('.update_group', (data: any) => {//
    const group = data.data.response as GroupSmall;
    const currentGroupId = store.getState().current.group?.contact.id;
    const generalGroupId = store.getState().general.group?.contact.id;

    if (currentGroupId && currentGroupId === group.contact.id) {
      axios.get(`system/groups/${group.contact.id}`, axiosOptions.apiConfig)
        .then((response) => {
          console.log('TODO: current alert');
          // setCurrentAlert({
          //   open: true,
          //   message: t("messages.current_group_updated"),
          //   user: data.data.user
          // });
          dispatch(setCurrentGroup(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (group.contact.id === generalGroupId) {
      // Check wat te doen met deze axios calls
      axios.get(`general/group`, axiosOptions.apiConfig)
        .then((response) => {
          const group = response.data as Group;
          dispatch(setGeneralGroup(group));
        })
        .catch((error) => {
          console.log(error);
        });

      axios.get('general/menu', axiosOptions.apiConfig)
        .then((response) => {
          dispatch(setMenu(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      axios.get('general/user', axiosOptions.apiConfig)
        .then((response) => {
          dispatch(setGeneralUser(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    }
    //if permission ???

    const canReadGroup = store.getState().general.user?.role.permissions.find((perm) => perm.tag === 'read_group');
    if (canReadGroup) {
      dispatch(updateGroup(group));
    }
  });
  channel.listen('.delete_group', (data: any) => {//MOET NOG ALS EIGEN GROEP VERWIJDERD WORDT(Uitloggen?)
    const currentGroupId = store.getState().current.group?.contact.id;
    const group = data.data.response as GroupSmall;
    if (currentGroupId && currentGroupId === group.contact.id) {
      dispatch(setCurrentGroup(null));
    }
    dispatch(deleteGroup(group));
  });


  channel.listen('.update_sms_credits', (data: any) => {
    const generalGroup = store.getState().general.group;
    const newSmsCredits = data.data.response as number;
    if(generalGroup){
      dispatch(setGeneralGroup({ ...generalGroup, sms_credits: newSmsCredits }));
    }
  });

}