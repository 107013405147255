import { Radio, Typography } from "@mui/material";
import clsx from 'clsx';
import { useEffect, useState } from "react";
import DuotoneDialog from "src/components/general/DuotoneDialog";
import { useTranslation } from "src/i18n";
import { useSelector } from "src/store";
import { makeStyles } from "src/theme/makeStyles";
import { RoleSmall } from "src/types/api/role";

const useStyles = makeStyles()(theme => ({
  optionRoot: {
    display: 'flex',
    alignItems: 'center',

    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),

    cursor: 'pointer',
    transition: 'border-color 0.25s',
    marginBottom: theme.spacing(1),
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  },
  optionSelected: {
    borderColor: `${theme.palette.primary.main} !important`
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onChange: (role: RoleSmall) => void;
  rolesToUse?: RoleSmall[] | null;
  initialRoleId?: string | null;
}

const RoleSelectDialog = ({
  open,
  onClose,
  onChange,
  rolesToUse,
  initialRoleId = null
}: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const stateRoles = useSelector(state => state.lists.roles);
  const roles = rolesToUse ? rolesToUse : stateRoles;

  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const handleChange = () => {
    if (!selectedRoleId)
      return;
    const selectedRole = roles.find(role => role.id === selectedRoleId);
    if(selectedRole)
      onChange(selectedRole);
  }

  useEffect(() => {
    if(open){
      setSelectedRoleId(initialRoleId);
    }else{
      setSelectedRoleId(null);
    }
  }, [open, initialRoleId]);

  return (
    <DuotoneDialog
      open={open}
      title={t('lang.select_role')}
      onSubmit={handleChange}
      onClose={onClose}
      submitButtonProps={{ disabled: !selectedRoleId }}
      dialogProps={{
        maxWidth: 'xs'
      }}
    >
      <>
        {roles.map((role, index) =>
          <div
            key={index}
            onClick={() => setSelectedRoleId(role.id)}
            className={clsx(
              classes.optionRoot,
              role.id === selectedRoleId && classes.optionSelected
            )}
          >
            <Radio checked={role.id === selectedRoleId} />
            <div>
              <Typography>{role.name}</Typography>
              <Typography variant="body2" color='GrayText'>{role.description}</Typography>
            </div>
          </div>
        )}
      </>
    </DuotoneDialog>
  )
}
export default RoleSelectDialog;