const getBicFromIban = (iban: string) => {
  if(iban.length > 6){
    let bic = iban.substring(4, 8) + iban.substring(0, 2);
    let replace = new Array("ABNANL","AEGONL","AKBKNL","ANDLNL","AOLBNL","ARBNNL","ARSNNL","ARTENL","ASRBNL","ATBANL","BBRUNL","BCITNL","BGCCNL","BKMGNL","BNGHNL","BNPANL","BOFANL","BOFSNL","BOTKNL","BOUWNL","CITCNL","CITINL","COBANL","DEUTNL","DHBNNL","DLBKNL","DNIBNL","DSSBNL","FBHLNL","FLORNL","FRBKNL","FRGHNL","FTSBNL","FVLBNL","GILLNL","HANDNL","HSBCNL","INGBNL","INKBNL","INSINL","INSINL","ISBKNL","KABANL","KASANL","KNABNL","KOEXNL","KREDNL","LOCYNL","LOYDGB","LOYDNL","LPLNNL","MHCBNL","NWABNL","RABONL","RBOSNL","RBRBNL","RGRBNL","SNSBNL","SOGENL","STALNL","TEBUNL","TRIONL","UBSWNL","UGBINL","VOWANL","VPVGNL");
    let by = new Array("ABNANL2A","AEGONL2U","AKBKNL2R","ANDLNL2A","AOLBNL2A","ARBNNL22","ARSNNL21","ARTENL2A","ASRBNL2R","ATBANL2A","BBRUNL2X","BCITNL2A","BGCCNL2A","BKMGNL2A","BNGHNL2G","BNPANL2A","BOFANLNX","BOFSNL21002","BOTKNL2X","BOUWNL22","CITCNL2A","CITINL2X","COBANL2X","DEUTNL2N","DHBNNL2R","DLBKNL2A","DNIBNL2G","DSSBNL22","FBHLNL2A","FLORNL2A","FRBKNL2L","FRGHNL21","FTSBNL2R","FVLBNL22","GILLNL2A","HANDNL2A","HSBCNL2A","INGBNL2A","INKBNL21","INSINL2A","INSINL2X","ISBKNL2A","KABANL2A","KASANL2A","KNABNL2H","KOEXNL2A","KREDNL2X","LOCYNL2AXXX","LOYDGB2L","LOYDNL2A","LPLNNL2A","MHCBNL2A","NWABNL2G","RABONL2U","RBOSNL2A","RBRBNL21","RGRBNL2R","SNSBNL2A","SOGENL2A","STALNL2G","TEBUNL2A","TRIONL2U","UBSWNL2A","UGBINL2A","VOWANL21","VPVGNL22");
    
    for (let i=0; i<replace.length; i++) {
      if(bic === replace[i]){
        return bic.replace(replace[i], by[i]);
      }
    }
    return null;
  }
  return null;
}
export default getBicFromIban;