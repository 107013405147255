import { useEffect, useMemo } from 'react'; 
import { useSelector } from 'src/store';
import { useTimerContext } from 'src/contexts/TimerContext';
import type { TimeTracker } from 'src/types/api/timetracker';
import dayjs from 'dayjs';

interface useActiveTimerReturn { 
  myActiveTimeTracker: TimeTracker | null;
  otherActiveTimeTrackers: TimeTracker[]
}

export function useActiveTimer() : useActiveTimerReturn {
  const myId = useSelector(state => state.general.user?.id);
  const timeTrackers = useSelector(state => state.lists.time_trackers);

  const activeTimeTracker = useMemo(() => {
    let myActiveTimeTracker: TimeTracker | null = null;
    let otherActiveTimeTrackers: TimeTracker[] = [];

    timeTrackers.map((timeTracker) => {
      if(timeTracker.stop == null){
        if(timeTracker.user.id === myId){
          myActiveTimeTracker = timeTracker;
        }else{
          otherActiveTimeTrackers.push(timeTracker);
        }
      }
    });
    return {
      myActiveTimeTracker,
      otherActiveTimeTrackers
    }
  }, [timeTrackers, myId]);
  return activeTimeTracker;
}

export function useTimeTracker(){
  const timerContext = useTimerContext();

  const {
    myActiveTimeTracker: activeTimeTracker,
    otherActiveTimeTrackers: otherActiveTimeTrackers //TODO
  } = useActiveTimer();

  useEffect(() => {
    if(activeTimeTracker || otherActiveTimeTrackers){
      if(activeTimeTracker && activeTimeTracker.id){
        timerContext.setValues({
          [activeTimeTracker.id]: dayjs().unix() - activeTimeTracker.start - activeTimeTracker.idle //calc time tracker thing here
        });
      }
      const interval = setInterval(() => {
        let finalObj: {[key: string]: number} = {};
        otherActiveTimeTrackers.map((tt) => {
          finalObj[tt.id] = dayjs().unix() - tt.start - tt.idle;
        });
        if(activeTimeTracker){
          finalObj[activeTimeTracker.id] = dayjs().unix() - activeTimeTracker.start - activeTimeTracker.idle;
        }
        timerContext.setValues(finalObj);
      }, 1000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [activeTimeTracker, otherActiveTimeTrackers]); //selected_user ??
}