import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Note } from 'src/types/api/note';

const initialState: Note[] = [];

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNotes(state: Note[], action: PayloadAction<Note[]>){
      return action.payload;
    },
    createNote(state: Note[], action: PayloadAction<Note>){
      state.push(action.payload);
    },
    updateNote(state: Note[], action: PayloadAction<Note>){
      return [
        ...state.filter((val) => val.id !== action.payload.id),
        action.payload
      ]
    },
    deleteNote(state: Note[], action: PayloadAction<Note>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setNotes = (data: Note[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNotes(data));
}

export const createNote = (data: Note): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createNote(data));
}

export const updateNote = (data: Note): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateNote(data));
}

export const deleteNote = (data: Note): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteNote(data));
}

export default slice;