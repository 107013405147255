import { Box, Collapse, IconButton, IconButtonProps, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton size="small" {...other} />;
})(({ theme, expand }) => ({
  color: theme.palette.primary.main,
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  })
}));

interface Props {
  title: string;
  icon: React.ReactElement;
  children: React.ReactChild;
  mt?: number;
}

const CollapseRow = ({
  title,
  icon,
  children,
  mt = 6
}: Props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  return (
    <>
      <Box mt={mt} style={{ display: 'flex', alignItems: 'center', borderBottom: `1px solid ${theme.palette.divider}` }}>
        {icon}
        <Typography
          color="textPrimary"
          component="h4"
          style={{ 
            fontWeight: 600,
            lineHeight: 1,
            marginLeft: theme.spacing(1)
          }}
          variant="overline"
        >
          {title}
        </Typography>

        <div style={{ marginLeft: 'auto' }}>
          <ExpandMore
            expand={open}
            onClick={() => setOpen(prev => !prev)}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>
      </Box>
      <Collapse
        in={open}
      >
        {children}
      </Collapse>
    </>
  )
}
export default CollapseRow;