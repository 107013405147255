import { alpha, Checkbox, Typography } from "@mui/material";
import { UseFieldArrayReturn } from "react-hook-form";
import { useTranslation } from 'src/i18n';
import DotsMenu from 'src/components/general/DotsMenu';
import { makeStyles } from 'src/theme/makeStyles';
import type { FormValues } from '../../types';
import UpdateSubtaskTitleField from './UpdateSubtaskTitleField';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableStateSnapshot } from "react-beautiful-dnd";
import clsx from 'clsx';

const useStyles = makeStyles()(theme => ({
  subtaskRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 8,
    position: 'relative',
    '& .subtask-iconbuttons': {
      transition: 'opacity 0.15s',
      opacity: 0,
      pointerEvents: 'none',
      marginLeft: 'auto',
      position: 'absolute',
      right: 8
    },
    '&:hover': {
      backgroundColor: alpha('#000', 0.15),
      '& .subtask-iconbuttons': {
        opacity: 1,
        pointerEvents: 'auto',
        position: 'static',
        display: 'flex'
      }
    }
  },
  subtaskRowDragging: {
    backgroundColor: alpha('#000', 0.15)
  }
}));

interface Props {
  subtaskFieldarray: UseFieldArrayReturn<FormValues, "subtasks", "id">;
  editingSubtask: string | null;
  setEditingSubtask: (value: string | null) => void;
  disableAllFields: boolean;
  createSubTask: () => void;
}

const RenderRows = ({
  subtaskFieldarray,
  editingSubtask,
  setEditingSubtask,
  disableAllFields,
  createSubTask
}: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    fields: subtaskFields,
    remove: removeSubtask,
    update: updateSubtask,
    move: moveSubtask
  } = subtaskFieldarray;

  const handleSubtaskCheck = (index: number) => {
    updateSubtask(index, {
      ...subtaskFields[index],
      finished: !subtaskFields[index].finished
    });
  }

  const handleDeleteSubtask = (index: number) => {
    removeSubtask(index);
  }

  const handleUpdateSubtaskClick = (index: number) => {
    const field = subtaskFields[index];
    if (field) {
      setEditingSubtask(field.id);
    }
  }

  const handleUpdateSubtask = (id: string, value: string, newline = false) => {
    const index = subtaskFields.findIndex((val) => val.id === id);
    if (index !== null) {
      if (value) {
        updateSubtask(index, { ...subtaskFields[index], title: value });
      } else {
        removeSubtask(index);
      }
    }

    if(newline){
      createSubTask();
      return;
    }
    setEditingSubtask(null);
  }

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if(source && destination){
      moveSubtask(source.index, destination.index);
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {subtaskFields.map((subtask, index) => (
              <Draggable
                key={subtask.id}
                draggableId={subtask.id}
                index={index}
                isDragDisabled={disableAllFields}
              >
                {(provided, snapshot: DraggableStateSnapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div
                      className={clsx(classes.subtaskRow, snapshot.isDragging && classes.subtaskRowDragging)}
                      {...provided.dragHandleProps}
                    >
                      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        <Checkbox
                          onChange={() => handleSubtaskCheck(index)}
                          color="primary"
                          checked={subtask.finished}
                          disabled={disableAllFields}
                        />
                        {(editingSubtask !== subtask.id) && (editingSubtask !== subtask?.tmp_id) ?
                          <Typography
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              paddingRight: 10,
                              textDecoration: subtask.finished ? 'line-through' : 'none'
                            }}
                          >{subtask.title}</Typography>
                          :
                          <UpdateSubtaskTitleField 
                            title={subtask.title} 
                            onFinish={(value) => handleUpdateSubtask(subtask.id, value)}
                            onNewLine={(value) => handleUpdateSubtask(subtask.id, value, true)}
                          />
                        }
                        {editingSubtask !== subtask.id &&
                          <span className='subtask-iconbuttons'>
                            <DotsMenu
                              disabled={disableAllFields}
                              menuItems={[
                                {
                                  content: `${t('lang.update')}`,
                                  icon: 'update',
                                  onClick: () => handleUpdateSubtaskClick(index)
                                },
                                {
                                  content: `${t('lang.delete')}`,
                                  icon: 'delete',
                                  onClick: () => handleDeleteSubtask(index)
                                }
                              ]}
                            />
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default RenderRows;