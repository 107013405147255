import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { EntitySetting } from 'src/types/api/setting';

const initialState: EntitySetting[] = [];

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(state: EntitySetting[], action: PayloadAction<EntitySetting[]>){
      return action.payload;
    },
    createSetting(state: EntitySetting[], action: PayloadAction<EntitySetting>){
      state.push(action.payload);
    },
    updateSetting(state: EntitySetting[], action: PayloadAction<EntitySetting>){
      return state.map(setting => setting.id !== action.payload.id ? setting : action.payload)
    },
    deleteSetting(state: EntitySetting[], action: PayloadAction<EntitySetting>){
      return state.filter((setting) => setting.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setSettings = (data: EntitySetting[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSettings(data));
}
export const createSetting = (data: EntitySetting): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createSetting(data));
}
export const updateSetting = (data: EntitySetting): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSetting(data));
}
export const deleteSetting = (data: EntitySetting): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteSetting(data));
}

export default slice;