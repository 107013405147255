import { StandardTextFieldProps, TextField } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import mergeRefs from "react-merge-refs";
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';
import useAutofocus from './logic/useAutofocus';

const normalize = (value: string) => value.toUpperCase();

const UpperCase = forwardRef<HTMLDivElement, StandardTextFieldProps>((props, forwardedRef) => {
  const { ref: caretRef, updateCaret} = useCaretPosition();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    updateCaret();
    e.target.value = normalize(e.target.value);
    props.onChange && props.onChange(e);
  }

  const focusRef = useAutofocus(!!props.autoFocus, props.value);

  const combinedRefs = mergeRefs(props.inputRef ? [props.inputRef, caretRef, focusRef] : [caretRef, focusRef]);
  return (
    <TextField
      ref={forwardedRef}
      variant='standard'
      {...props}
      inputRef={combinedRefs}
      onChange={handleChange}
    />
  )
});

export default UpperCase;