import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Language } from 'src/types/api/language';

const initialState: Language[] = [];

const slice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguages(state: Language[], action: PayloadAction<Language[]>){
      return action.payload;
    },
    createLanguage(state: Language[], action: PayloadAction<Language>){
      state.push(action.payload);
    },
    updateLanguage(state: Language[], action: PayloadAction<Language>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteLanguage(state: Language[], action: PayloadAction<Language>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setLanguages = (data: Language[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLanguages(data));
}

export const createLanguage = (data: Language): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createLanguage(data));
}

export const updateLanguage = (data: Language): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateLanguage(data));
}

export const deleteLanguage = (data: Language): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteLanguage(data));
}

export default slice;