import { useTranslation } from 'src/i18n';
import { ContactSmall } from "src/types/api/contact";
import { OriginalLabel } from "../general/Labels";
import { TFunction } from "i18next";

const getContactEntityFromTag = (
  contactTag: string,
  t: TFunction | ((value: string) => string) = (val) => val
): string => {

  switch(contactTag){
    case 'user': { return t('lang.user') }
    case 'person': { return t('lang.person') }
    case 'company': { return t('lang.company') }
    case 'group': { return t('lang.group') }
    default: { return t('lang.contact')}
  }
}

interface Props {
  contact: ContactSmall;
}

const TypeLabel = ({
  contact
}: Props) => {
  const {t} = useTranslation();
  const entity = getContactEntityFromTag(contact.tag, t);

  return (
    <OriginalLabel
      color={
        contact.tag === 'user' ? 'info' : 
        contact.tag === 'person' ? 'success' : 
        contact.tag === 'company' ? 'warning' : 'error'
      }
    >
      {entity}
    </OriginalLabel>
  );
}

export { getContactEntityFromTag };

export default TypeLabel;