import { createPublicChannel, updatePublicChannel } from 'src/slices/lists/publicwidgets';
import type { AppDispatch } from 'src/store/index';
import type { PublicChannel } from 'src/types/api/publicwidget';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_public_channel', (data: any) => {//
    const channel = data.data.response as PublicChannel;
    dispatch(createPublicChannel(channel));
  });
  channel.listen('.update_public_channel', (data: any) => {//
    const channel = data.data.response as PublicChannel;
    dispatch(updatePublicChannel(channel));
  });
}