import { useTheme, Dialog, DialogContent, TextField, Typography, Box, IconButton } from '@mui/material';
import { makeStyles } from  'src/theme/makeStyles';
import { BLOCK_SIZE, GROW_SIZE } from './constants';
import { useState } from 'react';
import isValidColor from 'src/utils/general/isValidHex';
import clsx from 'clsx';
import { useTranslation } from 'src/i18n';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute', 
    top: 25,
    left: 0,
    zIndex: 998,
  },
  colorBlockWrapper: {
    position: 'relative',
    width: BLOCK_SIZE, 
    height: BLOCK_SIZE,
    margin: GROW_SIZE,
    cursor: 'pointer'
  },
  colorBlock: {
    position: 'absolute',
    width: BLOCK_SIZE, 
    height: BLOCK_SIZE,
    left: 0,
    top: 0,
    borderRadius: 5,
    '&:hover': {
      width: BLOCK_SIZE + GROW_SIZE,
      height: BLOCK_SIZE + GROW_SIZE,
      left: -(GROW_SIZE / 2),
      top: -(GROW_SIZE / 2),
      zIndex: 999
    }
  },
  colorBlockDisabled: {
    cursor: 'not-allowed',
    '&:hover': {
      width: BLOCK_SIZE, 
      height: BLOCK_SIZE,
      left: 0,
      top: 0,
    }
  },
  paper: {
    padding: 10
  }
}));

export interface ColorPickerProps {
  onClick: (color: string) => void;
  open: boolean;
  handleClose: () => void;
  [key: string]: any;
}

const ColorPicker = ({
  onClick,
  open,
  handleClose,
  ...rest
}: ColorPickerProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();

  const colors = [
    ['#f3c500', '#f59d05', '#e88007', '#d55400', '#ea4b35', '#c33825'],
    ['#fe599b', '#f44389', '#9c54b7', '#913eb0', '#468efd', '#336ace'],
    ['#2e96dd', '#227fbc', '#00be9c', '#0b9f89', '#19cd6c', '#1caf5e'],
    [theme.palette.primary.main, '#808080']
  ];

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, color: string) => {
    e.stopPropagation();
    onClick(color);
  }
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <DialogContent>
        <Box mb={1.5}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3">
              {t('lang.choose_color')}
            </Typography>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        
        </Box>
        {colors.map((colorRow, index) => (
          <div 
            key={index} 
            style={{ display: 'flex' }}
          >
            {colorRow.map((color, index) => (
              <div 
                key={index}
                className={classes.colorBlockWrapper}
                onClick={(e) => handleClick(e, color)}
              >
                <div
                  className={classes.colorBlock}
                  style={{ background: color }}
                />
              </div>
            ))}
            {index + 1 === colors.length && ( <CustomColor onClick={onClick} /> )}
          </div>
        ))}

      </DialogContent>
    </Dialog>
  )
}

interface CustomColorProps {
  onClick: (color: string) => void;
}

const CustomColor = ({
  onClick
}: CustomColorProps) => {
  const { classes } = useStyles();

  const [value, setValue] = useState('000000');

  const handleClick = () => {
    if(!isValidColor(`#${value}`)){
      return;
    }
    onClick(`#${value}`);
  }

  return (
    <>
      <div 
        className={classes.colorBlockWrapper}
        onClick={handleClick}
      >
        <div
          className={clsx(classes.colorBlock, !isValidColor(`#${value}`) && classes.colorBlockDisabled)}
          style={{ background: isValidColor(`#${value}`) ? `#${value}` : '#000' }}
        />
      </div>
      <TextField
        variant='standard'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ startAdornment: '#' }}
        style={{ 
          maxWidth: (BLOCK_SIZE * 3) + (GROW_SIZE * 3 * 2),
          marginTop: 5,
          paddingLeft: 5
        }}
        inputProps={{ maxLength: '6' }}
        // fullWidth
      />
    </>
  )

}

export default ColorPicker;