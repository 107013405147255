import { Avatar, Typography } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "src/theme/makeStyles";
import getInitials from "src/utils/general/getInitials";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Tags from 'src/components/general/Tags';
import ImagePicker from "../ImagePicker";

const useStyles = makeStyles<{ avatarHovering: boolean; }>()((theme: any, { avatarHovering }) => ({
  listItemRoot: {
    padding: 0,
    color: theme.palette.text.primary
  },
  avatar: {
    height: 44,
    width: 44,
    // marginRight: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  deleteIconWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 44,
    width: 44,
    zIndex: 2,
    opacity: avatarHovering ? 1 : 0,
    transition: 'opacity 0.25s',
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}));

interface Props {
  name: string;
  enableAvatarUpdate?: boolean;
  avatarDataUrl?: string;
  secondaryProperties: (string | undefined)[];
  tags?: {
    ids: string[];
    category: string;
  };
  onAvatarChange?: (dataUrl: string | null) => void;
  rightComponent?: React.ReactNode;
}

const BaseAvatarEntityDetails = ({
  name,
  enableAvatarUpdate = false,
  avatarDataUrl,
  secondaryProperties,
  tags,
  onAvatarChange,
  rightComponent
}: Props) => {
  const [avatarHovering, setAvatarHovering] = useState(false);
  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);
  const [avatarPickerInitial, setAvatarPickerInitial] = useState<string | null>(null);

  const { classes, theme } = useStyles({ avatarHovering });

  const handleAvatarChange = (dataUrl: string | null) => {
    onAvatarChange && onAvatarChange(dataUrl);
    setAvatarPickerOpen(false);
    setAvatarPickerInitial(null);
  }

  const handleExistingAvatarChange = () => {
    if (avatarDataUrl) {
      setAvatarPickerInitial(avatarDataUrl);
      setAvatarPickerOpen(true);
    }
  }

  const handleImagePickerClose = () => {
    setAvatarPickerInitial(null);
    setAvatarPickerOpen(false);
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {enableAvatarUpdate ? (
          <div
            style={{
              position: 'relative',
              borderRadius: '50%',
              height: 44,
              width: 44
            }}
            onMouseEnter={() => setAvatarHovering(true)}
            onMouseLeave={() => setAvatarHovering(false)}
          >
            <Avatar
              className={classes.avatar}
              src={avatarDataUrl ? `${avatarDataUrl}` : avatarDataUrl}
            >
              {getInitials(name)}
            </Avatar>
            {!!avatarDataUrl ? (
              <div
                className={classes.deleteIconWrapper}
                // onClick={() => onAvatarDelete && onAvatarDelete()}
                onClick={handleExistingAvatarChange}
              >
                <DeleteOutlineIcon style={{ color: '#fff' }} />
              </div>
            ) : (
              <div
                className={classes.deleteIconWrapper}
                onClick={() => setAvatarPickerOpen(true)}
              >
                <AddIcon style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }} />
              </div>
            )}
          </div>
        ) : (
          <Avatar
            className={classes.avatar}
            src={avatarDataUrl ? `${avatarDataUrl}` : avatarDataUrl}
          >
            {getInitials(name)}
          </Avatar>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: theme.spacing(1) }}>
          <Typography
            color="inherit"
            variant="h6"
          // textOverflow='ellipsis'
          // noWrap
          >
            {name}
          </Typography>
          {secondaryProperties.map((secondaryProperty, index) => {
            if (!secondaryProperty) {
              return null;
            }
            return (
              <Typography
                key={index}
                variant="body2"
                color="textSecondary"
              // textOverflow='ellipsis'
              // noWrap
              >
                {secondaryProperty}
              </Typography>
            )
          })}
        </div>
        {!!rightComponent && (
          <div style={{ marginLeft: 'auto' }}>
            {rightComponent}
          </div>
        )}
      </div>
      {tags && (
        <div style={{ marginTop: theme.spacing(1.5) }}>
          <Tags
            tagIds={tags.ids}
            category={tags.category}
            disableRemove
            disableColorPicker
            hideCreate
          />
        </div>
      )}
      {enableAvatarUpdate && (
        <ImagePicker
          open={avatarPickerOpen}
          onChange={handleAvatarChange}
          onClose={handleImagePickerClose}
          aspectRatio={1}
          initialImageSrc={avatarPickerInitial ? avatarPickerInitial : undefined}
        />
      )}
    </div>
  )
}
export default BaseAvatarEntityDetails;