// // import { useRef, useContext, useEffect, useState } from 'react';
// // import { BroadcastContext } from 'src/custom/DataListener';
// // import {
// //   Dialog,
// //   DialogTitle,
// //   Typography,
// //   DialogContent,
// //   DialogContentText
// // } from '@mui/material';
// // import { useTranslation } from 'src/i18n';
// // import { useIsOnline } from 'src/hooks/general/useIsOnline';
// // import { useStore, useDispatch, useSelector } from 'src/store';
// // import { handleCoreCall } from 'src/custom/DataLoader';
// // import { useAxiosOptions, useAxios } from 'src/hooks/general/useAxios';
// // import { useLocation, useNavigate } from 'react-router';
// // import { currentActionsMap } from 'src/slices/current';
// // import { AppContext } from 'src/App';

// // export default () => {
// //   const axios = useAxios();
// //   const broadcastContext = useContext(BroadcastContext);
// //   const appContext = useContext(AppContext);
// //   const navigate = useNavigate();

// //   const {t} = useTranslation();
// //   const dispatch = useDispatch();
// //   const isOnline = useIsOnline();

// //   const location = useLocation();

// //   const axiosOptions = useAxiosOptions();
// //   const store = useStore();

// //   const [disconnectedMsg, setDisconnectedMsg] = useState<string | null>(null);
  
// //   const initialized = useRef(false);

// //   const [switchLocation, setSwitchLocation] = useState(false);
// //   useEffect(() => {
// //     if(broadcastContext !== null){
// //       // @ts-ignore
// //       if(broadcastContext?.presenceChannel){
// //         setTimeout(() => {
// //           // @ts-ignore
// //           broadcastContext?.presenceChannel.setLocation(location.pathname);
// //         }, 500);
// //       }
// //     }
// //   }, [switchLocation]);

// //   useEffect(() => {
// //     // @ts-ignore
// //     if(broadcastContext?.pusherJs && !initialized.current){
// //       // @ts-ignore
// //       broadcastContext?.pusherJs.connector.socket.on('reconnect', () => {
// //         setDisconnectedMsg('messages.reconnecting');
// //         const corePromise = handleCoreCall(dispatch, appContext, axios, navigate);
// //         setSwitchLocation((prevVal) => !prevVal);
// //         let current = store.getState().current;
// //         let promiseArr: Promise<any>[] = [corePromise];
// //         let keys: any = [];
// //         Object.keys(current).map((key) => {
// //           // @ts-ignore
// //           if(current[key]){
// //             // @ts-ignore
// //             let promise = axios.get(key+'s/'+current[key].id, axiosOptions.apiConfig);
// //             promiseArr.push(promise);
// //             keys.push(key);
// //           }
// //         });
// //         console.log(keys);
// //         Promise.all(promiseArr).then((response) => {
// //           response.map((data, index) => {
// //             if(index !== 0){
// //               // @ts-ignore
// //               if(currentActionsMap['SET_CURRENT_'+keys[index - 1].toUpperCase()]){
// //                 // @ts-ignore
// //                 dispatch(currentActionsMap['SET_CURRENT_'+keys[index - 1].toUpperCase()](data.data));
// //               }
// //             }
// //           });
// //           setDisconnectedMsg(null);
// //         })
// //         .catch((error) => {
// //           console.log('Error reloading data');
// //         });
// //       });
// //       initialized.current = true;
// //     }else{
// //       if(initialized.current && !isOnline){
// //         setDisconnectedMsg("messages.disconnected");
// //       }
// //     }
// //   }, [broadcastContext, isOnline]);

// //   return (
// //     <Dialog
// //       open={disconnectedMsg !== null}
// //     >
// //       <DialogTitle>
// //         {disconnectedMsg !== null && t("lang.you_are_offline")}
// //       </DialogTitle>
// //       <DialogContent>
// //         <DialogContentText>
// //           {disconnectedMsg !== null && t(disconnectedMsg)}
// //         </DialogContentText>
// //       </DialogContent>
// //     </Dialog>
// //   );
// // }

// import {
//   Dialog, DialogContent,
//   DialogContentText, DialogTitle
// } from '@mui/material';
// import { useContext, useEffect, useState } from 'react';
// import { useTranslation } from 'src/i18n';
// import { BroadcastContext } from 'src/custom/DataListener';
// import { useIsOnline } from 'src/hooks/general/useIsOnline';

// const OfflineOverlay = () => {
//   const broadcastContext = useContext(BroadcastContext);  
//   const isOnline = useIsOnline();
//   const {t} = useTranslation();

//   const [disconnectedMsg, setDisconnectedMsg] = useState<string | null>(null);
//   const [initialized, setInitialized] = useState(false);

//   useEffect(() => {
//     if(broadcastContext?.pusherJs && !initialized){
//       broadcastContext.pusherJs.bind_global((val: any) => {
//         console.log(val);
//       })

//       // pusher disconnect
//       broadcastContext.pusherJs.bind('pusher:error', () => {
//         setDisconnectedMsg('messages.socket_server_connection_error');
//       });

//       // on reconnect
//       broadcastContext.pusherJs.bind('pusher:pong', () => {
//         setDisconnectedMsg(null);
//       });

//       setInitialized(true);
//     }else{
//       if(initialized && !isOnline){
//         setDisconnectedMsg("messages.disconnected");
//       }
//     }
//   }, [broadcastContext, isOnline]);

//   return (
//     <Dialog
//       open={disconnectedMsg !== null}
//     >
//       <DialogTitle>
//         {disconnectedMsg !== null && t("lang.you_are_offline")}
//       </DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           {disconnectedMsg !== null && t(disconnectedMsg)}
//         </DialogContentText>
//       </DialogContent>
//     </Dialog>
//   );
// }
// export default OfflineOverlay;

const OfflineOverlay = () => {
  return null;
}
export default OfflineOverlay;