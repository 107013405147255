import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Holiday } from 'src/types/api/holiday';

const initialState: Holiday[] = [];

const slice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    setHolidays(state: Holiday[], action: PayloadAction<Holiday[]>){
      return action.payload;
    },
    createHoliday(state: Holiday[], action: PayloadAction<Holiday>){
      state.push(action.payload);
    },
    updateHoliday(state: Holiday[], action: PayloadAction<Holiday>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteHoliday(state: Holiday[], action: PayloadAction<Holiday>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setHolidays = (data: Holiday[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setHolidays(data));
}

export const createHoliday = (data: Holiday): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createHoliday(data));
}

export const updateHoliday = (data: Holiday): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateHoliday(data));
}

export const deleteHoliday = (data: Holiday): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteHoliday(data));
}

export default slice;