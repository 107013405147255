import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedView as CalendarSupportedView } from 'src/pages/main/EventsCalendar/types';
import { AppThunk } from 'src/store';

export interface DefaultFilter {
  search: string;
  sort: string;
  filters: { [name: string]: string; }
}

export type FiltersProject = {
  selectedUserIds: string[];
  searchValue: string;
  show_finished: boolean;
  view: 'list' | 'board';
}

export const getDefaultValuesProjectFilters = (is_default: boolean): FiltersProject => ({
  selectedUserIds: [],
  searchValue: '',
  show_finished: false,
  view: is_default ? 'list' : 'board'
});
type FiltersContacts = {
  search: string;
  entity: 'person' | 'user' | 'company' | 'group' | null;
}
type FiltersDevices = { search: string; }
type FiltersPresentations = { search: string; }
type FiltersRoles = { 
  search: string; 
  type: null | 'user' | 'customer';
}
type FiltersUsers = {
  search: string;
  type: null | 'user' | 'customer' | 'education';
}
export type FiltersRole = { search: string; }
type FiltersProjects = { 
  search: string; 
  view: 'list' | 'board';
}
type FiltersTemplates = { search: string; }
type FiltersGroups = { search: string; }
type FiltersPermissions = { 
  search: string; 
  action: 'read' | 'create' | 'update' | 'delete' | null;
}
type FiltersPermissionCategories = { search: string; }
type FiltersLanguages = { search: string; }
type FiltersProducts = { search: string; }
type FiltersServices = { search: string; }
type FiltersProductCategories = { search: string; }
type FiltersCalendar = {
  selectedUserIds: string[];
  view: CalendarSupportedView | null;
}
type FiltersSettings = { 
  search: string; 
  is_user: boolean;
  is_group: boolean;
  is_widget: boolean;
  is_contact: boolean;
}

type TFiltersState = {
  project: { [project_id: string]: FiltersProject; };
  contacts: FiltersContacts;
  devices: FiltersDevices;
  presentations: FiltersPresentations;
  roles: FiltersRoles;
  role: { [role_id: string]: FiltersRole; };
  users: FiltersUsers;
  projects: FiltersProjects;
  templates: FiltersTemplates;
  groups: FiltersGroups;
  permissions: FiltersPermissions;
  permission_categories: FiltersPermissionCategories;
  languages: FiltersLanguages;
  products: FiltersProducts;
  services: FiltersServices;
  product_categories: FiltersProductCategories;
  calendar: FiltersCalendar;
  settings: FiltersSettings;
  invoices: DefaultFilter; // If changes extend defaultFilter!
}

const initialState: TFiltersState = {
  contacts: {
    search: '',
    entity: null
  },
  // products: {
  //   category: 'all',
  //   show_in_stock: false,
  //   show_shippable: false
  // },
  project: {},
  devices: { search: '' },
  presentations: { search: '' },
  roles: { search: '', type: null },
  role: {},
  users: { search: '', type: null },
  projects: { search: '', view: 'board' },
  templates: { search: '' },
  groups: { search: '' },
  permissions: { search: '', action: null },
  permission_categories: { search: '' },
  languages: { search: '' },
  products: { search: '' },
  services: { search: '' },
  product_categories: { search: '' },
  calendar: { 
    selectedUserIds: [],
    view: null // TODO: isMobile ? timeGridDay : timeGridWeek
  },
  settings: { search: '', is_contact: false, is_user: false, is_group: false, is_widget: false },
  invoices: { search: '', sort: '-title', filters: {} }
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFiltersProject(state: TFiltersState, action: PayloadAction<{ project_id: string; data: Partial<FiltersProject>; is_default: boolean; }>){
      const { project_id, data, is_default } = action.payload;
      if(typeof(state.project[project_id]) === 'undefined'){
        state.project[project_id] = getDefaultValuesProjectFilters(is_default);
      }
      state.project[project_id] = {
        ...state.project[project_id],
        ...data
      };
    },
    updateFiltersContacts(state: TFiltersState, action: PayloadAction<Partial<FiltersContacts>>){
      state.contacts = { ...state.contacts, ...action.payload };
    },
    updateFiltersDevices(state: TFiltersState, action: PayloadAction<Partial<FiltersDevices>>){
      state.devices = { ...state.devices, ...action.payload };
    },
    updateFiltersPresentations(state: TFiltersState, action: PayloadAction<Partial<FiltersPresentations>>){
      state.presentations = { ...state.presentations, ...action.payload };
    },
    updateFiltersRoles(state: TFiltersState, action: PayloadAction<Partial<FiltersRoles>>){
      state.roles = { ...state.roles, ...action.payload };
    },
    updateFiltersRole(state: TFiltersState, action: PayloadAction<{ role_id: string; data: Partial<FiltersRole>; }>){
      // state.roles = { ...state.roles, ...action.payload };
      const { role_id, data } = action.payload;
      if(typeof(state.role[role_id]) === 'undefined'){
        state.role[role_id] = { search: '' };
      }
      state.role[role_id] = { ...state.role[role_id], ...data };
    },
    updateFiltersUsers(state: TFiltersState, action: PayloadAction<Partial<FiltersUsers>>){
      state.users = { ...state.users, ...action.payload };
    },
    updateFiltersProjects(state: TFiltersState, action: PayloadAction<Partial<FiltersProjects>>){
      state.projects = { ...state.projects, ...action.payload };
    },
    updateFiltersTemplates(state: TFiltersState, action: PayloadAction<Partial<FiltersTemplates>>){
      state.templates = { ...state.templates, ...action.payload };
    },
    updateFiltersGroups(state: TFiltersState, action: PayloadAction<Partial<FiltersGroups>>){
      state.groups = { ...state.groups, ...action.payload };
    },
    updateFiltersPermissions(state: TFiltersState, action: PayloadAction<Partial<FiltersPermissions>>){
      state.permissions = { ...state.permissions, ...action.payload };
    },
    updateFiltersPermissionCategories(state: TFiltersState, action: PayloadAction<Partial<FiltersPermissionCategories>>){
      state.permission_categories = { ...state.permission_categories, ...action.payload };
    },
    updateFiltersLanguages(state: TFiltersState, action: PayloadAction<Partial<FiltersLanguages>>){
      state.languages = { ...state.languages, ...action.payload };
    },
    updateFiltersProducts(state: TFiltersState, action: PayloadAction<Partial<FiltersProducts>>){
      state.products = { ...state.products, ...action.payload };
    },
    updateFiltersServices(state: TFiltersState, action: PayloadAction<Partial<FiltersServices>>){
      state.services = { ...state.services, ...action.payload };
    },
    updateFiltersProductCategories(state: TFiltersState, action: PayloadAction<Partial<FiltersProductCategories>>){
      state.product_categories = { ...state.product_categories, ...action.payload };
    },
    updateFiltersCalendar(state: TFiltersState, action: PayloadAction<Partial<FiltersCalendar>>){
      state.calendar = { ...state.calendar, ...action.payload };
    },
    updateFiltersSettings(state: TFiltersState, action: PayloadAction<Partial<FiltersSettings>>){
      state.settings = { ...state.settings, ...action.payload };
    },
    updateFiltersInvoices(state: TFiltersState, action: PayloadAction<Partial<DefaultFilter>>){
      state.invoices = { ...state.invoices, ...action.payload };
    }
  }
})

export const { reducer } = slice;

export const updateFiltersProject = (data: { project_id: string; data: Partial<FiltersProject>; is_default: boolean; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersProject(data));
}
export const updateFiltersContacts = (data: Partial<FiltersContacts>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersContacts(data));
}
export const updateFiltersDevices = (data: Partial<FiltersDevices>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersDevices(data));
}
export const updateFiltersPresentations = (data: Partial<FiltersPresentations>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersPresentations(data));
}
export const updateFiltersRoles = (data: Partial<FiltersRoles>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersRoles(data));
}
export const updateFiltersRole = (data: { role_id: string; data: Partial<FiltersRole>; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersRole(data));
}
export const updateFiltersUsers = (data: Partial<FiltersUsers>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersUsers(data));
}
export const updateFiltersProjects = (data: Partial<FiltersProjects>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersProjects(data));
}
export const updateFiltersTemplates = (data: Partial<FiltersTemplates>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersTemplates(data));
}
export const updateFiltersGroups = (data: Partial<FiltersGroups>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersGroups(data));
}
export const updateFiltersPermissions = (data: Partial<FiltersPermissions>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersPermissions(data));
}
export const updateFiltersPermissionCategories = (data: Partial<FiltersPermissionCategories>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersPermissionCategories(data));
}
export const updateFiltersLanguages = (data: Partial<FiltersLanguages>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersLanguages(data));
}
export const updateFiltersProducts = (data: Partial<FiltersProducts>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersProducts(data));
}
export const updateFiltersServices = (data: Partial<FiltersServices>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersServices(data));
}
export const updateFiltersProductCategories = (data: Partial<FiltersProductCategories>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersProductCategories(data));
}
export const updateFiltersCalendar = (data: Partial<FiltersCalendar>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersCalendar(data));
}
export const updateFiltersSettings = (data: Partial<FiltersSettings>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersSettings(data));
}
export const updateFiltersInvoices = (data: Partial<DefaultFilter>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFiltersInvoices(data));
}

export default slice;