
const getBase64String = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      if(result && typeof result === 'string'){
        resolve(result);
      }
    }

    reader.onerror = () => {
      reject('Error on reading file');
    }
  })
}

interface UseFilePickerOptions {
  accept: string;
  base64: boolean;
}

export const commonMimeTypes = {
  image: 'image/*'
}

const useFilePicker = (
  onChange: (file: FileList | string[] | null) => void,
  options: Partial<UseFilePickerOptions> = {}
) => {
  const handleInputChange = async (e: Event) => {
    const event = e as unknown as React.ChangeEvent<HTMLInputElement>;
    const files = event.target.files;
    if(!files || !files.length){
      return;
    }

    if(!!options.base64){
      const base64StringArray: string[] = [];
      for(const file of files){
        try{
          const str = await getBase64String(file);
          base64StringArray.push(str);
        }catch(e){
          console.error(e);
        }
      }
      onChange(base64StringArray);
      return;
    }

    onChange(event.target.files);
  }

  const open = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    if(!!options.accept){
      inputElement.accept = options.accept;
    }
    inputElement.click();
    inputElement.onchange = handleInputChange;
  }
  
  return { open }
}
export default useFilePicker;