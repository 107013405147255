import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Activity } from 'src/types/api/activity';

interface ActivityContainer {
  [object_id: string]: Activity[];
}

const initialState: ActivityContainer = {}

const slice = createSlice({
  name: 'activity',
  initialState: initialState,
  reducers: {
    setActivity(state: ActivityContainer, action: PayloadAction<{ object_id: string, activity: Activity[] }>){
      state[action.payload.object_id] = action.payload.activity;
    },
    createActivity(state: ActivityContainer, action: PayloadAction<{ object_id: string, activity: Activity }>){
      if(!state[action.payload.object_id]){
        state[action.payload.object_id] = [];
      }
      state[action.payload.object_id].unshift(action.payload.activity);
      // state[action.payload.object_id].push(action.payload.activity);
    },
    updateActivity(state: ActivityContainer, action: PayloadAction<{ object_id: string, activity: Activity }>){
      // state.downloads = state.downloads.map((download) => download.id === action.payload.id ? action.payload : download) ;
      if(!state[action.payload.object_id]){
        state[action.payload.object_id] = [];
      }
      state[action.payload.object_id] = state[action.payload.object_id].map((val) => val.id !== action.payload.activity.id ? val : action.payload.activity);
    },
    deleteActivity(state: ActivityContainer, action: PayloadAction<{ object_id: string, activity: Activity }>){
      if(!state[action.payload.object_id]){
        state[action.payload.object_id] = [];
      }
      state[action.payload.object_id] = state[action.payload.object_id].filter((val) => val.id !== action.payload.activity.id);
    }
  }
})

export const { reducer } = slice;

export const setActivity = (payload: { object_id: string, activity: Activity[] }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setActivity(payload));
}
export const createActivity = (payload: { object_id: string, activity: Activity }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createActivity(payload));
}
export const updateActivity = (payload: { object_id: string, activity: Activity }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateActivity(payload));
}
export const deleteActivity = (payload: { object_id: string, activity: Activity }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteActivity(payload));
}

export default slice;