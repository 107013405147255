import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import { useTranslation } from 'src/i18n';
import { Link as RouterLink } from 'react-router-dom';
import useLocalSettings from 'src/hooks/general/useLocalSettings';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import SettingsBrightnessOutlinedIcon from '@mui/icons-material/SettingsBrightnessOutlined';

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    color: theme.palette.text.primary
  }
}));

const ThemeButton = () => {
  const { classes } = useStyles();
  const {t} = useTranslation();
  const { settings, saveSettings } = useLocalSettings();

  const is_lightmode = settings.theme === 'LIGHT';

  const handleClick = () => {
    const new_theme = is_lightmode ? 'DARK' : 'LIGHT';
    saveSettings({
      ...settings,
      theme: new_theme,
      automaticTheme: false
    });
  }

  return (
    <Tooltip title={!is_lightmode ? `${t("lang.lightmode")}` : `${t("lang.darkmode")}`}>
      <IconButton 
        className={classes.iconButton} 
        size="large"
        onClick={handleClick}
      >
        <SvgIcon>
          {is_lightmode ?
            <DarkModeOutlinedIcon />
            :
            <LightModeOutlinedIcon />
          }          
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export default ThemeButton;