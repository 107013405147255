import { useEffect, useRef, useState } from 'react';
import NormalizationTextField from 'src/components/general/NormalizationTextField';

interface Props {
  title: string;
  onFinish: (value: string) => void;
  onNewLine: (value: string) => void;
}

const UpdateSubtaskTitleField = ({
  title,
  onFinish,
  onNewLine
}: Props) => {

  const [value, setValue] = useState(title);

  const blurrableRef = useRef(false);
  useEffect(() => {
    blurrableRef.current = true;
    return () => {
      blurrableRef.current = false;
    }
  }, []);

  const handleFinish = () => {
    if(blurrableRef.current){
      onFinish(value);
    }
  }

  const handleNewLine = () => {
    if(blurrableRef.current){
      onNewLine(value);
      setValue('');
    }
  }

  return (
    <NormalizationTextField
      normalization='first_to_upper'
      value={value}
      onChange={(e) => setValue(e.target.value)}
      fullWidth
      onBlur={handleFinish}
      onKeyPress={(e) => e.key === 'Enter' && handleNewLine()}
      autoFocus
      variant='standard'
    />
  )
}

export default UpdateSubtaskTitleField;