import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { EventProject } from 'src/types/api/events/Event';

const initialState: EventProject[] = [];

const slice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    setEventProjects(state: EventProject[], action: PayloadAction<EventProject[]>){
      return action.payload;
    },
    createEventProject(state: EventProject[], action: PayloadAction<EventProject>){
      state.push(action.payload);
    },
    updateEventProject(state: EventProject[], action: PayloadAction<EventProject>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteEventProject(state: EventProject[], action: PayloadAction<EventProject>){
      return state.filter((val) => val.id !== action.payload.id);
    },
  }
})

export const { reducer } = slice;

export const setEventProjects = (data: EventProject[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setEventProjects(data));
}
export const createEventProject = (data: EventProject): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createEventProject(data));
}
export const updateEventProject = (data: EventProject): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateEventProject(data));
}
export const deleteEventProject = (data: EventProject): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteEventProject(data));
}

export default slice;