import {
  ToggleButton as MuiToggleButton, 
  ToggleButtonProps as MuiToggleButtonProps,
  alpha
} from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';

const useStyles = makeStyles()(theme => ({
  activeButton: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.16)} !important`
  }
}));

const ToggleButton = (props: MuiToggleButtonProps) => {
  const { classes } = useStyles();

  return (
    <MuiToggleButton 
      classes={{ selected: classes.activeButton }} 
      {...props}
    />
  )
}
export default ToggleButton;