import { Box, Dialog, DialogContent, Grid, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'src/i18n';
import SelectSalonType from './steps/SelectSalonType';
import CompanyInformation from './steps/CompanyInformation';
import OpeningHours from './steps/OpeningHours';
import Users from './steps/Users';
import SvgSalonType from 'src/assets/svg/SalonType';
import SvgCompanyInformation from 'src/assets/svg/CompanyInformation';
import SvgWorkingHours from 'src/assets/svg/WorkingHours';
import SvgUsers from 'src/assets/svg/Users';
import { useDispatch, useSelector } from "src/store";
import { FormValues, TStep } from './types';
import { useForm } from "react-hook-form";
import { stepperRequest, StepperRequestData } from 'src/requests/stepper';
import { FormValues as OpeningHoursFormValues } from './steps/OpeningHours/types';
import { useAxiosOptions } from "src/hooks/general/useAxios";
import { makeStyles } from "src/theme/makeStyles";
import clsx from "clsx";
import Finished from './Finished';

const useStyles = makeStyles()(theme => ({
  svgWrapper: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    opacity: 0,
    transition: `opacity 1s`
  },
  svgWrapperEnabled: {
    opacity: 1
  },
  dialogPaperRoot: {
    height: '100%'
  }
}));

const WelcomeStepper = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();

  const group = useSelector(state => state.general.group);
  const settings = useSelector(state => state.general.settings);

  const steps: TStep[] = useMemo(() => {
    const tagsToUse = ['step_salon_type', 'step_company_information', 'step_working_hours', 'step_users'];

    const settingsToUse = tagsToUse.reduce((prev, tag) => {
      const foundSetting = settings.find(setting => setting.tag === tag && setting.value === 'true'); // ! USE THIS FOR NORMAL BEHAVIOR
      // const foundSetting = settings.find(setting => setting.tag === tag);
      if (foundSetting)
        return [...prev, foundSetting];
      return [...prev];
    }, []);

    // foreach setting
    // if !watchValues[stepCompany | stepWorkingHours] set to their default values ??

    return settingsToUse.map(setting => ({
      name: setting.tag,
      label: setting.name,
    }));
  }, [settings]);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (steps.length && !open) {
      setOpen(true);
    }
  }, [steps]);

  const [activeStep, setActiveStep] = useState(0);

  // to prevent skipping steps on step update & reset to 0 when new step is added
  useEffect(() => {
    // when steps change set active step to 0
    setActiveStep(0);
  }, [steps]);

  const {
    watch,
    setValue
  } = useForm<FormValues>({

  });
  const watchValues = watch();

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (data: FormValues) => {
    const dataToSend: StepperRequestData = {}
    if (data.companyInformation) {
      dataToSend.company_information = { ...data.companyInformation };
    }

    if (data.openingHours) {
      const dayKeys = Object.keys(data.openingHours) as (keyof OpeningHoursFormValues)[];
      dataToSend.opening_hours = dayKeys.map(key => {
        return {
          day: key,
          enabled: data.openingHours![key].enabled,
          times: data.openingHours![key].times.map(time => ({
            start: time.start ? time.start.format('HH:mm:ss') : '',
            end: time.end ? time.end.format('HH:mm:ss') : ''
          }))
        }
      })
    }

    if (data.users) {
      dataToSend.users = data.users.map(user => ({
        ...user
      }));
    }

    if (data.salonType) {
      dataToSend.salon_type = data.salonType;
    }

    // console.log(dataToSend);
    try {
      setSubmitting(true);
      await stepperRequest(dataToSend, dispatch, axiosOptions.apiConfig);
      // console.log(responseData);
    } catch (err) {
      console.log(err);
      handleBack();
    }
    setSubmitting(false);
  }

  // set false on close ? 
  const [finished, setFinished] = useState(false);
  
  
  useEffect(() => {
    // This is called AFTER all variable steps
    if (steps.length && activeStep === steps.length) {
      setFinished(true);
      onSubmit(watchValues);
    }
  }, [activeStep, steps]);

  const handleNext = () => {
    setActiveStep(prev => prev + 1);
  }

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  }

  const activeStepName = useMemo(() =>
    steps.find((step, index) => index === activeStep)?.name
    , [steps, activeStep]);

  if (!group) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      classes={{ paper: classes.dialogPaperRoot }}
    >
      <DialogContent>
        {finished ? (
          <Finished 
            submitting={submitting} 
            onClose={() => setOpen(false)}
          />
        ) : (
          <>
            <Typography variant='h2'>{t(`lang.almost_there`)}</Typography>
            <Box my={1}>
              <Typography variant='body2'>{t(`description.welcome_stepper`)}</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  connector={null}
                >
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={step.caption ? <Typography variant="caption">{step.caption}</Typography> : null}
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        {step.name === 'step_salon_type' && (
                          <SelectSalonType
                            value={watchValues['salonType'] ? watchValues['salonType'] : null}
                            stepIndex={index}
                            onNext={handleNext}
                            onChange={(salonType) => setValue('salonType', salonType)}
                            onBack={handleBack}
                          />
                        )}
                        {step.name === 'step_company_information' && (
                          <CompanyInformation
                            stepIndex={index}
                            onNext={handleNext}
                            onBack={handleBack}
                            onChange={(values) => setValue('companyInformation', values)}
                            initialValues={watchValues.companyInformation}
                          />
                        )}
                        {step.name === 'step_working_hours' && (
                          <OpeningHours
                            stepIndex={index}
                            onNext={handleNext}
                            onBack={handleBack}
                            onChange={(values) => setValue('openingHours', values)}
                          />
                        )}
                        {step.name === 'step_users' && (
                          <Users
                            stepIndex={index}
                            onNext={handleNext}
                            onBack={handleBack}
                            onChange={(values) => setValue('users', values)}
                          />
                        )}

                      </StepContent>
                    </Step>
                  ))}
                  <Step>
                    <StepLabel>{t(`lang.finished`)}</StepLabel>
                  </Step>
                </Stepper>
              </Grid>
              <Grid item sm={6}>
                <div
                  style={{ position: 'relative', width: '100%', height: '100%', minHeight: 550 }}
                >
                  <div
                    className={clsx(
                      classes.svgWrapper,
                      activeStepName === 'step_salon_type' && classes.svgWrapperEnabled
                    )}
                  >
                    <SvgSalonType
                      color1light={group.color1_light}
                      color2light={group.color2_light}
                      color3light={group.color3_light}
                      color1dark={group.color1_dark}
                      color2dark={group.color2_dark}
                      color3dark={group.color3_dark}
                    />
                  </div>
                  <div
                    className={clsx(
                      classes.svgWrapper,
                      activeStepName === 'step_company_information' && classes.svgWrapperEnabled
                    )}
                  >
                    <SvgCompanyInformation
                      color1light={group.color1_light}
                      color2light={group.color2_light}
                      color3light={group.color3_light}
                      color1dark={group.color1_dark}
                      color2dark={group.color2_dark}
                      color3dark={group.color3_dark}
                    />
                  </div>
                  <div
                    className={clsx(
                      classes.svgWrapper,
                      activeStepName === 'step_working_hours' && classes.svgWrapperEnabled
                    )}
                  >
                    <SvgWorkingHours
                      color1light={group.color1_light}
                      color2light={group.color2_light}
                      color3light={group.color3_light}
                      color1dark={group.color1_dark}
                      color2dark={group.color2_dark}
                      color3dark={group.color3_dark}
                    />
                  </div>

                  <div
                    className={clsx(
                      classes.svgWrapper,
                      activeStepName === 'step_users' && classes.svgWrapperEnabled
                    )}
                  >
                    <SvgUsers
                      color1light={group.color1_light}
                      color2light={group.color2_light}
                      color3light={group.color3_light}
                      color1dark={group.color1_dark}
                      color2dark={group.color2_dark}
                      color3dark={group.color3_dark}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default WelcomeStepper;