import { useTheme, lighten, darken } from '@mui/material';



export default function SvgComponent({ color1light, color2light, color3light, color1dark, color2dark, color3dark }) {
  const theme = useTheme();
  const changeColor = theme.name === 'LIGHT' ? lighten : darken;
  
  return (
    <svg id="company_information" data-name="Company information" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <style>
        {
            `
            .svgc1-0{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0)}}
			.svgc1-1{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.1)}}
			.svgc1-2{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.2)}}
			.svgc1-3{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.3)}}
			.svgc1-4{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.4)}}
			.svgc1-5{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.5)}}
			.svgc1-6{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.6)}}
			.svgc1-7{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.7)}}
			.svgc1-8{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.8)}}
			.svgc1-9{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9)}}
			.svgc1-10{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 1)}}

			.svgc2-0{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0)}}
			.svgc2-1{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.1)}}
			.svgc2-2{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.2)}}
			.svgc2-3{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.3)}}
			.svgc2-4{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.4)}}
			.svgc2-5{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.5)}}
			.svgc2-6{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.6)}}
			.svgc2-7{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.7)}}
			.svgc2-8{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.8)}}
			.svgc2-9{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.9)}}
			.svgc2-10{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 1)}}

			.svgc3-0{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0)}}
			.svgc3-1{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.1)}}
			.svgc3-2{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.2)}}
			.svgc3-3{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.3)}}
			.svgc3-4{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.4)}}
			.svgc3-5{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.5)}}
			.svgc3-6{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.6)}}
			.svgc3-7{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.7)}}
			.svgc3-8{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.8)}}
			.svgc3-9{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.9)}}
			.svgc3-10{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 1)}}

            .ci0{opacity:0.5;}
            .ci1{fill:#F8BC9E;}
            .ci2{fill:none;stroke:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0)};stroke-width:1;stroke-miterlimit:10;stroke-dasharray:8;}
            .ci3{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
            .ci4{fill:none;stroke:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0 )};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
            .ci5{opacity:0.2;}      
           
            `
          }
         
        </style>
        <g id="background_23_">
        <g>
            <path className="svgc1-9" d="M241.5,258.41c31.97-16.86,40.78,14.09,55.61,2.4c14.16-11.16-0.99-41.25,6.3-61.41
                c9.09-25.14,26.35-13.6,33.66-35.7c7.39-22.33-12-59.85-28.53-80.1c-19.76-24.19-48.32-39.51-71.7-34.87
                c-24.74,4.91-20.41,27.55-47.5,40.8c-34.77,17.01-57.84-12.52-81.54,6.83c-21.99,17.95-27.7,64.25-13.99,86.4
                c10.31,16.66,26.14,10.84,35.57,28.09c12.19,22.31-8.22,43.18,2.62,66.64c10.64,23.05,54.27,35.27,77.33,30.9
                C226.57,305.13,214.1,272.86,241.5,258.41z"/>
        </g>
        <g>
            <path className="svgc1-7" d="M223.75,119.63c-0.24-1.17-0.5-2.34-0.78-3.47l0.87-0.21c0.28,1.14,0.55,2.32,0.79,3.51L223.75,119.63z
                M222.04,112.72c-0.34-1.15-0.7-2.29-1.08-3.39l0.85-0.29c0.39,1.11,0.75,2.26,1.09,3.43L222.04,112.72z M219.71,106.01
                c-0.45-1.11-0.93-2.2-1.43-3.25l0.81-0.38c0.51,1.06,0.99,2.17,1.45,3.3L219.71,106.01z M216.65,99.61
                c-0.58-1.04-1.2-2.06-1.83-3.04l0.75-0.49c0.64,0.99,1.27,2.03,1.86,3.09L216.65,99.61z M212.78,93.68
                c-0.72-0.94-1.48-1.86-2.26-2.72l0.66-0.6c0.79,0.88,1.57,1.81,2.31,2.78L212.78,93.68z M152.61,89.03l-0.53-0.72
                c0.97-0.71,1.97-1.4,2.97-2.04l0.48,0.75C154.55,87.65,153.56,88.33,152.61,89.03z M208.03,88.44c-0.87-0.8-1.77-1.57-2.7-2.28
                l0.55-0.71c0.95,0.73,1.87,1.51,2.76,2.34L208.03,88.44z M158.58,85.19l-0.44-0.78c1.05-0.59,2.13-1.15,3.2-1.66l0.39,0.81
                C160.68,84.06,159.62,84.61,158.58,85.19z M202.43,84.14c-1-0.62-2.03-1.2-3.08-1.73l0.4-0.8c1.08,0.53,2.14,1.13,3.16,1.77
                L202.43,84.14z M164.99,82.12l-0.33-0.83c1.12-0.45,2.26-0.86,3.39-1.23l0.28,0.85C167.21,81.28,166.09,81.68,164.99,82.12z
                M196.11,81c-1.09-0.41-2.22-0.78-3.36-1.09l0.23-0.86c1.16,0.32,2.32,0.69,3.44,1.12L196.11,81z M171.74,79.94l-0.22-0.87
                c1.17-0.29,2.36-0.54,3.54-0.74l0.15,0.88C174.05,79.41,172.89,79.65,171.74,79.94z M189.3,79.15c-1.15-0.2-2.33-0.35-3.51-0.44
                l0.07-0.89c1.21,0.1,2.41,0.25,3.59,0.45L189.3,79.15z M178.72,78.76l-0.08-0.89c1.21-0.11,2.42-0.17,3.61-0.18l0.01,0.89
                C181.09,78.59,179.9,78.65,178.72,78.76z"/>
        </g>
        <g>
            <g>
                <path className="svgc1-7" d="M321.61,189.08c-1.17,5.41-5.61,9.08-8.78,8.94c-3.03-0.14-2.99-3.68-5.93-4.18
                    c-4.62-0.79-7.25,7.51-12.33,8.09c-5.25,0.59-5.37-7.92-11.51-9.85c-7.52-2.36-12.23,8.89-20.43,8.12
                    c-7.85-0.74-16.47-12.27-13.76-24.59c2.4-10.88,12.83-18.73,19.75-17.36c8.08,1.61,7.81,15.1,15.63,15.57
                    c7.02,0.43,10.91-10.23,16.7-8.23c4.84,1.67,3.61,9.64,9.79,12.2c4.06,1.68,6.84-0.81,9.29,2.22
                    C321.71,182.12,322.34,185.72,321.61,189.08z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M232.66,176.74c-0.02-0.02-0.04-0.05-0.05-0.08c-0.12-0.22-0.04-0.49,0.18-0.61
                    c5.76-3.18,20.96-2.37,45.15,2.39c17.96,3.53,34.46,8.05,34.63,8.09c0.24,0.06,0.38,0.31,0.31,0.55
                    c-0.07,0.24-0.31,0.38-0.55,0.31c-0.66-0.18-65.75-17.92-79.11-10.56C233.03,176.94,232.79,176.9,232.66,176.74z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M267.26,190.42c-0.02-0.02-0.03-0.04-0.05-0.06l-8.72-14.81c-0.11-0.19-0.07-0.43,0.1-0.57l10.4-8.71
                    c0.19-0.16,0.46-0.15,0.63,0.06c0.16,0.19,0.13,0.47-0.06,0.63l-10.11,8.47l8.53,14.48c0.13,0.21,0.05,0.49-0.16,0.61
                    C267.63,190.63,267.4,190.58,267.26,190.42z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M295.4,193.3c-0.03-0.04-0.06-0.08-0.08-0.13l-4.28-11.26c-0.06-0.17-0.02-0.36,0.11-0.49l8.99-8.44
                    c0.18-0.17,0.46-0.16,0.63,0.02c0.17,0.18,0.16,0.46-0.02,0.63l-8.77,8.24l4.17,10.98c0.09,0.23-0.03,0.49-0.26,0.58
                    C295.71,193.49,295.52,193.44,295.4,193.3z"/>
            </g>
        </g>
        <g>
            <path className="svgc1-7" d="M263.92,183.12l-0.5-0.75c1-0.66,1.98-1.34,2.93-2.02l0.52,0.73C265.92,181.76,264.92,182.45,263.92,183.12z
                M269.76,178.93l-0.55-0.71c0.95-0.73,1.88-1.48,2.77-2.23l0.57,0.69C271.66,177.44,270.72,178.19,269.76,178.93z M275.27,174.31
                l-0.6-0.66c0.88-0.81,1.74-1.64,2.56-2.47l0.64,0.63C277.03,172.65,276.16,173.49,275.27,174.31z M280.32,169.18l-0.67-0.59
                c0.79-0.89,1.56-1.81,2.28-2.72l0.7,0.55C281.91,167.34,281.13,168.27,280.32,169.18z M284.77,163.51l-0.74-0.51
                c0.67-0.98,1.31-1.99,1.9-3l0.77,0.45C286.11,161.48,285.46,162.51,284.77,163.51z M288.39,157.27l-0.81-0.39
                c0.52-1.07,0.98-2.17,1.39-3.25l0.84,0.32C289.4,155.05,288.92,156.17,288.39,157.27z M290.94,150.5l-0.86-0.24
                c0.31-1.14,0.57-2.3,0.76-3.45l0.88,0.15C291.52,148.15,291.26,149.33,290.94,150.5z M292.14,143.37l-0.89-0.06
                c0.04-0.7,0.07-1.4,0.07-2.09c0-0.47-0.01-0.96-0.03-1.43l0.89-0.04c0.02,0.49,0.03,0.99,0.03,1.47
                C292.2,141.93,292.18,142.65,292.14,143.37z M290.93,136.27c-0.18-1.16-0.42-2.32-0.72-3.45l0.86-0.23
                c0.31,1.17,0.56,2.36,0.74,3.55L290.93,136.27z M289.12,129.46c-0.42-1.09-0.9-2.18-1.44-3.22l0.8-0.41
                c0.55,1.07,1.05,2.19,1.48,3.31L289.12,129.46z M285.91,123.19c-0.64-0.98-1.34-1.94-2.08-2.85l0.69-0.56
                c0.76,0.93,1.48,1.92,2.13,2.93L285.91,123.19z M281.46,117.71c-0.84-0.83-1.73-1.62-2.63-2.35l0.56-0.7
                c0.93,0.74,1.85,1.56,2.71,2.41L281.46,117.71z M275.95,113.34c-1.02-0.63-2.06-1.19-3.11-1.65l0.37-0.82
                c1.08,0.48,2.16,1.06,3.21,1.71L275.95,113.34z M259.28,110.73l-0.33-0.83c1.13-0.44,2.33-0.74,3.57-0.89l0.11,0.89
                C261.47,110.03,260.34,110.32,259.28,110.73z M269.54,110.48c-1.15-0.32-2.31-0.54-3.43-0.64l0.08-0.89
                c1.18,0.11,2.39,0.33,3.59,0.67L269.54,110.48z"/>
        </g>
        <g>
            <g>
                <path className="svgc2-0" d="M256.31,184.93c-0.07,0.01-0.15,0.01-0.22-0.01c-0.23-0.08-0.36-0.33-0.29-0.56
                    c1.79-5.48,10.31-12.61,25.34-21.18c11.14-6.36,21.9-11.19,22.01-11.24c0.23-0.1,0.49,0,0.59,0.23c0.1,0.23,0,0.49-0.23,0.59
                    c-0.43,0.19-42.75,19.27-46.86,31.88C256.6,184.79,256.46,184.9,256.31,184.93z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M311.9,153.31c-4.3,0.85-8.67-0.38-8.74-0.4c-0.13-0.04-0.24-0.14-0.29-0.27c-0.05-0.13-0.04-0.28,0.04-0.4
                    c5.52-8.83,16.12-5.66,16.23-5.63c0.12,0.04,0.21,0.12,0.27,0.23c0.05,0.11,0.06,0.24,0.02,0.35
                    C317.97,151.06,314.95,152.7,311.9,153.31z M304,152.2c2.41,0.54,11.41,2.04,14.39-4.86C316.39,146.85,308.63,145.48,304,152.2z"
                    />
            </g>
            <g>
                <path className="svgc2-0" d="M298.61,168.32c-7.01,1.38-12.94-6.96-13.01-7.06c-0.08-0.11-0.1-0.25-0.06-0.38
                    c0.04-0.13,0.14-0.24,0.26-0.29c9.69-3.87,16.11,5.66,16.17,5.75c0.07,0.1,0.09,0.22,0.06,0.34c-0.02,0.12-0.09,0.22-0.19,0.28
                    C300.75,167.68,299.67,168.11,298.61,168.32z M286.69,161.21c1.52,1.94,7.76,9.07,14.27,5.25c-0.55-0.72-1.91-2.37-3.9-3.73
                    C293.75,160.48,290.26,159.97,286.69,161.21z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M286.05,161.44c-0.08,0.02-0.16,0.01-0.24-0.02c-0.13-0.05-0.23-0.15-0.28-0.29
                    c-3.07-9.95,6.26-15.9,6.35-15.96c0.1-0.07,0.23-0.08,0.35-0.05c0.12,0.03,0.22,0.11,0.28,0.22c4.76,8.72-6.2,15.97-6.31,16.04
                    C286.16,161.41,286.11,161.43,286.05,161.44z M291.96,146.21c-1.68,1.2-7.8,6.17-5.74,14.07
                    C288.21,158.81,295.23,152.97,291.96,146.21z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M279.97,178.07c-6.4,1.26-13.01-5.06-13.09-5.13c-0.1-0.1-0.15-0.23-0.13-0.37
                    c0.02-0.14,0.09-0.26,0.21-0.33c8.92-5.39,17.24,2.87,17.32,2.96c0.09,0.09,0.13,0.2,0.13,0.32c0,0.12-0.05,0.24-0.14,0.32
                    C282.9,177.09,281.44,177.78,279.97,178.07z M267.95,172.7c1.91,1.65,9.53,7.62,15.35,2.8
                    C281.72,174.1,275.07,168.84,267.95,172.7z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M267.28,173.06c-0.05,0.01-0.1,0.01-0.16,0c-0.14-0.02-0.27-0.11-0.33-0.24c-4.65-9.3,3.1-17.17,3.18-17.25
                    c0.09-0.09,0.22-0.14,0.34-0.13c0.13,0.01,0.25,0.07,0.32,0.17c6.11,7.8-3.04,17.23-3.13,17.32
                    C267.45,173,267.37,173.04,267.28,173.06z M270.25,156.59c-1.38,1.59-6.19,7.9-2.94,15.23
                    C268.97,169.92,274.55,162.72,270.25,156.59z"/>
            </g>
        </g>
        <path className="ci2" d="M192.73,239.25c-102.01,19.24-99.32-119.46-11.36-97.68"/>
        <g>
            <g>
                
                    <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 -103.6348 268.0233)" className="svgc2-0" cx="117.51" cy="199.49" rx="6.71" ry="6.71"/>
            </g>
            <g>
                <path className="svgc3-10" d="M115.77,197.31v4.36c0,0.34,0.36,0.55,0.66,0.4l3.48-1.85c0.29-0.16,0.32-0.56,0.05-0.76l-3.48-2.51
                    C116.18,196.73,115.77,196.94,115.77,197.31z"/>
            </g>
        </g>
        <g>
            <g>
                <path className="svgc1-6" d="M112.41,118.15c-0.96,4.41,1.39,9.33,4.17,10.21c2.98,0.94,6.16-0.6,8.7,0.26c4.11,1.4,1.2,10.87,5.25,12.36
                    c2.81,1.03,5.92-2.16,9.85-1.78c4.7,0.46,3.76,6.28,8.75,8.69c6,2.9,17.6-2.89,20.12-14.26c2.04-9.24-2.42-19.95-7.87-21.46
                    c-6.01-1.66-9.83,4.26-15.23,2.06c-5.42-2.2-5.53-9.81-9.59-10.22c-3.5-0.36-5.48,5.83-11.2,6.62c-3.52,0.49-4.36,0.04-7.84,1.31
                    C115.29,112.75,113.09,115.04,112.41,118.15z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M180.75,133.75c-0.06,0.06-0.14,0.1-0.23,0.12c-23.74,4.05-49.82-11.98-50.09-12.15
                    c-0.21-0.13-0.27-0.41-0.14-0.62c0.13-0.21,0.41-0.27,0.62-0.14c0.26,0.16,26.07,16.02,49.46,12.02
                    c0.24-0.04,0.47,0.12,0.52,0.37C180.91,133.51,180.85,133.65,180.75,133.75z"/>
            </g>
        </g>
        <g>
            <g>
                <path className="svgc1-7" d="M153.03,72.61c0,0,28.15,11.29,30.58,28.3s-3.51,27.02-3.51,27.02s-16.7-5.51-23.66-21.15
                    c-6.96-15.64-5.24-21.39-5.24-21.39l4.5,0.98l-4.58-7.11C151.12,79.26,150.8,76.37,153.03,72.61z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M180.4,128.25c-0.07,0.06-0.15,0.11-0.25,0.12c-0.24,0.03-0.47-0.14-0.5-0.39
                    c-1.73-13.32-26.75-54.73-27-55.14c-0.13-0.21-0.06-0.49,0.15-0.61c0.21-0.13,0.49-0.06,0.61,0.15
                    c1.04,1.71,25.37,41.99,27.13,55.49C180.56,128.02,180.5,128.16,180.4,128.25z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M171.4,105.45c-0.04,0.03-0.08,0.06-0.12,0.08c-4.17,1.9-18.82-13.38-20.48-15.13
                    c-0.17-0.18-0.16-0.46,0.02-0.63c0,0,0,0,0,0c0.18-0.17,0.46-0.16,0.63,0.02c6.09,6.43,16.76,16.17,19.46,14.94
                    c0.22-0.1,0.49,0,0.59,0.22C171.57,105.12,171.53,105.32,171.4,105.45z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M175.23,111.42c-0.1,0.09-0.16,0.15-0.18,0.16c-0.19,0.15-0.47,0.12-0.63-0.07
                    c-0.15-0.19-0.12-0.47,0.07-0.63c0.07-0.06,4.96-4.22,5.03-19.24c0-0.25,0.2-0.45,0.45-0.45c0.25,0,0.45,0.2,0.45,0.45
                    C180.35,105.74,176.18,110.52,175.23,111.42z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M165.94,95.02c-0.15,0.15-0.39,0.17-0.57,0.04c-0.2-0.14-0.25-0.42-0.11-0.62
                    c3.86-5.43,1.32-14.33,1.29-14.42c-0.07-0.24,0.07-0.49,0.3-0.56c0.24-0.07,0.49,0.07,0.56,0.3c0.03,0.1,0.69,2.37,0.85,5.38
                    c0.22,4.02-0.56,7.41-2.27,9.82C165.98,94.97,165.96,95,165.94,95.02z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M168.29,97.02c-0.3,0.29-0.64,0.54-1.01,0.74c-0.22,0.12-0.49,0.04-0.61-0.18
                    c-0.12-0.22-0.03-0.49,0.18-0.61c4.6-2.47,2.91-14.32,2.9-14.44c-0.04-0.24,0.13-0.47,0.38-0.51c0.24-0.04,0.47,0.13,0.51,0.38
                    C170.71,82.87,172.18,93.3,168.29,97.02z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M175.9,116.45c-0.03,0.03-0.07,0.06-0.11,0.08c-5.45,2.71-16.34-4.68-16.8-4.99
                    c-0.2-0.14-0.26-0.42-0.12-0.62c0.14-0.2,0.42-0.26,0.62-0.12c0.11,0.07,10.91,7.4,15.89,4.93c0.22-0.11,0.49-0.02,0.6,0.2
                    C176.09,116.1,176.04,116.32,175.9,116.45z"/>
            </g>
            <g>
                <path className="svgc3-10" d="M177.71,119.26c-0.04,0.04-0.1,0.08-0.16,0.1c-8.36,2.91-13.25-0.92-13.45-1.08
                    c-0.19-0.15-0.22-0.44-0.07-0.63c0.16-0.2,0.44-0.22,0.63-0.07c0.05,0.04,4.74,3.67,12.6,0.93c0.23-0.08,0.49,0.04,0.57,0.28
                    C177.88,118.96,177.83,119.14,177.71,119.26z"/>
            </g>
        </g>
        <g>
            <g>
                <path className="svgc2-0" d="M172.06,127.64L172.06,127.64c-3.23,3.09-7.73,5.13-12.33,5.61c-4.39,0.46-8.3-0.56-10.46-2.74
                    c-2.59-2.61-2.2-6.26-1.83-9.79c0.36-3.43,0.71-6.67-1.77-8.56c-2.19-1.67-5.12-1.22-7.97-0.79c-3.11,0.47-6.05,0.92-7.8-1.41
                    c-1.37-1.81-0.95-3.92-0.52-6.15c0.46-2.35,0.93-4.78-0.62-7.12c-0.84-1.27-1.86-1.9-2.84-2.51c-1.31-0.81-2.55-1.58-2.73-3.68
                    c-0.21-2.42,1.09-5.37,3.31-7.5l0,0c0.01-0.01,0.02-0.02,0.03-0.03c3.57-3.41,8.65-3.88,10.98-2.15
                    c1.24,0.92,1.31,2.15,1.38,3.24c0.06,0.94,0.11,1.84,0.87,2.56c1.32,1.26,3.48,0.73,5.76,0.17c2.3-0.56,4.67-1.14,6.68-0.1
                    c2.31,1.2,2.24,3.54,2.17,6.02c-0.06,2.25-0.13,4.57,1.42,6.5c2.02,2.53,5.24,2.31,8.65,2.09c3.35-0.22,6.8-0.45,9.55,1.8
                    c2.37,1.94,3.88,5.55,4.04,9.66C178.24,118.4,176.06,123.82,172.06,127.64z M127.12,83.65c-2,1.93-3.22,4.65-3.04,6.78
                    c0.14,1.65,1.05,2.21,2.31,2.99c1.01,0.63,2.16,1.34,3.11,2.77c1.75,2.64,1.22,5.38,0.75,7.78c-0.41,2.12-0.77,3.95,0.35,5.44
                    c1.43,1.9,3.99,1.51,6.95,1.06c2.89-0.44,6.16-0.93,8.64,0.97c2.87,2.2,2.49,5.84,2.11,9.37c-0.35,3.32-0.72,6.76,1.57,9.06
                    c4.06,4.08,14.8,3.55,21.54-2.89c0,0,0,0,0.01,0c3.82-3.65,5.89-8.82,5.69-14.19c-0.15-3.86-1.54-7.23-3.71-9.01
                    c-2.48-2.03-5.76-1.81-8.93-1.6c-3.49,0.23-7.09,0.47-9.4-2.42c-1.75-2.19-1.68-4.79-1.61-7.08c0.07-2.38,0.12-4.26-1.69-5.21
                    c-1.7-0.89-3.91-0.35-6.05,0.18c-2.42,0.59-4.91,1.2-6.59-0.39c-1.02-0.97-1.08-2.13-1.14-3.16c-0.06-1.02-0.11-1.9-1.02-2.57
                    c-2.04-1.51-6.67-0.94-9.83,2.08C127.14,83.63,127.13,83.64,127.12,83.65L127.12,83.65z M126.81,83.33L126.81,83.33L126.81,83.33
                    z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M183.64,134.58c-0.09,0.09-0.21,0.13-0.35,0.12c-5.59-0.5-15.94-8.27-30.76-23.12
                    c-11-11.01-20.5-22.03-20.59-22.14c-0.16-0.19-0.15-0.47,0.05-0.63c0.19-0.16,0.47-0.14,0.63,0.05
                    c0.38,0.44,37.87,43.81,50.76,44.95c0.25,0.02,0.43,0.24,0.41,0.49C183.77,134.41,183.72,134.51,183.64,134.58z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M164.85,123.11c-0.07,0.07-0.16,0.11-0.26,0.12l-11.47,1.22c-0.25,0.03-0.47-0.15-0.49-0.4
                    c-0.03-0.25,0.15-0.47,0.4-0.49l11.09-1.18l0.85-14.21c0.01-0.25,0.23-0.43,0.47-0.42c0.25,0.01,0.43,0.23,0.42,0.47l-0.87,14.59
                    C164.98,122.93,164.93,123.03,164.85,123.11z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M144.91,103.12c-0.06,0.06-0.15,0.1-0.24,0.12l-10.35,1.69c-0.24,0.04-0.47-0.13-0.51-0.37
                    c-0.04-0.24,0.13-0.47,0.37-0.51l10.05-1.64l2.26-9.68c0.06-0.24,0.3-0.39,0.54-0.33c0.24,0.06,0.39,0.3,0.33,0.54l-2.33,9.97
                    C145.01,102.99,144.97,103.06,144.91,103.12z"/>
            </g>
        </g>
        <g>
            
                <rect x="177.76" y="48.83" transform="matrix(0.9267 -0.3757 0.3757 0.9267 -6.2693 71.7722)" className="svgc2-0" width="6.26" height="6.26"/>
        </g>
        <g>
            
                <rect x="281.66" y="96.03" transform="matrix(0.9125 0.409 -0.409 0.9125 65.4685 -107.8082)" className="svgc1-3" width="6.26" height="6.26"/>
        </g>
        <g>
            <g>
                <path className="svgc2-0" d="M315.82,55.18c-0.11,0-0.21-0.04-0.3-0.11c-0.18-0.16-0.2-0.45-0.04-0.63c1-1.13,2.45-1.78,3.96-1.78
                    c1.41,0,2.74,0.55,3.74,1.55c0.17,0.17,0.17,0.46,0,0.63c-0.17,0.17-0.46,0.17-0.63,0c-0.83-0.83-1.94-1.29-3.11-1.29
                    c-1.26,0-2.46,0.54-3.29,1.48C316.07,55.13,315.95,55.18,315.82,55.18z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M311.02,51.33c-0.11,0-0.22-0.04-0.31-0.12c-0.18-0.17-0.18-0.45-0.01-0.63c2.33-2.44,5.47-3.78,8.84-3.78
                    c3.27,0,6.34,1.27,8.65,3.58c0.17,0.17,0.17,0.46,0,0.63c-0.17,0.17-0.46,0.17-0.63,0c-2.14-2.14-4.99-3.32-8.02-3.32
                    c-3.12,0-6.03,1.24-8.19,3.5C311.26,51.28,311.14,51.33,311.02,51.33z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M306.72,47.1c-0.11,0-0.22-0.04-0.31-0.12c-0.18-0.17-0.18-0.45-0.01-0.63c3.48-3.61,8.16-5.6,13.17-5.6
                    c4.88,0,9.48,1.9,12.93,5.36c0.17,0.17,0.17,0.46,0,0.63c-0.17,0.17-0.46,0.17-0.63,0c-3.28-3.28-7.65-5.09-12.3-5.09
                    c-4.77,0-9.22,1.89-12.53,5.33C306.95,47.05,306.83,47.1,306.72,47.1z"/>
            </g>
            <g>
                <path className="svgc2-0" d="M319.41,63.64c-1.28,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33c1.28,0,2.33,1.04,2.33,2.33
                    S320.69,63.64,319.41,63.64z M319.41,59.88c-0.79,0-1.43,0.64-1.43,1.43s0.64,1.43,1.43,1.43c0.79,0,1.43-0.64,1.43-1.43
                    S320.2,59.88,319.41,59.88z"/>
            </g>
        </g>
    </g>
    <g id="shadows_23_">
        <ellipse className="svgc3-9 ci0" cx="214.69" cy="310.39" rx="54.81" ry="6.01"/>
        <ellipse className="svgc3-9 ci0" cx="160.09" cy="330.07" rx="37.3" ry="4.31"/>
    </g>
    <g id="flower_7_">
        <g>
            <path className="svgc1-3" d="M101.76,234.49c0.19-0.3,0.64-0.18,0.67,0.17c0.25,3.63,0.66,15.14-3.27,21.93
                c-2.71,4.68-10.71,12.88-15.87,15.07c-5.16,2.19-7.65,9.14-7.65,9.14s-3.67-17.45,7.8-28.9c5.51-5.75,7.16-8.67,11.39-10.5
                C98.01,240.01,100.65,236.27,101.76,234.49z"/>
            <path className="svgc1-5" d="M56.08,234.51c-0.18-0.16-0.46,0.03-0.38,0.26c0.48,1.51,1.36,5.07,0.62,9.07
                c-0.95,5.12-2.26,14.97-0.61,21.49c1.65,6.52,12.42,13.19,19.38,11.46c0,0-6.25-4.31-8.58-12.07c-2.33-7.76-0.62-15.81-4.03-22
                C59.94,238.1,57.28,235.52,56.08,234.51z"/>
            <g>
                <path className="svgc1-6" d="M75.72,228.82c-0.04-0.24,0.28-0.36,0.42-0.15c1.71,2.72,6.22,10.52,6.45,17.43
                    c0.3,8.54-10.19,27.18-6.41,34.78c0,0-8.91-5.53-9.89-15.78c-0.99-10.25,7.83-29.08,9.06-31.99
                    C75.97,231.63,75.88,229.92,75.72,228.82z"/>
                <path className="ci3" d="M76.18,280.88c0,0-3.58-9.44-4.27-14.31c-0.68-4.87,3.91-20.43,5.5-25.17c1.59-4.74-1.09-11.28-1.09-11.28"
                    />
                <path className="ci3" d="M70.2,246.61c0,0,1.27,6.4,3.6,7.23"/>
                <path className="ci3" d="M76.72,243.55c0,0,2.71,0.35,4.33-3.75"/>
                <path className="ci3" d="M76.06,245.73c0,0,2.65,0.65,5.72-2.74"/>
                <path className="ci3" d="M73.04,257.22c0,0,4.26-0.15,7.39-2.06"/>
                <path className="ci3" d="M66.65,262.52c0,0,0.83,4.67,5.45,5.38"/>
                <path className="ci3" d="M67.05,258.91c0,0,1.91,5.96,4.65,6.18"/>
            </g>
            <path className="ci4" d="M75.64,280.79c1.19-12.02,12.65-21.68,17.98-26.33c5.08-4.44,6.52-12.2,6.52-12.2"/>
            <path className="ci4" d="M75.08,276.78c0,0-11.03-1.95-14.3-13.16c-3.27-11.21,1.76-15.48-2.08-23.8"/>
        </g>
        <g id="flowers_pot_17_">
            <path className="svgc2-0" d="M78.61,302.02H63.63c-0.67,0-1.24-0.48-1.36-1.14l-3.94-21.69h25.59l-3.94,21.69
                C79.85,301.55,79.28,302.02,78.61,302.02z"/>
            <path className="svgc2-0" d="M83.57,279.89H59.36c-2.09,0-3.8-1.71-3.8-3.8l0,0c0-2.09,1.71-3.8,3.8-3.8h24.21c2.09,0,3.8,1.71,3.8,3.8
                l0,0C87.38,278.18,85.66,279.89,83.57,279.89z"/>
            <polygon className="svgc3-0 ci5" points="58.49,280.08 82.87,284.95 83.79,279.89 58.45,279.89 		"/>
        </g>
    </g>
    <ellipse className="svgc3-9 ci0" cx="70.95" cy="303.33" rx="12.75" ry="2.59"/>
    <g id="phone_7_">
        <path className="svgc1-0" d="M251.96,310.59h-74.47c-9.73,0-17.61-7.89-17.61-17.61V114.34c0-9.73,7.89-17.61,17.61-17.61h74.47
            c9.73,0,17.61,7.89,17.61,17.61v178.63C269.57,302.7,261.69,310.59,251.96,310.59z"/>
        <path className="svgc3-10" d="M254.48,298.14h-79.44c-4.5,0-8.15-3.65-8.15-8.15V116.15c0-4.5,3.65-8.15,8.15-8.15h79.44
            c4.5,0,8.15,3.65,8.15,8.15v173.84C262.63,294.49,258.98,298.14,254.48,298.14z"/>
        <path className="svgc3-10" d="M222.76,103.44h-16.11c-0.62,0-1.12-0.5-1.12-1.12l0,0c0-0.62,0.5-1.12,1.12-1.12h16.11
            c0.62,0,1.12,0.5,1.12,1.12l0,0C223.88,102.94,223.38,103.44,222.76,103.44z"/>
    </g>
    <g>
        <g>
            <path className="svgc1-1" d="M234.86,208.35h-38.92c-2.22,0-4.03-1.8-4.03-4.03l0,0c0-2.22,1.8-4.03,4.03-4.03h38.92
                c2.22,0,4.03,1.8,4.03,4.03l0,0C238.89,206.55,237.08,208.35,234.86,208.35z"/>
        </g>
        <g>
            <g>
                <path className="svgc2-0" d="M241.63,283.07h-54.25c-5.55,0-10.05-4.5-10.05-10.05l0,0c0-5.55,4.5-10.05,10.05-10.05h54.25
                    c5.55,0,10.05,4.5,10.05,10.05l0,0C251.68,278.57,247.18,283.07,241.63,283.07z"/>
            </g>
        </g>
        <g>
            <path className="svgc3-1" d="M250.01,219.03h-71.02c-0.77,0-1.4-0.63-1.4-1.4l0,0c0-0.77,0.63-1.4,1.4-1.4h71.02c0.77,0,1.4,0.63,1.4,1.4
                l0,0C251.41,218.4,250.79,219.03,250.01,219.03z"/>
            <path className="svgc3-1" d="M247.53,226.18h-68.58c-0.75,0-1.35-0.63-1.35-1.4l0,0c0-0.77,0.61-1.4,1.35-1.4h68.58
                c0.75,0,1.35,0.63,1.35,1.4l0,0C248.88,225.56,248.27,226.18,247.53,226.18z"/>
        </g>
        <g>
            <g>
                <g>
                    <path className="svgc3-1" d="M225.65,242.52c1.05,0,1.9,0.85,1.9,1.9c0,1.05-0.85,1.9-1.9,1.9c-1.05,0-1.9-0.85-1.9-1.9
                        C223.74,243.38,224.6,242.52,225.65,242.52 M225.65,241.47c-1.63,0-2.95,1.32-2.95,2.95c0,1.63,1.32,2.95,2.95,2.95
                        s2.95-1.32,2.95-2.95C228.6,242.8,227.28,241.47,225.65,241.47L225.65,241.47z"/>
                </g>
            </g>
            <g>
                <g>
                    <path className="svgc3-1" d="M214.5,242.52c1.05,0,1.9,0.85,1.9,1.9c0,1.05-0.85,1.9-1.9,1.9c-1.05,0-1.9-0.85-1.9-1.9
                        C212.6,243.38,213.45,242.52,214.5,242.52 M214.5,241.47c-1.63,0-2.95,1.32-2.95,2.95c0,1.63,1.32,2.95,2.95,2.95
                        c1.63,0,2.95-1.32,2.95-2.95C217.45,242.8,216.13,241.47,214.5,241.47L214.5,241.47z"/>
                </g>
            </g>
            <circle className="svgc3-1" cx="203.36" cy="244.42" r="2.95"/>
        </g>
        <circle className="svgc3-1" cx="215.4" cy="152.65" r="28.41"/>
    </g>
    <path className="svgc2-0" d="M228.31,159.71L228.31,159.71c-1.31,0-2.37-1.07-2.37-2.37v-11.08c0-1.31,1.07-2.37,2.37-2.37l0,0
        c1.31,0,2.37,1.07,2.37,2.37v11.08C230.68,158.64,229.62,159.71,228.31,159.71z"/>
    <path className="svgc2-0" d="M232.36,154.51L232.36,154.51c-1.31,0-2.37-1.07-2.37-2.37v-12.08c0-1.31,1.07-2.37,2.37-2.37l0,0
        c1.31,0,2.37,1.07,2.37,2.37v12.08C234.74,153.44,233.67,154.51,232.36,154.51z"/>
    <path className="svgc2-0" d="M226.81,145.83c5.45,1.82,11.19,7.76,17,7.06c0-0.08,0.01-0.16,0.01-0.23c0-15.69-12.72-28.41-28.41-28.41
        c-15.27,0-27.72,12.05-28.38,27.16c4.63-2.69,9.32-5.59,14.29-7.36C209.81,140.46,219.2,143.14,226.81,145.83z"/>
    <g>
        <path className="svgc3-1" d="M152.34,194.24l0.5,0.6c-0.73-0.88-0.39-2.33,0.77-3.24c0,0,16.07-14.6,21.67-19.22
            c5.7-4.69,23.93-17.25,23.93-17.25c1.16-0.91,2.73,1.55,1.58,2.46c0,0-16.07,14.79-21.76,19.27c-5.69,4.49-23.27,17.31-23.27,17.31
            C154.6,195.09,153.07,195.12,152.34,194.24z"/>
        <path className="svgc3-3" d="M194.38,163.44c-1.58-1.14-1.89-2.26-2.4-3.29c0.25-0.17,7.4-5.13,7.4-5.13c0.9-0.71,2.54,1.69,1.63,2.4
            C201.01,157.41,194.57,163.25,194.38,163.44z"/>
        <path className="svgc3-9" d="M213.95,147.51c-5.87-3.59-23.71,2.98-16.76,11.32c2.21,2.65,8.53,2.87,11.48-2.27
            c2.85-4.97,0.26-4.3,5.41-8.45C214.31,147.94,214.2,147.67,213.95,147.51z"/>
        <path className="svgc2-0" d="M208.67,156.57c2.85-4.97,0.26-4.3,5.41-8.45c0.23-0.18,0.11-0.45-0.13-0.6c-3.21-1.96-9.99-0.89-14.35,1.85
            c1.14,1.23,2.6,2.33,4.11,3c1.73,0.78,2.86,0.7,3.71,1.83c0.83,1.1,0.68,2.42,0.41,3.59C208.13,157.42,208.42,157.01,208.67,156.57
            z"/>
    </g>
    <g id="man_43_">
        <path className="ci1" d="M163.46,158.34c0,0,3.29,4.54,0.22,12.07c-0.24,0.58-4.46-0.13-4.46-0.13s0.36,2.22,0.82,3.61
            c0.17,0.52,0.62,1.93,0.47,2.42c-0.54,1.85-10.5,2.51-8.74-1.11c1.51-3.1,1.7-5.58,1.7-5.58S153.65,153.44,163.46,158.34z"/>
        <path className="svgc3-0" d="M144.71,277.86v4.19c0,0.53,0.35,0.95,0.79,0.95h11.8v-0.53c0-0.42-0.28-0.79-0.68-0.9
            c-1.69-0.48-3.9-1.86-6.08-3.83C146.95,274.51,144.71,277.86,144.71,277.86z"/>
        <path className="svgc3-0" d="M165.65,277.86v4.19c0,0.53,0.35,0.95,0.79,0.95h14.01v-0.53c0-0.42-0.28-0.79-0.68-0.9
            c-1.69-0.48-6.1-1.86-8.28-3.83C167.9,274.51,165.65,277.86,165.65,277.86z"/>
        <polygon className="svgc3-2" points="143.57,207.52 144.71,277.86 150.53,277.75 158.35,214.83 157.33,199.49 	"/>
        <path className="ci1" d="M154.99,183.24l-4.19-5.97c0,0-9.74,9.39-9.8,11.69c-0.11,4.18,15.1,11.17,15.1,11.17s3.09,6.11,3.18,7.21
            c0.1,1.1,1.15-4.97,1.7-7.14c0.34-1.33,2.63,0.36,3.17-0.1c0.53-0.46-4.35-4.23-5.43-3.67c-0.75,0.38-11.43-6.21-11.47-7.1
            C147.21,188.23,154.99,183.24,154.99,183.24z"/>
        <path className="ci1" d="M165.61,148.7"/>
        <polygon className="svgc3-1" points="149.72,207.71 165.65,277.87 171.63,277.87 167.62,207.52 	"/>
        <path className="svgc1-3" d="M159.37,173.63c0,0,3.26-0.96,6.25,8.08c2.99,9.04,2.16,26.41,2.16,26.41h-24.2c0,0-1.98-30.07,8.98-34.12
            c0,0,1.19,1.43,3.2,1.32C157.77,175.2,159.37,173.63,159.37,173.63z"/>
        <path className="ci1" d="M180.47,175.86c-0.09,1.36-4.26,11.64-5.14,11.77c-1.09,0.17-6.45-6.84-6.45-6.84l-5.46,4.84
            c0,0,10.43,8.62,12.72,8.42c4.03-0.35,6.95-15.87,7.41-16.72C182.22,176.46,180.47,175.86,180.47,175.86z"/>
        <path className="svgc1-5" d="M164.9,187.82c0,0-9.35-7.87-6.06-10.88c3.29-3,11.36,4.79,11.36,4.79S167.41,186.38,164.9,187.82z"/>
        <path className="svgc3-0" d="M165.38,153.36c-0.22,1.77-0.76,4.45-1.99,4.97c-0.01,0-0.01,0-0.01,0c-0.17-0.06-4.32-1.47-6.94,2.98
            c-2.53,4.3-2.4,8.03-2.4,8.03c-0.24-0.52-5.51-1.47-5.51-8.19c0-2.06,1.84-2.54,2.09-2.9c0.28-0.4,0.1-1.9,0.48-2.35
            c2.3-2.74,7.94-1.76,9.63-3.27C161.95,151.52,165.67,151.01,165.38,153.36z"/>
        <path className="ci1" d="M156.38,162.2c-0.03-0.15-0.13-0.28-0.27-0.33c-0.5-0.19-1.66-0.49-2,0.71c-0.38,1.31,1.1,2.37,2.21,2.45
            c0.28,0.02,0.5-0.24,0.45-0.51L156.38,162.2z"/>
        <path className="ci1" d="M183.22,173.12c-0.94,0.53-0.18-2.08-0.62-2.41c-0.26-0.19-0.95,1.54-1.82,2.89
            c-0.61,0.94-0.3,2.15-0.31,2.26c0.79,0.86,2.02,0.99,3.11,1.47c0.05-0.23-0.02-0.58,0.56-0.79c0.36-0.14,1.25-0.59,1.43-0.93
            c0.62-1.19,0.56-3.61,0.57-4.89C186.14,170.54,184.7,172.28,183.22,173.12z"/>
    </g>
    <g id="girl_42_">
        <path className="ci1" d="M315.42,249.04c0,0,6.95,11.36,5.97,13.25c-0.98,1.88-19.78,3.14-19.78,3.14l0.4-3.1
            c0,0,13.59-1.69,13.91-2.22c0.32-0.53-6.49-11.15-6.49-11.15L315.42,249.04z"/>
        <polygon className="svgc3-1" points="320.78,257.76 315.35,259.49 311.55,250.58 316.87,249.31 	"/>
        <g>
            
                <rect x="267.09" y="225.33" transform="matrix(0.5951 -0.8036 0.8036 0.5951 -74.6347 307.2106)" className="svgc3-0" width="0.94" height="4.69"/>
            <path className="svgc1-6" d="M322.6,271.64l3.09-4.17c0.62-0.84,0.44-2.02-0.39-2.64l-44.64-33.06l-5.33,7.2l44.64,33.06
                C320.8,272.65,321.98,272.48,322.6,271.64z"/>
            <polygon className="svgc1-6" points="280.64,231.78 269.11,227.23 267.62,229.24 275.31,238.99 		"/>
            
                <rect x="282.63" y="230.78" transform="matrix(-0.5951 0.8036 -0.8036 -0.5951 652.564 155.5019)" className="svgc1-0" width="8.95" height="22.72"/>
            <path className="svgc1-0" d="M322.6,271.64l3.08-4.16c0.62-0.84,0.44-2.02-0.39-2.64l-4.57-3.38l-5.33,7.19l4.57,3.38
                C320.8,272.65,321.98,272.48,322.6,271.64z"/>
        </g>
        <path className="svgc3-1" d="M310.9,240.82c2.01,0,4.7,3.07,4.1,8.07c-0.6,5-2.61,16.4-2.61,16.4h-13.94c0,0-2.16-19.91-0.52-21.48
            c1.64-1.57,3.65-3,3.65-3H310.9"/>
        <path className="ci1" d="M297.41,252.52c0,0-9.31,9.16-11.23,8.24c-1.92-0.91-8.29-16.26-8.29-16.26l2.39-0.49
            c0,0,6.83,11.1,7.37,11.41c0.54,0.3,9.63-8.9,9.63-8.9L297.41,252.52z"/>
        <path className="svgc3-1" d="M304.11,240.55c-4.92-2.4-9.88,7.83-16.15,14.15l2.23,4.98l14.66-13.84
            C304.85,245.84,305.15,241.06,304.11,240.55z"/>
        <path className="ci1" d="M296.28,226.59c0,0-1.51,4.41,1.03,9.1c0.15,0.29,2.12-0.04,2.78,0.71c0.66,0.75,1.64,3.99,1.64,3.99
            l3.5-7.53l-5.14-8.2L296.28,226.59z"/>
        <path className="svgc3-0" d="M298.67,221.66c1.82-0.71,3.47-1.26,4.45-1.11c1.21,0.18,3.07,1.24,4.54,2.62c0.91,0.86,2.41,0.54,3.33,1.22
            c0.74,0.54,0.9,2.05,1.67,2.83c1.66,1.69,3.55,1.38,4.22,2.73c0.84,1.69-0.96,2.96-0.41,5.67c0.31,1.54,0.87,0.95,1.79,3.35
            c1.05,2.72-1.64,3.81-0.4,4.85c0.97,0.81,2.73,0.91,3.22,1.73c0.59,0.99,0.36,2.96-1.04,3.6c-1.03,0.48-2.34,0.4-3.26,0.78
            c-1.35,0.56-0.82,1.58-2.35,2.5c-1.03,0.62-3.78,0.85-4.59-0.26c-0.85-1.17-0.09-2.3-0.89-2.98c-1.04-0.88-2.82,0.6-3.8-0.22
            c-1.03-0.87,0.26-3.1-0.82-4.03c-0.96-0.82-2.71,0.32-3.13-0.3c-0.49-0.71-0.96-2.3-0.65-4.7c0.18-1.36,1.37-0.81,0.24-4.18
            c-0.79-2.34,0.22-3.26-0.56-4.62c-0.71-1.24-1.39-2.33-2.39-2.91c-2.34-1.34-2.33-0.52-2.88-2.83
            C294.47,223.38,297.02,222.3,298.67,221.66z"/>
        <path className="ci1" d="M299.12,230.6c-0.02-0.13,0.03-0.26,0.13-0.34c0.35-0.28,1.21-0.81,1.79,0.06c0.64,0.96-0.29,2.19-1.16,2.53
            c-0.22,0.09-0.46-0.06-0.49-0.3L299.12,230.6z"/>
        <path className="ci1" d="M305.01,265.29h6.93c0,0,4.92,5.74,4.25,10.92c-0.72,5.51-1.72,61.47-1.72,61.47l-4.45-0.09l-3.6-44.25
            L305.01,265.29"/>
        <path className="ci1" d="M310.24,277.18c-2.38,6.74-11.79,60.44-11.79,60.44h-4.54l6.08-72.06L310.24,277.18z"/>
        <path className="svgc1-3" d="M312.39,265.29c0,0,4.48,3.8,4.48,10.07c0,6.32-1.1,22.73-0.82,26.73c0.02,0.26-0.19,0.49-0.45,0.49l-19.53,0
            c-0.34,0-0.6-0.29-0.57-0.62l2.95-36.66H312.39z"/>
        <g>
            <path className="ci1" d="M306.72,339.49c1.21-0.5,2.47-1.14,3.3-1.89c0.17-0.15,0.34-0.29,0.5-0.4l1.48,0.49
                C311.99,337.69,309.08,339.83,306.72,339.49z"/>
            <path className="svgc3-0" d="M303.7,340.52c0.64-0.18,1.81-0.54,3.02-1.03c1.68,0.24,3.24-0.77,4-1.37c0.35-0.28,0.77-0.43,1.21-0.43h2.54
                v3.2c0,0.4-0.27,0.73-0.6,0.73h-0.91c-0.32,0-0.58-0.26-0.58-0.58v-0.85c0,0-0.64,0.79-2,1.32c-0.19,0.08-0.41,0.11-0.61,0.11
                h-6.6v-0.4C303.18,340.89,303.39,340.61,303.7,340.52z"/>
        </g>
        <g>
            <path className="ci1" d="M290.63,339.49c1.21-0.5,2.47-1.14,3.3-1.89c0.17-0.15,0.34-0.29,0.5-0.4l3.95,0.49
                C298.39,337.69,293,339.83,290.63,339.49z"/>
            <path className="svgc3-0" d="M287.61,340.52c0.64-0.18,1.81-0.54,3.02-1.03c1.86,0.27,4.29-1,5.24-1.56c0.27-0.16,0.59-0.25,0.9-0.25h1.61
                v3.2c0,0.4-0.27,0.73-0.6,0.73h-0.91c-0.32,0-0.58-0.26-0.58-0.58v-0.85c0,0-0.64,0.79-2,1.32c-0.2,0.08-0.41,0.11-0.61,0.11h-6.6
                v-0.4C287.09,340.89,287.31,340.61,287.61,340.52z"/>
        </g>
        <path className="ci1" d="M278.56,241.49c0.62,0.48,0.63-1.38,1.02-1.49c0.39-0.12,0.75,3.83,0.71,4.01c-0.8,0.11-1.53,0.43-2.39,0.49
            c0.02-0.17-0.46-0.61-0.8-0.9c-0.21-0.18-0.35-0.43-0.39-0.71c-0.13-0.97-0.46-3.22-0.85-3.52
            C275.36,238.99,277.51,240.68,278.56,241.49z"/>
    </g>
    <ellipse className="svgc3-9 ci0" cx="301.25" cy="341.62" rx="25.73" ry="3.8"/>
    <g id="girl_41_">
        <path className="ci1" d="M238.78,46.23c0,0-1.31,9.33-4.04,9.77c-2.73,0.44-8.46-3.92-8.46-3.92l5.3-9.01L238.78,46.23z"/>
        <path className="ci1" d="M227.55,72.09c0,0-0.32,7.9,1.27,9.31c1.59,1.41,15.48-4.46,15.48-4.46l-0.97-2.51c0,0-11.14,3.44-11.63,3.06
            c-0.49-0.38,1.42-7.62,1.42-7.62L227.55,72.09z"/>
        <path className="svgc3-0" d="M224.64,53.13c0,0-3.2-0.97-4.48,1.03c-1.28,2-1.97,6.05-4.36,6.83c-2.39,0.78-3.8,5.13-2.66,8.01
            c1.14,2.88,1.56,4.03,1.13,5.61c-0.43,1.59,4.56,3.73,7.26,0.31c2.7-3.42,7.52-14.26,6-18.14
            C226.01,52.91,224.64,53.13,224.64,53.13z"/>
        <path className="svgc3-1" d="M226.93,53.95L226.93,53.95c0.23-0.42,0.07-0.94-0.34-1.16l-1.11-0.61c-0.42-0.23-0.94-0.07-1.16,0.34l0,0
            c-0.23,0.42-0.07,0.94,0.34,1.16l1.11,0.61C226.18,54.52,226.7,54.36,226.93,53.95z"/>
        <path className="ci1" d="M227.71,51.96c0,0-3.03,5.74-3.97,6.22c-0.12,0.06,0.7,3.44,3.6,3.72c4.04,0.39,4.22-2.16,4.22-2.16
            s-0.93-0.97-0.97-1.07c-0.52-1.15,0.77-4.85,0.77-4.85L227.71,51.96z"/>
        <path className="svgc3-0" d="M257.4,128.47l-0.12,3.2c-0.02,0.4,0.24,0.74,0.57,0.75l10.68,0.41l0.02-0.4c0.01-0.32-0.19-0.61-0.49-0.71
            c-1.28-0.41-4.51-1.5-6.12-3.07C259.3,126.07,257.4,128.47,257.4,128.47z"/>
        <path className="svgc1-3" d="M218.28,68.28c0.96-8.1,1.37-9.43,5.46-10.09c0,0,2.92,3.42,5.39,3.16c1.36-0.15,1.46-2.67,1.46-2.67
            s0.6,0.58,2.23,2.16c2.6,2.53,5.22,6.19,2.23,8.73c-0.9,0.77,1.48,13.37,1.48,13.37l-14.35,1.55
            C222.17,84.48,217.69,73.27,218.28,68.28z"/>
        <path className="svgc3-2" d="M236.52,82.93c0,0,15.75,4.83,21.62,7.56c1.19,0.55,2.1,1.63,2.28,2.93c0.94,6.78,1.52,35.22,1.52,35.22
            l-4.54-0.17l-3.2-26.57l-28.73-17.78L236.52,82.93z"/>
        <path className="ci1" d="M218.47,73.01c0,0,0.88,7.93,2.78,8.87c1.9,0.94,13.96-7.74,13.96-7.74l-1.26-2.19c0,0-10.1,5.61-10.67,5.37
            c-0.57-0.24-0.02-7.9-0.02-7.9L218.47,73.01z"/>
        <path className="svgc1-5" d="M221.33,59.67c1.94,0.85,2.38,5.15,2.19,12.37c0,0.14-0.1,0.26-0.24,0.3l-4.94,0.83
            c-0.12,0.03-0.24-0.05-0.25-0.17c-0.17-1.44-0.78-9,0.56-11.32C219.23,60.67,220.27,59.2,221.33,59.67z"/>
        <path className="svgc3-0" d="M237.56,40.94c-1.64,0.4-1.77-2.68-7-2.11c-3.63,0.39-4.79,2.06-6.58,6.41c-2.48,6.01,1.87,8.64,3.42,8.11
            c3.77-1.29,3.52-3.57,3.52-3.57s1.18-2.39,1.42-2.33c2.87,0.76,6.13-1,6.13-1s0.15,2.15,1.39,1.93s1.12-2.88,0.94-4.04
            C240.62,43.18,239.23,40.54,237.56,40.94z"/>
        <path className="svgc3-0" d="M246.64,76.84l-9.47,1.02c-0.17,0.02-0.28-0.18-0.17-0.31l10.66-12.9c0.03-0.04,0.08-0.07,0.13-0.07l9.47-1.02
            c0.17-0.02,0.28,0.18,0.17,0.31l-10.66,12.9C246.73,76.81,246.69,76.84,246.64,76.84z"/>
        <path className="ci1" d="M249.17,74.19c0.16-0.4-0.42-1.25-0.42-1.25c-0.03-0.07-0.15-0.04-0.26,0.06c-0.4,0.38-1.36,1.27-1.37,1.47
            c-0.08,0.78,0.17,1.67,0.35,1.6C247.96,75.89,249.04,74.51,249.17,74.19z"/>
        <path className="ci1" d="M234.73,74.6c-1.52,0.97-2.66-1.42-1.01-2.61c0.8-0.58,2.1-3.18,6.01-1.84c0.26,0.09,0.34,1.3,0.39,1.81
            c0.01,0.14-0.09,0.26-0.23,0.26c0,0-3.69-0.28-3.17,0.33c0.05,0.06,2.71,0.35,0.43,0.89C234.84,74,235.3,74.24,234.73,74.6z"/>
        <path className="svgc3-0" d="M237.79,125.38l-1.89,2.68c-0.24,0.32-0.22,0.74,0.05,0.94l8.59,6.37l0.24-0.32c0.19-0.26,0.19-0.61-0.01-0.86
            c-0.82-1.06-2.87-3.79-3.32-5.99C240.73,124.58,237.79,125.38,237.79,125.38z"/>
        <path className="ci1" d="M232.53,48.02c0.04-0.12,0.01-0.26-0.07-0.36c-0.3-0.34-1.05-1.01-1.77-0.25c-0.79,0.83-0.1,2.21,0.7,2.69
            c0.2,0.12,0.46,0.02,0.54-0.21L232.53,48.02z"/>
        <path className="svgc3-1" d="M222.41,84.45c-0.15,0.02-0.27,0.12-0.3,0.26c-0.44,1.66-2.71,11.5,4.73,12c9.02,0.61,17.68,2.62,19.57,3.08
            c0.2,0.05,0.32,0.26,0.25,0.46l-8.77,24.88c-0.05,0.15,0,0.31,0.13,0.4l3.14,2.29c0.17,0.13,0.42,0.07,0.52-0.12l14.18-25.78
            c0.01-0.02,0.02-0.03,0.02-0.05c0.14-0.41,1.82-5.48-3.14-8.19c-4.95-2.69-18.97-9.66-20.22-10.28c-0.06-0.03-0.12-0.04-0.19-0.03
            L222.41,84.45z"/>
    </g>
    <g id="stairs_15_">
        <g>
            <g>
                <path className="svgc3-6" d="M155.01,329.91c-0.31,0-0.59-0.21-0.65-0.53l-13.25-45.04c-0.08-0.36,0.15-0.72,0.51-0.8
                    c0.36-0.08,0.72,0.15,0.8,0.51l13.25,45.04c0.08,0.36-0.15,0.72-0.51,0.8C155.1,329.9,155.05,329.91,155.01,329.91z"/>
            </g>
            <g>
                <path className="svgc3-6" d="M193.14,329.91c-0.31,0-0.59-0.22-0.66-0.54l-12.68-45.94c-0.07-0.36,0.16-0.72,0.52-0.79
                    c0.37-0.07,0.72,0.16,0.79,0.52l12.68,45.94c0.07,0.36-0.16,0.72-0.52,0.79C193.23,329.9,193.19,329.91,193.14,329.91z"/>
            </g>
        </g>
        <g>
            <g>
                <path className="svgc3-3" d="M166.48,329.91c-0.05,0-0.1-0.01-0.15-0.02c-0.36-0.08-0.59-0.44-0.51-0.8l13.9-45.94
                    c0.08-0.36,0.44-0.59,0.8-0.51c0.36,0.08,0.59,0.44,0.51,0.8l-13.9,45.94C167.06,329.69,166.78,329.91,166.48,329.91z"/>
            </g>
            <g>
                <path className="svgc3-3" d="M129.41,329.57c-0.05,0-0.09,0-0.14-0.01c-0.36-0.07-0.6-0.42-0.53-0.79l12.22-45.64
                    c0.07-0.36,0.42-0.6,0.79-0.53c0.36,0.07,0.6,0.42,0.53,0.79l-12.22,45.64C129.99,329.34,129.72,329.56,129.41,329.57z"/>
            </g>
            <g>
                <path className="svgc3-3" d="M141.09,283.29c-0.01-0.43,0.33-0.69,0.75-0.7h38.61c0.43-0.01,0.78,0.34,0.78,0.77
                    c0.01,0.43-0.34,0.78-0.77,0.78h-38.61C141.86,284.15,141.09,283.72,141.09,283.29z"/>
            </g>
            <g>
                <path className="svgc3-3" d="M175.41,298.82h-37.14c-0.42,0-0.76-0.35-0.76-0.77c0-0.43,0.34-0.77,0.76-0.77h37.14
                    c0.42,0,0.76,0.35,0.76,0.77C176.16,298.47,175.82,298.82,175.41,298.82z"/>
            </g>
            <g>
                <path className="svgc3-3" d="M170.75,313.49h-36.92c-0.42,0-0.76-0.35-0.76-0.78s0.34-0.78,0.76-0.78h36.92c0.42,0,0.76,0.35,0.76,0.78
                    S171.17,313.49,170.75,313.49z"/>
            </g>
        </g>
    </g>
</svg>
)
}
