import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, useDispatch } from 'src/store';
import type { Contact } from 'src/types/api/contact';
import type { User } from 'src/types/api/user';
import type { Permission } from 'src/types/api/permission';
import type { Group } from 'src/types/api/group';
import type { Role } from 'src/types/api/role';
import type { Template } from 'src/types/api/template';
import type { Presentation } from 'src/types/api/presentation';
import type { Task } from 'src/types/api/project';

type TCurrentState = {
  contact: Contact | null;
  user: User | null;
  permission: Permission | null;
  group: Group | null;
  groupRoles: Role[] | null;
  role: Role | null;
  template: Template | null;
  templatePermissions: Permission[] | null;
  // presentation: Presentation | null
  presentation: Presentation | null;
  task: Task | null;
}

const initialState: TCurrentState = {
  contact: null,
  user: null,
  permission: null,
  group: null,
  groupRoles: null,
  role: null,
  template: null,
  templatePermissions: null,
  presentation: null,
  task: null
};

const slice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    setCurrentContact(state: TCurrentState, action: PayloadAction<Contact | null>){
      return {
        ...state,
        contact: action.payload
      };
    },
    setCurrentUser(state: TCurrentState, action: PayloadAction<User | null>){
      return {
        ...state,
        user: action.payload
      };
    },
    // is this used ???
    setCurrentUserContact(state: TCurrentState, action: PayloadAction<Contact>){
      if(state.user){
        state.user.contact = action.payload;
      }
    },
    setCurrentPermission(state: TCurrentState, action: PayloadAction<Permission | null>){
      return {
        ...state,
        permission: action.payload
      };
    },
    setCurrentGroup(state: TCurrentState, action: PayloadAction<Group | null>){
      return {
        ...state,
        group: action.payload
      };
    },
    setCurrentGroupRoles(state: TCurrentState, action: PayloadAction<Role[] | null>){
      return {
        ...state,
        groupRoles: action.payload
      };
    },
    setCurrentRole(state: TCurrentState, action: PayloadAction<Role | null>){
      return {
        ...state,
        role: action.payload
      };
    },
    setCurrentTemplate(state: TCurrentState, action: PayloadAction<Template | null>){
      return {
        ...state,
        template: action.payload
      };
    },
    setCurrentTemplatePermissions(state: TCurrentState, action: PayloadAction<Permission[] | null>){
      return {
        ...state,
        templatePermissions: action.payload
      };
    },
    setCurrentPresentation(state: TCurrentState, action: PayloadAction<Presentation | null>){
      return {
        ...state,
        presentation: action.payload
      };
    },
    setCurrentTask(state: TCurrentState, action: PayloadAction<Task | null>){
      return {
        ...state,
        task: action.payload
      };
    },
  }
})

export const { reducer } = slice;

export const setCurrentContact = (data: Contact | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentContact(data));
}

export const setCurrentUser = (data: User | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentUser(data));
}

export const setCurrentUserContact = (data: Contact): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserContact(data));
}

export const setCurrentPermission = (data: Permission | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentPermission(data));
}
export const setCurrentGroup = (data: Group | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentGroup(data));
}
export const setCurrentGroupRoles = (data: Role[] | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentGroupRoles(data));
}

export const setCurrentRole = (data: Role | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentRole(data));
}

export const setCurrentTemplate = (data: Template | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentTemplate(data));
}

export const setCurrentTemplatePermissions = (data: Permission[] | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentTemplatePermissions(data));
}

export const setCurrentPresentation = (data: Presentation | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentPresentation(data));
}

export const setCurrentTask = (data: Task | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentTask(data));
}

export const currentActionsMap = {
  'SET_CURRENT_CONTACT': setCurrentContact,
  'SET_CURRENT_USER': setCurrentUser,
  'SET_CURRENT_PERMISSION': setCurrentPermission,
  'SET_CURRENT_GROUP': setCurrentGroup,
  'SET_CURRENT_ROLE': setCurrentRole,
  'SET_CURRENT_TEMPLATE': setCurrentTemplate,
  'SET_CURRENT_TEMPLATE_PERMISSIONS': setCurrentTemplatePermissions,
  'SET_CURRENT_PRESENTATION': setCurrentPresentation,
  // Group roles ???
  // current task ?!
}

export default slice;