import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, FocusEvent, useState } from "react";
import { useTranslation } from 'src/i18n';
import { AddressAutofillController } from "./useAddressAutofillController";

interface Props {
  value: string;
  onChange: (value: string) => void;
  textFieldProps?: TextFieldProps;
  addressAutofillController?: AddressAutofillController;
  zipValue?: string;
}

const normalize = (value: string) => value.replace(/[^0-9a-zA-Z]/g, '');

const HouseNumber = ({
  value,
  onChange,
  textFieldProps,
  addressAutofillController,
  zipValue = ''
}: Props) => {
  const {t} = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if(!changedAfterLastFocus){
      setChangedAfterLastFocus(true);
    }
    onChange(normalize(e.target.value));
  }

  const [changedAfterLastFocus, setChangedAfterLastFocus] = useState(false);
  
  const handleFocus = () => {
    setChangedAfterLastFocus(false);
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(!changedAfterLastFocus){
      return;
    }

    if(addressAutofillController){
      addressAutofillController.handleNewZip(zipValue, value);
    }

    if(textFieldProps?.onBlur){
      textFieldProps.onBlur(e);
    }

    setChangedAfterLastFocus(false);
  }

  return (
    <TextField 
      variant='standard'
      label={`${t('lang.number_addon')}`}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      fullWidth
      {...textFieldProps}
      inputProps={{ 
        maxLength: '5', 
        ...textFieldProps?.inputProps
      }}
      onBlur={handleBlur}
    />
  )
}
export default HouseNumber;