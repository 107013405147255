import type { FormValues } from './types';

export const DEFAULT_VALUES: FormValues = {
  title: '',
  description: '',
  subtasks: [],
  member_ids: [],
  all_users: false,
  priority: 2,
  start_date: null,
  end_date: null,
  all_day: false,
  recurring: false,
  contact: null,
  activity: [],
  tags: [],

  stepId: null,
  file_ids: [],
  archived_at: null
}