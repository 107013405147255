import type { FormValues } from './types';

export const DEFAULT_VALUES: FormValues = {
  title: '',
  all_day: false,
  // start: null,
  // end: null,
  start: null,
  end: null,
  user_ids: [],
  all_users: true
}