import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { RoleSmall as TRole } from 'src/types/api/role';

const initialState: TRole[] = [];

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles(state: TRole[], action: PayloadAction<TRole[]>){
      return action.payload;
    },
    createRole(state: TRole[], action: PayloadAction<TRole>){
      state.push(action.payload);
    },
    updateRole(state: TRole[], action: PayloadAction<TRole>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteRole(state: TRole[], action: PayloadAction<TRole>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setRoles = (data: TRole[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRoles(data));
}

export const createRole = (data: TRole): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createRole(data));
}

export const updateRole = (data: TRole): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRole(data));
}

export const deleteRole = (data: TRole): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteRole(data));
}

export default slice;