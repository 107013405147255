import { useTranslation } from 'src/i18n';
import { useSelector, useDispatch } from "src/store";
import { FormControlLabel, Switch, TextField, Typography, Badge } from "@mui/material";
import { useAxiosOptions } from "src/hooks/general/useAxios";
import axios from 'axios';
import type { User } from 'src/types/api/user';
import { setGeneralUser } from "src/slices/general/user";
import { userToSmall as convertUserToSmall } from 'src/custom/resourceConverter';
import { updateUser } from 'src/slices/lists/users';
import { useEffect, useState } from "react";
import DynamicIcon from "src/components/general/DynamicIcon";

interface SupportBadgeWrapperProps {
  children: React.ReactChild | React.ReactChild[];
}
// Not finished
export const SupportBadgeWrapper = ({
  children
}: SupportBadgeWrapperProps) => {
  const generalUser = useSelector(state => state.general.user);
  if(generalUser?.support){
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={(
          <span>
            <DynamicIcon
              icon='mdiFaceAgent' 
              // iconProps={{ size: '1.2rem' }}
            />
          </span>
        )}
        color="info"
      >
        {children}
      </Badge>
    )
  }
  return (
    <>
      {children}
    </>
  );
}

const SupportToggle = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();
  const generalUser = useSelector(state => state.general.user);

  const [submitting, setSubmitting] = useState(false);

  const [supportToggled, setSupportToggled] = useState(generalUser ? generalUser.support : false);
  useEffect(() => {
    if(generalUser && typeof(generalUser.support) !== 'undefined'){
      setSupportToggled(generalUser.support);
    }
  }, [generalUser?.support]);

  const handleToggleSupport = () => {
    if(!generalUser){
      return;
    }

    setSubmitting(true);

    const backup = supportToggled;
    setSupportToggled(!supportToggled);

    axios.get<User>(`users/${generalUser.id}/support`, axiosOptions.apiConfig)
    .then((response) => {
      dispatch(setGeneralUser(response.data));
      dispatch(updateUser(convertUserToSmall(response.data)));
    })
    .catch((error) => {
      setSupportToggled(backup);
    }).finally(() => {
      setSubmitting(false);
    });
  }

  // FONTSIZE 0.9REM !!!
  return (
    <FormControlLabel
      onChange={handleToggleSupport}
      control={(
        <span style={{ marginLeft: 10 }}>
          <Switch 
            size='small' 
            checked={supportToggled}
            disabled={submitting}
          />
        </span>
      )}
      label={(
        <Typography variant='body2'>
          {t('lang.customer_support')}
        </Typography>
      )}
      labelPlacement='start'
      style={{ marginLeft: 0, marginTop: 8 }}
      disabled={submitting}
    />
  )

}
export default SupportToggle;