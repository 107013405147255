import { createFolder, updateFolder, deleteFolder } from 'src/slices/lists/folders';
import type { AppDispatch } from 'src/store/index';
import type { Folder } from 'src/types/api/folder';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_folder', (data: any) => {
    const folder = data.data.response as Folder;
    dispatch(createFolder(folder));
  });
  channel.listen('.update_folder', (data: any) => {//
    const folder = data.data.response as Folder;
    dispatch(updateFolder(folder));
  });
  channel.listen('.delete_folder', (data: any) => {//
    const folder = data.data.response as Folder;
    dispatch(deleteFolder(folder));
  });
}