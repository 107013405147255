import { Helmet } from "react-helmet-async";
import { useSelector } from "./store";

interface Props{
    group_id: string;
    group_name: string;
}

const WebIconProvider = ({group_id, group_name}:Props) => {
    return(
        <Helmet>
            <link rel="icon" type="image/png" href={`storage/profiles/${group_id}favicon.png`}/>
            <link rel="apple-touch-icon" sizes="57x57" href={`storage/profiles/${group_id}/pwa/apple-icon-57x57.png`}/>
            <link rel="apple-touch-icon" sizes="60x60" href={`storage/profiles/${group_id}/pwa/apple-icon-60x60.png`}/>
            <link rel="apple-touch-icon" sizes="72x72" href={`storage/profiles/${group_id}/pwa/apple-icon-72x72.png`}/>
            <link rel="apple-touch-icon" sizes="76x76" href={`storage/profiles/${group_id}/pwa/apple-icon-76x76.png`}/>
            <link rel="apple-touch-icon" sizes="114x114" href={`storage/profiles/${group_id}/pwa/apple-icon-114x114.png`}/>
            <link rel="apple-touch-icon" sizes="120x120" href={`storage/profiles/${group_id}/pwa/apple-icon-120x120.png`}/>
            <link rel="apple-touch-icon" sizes="144x144" href={`storage/profiles/${group_id}/pwa/apple-icon-144x144.png`}/>
            <link rel="apple-touch-icon" sizes="152x152" href={`storage/profiles/${group_id}/pwa/apple-icon-152x152.png`}/>
            <link rel="apple-touch-icon" sizes="180x180" href={`storage/profiles/${group_id}/pwa/apple-icon-180x180.png`}/>
            <link rel="icon" type="image/png" sizes="192x192"  href={`storage/profiles/${group_id}/pwa/android-icon-192x192.png`}/>
            <link rel="icon" type="image/png" sizes="32x32" href={`storage/profiles/${group_id}/pwa/favicon-32x32.png`}/>
            <link rel="icon" type="image/png" sizes="96x96" href={`storage/profiles/${group_id}/pwa/favicon-96x96.png`}/>
            <link rel="icon" type="image/png" sizes="16x16" href={`storage/profiles/${group_id}/pwa/favicon-16x16.png`}/>
            <link rel="manifest" href={`storage/profiles/${group_id}/pwa/manifest.json`}/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="msapplication-TileImage" content={`storage/profiles/${group_id}/pwa/ms-icon-144x144.png`}/>
            <meta name="theme-color" content="#ffffff"/>  
            <meta name="application-name" content={`${group_name}`}/>
        </Helmet>
    )
}

export default WebIconProvider;