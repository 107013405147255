import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import LoadingScreen from 'src/pages/other/misc/Loading';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LoadablePreLogin = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen hideBackground />}>
    <Component {...props} />
  </Suspense>
);

// AUTH
const Login = LoadablePreLogin(lazy(() => import('src/pages/other/auth/LoginView')));
const Logout = LoadablePreLogin(lazy(() => import('src/pages/other/auth/LogoutView')));
const SignUp = LoadablePreLogin(lazy(() => import('src/pages/other/auth/SignUp')));
const DeviceRegister = LoadablePreLogin(lazy(() => import('src/pages/other/auth/DeviceRegister')));
const ForgotPassword = LoadablePreLogin(lazy(() => import('src/pages/other/auth/passwordReset/ForgotPassword')));
const PasswordReset = LoadablePreLogin(lazy(() => import('src/pages/other/auth/passwordReset/PasswordReset')));

// MISC
const Dashboard = Loadable(lazy(() => import('src/pages/main/Dashboard')));
const Error404View = Loadable(lazy(() => import('src/pages/other/misc/Error404View')));

// CONTACTS
const ContactsList = Loadable(lazy(() => import('src/pages/main/contacts/ContactsList')));
const ContactView = Loadable(lazy(() => import('src/pages/main/contacts/ContactView')));

// NOTES
const NotesList = Loadable(lazy(() => import('src/pages/main/NotesList')));

// USERS
const UsersList = Loadable(lazy(() => import('src/pages/main/users/UsersList')));
const UserView = Loadable(lazy(() => import('src/pages/main/users/UserView')));

// SETTINGS
const SettingsView = Loadable(lazy(() => import('src/pages/main/Settings')));
const SystemSettingsList = Loadable(lazy(() => import('src/pages/core/systemSettings/SettingsList')));
const SystemSettingsCategoriesList = Loadable(lazy(() => import('src/pages/core/systemSettings/CategoriesList')));

// const InvoiceWorkflowCreate = Loadable(lazy(() => import('src/pages/settings/Financial/ProfilesSettings/Workflows/WorkflowInvoiceCreate')));
// const InvoiceWorkflowUpdate = Loadable(lazy(() => import('src/pages/settings/Financial/ProfilesSettings/Workflows/WorkflowInvoiceEdit')));

// DEVICES
const DevicesList = Loadable(lazy(() => import('src/pages/main/devices/DeviceList')));

// PRESENTATIONS
const PresentationsList = Loadable(lazy(() => import('src/pages/main/presentations/PresentationList')));
const PresentationCreate = Loadable(lazy(() => import('src/pages/main/presentations/PresentationCreate')));
const PresentationUpdate = Loadable(lazy(() => import('src/pages/main/presentations/PresentationUpdate')));

// ROLES
const RolesList = Loadable(lazy(() => import('src/pages/main/roles/RolesList')));
const RolePermissionsView = Loadable(lazy(() => import('src/pages/main/roles/RolePermissionsView')));

// TEMPLATES
const TemplateList = Loadable(lazy(() => import('src/pages/core/templates/TemplateList')));
const TemplateView = Loadable(lazy(() => import('src/pages/core/templates/TemplateView')));
const TemplateCreate = Loadable(lazy(() => import('src/pages/core/templates/NewTemplate')));
const TemplateUpdate = Loadable(lazy(() => import('src/pages/core/templates/EditTemplate')));

// GROUPS
const GroupsList = Loadable(lazy(() => import('src/pages/core/groups/GroupList')));
const GroupView = Loadable(lazy(() => import('src/pages/core/groups/GroupView')));

// LANGUAGE
const LanguagesList = Loadable(lazy(() => import('src/pages/core/language/LangList')));
const LanguageCreate = Loadable(lazy(() => import('src/pages/core/language/NewLang')));
const LanguageUpdate = Loadable(lazy(() => import('src/pages/core/language/EditLang')));

// PERMISSIONS
const PermissionsList = Loadable(lazy(() => import('src/pages/core/permissions/rules/PermissionsList')));
const PermissionView = Loadable(lazy(() => import('src/pages/core/permissions/rules/PermissionView')));

// PERMISSION CATEGORIES
const PermissionCategoriesList = Loadable(lazy(() => import('src/pages/core/permissions/categories/CategoriesList')));

// TIME_REGISTRATION
const TimeRegistration = Loadable(lazy(() => import('src/pages/main/TimeRegistration')));

// OTHER
const TestPage1 = Loadable(lazy(() => import('src/pages/other/Test/Test1')));
const TestPage2 = Loadable(lazy(() => import('src/pages/other/Test/Test2')));
const TestPage3 = Loadable(lazy(() => import('src/pages/other/Test/Test3')));
const TestPage4 = Loadable(lazy(() => import('src/pages/other/Test/Test4')));
const TestPage6 = Loadable(lazy(() => import('src/pages/other/Test/Test6')));
const TestPage7 = Loadable(lazy(() => import('src/pages/other/Test/Test7')));
const TestPage8 = Loadable(lazy(() => import('src/pages/other/Test/Test8')));

// DEVICE 
// NAAM LATER VERANDEREN !!!
const DeviceComponent = Loadable(lazy(() => import('src/pages/other/Device')));

// FILES
const FilesComponent = Loadable(lazy(() => import('src/pages/main/FilesList')));

// AGENDA
// const AppointmentsComponent = Loadable(lazy(() => import('src/pages/other/appointments/Appointments')));
// const AppointmentCreate = Loadable(lazy(() => import('src/pages/other/appointments/AppointmentCreate')));

// PROFILE
const ProfileComponent = Loadable(lazy(() => import('src/pages/other/Profile')));

// PROJECTS
const ProjectsList = Loadable(lazy(() => import('src/pages/main/projects/ProjectsList')));

// CALENDAR
// const CalendarView = Loadable(lazy(() => import('src/pages/main/Calendar')));
const CalendarOld = Loadable(lazy(() => import('src/pages/main/CalendarOld')));

// TASKS / PROJECTS
const ProjectView = Loadable(lazy(() => import('src/pages/main/projects/ProjectView')));
const ProjectViewForTasks = Loadable(lazy(() => import('src/pages/main/projects/ProjectView')));

const TasksView = Loadable(lazy(() => import('src/pages/main/projects/ProjectView')));
const IFrameKanban = Loadable(lazy(() => import('src/pages/main/projects/IFrameKanban')));

const TripRegistration = Loadable(lazy(() => import('src/pages/main/TripRegistration')));

const Products = Loadable(lazy(() => import('src/pages/main/Products')));
const Services = Loadable(lazy(() => import('src/pages/main/Services')));
const Register = Loadable(lazy(() => import('src/pages/main/Register')));
const Newsletter = Loadable(lazy(() => import('src/pages/main/Newsletter')));
const Reviews = Loadable(lazy(() => import('src/pages/main/Reviews')));

const Schedule = Loadable(lazy(() => import('src/pages/main/Schedule')));

const Administration = Loadable(lazy(() => import('src/pages/main/Administration')));


//PROFILE
const Profile = Loadable(lazy(() => import('src/pages/main/Profile')));
// const SettingsExtraOld = Loadable(lazy(() => import('src/pages/main/SettingsExtraOld')));

const PublicWidgetCompanyInformation = Loadable(lazy(() => import('src/pages/other/PublicWidgetCompanyInformation')));

const EventsCalendar = Loadable(lazy(() => import('src/pages/main/EventsCalendar'))); // !!! TEMPORARY !!!

const Messages = Loadable(lazy(() => import('src/pages/main/Messages')));

export const routes: (Partial<RouteObject>)[] = [
  {
    path: '/',
    element: (
      <Navigate
        to="/dashboard"
        // replace
      />
    )
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/contacts',
    element: <ContactsList />
  },
  {
    path: '/contacts/:id',
    element: <ContactView />
  },
  {
    path: '/contacts/:id/:tab',
    element: <ContactView />
  },
  {
    path: '/notes',
    element: <NotesList />    
  },
  {
    path: '/notes/:id',
    element: <NotesList />    
  },
  {
    path: '/files',
    element: <FilesComponent />
  },
  {
    path: '/time_registration',
    element: <TimeRegistration />
  },
  {
    path: '/presentations',
    element: <PresentationsList />
  },
  {
    path: '/presentations/new',
    element: <PresentationCreate />
  },
  {
    path: '/presentations/:id/edit',
    element: <PresentationUpdate />
  },
  {
    path: '/users',
    element: <UsersList />
  },
  {
    path: '/users/:id',
    element: <UserView />
  },
  {
    path: '/users/:id/:tab',
    element: <UserView />
  },
  {
    path: '/devices',
    element: <DevicesList />
  },
  {
    path: '/roles',
    element: <RolesList />
  },
  {
    path: '/roles/:id',
    element: <RolePermissionsView />
  },
  {
    path: '/settings',
    element: <SettingsView />
  },
  {
    path: '/settings/:tab',
    element: <SettingsView />
  },
  // {
  //   path: '/settings/:subpath',
  //   element: <SettingsView />
  // },
  // {
  //   path: '/settings/profiles/invoiceworkflow/new',
  //   element: <InvoiceWorkflowCreate />
  // },
  // {
  //   path: '/settings/profiles/invoiceworkflow/:id/edit',
  //   element: <InvoiceWorkflowUpdate />
  // },
  // {
  //   path: '/settings/chart_of_accounts/new',
  //   exact: true,
  //   component: lazy(() => import('src/views/settings/Financial/ChartOfAccounts/AddIndividualCategory'))
  // },
  // //23-11
  {
    path: '/core/templates',
    element: <TemplateList />
  },
  {
    path: '/core/templates/new',
    element: <TemplateCreate />
  },
  {
    path: '/core/templates/:id',
    element: <TemplateView />
  },
  {
    path: '/core/templates/:id/edit',
    element: <TemplateUpdate />
  },
  {
    path: '/core/templates/:id/:tab',
    element: <TemplateView />
  },
  {
    path: '/core/groups',
    element: <GroupsList />
  },
  {
    path: '/core/groups/:id',
    element: <GroupView />
  },
  {
    path: '/core/groups/:id/:tab',
    element: <GroupView />
  },
  {
    path: '/core/languages',
    element: <LanguagesList />
  },
  {
    path: '/core/languages/new',
    element: <LanguageCreate />
  },
  {
    path: '/core/languages/:id/edit',
    element: <LanguageUpdate />
  },
  {
    path: '/core/permissions/rules',
    element: <PermissionsList />
  },
  {
    path: '/core/permissions/rules/view/:id',
    element: <PermissionView />
  },
  {
    path: '/core/permissions/rules/:tab',
    element: <PermissionsList />
  },
  {
    path: '/core/permissions/categories',
    element: <PermissionCategoriesList />
  },
  // {
  //   path: '/appointments',
  //   element: <AppointmentsComponent />
  // },
  {
    path: '/core/profile',
    element: <ProfileComponent />
  },
  {
    path: '/projects',
    element: <ProjectsList />
  },
  {
    path: '/projects/:id',
    element: <ProjectView />
  },
  {
    path: '/tasks',
    element: <ProjectViewForTasks />
  },
  {
    path: '/tasks',
    element: <TasksView />
  },
  {
    path: '/calendar',
    element: <EventsCalendar />
  },
  {
    path: '/calendar_old',
    element: <CalendarOld />
  },
  {
    path: '/test',
    element: <TestPage1 />
  },
  {
    path: '/test2',
    element: <TestPage2 />
  },
  {
    path: '/test3',
    element: <TestPage3 />
  },
  {
    path: '/test4',
    element: <TestPage4 />
  },
  {
    path: '/test6',
    element: <TestPage6 />
  },
  {
    path: '/test7',
    element: <TestPage7 />
  },
  {
    path: '/test8',
    element: <TestPage8 />
  },
  {
    path: '/trip_registration',
    element: <TripRegistration />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/products/:tab',
    element: <Products />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/register/:tab/:id',
    element: <Register />
  },{
    path: '/register/:tab',
    element: <Register />
  },
  {
    path: '/services',
    element: <Services />
  },
  {
    path: '/core/settings/rules',
    element: <SystemSettingsList />
  },
  {
    path: '/core/settings/categories',
    element: <SystemSettingsCategoriesList />
  },
  {
    path: '/core/settings/categories/:tab',
    element: <SystemSettingsCategoriesList />
  },
  {
    path: '/schedule',
    element: <Schedule />
  },
  {
    path: '/schedule/:tab',
    element: <Schedule />
  },
  {
    path: '/administration',
    element: <Administration />
  },
  {
    path: '/administration/:tab',
    element: <Administration />
  },
  {
    path: '/newsletter',
    element: <Newsletter />
  },
  {
    path: '/reviews',
    element: <Reviews />
  },
  {
    path: '/logout',
    element: <Logout />
  },
  {
    path: '/profile',
    element: <Profile />
  },
  {
    path: '/messages',
    element: <Messages />
  },
  {
    path: '/login',
    // element: () => <Redirect to={initialRoute ? initialRoute : '/dashboard'} />
    element: (
      <Navigate
        to="/dashboard"
      />
    )
  },
  {
    path: '*',
    element: (
      <Error404View />
    )
  }
];


export const deviceRoutes: (Partial<RouteObject>)[] = [
  {
    path: '/device',
    element: <DeviceComponent />
  }
];

export const preLoginRoutes: (Partial<RouteObject>)[] = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/password-reset',
    element: <ForgotPassword />
  },
  {
    path: '/password-reset/:token',
    element: <PasswordReset />
  },
  {
    path: '/sign-up',
    element: <SignUp />
  },
  {
    path: '/terms-and-conditions',
    element: <h1>TODO: TERMS AND CONDITIONS!</h1>
  },
  {
    path: '/device',
    element: <DeviceRegister />
  },
  {
    path: '/widget',
    element: <PublicWidgetCompanyInformation />
  },
  {
    path: '*',
    element: (
      <Navigate
        to="/login"
      />
    )
  }
];

export const frameRoutes: (Partial<RouteObject>)[] = [
  {
    path: '/frame/project/:id',
    element: <IFrameKanban />
  },
  {
    path: '/frame/project',
    element: <IFrameKanban />
  }
];