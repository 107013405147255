import { AxiosRequestConfig } from "axios";
import { AppDispatch } from "src/store";
import { RegisterDayClosing, RegisterDayClosingSmall } from "src/types/api/financial/dayClosing";
import axios from 'axios';
import { createRegisterDayClosing, setRegisterDayClosings, updateRegisterDayClosing } from "src/slices/register";

export const getDayClosingsRequest = (
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<RegisterDayClosingSmall[]> => {
  return new Promise<RegisterDayClosingSmall[]>((resolve, reject) => {
    axios.get<RegisterDayClosingSmall[]>('register/dayClosings', config)
    .then((response) => {
      dispatch(setRegisterDayClosings(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    });
  });
}

export const getDayClosingRequest = (
  id: string,
  config: AxiosRequestConfig
): Promise<RegisterDayClosing> => {
  return new Promise<RegisterDayClosing>((resolve, reject) => {
    axios.get<RegisterDayClosing>(`register/dayClosings/${id}`, config)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    });
  });
}

type DayClosingsOpenRequestData = {
  balance: number;
}
export const dayClosingsOpenRequest = (
  data: DayClosingsOpenRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<RegisterDayClosingSmall> => {
  return new Promise<RegisterDayClosingSmall>((resolve, reject) => {
    axios.post<RegisterDayClosingSmall>('register/dayClosings/open', data, config)
    .then((response) => {
      dispatch(createRegisterDayClosing(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    });
  });
}

export type DayClosingsCloseRequestData = {
  cash: { amount: number; value: number }[];
  balance: {
    debitcard: number;
    cash: number;
    creditcard: number;
  }
}
export const dayClosingsCloseRequest = (
  data: DayClosingsCloseRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<RegisterDayClosingSmall> => {
  return new Promise<RegisterDayClosingSmall>((resolve, reject) => {
    axios.post<RegisterDayClosingSmall>('register/dayClosings/close', data, config)
    .then((response) => {
      dispatch(updateRegisterDayClosing(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    });
  });
}
// on close update

export const dayClosingsCashDepositRequest = (
  amount: number,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<RegisterDayClosingSmall> => {
  return new Promise<RegisterDayClosingSmall>((resolve, reject) => {
    axios.post<RegisterDayClosingSmall>('register/dayClosings/cashDeposit', { amount }, config)
    .then((response) => {
      dispatch(updateRegisterDayClosing(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    });
  });
}
