import { CircularProgress, Divider, Fade, Hidden, IconButton, TextField, useTheme } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'src/i18n';
import { Virtuoso } from "react-virtuoso";
import { OriginalLabel as Label } from 'src/components/general/Labels';
import { BroadcastContext } from 'src/custom/DataListener';
import { useAxiosOptions } from 'src/hooks/general/useAxios';
import { addChatMessage, prependChatMessages, removeChatMessage, setChatMessages } from 'src/slices/chatMessages';
import { useDispatch, useSelector } from 'src/store';
import { v4 as uuid } from 'uuid';
import Message from './Message';
import type { VirtuosoHandle } from 'react-virtuoso';

const useStyles = makeStyles()((theme: any) => ({
  root: {},
  messageContainer: {
    padding: 20,
    marginBottom: 50,
    maxWidth: '300px'
  },
  messageInputContainer: {},
  toBottomArrowRoot: {
    position: 'absolute', 
    right: 33,
    bottom: 93
  },
  toBottomArrowIconBtn: {
    backgroundColor: theme.name === 'LIGHT' ? 'rgb(0,0,0, 0.2)' : 'rgb(0,0,0, 0.8)',
  },
  toBottomArrowIcon: {
    opacity: 1,
    fill: theme.name === 'DARK' ? '#fff' : '#000'
  },
}));

interface RenderToBottomArrowProps {
  handleClick: () => void;
}

const RenderToBottomArrow = ({ handleClick }: RenderToBottomArrowProps) => {
  const { classes } = useStyles();
  const [initialized, setInitialized] = useState(true);

  return (
    <div className={classes.toBottomArrowRoot} style={!initialized ? { visibility: 'hidden' } : {}}>
      <IconButton
        className={classes.toBottomArrowIconBtn}
        onClick={handleClick}
        size="large">
        <ExpandMoreIcon className={classes.toBottomArrowIcon} fontSize="large" />
      </IconButton>
    </div>
  );
}

interface MyTextFieldProps {
  receiver: any;
  scrollToBottom: () => void;
}

const MyTextField = ({ receiver, scrollToBottom }: MyTextFieldProps) => {
  const { classes } = useStyles();
  const {t} = useTranslation();
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const myUserId = useSelector(state => state.general.user?.contact.id);
  const axiosOptions = useAxiosOptions();

  const broadcastContext = useContext(BroadcastContext);
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // broadcastContext.privateChannel?.trigger('client-typing', {
    //   sender_id: myUserId,
    //   receiver_id: receiver.id
    // });

    broadcastContext.privateChannel?.whisper('typing', {
      sender_id: myUserId,
      receiver_id: receiver.id
    });

    setMessage(e.target.value);
  }

  const handleSendMessage = () => {
    if(message.length){
      setMessage('');
      let messageId = uuid();
      
      const tmpData = {
        id: messageId,
        sender_id: myUserId,
        receiver_id: receiver.id,
        content: message,
        read: false,
        created_at: new Date().toISOString()
      };
      
      // @ts-ignore
      dispatch(addChatMessage(tmpData, receiver.id));

      axios.post('chatmessages/send', {receiver_id: receiver.id, message: message}, axiosOptions.apiConfig)
      .then((response) => {})
      .catch((error) => {
        dispatch(removeChatMessage(receiver.id, messageId));
      });

      setTimeout(() => {
        scrollToBottom();
      }, 50);

    }
  }
  
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter'){
      handleSendMessage();
    }
  }

  return (
    <div className={classes.messageInputContainer}>
      <Hidden mdDown>
        <TextField
          fullWidth
          placeholder={`${t('lang.message')}`}
          variant="outlined"
          value={message}
          onChange={handleMessageChange}
          InputProps={{
            endAdornment: <IconButton onClick={handleSendMessage} size="large"><SendIcon/></IconButton>,
          }}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      </Hidden>
      <Hidden mdUp>
        <TextField
          fullWidth
          placeholder={`${t('lang.message')}`}
          variant="outlined"
          value={message}
          onChange={handleMessageChange}
          InputProps={{
            endAdornment: <IconButton onClick={handleSendMessage} size="large"><SendIcon/></IconButton>,
          }}
          onKeyPress={handleKeyPress}
        />
      </Hidden>
    </div>
  );
}

interface Props {
  receiver: any;
  presenceOpen: boolean;
}

const Chat = ({ receiver, presenceOpen }: Props) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();

  const currentUser = useSelector(state => state.general.user);
  const allmessages = useSelector(state => state.chatMessages);

  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  // @ts-ignore
  const chatData = useMemo(() => allmessages[receiver.id] ? allmessages[receiver.id] : []);
  
  const [firstItemIndex, setFirstItemIndex] = useState(chatData.length);
  const [atBottom, setAtBottom] = useState(true);

  const [allFetched, setAllFetched] = useState(false);

  const [fade, setFade] = useState(false);

  const prependItems = useCallback(() => {

    if(chatData.length < 100){
      setAllFetched(true);
      return;
    }

    axios.post('chatmessages/more', {other_user_id: receiver.id, first_message: chatData[0].id}, axiosOptions.apiConfig)
    .then((response) => {
      const responseData = response.data;

      //if !responseData.length

      if(!responseData.length){
        setAllFetched(true)
      }else{
        if(!allFetched){
          const messagesToPrepend = responseData.length;
          const nextFirstItemIndex = firstItemIndex - messagesToPrepend;
          
          dispatch(prependChatMessages(receiver.id, responseData));

          setFirstItemIndex(() => nextFirstItemIndex);
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });


    return false;
  }, [firstItemIndex, chatData])

  useEffect(() => {
    let unreadMessages = false;
    if(allmessages[receiver.id]){
      allmessages[receiver.id].map((message) => {
        if(!message.read){
          unreadMessages = true;
        }
      });
    }

    if(unreadMessages){
      let newAllMessages = {...allmessages};
      newAllMessages[receiver.id] = newAllMessages[receiver.id].map((msg) => {
        return {
          ...msg,
          read: true
        };
      });
      dispatch(setChatMessages(newAllMessages));
    }
  }, [chatData]);

  useEffect(() => {
    axios.get(`chatmessages/read/${receiver.id}`, axiosOptions.apiConfig);
  }, []);

  const scrollToBottom = () => {
    if(virtuosoRef.current){
      virtuosoRef.current.scrollToIndex({index: chatData.length - 1, behavior: 'smooth'});
    }
  }

  useEffect(() => {
    setTimeout(() => {
     // scrollToBottom();
      setFade(true);
    }, 150);
  }, []);

  return <>
  <Fade 
    in={fade}
    timeout={{
      enter: 200,
      appear: 200
    }}
  >
    <div 
      style={{
        width: '100%', 
        height: '100%'
      }}
    >
    <Virtuoso
      ref={virtuosoRef}
      style={{
        // height: 'calc(100% - 144px)',
        height: 'calc(100% - 77px)',
      }}
      components={{
        Header: () => <div style={{ textAlign: 'center', padding: '1rem' }}>
          {chatData.length === 0 ? 
            <Label color="grey">
              {t("lang.no_messages_yet")}
            </Label> : 
            (allFetched ? 
            <Label color="grey">
              {t("lang.no_previous_messages")}
            </Label> : 
            chatData.length >= 100 && 
              <CircularProgress size={18} color="primary" />
            ) }
        </div>,
      }}
      firstItemIndex={firstItemIndex}
      initialTopMostItemIndex={chatData.length - 1}
      data={chatData}
      startReached={prependItems}
      overscan={10}
      atBottomStateChange={(bottom) => {
        setAtBottom(bottom);
      }}
      followOutput={"smooth"}
      itemContent={(index, item) => {
        if(!currentUser){ return null; }
        return (
          <div style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10
          }}>
            <Message 
              target={receiver}
              receiver_id={item.receiver_id}
              content={item.content}
              created_at={item.created_at}
              currentUser={currentUser}
            />
          </div>
        )
      }}
    />
    </div>
  </Fade>
  <Hidden mdUp>
    <div style={{
      position: 'fixed',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      width: '100%'
    }}>
      <Divider/>
      <div style={{
        margin: 10
      }}>
        <MyTextField receiver={receiver} scrollToBottom={scrollToBottom} />
      </div>
    </div>
    {!atBottom && <RenderToBottomArrow handleClick={scrollToBottom}/>}
  </Hidden>
  <Hidden mdDown>
    <div style={{
        position: 'fixed',
        bottom: 0,
        backgroundColor: theme.palette.background.default,
        width: '380px'
      }}>
        <Divider/>
        <div style={{
          margin: 10
        }}>
          <MyTextField receiver={receiver} scrollToBottom={scrollToBottom} />
        </div>
      </div>
      {!atBottom && <RenderToBottomArrow handleClick={scrollToBottom}/>}
  </Hidden>
  </>;
}

export default Chat;