import { AxiosRequestConfig } from "axios";
import { AppDispatch } from "src/store";
import type { Template } from 'src/types/api/template';
import axios from 'axios';
import { deleteTemplate, updateTemplate } from "src/slices/lists/templates";
// import { templateToSmall } from "src/custom/resourceConverter";
import { setCurrentTemplate } from "src/slices/current";
import type { TemplateSubscription } from "src/types/api/templateSubscription";
import { templateToSmall } from "src/custom/resourceConverter";


// axios.post(`system/templates/${templateCpy.id}`, {_method: 'DELETE'}, axiosOptions.apiConfig)
// .then((response) => {})
// .catch((error) => {
//   dispatch(createTemplate(templateCpy));
// });

export const deleteTemplateRequest = (
  template_id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Template> => {
  return new Promise<Template>((resolve, reject) => {
    axios.delete(`system/templates/${template_id}`, config)
    .then((response) => {
      dispatch(deleteTemplate(response.data));
      resolve(response.data);
    })
    .catch((error) => reject(error));
  });
}



// Subscriptions
export interface CreateTemplateSubscriptionRequestData {
  title: string;
  description: string;
  duration: number;
  recurrence: TemplateSubscription['recurrence'];
  price: number;
  is_default: boolean;
}

export const createTemplateSubscriptionRequest = (
  template_id: string,
  data: CreateTemplateSubscriptionRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  currentTemplate: Template | null = null
): Promise<Template> => {
  return new Promise<Template>((resolve, reject) => {
    axios.post<Template>(`system/templates/${template_id}/subscriptions`, data, config)
    .then((response) => {
      // const templateSmall = templateToSmall(response.data);
      dispatch(updateTemplate(templateToSmall(response.data)));
      if(currentTemplate){
        dispatch(setCurrentTemplate(response.data));
      }
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export interface UpdateTemplateSubscriptionRequestData extends CreateTemplateSubscriptionRequestData {}
export const updateTemplateSubscriptionRequest = (
  template_id: string,
  subscription_id: string,
  data: UpdateTemplateSubscriptionRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  currentTemplate: Template | null = null
): Promise<Template> => {
  return new Promise<Template>((resolve, reject) => {
    axios.put<Template>(`system/templates/${template_id}/subscriptions/${subscription_id}`, data, config)
    .then((response) => {
      // const templateSmall = templateToSmall(response.data);
      dispatch(updateTemplate(templateToSmall(response.data)));
      if(currentTemplate){
        dispatch(setCurrentTemplate(response.data));
      }
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const deleteTemplateSubscriptionRequest = (
  template_id: string,
  subscription_id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  currentTemplate: Template | null = null
): Promise<Template> => {
  return new Promise<Template>((resolve, reject) => {
    axios.delete<Template>(`system/templates/${template_id}/subscriptions/${subscription_id}`, config)
    .then((response) => {
      // const templateSmall = templateToSmall(response.data);
      dispatch(updateTemplate(templateToSmall(response.data)))

      if(currentTemplate){
        dispatch(setCurrentTemplate(response.data));
      }

      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}