import BusinessIcon from '@mui/icons-material/HomeWorkOutlined';
import { IconButton, Paper, Typography, useTheme } from "@mui/material";
import { useTranslation } from 'src/i18n';
import DotsMenu from "src/components/general/DotsMenu";
import { OriginalLabel } from "src/components/general/Labels";
import { AddressValues } from './AddAddressDialog';
import { DeleteOutline } from "@mui/icons-material";

interface Props {
  values: AddressValues;
  onUpdate: () => void;
  onDelete: () => void;
  deleteOnly?: boolean;
}

const AddressCard = ({
  values,
  onUpdate,
  onDelete,
  deleteOnly = false
}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Paper 
      style={{
        display: 'flex',
        padding: theme.spacing(1.5)
      }}
    >
      {/* <HomeOutlined /> */}
      <BusinessIcon />
      <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: theme.spacing(1.5), overflow: 'hidden' }}>
        <Typography variant='body2' textOverflow='ellipsis'>{values.street} {values.houseNumber}</Typography>
        <Typography variant='body2' textOverflow='ellipsis'>{values.zipcode} {values.city}</Typography>
        <Typography variant='body2' textOverflow='ellipsis'>Nederland, {values.province}</Typography>
      </div>
      <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginLeft: 'auto' }}>
          {deleteOnly ? (
            <IconButton
              onClick={onDelete}
              size='small'
            >
              <DeleteOutline />
            </IconButton>
          ) : (
            <DotsMenu 
              menuItems={[
                {
                  icon: 'update',
                  content: `${t('lang.update')}`,
                  // onClick: () => setOpen(true)
                  onClick: () => onUpdate()
                },
                {
                  icon: 'delete',
                  content: `${t('lang.delete')}`,
                  // onClick: () => {
                  //   setLocalValues(defaultLocalValues);
                  //   onChange(null);
                  // }
                  onClick: () => onDelete()
                }
              ]}
            />
          )}
        </div>
        <div style={{ marginTop: 'auto' }}>
          <OriginalLabel color="grey">
            {t(`lang.${values.type}`)}
          </OriginalLabel>
        </div>
      </div>
    </Paper>
  )
}
export default AddressCard;