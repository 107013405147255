import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { GroupSmall } from 'src/types/api/group';

const initialState: GroupSmall[] = [];

const slice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state: GroupSmall[], action: PayloadAction<GroupSmall[]>){
      return action.payload;
    },
    createGroup(state: GroupSmall[], action: PayloadAction<GroupSmall>){
      state.push(action.payload);
    },
    updateGroup(state: GroupSmall[], action: PayloadAction<GroupSmall>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteGroup(state: GroupSmall[], action: PayloadAction<GroupSmall>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setGroups = (data: GroupSmall[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGroups(data));
}

export const createGroup = (data: GroupSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createGroup(data));
}

export const updateGroup = (data: GroupSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateGroup(data));
}

export const deleteGroup = (data: GroupSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteGroup(data));
}

export default slice;