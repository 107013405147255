import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { setCustomFields } from "src/slices/general/customfields";
import { setGeneralGroup } from "src/slices/general/group";
import { setGeneralLanguages } from "src/slices/general/languages";
import { setMenu } from "src/slices/general/menu";
import { setSettings } from "src/slices/general/settings";
import { setGeneralUser } from "src/slices/general/user";
import { setVatrates } from "src/slices/lists/invoicing";
import { setPermissionCategories } from "src/slices/lists/permissions";
import { setRoles } from "src/slices/lists/roles";
import { setSystemSettingCategories } from "src/slices/lists/systemSettings";
import { setUsers } from "src/slices/lists/users";
import { AppDispatch } from "src/store";
import { Group } from "src/types/api/group";
import { GeneralLanguage } from "src/types/api/language";
import { PermissionCategory } from "src/types/api/permission";
import { RoleSmall } from "src/types/api/role";
import { EntitySetting, SystemSettingCategory } from "src/types/api/setting";
import { User, UserSmall } from "src/types/api/user";
import { Vatrate } from "src/types/api/financial/vatrate";
import { setEventStatuses } from "src/slices/lists/events";
import { EventProject, EventStatus } from "src/types/api/events/Event";
import { setEventProjects } from "src/slices/lists/eventProjects";

export const aliasCheck = (
  alias: string,
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.get(`system/aliascheck/${alias}`, config)
    .then(() => {
      resolve();
    })
    .catch((error: AxiosError) => {
      reject();
    });
  });
}

export const emailCheck = (
  email: string,
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.post(`system/emailcheck`, { email }, config)
    .then(() => {
      resolve();
    })
    .catch((error: AxiosError) => {
      reject();
    });
  });
}

export const discountCodeCheck = (
  data: { discount_code: string; from_group: string; },
  config: AxiosRequestConfig
): Promise<{ id: string | null }> => {
  return new Promise<{ id: string | null }>((resolve, reject) => {
    axios.post<{ id: string | null }>(`system/discountcodecheck`, data, config)
    .then((data) => {
      resolve(data.data);
    })
    .catch((error: AxiosError) => {
      reject();
    });
  });
}

// NAAR TYPES FILE (SYSTEM?)
interface CoreData {
  general: {
    group: Group;
    user: User;
    menu: any;
    custom_fields: any;
    languages: GeneralLanguage[];
    settings: EntitySetting[];
  },
  lists: {
    users: UserSmall[];
    permissions: {
      categories: PermissionCategory[];
    },
    roles: RoleSmall[];
    vatrates: Vatrate[];
    system_settings: {
      categories: SystemSettingCategory[];
    },
    event_statuses: EventStatus[];
    event_projects: EventProject[];
  }
}


export const coreCallRequest = (
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<CoreData> => {
  return new Promise<CoreData>((resolve, reject) => {
    axios.get<CoreData>('general/core', config)
    .then((response) => {
      const data = response.data;
      
      dispatch(setGeneralGroup(data.general.group));
      dispatch(setGeneralUser(data.general.user));
      dispatch(setGeneralLanguages(data.general.languages));
      dispatch(setMenu(data.general.menu))
      dispatch(setCustomFields(data.general.custom_fields));
      dispatch(setUsers(data.lists.users));
      dispatch(setPermissionCategories(data.lists.permissions.categories));
      dispatch(setRoles(data.lists.roles));
      dispatch(setVatrates(data.lists.vatrates));
      dispatch(setSystemSettingCategories(data.lists.system_settings.categories));
      dispatch(setSettings(data.general.settings));
      dispatch(setEventStatuses(data.lists.event_statuses));
      dispatch(setEventProjects(data.lists.event_projects));
  
      resolve(response.data);


      // // subscription check (created hook does not work here yet !!!)
      // const subscription_expiry_date_dayjs = dayjs(response.data.general.group.subscription_expiry_date).startOf('day');
      // const now_dayjs = dayjs().startOf('day');
      // const subscriptionExpired = subscription_expiry_date_dayjs.isSameOrBefore(now_dayjs);
      // if(!subscriptionExpired){
      //   handleAsyncCalls(data, dispatch, appContext, axios);
      // }else{
      //   navigate('/dashboard');
      // }
      // // asyncCalls(data);
      // // setLoaded(true);
    })
    .catch((error) => {
      reject(error);
      // onError(error);
    });
  })



}