import { useMemo } from 'react';
import Error404Page from 'src/pages/other/misc/Error404View';
import UpgradeSubscriptionView from 'src/pages/other/misc/UpgradeSubscriptionView';
import { useSelector } from 'src/store';

export interface BasePermissionCheckerProps {
  pageTitle?: string;
  permissions?: string | string[];
  anyPermission?: boolean;
  upgrade?: {
    permission: string;
    title: string;
    subtitle: string;
    svgComponent: JSX.Element;
  }
}

export const usePermissionChecker = ({
  pageTitle,
  permissions = [], 
  anyPermission = false,
  upgrade
}: BasePermissionCheckerProps): {
  type: 'upgrade' | 'notFound';
  component: JSX.Element;
} | null => {
  const userRole = useSelector(state => state.general.user?.role);
  
  const checkingCount = useMemo(() => {
    const permissionsToMap = Array.isArray(permissions) ? permissions : [permissions];
    return permissionsToMap.reduce((prev, current) => {
      const hasPermission = !!userRole?.permissions.find(perm => perm.tag === current);
      if(hasPermission)
        return prev + 1;
      return prev;
    }, 0);

  }, [userRole, permissions]);

  const isAllowed = useMemo(() => {
    if(anyPermission){
      return checkingCount > 0;
    }
    const permissionLength = Array.isArray(permissions) ? permissions.length : [permissions].length;
    return checkingCount === permissionLength;
  }, [anyPermission, checkingCount, permissions]);

  const userHasUpgradePermission = useMemo(() => {
    if(!upgrade){
      return null;
    }

    return !!userRole?.permissions.find(perm => upgrade.permission === perm.tag);
  }, [upgrade, userRole]);

  if(!isAllowed){
    if(!!upgrade && userHasUpgradePermission){
      return {
        type: 'upgrade',
        component: (
          <UpgradeSubscriptionView 
            pageTitle={pageTitle ? pageTitle : ''}
            title={upgrade.title}
            subtitle={upgrade.subtitle}
            svgComponent={upgrade.svgComponent}
          />
        ) 
      }
    }

    return {
      type: 'notFound',
      component: <Error404Page/>
    }
  }
  return null;
}

export interface PermissionCheckerProps extends BasePermissionCheckerProps {
  children: JSX.Element;
  fullpage?: boolean;
}

const PermissionChecker = ({
  pageTitle,
  permissions = [], 
  children,
  fullpage = false,
  anyPermission = false,
  upgrade
}: PermissionCheckerProps) => {
  const userRole = useSelector(state => state.general.user?.role);
  
  const checkingCount = useMemo(() => {
    const permissionsToMap = Array.isArray(permissions) ? permissions : [permissions];
    return permissionsToMap.reduce((prev, current) => {
      const hasPermission = !!userRole?.permissions.find(perm => perm.tag === current);
      if(hasPermission)
        return prev + 1;
      return prev;
    }, 0);

  }, [userRole, permissions]);

  const isAllowed = useMemo(() => {
    if(anyPermission){
      return checkingCount > 0;
    }
    const permissionLength = Array.isArray(permissions) ? permissions.length : [permissions].length;
    return checkingCount === permissionLength;
  }, [anyPermission, checkingCount, permissions]);

  const userHasUpgradePermission = useMemo(() => {
    if(!upgrade){
      return null;
    }

    return !!userRole?.permissions.find(perm => upgrade.permission === perm.tag);
  }, [upgrade, userRole]);

  if(!isAllowed){
    if(!fullpage){
      return null;
    }

    if(!!upgrade && userHasUpgradePermission){
      return (
        // <UseTopbarFC
        //   pageTitle={pageTitle}
        //   topBar={topBar}
        // >
          <UpgradeSubscriptionView 
            pageTitle={pageTitle ? pageTitle : ''}
            title={upgrade.title}
            subtitle={upgrade.subtitle}
            svgComponent={upgrade.svgComponent}
          />
        // </UseTopbarFC>
      )
    }
    return <Error404Page/>
  }
  // UseTopbar(pageTitle, topBar);
  return (
    // <UseTopbarFC
    //   pageTitle={pageTitle}
    //   topBar={topBar}
    // >
    <>
      {children}
    </>
    // </UseTopbarFC>
  )
}

export default PermissionChecker;