import i18n from 'i18next';
import { initReactI18next, useTranslation as useTranslationBase } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { useSelector } from 'src/store';

i18n.use(HttpBackend).use(initReactI18next).init<HttpBackendOptions>({
  lng: 'nl',
  backend: {
    loadPath: `/assets/i18n/translations/{{ns}}/{{lng}}.json?v=${Math.random()}`
  },
  fallbackLng: 'en',
  fallbackNS: 'default',
  debug: false,
  ns: ['default'],
  defaultNS: 'default',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

// export const useTranslation = useTranslationBase;
export const useTranslation = () => {
  const currentTemplatePrefix = useSelector(state => state.general.group?.template.prefix);
  return useTranslationBase(currentTemplatePrefix ? currentTemplatePrefix : 'default');
}

// export const useTranslation = () => {
//   // with template
//   return useTranslationBase(['default', '_sal']);
// }