import { useEffect, useState } from "react";
import { useSelector } from "src/store";
import dayjs from 'dayjs';

const useSubscriptionExpired = () => {
  const group = useSelector(state => state.general.group);

  const checkSubscriptionExpired = () => {
    if(group && group.subscription.expiry_date){
      const expiryDayjs = dayjs(group.subscription.expiry_date).startOf('day');
      const todayDayjs = dayjs().startOf('day');

      if(expiryDayjs.isSameOrBefore(todayDayjs)){
        return true;
      }
    }
    return false;
  }

  const getDaysUntilExpiry = (): number | null => {
    if(group && group.subscription.expiry_date){
      const expiryDayjs = dayjs(group.subscription.expiry_date).startOf('day');
      const todayDayjs = dayjs().startOf('day');
      const diffDays = expiryDayjs.diff(todayDayjs, 'day');
      return diffDays < 1 ? 0 : diffDays;
    }
    return null;
  }

  const [subscriptionExpired, setSubscriptionExpired] = useState(checkSubscriptionExpired());
  const [daysUntilExpiry, setDaysUntilExpiry] = useState(getDaysUntilExpiry());

  useEffect(() => {
    const minuteInterval = setInterval(() => {
      setSubscriptionExpired(checkSubscriptionExpired());
      setDaysUntilExpiry(getDaysUntilExpiry());
    }, 60000);
    return () => {
      clearInterval(minuteInterval);
    }
  }, []);

  return {
    subscriptionExpired,
    daysUntilExpiry
  };
}
export default useSubscriptionExpired;