import { Box, Button, FormControl, FormControlLabel, Switch, TextField, Typography, useTheme } from "@mui/material";
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'src/i18n';
import { createAbsenceRequest, deleteAbsenceRequest, updateAbsenceRequest } from "src/requests/absences";
import { DateRangePicker, DateTimeRangePicker } from 'src/components/general/DateTimePicker_v3';
import { OriginalLabel } from 'src/components/general/Labels';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import UsersPicker from "src/components/general/UsersPicker";
import { useAxiosOptions } from "src/hooks/general/useAxios";
import { closeForm } from 'src/slices/forms';
import { useDispatch, useSelector } from "src/store";
import { DEFAULT_VALUES } from "./constants";
import { FormValues } from './types';
import useClaim from "src/hooks/general/useClaim";
import Avatar from 'src/components/general/Avatar';
import CustomDialog from 'src/components/general/Dialog';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import validations from "src/components/forms/validations";
import ConfirmDialog from "src/components/general/ConfirmDialog";
import useCalendarOverlap from "src/hooks/general/useCalendarOverlap";

const yup_schema = yup.object().shape({
  title: validations.default_string,
  all_users: yup.boolean(),
  user_ids: yup.array().when('all_users', {
    is: false,
    then: yup.array().min(1, 'errors.required')
  }),
  start: yup.number().required('errors.required'),
  end: yup.number().required('errors.required').min(yup.ref('start'))
});

const Absence = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const axiosOptions = useAxiosOptions();
  
  const users = useSelector(state => state.lists.users);
  const absences = useSelector(state => state.lists.absences);

  const { overlaps: overlapsCheck } = useCalendarOverlap();

  const {
    open: formOpen,
    updateId,
    initialValues
  } = useSelector(state => state.forms.absence);

  const handleClose = () => {
    dispatch(closeForm('absence'));
  }

  const {
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(yup_schema)
  });
  const watchValues = watch();

  const selectedUsers = useMemo(() => users.filter(user => watchValues.user_ids.includes(user.id)), [users, watchValues.user_ids]);

  const DatePickerComponent = watchValues.all_day ? DateRangePicker : DateTimeRangePicker;

  useEffect(() => {
    if(formOpen && !updateId){
      // let initialEnd = initialValues.end ? initialValues.end : null;
      // if(initialEnd && !!initialValues.all_day){
      //   initialEnd = initialEnd.subtract(1, 'second');
      // }
      // TODO: end date
      reset({ 
        ...DEFAULT_VALUES,
        ...initialValues 
      });
    }
  }, [formOpen]);

  const absenceToUpdate = useMemo(() => 
    absences.find(absence => absence.id === updateId)
  , [absences, updateId]);
  useEffect(() => {
    if(absenceToUpdate){
      let start = dayjs.unix(absenceToUpdate.start_date);
      let end = dayjs.unix(absenceToUpdate.end_date);
      if(absenceToUpdate.all_day){
        end = end.subtract(1, 'day').endOf('day');
      }

      reset({
        title: absenceToUpdate.title,
        all_day: absenceToUpdate.all_day,
        start: start.unix(),
        end: end.unix(),
        user_ids: absenceToUpdate.users.map((user) => user.id),
        all_users: absenceToUpdate.all_users
      });
    }
  }, [absenceToUpdate]);


  const defaultOverlapsDialog = { open: false, cb: () => {} };
  const [overlapsDialog, setOverlapsDialog] = useState<{
    open: boolean;
    cb: Function;
  }>(defaultOverlapsDialog);

  const handleValidSubmit = async (values: FormValues, e: any, ignoreOverlaps: boolean = false) => {
    if(typeof values.start !== 'number' || typeof values.end !== 'number'){
      return;
    }

    let start = dayjs.unix(values.start);
    let end = dayjs.unix(values.end);

    if(values.all_day){
      start = start.startOf('day');
      end = end.add(1, 'day').startOf('day');
    }

    // overlaps check
    if(!ignoreOverlaps){
      const absenceIgnoreIds: string[] = updateId ? [updateId] : [];

      const result = overlapsCheck(
        values.start, 
        values.end, 
        values.all_users ? null : selectedUsers, 
        { absence: absenceIgnoreIds }
      );
      if(Object.values(result).find(x => x.length)){
        setOverlapsDialog({ 
          open: true, 
          cb: handleSubmit((values, e) => handleValidSubmit(values, e, true)) 
        })
        return;
      }
    }

    const data = {
      title: values.title,
      start: start.unix(),
      end: end.unix(),
      all_day: values.all_day,
      members: values.user_ids,
      all_users: values.all_users
    };


    // handleClose after call !!! 

    if(updateId){
      try {
        await updateAbsenceRequest(updateId, data, dispatch, axiosOptions.apiConfig);        
        handleClose();
      } catch (error) {
        console.log(error);
      }
      return;
    }
    //create
    try {
      await createAbsenceRequest(data, dispatch, axiosOptions.apiConfig);        
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDelete = async () => {
    setDeleteDialogOpen(false);
    if(updateId){
      try {
        await deleteAbsenceRequest(updateId, dispatch, axiosOptions.apiConfig);
        handleClose();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const {  
    claimUser,
    currentUserHasFirstClaim
  } = useClaim(updateId ? updateId : null);

  // const [delayedClaimUser, setDelayedClaimUser] = useState(claimUser);
  // useEffect(() => {
  //   if(!delayedClaimUser){
  //     setDelayedClaimUser(claimUser);
  //     return;
  //   }
  //   setTimeout(() => {
  //     setDelayedClaimUser(claimUser);
  //   }, 500); // CHANGE TO TRANSITION TIME !!! 
  // }, [claimUser]);


  const disableAllFields = !!(updateId && !currentUserHasFirstClaim);

  return (
    <DuotoneDialog
      open={formOpen}
      title={`${t(updateId ? 'lang.update_absence' : 'lang.new_absence')}`}
      onClose={handleClose}
      onSubmit={handleSubmit(handleValidSubmit)}
      submitButtonProps={{
        disabled: disableAllFields || isSubmitting
      }}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      subtitle={(
        <Typography variant='body2'>
          {t('lang.status')}:
          <span style={{ marginLeft: 5, marginRight: 5 }}>
            <OriginalLabel
              // onClick={() => console.log('todo')}
              onClick={() => {}}
              colorCode={theme.palette.error.main}
            >
              {t('lang.absent')}
            </OriginalLabel>
          </span>
        </Typography>
      )}
      alert={updateId ? {
        open: !currentUserHasFirstClaim && !!claimUser,
        title: claimUser ? `${claimUser.contact.name} ${t('lang.is_editing_task')}` : undefined,
        startComponent: !claimUser ? null : (
          <Avatar
            name={claimUser.contact.name}
            user_id={claimUser.id}
            size='small'
          />
        )
      } : undefined}
    >
      <>
        <Box mt={3}>
          <Controller 
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                label={`${t('lang.title')}`}
                fullWidth
                variant='standard'
                disabled={disableAllFields}
                {...field}
                error={!!errors.title}
                helperText={errors.title?.message ? errors.title.message : undefined}
              />
            )}
          />
        </Box>

        <Box mt={3}>
          <FormControl>
            <Controller 
              name='all_day'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // onChange={() => setValues({ ...values, all_day: !values.all_day })}
                  onChange={() => field.onChange(!watchValues.all_day)}
                  control={<Switch checked={field.value} />}
                  label={`${t('lang.all_day')}`}
                  labelPlacement="end"
                  disabled={disableAllFields}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={3}>
          <DatePickerComponent 
            value={{
              start: typeof watchValues.start === 'number' ? dayjs.unix(watchValues.start) : null,
              end: typeof watchValues.end === 'number' ? dayjs.unix(watchValues.end) : null
            }}
            onChange={val => {
              setValue('start', val.start ? val.start.unix() : null);
              setValue('end', val.end ? val.end.unix() : null);
            }}
            error={{ 
              start: !!errors.start, 
              end: !!errors.end 
            }}
            // onError={err => console.log(err)}
            disabled={disableAllFields}
          />
        </Box>

        <Box mt={3}>
          <UsersPicker
            label={`${t('lang.users')}`}
            values={{ users: selectedUsers, allUsers: watchValues.all_users }}
            onChange={({ users, allUsers }) => {
              setValue('user_ids', users.map(user => user.id));
              setValue('all_users', !!allUsers);
            }}
            disabled={disableAllFields}
            allUsersCheckbox
            error={!!errors.user_ids}
            helperText={errors.user_ids?.message ? `${t(errors.user_ids.message)}` : undefined}
          />
        </Box>
        {!!updateId && (
          <Box mt={3}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={() => setDeleteDialogOpen(true)}
              disabled={isSubmitting}
              style={{
                backgroundColor: theme.palette.error.main
              }}
            >
              {`${t('lang.delete')}`}
            </Button>
          </Box>
        )}
        <CustomDialog
          open={deleteDialogOpen}
          onSubmit={handleDelete}
          onClose={() => setDeleteDialogOpen(false)}
          title={`${t('lang.delete')}`}
          submitButtonText={`${t('lang.delete')}`}
          isDelete
        >
          <Typography>{t('lang.are_you_sure')}</Typography>
        </CustomDialog>
        <ConfirmDialog
          open={overlapsDialog.open}
          onConfirm={() => {
            overlapsDialog.cb();
            setOverlapsDialog(defaultOverlapsDialog);
          }}
          onCancel={() => setOverlapsDialog(defaultOverlapsDialog)}
          titleText={'Overlapt...'}
          contentText={'Let op: als je doorgaat wordt er een ander evenement overlapt'}
        />
      </>
    </DuotoneDialog>
  )
}
export default Absence;