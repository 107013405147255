import { Table, TableBody, TableCell, TableHead, TableRow, Button, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'src/i18n';
import DuotoneDialog from "src/components/general/DuotoneDialog";
import CashCountDialog, { CashCountFormValues, calculateTotalValueFromFormValues } from 'src/dialogs/financial/CashCount';
import { useAxiosOptions } from "src/hooks/general/useAxios";
import useCurrency from "src/hooks/general/useCurrency";
import { dayClosingsCloseRequest, DayClosingsCloseRequestData } from "src/requests/dayClosings";
import { closeForm } from "src/slices/forms";
import { useDispatch, useSelector } from "src/store";
import { DEFAULT_VALUES } from './constants';
import Row from "./Row";
import { FormValues } from "./types";

const CloseRegister = () => {
  const {
    open: formOpen,
    initialValues
  } = useSelector(state => state.forms.closeRegister);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const axiosOptions = useAxiosOptions();
  const theme = useTheme();

  const { numberToPriceString } = useCurrency();

  const { dayClosings } = useSelector(state => state.register);

  const [values, setValues] = useState<FormValues>(DEFAULT_VALUES);

  const handleClose = () => {
    dispatch(closeForm('closeRegister'));
  }

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    if(submitting)
      return;

    setSubmitting(true);

    const data: DayClosingsCloseRequestData = {
      balance: {
        cash: values.balance.cash,
        creditcard: values.balance.creditcard,
        debitcard: values.balance.debitcard
      },
      cash: 
        Object.entries(values.cash)
        .filter(([value, amount]) => !!value && !!amount)
        .map(([value, amount], index) => ({
          amount: parseInt(amount),
          value: parseFloat(value)
      }))
    }

    const response = await dayClosingsCloseRequest(data, dispatch, axiosOptions.apiConfig);
    console.log(response);

    setSubmitting(false);
    handleClose();
  }

  // import getCurrentDayClosing

  useEffect(() => {
    if (formOpen) {
      // const lastDayClosing = getLastDayClosing();
      // console.log(lastDayClosing);
      setValues({
        ...DEFAULT_VALUES,
        ...initialValues
      });
    }
  }, [formOpen]);


  const expectedCentValues = useMemo(() => {
    const sortedDayClosings = [...dayClosings].sort((a,b) => {
      if(a.number < b.number){ return 1; }
      if(a.number > b.number){ return -1; }
      return 0;
    });

    const secondLastDayClosing = sortedDayClosings[1]; // maybe filter non opened and take first ???

    const currentDayClosing = dayClosings.find(dayClosing => !dayClosing.closed_at);

    const startValue = { 
      bank: 0, 
      cash: (secondLastDayClosing?.closing_balance) ? secondLastDayClosing.closing_balance * 100 : 0,  
      // coupon: 0, 
      creditcard: 0, 
      debitcard: 0, 
      giftcard: 0 
    }
    
    if (!currentDayClosing)
      return startValue;

    return currentDayClosing.payments.reduce((prev, payment) => ({
      ...prev,
      [payment.type]: prev[payment.type] + (payment.price * 100)
    }), startValue);
  }, [dayClosings]);

  const handleCountedChange = (key: keyof FormValues['balance'], value: number) => {
    setValues(prev => ({
      ...prev,
      balance: {
        ...prev.balance,
        [key]: value
      }
    }))
  }

  const totals = useMemo(() => {
    const expected = Object.values(expectedCentValues).reduce((prev, current) => prev + current, 0) / 100;

    const countedCentsObj = {
      ...expectedCentValues,
      cash: values.balance.cash * 100,
      creditcard: values.balance.creditcard * 100,
      debitcard: values.balance.debitcard * 100
    }
    const counted = Object.values(countedCentsObj).reduce((prev, current) => prev + current, 0) / 100;

    return {
      expected: expected,
      counted: counted,
      difference: counted - expected
    }
  }, [expectedCentValues, values]);

  const [cashCountOpen, setCashCountOpen] = useState(false);

  const handleCashCountSubmit = (value: CashCountFormValues, is_close: boolean = false) => {
    if(is_close){
      setValues(prev => ({ ...prev, cash: value, }));
    }else{
      const totalCashValue = calculateTotalValueFromFormValues(value);
      setValues(prev => ({
        ...prev, 
        cash: value,
        balance: {
          ...prev.balance,
          cash: totalCashValue
        }
      }));
    }
    setCashCountOpen(false);
  }

  return (
    <>
      <DuotoneDialog
        open={formOpen}
        title={`${t('lang.close_register')}`}
        onClose={handleClose}
        onSubmit={() => handleSubmit()}
        submitButtonProps={{
          disabled: submitting
        }}
        cancelButtonProps={{
          disabled: submitting
        }}
        disableOutsideClick={submitting}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md'
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('lang.payment_method')}</TableCell>
              <TableCell>{t('lang.expected')}</TableCell>
              <TableCell>{t('lang.counted')}</TableCell>
              <TableCell/>
              <TableCell>{t('lang.difference')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Row
              title={t('lang.cash')}
              expectedCentValue={expectedCentValues.cash}
              moneyField={{
                value: values.balance.cash ? values.balance.cash : undefined,
                onChange: (value) => handleCountedChange('cash', value)
              }}
              buttonComponent={(
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setCashCountOpen(true)}
                >
                  {t('lang.open_cash_count')}
                </Button>
              )}
            />
            <Row
              title={t('lang.debitcard')}
              expectedCentValue={expectedCentValues.debitcard}
              moneyField={{
                value: values.balance.debitcard ? values.balance.debitcard : undefined,
                onChange: (value) => handleCountedChange('debitcard', value)
              }}
            />
            <Row
              title={t('lang.creditcard')}
              expectedCentValue={expectedCentValues.creditcard}
              moneyField={{
                value: values.balance.creditcard ? values.balance.creditcard : undefined,
                onChange: (value) => handleCountedChange('creditcard', value)
              }}
            />
            <Row
              title={t('lang.bank')}
              expectedCentValue={expectedCentValues.bank}
            />
            <Row
              title={t('lang.giftcard')}
              expectedCentValue={expectedCentValues.giftcard}
            />
            {/* <Row
              title={t('lang.coupon')}
              expectedCentValue={expectedCentValues.coupon}
            /> */}

            {/* // TODO: Colorize difference (red for negative green for positive ?) */}
            {/* // TODO: Total row */}

          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>{t('lang.total')}</TableCell>
              <TableCell>
                {numberToPriceString(totals.expected)}
              </TableCell>
              <TableCell>
                {numberToPriceString(totals.counted)}
              </TableCell>
              <TableCell/>
              <TableCell
                style={totals.difference !== 0 ? { 
                  color: theme.palette.error.main 
                } : {
                  color: theme.palette.success.main
                }}
              >
                {numberToPriceString(totals.difference)}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </DuotoneDialog>
      <CashCountDialog 
        open={cashCountOpen}
        onClose={(val) => handleCashCountSubmit(val, true)}
        onSubmit={(val) => handleCashCountSubmit(val)}
        initialValues={values.cash}
      />
    </>
  )
}
export default CloseRegister;