import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { FormValues as ContactFormValues } from 'src/dialogs/forms/Contact/types';
import { FormValues as AppointmentFormValues } from 'src/dialogs/forms/Appointment/types';
import { FormValues as AbsenceFormValues } from 'src/dialogs/forms/Absence/types';
import { FormValues as TaskFormValues } from 'src/dialogs/forms/Task/types';
import { FormValues as TemplateSubscriptionFormValues } from 'src/dialogs/forms/TemplateSubscription/types';
import { FormValues as GroupFormValues } from 'src/dialogs/forms/Group/types';
import { FormValues as OpenRegisterFormValues } from 'src/dialogs/forms/Register/OpenRegister/types';
import { FormValues as CloseRegisterFormValues } from 'src/dialogs/forms/Register/CloseRegister/types';
import { FormValues as SimpleContactFormValues } from 'src/dialogs/forms/ContactSimple/types';

const PRELOAD_TIME = 200;

type FormCallback = (values: any) => void;

interface Form<FormValues> {
  open: boolean;
  render: boolean;
  updateId: string | null;
  initialValues: Partial<FormValues>;
  callback: FormCallback | null;
}
interface FormsState {
  contact: Form<ContactFormValues>;
  contactSimple: Form<SimpleContactFormValues>;
  appointment: Form<AppointmentFormValues>;
  task: Form<TaskFormValues>;
  absence: Form<AbsenceFormValues>;
  templateSubscription: Form<TemplateSubscriptionFormValues>;
  group: Form<GroupFormValues>;
  openRegister: Form<OpenRegisterFormValues>;
  closeRegister: Form<CloseRegisterFormValues>;
}

export type FormsStateKey = keyof FormsState;

const initialState: FormsState = {
  contact: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  contactSimple: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  appointment: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  task: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  absence: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  templateSubscription: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  group: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  openRegister: { open: false, render: false, updateId: null, initialValues: {}, callback: null },
  closeRegister: { open: false, render: false, updateId: null, initialValues: {}, callback: null }
};

const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    openForm(
      state: FormsState, 
      action: PayloadAction<FormsStateKey>
    ){
      state[action.payload].open = true;
    },
    setFormUpdateIdAndInitialValues(
      state: FormsState, 
      action: PayloadAction<{ 
        key: FormsStateKey, 
        updateId: Form<any>['updateId'], 
        initialValues: any, 
        callback: FormCallback | null;
      }>
    ){
      state[action.payload.key] = {
        ...state[action.payload.key],
        updateId: action.payload.updateId,
        initialValues: action.payload.initialValues,
        callback: action.payload.callback
      };
    },
    closeForm(state: FormsState, action: PayloadAction<FormsStateKey>){
      state[action.payload].open = false;
    },
    renderForm(state: FormsState, action: PayloadAction<FormsStateKey>){
      state[action.payload].render = true;
    },
    derenderForm(state: FormsState, action: PayloadAction<FormsStateKey>){
      state[action.payload].render = false;
    }
  }
})

export const { reducer } = slice;

export const openForm = (
  data: FormsStateKey, 
  updateId: Form<any>['updateId'] = null, 
  initialValues: any = {},
  callback: FormCallback | null = null
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFormUpdateIdAndInitialValues({ key: data, updateId, initialValues, callback }))
  setTimeout(() => {
    dispatch(slice.actions.openForm(data));
  }, PRELOAD_TIME);
}

export const closeForm = (data: FormsStateKey): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeForm(data));
  setTimeout(() => {
    dispatch(slice.actions.setFormUpdateIdAndInitialValues({ key: data, updateId: null, initialValues: {}, callback: null }))
  }, PRELOAD_TIME);
}

export const renderForm = (data: FormsStateKey): AppThunk => async (dispatch) => {
  dispatch(slice.actions.renderForm(data));
}
export const derenderForm = (data: FormsStateKey): AppThunk => async (dispatch) => {
  dispatch(slice.actions.derenderForm(data));
}

export const setFormUpdateIdAndInitialValues = (data: { 
  key: keyof FormsState; 
  updateId: string | null; 
  initialValues: any; 
  callback: FormCallback | null;
}): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFormUpdateIdAndInitialValues(data));
}

export default slice;