import React from 'react';
import clsx from 'clsx';
import { Typography, alpha } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';

const useOriginalLabelStyles = makeStyles()((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08)
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08)
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08)
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08)
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08)
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08)
  },
  grey: {
    color: '#263238',
    backgroundColor: '#eeeeee'
  },
  lightgrey: {
    color: '#808080',
    backgroundColor: alpha('#808080', 0.08)
  },
  black: {
    color: '#000000',
    backgroundColor: alpha('#000000', 0.08)
  },
  purple: {
    color: '#800080',
    backgroundColor: alpha('#800080', 0.08)
  }
}));

export type OriginalLabelColor = 'primary' | 'secondary' | 'error' | 'lightgrey' | 'success' | 'warning' | 'info' | 'grey' | 'black' | 'purple' ;

interface OriginalLabelProps {
  className?: string;
  color?: OriginalLabelColor;
  colorCode?: string | null;
  children?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  [x: string]: any; // Rest props
}

export const OriginalLabel = ({
  className,
  color,
  colorCode = null,
  children,
  onClick,
  style,
  ...rest
}: OriginalLabelProps) => {
  const { classes } = useOriginalLabelStyles(); 

  if(colorCode !== null){
    return (
      <span
        className={clsx(classes.root, className)}
        style={{
          color: colorCode,
          backgroundColor: alpha(colorCode, 0.08),
          cursor: !!onClick ? 'pointer' : 'default',
          ...style
        }}
        onClick={!!onClick ? onClick : undefined}
        {...rest}
      >
        {children}
      </span>
    )
  }

  return (
    <span
      className={color ? clsx(classes.root, {[classes[color]]: color}, className) : clsx(classes.root, className)}
      style={style}
      {...rest}
    >
      {children}
    </span>
  );
}



const useModernLabelStyles = makeStyles()((theme) => ({
  root: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: '4px',
    color: theme.palette.text.secondary,
    paddingLeft: 5,
    paddingRight: 5,
    transition: '0.5s',
  },
  rootHover: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    }
  },
  typographyStyle: {
    fontSize: 10
  }
}));

interface ModernLabelProps {
  children: React.ReactNode;
  handleClick?: () => void;
  style?: React.CSSProperties;
  disableHover?: boolean;
}

export const ModernLabel = ({ children, handleClick, style, disableHover = false }: ModernLabelProps) => {
  const { classes } = useModernLabelStyles();

  const onClick = (e: any) => {
    e.stopPropagation();
    handleClick && handleClick();
  }

  return (
    <div 
      className={clsx(classes.root, !disableHover && classes.rootHover)}
      onMouseDown={onClick}
      style={style}
    >
      {typeof children === 'string' ?
        <Typography className={classes.typographyStyle}>
          {children}
        </Typography>
        :
        children
      }
    </div>
  );
}