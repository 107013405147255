import { ReactNode, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from 'src/App';
import { useSelector } from 'src/store';
import { makeStyles } from 'src/theme/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex', 
    flexDirection: 'column',
    height: '100%'
  }
}));

interface Props {
  className?: string;
  title: string;
  children: ReactNode; // | ReactNode[]
  [x: string]: any;
}

const Page = ({
  className, 
  title, 
  children, 
  ...rest
}: Props) => {
  const { classes } = useStyles();

  const appContext = useContext(AppContext);
  const my_user = useSelector(state => state.general.user);
  const all_messages = useSelector(state => state.chatMessages);

  const unreadMessageCount = useMemo(() => {
    let finalCount = 0;
    if(my_user){
      Object.values(all_messages).map((messages) => messages.map((message) => {
        if(message.receiver_id === my_user.contact.id && message.read === false)
          finalCount++;
      }));
    }
    return finalCount;
  }, [all_messages]);

  if(appContext === null){
    return null;
  }

  const final_title = unreadMessageCount > 0 ? 
    `(${unreadMessageCount}) ${title} | ${appContext.group.contact.company_name}` 
    : 
    `${title} | ${appContext.group.contact.company_name}`;

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Helmet title={final_title}>
        {final_title}
      </Helmet>
      {children}
    </div>
  );
};

export default Page;