import { TextField, TextFieldProps } from '@mui/material';
import useCurrency from 'src/hooks/general/useCurrency';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useState } from 'react';

interface Props {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  fieldProps?: Partial<NumberFormatProps<TextFieldProps>>;
  // textFieldProps?: Partial<TextFieldProps>;
}

const MoneyField = ({
  value,
  onChange,
  fieldProps
}: Props) => {
  const currency = useCurrency();
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <NumberFormat
      thousandSeparator={currency.thousandDelimiter}
      decimalSeparator={currency.delimiter}
      value={value}
      onValueChange={(values) => onChange(values.floatValue)}
      customInput={TextField}
      allowNegative={false}
      decimalScale={8}
      allowEmptyFormatting
      format={(!isFocused && !value) ? `0${currency.delimiter}00` : undefined}
      onFocus={() => setIsFocused(true)}
      onBlur={handleBlur}

      variant='standard'
      fullWidth
      InputProps={{
        startAdornment: (
          <span style={{ marginRight: 5 }}>
            {currency.symbol}
          </span>
        )
      }}
      {...fieldProps}
    />
  )
}

export default MoneyField;