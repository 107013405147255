import { createEventStatus, deleteEventStatus, updateEventStatus } from 'src/slices/lists/events';
import type { AppDispatch } from 'src/store/index';
import { EventStatus } from 'src/types/api/events/Event';
import { EchoChannel as Channel } from '../index';

const eventDataHandler = (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_event_status', async (data: any) => {
    const status = data.data.response as EventStatus;
    dispatch(createEventStatus(status));
  });

  channel.listen('.update_event_status', async (data: any) => {
    const status = data.data.response as EventStatus;
    dispatch(updateEventStatus(status));
  });
  
  channel.listen('.delete_event_status', (data: any) => {
    const status = data.data.response as EventStatus;
    dispatch(deleteEventStatus(status));
  });
}
export default eventDataHandler;