import {
  IconButton,
  Menu,
  MenuItem,
  SvgIcon
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MergeIcon from '@mui/icons-material/GroupRemoveOutlined';
import CopyIcon from '@mui/icons-material/CopyAllOutlined';
import { userHasAllPermissions } from 'src/utils/general/userHasPermissions';
import { useSelector } from 'src/store';

interface PredefinedIcons {
  create: JSX.Element;
  update: JSX.Element;
  delete: JSX.Element;
  merge: JSX.Element;
  copy: JSX.Element;
}
const predefinedIcons: PredefinedIcons = {
  create: <AddIcon />,
  update: <EditIcon />,
  delete: <DeleteIcon />,
  merge: <MergeIcon />,
  copy: <CopyIcon />
}

interface MenuItemValue {
  content: JSX.Element | string;
  onClick: () => void;
  icon?: JSX.Element | keyof PredefinedIcons;
  permissions?: string[];
  condition?: boolean;
  disabled?: boolean;
}

export interface DotsMenuProps {
  positionRight?: boolean;
  menuItems: MenuItemValue[];
  bigButton?: boolean;
  disabled?: boolean;
}

const DotsMenu = ({
  positionRight = false, 
  menuItems, 
  bigButton = false,
  disabled = false
}: DotsMenuProps) => {
  const currentUser = useSelector((state) => state.general.user);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handleClose = (e: any) => {
    if(e){ e.stopPropagation(); }
    setAnchorEl(null);
  }

  // if(!currentUser){
  //   return null;
  // }

  return (
    <>
      <IconButton 
        size={bigButton ? 'medium' : 'small'} 
        onClick={handleOpen}
        disabled={disabled}
      >
        <SvgIcon>
          <MoreVertIcon />
        </SvgIcon>
      </IconButton>
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: positionRight ? 'left' : 'right'
        }}
      >
        {menuItems.map((menuItem, index) => {
          let icon = null;
          let isDisabled = !!menuItem.disabled;

          if(menuItem.icon){
            if(typeof menuItem.icon === 'string'){
              icon = predefinedIcons[menuItem.icon];
            }else{
              icon = menuItem.icon;
            }
          }

          if(menuItem.permissions?.length){
            const hasPermissions = currentUser ? userHasAllPermissions(currentUser, menuItem.permissions) : false;
            if(!hasPermissions){
              isDisabled = true
            }
          }

          if(typeof menuItem.condition !== 'undefined' && !menuItem.condition){
            return null;
          }

          return (
            <MenuItem 
              key={index} 
              onClick={(e) => {
                menuItem.onClick();
                handleClose(e);
              }}
              disabled={isDisabled}
            >
              {icon && icon}
              <span style={{paddingLeft: 10}}>
                {menuItem.content}
              </span>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DotsMenu;