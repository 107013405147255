import { EchoChannel as Channel } from '../index';

const miscHandler = (
  channel: Channel
) => {
  channel.listen('.alert', (data: any) => {
    const socketResponse = data.data.response as { text: string; };
    alert(socketResponse.text ? socketResponse.text : '-');
  });
}
export default miscHandler;