import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { PublicWidget, PublicChannel } from 'src/types/api/publicwidget';

const initialState: PublicWidget[] = [];

const slice = createSlice({
  name: 'publicwidgets',
  initialState,
  reducers: {
    setPublicWidgets(state: PublicWidget[], action: PayloadAction<PublicWidget[]>){
      return action.payload;
    },

    createPublicChannel(state: PublicWidget[], action: PayloadAction<PublicChannel>){
      return state.map((public_widget) => public_widget.id !== action.payload.public_widget_id ? public_widget : {
        ...public_widget,
        channels: [
          ...public_widget.channels,
          action.payload
        ]
      })
    },
    updatePublicChannel(state: PublicWidget[], action: PayloadAction<PublicChannel>){
      return state.map((public_widget) => public_widget.id !== action.payload.public_widget_id ? public_widget : {
        ...public_widget,
        channels: public_widget.channels.map((public_channel) => public_channel.id !== action.payload.id ? public_channel : action.payload)
      })
    }
  }
})

export const { reducer } = slice;

export const setPublicWidgets = (data: PublicWidget[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPublicWidgets(data));
}

export const createPublicChannel = (data: PublicChannel): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createPublicChannel(data));
}

export const updatePublicChannel = (data: PublicChannel): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePublicChannel(data));
}

export default slice;