import { Avatar as MuiAvatar, Box, Paper, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'src/i18n';
import DotsMenu from 'src/components/general/DotsMenu';
import type { UserSmall } from 'src/types/api/user';
import { makeStyles } from 'src/theme/makeStyles';
import getInitials from 'src/utils/general/getInitials';
import AlertCircleIcon from 'mdi-react/AlertCircleOutlineIcon';
import dayjs from 'dayjs';
import { imageUrls } from 'src/config';
import { useSelector } from 'src/store';

const useStyles = makeStyles()(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 32,
    width: 32,
    fontSize: 15
  }
}));

interface Props {
  users: UserSmall[];
  avatars: { [id: string]: any };
  user_id: string | null;
  content: string;
  date: Date;
  comment: string | null;
  is_comment: boolean;
  is_removed: boolean;
  onDelete: () => void;
  generalUser: UserSmall | null;
  disableCommentOptions?: boolean;
}

const Activity = ({ 
  users,
  avatars,
  user_id,
  content,
  date,
  comment,
  is_comment,
  is_removed,
  onDelete,
  generalUser,
  disableCommentOptions = false
}: Props) => {
  const user = users.find((user) => user.id === user_id);
  
  const currentGroup = useSelector(state => state.general.group);
  const theme = useTheme();

  
  const { classes } = useStyles();

  const {t} = useTranslation();

  const show_dots = is_comment && !is_removed && generalUser?.id === user_id;

  const getAvatarSource = () => {
    if(!user && currentGroup){
      // @ts-ignore
      if(theme.name === 'DARK'){
        return imageUrls(currentGroup.contact.id).favicon_dark;
      }
      return imageUrls(currentGroup.contact.id).favicon_light;
    }
    if(user?.id && avatars[user.id]){
      return `data:image/jpeg;base64,${avatars[user.id]}`;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2
      }}
    >
      <MuiAvatar
        className={classes.avatar} 
        // src={user && user.id && avatars[user.id] && `data:image/jpeg;base64,${avatars[user.id]}`}
        src={getAvatarSource()}
        style={!user ? { background: theme.custom.backgroundDefaultduo } : {}}
      >
        {user && getInitials(user.contact.name)}
      </MuiAvatar>
      <Box
        sx={{
          ml: 2,
          flexGrow: 1
        }}
        style={{ overflow: 'hidden', wordBreak: 'break-word' }}
      >
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', paddingTop: 7  }}>
          <div style={{ display: 'flex', position: 'relative' }}>
            <Typography
              color="textPrimary"
              variant="body2"
              style={{ marginRight: 32 }}
              dangerouslySetInnerHTML={{ __html: content }}
            >
            </Typography>
            {show_dots &&
              <div style={{ position: 'absolute', top: -10, right: 0 }}>
                <DotsMenu 
                  disabled={disableCommentOptions}
                  menuItems={[
                    {
                      content: `${t('lang.delete')}`,
                      icon: 'delete',
                      onClick: onDelete
                    }
                  ]}
                />
              </div>
            }
          </div>
          
          {is_comment && 
            <Paper
              sx={{
                backgroundColor: 'background.dark',
                p: 1
              }}
              variant="outlined"
              style={{ marginTop: 2 }}
            >
              
                {!is_removed ? (
                  <Typography
                    color="textPrimary"
                    variant="body2"
                  >
                    {comment}
                  </Typography>
                ) : (
                  <span style={{ display: 'inline-flex', alignItems: 'center', height: '100%' }}>
                    <AlertCircleIcon size='20px' style={{ marginRight: 5, lineHeight: 1.5 }} />
                    <Typography
                      color="textPrimary"
                      variant="body2"
                    >
                      {t('lang.comment_deleted')}
                    </Typography>
                  </span>
                )}
            </Paper>
          }
          
          <Typography
            color="textSecondary"
            component="p"
            variant="caption"
            style={{ marginTop: 2 }}
          >
            {/* {dateFormat(date, 'MMM dd, yyyy - HH:mm')} */}
            {dayjs(date).format('MMM DD, YYYY - HH:mm')}
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Activity;
