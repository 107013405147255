import { AxiosOptions } from 'src/hooks/general/useAxios';
import type { AppDispatch, RootState } from 'src/store/index';
import DefaultSocketData from 'src/types/api/general/defaultSocketData';
import { EchoChannel as Channel } from '../index';
import { AnyAction, Store } from 'redux';
import { createUpdateEventWithPositionsAndViews, deleteEventAndFromViews, refetchEvents } from 'src/features/event/eventStateHandler';

const eventDataHandler = (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions
) => {
  channel.listen('.create_event', async (data: any) => {
    const socketData = data.data.response as DefaultSocketData;
    createUpdateEventWithPositionsAndViews(socketData.id, store, dispatch, axiosOptions.apiConfig);
  });

  channel.listen('.update_event', async (data: any) => {
    const socketData = data.data.response as DefaultSocketData;
    createUpdateEventWithPositionsAndViews(socketData.id, store, dispatch, axiosOptions.apiConfig);
  });
  
  channel.listen('.delete_event', (data: any) => {
    const socketData = data.data.response as DefaultSocketData;
    deleteEventAndFromViews(socketData.id, store, dispatch);
  });

  channel.listen('.refetch_events', async () => {
    refetchEvents(store, dispatch, axiosOptions.apiConfig);
  });

}
export default eventDataHandler;