import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Message } from 'src/types/api/messages';

type StateType = {
  data: Message[],
  totalRows: number;
}

const initialState: StateType = {
  data: [],
  totalRows: 0
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages(state: StateType, action: PayloadAction<Message[]>){
      state.data = action.payload;
    },
    
    pushMessage(state: StateType, action: PayloadAction<Message>){
      state.data.push(action.payload);
    },
    unshiftMessage(state: StateType, action: PayloadAction<Message>){
      state.data.unshift(action.payload);
    },
    updateMessage(state: StateType, action: PayloadAction<Message>){
      state.data = state.data.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteMessage(state: StateType, action: PayloadAction<Message>){
      state.data = state.data.filter((val) => val.id !== action.payload.id);
    },

    setMessageTotalRows(state: StateType, action: PayloadAction<number>){
      state.totalRows = action.payload;
    }
  }
})

export const { reducer } = slice;

export const setMessages = (data: Message[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMessages(data));
}
export const pushMessage = (data: Message): AppThunk => async (dispatch) => {
  dispatch(slice.actions.pushMessage(data));
}
export const unshiftMessage = (data: Message): AppThunk => async (dispatch) => {
  dispatch(slice.actions.unshiftMessage(data));
}
export const updateMessage = (data: Message): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateMessage(data));
}
export const deleteMessage = (data: Message): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteMessage(data));
}

export const setMessageTotalRows = (data: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMessageTotalRows(data));
}

export default slice;