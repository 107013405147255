import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Tag } from 'src/types/api/tag';

const initialState: Tag[] = [];

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags(state: Tag[], action: PayloadAction<Tag[]>){
      return action.payload;
    },
    createTag(state: Tag[], action: PayloadAction<Tag>){
      state.push(action.payload);
    },
    updateTag(state: Tag[], action: PayloadAction<Tag>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteTag(state: Tag[], action: PayloadAction<Tag>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setTags = (data: Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTags(data));
}

export const createTag = (data: Tag): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createTag(data));
}

export const updateTag = (data: Tag): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTag(data));
}

export const deleteTag = (data: Tag): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteTag(data));
}

export default slice;