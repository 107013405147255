// import { updateGeneralGroupSetting } from 'src/slices/general/group';
// import { updateGeneralUserSetting } from 'src/slices/general/user';
import { 
  createSystemSettingCategory, 
  deleteSystemSettingCategory, 
  setSystemSettingCategories, 
  updateSystemSettingCategory,
  createSystemSetting,
  deleteSystemSetting,
  updateSystemSetting,
  setSystemSettings
} from 'src/slices/lists/systemSettings';
import type { AppDispatch } from 'src/store/index';
import { SystemSettingCategory, SystemSetting, EntitySetting } from 'src/types/api/setting';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_system_setting', (data: any) => {
    const setting = data.data.response as SystemSetting;
    dispatch(createSystemSetting(setting));
  });
  channel.listen('.update_system_setting', (data: any) => {
    const setting = data.data.response as SystemSetting;
    dispatch(updateSystemSetting(setting));
  });
  channel.listen('.delete_system_setting', (data: any) => {
    const setting = data.data.response as SystemSetting;
    dispatch(deleteSystemSetting(setting));
  });
  channel.listen('.sort_system_settings', (data: any) => {
    const settings = data.data.response as SystemSetting[];
    dispatch(setSystemSettings(settings));
  });

  channel.listen('.create_system_setting_category', (data: any) => {
    const setting_category = data.data.response as SystemSettingCategory;
    dispatch(createSystemSettingCategory(setting_category));
  });
  channel.listen('.update_system_setting_category', (data: any) => {
    const setting_category = data.data.response as SystemSettingCategory;
    dispatch(updateSystemSettingCategory(setting_category));
  });
  channel.listen('.delete_system_setting_category', (data: any) => {
    const setting_category = data.data.response as SystemSettingCategory;
    dispatch(deleteSystemSettingCategory(setting_category));
  });
  channel.listen('.sort_system_setting_categories', (data: any) => {
    const setting_categories = data.data.response as SystemSettingCategory[];
    dispatch(setSystemSettingCategories(setting_categories));
  });
  
  // create setting todo !!!
  // channel.listen('.update_setting', (data: any) => {
  //   const setting = data.data.response as EntitySetting;
  //   if(setting.type === 'user'){
  //     dispatch(updateGeneralUserSetting(setting));
  //   }
  //   if(setting.type === 'group'){
  //     dispatch(updateGeneralGroupSetting(setting));
  //   }
  //   if(setting.type === 'widget'){
  //     console.log('todo: widget');
  //   }
  // });

}