import { AdapterDayjs as MuiAdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import en_locale from 'dayjs/locale/en-gb';
import nl_locale from 'dayjs/locale/nl';
import { useSelector } from 'src/store';

const localeMap = {
  'nl': nl_locale,
  'en': en_locale
}

export const localeMasks = {
  date: {
    'nl': '__-__-____',
    'en': '__/__/____'
  },
  date_time: {
    'nl': '__-__-____ __:__',
    'en': '__/__/____ __:_M'
  }
}

const getLocale = (language?: string) => {
  const typed_language = (language ? language : 'nl') as keyof typeof localeMap;
  if(localeMap[typed_language]){
    return localeMap[typed_language];
  }
  return localeMap['nl'];
}

interface Props {
  children: React.ReactNode | React.ReactNode[];
  isMuiX?: boolean;
}

const LocalizationProvider = ({
  children
}: Props) => {
  const general_user = useSelector(state => state.general.user);
  const locale = getLocale(general_user?.language);
  const locale_str = dayjs.locale(locale);
  
  return (
    <MuiLocalizationProvider 
      dateAdapter={MuiAdapterDayjs} 
      adapterLocale={locale_str}
    >
      {children}
    </MuiLocalizationProvider>
  )
}
export default LocalizationProvider;