import { useContext, createContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';


interface TimeRegistrationValue {
  [key: string]: number;
}

interface TimeRegistrationContextValue {
  values: TimeRegistrationValue,
  setValues: Dispatch<SetStateAction<TimeRegistrationValue>>
}

const TimeRegistrationContext = createContext<TimeRegistrationContextValue>({
  values: {},
  setValues: () => {}
});

export const useTimerContext = () => {
  const context = useContext(TimeRegistrationContext);
  return context;
}

interface TimerContextProviderProps {
  children: ReactNode
}

export const TimerContextProvider = ({children}: TimerContextProviderProps) => {
  const [values, setValues] = useState<TimeRegistrationValue>({});

  return (
    <TimeRegistrationContext.Provider value={{
      values: values,
      setValues: setValues
    }}>
      {children}
    </TimeRegistrationContext.Provider>
  );
}
//TO TYPE ?
