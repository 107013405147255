import { 
  createProject,
  updateProject,
  deleteProject,
  createProjectStep, 
  updateProjectStep, 
  deleteProjectStep, 
  createTask, 
  deleteTask, 
  moveProjectStep, 
  moveProjectTask, 
  updateTask,
  refetchTask,
  titleSortStepTasks
} from 'src/slices/lists/projects';
import type { AppDispatch } from 'src/store/index';
import type { Task, Step, Project } from 'src/types/api/project';
import type { AxiosOptions } from 'src/hooks/general/useAxios';
import { AxiosInstance } from 'axios';
import { createArchivedTask, deleteArchivedTask } from 'src/slices/archives';
import { AnyAction, Store } from 'redux';
import type { RootState } from 'src/store';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch,
  axiosOptions: AxiosOptions,
  axios: AxiosInstance,
  store: Store<RootState, AnyAction>
) => {
  // Projects
  channel.listen('.create_project', (data: any) => {
    const project = data.data.response as Project;
    dispatch(createProject(project));
  });
  channel.listen('.update_project', (data: any) => {
    const project = data.data.response as Project;
    dispatch(updateProject(project));
  });
  channel.listen('.delete_project', (data: any) => {
    const project = data.data.response as Project;
    dispatch(deleteProject(project));
  });
  
  channel.listen('.create_project_step', (data: any) => {
    const step = data.data.response as Step;
    dispatch(createProjectStep(step));
  });
  channel.listen('.update_project_step', (data: any) => {
    const step = data.data.response as Step;
    dispatch(updateProjectStep(step));
  });
  channel.listen('.delete_project_step', (data: any) => {
    const step = data.data.response as Step;
    dispatch(deleteProjectStep(step));
  });

  type SortProjectStepsData = { project_id: string; step_id: string; destination_index: number; };
  // move_project_step
  channel.listen('.sort_project_steps', (data: any) => {
    const { project_id, step_id, destination_index } = data.data.response as SortProjectStepsData;
    dispatch(moveProjectStep({
      project_id: project_id,
      step_id: step_id,
      destination_index: destination_index
    }));
  });

  // Tasks
  channel.listen('.create_task', (data: any) => {
    const task = data.data.response as Task;
    dispatch(createTask(task));
  });

  channel.listen('.update_task', (data: any) => {
    const task = data.data.response as Task;
    dispatch(updateTask(task));
    // update archived tasks ???
  });

  channel.listen('.delete_task', (data: any) => {
    const task = data.data.response as Task;
    dispatch(deleteTask(task));

    const task_project = store.getState().lists.projects.projects.find((p) => p.steps.find((step) => step.id === task.step_id));
    if(task_project){
      dispatch(deleteArchivedTask({ project_id: task_project.id, data: task }));
    }
  });

  // UNUSED DISPATCH ?!?!!
  channel.listen('.delete_tasks', (data: any) => {
    const tasks = data.data.response as Task[];
    tasks.map((task) => {
      dispatch(deleteTask(task));
      
      const task_project = store.getState().lists.projects.projects.find((p) => p.steps.find((step) => step.id === task.step_id));
      if(task_project){
        dispatch(deleteArchivedTask({ project_id: task_project.id, data: task }));
      }

    });
  });

  // create, update, delete
  type SortTasksData = { task_id: string; destination_step_id: string; destination_index: number; triggers: string[]; };
  // move_project_step
  channel.listen('.sort_tasks', (data: any) => {
    const { task_id, destination_step_id, destination_index, triggers } = data.data.response as SortTasksData;
    dispatch(moveProjectTask({
      task_id: task_id,
      destination_step_id: destination_step_id,
      destination_index: destination_index
    }));

    triggers.map((trigger) => {
      switch(trigger){
        case 'finish': {
          dispatch(refetchTask(task_id, axiosOptions, axios));
          break;
        }
        case 'unfinish': {
          dispatch(refetchTask(task_id, axiosOptions, axios));
          break;
        }
      }
    });
  });

  channel.listen('.sort_step_tasks', (data: any) => {
    const { step_id, sort, direction } = data.data.response as { step_id: string; sort: 'title' | 'priority' | 'start_date', direction: 'asc' | 'desc' };
    dispatch(titleSortStepTasks({ step_id, sort, direction }));
  });

  // archive
  channel.listen('.archive_task', (data: any) => {    
    const task = data.data.response as Task;
    const task_project = store.getState().lists.projects.projects.find((p) => p.steps.find((step) => step.id === task.step_id));

    if(task_project){
      console.log('task project found !!!');

      dispatch(deleteTask(task));
      dispatch(createArchivedTask({ project_id: task_project.id, data: task }));
    }

    // delete task from non archived
    // add task to archived if archived is open
  });

  channel.listen('.revert_archive_task', (data: any) => {    
    // delete task from non archived if archived is open
    // add task to archived
    const task = data.data.response as Task;
    const task_project = store.getState().lists.projects.projects.find((p) => p.steps.find((step) => step.id === task.step_id));

    if(task_project){
      dispatch(createTask(task));
      dispatch(deleteArchivedTask({ project_id: task_project.id, data: task }));
    }
  });

}