import { createAbsence, updateAbsence, deleteAbsence } from 'src/slices/lists/absences';
import type { AppDispatch } from 'src/store/index';
import { Absence } from 'src/types/api/absence';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_absence', (data: any) => {
    const absence = data.data.response as Absence | Absence[];
    if(Array.isArray(absence)){
      absence.map((absence) => dispatch(createAbsence(absence)));
      return;
    }
    dispatch(createAbsence(absence));
  });
  channel.listen('.update_absence', (data: any) => {//
    const absence = data.data.response as Absence | Absence[];
    if(Array.isArray(absence)){
      absence.map((absence) => dispatch(updateAbsence(absence)));
      return;
    }
    dispatch(updateAbsence(absence));
  });
  channel.listen('.delete_absence', (data: any) => {//
    const absence = data.data.response as Absence | Absence[];
    if(Array.isArray(absence)){
      absence.map((absence) => dispatch(deleteAbsence(absence)));
      return;
    }
    dispatch(deleteAbsence(absence));
  });
}