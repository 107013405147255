import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Upload, Download } from 'src/types/api/dataTransfer';

interface InitialState {
  downloads: Download[],
  uploads: Upload[]
}

const initialState: InitialState = {
  downloads: [], 
  uploads: []
}

const slice = createSlice({
  name: 'dataTransfer',
  initialState: initialState,
  reducers: {
    setDownloads(state: InitialState, action: PayloadAction<Download[]>){
      state.downloads = action.payload;
    },
    createDownload(state: InitialState, action: PayloadAction<Download>){
      state.downloads.push(action.payload);
    },
    updateDownload(state: InitialState, action: PayloadAction<Download>){
      state.downloads = state.downloads.map((download) => download.id === action.payload.id ? action.payload : download) ;
    },
    deleteDownload(state: InitialState, action: PayloadAction<Download>){
      state.downloads = state.downloads.filter((download) => download.id !== action.payload.id);
    },

    // Upload

    setUploads(state: InitialState, action: PayloadAction<Upload[]>){
      state.uploads = action.payload;
    },
    createUpload(state: InitialState, action: PayloadAction<Upload>){
      state.uploads.push(action.payload);
    },
    updateUpload(state: InitialState, action: PayloadAction<Upload>){
      state.uploads = state.uploads.map((upload) => upload.id === action.payload.id ? action.payload : upload) ;
    },
    deleteUpload(state: InitialState, action: PayloadAction<Upload>){
      state.uploads = state.uploads.filter((upload) => upload.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setDownloads = (payload: Download[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDownloads(payload));
}
export const createDownload = (payload: Download): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createDownload(payload));
}
export const updateDownload = (payload: Download): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateDownload(payload));
}
export const deleteDownload = (payload: Download): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteDownload(payload));
}

export const setUploads = (payload: Upload[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setUploads(payload));
}
export const createUpload = (payload: Upload): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createUpload(payload));
}
export const updateUpload = (payload: Upload): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateUpload(payload));
}
export const deleteUpload = (payload: Upload): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteUpload(payload));
}

export default slice;