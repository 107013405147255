import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  SvgIcon,
  Typography,
  colors
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import DotsMenu from 'src/components/general/DotsMenu';
import PermissionChecker from 'src/components/general/PermissionChecker';
import SuperScrollbar from 'src/components/general/SuperScrollbar';
import useIsMobile from 'src/hooks/general/useIsMobile';
import { useTranslation } from 'src/i18n';
import { useSelector } from 'src/store';
import { makeStyles } from 'src/theme/makeStyles';
import type { MenuItem } from 'src/types/api/menu';
import getInitials from 'src/utils/general/getInitials';
import NavItem from './NavItem';
import StorageBar from './StorageBar';
import SubscriptionExpiryAlert from './SubscriptionExpiryAlert';
import SupportToggle from './SupportToggle';

const useStyles = makeStyles()((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 295,
    }),
    overflow: 'hidden'
  },
  desktopDrawerClosed: {
    width: 75,
    height: '100%',
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transition: `filter ${theme.transitions.duration.enteringScreen}ms`
  },
  avatarMenuInactive: {
    filter: 'grayscale(100%)'
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  navigation: {
    overflow: 'auto',
    flexGrow: 1,
    opacity: 1,
  },

  contentWrapper: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  contentWrapperInactive: {
    opacity: '0.2 !important',
    pointerEvents: 'none'
  },

  // navigation: {
  //   overflow: 'auto',
  //   flexGrow: 1
  // },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    backgroundColor: colors.green[600]
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  listSubheaderRoot: {
    lineHeight: '33px',
    marginLeft: '10px',
    fontWeight: '200'
  }
}));

interface RenderNavItemsProps {
  items: MenuItem[];
  fullyOpen: boolean;
  menuInactive: boolean;
  [key: string]: any;
}

const renderNavItems = ({ items, fullyOpen, menuInactive, ...rest }: RenderNavItemsProps) => (
  <List disablePadding>
    {items.reduce(
      (acc, item, index) => reduceChildRoutes({ acc, item, fullyOpen, menuInactive, index, ...rest }),
      []
    )}
  </List>
);

interface ReduceChildRouteProps {
  acc: any[];
  item: MenuItem;
  pathname?: string;
  depth?: number;
  fullyOpen: boolean;
  menuInactive: boolean;
  index: number;
}

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth = 0,
  fullyOpen,
  menuInactive,
  index
}: ReduceChildRouteProps) => {
  const key = item.title;
  const open = item.url && pathname ? pathname.includes(item.url) : false;

  if (item.children.length) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={index}
        open={open}
        title={item.title}
        highlight={open}
        label={item.label}
        fullyOpen={fullyOpen}
        menuInactive={menuInactive}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.children,
          fullyOpen,
          menuInactive
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.url}
        icon={item.icon}
        key={index}
        title={item.title}
        highlight={open}
        label={item.label}
        fullyOpen={fullyOpen}
        menuInactive={menuInactive}
      />
    );
  }

  return acc;
}

interface Props {
  openMobile: boolean;
  onMobileClose: () => void;
  fullyOpen: boolean;
  setFullyOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navPinned: boolean;
  setNavPinned: React.Dispatch<React.SetStateAction<boolean>>;
  menuInactive: boolean;
}

const NavBar = ({
  openMobile,
  onMobileClose,
  fullyOpen,
  setFullyOpen,
  navPinned,
  setNavPinned,
  menuInactive
}: Props) => {
  const { classes } = useStyles();
  const location = useLocation();
  const user = useSelector((state) => state.general.user);
  const menu = useSelector(state => state.general.menu);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const group = useSelector(state => state.general.group);

  const { t } = useTranslation();

  const avatars = useSelector(state => state.misc.avatars);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      setFullyOpen(true);
      setNavPinned(true);
    }
  }, [isMobile]);

  const handleNavToggle = () => {
    if (navPinned) {
      setFullyOpen(false);
    } else {
      setFullyOpen(true);
    }
    setNavPinned(!navPinned);
  }

  const content = (
    <>
      <nav className={clsx(classes.navigation, classes.contentWrapper, menuInactive && classes.contentWrapperInactive)}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <SuperScrollbar>
            {fullyOpen ?
              <Box p={2} style={{ overflowX: 'hidden' }}>
                {menu.map((menuItem, index) => (
                  <List
                    key={menuItem.title}
                    subheader={(
                      <ListSubheader
                        disableGutters
                        disableSticky
                        classes={{ root: classes.listSubheaderRoot }}
                      >
                        {index !== 0 && t(menuItem.title)}
                      </ListSubheader>
                    )}
                  >
                    {renderNavItems({ items: menuItem.children, pathname: location.pathname, fullyOpen, menuInactive })}
                  </List>
                ))}
              </Box>
              :
              <Box p={2} style={{ overflowX: 'hidden' }}>
                {menu.map((menuItem, index) => (
                  <React.Fragment key={menuItem.title}>
                    {index > 0 && <Divider />}
                    <List
                      style={index > 0 ? { paddingBottom: '20px', paddingTop: '20px' } : { paddingBottom: '20px' }}
                      subheader={(
                        <ListSubheader
                          disableGutters
                          disableSticky
                        />
                      )}
                    >
                      <List disablePadding>
                        {menuItem.children.reduce((acc, item, index) =>
                          reduceChildRoutes({ acc, item, pathname: location.pathname, fullyOpen, menuInactive, index })
                          , [])
                        }
                      </List>
                    </List>
                  </React.Fragment>
                ))}
              </Box>
            }
          </SuperScrollbar>
        </Box>
      </nav>

      {group && group.subscription.expiry_date && (
        <>
          <Divider />
          <SubscriptionExpiryAlert fullyOpen={fullyOpen} />
        </>
      )}

      <Divider />
      <div
        className={clsx(classes.profile, classes.contentWrapper, menuInactive && classes.contentWrapperInactive)}
        style={{
          position: 'relative',
          marginBottom: fullyOpen ? 20 : 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start'
        }}
      >
        <div 
          style={{ display: 'flex', width: '100%' }}
        >

          {/* <SupportBadgeWrapper> */}
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              classes={{
                dot: classes.badgeDot,
                badge: classes.badge
              }}
              variant="dot"
            >

              {fullyOpen ?
                <Avatar
                  className={clsx(classes.avatar, menuInactive && classes.avatarMenuInactive)}
                  src={user?.contact.id && avatars[user.contact.id] && `data:image/jpeg;base64,${avatars[user.contact.id]}`}
                  onClick={() => navigate('/profile')}
                >
                  {getInitials(user?.contact.name)}
                </Avatar>
                :
                <Avatar
                  className={clsx(classes.avatar, menuInactive && classes.avatarMenuInactive)}
                  src={user?.contact.id && avatars[user.contact.id] && `data:image/jpeg;base64,${avatars[user.contact.id]}`}
                >
                  {getInitials(user?.contact.name)}
                </Avatar>
              }
            </Badge>
          {/* </SupportBadgeWrapper> */}

          {fullyOpen &&
            <>
              <div className={classes.details}>
                <Typography
                  variant="h5"
                  noWrap
                >
                  {user?.contact.name}
                </Typography>
                <Typography variant="body2">{user?.role.name}</Typography>
              </div>

              {/* <div style={{ marginLeft: 'auto' }}>
                <DotsMenu
                  menuItems={[
                    {
                      content: `${t('lang.profile')}`,
                      onClick: () => navigate('/profile')
                    },
                    {
                      content: `${t("lang.settings")}`,
                      onClick: () => console.log('TODO')
                    },
                    {
                      content: `${t('lang.logout')}`,
                      onClick: () => console.log('TODO')
                    }
                  ]}
                />
              </div> */}
            </>
          }
        </div>
        {fullyOpen && 
        <PermissionChecker 
          permissions={['read_support']}
        >
          <SupportToggle />
        </PermissionChecker>}
      </div>
      {fullyOpen &&
        <div
          style={{ opacity: 1 }}
          className={clsx(menuInactive && classes.contentWrapper, menuInactive && classes.contentWrapperInactive)}
        >
          <StorageBar />
        </div>
      }
    </>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden mdDown>
      <Drawer
        anchor="left"
        classes={{ paper: clsx(classes.desktopDrawer, !fullyOpen && classes.desktopDrawerClosed) }}
        open
        variant="persistent"
        onMouseEnter={() => {
          setFullyOpen(true);
        }}
        onMouseLeave={() => {
          if (!navPinned) {
            setFullyOpen(false);
          }
        }}
      >
        <div
          className={clsx(classes.contentWrapper, menuInactive && classes.contentWrapperInactive)}
          style={{
            minHeight: '64px',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingRight: '15px'
          }}
        >
          <IconButton color="inherit" onClick={handleNavToggle} size="large">
            <SvgIcon fontSize="small">
              {navPinned ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </SvgIcon>
          </IconButton>
        </div>
        <Divider />
        {content}
      </Drawer>
    </Hidden>
  </>
}

export default NavBar