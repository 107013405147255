import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'src/i18n';
import type { Project, Step } from 'src/types/api/project';
import { makeStyles } from 'src/theme/makeStyles';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles()(theme => ({
  movingItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    marginBottom: 5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.custom.backgroundDark
    }
  },
  movingItemStepColor: {
    borderRadius: '50%',
    width: 12,
    height: 12,
    marginRight: 10,
    marginLeft: 5
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
  project?: Project;
  selectedStepId?: string | null;
  onChange: (step: Step) => void;
}

const SelectStep = ({
  open,
  onClose,
  project,
  selectedStepId,
  onChange
}: Props) => {
  const {t} = useTranslation();
  const { classes } = useStyles();

  const handleChange = (step: Step) => {
    onChange(step);
    onClose();
  }

  const sortedSteps = project ? [...project.steps].sort((a,b) => {
    if(a.sort_order > b.sort_order){ return 1; }
    if(a.sort_order < b.sort_order){ return -1; }
    return 0;
  }) : [];

  if(!project || !selectedStepId){
    return null;
  }
  
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={() => {
        onClose && onClose();
      }}
      maxWidth='xs'
    >
      <div style={{ padding: 24 }}>
        <Typography
          variant='h3'
          style={{ marginBottom: 12 }}
        >
          {t('lang.select_status')}
        </Typography>
        <Typography
          variant='body2'
          style={{ marginBottom: 12 }}
        >
          {t('description.select_status')}
        </Typography>
        {sortedSteps.map((step) => (
          <div
            key={step.id}
            className={classes.movingItem}
            onClick={() => handleChange(step)}
          >
            <div
              className={classes.movingItemStepColor}
              style={{ backgroundColor: step.color ? step.color : '#000' }}
            />
            <Typography>
              {step.title} 
            </Typography>
            {step.id === selectedStepId &&
              <span style={{ marginLeft: 'auto', marginRight: 5 }}>
                <CheckIcon />
              </span>
            }
          </div>
        ))}
      </div>
    </Dialog>
  )
}

export default SelectStep;