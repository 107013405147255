import dayjs from "dayjs";

interface Times {
  start_time: dayjs.Dayjs | null;
  end_time: dayjs.Dayjs | null;
}

const timeOrderCorrect = (times: Times[]) => {
  const timeValid = !times.find((t) => t.start_time === null || t.end_time === null);
  if(!timeValid){
    return false;
  }

  let timeOrderIsCorrect = true;
  let last_end_time: dayjs.Dayjs | null = null;
  times.map((time) => {
    if (!time.end_time!.isAfter(time.start_time, 'minute')) {
      timeOrderIsCorrect = false;
    }

    if (!last_end_time) {
      last_end_time = time.end_time;
      return;
    }
    if (!time.start_time!.isAfter(last_end_time, 'minute')) {
      timeOrderIsCorrect = false;
    }
  });

  let timesAreOnSameDate = true;
  if (times.length) {
    const firstTime = times[0].start_time;
    const lastTime = times[times.length - 1].end_time;
    if (!firstTime!.isSame(lastTime, 'date')) {
      timesAreOnSameDate = false;
    }
  }

  return timeValid && timeOrderIsCorrect && timesAreOnSameDate;
}
export default timeOrderCorrect;