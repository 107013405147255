import {
  createProduct,
  updateProduct,
  deleteProduct,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory
} from 'src/slices/lists/products';
import type { AppDispatch } from 'src/store/index';
import type { Product, ProductCategory } from 'src/types/api/product';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_product', (data: any) => {
    const product = data.data.response as Product;
    dispatch(createProduct(product));
  });
  channel.listen('.update_product', (data: any) => {
    const product = data.data.response as Product;
    dispatch(updateProduct(product));
  });
  channel.listen('.delete_product', (data: any) => {
    const product = data.data.response as Product;
    dispatch(deleteProduct(product));
  });

  channel.listen('.create_product_category', (data: any) => {
    const productCategory = data.data.response as ProductCategory;
    dispatch(createProductCategory(productCategory));
  });
  channel.listen('.update_product_category', (data: any) => {
    const productCategory = data.data.response as ProductCategory;
    dispatch(updateProductCategory(productCategory));
  });
  channel.listen('.delete_product_category', (data: any) => {
    const productCategory = data.data.response as ProductCategory;
    dispatch(deleteProductCategory(productCategory));
  });
}