import { Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'src/i18n';
import CityField from 'src/components/forms/fields/Address/City';
import HouseNumberField from 'src/components/forms/fields/Address/HouseNumber';
import ProvinceField from 'src/components/forms/fields/Address/Province';
import StreetField from "src/components/forms/fields/Address/Street";
import { geoDataCall } from 'src/components/forms/fields/Address/useAddressAutofillController';
import ZipCodeField from "src/components/forms/fields/Address/ZipCode";
import DuotoneDialog from "src/components/general/DuotoneDialog";
import { ContactAddressLabel } from "src/types/api/contact";

const DEFAULT_ADDRESS_VALUES: AddressValues = {
  type: 'general',
  zipcode: '',
  city: '',
  street: '',
  houseNumber: '',
  province: ''
}

export interface AddressValues {
  type: ContactAddressLabel;
  zipcode: string;
  city: string;
  street: string;
  houseNumber: string;
  province: string;
  // is invoice ???
}
const addressTypes: AddressValues['type'][] = [
  'general', 
  'delivery', 
  'business', 
  'visiting', 
  'private', 
  'invoicing', 
  'quotes', 
  'other'
];

interface Props {
  values?: AddressValues;
  open: boolean;
  onChange: (value: AddressValues) => void; // TODO: value type
  onClose: () => void;
}

const AddAddressDialog = ({
  values,
  open,
  onChange,
  onClose,
}: Props) => {
  const {t} = useTranslation();

  const [localValues, setLocalValues] = useState<AddressValues>(DEFAULT_ADDRESS_VALUES);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

  const handleZipOrNrBlur = async () => {
    if(localValues.zipcode.length && localValues.houseNumber.length){
      const result = await geoDataCall(localValues.zipcode, localValues.houseNumber);
      if(result){
        setLocalValues(prev => ({
          ...prev,
          street: result.street,
          city: result.city,
          province: result.province
        }));
        return;
      }
    }

    setLocalValues(prev => ({
      ...prev,
      street: '',
      city: '',
      province: ''
    }));
  }

  useEffect(() => {
    if(open){
      setLocalValues(values ? values : DEFAULT_ADDRESS_VALUES);
      setErrors({});
    }
  }, [open, values]);

  const handleSubmit = () => {
    let newErrors: { [key: string]: boolean } = {};
    Object.keys(localValues).forEach(l_key => {
      const key = l_key as keyof AddressValues;
      if(!localValues[key].length){
        newErrors[key] = true;
      }
    });

    if(Object.keys(newErrors).length){
      setErrors(newErrors);
      return;
    }
    
    onChange(localValues)
    onClose();
  }

  return (
    <DuotoneDialog
      open={open}
      title={values ? `${t('lang.update_address')}` : `${t('lang.add_address')}`}
      onSubmit={handleSubmit}
      onClose={onClose}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ZipCodeField
            value={localValues.zipcode}
            onChange={(val) => setLocalValues(prev => ({ ...prev, zipcode: val }))}
            // addressAutofillController={addressAutofillController}
            textFieldProps={{
              error: !!errors.zipcode,
              onBlur: () => handleZipOrNrBlur()
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <HouseNumberField
            value={localValues.houseNumber}
            onChange={(val) => setLocalValues(prev => ({ ...prev, houseNumber: val }))}
            textFieldProps={{
              error: !!errors.houseNumber,
              onBlur: () => handleZipOrNrBlur()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <StreetField
            value={localValues.street}
            onChange={(val) => setLocalValues(prev => ({ ...prev, street: val }))}
            // addressAutofillController={addressAutofillController}
            textFieldProps={{
              error: !!errors.street
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CityField
            value={localValues.city}
            onChange={(val) => setLocalValues(prev => ({ ...prev, city: val }))}
            // addressAutofillController={addressAutofillController}
            textFieldProps={{
              error: !!errors.city
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ProvinceField
            value={localValues.province}
            onChange={(val) => setLocalValues(prev => ({ ...prev, province: val }))}
            // addressAutofillController={addressAutofillController}
            textFieldProps={{
              error: !!errors.province
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={`${t('lang.type')}`}
            variant='standard'
            value={localValues.type}
            onChange={(e) => {
              const val = e.target.value as AddressValues['type'];
              setLocalValues(prev => ({ ...prev, type: val }));
            }}
            select
            fullWidth
          >
            {addressTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {t(`lang.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </DuotoneDialog>
  )
}
export default AddAddressDialog;