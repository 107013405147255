import { Typography } from "@mui/material";

interface Props {
  title: string;
  disableMarginTop?: boolean;
  children: React.ReactChild | React.ReactChild[];
}

const ActionButtonGroup = ({
  title,
  disableMarginTop = false,
  children
}: Props) => {
  return (
    <>
      <Typography
        color="textPrimary"
        component="h4"
        style={{ 
          fontWeight: 600,
          marginTop: disableMarginTop ? 0 : 18
        }}
        variant="overline"
      >
        {title}
      </Typography>
      {children}
    </>
  )
}

export default ActionButtonGroup;