import axios, { AxiosRequestConfig } from "axios";
import { AnyAction, Store } from "redux";
import { RecurringUpdateDeleteRange } from "src/features/event/components/RecurringUpdateDialog";
import { createUpdateEventWithPositionsAndViews, deleteEventAndFromViews } from "src/features/event/eventStateHandler";
import { AppDispatch, RootState } from "src/store";
import { Event } from "src/types/api/events/Event";
import { ResponseWithRowCounts } from "src/types/api/general/collated";
// import { AppDispatch } from "src/store";


export const getEventsRequest = (
  start: number,
  // dispatch: AppDispatch,
  config: AxiosRequestConfig,
  urlParams?: URLSearchParams
  // setTotalRows: boolean = true
): Promise<ResponseWithRowCounts<Event[]>> => {
  return new Promise<ResponseWithRowCounts<Event[]>>((resolve, reject) => {
    let url = `events?start=${start}`;
    if(urlParams){
      url += `&${urlParams.toString()}`;
    }
    axios.get<ResponseWithRowCounts<Event[]>>(url, config)
    .then((response) => {
      resolve(response.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const getEventRequest = (
  id: string,
  config: AxiosRequestConfig
): Promise<Event> => {
  return new Promise<Event>((resolve, reject) => {
    axios.get<Event>(`events/${id}`, config)
    .then((response) => {
      resolve(response.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export interface CreateAppointmentEventRequestDataBlockChild {
  id: string | null;
  start_date: string;
  end_date: string;
  members: string[];
  service: { id: string; }
}

export interface CreateAppointmentEventRequestDataBlock {
  id: string | null;
  start_date: string;
  end_date: string;
  members: string[];

  all_day: false;
  all_users: false;
  // blocking: true;

  service: {
    id: string;
    price: number;
  };

  children: CreateAppointmentEventRequestDataBlockChild[];
}

interface CreateAppointmentEventRequestDataTask {
  name: string;
  finished: boolean;
}

export interface CreateAppointmentEventRequestData {
  type: 'appointment';
  status_id: string;
  contact_id: string | null;
  no_show: boolean;
  tag_ids: string[];
  file_ids: string[];
  description: string;
  blocks: CreateAppointmentEventRequestDataBlock[];
  tasks: CreateAppointmentEventRequestDataTask[];
  recurring?: string;
}

export interface CreateAbsenceEventRequestData {
  type: 'absence';
  name: string;
  status_id: string;
  tag_ids: string[];
  blocks: [{
    id: string | null;
    start_date: string;
    end_date: string;
    all_day: boolean;
    all_users: boolean;
    members: string[]
  }]
}

interface CreateTaskEventRequestDataTask {
  name: string;
  finished: boolean;
}
export interface CreateTaskEventRequestData {
  type: 'task';
  name: string;
  description: string;
  status_id: string;
  tag_ids: string[];
  file_ids: string[];
  priority: number;
  contact_id: string | null;
  blocks: [{
    id: string | null;
    start_date: string;
    end_date: string;
    all_day: boolean;
    all_users: boolean;
    members: string[];
  }];
  tasks: CreateTaskEventRequestDataTask[];
  unconfirmed_activities?: string[];
  recurring?: string;
}

export interface CreateNoteEventRequestData {
  type: 'note';
  description: string;
  tag_ids: string[];
  status_id: string;
  blocks: [{
    id: string | null;
    start_date: string;
    end_date: string;
    all_day: true;
    all_users: boolean;
    members: string[];
    blocking: false;
  }],
}

type CreateEventRequestData = CreateAppointmentEventRequestData | CreateAbsenceEventRequestData | CreateTaskEventRequestData | CreateNoteEventRequestData;

export const createEventRequest = (
  data: CreateEventRequestData,
  store: Store<RootState, AnyAction>,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Event> => {
  return new Promise<Event>((resolve, reject) => {
    axios.post<Event>(`events`, data, config)
    .then((response) => {
      createUpdateEventWithPositionsAndViews(response.data.id, store, dispatch, config).then(() => {
        resolve(response.data);
      });
    })
    .catch((e) => {
      reject(e);
    });
  });
}


// TODO: Change these names and add types (has nothing to do with block even though it is in the name)

// export interface UpdateAppointmentEventRequestDataBlockChild extends CreateAppointmentEventRequestDataBlockChild {
//   id: string;
// };

export interface UpdateAppointmentEventRequestDataBlock extends CreateAppointmentEventRequestDataBlock {};
export interface UpdateAppointmentEventRequestDataBlockChild extends CreateAppointmentEventRequestDataBlockChild {
  id: string | null; // ??? maybe not null ???
};

export interface UpdateAppointmentEventRequestData extends CreateAppointmentEventRequestData {};
export interface UpdateAbsenceEventRequestData extends CreateAbsenceEventRequestData {};
export interface UpdateTaskEventRequestData extends CreateTaskEventRequestData {
  recurring_update_range?: RecurringUpdateDeleteRange;
};
export interface UpdateNoteEventRequestData extends CreateNoteEventRequestData {};

// export type UpdateEventRequestData = any;
export type UpdateEventRequestData = 
  UpdateAppointmentEventRequestData | 
  UpdateAbsenceEventRequestData |
  UpdateTaskEventRequestData | 
  UpdateNoteEventRequestData;


export const updateEventRequest = (
  id: string,
  data: UpdateEventRequestData,
  store: Store<RootState, AnyAction>,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  dispatches: boolean = true
): Promise<Event> => {
  return new Promise<Event>((resolve, reject) => {
    axios.put<Event>(`events/${id}`, data, config)
    .then((response) => {
      if(!dispatches){
        resolve(response.data);
        return;
      }

      createUpdateEventWithPositionsAndViews(response.data.id, store, dispatch, config).then(() => {
        resolve(response.data);
      });
    })
    .catch((e) => {
      reject(e);
    });
  });
}


export const deleteEventRequest = (
  id: string,
  store: Store<RootState, AnyAction>,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  recurringUpdateDeleteRange: RecurringUpdateDeleteRange | null = null
): Promise<Event> => {
  return new Promise<Event>((resolve, reject) => {
    let data: {
      _method: string;
      delete_type?: string;
    } = {
      _method: 'DELETE'
    };

    if (typeof recurringUpdateDeleteRange === 'string') {
      data.delete_type = recurringUpdateDeleteRange;
    }

    axios.post<Event>(`events/${id}`, data, config)
    .then((response) => {
      deleteEventAndFromViews(response.data.id, store, dispatch);
      resolve(response.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}

interface EventOverlapsCheckRequestData {
  start_date: string;
  end_date: string;
  ignore_ids: string[];
  all_users: boolean;
  users: string[];
}

interface EventOverlapsCheckResponse {
  overlaps: boolean;
}


export const eventOverlapsCheckRequest = (
  data: EventOverlapsCheckRequestData,
  config: AxiosRequestConfig
): Promise<EventOverlapsCheckResponse> => {
  return new Promise<EventOverlapsCheckResponse>((resolve, reject) => {
    axios.post<EventOverlapsCheckResponse>(`events/overlaps`, data, config)
    .then((response) => {
      resolve(response.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const sendAppointmentEventConfirmationEmailRequest = (
  event_id: string,
  email: string,
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.post(`events/${event_id}/confirmationEmail`, { email }, config)
    .then((response) => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const sendAppointmentEventMovedEmailRequest = (
  event_id: string,
  email: string,
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.post(`events/${event_id}/movedEmail`, { email }, config)
    .then((response) => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
  });
}

interface MoveEventRequestData {
  event_status_id: string;
  index: number;
}

export const moveEventRequest = (
  event_id: string,
  data: MoveEventRequestData, 
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.put(`events/${event_id}/move`, data, config)
    .then((response) => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
  });
}