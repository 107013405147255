import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Giftcard } from 'src/types/api/giftcard';

const initialState: Giftcard[] = [];

const slice = createSlice({
  name: 'giftcards',
  initialState,
  reducers: {
    setGiftcards(state: Giftcard[], action: PayloadAction<Giftcard[]>){
      return action.payload;
    },
    createGiftcard(state: Giftcard[], action: PayloadAction<Giftcard>){
      state.push(action.payload);
    },
    updateGiftcard(state: Giftcard[], action: PayloadAction<Giftcard>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteGiftcard(state: Giftcard[], action: PayloadAction<Giftcard>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setGiftcards = (data: Giftcard[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGiftcards(data));
}
export const createGiftcard = (data: Giftcard): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createGiftcard(data));
}
export const updateGiftcard = (data: Giftcard): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateGiftcard(data));
}
export const deleteGiftcard = (data: Giftcard): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteGiftcard(data));
}

export default slice;