import CreateComment from '../components/CreateComment';
import { useSelector, useDispatch } from 'src/store';
import { useAxiosOptions } from 'src/hooks/general/useAxios';
import axios from 'axios';
import type { Activity } from 'src/types/api/activity';
import CActivity from '../components/Activity';
import Header from '../components/Header';
import { useState, useEffect, useMemo } from 'react';
import { setActivity } from 'src/slices/activity';
import { useUsersNoCustomer } from 'src/store/customSelectors';

interface Props {
  objectId: string | null;
  // category: string;
  // urlPrefix: string;
  hideCommentField?: boolean;
  disabled?: boolean;
  unconfirmedActivities?: Activity[];
  onNewUnconfirmedActivity?: (activity: Activity) => void;
}

const ActivityList = ({
  objectId,
  // category,
  // urlPrefix,
  hideCommentField = false,
  disabled = false,
  unconfirmedActivities,
  onNewUnconfirmedActivity
}: Props) => {
  const axiosOptions = useAxiosOptions();
  const dispatch = useDispatch();

  const all_activity_items = useSelector((state) => state.activity);
  const general_user = useSelector(state => state.general.user);
  const users = useUsersNoCustomer();
  
  const avatars = useSelector(state => state.misc.avatars);

  // const items = all_activity_items[objectId] ? all_activity_items[objectId] : []; 
  const items = useMemo(() => {
    let activities = objectId ? [
      ...(all_activity_items[objectId] ? all_activity_items[objectId] : []), 
    ] : [];

    unconfirmedActivities?.forEach(activity => {
      const found = activities.find(a => a.id === activity.id);
      if(!found){
        activities.unshift(activity);
      }
    });

    activities.sort((a,b) => {
      if(a.index < b.index){ return 1; }
      if(a.index > b.index){ return -1; }
      return 0;
    });

    return activities;
  }, [objectId, all_activity_items, unconfirmedActivities]);

  const [showDetails, setShowDetails] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const handleFetchItems = () => {
    if(!objectId) return;

    if(!all_activity_items[objectId]){
      setIsLoading(true);
      axios.get<Activity[]>(`activity/${objectId}`, axiosOptions.apiConfig)
      .then((response) => {
        setIsLoading(false);
        dispatch(setActivity({
          object_id: objectId,
          activity: [
            ...items,
            ...response.data
          ]
        }));
      })
      .catch((error) => {
      });
    }
  }
  useEffect(() => { handleFetchItems(); }, []);

  const handleDeleteComment = (activity: Activity) => {
    if(!objectId) return;

    axios.delete<Activity>(`activity/${objectId}/comment/${activity.id}`, axiosOptions.apiConfig)
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const handleCreateComment = (content: string) => {
    axios.post<Activity>(`activity/comment`, { 
      object_id: objectId ? objectId : null, 
      content 
    }, axiosOptions.apiConfig)
    .then((response) => {
      if(!response.data.confirmed)
        onNewUnconfirmedActivity && onNewUnconfirmedActivity(response.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  if(!general_user){
    return null;
  }

  return (
    <>
      <Header 
        show={showDetails}
        onChange={setShowDetails}
        style={{ marginBottom: 12 }}
        isLoading={isLoading}
      />
      {!hideCommentField &&
        <CreateComment
          // onCreate={(value) => onCommentCreate && onCommentCreate(value)}
          onCreate={handleCreateComment}
          generalUser={general_user}
          style={{ marginBottom: 24, marginRight: 4 }}
          disabled={disabled}
        />
      }
      {/* {isLoading &&
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      } */}
      {/* Map activity */}
      {items.map((item) => {
        if(!showDetails && !item.is_primary){
          return null;
        }
        return (
          <CActivity
            key={item.id}
            users={users}
            avatars={avatars}
            user_id={item.user_id}
            content={item.content}
            date={new Date(item.created_at)}
            comment={item.comment}
            is_comment={item.is_comment}
            is_removed={item.is_removed}
            onDelete={() => handleDeleteComment(item)}
            generalUser={general_user}
            disableCommentOptions={disabled}
          /> 
        )
      })}
    </>
  )
}

export default ActivityList;