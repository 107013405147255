import { Dialog as MuiDialog, Typography, IconButton, Button, useTheme, alpha } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'src/i18n';
import clsx from 'clsx';

const useStyles = makeStyles()((theme) => ({
  rootDiv: {
    padding: 25,
    position: 'relative',
  },
  cancelButton: {
    color: alpha(theme.palette.text.primary, 0.7),
    marginRight: 5,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.primary, 0.1)
    }
  }
}));

interface Props {
  title?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  isSubmitting?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  isDelete?: boolean;
}

const Dialog = ({
  title,
  open,
  onClose,
  onSubmit,
  children,
  isSubmitting = false,
  cancelButtonText,
  submitButtonText,
  isDelete = false
}: Props) => {
  const {t} = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();

  const getSubmitButtonStyle = () => {
    let style: React.CSSProperties = {};
    if(isDelete)
      style.backgroundColor = theme.palette.error.main;
    return style;
  }

  return (
    <MuiDialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
    >
      <div
        className={clsx(classes.rootDiv)}
      >
        {/* Header */}
        <div style={{ display: 'flex' }}>
          <div>
            <Typography
              color="textPrimary"
              variant="h3"
              gutterBottom
            >
              {title && title}
            </Typography>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton
              style={{ marginRight: '-10px', marginTop: '-10px' }}
              onClick={onClose}
              size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {/* Content */}
        {children}
        {/* Buttons */}
        <div style={{ float: 'right', marginTop: 30 }}>
          <Button
            className={classes.cancelButton}
            color="primary"
            variant="text"
            onClick={onClose}
            disabled={isSubmitting}
          >
            {cancelButtonText ? cancelButtonText : t('lang.cancel')}
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
            style={getSubmitButtonStyle()}
          >
            {submitButtonText ? submitButtonText : t('lang.save')}
          </Button>
        </div>
      </div>
    </MuiDialog>
  );
}

export default Dialog;