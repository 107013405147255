import { Avatar as MuiAvatar, Tooltip } from '@mui/material';
import { useSelector } from 'src/store';
import getInitials from 'src/utils/general/getInitials';
import clsx from 'clsx';
import type { TooltipProps } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';

export const avatar_sizes = {
  small: 24,
  medium: 32,
  large: 38,
  xlarge: 42
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatarSmall: {
    height: avatar_sizes.small,
    width: avatar_sizes.small,
    fontSize: 11
  },
  avatarMedium: {
    height: avatar_sizes.medium,
    width: avatar_sizes.medium,
    fontSize: 15
  },
  avatarLarge: {
    height: avatar_sizes.large,
    width: avatar_sizes.large,
    fontSize: 17
  },
  avatarX_Large: {
    height: avatar_sizes.xlarge,
    width: avatar_sizes.xlarge,
    fontSize: 20
  }
}));

interface WrapperProps {
  tooltip?: string;
  TooltipProps?: Partial<TooltipProps>;
  children: JSX.Element;
}

const Wrapper = ({
  tooltip,
  children,
  TooltipProps = {}
}: WrapperProps) => {
  if(!tooltip){
    return children;
  }
  return (
    <Tooltip
      title={tooltip}
      {...TooltipProps}
    >
      {children}
    </Tooltip>
  )
}

interface Props {
  name: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  user_id?: string;
  tooltip?: string;
  TooltipProps?: Partial<TooltipProps>;
  className?: string;
  fullname?: boolean;
  overwriteName?: React.ReactNode;
}

const Avatar = ({
  name,
  size = 'large',
  user_id,
  tooltip,
  TooltipProps,
  className,
  fullname = false,
  overwriteName
}: Props) => {
  const { classes } = useStyles();
  const avatars = useSelector((state) => state.misc.avatars);

  return (
    <Wrapper
      tooltip={tooltip}
      TooltipProps={TooltipProps}
    >
      <MuiAvatar
        className={clsx(
          classes.avatar,
          className,
          size === 'xlarge' && classes.avatarX_Large,
          size === 'large' && classes.avatarLarge,
          size === 'medium' && classes.avatarMedium,
          size === 'small' && classes.avatarSmall
        )} 
        src={user_id && avatars[user_id] && `data:image/jpeg;base64,${avatars[user_id]}`}
      >
        {overwriteName ? overwriteName : fullname ? name : getInitials(name)}
      </MuiAvatar>
    </Wrapper>
  )
}

export default Avatar;