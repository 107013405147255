import type { AppDispatch } from 'src/store/index';
import { EchoChannel as Channel } from '../index';
import { createRegisterDayClosing, deleteRegisterDayClosing, updateRegisterDayClosing } from 'src/slices/register';
import { RegisterDayClosing } from 'src/types/api/financial/dayClosing';
import { dayClosingToSmall } from 'src/custom/resourceConverter';

const dayClosings = (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_day_closing', (data: any) => {
    const dayClosing = data.data.response as RegisterDayClosing;
    const converted = dayClosingToSmall(dayClosing);
    dispatch(createRegisterDayClosing(converted));
  });
  channel.listen('.update_day_closing', (data: any) => {
    const dayClosing = data.data.response as RegisterDayClosing;
    const converted = dayClosingToSmall(dayClosing);
    dispatch(updateRegisterDayClosing(converted));
  });
  channel.listen('.delete_day_closing', (data: any) => {
    const dayClosing = data.data.response as RegisterDayClosing;
    const converted = dayClosingToSmall(dayClosing);
    dispatch(deleteRegisterDayClosing(converted));
  });
}
export default dayClosings;