import { Avatar, AvatarGroup, Typography } from "@mui/material";
import { makeStyles } from "src/theme/makeStyles";
import { MouseEvent, useMemo, useState } from "react";
import { useTranslation } from "src/i18n";
import { useSelector } from "src/store";
import { UserSmall } from "src/types/api/user";
import getInitials from "src/utils/general/getInitials";
import MoreDropdown from "./MoreDropdown";
import clsx from 'clsx';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.text.primary,
    borderRadius: 4,
    alignItems: 'center',
    height: '100%'
  },
  rootDark: {
    background: theme.custom.backgroundDark
  },
  rootError: {
    borderBottom: `2px solid ${theme.palette.error.main}`
  },
  rootDisabled: {
    cursor: 'default'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 24,
    height: 24,
    fontSize: 11
  }
}));

interface Props {
  selectedUserIds: string[];
  setSelectedUserIds: (user_ids: string[]) => void;
  singleUser?: boolean;
  dark?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const AvatarGroupButton = ({
  selectedUserIds, 
  setSelectedUserIds,
  singleUser = false,
  dark = false,
  error = false,
  disabled = false
}: Props) => {
  const { classes } = useStyles();
  const {t} = useTranslation();

  const generalUser = useSelector(state => state.general.user);
  const users = useSelector(state => state.lists.users);
  const avatars = useSelector(state => state.misc.avatars);

  const findUserById = (user_id: string) => users.find(user => user.id === user_id);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    setMoreAnchorEl(e.currentTarget);
  }

  const getText = () => {
    if(selectedUserIds.length === 1){
      const firstSelectedUser = findUserById(selectedUserIds[0]);
      if(firstSelectedUser){
        return firstSelectedUser.contact.name;
      }
    }

    if(selectedUserIds.length === 2){
      const firstSelectedUser = findUserById(selectedUserIds[0]);
      const secondSelectedUser = findUserById(selectedUserIds[1]);
      if(firstSelectedUser && secondSelectedUser){
        return `${firstSelectedUser.contact.first_name} & ${secondSelectedUser.contact.first_name}`;
      }
    }

    if(selectedUserIds.length > 2){
      return `${selectedUserIds.length} ${t('lang.users')}`
    }

    return singleUser ? `${t('lang.user')}` : `${t('lang.users')}`;
  }

  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);

  const sortedSelectedUsers: UserSmall[] = useMemo(() => {
    if(!generalUser){
      return [];
    }

    const filtered = users.filter(user => selectedUserIds.includes(user.id));
    return filtered.sort((a,b) => {
      if(a.id === generalUser.id){ return -1; }
      if(b.id === generalUser.id){ return 1; }

      if(a.contact.name.toLowerCase() > b.contact.name.toLowerCase()){ return 1; }
      if(a.contact.name.toLowerCase() < b.contact.name.toLowerCase()){ return -1; }
      return 0;
    });
  }, [users, generalUser, selectedUserIds]);


  if(!generalUser){
    return null;
  }

  return (
    <>
      <div 
        className={clsx(
          classes.root, 
          dark && classes.rootDark,
          error && classes.rootError,
          disabled && classes.rootDisabled
        )}
        onClick={disabled ? undefined : handleClick }
      >
        <AvatarGroup
          max={3}
          classes={{ avatar: classes.avatar }}
        >
          {!!sortedSelectedUsers.length && sortedSelectedUsers.map((user, index) => (
            <Avatar 
              key={index}
              // className={classes.avatar}
              src={avatars[user.contact.id] && `data:image/jpeg;base64,${avatars[user.contact.id]}`}
            >
              {getInitials(user.contact.name)}
            </Avatar>
          ))}
        </AvatarGroup>
        <Typography 
          variant='body2' 
          style={{ marginLeft: sortedSelectedUsers.length ? 12 : 0, lineHeight: 1.5 }}
          textOverflow={'ellipsis'}
          noWrap
        >
          {getText()}
        </Typography>
      </div>
      <MoreDropdown
        anchorEl={moreAnchorEl}
        handleClose={() => setMoreAnchorEl(null)}
        selected={selectedUserIds}
        setSelected={setSelectedUserIds}
        singleUser={singleUser}
      />
    </>
  )
}
export default AvatarGroupButton;