import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from 'src/App';
import { imageUrls } from 'src/config';

const PreLoginBackground = () => {  
  const appContext = useContext(AppContext);

  useEffect(() => {
    if(appContext){
      const bodyEl = document.body;
      bodyEl.style.backgroundImage = `url(${imageUrls(appContext.group.contact.id).background})`;
      bodyEl.style.backgroundPosition = 'center';
      bodyEl.style.backgroundSize = 'cover';
    }

    return () => {
      const bodyEl = document.body;
      bodyEl.style.backgroundImage = `none`;
      bodyEl.style.backgroundPosition = '0% 0%';
      bodyEl.style.backgroundSize = 'auto auto';
    }
  }, []);

  return null;
}
export default PreLoginBackground;