import { Box, Button, Dialog, DialogContent, IconButton, Typography, useTheme } from '@mui/material';
import dataUrlToBlob from './dataUrlToBlob';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from 'src/i18n';
import useFilePicker, { commonMimeTypes } from './useFilePicker';
import { useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import 'cropperjs/dist/cropper.css';
import useIsMobile from 'src/hooks/general/useIsMobile';

export const commonAspectRatios = {
  screen: 16/9,
  avatar: 1/1,
  logo: 0
};

interface Props {
  open: boolean;
  onClose: () => void;
  onChange: (value: string | null) => void;
  aspectRatio?: number;
  initialImageSrc?: string;
}

const ImagePicker = ({
  open,
  onClose,
  onChange,
  aspectRatio = 1,
  // aspectRatio = 16/9,
  initialImageSrc
}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const [base64Image, setBase64Image] = useState<string>();
  const [initialImageCleared, setInitialImageCleared] = useState(false);

  const { open: openFilePicker } = useFilePicker((files) => {
    if(Array.isArray(files) && files.length){
      setBase64Image(files[0]);
    }
  }, { accept: commonMimeTypes.image, base64: true });

  useEffect(() => {
    if(!open){
      setTimeout(() => {
        setInitialImageCleared(false);
        setBase64Image(undefined);
        setInitialImageSrcCopy(null);
      }, 250);
    }
  }, [open]); // initialImage

  const cropperRef = useRef<ReactCropperElement>(null);
  const handleFinish = async () => {
    if(!cropperRef.current){
      return;
    }

    const dataUrl = cropperRef.current.cropper.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high'
    }).toDataURL()

    onChange(dataUrl);
  }

  // buffer
  const [initialImageSrcCopy, setInitialImageSrcCopy] = useState<string | null>(null);
  useEffect(() => {
    if(initialImageSrc){
      setInitialImageSrcCopy(initialImageSrc);
    }
  }, [initialImageSrc]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      fullWidth
    >
      <DialogContent style={{ padding: theme.spacing(isMobile ? 1.5 : 3) }}>
        {base64Image ? (
          <div 
            style={{ 
              position: 'relative', 
              width: '100%',
              aspectRatio: `${aspectRatio}`
            }}
          >
            <Cropper
              ref={cropperRef} 
              src={base64Image}
              aspectRatio={aspectRatio}
              guides={true}
              viewMode={2}
              // @ts-ignore
              cropCon
              dragMode="move"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        ) : initialImageSrcCopy && !initialImageCleared ? (
          <div 
            style={{ 
              position: 'relative', 
              width: '100%',
              aspectRatio: `${aspectRatio}`
            }}
          >
            <img 
              // src={initialImageSrc} 
              src={initialImageSrcCopy ? initialImageSrcCopy : undefined}
              style={{ 
                width: '100%',
                height: '100%',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 4
              }}
            />
            {/* <div 
              style={{ 
                position: 'absolute', 
                width: 40, 
                height: 40,
                top: theme.spacing(3),
                right: theme.spacing(3),
                zIndex: 3,
                background: '#f00'
              }}
            >
              ICON
            </div> */}

            <div
              style={{ 
                position: 'absolute', 
                top: theme.spacing(1), 
                right: theme.spacing(1), 
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%' 
              }}
            >
              <IconButton onClick={() => setInitialImageCleared(true)}>
                <DeleteOutlineIcon />
              </IconButton>
            </div>

          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 4,
              aspectRatio: `${aspectRatio}`
            }}
            onClick={openFilePicker}
          >
            <Typography variant='h3'>Kies een foto</Typography>
            <CloudUploadOutlinedIcon style={{ fontSize: '3rem' }} />
          </div>
        )}
        {/* Btns */}
        
        <Box mt={3} style={{ float: 'right' }}>
          {base64Image ? (
            <>
              <Button 
                color='inherit'
                // onClick={() => setBase64Image(undefined)}
                onClick={onClose}
              >
                {t('lang.cancel')}
              </Button>
              <Button 
                variant='contained' 
                color='primary'
                onClick={() => handleFinish()}
              >
                {t('lang.save')}
              </Button>
            </>
          ) : (
            <>
              <Button 
                color='inherit'
                onClick={onClose}
              >
                {t('lang.cancel')}
              </Button>
              <Button 
                variant='contained' 
                color='primary'
                onClick={() => onChange(null)}
                disabled={!initialImageCleared}
              >
                {t('lang.save')}
              </Button>
            </>
          )}          
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export { dataUrlToBlob };

export default ImagePicker;