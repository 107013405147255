import { useSelector } from './index';
import { useMemo } from 'react';

export const useUsersNoCustomer = () => {
  const users = useSelector(state => state.lists.users);
  
  const filteredUsers = useMemo(() => {
    return users.filter((user) => user.type !== 'customer');
  }, [users]);

  return filteredUsers;
}