import { StandardTextFieldProps, TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslation } from 'src/i18n';
import mergeRefs from "react-merge-refs";
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';
import useAutofocus from '../logic/useAutofocus';

const Bic = forwardRef<HTMLDivElement, StandardTextFieldProps>((props, forwardedRef) => {
  const focusRef = useAutofocus(!!props.autoFocus, props.value);
  const { ref: caretRef, updateCaret} = useCaretPosition();
  const {t} = useTranslation();
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateCaret();
    e.target.value = e.target.value.toUpperCase().replace(' ', '');
    props.onChange && props.onChange(e);
  }
  
  const combinedRefs = mergeRefs(props.inputRef ? [props.inputRef, caretRef, focusRef] : [focusRef, caretRef]);

  return (
    <TextField
      ref={forwardedRef}
      variant='standard'
      label={`${t('lang.bic')}`}
      fullWidth
      {...props}
      inputProps={{
        maxLength: '10',
        ...props.inputProps
      }}
      inputRef={combinedRefs}
      onChange={handleChange}
    />
  )
});

export default Bic;