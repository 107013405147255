import { setMessageTotalRows, unshiftMessage, updateMessage } from 'src/slices/lists/messages';
import type { AppDispatch, RootState } from 'src/store/index';
import { Message } from 'src/types/api/messages';
import { EchoChannel as Channel } from '../index';
import { AnyAction, Store } from 'redux';

const messages = (
  channel: Channel, 
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>
) => {
  channel.listen('.create_message', (data: any) => {
    const stateMessages = store.getState().lists.messages.data;
    if(stateMessages.length){
      const message = data.data.response as Message;
      dispatch(unshiftMessage(message));
      
      const prevTotalRows = store.getState().lists.messages.totalRows;
      dispatch(setMessageTotalRows(prevTotalRows + 1));
    }
  });
  channel.listen('.update_message', (data: any) => {
    const message = data.data.response as Message;
    dispatch(updateMessage(message));
  });
}

export default messages;