import type { AppDispatch } from 'src/store/index';
import { createAvatar, deleteAvatar } from 'src/slices/misc';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch
) => {
  channel.listen('.create_avatar', (data: any) => {
    const avatar = {
      id: data.data.response.id,
      avatar: data.data.response.avatar
    };
    dispatch(createAvatar(avatar));
  });
  channel.listen('.delete_avatar', (data: any) => {//
    dispatch(deleteAvatar(data.data.response.id));
  })
}