import { Typography, useTheme } from "@mui/material";
import { useTranslation } from 'src/i18n';
import useCurrentBreakpoint from "src/hooks/general/useCurrentBreakpoint";
import Avatar, { avatar_sizes } from 'src/components/general/Avatar';
import {
  default as AvatarGroup,
  getMaxContainerLength as getMaxAvatarContainerLength
} from 'src/components/general/AvatarGroup';
import { UserSmall } from "src/types/api/user";
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  members: UserSmall[];
}

const NewDialogAvatarComponent = ({
  members
}: Props) => {
  const {t} = useTranslation();
  const bp = useCurrentBreakpoint();
  const theme = useTheme();

  // naar boven !
  const [membersPickerOpen, setMembersPickerOpen] = useState(false);
  const handleMembersClick = () => {
    setMembersPickerOpen(true);
  }

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      {bp === 'xs' && (
        <Typography
          variant='body2'
        >
          {`${t('lang.members')}:`}
        </Typography>
      )}
      <div
        style={{
          width: getMaxAvatarContainerLength(members.length, bp === 'xs' ? 'small' : 'large'),
          marginLeft: members.length ? 5 : 0,
          marginRight: members.length ? 2 : 0
        }}
      >
        <AvatarGroup
          // avatars={avatars}
          avatars={members.map((member) => ({
            name: member.contact.name,
            user_id: member.id
          }))}
          selectedUserIds={[]}
          setSelectedUserIds={() => {}} // noop
          size={bp === 'xs' ? 'small' : 'large'}
          disableSelect
        />
      </div>
      {bp === 'xs' && (
        <div
          style={{
            width: avatar_sizes.small - 2,
            height: avatar_sizes.small - 2,
            border: `1px dashed ${theme.palette.text.secondary}`,
            color: theme.palette.text.secondary,
            borderRadius: '50%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={handleMembersClick}
        >
          <AddIcon style={{ fontSize: '1rem' }} />
        </div>
      )}
    </div>
  );
}
export default NewDialogAvatarComponent;