import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import {
  Box,
  Button, LinearProgress, Typography
} from '@mui/material';
import { useState } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'src/i18n';
import { v4 as uuidv4 } from 'uuid';
import type { FormValues } from '../../types';
import RenderRows from './RenderRows';

interface Props {
  subtaskFieldarray: UseFieldArrayReturn<FormValues, "subtasks", "id">
  disableAllFields: boolean;
}

const Subtasks = ({
  subtaskFieldarray,
  disableAllFields
}: Props) => {
  const {t} = useTranslation();

  const {
    fields: subtaskFields,
    append: appendSubtask,
  } = subtaskFieldarray;

  const [editingSubtask, setEditingSubtask] = useState<string | null>(null);

  const finishedSubtasks = subtaskFields.filter((val) => val.finished);
  const subtaskPercentage = subtaskFields.length ? Math.round(finishedSubtasks.length / subtaskFields.length * 100) : 0;

  const createSubTask = () => {
    const tmp_id = `tmp-${uuidv4()}`;
    appendSubtask({
      id: null,
      title: '',
      finished: false,
      tmp_id,
    });
    setEditingSubtask(tmp_id);
  }

  return (
    <>
      <div
        style={{
          display: 'flex'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AssignmentTurnedInOutlinedIcon />
          <Typography
            variant='h6'
            color='textPrimary'
            style={{ marginLeft: 10, lineHeight: 1.7 }}
          >
            {t('lang.sub_tasks')} {!!subtaskFields.length && `( ${finishedSubtasks.length} / ${subtaskFields.length} )`}
          </Typography>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            onClick={() => {
              !disableAllFields && createSubTask();
            }}
            size='small'
            color='primary'
            style={{ cursor: 'pointer', width: 'fit-content' }}
            disabled={disableAllFields}
          >
            {`+ ${t('lang.add_subtask')}`}
          </Button>
        </div>
      </div>
      {!!subtaskFields.length &&
        <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={subtaskPercentage} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${subtaskPercentage}%`}</Typography>
          </Box>
        </Box>
      }
      <RenderRows 
        disableAllFields={disableAllFields}
        editingSubtask={editingSubtask}
        setEditingSubtask={setEditingSubtask}
        subtaskFieldarray={subtaskFieldarray}
        createSubTask={createSubTask}
      />
    </>
  )
}
export default Subtasks;