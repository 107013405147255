import { FormValues, TimeValue } from "./types";
import dayjs from 'dayjs';

export const getDefaultTimes = (): TimeValue => ({
  start: dayjs().hour(8).minute(30).second(0),
  end: dayjs().hour(17).minute(0).second(0)
})

export const getDefaultFormValues = (): FormValues => ({
  monday: { enabled: true, times: [getDefaultTimes()] },
  tuesday: { enabled: true, times: [getDefaultTimes()] },
  wednesday: { enabled: true, times: [getDefaultTimes()] },
  thursday: { enabled: true, times: [getDefaultTimes()] },
  friday: { enabled: true, times: [getDefaultTimes()] },
  saturday: { enabled: false, times: [getDefaultTimes()] },
  sunday: { enabled: false, times: [getDefaultTimes()] }
})