import { useMemo } from 'react';
import { useSelector } from 'src/store';

export const useHasAllPermissions = (permissions: string[]) => {
  const userRole = useSelector(state => state.general.user?.role);

  const hasPermission = useMemo(() => {
    let count = 0;
    permissions.map((fullpermission) => {
      const tmpHasPerm = !!userRole?.permissions.find((perm) => perm.tag === fullpermission);
      if(tmpHasPerm){
        count++;
      }
    });
    return count == permissions.length; 
  }, [userRole, permissions]);

  return hasPermission;
}

// export const useHasAnyPermissions = (permissions: string[]) => {
//   const userRole = useSelector(state => state.general.user?.role);

//   const hasPermission = useMemo(() => {
//     let count = 0;
//     permissions.map((fullpermission) => {
//       let tmpHasPerm = false;
//       userRole && userRole.permissions.map((perm) => {
//         if(perm.tag === fullpermission){
//           tmpHasPerm = true;
//         }
//       });
//       if(tmpHasPerm){
//         count++;
//       }
//     });
//     return count > 0; 
//   }, [userRole, permissions]);

//   return hasPermission;
// }