import { Typography } from "@mui/material";
import { OriginalLabel } from 'src/components/general/Labels';
import { useTranslation } from "src/i18n";
import SelectStatusLabelDialog, { SelectStatusLabelDialogProps } from "./SelectStatusLabelDialog";
import { useState } from "react";

export interface StatusLabelProps {
  title: string;
  color: string;
  changeable?: {
    selectedStatusId: string | null;
    onChange: (statusId: string) => void;
    options: SelectStatusLabelDialogProps['options'];
  }
}

const StatusLabel = ({
  title,
  color,
  changeable
}: StatusLabelProps) => {
  const { t } = useTranslation();
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);

  const handleChangeStatus = (statusId: string) => {
    changeable && changeable.onChange(statusId);
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography variant='body2'>
          {t('lang.status')}:
        </Typography>
        <span
          style={{
            marginLeft: 5,
            marginRight: 5
          }}
        >
          <OriginalLabel
            colorCode={color}
            onClick={!!changeable ? () => setChangeStatusOpen(true) : undefined}
          >
            {title}
          </OriginalLabel>
        </span>
      </div>
      {!!changeable && (
        <SelectStatusLabelDialog
          open={changeStatusOpen}
          onClose={() => setChangeStatusOpen(false)}
          onChange={handleChangeStatus}
          options={changeable.options}
          selectedOptionId={changeable.selectedStatusId}
        />
      )}
    </>
  )
}
export default StatusLabel;