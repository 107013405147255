import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Product, ProductCategory } from 'src/types/api/product';

interface State {
  products: Product[];
  categories: ProductCategory[];
}

const initialState: State = {
  products: [],
  categories: []
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state: State, action: PayloadAction<Product[]>){
      return {
        ...state,
        products: action.payload
      };
    },
    createProduct(state: State, action: PayloadAction<Product>){
      return {
        ...state,
        products: [
          ...state.products,
          action.payload
        ]
      };
    },
    updateProduct(state: State, action: PayloadAction<Product>){
      return {
        ...state,
        products: state.products.map((product) => product.id === action.payload.id ? action.payload : product)
      };
    },
    deleteProduct(state: State, action: PayloadAction<Product>){
      return {
        ...state,
        products: state.products.filter((product) => product.id !== action.payload.id)
      };
    },
    // Categories
    setProductCategories(state: State, action: PayloadAction<ProductCategory[]>){
      return {
        ...state,
        categories: action.payload
      };
    },
    createProductCategory(state: State, action: PayloadAction<ProductCategory>){
      return {
        ...state,
        categories: [
          ...state.categories,
          action.payload
        ]
      };
    },
    updateProductCategory(state: State, action: PayloadAction<ProductCategory>){
      return {
        ...state,
        categories: state.categories.map((category) => category.id === action.payload.id ? action.payload : category)
      };
    },
    deleteProductCategory(state: State, action: PayloadAction<ProductCategory>){
      return {
        ...state,
        categories: state.categories.filter((category) => category.id !== action.payload.id)
      };
    }
  }
})

export const { reducer } = slice;

export const setProducts = (data: Product[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setProducts(data));
}
export const createProduct = (data: Product): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createProduct(data));
}
export const updateProduct = (data: Product): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateProduct(data));
}
export const deleteProduct = (data: Product): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteProduct(data));
}

export const setProductCategories = (data: ProductCategory[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setProductCategories(data));
}
export const createProductCategory = (data: ProductCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createProductCategory(data));
}
export const updateProductCategory = (data: ProductCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateProductCategory(data));
}
export const deleteProductCategory = (data: ProductCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteProductCategory(data));
}

export default slice;