import { useTranslation } from 'src/i18n';
import DuotoneDialog from "src/components/general/DuotoneDialog";
import { Grid, Typography } from '@mui/material';
import NumericField from 'src/components/forms/fields/Numeric';
import { useEffect, useMemo, useState } from "react";
import useCurrency from 'src/hooks/general/useCurrency';

export type CashCountFormValues = {
  [key: string]: string;
}

export const calculateTotalValueFromFormValues = (formValues: CashCountFormValues) => {
  let totalValueInCents = 0;
  Object.keys(formValues).map((key) => {
    try {
      const valueCents = parseFloat(key) * 100;
      const amount = parseInt(formValues[key]);

      const sum = amount * valueCents;
      if(!isNaN(sum)){
        totalValueInCents += sum;
      }
    }catch(e){
      console.log(e);
    }
  });
  return totalValueInCents / 100;
}

interface Props {
  open: boolean;
  onClose: (values: CashCountFormValues) => void;
  onSubmit: (values: CashCountFormValues) => void;
  initialValues?: CashCountFormValues;
}

const CashCount = ({
  open,
  onClose,
  onSubmit,
  initialValues = {}
}: Props) => {
  const { numberToPriceString, denominations, symbol } = useCurrency();
  const {t} = useTranslation();

  const [values, setValues] = useState<CashCountFormValues>(initialValues);
  
  const handleChange = (value: string, amount: string) => {
    setValues(prev => ({
      ...prev,
      [value]: amount
    }));
  }

  const totalValue = useMemo(() => {
    return calculateTotalValueFromFormValues(values);
  }, [values]);

  useEffect(() => {
    if(open){
      setValues(initialValues);
    }
  }, [open]);

  // TODO: maximum mexLength of textfield (to prevent possible Infinity type)

  return (
    <DuotoneDialog
      open={open}
      title={`${t('lang.cash_count')}`}
      onClose={() => onClose(values)}
      onSubmit={() => onSubmit(values)}
      submitButtonTitle={`${t('lang.use_counted_value')}`}
      cancelButtonTitle={`${t('lang.close')}`}
    >
      <Grid container spacing={3}>
        {denominations.map((value, index) => (
          <Grid 
            key={index}
            item 
            xs={6}
          > 
            <NumericField
              textFieldProps={{
                label: `${symbol} ${value.toFixed(2)}`
              }}
              value={values[`${value}`] ? values[`${value}`] : '0'}
              onChange={(amount) => handleChange(`${value}`, amount)}
              dontAllowLeadingZeros
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography>
            {`${t('lang.total')}: ${numberToPriceString(totalValue, true)}`}
          </Typography>
        </Grid>
      </Grid>
    </DuotoneDialog>
  )
}
export default CashCount;