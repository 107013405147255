import { AxiosRequestConfig } from "axios";
import { AppDispatch } from "src/store";
import axios from 'axios';
import { createAvatar, deleteAvatar } from 'src/slices/misc';

// globalize!
type Avatar = {
  id: string;
  avatar: string;
}

interface UploadAvatarRequestData {
  imageName: string;
  image: File; // IMAGE
  id: string; // todo: to contact id (on back & front end)
}

export const uploadAvatarRequest = (
  data: UploadAvatarRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Avatar> => {

  let formData = new FormData();
  formData.append('image', data.image, data.imageName);
  formData.append('id', data.id);

  return new Promise<Avatar>((resolve, reject) => {
    axios.post<Avatar>(`avatars`, formData, config)
    .then((response) => {
      dispatch(createAvatar(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

type DeleteAvatarResponse = { id: string; };

export const deleteAvatarRequest = (
  contact_id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<DeleteAvatarResponse> => {

  return new Promise<DeleteAvatarResponse>((resolve, reject) => {
    axios.delete<DeleteAvatarResponse>(`avatars/${contact_id}`, config)
    .then((response) => {
      dispatch(deleteAvatar(response.data.id));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}