import type { Priority } from './types';
import { Radio, alpha, colors, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'src/i18n';

export const priorities: { priority: Priority; name: string; color: string; }[] = [
  {
    priority: 0,
    name: 'lang.none',
    color: colors.grey[500]
  },
  {
    priority: 1,
    name: 'lang.low',
    color: colors.lightBlue[500]
  },
  {
    priority: 2,
    name: 'lang.normal',
    color: colors.lightGreen[500]
  },
  {
    priority: 3,
    name: 'lang.high',
    color: colors.orange[500]
  },
  {
    priority: 4,
    name: 'lang.urgent',
    color: colors.red[500]
  }
];

interface Props {
  value: Priority;
  onChange: (value: Priority) => void;
  disabled?: boolean;
}

const PriorityPicker = ({
  value,
  onChange,
  disabled = false
}: Props) => {
  const {t} = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant='body1' style={{ marginRight: 10 }}>
        {t('lang.priority')}:
      </Typography>
      {priorities.map((val, index) => (
        <Tooltip
          key={index}
          title={`${t(val.name)}`}
        >
          <Radio
            checked={val.priority === value}
            onChange={() => onChange(val.priority)}
            style={{ color: alpha(val.color, disabled ? 0.25 : 0.8) }}
            disabled={disabled}
          />
        </Tooltip>
      ))}
    </div>
  )
}
export default PriorityPicker;