import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, ListItem, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'src/i18n';
import { useNavigate } from 'react-router-dom';
import { default as DynamicIcon, MDIcon } from 'src/components/general/DynamicIcon';
import { makeStyles } from 'src/theme/makeStyles';

const useStyles = makeStyles()((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
    minWidth: 26,
    minHeight: 26
  },
  title: {
    marginRight: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  activeParent: {
    color: theme.palette.primary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  }
}));

interface NavItemProps {
  title?: string;
  href?: string;
  depth: number;
  children?: React.ReactNode;
  icon: string;
  open?: boolean;
  highlight: boolean;
  label: string | null;
  fullyOpen: boolean;
  menuInactive: boolean;
  [x: string]: any;
}

function NavItem({
  title,
  href,
  depth,
  children,
  icon: iconName,
  className,
  open: openProp,
  highlight,
  label,
  fullyOpen,
  menuInactive,
  ...rest
}: NavItemProps) {
  const { classes } = useStyles();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [open, setOpen] = useState(openProp ? openProp : false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const getButtonStyle = () => {
    let paddingLeft = 8;
    if (depth > 0) {
      paddingLeft = 32 + 8 * depth;
    }
    return { paddingLeft };
  }

  const icon = iconName as MDIcon;

  const handleButtonClick = () => {
    if(children){
      handleToggle();
      return;
    }
    if(href){
      navigate(href);
    }
  }

  const CollapseIcon = open ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <ListItem
      className={clsx(children ? classes.item : classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        className={highlight ? classes.activeParent : classes.button}
        style={getButtonStyle()}
        onClick={handleButtonClick}
      >
        <DynamicIcon 
          icon={icon}
          iconProps={{ size: '1.5rem' }}
          className={classes.icon}
          style={{
            filter: menuInactive ? 'grayscale(100%)' : 'none',
            transition: `filter ${theme.transitions.duration.enteringScreen}ms`
          }}
        />
        <span 
          className={classes.title}
          style={{ 
            filter: menuInactive ? 'grayscale(100%)' : 'none',
            transition: `filter ${theme.transitions.duration.enteringScreen}ms`
          }}
        >
          {title && t(title)}
        </span>

        {fullyOpen && label &&
          <div
            style={{
              background: theme.palette.primary.main,
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 3,
              filter: menuInactive ? 'grayscale(100%)' : 'none',
              transition: `filter ${theme.transitions.duration.enteringScreen}ms`,
              // color: theme.palette.text.primary,
              color: theme.palette.primary.contrastText
            }}
          >
            <Typography style={{ fontSize: 12 }}>
              {t(`lang.${label}`)}
            </Typography>
          </div>
        }

        {children && fullyOpen && <CollapseIcon />}
      </Button>

      {children &&
        <Collapse in={open}>
          {children}
        </Collapse>
      }
    </ListItem>
  );
}

export default NavItem;
