import { Typography, IconButton, useTheme, alpha } from '@mui/material';
import type { File as TFile } from 'src/types/api/file';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { makeStyles } from 'src/theme/makeStyles';
import DotsMenu from 'src/components/general/DotsMenu';
import { useTranslation } from 'src/i18n';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex', 
    alignItems: 'center', 
    width: '100%',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    cursor: 'grab',
    '& .taskfilerow-iconbuttons': {
      transition: 'opacity 0.15s',
      opacity: 0,
      pointerEvents: 'none',
      marginLeft: 'auto'
    },
    '&:hover': {
      backgroundColor: alpha('#000', 0.15),
      '& .taskfilerow-iconbuttons': {
        opacity: 1,
        pointerEvents: 'auto'
      }
    }
  }
}));

interface Props {
  file: TFile;
  onDelete: () => void;
  onDownload: () => void;
  disabled?: boolean;
}

const TaskFileRow = ({
  file,
  onDelete,
  onDownload,
  disabled = false
}: Props) => {
  const {t} = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classes.root}
    >
      <div
        style={{ 
          width: 400,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <InsertDriveFileOutlinedIcon 
          style={{
            marginRight: theme.spacing(1)
          }}
        />
        <Typography
          color={theme.palette.text.primary}
          variant='h6'
          textOverflow={'ellipsis'}
          noWrap
        >
          {file.filename}
        </Typography>
      </div>
      <div
        className='taskfilerow-iconbuttons'
      >
        <DotsMenu
          disabled={disabled}
          menuItems={[
            // {
            //   content: `${t('lang.update')}`,
            //   icon: 'update',
            //   onClick: () => handleUpdateSubtaskClick(index)
            // },
            {
              content: `${t('lang.download')}`,
              icon: <DownloadIcon />,
              onClick: () => onDownload()
            },
            {
              content: `${t('lang.delete')}`,
              icon: 'delete',
              onClick: () => onDelete()
            }
          ]}
        />

        {/* <IconButton
          size="small"
          onClick={() => onDelete()}
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton> */}
      </div>
    </div>
  )
}
export default TaskFileRow;