
import { AnyAction, Store } from 'redux';
import { setCurrentContact } from 'src/slices/current';
import { updateSetting } from 'src/slices/general/settings';
import type { AppDispatch, RootState } from 'src/store/index';
import type { EntitySetting } from 'src/types/api/setting';
import { EchoChannel as Channel } from '../index';

type UpdateSettingData = {
  object_id?: string;
  data: EntitySetting;
}

const handleUpdateSetting = (
  setting: EntitySetting, 
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
  object_id?: string
) => {
  if(
    setting.type === 'group' || 
    setting.type === 'widget'
  ){
    dispatch(updateSetting(setting));
  }

  // if setting type: contact -> check if setting
  if(setting.type === 'contact'){
    // update Contact setting
    const currentContact = store.getState().current.contact;
    if(currentContact && object_id){
      if(currentContact.id === object_id){
        // sets current contact with updated setting
        dispatch(setCurrentContact({
          ...currentContact,
          settings: currentContact.settings.map(l_setting => l_setting.id !== setting.id ? l_setting : setting)
        }));
      }
    }
  }

}

export default (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>
) => {
  channel.listen('.update_setting', (data: any) => {
    const settings = data.data.response as UpdateSettingData | UpdateSettingData[];
    if(Array.isArray(settings)){
      settings.map(setting => handleUpdateSetting(setting.data, dispatch, store, setting.object_id));
      return;
    }
    handleUpdateSetting(settings.data, dispatch, store, settings.object_id);
  });
}