import { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { AppContext } from 'src/App';

interface IdleCheckerProps {
  minutes: number;
}

const IdleChecker = ({minutes}: IdleCheckerProps) => {
  const appContext = useContext(AppContext);

  const handleOnIdle = () => {
    localStorage.removeItem('API_TOKEN');

    appContext?.setApiToken({
      ...appContext.apiToken,
      token: null,
      is_device: appContext.apiToken?.is_device ? appContext.apiToken.is_device : false
    });
  }
  
  useIdleTimer({
    timeout: 1000 * minutes * 60,
    onIdle: handleOnIdle
  });

  return null;
}

export default IdleChecker;