import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BroadcastContext } from 'src/custom/DataListener';
import { useSelector } from 'src/store';
import { useUsersNoCustomer } from 'src/store/customSelectors';

export const useFirstClaimUser = (object_id: string | null) => {
  const presence = useSelector(state => state.misc.presence);
  const users = useUsersNoCustomer();

  const firstClaimUser = useMemo(() => {
    if(!object_id){
      return null;
    }

    const claimArr: { claim: string; socket_id: string; time: number; user_id: string }[] = [];
    presence.map(presenceUser => 
      Object.entries(presenceUser.claims).map(([socketId, socketClaims]) => socketClaims.map((claim) => {
        if(claim.value === object_id){
          claimArr.push({
            claim: claim.value,
            socket_id: socketId,
            time: claim.timestamp,
            user_id: presenceUser.id
          })
        }
      })
    ));

    claimArr.sort((a,b) => {
      if(a.time > b.time){ return 1; }
      if(a.time < b.time){ return -1; }
      return 0;
    });

    const foundUser = users.find((user) => user.id === claimArr[0]?.user_id);
    return foundUser ? foundUser : null;
  }, [presence, users, object_id])
  
  return firstClaimUser;   
}

const useClaim = (id: string | null) => {
  const broadcastContext = useContext(BroadcastContext);
  const presence = useSelector(state => state.misc.presence);
  const ownUser = useSelector(state => state.general.user);

  const [enabledClaim, setEnabledClaim] = useState<string | null>(null);
  const createClaim = (id: string) => {
    if(!broadcastContext.presenceChannel){
      return;
    }
    broadcastContext.presenceChannel.whisper('action-set_claim', id);
    
    setEnabledClaim(id);
  }
  
  const updateClaim = (id: string) => {
    if(!broadcastContext.presenceChannel){
      return;
    }

    if(enabledClaim){
      broadcastContext.presenceChannel.whisper('action-remove_claim', enabledClaim);
    }
    broadcastContext.presenceChannel.whisper('action-set_claim', id);
    setEnabledClaim(id);
  }
  
  const removeClaim = () => {
    if(!broadcastContext.presenceChannel){
      return;
    }

    if(enabledClaim){
      broadcastContext.presenceChannel.whisper('action-remove_claim', enabledClaim);
      setEnabledClaim(null);
    }
  }
  
  useEffect(() => {
    if(!id){
      removeClaim();
      return;
    }

    if(id){
      if(enabledClaim){
        if(id !== enabledClaim){
          updateClaim(id);
        }
        // else ignore
      }else{
        createClaim(id);
      }
    }
  }, [id]); // + dep: broadcastContext.presenceChannel ???


  // temporarily save claim in ref (can be read after destroy!)
  const lastClaimRef = useRef<string | null>(null);
  useEffect(() => {
    lastClaimRef.current = enabledClaim;
  }, [enabledClaim]);
  
  // deleteClaim on destruct
  useEffect(() => {
    return () => {
      if(!broadcastContext.presenceChannel){
        return;
      }
      if(lastClaimRef.current){
        broadcastContext.presenceChannel.whisper('action-remove_claim', lastClaimRef.current);
        lastClaimRef.current = null;
      }
    }
  }, []);

  const firstClaimUser = useFirstClaimUser(id);

  const presenceUser = presence.find((user) => user.id === ownUser?.id);
  const hasFirstClaim = useMemo(() => presenceUser?.id === firstClaimUser?.id, [presenceUser, firstClaimUser]);

  return {
    currentUserHasFirstClaim: hasFirstClaim,
    claimUser: firstClaimUser
  }
}

export type Claim = ReturnType<(typeof useClaim)>;

export default useClaim;




// TEMPORARY & NON FUNCTIONAL !!!!

// import { UserSmall } from "src/types/api/user";

// const useFirstClaimUser = (object_id: any) => {
//   const user = null as UserSmall | null;
//   return user;
// }

// const useClaim = (object_id: any) => {
//   const firstClaimUser = useFirstClaimUser(object_id);
  
//   return {
//     currentUserHasFirstClaim: true,
//     claimUser: firstClaimUser
//   }
// }

// export type Claim = ReturnType<(typeof useClaim)>;

// export { useFirstClaimUser };

// export default useClaim;