import { alpha, Typography } from "@mui/material";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from 'mdi-react/FileUploadOutlineIcon';
import { makeStyles } from "src/theme/makeStyles";
import { useTranslation } from 'src/i18n';
import DuotoneDialog from './DuotoneDialog'

const useStyles = makeStyles()((theme) => ({
  dragRoot: {},
  dragBox: {
    padding: 10,
    border: `2px dashed ${theme.palette.divider}`,
    height: '100%',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha('#000', 0.3)
    }
  },
  dragBoxActive: {
    border: `2px solid ${theme.palette.divider}`,
    backgroundColor: alpha('#000', 0.3),
    color: alpha('#fff', 0.5)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (files: File[]) => void;
}

const FileSelectDialog = ({
  open,
  onClose,
  onSubmit
}: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if(!open){
      setSelectedFiles([]);
      setSubmitting(false);
    }
  }, [open]);

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit(selectedFiles);
  }

  return (
    <DuotoneDialog
      title={`${t('lang.file_upload')}`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitButtonProps={{
        disabled: !selectedFiles.length || submitting
      }}
      cancelButtonProps={{
        disabled: submitting
      }}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true
      }}
      disableOutsideClick
    >
      <>
        <div
          className={clsx(classes.dragBox, isDragActive && classes.dragBoxActive)}
          {...getRootProps()}
        >
          <FileUploadIcon size='45px' />
          <Typography variant='subtitle2'>
            {t('lang.drag_files_here')}
          </Typography>
        </div>
        <div>
          {selectedFiles.map((file, index) => (
            <div key={index}>
              <Typography variant='body2'>
                {file.name}
              </Typography>
            </div>
          ))}
        </div>
        <input {...getInputProps()} />
      </>
    </DuotoneDialog>
  )
}
export default FileSelectDialog;