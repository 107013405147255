const dataUrltoBlob = (dataurl: string, filename: string) => {
  let arr = dataurl.split(','),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  let pre_mime = arr[0].match(/:(.*?);/);
  if (!pre_mime)
    return;
  let mime = pre_mime[1];

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export default dataUrltoBlob;