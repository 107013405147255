import { createAppointment, deleteAppointment, updateAppointment } from 'src/slices/lists/appointments';
import type { AppDispatch } from 'src/store/index';
import { Appointment } from 'src/types/api/appointment';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch
) => {
  channel.listen('.create_appointment', (data: any)=>{
    const appointment = data.data.response as Appointment;
    dispatch(createAppointment(appointment));
  });

  channel.listen('.update_appointment', (data: any)=>{//
    const appointment = data.data.response as Appointment;
    dispatch(updateAppointment(appointment));
  });

  channel.listen('.delete_appointment', (data: any)=>{
    const appointment = data.data.response as Appointment;
    dispatch(deleteAppointment(appointment));
  });

}