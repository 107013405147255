import TimePicker from './components/TimePicker';
import DatePicker from './components/DatePicker';
import dayjs from 'dayjs';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'src/i18n';
import { DEFAULT_TIME_DISTANCE } from './components/constants';

interface Props {
  value: {
    start: dayjs.Dayjs | null;
    end: dayjs.Dayjs | null;
  },
  onChange: (value: { start: dayjs.Dayjs | null; end: dayjs.Dayjs | null; }) => void;
  error?: {
    start: boolean;
    end: boolean;
  };
  onError?: React.Dispatch<React.SetStateAction<{ start: boolean; end: boolean; }>>;
}

const DateTimeRangeOneDatePicker = ({
  value,
  onChange,
  error,
  onError
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChange = (val: dayjs.Dayjs | null, position: 'start' | 'end') => {
    if (!val) {
      onChange({ start: null, end: null });
      return;
    }

    if (position === 'start') {
      const newEndValue = value.end ? value.end : val;
      onChange({ start: val, end: newEndValue })
    }

    if (position === 'end') {
      onChange({ start: value.start, end: val });
    }
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ display: 'inline-flex', marginRight: theme.spacing(3) }}>
        <DatePicker
          value={value.start}
          // onChange={(val) => onChange({ 
          //   start: val, 
          //   end: (value.end && val) ? value.end.clone().date(val.date()) : value.end
          // })}
          onChange={(val) => handleChange(val, 'start')}
          textFieldProps={{
            label: `${t('lang.date')}`
          }}
          error={error?.start}
          onError={(val) => onError && onError(prev => ({ ...prev, start: val }))}
        />
      </div>
      <div style={{ display: 'inline-flex' }}>
        <TimePicker
          value={value.start}
          // onChange={(val) => onChange({ start: val, end: value.end })}
          onChange={(val) => handleChange(val, 'start')}
          error={error?.start}
          onError={(val) => onError && onError(prev => ({ ...prev, start: val }))}
          // maxTime={(value.end && value.end.isValid()) ? value.end : undefined}
          textFieldProps={{
            label: `${t('lang.start')}`
          }}
        />
        <div
          style={{
            color: theme.palette.text.primary,
            margin: `0px ${theme.spacing(3)}`,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span style={{ marginTop: theme.spacing(1.5) }}>
            &#8212;
          </span>
        </div>
      </div>
      <div style={{ display: 'inline-flex' }}>
        <TimePicker
          value={value.end}
          // onChange={(val) => onChange({ start: value.start, end: val })}
          onChange={(val) => handleChange(val, 'end')}
          error={error?.end}
          onError={(val) => onError && onError(prev => ({ ...prev, end: val }))}
          minTime={(value.start && value.start.isValid()) ? value.start : undefined}
          textFieldProps={{
            label: `${t('lang.end')}`
          }}
        />
      </div>
    </div>
  )
}

export default DateTimeRangeOneDatePicker;