import { createHoliday, updateHoliday, deleteHoliday } from 'src/slices/lists/holidays';
import type { AppDispatch } from 'src/store/index';
import type { Holiday } from 'src/types/api/holiday';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_holiday', (data: any) => {
    const holiday = data.data.response as Holiday;
    dispatch(createHoliday(holiday));
  });
  channel.listen('.update_holiday', (data: any) => {//
    const holiday = data.data.response as Holiday;
    dispatch(updateHoliday(holiday));
  });
  channel.listen('.delete_holiday', (data: any) => {//
    const holiday = data.data.response as Holiday;
    dispatch(deleteHoliday(holiday));
  });
}