import { Menu, MenuItem, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useSelector } from 'src/store';
import { makeStyles } from 'src/theme/makeStyles'
import Avatar from 'src/components/general/Avatar';
import { useUsersNoCustomer } from 'src/store/customSelectors';

const useStyles = makeStyles()((theme) => ({
  menuItemRoot: {
    paddingLeft: 8
  },
  checkbox: {
    marginRight: 5
  },
  checkboxRoot: {
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },
  userName: {
    marginLeft: 10
  }
}));

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  userIdWhitelist: string[];
  selected: string[];
  // setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setSelected: (value: string[]) => void;
}

const MoreDropdown = ({
  anchorEl,
  handleClose,
  userIdWhitelist,
  selected,
  setSelected
}: Props) => {
  const { classes } = useStyles();

  const users = useUsersNoCustomer();
  const filteredUsers = users.filter((user) => userIdWhitelist.includes(user.id));

  const handleClick = (e: React.MouseEvent<HTMLLIElement>, user_id: string) => {
    e.stopPropagation();
    if(selected.includes(user_id)){
      setSelected(selected.filter((x) => x !== user_id));
      return;
    }
    setSelected([...selected, user_id]);
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 0.5,
          maxHeight: 400,
          overflow: 'auto'
        }
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {filteredUsers.map((user) => (
        <MenuItem 
          key={user.id}
          classes={{ root: classes.menuItemRoot }} 
          onClick={(e) => handleClick(e, user.id)}
        >
          <Checkbox 
            className={classes.checkbox}
            classes={{ root: classes.checkboxRoot }}
            size='small' 
            checked={selected.includes(user.id)}
          />
          <Avatar 
            name={user.contact.name}
            user_id={user.id}
            size='small'
          />
          <Typography
            className={classes.userName}
            variant='body2'
          >
            {user.contact.name}
          </Typography>
        </MenuItem> 
      ))}
    </Menu>
  );
}
export default MoreDropdown;