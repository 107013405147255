import { useTheme, lighten, darken } from '@mui/material';



export default function SvgComponent({ color1light, color2light, color3light, color1dark, color2dark, color3dark }) {
  const theme = useTheme();
  const changeColor = theme.name === 'LIGHT' ? lighten : darken;
  const conChangeColor = theme.name === 'LIGHT' ? darken: lighten;
  return (
    <svg id="salon_type" data-name="Salon type" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <style>
        {
            `
			.svgc1-0{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0)}}
			.svgc1-1{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.1)}}
			.svgc1-2{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.2)}}
			.svgc1-3{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.3)}}
			.svgc1-4{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.4)}}
			.svgc1-5{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.5)}}
			.svgc1-6{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.6)}}
			.svgc1-7{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.7)}}
			.svgc1-8{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.8)}}
			.svgc1-9{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9)}}
			.svgc1-10{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 1)}}

			.svgc2-0{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0)}}
			.svgc2-1{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.1)}}
			.svgc2-2{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.2)}}
			.svgc2-3{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.3)}}
			.svgc2-4{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.4)}}
			.svgc2-5{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.5)}}
			.svgc2-6{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.6)}}
			.svgc2-7{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.7)}}
			.svgc2-8{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.8)}}
			.svgc2-9{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.9)}}
			.svgc2-10{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 1)}}

			.svgc3-0{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0)}}
			.svgc3-1{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.1)}}
			.svgc3-2{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.2)}}
			.svgc3-3{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.3)}}
			.svgc3-4{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.4)}}
			.svgc3-5{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.5)}}
			.svgc3-6{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.6)}}
			.svgc3-7{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.7)}}
			.svgc3-8{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.8)}}
			.svgc3-9{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.9)}}
			.svgc3-10{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 1)}}
			
			.lnc0{opacity:0.5;}
			.lnc1{fill:#e8152e;}
			.lnc2{fill:${conChangeColor(changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9),0.1)}}
			.lnc3{fill:#ffda00;}
			.lnc4{fill:#ffbc00;}
			.lnc5{fill:#ffbc00;}

			
			
			
			.lnc5{fill:#FFFFFF;}
			.lnc6{fill:#6AC2EE;}
			.lnc7{fill:#A2D3F0;}
			.lnc8{opacity:0.1;}
			.lnc9{fill:#24224D;}
			.lnc10{fill:#5BA0D3;}
			.lnc11{fill:#508FBD;}
			.lnc12{opacity:0.2;}
			.lnc13{fill:#B13C44;}
			.lnc14{fill:#1B1C3A;}
			.lnc15{fill:#F8BC9E;}
			.lnc16{fill:#ABDBF5;}
			.lnc17{fill:#F3A078;}
			.lnc18{fill:#E8525D;}
			.lnc19{fill:#EE7078;}
			.lnc20{fill:#95D6F7;}
			.lnc21{fill:#92A8C9;}
			.lnc22{fill:#518EC2;}
			.lnc23{fill:none;stroke:#9FD9F8;stroke-width:2;stroke-miterlimit:10;stroke-dasharray:8;}
			.lnc24{opacity:0.1;fill:#24224D;}
			.lnc25{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.lnc26{fill:none;stroke:#5BA0D3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.lnc27{fill:none;stroke:#6AC2EE;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.lnc28{opacity:0.2;fill:#24224D;}
			.lnc29{fill:#DDE7F5;}
			.lnc30{fill:#C6D8EF;}
			.lnc31{fill:#2B3078;}
			.lnc32{fill:#7277A4;}
			.lnc33{fill:#87D1F5;}
			.lnc34{opacity:0.1;fill:#25244F;}
			.lnc35{fill:#EF7B91;}
			.lnc36{fill:#F6AB86;}
			.lnc37{fill:#72232B;}
			.lnc38{fill:none;stroke:#24224D;stroke-width:1.4455;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.lnc39{fill:#F4BD9C;}
			.lnc40{fill:#25244F;}
			.lnc41{fill:#993F46;}
			.lnc42{fill:#CB535D;}
			.lnc43{fill:#739ED0;}
			.lnc44{fill:#8BC0E9;}
			.lnc45{fill:#ACDAF6;}
			.lnc46{fill:#D7CD5D;}
			.lnc47{fill:#C7BF57;}
			.lnc48{fill:#A5654B;}
			.lnc49{opacity:0.5;fill:#A5654C;}
			.lnc50{fill:#A5654C;}
			.lnc51{fill:#C48867;}
			.lnc52{fill:#A3CBD6;}
			.lnc53{opacity:0.4;}
			.lnc54{fill:#6C96AC;}
			.lnc55{fill:#E1AB83;}
			.lnc56{opacity:0.5;fill:#6C96AC;}
			.lnc57{fill:#CEE1DF;}
			.lnc58{fill:#DAEDEA;}
			.lnc59{fill:#E5A445;}
			.lnc60{fill:#EFE3CE;}
			.lnc61{fill:#FAB952;}
			.lnc62{fill:#FFFBF3;}
			.lnc63{fill:#895B55;}
			.lnc64{fill:none;stroke:#24224D;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.lnc65{fill:#93D5F6;}
			.lnc66{fill:#D7DEE9;}
			.lnc67{fill:#F3A0B2;}
			.lnc68{fill:#020203;}
			.lnc69{fill:#EBF0F1;}
			.lnc70{fill:#ECECEB;}
			.lnc71{fill:#AFD8F0;}
            `
          }
       </style>
<g id="_x33_">
	<g id="illustration_9_">
		<g id="shadows_00000105404165403657996160000018222518759059803826_">
			<g>
				<path className="svgc3-9 lnc0" d="M144.23,338.47c0,2.95-14.67,5.34-32.76,5.34c-18.09,0-32.76-2.39-32.76-5.34c0-2.95,14.67-5.34,32.76-5.34
					C129.57,333.12,144.23,335.52,144.23,338.47z"/>
			</g>
		</g>
		<g id="elements_4_">
			<g>
				<path className="svgc1-9 lnc0" d="M194.13,247.25c-10.97-3.84-8.62-17.55-22.96-24.96c-9.37-4.84-12.85-0.27-20.46-4.99
					c-12.71-7.88-6.54-22.82-17.97-35.44c-12.96-14.32-29.56-4.65-38.93-17.47c-8.82-12.07-4.6-34.96,5.99-48.42
					c10.1-12.84,19.26-12,26.45-26.45c6.83-13.71,0.77-20.28,7.12-26.78c11.6-11.89,42.53-0.93,58.76,4.82
					c23.32,8.26,24.45,13.92,36.44,13.48c20.55-0.77,24.74-17.67,43.42-17.47c15.48,0.16,30.02,11.95,36.94,25.46
					c9.5,18.56,5.15,42.57-7.49,54.41c-12.76,11.95-28.04,5.92-34.94,18.47c-5.58,10.15,2.26,17.99-4.49,28.45
					c-5.61,8.68-12.42,5.46-21.46,14.97c-9.68,10.18-4.98,17.14-13.98,27.95C219.26,242.07,204.88,251,194.13,247.25z"/>
			</g>
			<g>
				<path className="svgc1-3" d="M154.87,172.47c-0.24,0-0.49,0-0.73-0.01l0.01-0.67c0.87,0.02,1.76,0,2.64-0.04l0.04,0.66
					C156.18,172.45,155.52,172.47,154.87,172.47z M151.46,172.3c-0.89-0.08-1.79-0.2-2.66-0.36l0.11-0.66
					c0.86,0.15,1.74,0.27,2.61,0.35L151.46,172.3z M159.5,172.17l-0.09-0.66c0.87-0.11,1.74-0.26,2.6-0.44l0.14,0.65
					C161.28,171.9,160.38,172.05,159.5,172.17z M146.17,171.39c-0.86-0.22-1.73-0.47-2.58-0.76l0.21-0.63
					c0.83,0.28,1.68,0.53,2.53,0.74L146.17,171.39z M164.76,171.07l-0.19-0.64c0.84-0.24,1.68-0.52,2.5-0.83l0.23,0.62
					C166.47,170.54,165.62,170.82,164.76,171.07z M141.08,169.68c-0.82-0.35-1.64-0.73-2.43-1.14l0.31-0.59
					c0.78,0.4,1.58,0.78,2.39,1.12L141.08,169.68z M169.78,169.19l-0.28-0.6c0.79-0.37,1.58-0.77,2.35-1.2l0.33,0.58
					C171.39,168.4,170.59,168.81,169.78,169.19z M136.31,167.22c-0.76-0.47-1.51-0.97-2.23-1.5l0.39-0.54
					c0.71,0.51,1.45,1.01,2.19,1.47L136.31,167.22z M174.46,166.57l-0.37-0.55c0.73-0.48,1.45-1,2.14-1.54l0.41,0.53
					C175.94,165.55,175.21,166.07,174.46,166.57z M131.96,164.07c-0.68-0.58-1.35-1.19-1.98-1.81l0.47-0.47
					c0.62,0.61,1.28,1.21,1.94,1.78L131.96,164.07z M178.7,163.27l-0.45-0.49c0.65-0.59,1.28-1.21,1.88-1.84l0.48,0.46
					C180,162.04,179.36,162.67,178.7,163.27z M128.14,160.3c-0.59-0.67-1.15-1.38-1.69-2.09l0.53-0.4c0.52,0.7,1.08,1.39,1.66,2.05
					L128.14,160.3z M182.39,159.37l-0.52-0.42c0.55-0.68,1.09-1.39,1.59-2.11l0.55,0.38C183.49,157.96,182.95,158.68,182.39,159.37z
					 M124.93,155.99c-0.48-0.75-0.93-1.53-1.35-2.32l0.59-0.31c0.41,0.77,0.86,1.54,1.33,2.28L124.93,155.99z M185.45,154.96
					l-0.57-0.34c0.44-0.76,0.86-1.54,1.25-2.32l0.6,0.29C186.33,153.39,185.91,154.19,185.45,154.96z M122.4,151.26
					c-0.36-0.82-0.69-1.66-0.98-2.5l0.63-0.22c0.29,0.82,0.61,1.65,0.97,2.45L122.4,151.26z M187.82,150.14l-0.62-0.25
					c0.32-0.81,0.62-1.65,0.88-2.49l0.64,0.2C188.45,148.45,188.15,149.31,187.82,150.14z M120.63,146.2
					c-0.23-0.86-0.43-1.74-0.59-2.62l0.65-0.12c0.16,0.86,0.36,1.73,0.58,2.57L120.63,146.2z M189.42,145.01l-0.65-0.15
					c0.2-0.85,0.36-1.72,0.49-2.59l0.66,0.1C189.79,143.25,189.62,144.14,189.42,145.01z M119.64,140.92
					c-0.1-0.88-0.16-1.79-0.19-2.68l0.67-0.02c0.03,0.88,0.09,1.76,0.19,2.63L119.64,140.92z M190.22,139.7l-0.66-0.05
					c0.07-0.87,0.1-1.76,0.1-2.63c0-0.8-0.03-1.61-0.08-2.4l0.66-0.05c0.06,0.81,0.08,1.63,0.08,2.45
					C190.32,137.91,190.29,138.81,190.22,139.7z M120.12,135.59l-0.66-0.03c0.04-0.89,0.11-1.79,0.21-2.68l0.66,0.08
					C120.23,133.82,120.16,134.71,120.12,135.59z M189.3,131.99c-0.13-0.87-0.29-1.74-0.48-2.59l0.65-0.14
					c0.19,0.87,0.36,1.76,0.49,2.64L189.3,131.99z M120.73,130.35l-0.65-0.13c0.17-0.88,0.38-1.76,0.61-2.62l0.64,0.18
					C121.1,128.63,120.9,129.49,120.73,130.35z M188.15,126.85c-0.25-0.84-0.55-1.67-0.86-2.49l0.62-0.24
					c0.32,0.83,0.62,1.69,0.88,2.54L188.15,126.85z M122.12,125.27l-0.63-0.22c0.3-0.84,0.64-1.68,1-2.49l0.61,0.27
					C122.75,123.63,122.42,124.45,122.12,125.27z M186.23,121.95c-0.38-0.79-0.79-1.57-1.23-2.33l0.58-0.33
					c0.45,0.77,0.87,1.57,1.26,2.37L186.23,121.95z M124.28,120.46l-0.58-0.32c0.42-0.78,0.89-1.56,1.37-2.31l0.56,0.36
					C125.15,118.93,124.69,119.69,124.28,120.46z M183.6,117.39c-0.49-0.72-1.02-1.43-1.57-2.12l0.52-0.42
					c0.56,0.7,1.1,1.42,1.6,2.16L183.6,117.39z M127.13,116.03l-0.53-0.4c0.54-0.71,1.11-1.41,1.7-2.08l0.5,0.44
					C128.22,114.65,127.66,115.34,127.13,116.03z M180.3,113.28c-0.6-0.64-1.23-1.27-1.87-1.86l0.45-0.49
					c0.66,0.6,1.3,1.24,1.91,1.89L180.3,113.28z M130.62,112.09l-0.46-0.48c0.64-0.62,1.31-1.23,1.99-1.8l0.43,0.51
					C131.91,110.88,131.25,111.48,130.62,112.09z M176.43,109.72c-0.69-0.54-1.4-1.07-2.13-1.56l0.37-0.55
					c0.74,0.5,1.47,1.03,2.17,1.59L176.43,109.72z M134.66,108.71l-0.39-0.54c0.73-0.52,1.48-1.02,2.24-1.48l0.35,0.57
					C136.12,107.71,135.38,108.2,134.66,108.71z M172.06,106.77c-0.76-0.43-1.55-0.84-2.34-1.22l0.28-0.6
					c0.81,0.38,1.61,0.8,2.38,1.24L172.06,106.77z M139.16,105.98l-0.3-0.59c0.79-0.4,1.61-0.78,2.44-1.12l0.26,0.61
					C140.75,105.21,139.94,105.58,139.16,105.98z M167.29,104.52c-0.82-0.31-1.66-0.6-2.5-0.85l0.19-0.64
					c0.86,0.25,1.71,0.54,2.54,0.86L167.29,104.52z M144.03,103.96l-0.21-0.63c0.85-0.28,1.72-0.53,2.58-0.74l0.16,0.65
					C145.71,103.44,144.86,103.69,144.03,103.96z M162.24,103.02c-0.86-0.18-1.73-0.34-2.6-0.46l0.09-0.66
					c0.88,0.12,1.77,0.28,2.65,0.47L162.24,103.02z M149.14,102.71l-0.11-0.66c0.88-0.15,1.77-0.26,2.66-0.34l0.06,0.66
					C150.88,102.45,150,102.56,149.14,102.71z M157.02,102.3c-0.87-0.05-1.76-0.07-2.63-0.06l-0.01-0.67
					c0.89-0.01,1.8,0.01,2.68,0.06L157.02,102.3z"/>
			</g>
			<g>
				<path className="svgc1-0" d="M92.72,163.02l-2.25-1.14c-0.14-0.07-0.3-0.07-0.44,0.01l-2.21,1.21c-0.34,0.19-0.75-0.1-0.69-0.49
					l0.38-2.49c0.02-0.15-0.03-0.31-0.14-0.42l-1.84-1.73c-0.28-0.27-0.14-0.75,0.25-0.81l2.49-0.41c0.15-0.03,0.28-0.12,0.35-0.26
					l1.08-2.28c0.17-0.35,0.67-0.36,0.85-0.01l1.16,2.24c0.07,0.14,0.21,0.23,0.36,0.25l2.5,0.32c0.39,0.05,0.55,0.52,0.28,0.8
					l-1.78,1.79c-0.11,0.11-0.16,0.27-0.13,0.42l0.47,2.48C93.47,162.9,93.07,163.2,92.72,163.02z"/>
			</g>
			<g>
				<path className="svgc3-0" d="M239.43,150.39l-2.25-1.14c-0.14-0.07-0.3-0.07-0.44,0.01l-2.21,1.21c-0.34,0.19-0.75-0.1-0.69-0.49
					l0.38-2.49c0.02-0.15-0.03-0.31-0.14-0.42l-1.84-1.73c-0.28-0.27-0.14-0.75,0.25-0.81l2.49-0.41c0.15-0.03,0.28-0.12,0.35-0.26
					l1.08-2.28c0.17-0.35,0.67-0.36,0.85-0.01l1.16,2.24c0.07,0.14,0.21,0.23,0.36,0.25l2.5,0.32c0.39,0.05,0.55,0.52,0.28,0.8
					l-1.78,1.79c-0.11,0.11-0.16,0.27-0.13,0.42l0.47,2.48C240.18,150.26,239.78,150.56,239.43,150.39z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M109.51,92.59l-2.25-1.14c-0.14-0.07-0.3-0.07-0.44,0.01l-2.21,1.21c-0.34,0.19-0.75-0.1-0.69-0.49
					l0.38-2.49c0.02-0.15-0.03-0.31-0.14-0.42l-1.84-1.73c-0.29-0.27-0.14-0.75,0.25-0.81l2.49-0.41c0.15-0.02,0.28-0.12,0.35-0.26
					l1.08-2.28c0.17-0.35,0.67-0.36,0.85-0.01l1.16,2.24c0.07,0.14,0.21,0.23,0.36,0.25l2.5,0.32c0.39,0.05,0.55,0.52,0.28,0.8
					l-1.78,1.79c-0.11,0.11-0.16,0.27-0.13,0.42l0.47,2.48C110.26,92.47,109.86,92.77,109.51,92.59z"/>
			</g>
			<g>
				<path className="svgc1-3" d="M223.24,198.91c-0.67,0-1.35-0.01-2.02-0.04l0.03-0.66c0.88,0.03,1.77,0.05,2.64,0.03l0.01,0.67
					C223.67,198.91,223.45,198.91,223.24,198.91z M226.57,198.81l-0.04-0.66c0.87-0.05,1.75-0.13,2.63-0.23l0.07,0.66
					C228.35,198.67,227.45,198.75,226.57,198.81z M218.54,198.7c-0.2-0.02-0.4-0.04-0.59-0.06l0.07-0.66
					c0.2,0.02,0.39,0.04,0.59,0.06L218.54,198.7z M231.88,198.21l-0.11-0.66c0.86-0.14,1.74-0.3,2.6-0.48l0.14,0.65
					C233.64,197.9,232.76,198.07,231.88,198.21z M237.11,197.11l-0.17-0.64c0.85-0.22,1.7-0.47,2.54-0.73l0.2,0.64
					C238.84,196.63,237.97,196.88,237.11,197.11z M242.22,195.52l-0.23-0.63c0.82-0.3,1.65-0.62,2.47-0.96l0.25,0.61
					C243.89,194.89,243.05,195.22,242.22,195.52z M247.16,193.48l-0.28-0.6c0.8-0.37,1.6-0.77,2.37-1.17l0.31,0.59
					C248.77,192.7,247.97,193.1,247.16,193.48z M251.9,191l-0.33-0.58c0.76-0.44,1.52-0.9,2.26-1.38l0.36,0.56
					C253.44,190.09,252.67,190.56,251.9,191z M256.4,188.12l-0.38-0.54c0.72-0.51,1.43-1.04,2.12-1.57l0.41,0.53
					C257.85,187.07,257.12,187.6,256.4,188.12z M260.62,184.84l-0.43-0.51c0.67-0.57,1.33-1.16,1.97-1.76l0.45,0.49
					C261.97,183.66,261.3,184.26,260.62,184.84z M264.53,181.18l-0.48-0.46c0.62-0.64,1.22-1.29,1.8-1.94l0.5,0.44
					C265.77,179.88,265.16,180.54,264.53,181.18z M268.07,177.16l-0.52-0.41c0.55-0.69,1.09-1.4,1.59-2.11l0.54,0.39
					C269.17,175.74,268.63,176.46,268.07,177.16z M271.18,172.8l-0.56-0.36c0.47-0.75,0.93-1.51,1.35-2.27l0.58,0.32
					C272.12,171.26,271.66,172.04,271.18,172.8z M273.79,168.11l-0.6-0.29c0.38-0.79,0.75-1.6,1.08-2.41l0.62,0.25
					C274.54,166.48,274.17,167.31,273.79,168.11z M275.81,163.15l-0.63-0.21c0.28-0.83,0.54-1.68,0.76-2.52l0.64,0.17
					C276.36,161.44,276.1,162.31,275.81,163.15z M277.18,157.97l-0.65-0.13c0.17-0.86,0.3-1.74,0.4-2.6l0.66,0.08
					C277.49,156.21,277.35,157.1,277.18,157.97z M277.81,152.65l-0.66-0.03c0.02-0.53,0.04-1.06,0.04-1.58
					c0-0.35-0.01-0.7-0.02-1.05l0.67-0.02c0.01,0.36,0.02,0.72,0.02,1.07C277.85,151.57,277.84,152.11,277.81,152.65z
					 M276.99,147.35c-0.09-0.87-0.22-1.75-0.38-2.61l0.65-0.12c0.16,0.88,0.29,1.77,0.38,2.66L276.99,147.35z M276.04,142.16
					c-0.22-0.84-0.47-1.69-0.76-2.53l0.63-0.21c0.29,0.85,0.55,1.72,0.77,2.57L276.04,142.16z M274.34,137.17
					c-0.18-0.42-0.36-0.85-0.56-1.27c-0.18-0.38-0.36-0.76-0.54-1.14l0.6-0.29c0.18,0.38,0.36,0.76,0.54,1.14
					c0.2,0.43,0.39,0.87,0.57,1.29L274.34,137.17z M272.06,132.39c-0.41-0.79-0.84-1.58-1.26-2.33l0.58-0.33
					c0.43,0.76,0.86,1.55,1.27,2.35L272.06,132.39z M269.45,127.78c-0.47-0.76-0.95-1.51-1.43-2.23l0.55-0.37
					c0.49,0.73,0.97,1.48,1.44,2.25L269.45,127.78z M266.5,123.39c-0.53-0.72-1.07-1.42-1.62-2.09l0.52-0.42
					c0.55,0.68,1.1,1.39,1.64,2.12L266.5,123.39z M263.16,119.29c-0.6-0.66-1.21-1.3-1.83-1.91l0.47-0.47
					c0.62,0.62,1.25,1.27,1.85,1.93L263.16,119.29z M259.4,115.59c-0.66-0.58-1.35-1.14-2.04-1.68l0.41-0.53
					c0.7,0.54,1.4,1.11,2.07,1.7L259.4,115.59z M255.22,112.37c-0.72-0.49-1.48-0.96-2.23-1.4l0.33-0.58
					c0.77,0.45,1.54,0.93,2.27,1.43L255.22,112.37z M250.67,109.71c-0.78-0.39-1.59-0.76-2.4-1.1l0.26-0.61
					c0.82,0.35,1.64,0.72,2.44,1.12L250.67,109.71z M245.8,107.67c-0.82-0.29-1.66-0.55-2.52-0.79l0.18-0.64
					c0.87,0.25,1.73,0.52,2.56,0.81L245.8,107.67z M206.5,107.09l-0.12-0.65c0.88-0.17,1.76-0.33,2.63-0.47l0.11,0.66
					C208.25,106.76,207.38,106.92,206.5,107.09z M240.73,106.22c-0.85-0.19-1.72-0.36-2.59-0.51l0.11-0.66
					c0.89,0.15,1.77,0.32,2.63,0.52L240.73,106.22z M211.74,106.19l-0.1-0.66c0.89-0.14,1.77-0.26,2.64-0.38l0.09,0.66
					C213.51,105.92,212.62,106.05,211.74,106.19z M217,105.48l-0.07-0.66c0.9-0.1,1.79-0.19,2.66-0.26l0.06,0.66
					C218.78,105.3,217.89,105.38,217,105.48z M235.52,105.33c-0.86-0.11-1.75-0.19-2.63-0.26l0.05-0.66
					c0.89,0.07,1.79,0.16,2.66,0.26L235.52,105.33z M222.29,105.03l-0.04-0.66c0.9-0.05,1.79-0.1,2.67-0.12l0.02,0.67
					C224.07,104.93,223.18,104.97,222.29,105.03z M230.24,104.92c-0.86-0.03-1.76-0.05-2.65-0.05l0-0.67c0.9,0,1.8,0.02,2.67,0.05
					L230.24,104.92z"/>
			</g>
			<g>
				<g>
					<path className="svgc1-9 lnc0" d="M274.74,130.39c-1.58,3.3-5.53,4.69-8.83,3.11c-3.3-1.58-4.69-5.53-3.11-8.83
						c1.58-3.3,5.53-4.69,8.83-3.11C274.93,123.14,276.32,127.09,274.74,130.39z"/>
					<path className="svgc2-0" d="M268.77,134.49c-1.02,0-2.04-0.23-3-0.68c-1.68-0.8-2.94-2.21-3.56-3.96c-0.62-1.75-0.52-3.64,0.28-5.31
						c0.8-1.68,2.21-2.94,3.96-3.56c1.75-0.62,3.64-0.52,5.31,0.28c1.68,0.8,2.94,2.21,3.56,3.96c0.62,1.75,0.52,3.64-0.28,5.31l0,0
						c-0.8,1.68-2.21,2.94-3.96,3.56C270.33,134.35,269.55,134.49,268.77,134.49z M268.78,121.24c-0.7,0-1.41,0.12-2.1,0.36
						c-1.58,0.56-2.85,1.7-3.58,3.22c-0.72,1.51-0.82,3.22-0.26,4.8c0.56,1.58,1.7,2.85,3.22,3.58c1.51,0.73,3.22,0.82,4.8,0.26
						c1.58-0.56,2.85-1.7,3.58-3.22l0,0c0.72-1.51,0.82-3.22,0.26-4.8c-0.56-1.58-1.7-2.85-3.22-3.58
						C270.63,121.45,269.7,121.24,268.78,121.24z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M279.45,134.71c-2.66,0-7.64-1.73-12.33-3.97c-3.36-1.61-6.39-3.39-8.53-5.02
						c-2.33-1.77-3.3-3.11-2.89-3.98c0.81-1.68,6.18,0.03,8.45,0.84l-0.22,0.63c-5.13-1.84-7.37-1.71-7.62-1.18
						c-0.18,0.38,0.41,1.43,2.69,3.16c2.11,1.6,5.1,3.36,8.42,4.95c7.56,3.62,12.83,4.51,13.28,3.56c0.25-0.52-1.02-2.3-5.52-5.09
						l0.35-0.57c2.48,1.53,6.54,4.34,5.78,5.94C281.06,134.49,280.4,134.71,279.45,134.71z"/>
				</g>
			</g>
			<g>
				<path className="svgc1-0" d="M264.7,87.22l-2.25-1.14c-0.14-0.07-0.3-0.07-0.44,0.01l-2.21,1.21c-0.34,0.19-0.75-0.1-0.69-0.49
					l0.38-2.49c0.02-0.15-0.03-0.31-0.14-0.42l-1.84-1.73c-0.28-0.27-0.14-0.75,0.25-0.81l2.49-0.41c0.15-0.03,0.28-0.12,0.35-0.26
					l1.08-2.28c0.17-0.35,0.67-0.36,0.85-0.01l1.16,2.24c0.07,0.14,0.21,0.23,0.36,0.25l2.5,0.32c0.39,0.05,0.55,0.52,0.28,0.8
					l-1.78,1.79c-0.11,0.11-0.16,0.27-0.13,0.42l0.47,2.48C265.45,87.09,265.05,87.39,264.7,87.22z"/>
			</g>
			<g id="Moon_5_">
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M129.21,122.23c0,2.96-2.32,5.38-5.25,5.53c-0.1,0-0.19,0.01-0.29,0.01c-3.06,0-5.54-2.48-5.54-5.54
							c0-1.83,0.89-3.45,2.26-4.46c0.92-0.68,2.05-1.07,3.28-1.07C126.73,116.69,129.21,119.17,129.21,122.23z"/>
						<path className="svgc3-0" d="M123.68,128.1c-3.24,0-5.87-2.63-5.87-5.87c0-1.86,0.9-3.63,2.39-4.73c1.01-0.75,2.21-1.14,3.47-1.14
							c3.24,0,5.87,2.63,5.87,5.87c0,3.13-2.44,5.7-5.56,5.86C123.88,128.09,123.78,128.1,123.68,128.1z M123.68,117.02
							c-1.12,0-2.18,0.35-3.08,1.01c-1.33,0.98-2.12,2.55-2.12,4.19c0,2.87,2.33,5.2,5.2,5.2c0.09,0,0.18,0,0.27-0.01
							c2.77-0.14,4.93-2.42,4.93-5.2C128.88,119.36,126.55,117.02,123.68,117.02z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M123.14,124c0,0.61-0.5,1.11-1.11,1.11c-0.61,0-1.11-0.5-1.11-1.11c0-0.61,0.5-1.11,1.11-1.11
							C122.65,122.89,123.14,123.39,123.14,124z"/>
						<path className="svgc3-0" d="M122.03,125.44c-0.79,0-1.44-0.65-1.44-1.44c0-0.79,0.65-1.44,1.44-1.44c0.79,0,1.44,0.65,1.44,1.44
							C123.47,124.8,122.83,125.44,122.03,125.44z M122.03,123.23c-0.43,0-0.77,0.35-0.77,0.77c0,0.43,0.35,0.77,0.77,0.77
							c0.43,0,0.77-0.35,0.77-0.77C122.81,123.57,122.46,123.23,122.03,123.23z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M125.77,121.17c0,0.43-0.35,0.77-0.77,0.77c-0.43,0-0.77-0.35-0.77-0.77s0.35-0.77,0.77-0.77
							C125.43,120.4,125.77,120.74,125.77,121.17z"/>
						<path className="svgc3-0" d="M125,122.27c-0.61,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.61,0,1.1,0.5,1.1,1.1S125.61,122.27,125,122.27z
							 M125,120.73c-0.24,0-0.44,0.2-0.44,0.44c0,0.24,0.2,0.44,0.44,0.44c0.24,0,0.44-0.2,0.44-0.44
							C125.44,120.92,125.24,120.73,125,120.73z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M122.83,119.59c0,0.35-0.28,0.63-0.63,0.63c-0.35,0-0.63-0.28-0.63-0.63c0-0.35,0.28-0.63,0.63-0.63
							C122.55,118.95,122.83,119.24,122.83,119.59z"/>
						<path className="svgc3-0" d="M122.2,120.55c-0.53,0-0.97-0.43-0.97-0.97c0-0.53,0.43-0.97,0.97-0.97c0.53,0,0.97,0.43,0.97,0.97
							C123.16,120.12,122.73,120.55,122.2,120.55z M122.2,119.29c-0.17,0-0.3,0.14-0.3,0.3c0,0.17,0.14,0.3,0.3,0.3
							c0.17,0,0.3-0.14,0.3-0.3C122.5,119.42,122.36,119.29,122.2,119.29z"/>
					</g>
				</g>
			</g>
			<g id="Black_hole_5_">
				<g>
					<path className="svgc1-0" d="M295.43,109.76c-0.17,0-0.34-0.01-0.52-0.03c-1.78-0.22-3.23-1.68-3.45-3.45
						c-0.16-1.25,0.26-2.47,1.14-3.35c0.88-0.88,2.1-1.3,3.35-1.14c1.78,0.22,3.23,1.68,3.45,3.45l0,0
						c0.16,1.25-0.26,2.47-1.14,3.35C297.5,109.35,296.49,109.76,295.43,109.76z M295.43,102.42c-0.89,0-1.73,0.34-2.36,0.98
						c-0.74,0.74-1.08,1.75-0.95,2.8c0.19,1.48,1.4,2.69,2.88,2.88c1.04,0.13,2.06-0.22,2.8-0.95c0.74-0.74,1.08-1.75,0.95-2.8
						c-0.19-1.48-1.4-2.69-2.88-2.88C295.72,102.43,295.58,102.42,295.43,102.42z"/>
				</g>
				<g>
					<g>
						<path className="svgc1-0" d="M289.25,108.15c-0.14,0-0.27-0.09-0.32-0.23c-0.18-0.52-0.29-1.07-0.33-1.61c-0.19-2.4,0.86-4.68,2.8-6.1
							c0.15-0.11,0.36-0.08,0.46,0.07c0.11,0.15,0.08,0.36-0.07,0.47c-1.76,1.28-2.7,3.34-2.53,5.51c0.04,0.49,0.14,0.98,0.3,1.46
							c0.06,0.17-0.04,0.36-0.21,0.42C289.32,108.15,289.28,108.15,289.25,108.15z"/>
					</g>
					<g>
						<path className="svgc1-0" d="M295.44,112.61c-0.19,0-0.37-0.01-0.56-0.02c-1.25-0.1-2.45-0.55-3.47-1.29
							c-0.15-0.11-0.18-0.32-0.07-0.47c0.11-0.15,0.32-0.18,0.46-0.07c0.92,0.67,2.01,1.08,3.13,1.17c1.98,0.16,3.88-0.61,5.18-2.12
							c0.12-0.14,0.33-0.15,0.47-0.03c0.14,0.12,0.15,0.33,0.03,0.47C299.29,111.76,297.43,112.61,295.44,112.61z"/>
					</g>
					<g>
						<path className="svgc1-0" d="M302.18,106.9l-0.32-0.03c-0.32-0.05-0.29-0.38-0.27-0.52c0-0.03,0.01-0.06,0.02-0.08
							c0.05-0.97,0.01-5.28-4.59-6.49c-0.68-0.18-1.37-0.24-2.06-0.19c-0.19,0.02-0.34-0.12-0.36-0.31
							c-0.01-0.18,0.12-0.34,0.31-0.36c0.76-0.06,1.53,0.01,2.28,0.21c5.17,1.35,5.26,6.5,5.06,7.46L302.18,106.9z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-0" d="M291.84,115.88c-0.04,0-0.08-0.01-0.11-0.02c-0.81-0.3-1.59-0.69-2.3-1.18c-3.13-2.13-4.9-5.65-4.73-9.42
							c0.01-0.18,0.17-0.33,0.35-0.32c0.18,0.01,0.33,0.16,0.32,0.35c-0.16,3.54,1.5,6.84,4.44,8.84c0.67,0.45,1.4,0.83,2.16,1.11
							c0.17,0.06,0.26,0.25,0.2,0.43C292.11,115.79,291.98,115.88,291.84,115.88z"/>
					</g>
					<g>
						<path className="svgc1-0" d="M298.19,116.14c-0.15,0-0.28-0.1-0.32-0.25c-0.05-0.18,0.06-0.36,0.23-0.41c1.8-0.49,3.42-1.46,4.68-2.8
							c2.23-2.38,3.17-5.57,2.58-8.76c-0.03-0.18,0.09-0.35,0.27-0.39c0.18-0.04,0.35,0.09,0.39,0.27c0.63,3.4-0.38,6.81-2.75,9.34
							c-1.35,1.44-3.07,2.47-4.99,2.99C298.25,116.14,298.22,116.14,298.19,116.14z"/>
					</g>
					<g>
						<path className="svgc1-0" d="M286.82,100.22c-0.06,0-0.13-0.02-0.19-0.06c-0.15-0.1-0.19-0.31-0.09-0.46
							c0.68-0.99,1.51-1.86,2.49-2.59c6.74-5.02,13.13-0.08,14.1,1.1l0.2,0.24l-0.22,0.24c-0.24,0.22-0.47,0-0.65-0.18
							c-0.02-0.02-0.04-0.05-0.06-0.08c-1.15-1.02-6.8-5.4-12.98-0.79c-0.92,0.68-1.7,1.5-2.34,2.43
							C287.03,100.17,286.93,100.22,286.82,100.22z"/>
					</g>
				</g>
			</g>
			<g id="Earth_4_">
				<g>
					<path className="svgc2-0" d="M154.92,146.13c-3.24,0-6.35-1.72-8.02-4.63c-1.87-3.26-1.5-7.15,0.96-10.14c2.46-3,6.36-4.17,9.92-2.98
						c3.74,1.25,6.26,4.83,6.21,8.77l0.01,0l-0.01,0.31c0,0.01,0,0.02,0,0.03l-0.04,0.72c0,0.01,0,0.03,0,0.04
						c0,0.09-0.01,0.15-0.01,0.16l-0.01,0c-0.01,0.08-0.03,0.15-0.07,0.2c-0.58,3.29-2.94,6.06-6.15,7.09
						C156.78,145.99,155.85,146.13,154.92,146.13z M154.96,128.58c-2.48,0-4.9,1.13-6.59,3.19c-2.28,2.77-2.62,6.37-0.89,9.39
						c1.99,3.48,6.21,5.12,10.02,3.9c3.3-1.06,5.63-4.12,5.81-7.61c0.18-3.78-2.18-7.25-5.75-8.45
						C156.71,128.72,155.83,128.58,154.96,128.58z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M154.91,146.13c-5.03,0-9.12-4.09-9.12-9.12s4.09-9.12,9.12-9.12c5.03,0,9.12,4.09,9.12,9.12
						S159.94,146.13,154.91,146.13z M154.91,128.57c-4.66,0-8.45,3.79-8.45,8.45c0,4.66,3.79,8.45,8.45,8.45
						c4.66,0,8.45-3.79,8.45-8.45C163.36,132.36,159.57,128.57,154.91,128.57z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M159.98,144.6l-0.17-0.07c-0.28-0.12-0.5-0.33-0.63-0.61c-0.28-0.61-0.02-1.33,0.59-1.61l2.23-1.04
						c0.19-0.09,0.32-0.28,0.32-0.49c0-0.08-0.02-0.16-0.05-0.23c-0.06-0.13-0.17-0.23-0.31-0.28s-0.28-0.04-0.42,0.02l-1.12,0.52
						c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.14-0.29-0.15-0.62-0.04-0.93c0.11-0.3,0.33-0.55,0.63-0.68
						l4.63-2.16l0,0.52c0.02,2.99-1.44,5.81-3.9,7.53L159.98,144.6z M161.6,142.19l-1.55,0.72c-0.27,0.13-0.39,0.45-0.26,0.72
						c0.03,0.07,0.08,0.14,0.14,0.19C160.56,143.35,161.12,142.8,161.6,142.19z M163.35,137.5l-3.67,1.71
						c-0.13,0.06-0.23,0.17-0.28,0.31c-0.05,0.14-0.04,0.28,0.02,0.42c0.12,0.26,0.46,0.39,0.72,0.26l1.11-0.52
						c0.29-0.14,0.62-0.15,0.93-0.04c0.25,0.09,0.46,0.26,0.6,0.47C163.1,139.28,163.3,138.4,163.35,137.5z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M160.78,135.79c-0.47,0-0.9-0.27-1.1-0.7c-0.13-0.27-0.45-0.39-0.72-0.26l-0.32,0.15
						c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.13-0.27-0.45-0.39-0.73-0.26l-0.4,0.19
						c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.13-0.27-0.45-0.39-0.73-0.26l-0.72,0.33
						c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.28-0.61-0.02-1.33,0.59-1.61l2.39-1.11
						c0.19-0.09,0.31-0.28,0.31-0.49c0-0.08-0.02-0.16-0.05-0.23c-0.06-0.13-0.17-0.23-0.31-0.28c-0.14-0.05-0.29-0.04-0.42,0.02
						l-1.99,0.93c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.05-0.11-0.08-0.21-0.1-0.3l-0.04-0.23l0.2-0.12
						c1.39-0.83,3.01-1.28,4.69-1.29c0.02,0,0.03,0,0.05,0c4,0,7.47,2.56,8.65,6.38l0.09,0.28l-2.41,1.11
						C161.13,135.75,160.96,135.79,160.78,135.79z M159.19,134.11c0.46,0,0.89,0.26,1.1,0.7c0.12,0.26,0.46,0.39,0.72,0.26
						l1.87-0.86c-1.18-3.39-4.32-5.63-7.93-5.63c-0.01,0-0.03,0-0.04,0c-1.47,0.01-2.88,0.37-4.11,1.05
						c0.14,0.22,0.44,0.31,0.69,0.2l1.99-0.93c0.29-0.14,0.62-0.15,0.93-0.04c0.3,0.11,0.55,0.33,0.68,0.63
						c0.08,0.16,0.11,0.33,0.11,0.51c0,0.47-0.27,0.9-0.7,1.1l-2.39,1.11c-0.27,0.13-0.39,0.45-0.26,0.73
						c0.12,0.26,0.46,0.39,0.72,0.26l0.72-0.33c0.61-0.28,1.33-0.02,1.61,0.59c0.12,0.26,0.46,0.39,0.73,0.26l0.4-0.19
						c0.61-0.28,1.33-0.02,1.61,0.59c0.12,0.26,0.46,0.39,0.73,0.26l0.32-0.15C158.85,134.15,159.02,134.11,159.19,134.11z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M152.47,145.8l-0.24-0.07c-3.84-1.16-6.43-4.65-6.44-8.69c0-0.87,0.12-1.72,0.34-2.5l0.08-0.27l0.28,0.03
						c0.43,0.05,0.8,0.31,0.97,0.68c0.12,0.26,0.43,0.39,0.73,0.25l0.4-0.19c0.61-0.28,1.33-0.02,1.61,0.58
						c0.12,0.26,0.46,0.39,0.73,0.26l0.72-0.33c0.29-0.14,0.62-0.15,0.93-0.04c0.3,0.11,0.55,0.33,0.68,0.63
						c0.08,0.16,0.11,0.33,0.11,0.51c0,0.47-0.27,0.9-0.7,1.1l-2.31,1.08c-0.13,0.06-0.23,0.17-0.28,0.31
						c-0.05,0.14-0.04,0.28,0.02,0.41c0.12,0.26,0.46,0.39,0.72,0.27l1.91-0.89c0.6-0.28,1.33-0.02,1.61,0.59
						c0.08,0.17,0.11,0.34,0.11,0.51c0,0.47-0.28,0.9-0.7,1.1l-2.47,1.15c-0.13,0.06-0.23,0.17-0.28,0.31
						c-0.05,0.14-0.04,0.28,0.02,0.42c0.12,0.26,0.46,0.39,0.72,0.26l0.32-0.15c0.61-0.28,1.33-0.02,1.61,0.59
						c0.08,0.16,0.11,0.33,0.11,0.51c0,0.46-0.27,0.89-0.68,1.09c-0.21,0.13-0.44,0.28-0.53,0.34L152.47,145.8z M146.69,135.06
						c-0.15,0.63-0.23,1.29-0.23,1.98c0.01,3.69,2.34,6.89,5.81,8.01c0.1-0.07,0.26-0.16,0.5-0.32l0.04-0.02
						c0.19-0.09,0.32-0.28,0.32-0.49c0-0.08-0.02-0.16-0.05-0.23c-0.13-0.27-0.45-0.39-0.73-0.26l-0.32,0.15
						c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.14-0.29-0.15-0.62-0.04-0.92c0.11-0.3,0.33-0.55,0.63-0.68
						l2.47-1.15c0.19-0.09,0.32-0.28,0.32-0.49c0-0.08-0.02-0.16-0.05-0.23c-0.13-0.27-0.45-0.39-0.72-0.26l-1.91,0.89
						c-0.16,0.07-0.34,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7c-0.14-0.29-0.15-0.62-0.04-0.92c0.11-0.3,0.33-0.55,0.63-0.69
						l2.31-1.08c0.19-0.09,0.31-0.28,0.31-0.49c0-0.08-0.02-0.16-0.05-0.23c-0.06-0.13-0.17-0.23-0.31-0.28
						c-0.14-0.05-0.29-0.04-0.42,0.02l-0.72,0.33c-0.16,0.08-0.33,0.11-0.51,0.11c-0.47,0-0.9-0.27-1.1-0.7
						c-0.13-0.27-0.45-0.39-0.73-0.26l-0.4,0.19c-0.63,0.29-1.34,0.01-1.61-0.58C146.83,135.18,146.77,135.11,146.69,135.06z"/>
				</g>
			</g>
		</g>
		<g id="plants_2_" className="lnc0">
			<g>
				<g>
					<g>
						<path className="svgc1-3" d="M82.25,236.32c3.63-1.93,7.85-1.18,9.3,0.7c1.38,1.79-0.66,3.46,0.47,5.38c1.77,3,7.75,0.51,10.52,3.12
							c2.86,2.7-1.92,6.86-0.07,11.27c2.27,5.41,10.93,2.68,14.42,7.71c3.35,4.81,0.94,15.25-7.36,19.62
							c-7.33,3.86-16.8,1.7-19.34-2.89c-2.97-5.36,4.83-11.68,1.34-16.35c-3.13-4.19-11.05-1.29-12.69-5.54
							c-1.38-3.55,3.74-6.68,2.23-11.42c-0.99-3.11-3.75-3.5-3.2-6.34C78.25,239.6,80,237.52,82.25,236.32z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M117.62,293.06c-0.04,0-0.08-0.01-0.12-0.02c-4.57-1.75-11.41-10.77-20.32-26.8
							c-6.61-11.9-11.97-23.44-12.02-23.56c-0.08-0.17,0-0.36,0.16-0.44c0.17-0.08,0.36,0,0.44,0.16
							c0.21,0.46,21.38,45.96,31.98,50.02c0.17,0.07,0.26,0.26,0.19,0.43C117.88,292.98,117.75,293.06,117.62,293.06z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M104.56,278.9c-0.02,0-0.04,0-0.06,0l-9.94-1.72c-0.18-0.03-0.3-0.2-0.27-0.38
							c0.03-0.18,0.2-0.3,0.38-0.27l9.66,1.68l4.13-11.8c0.06-0.17,0.25-0.26,0.42-0.2c0.17,0.06,0.26,0.25,0.2,0.42l-4.23,12.07
							C104.82,278.81,104.69,278.9,104.56,278.9z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-9 lnc0" d="M92.56,257.3c-0.01,0-0.03,0-0.04,0l-9.11-1.06c-0.18-0.02-0.31-0.19-0.29-0.37
							c0.02-0.18,0.18-0.31,0.37-0.29l8.89,1.03l4.24-7.64c0.09-0.16,0.29-0.22,0.45-0.13c0.16,0.09,0.22,0.29,0.13,0.45l-4.35,7.84
							C92.79,257.24,92.68,257.3,92.56,257.3z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path className="svgc1-5" d="M142.29,229.54c-2.82-0.45-5.74,1.59-6.11,3.7c-0.4,2.26,2.36,3.28,1.98,5.21
							c-0.62,3.13-8.31,2.79-8.98,5.88c-0.46,2.14,3.03,3.22,3.04,6.15c0.01,3.51-4.99,4.2-6.18,8.04
							c-1.43,4.62,2.92,12.87,10.18,14.05c5.9,0.96,12.31-2.98,12.91-7.11c0.66-4.55-6.05-7.23-5.02-11.36
							c1.03-4.15,8.54-4.49,8.55-7.52c0-2.62-5.58-3.09-6.44-7.28c-0.53-2.58,1.3-3.85,0.28-6.35
							C145.85,231.35,144.28,229.86,142.29,229.54z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="lnc5" d="M137.06,281.2c-0.15,0-0.29-0.1-0.32-0.26c-4.03-17.33,4.33-37.6,4.41-37.81
							c0.07-0.17,0.27-0.25,0.44-0.18c0.17,0.07,0.25,0.27,0.18,0.44c-0.08,0.2-8.36,20.28-4.38,37.4c0.04,0.18-0.07,0.36-0.25,0.4
							C137.11,281.2,137.08,281.2,137.06,281.2z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path className="svgc2-0" d="M141.48,281.76c-0.06-0.04-0.1-0.11-0.12-0.18c-1.04-4.16,1.52-12.02,7.61-23.36
							c4.51-8.4,9.38-15.7,9.43-15.77c0.1-0.15,0.31-0.19,0.46-0.09c0.15,0.1,0.19,0.31,0.09,0.46
							c-0.19,0.29-19.33,29.02-16.95,38.6c0.04,0.18-0.06,0.36-0.24,0.4C141.66,281.85,141.56,281.82,141.48,281.76z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M158.5,243c-0.08-0.06-0.13-0.16-0.13-0.26c-0.01-7.75,7.93-9.94,8.01-9.96
							c0.09-0.02,0.18-0.01,0.26,0.04c0.08,0.05,0.13,0.13,0.15,0.22c1.48,7.24-7.9,10-7.99,10.02
							C158.69,243.09,158.58,243.07,158.5,243C158.5,243,158.5,243,158.5,243z M166.2,233.53c-1.46,0.48-6.89,2.68-7.15,8.75
							C160.77,241.67,167.04,239.05,166.2,233.53z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M150.95,255.21c-0.06-0.04-0.1-0.1-0.12-0.18c-0.03-0.1-0.01-0.2,0.05-0.28
							c4.58-6.26,12.39-2.8,12.47-2.76c0.08,0.04,0.14,0.1,0.17,0.19c0.03,0.08,0.03,0.17-0.01,0.25
							c-3.09,6.73-12.4,2.86-12.49,2.82C151,255.24,150.97,255.23,150.95,255.21z M162.76,252.47c-0.63-0.24-2.14-0.74-3.93-0.81
							c-2.98-0.12-5.38,0.94-7.14,3.14C153.41,255.42,160.16,257.45,162.76,252.47z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M150.95,255.21c0,0-0.01-0.01-0.01-0.01c-5.87-5.06-2.34-12.49-2.3-12.57c0.04-0.08,0.11-0.14,0.2-0.17
							c0.09-0.03,0.18-0.02,0.26,0.03c6.44,3.62,2.4,12.51,2.36,12.6c-0.04,0.09-0.13,0.16-0.23,0.19
							C151.13,255.29,151.03,255.27,150.95,255.21z M149.09,243.26c-0.59,1.42-2.48,6.97,1.94,11.13
							C151.7,252.68,153.82,246.23,149.09,243.26z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M143.7,269.95c-0.04-0.03-0.07-0.07-0.1-0.12c-0.04-0.09-0.04-0.2,0-0.29c3.49-6.92,12-5,12.08-4.98
							c0.09,0.02,0.16,0.08,0.21,0.15c0.05,0.08,0.06,0.17,0.03,0.26c-1.95,7.13-12,5.06-12.1,5.04
							C143.79,270,143.74,269.98,143.7,269.95z M155.19,265.13c-1.54-0.26-7.81-0.95-10.78,4.3
							C146.27,269.72,153.43,270.47,155.19,265.13z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M142.75,269.29c-5.43-4.18-3.84-11.38-3.82-11.45c0.02-0.09,0.08-0.17,0.17-0.22
							c0.08-0.05,0.18-0.05,0.27-0.02c6.93,2.51,4.89,12.06,4.87,12.15c-0.02,0.1-0.1,0.19-0.19,0.23c-0.1,0.04-0.21,0.03-0.3-0.02
							C143.38,269.75,143.06,269.53,142.75,269.29z M139.51,258.37c-0.24,1.55-0.78,7.43,4.16,10.76
							C143.96,267.28,144.64,260.55,139.51,258.37z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path className="svgc1-5" d="M323.63,235.53c-2.73-1.23-6.25-0.39-7.2,1.3c-1.02,1.8-0.46,4.08-1.37,5.61
							c-1.48,2.47-7.22-0.73-8.74,1.7c-1.06,1.68,0.59,4.14-0.21,6.63c-0.95,2.98-4.59,1.57-6.85,4.47
							c-2.71,3.49-0.56,11.82,6.47,15.02c5.72,2.6,13.28,1.19,15.01-2.14c1.91-3.67-1.4-6.97,0.77-10.17
							c2.18-3.21,7.13-2.23,7.96-4.81c0.72-2.22-3.03-4.36-2.75-8.18c0.17-2.35,0.58-2.84,0.24-5.27
							C326.74,238.14,325.55,236.4,323.63,235.53z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="lnc5" d="M304.05,277.71c-0.03-0.05-0.05-0.11-0.04-0.17c0.65-15.96,14.67-30.67,14.81-30.81
							c0.11-0.12,0.3-0.12,0.42-0.01c0.12,0.11,0.12,0.3,0.01,0.42c-0.14,0.15-14,14.69-14.65,30.43c-0.01,0.16-0.15,0.29-0.31,0.28
							C304.19,277.84,304.1,277.79,304.05,277.71z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path className="svgc2-0" d="M306.64,268.45C306.64,268.45,306.64,268.45,306.64,268.45c-2.96,0.25-6.13-0.57-8.71-2.24
							c-2.46-1.59-4.05-3.75-4.27-5.77c-0.25-2.43,1.47-4.15,3.13-5.82c1.62-1.62,3.14-3.15,2.65-5.16
							c-0.43-1.77-2.14-2.77-3.78-3.73c-1.8-1.05-3.51-2.04-3.44-3.97c0.05-1.5,1.14-2.42,2.29-3.39c1.22-1.02,2.47-2.08,2.64-3.93
							c0.09-1.01-0.17-1.75-0.42-2.48c-0.34-0.97-0.66-1.88,0.12-3.04c0.9-1.34,2.8-2.32,4.83-2.5l0,0c0.01,0,0.02,0,0.03,0
							c3.27-0.28,6.08,1.6,6.57,3.46c0.26,0.99-0.22,1.66-0.64,2.25c-0.36,0.51-0.71,0.99-0.62,1.68c0.16,1.2,1.49,1.82,2.9,2.49
							c1.42,0.67,2.89,1.35,3.49,2.73c0.69,1.58-0.32,2.76-1.39,4.02c-0.97,1.13-1.97,2.31-1.97,3.95c-0.01,2.15,1.74,3.38,3.6,4.68
							c1.82,1.28,3.7,2.6,4.19,4.91c0.42,1.99-0.31,4.48-1.94,6.67C313.68,266.24,310.3,268.14,306.64,268.45z M301.74,227.02
							c-1.84,0.16-3.6,1.06-4.39,2.24c-0.61,0.91-0.38,1.58-0.05,2.51c0.26,0.75,0.56,1.59,0.45,2.73c-0.2,2.1-1.61,3.28-2.85,4.33
							c-1.1,0.92-2.04,1.72-2.08,2.96c-0.05,1.58,1.43,2.44,3.15,3.44c1.67,0.98,3.57,2.08,4.06,4.1c0.57,2.33-1.15,4.05-2.81,5.72
							c-1.57,1.57-3.19,3.19-2.96,5.34c0.4,3.8,6.17,7.99,12.33,7.48c0,0,0,0,0,0c3.49-0.29,6.72-2.1,8.84-4.96
							c1.53-2.06,2.21-4.37,1.83-6.2c-0.44-2.08-2.22-3.33-3.95-4.54c-1.9-1.33-3.86-2.71-3.85-5.17c0.01-1.86,1.13-3.17,2.12-4.33
							c1.03-1.2,1.84-2.15,1.29-3.39c-0.51-1.17-1.88-1.81-3.2-2.43c-1.49-0.7-3.04-1.42-3.24-2.95c-0.12-0.92,0.33-1.55,0.72-2.11
							c0.39-0.55,0.73-1.03,0.55-1.75c-0.43-1.63-3.05-3.26-5.94-3.02C301.76,227.01,301.75,227.01,301.74,227.02L301.74,227.02z
							 M301.71,226.72L301.71,226.72L301.71,226.72z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M309.74,276.85c-0.08,0.01-0.17-0.02-0.23-0.08c-2.68-2.58-4.79-10.91-6.27-24.75
							c-1.1-10.27-1.42-19.91-1.42-20.01c-0.01-0.16,0.12-0.3,0.29-0.31c0.16-0.01,0.3,0.12,0.31,0.29
							c0.01,0.38,1.33,38.39,7.51,44.34c0.12,0.11,0.12,0.3,0.01,0.42C309.87,276.82,309.81,276.85,309.74,276.85z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M304.81,263.11c-0.06,0.01-0.13-0.01-0.19-0.05l-6.43-4.15c-0.14-0.09-0.18-0.27-0.09-0.41
							c0.09-0.14,0.27-0.18,0.41-0.09l6.22,4.01l6.35-6.99c0.11-0.12,0.3-0.13,0.42-0.02c0.12,0.11,0.13,0.3,0.02,0.42l-6.52,7.17
							C304.95,263.07,304.88,263.1,304.81,263.11z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M302.82,244.48c-0.06,0-0.12-0.01-0.17-0.04l-6.05-3.43c-0.14-0.08-0.19-0.26-0.11-0.4
							c0.08-0.14,0.26-0.19,0.4-0.11l5.88,3.34l5.2-4.06c0.13-0.1,0.32-0.08,0.42,0.05c0.1,0.13,0.08,0.32-0.05,0.42l-5.35,4.18
							C302.94,244.45,302.88,244.48,302.82,244.48z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<path className="svgc3-8" d="M313.5,265.43c-3.52,0-6.78,0.9-9.44,2.43c-1.99-1.53-4.68-2.47-7.63-2.47c-3.15,0-6,1.07-8.02,2.78
				c-2.76-1.72-6.22-2.75-9.97-2.75c-4.29,0-8.18,1.33-11.1,3.52c-2.03-2.16-5.24-3.56-8.85-3.56c-1.9,0-3.69,0.39-5.26,1.07
				c-2.99-3.25-7.76-5.36-13.15-5.36c-0.94,0-1.86,0.06-2.75,0.19c-1.71-5.08-7.43-8.81-14.22-8.81c-0.79,0-1.56,0.05-2.31,0.15
				c0.02-0.19,0.02-0.39,0.02-0.59c0-4.98-4.97-9.02-11.11-9.02c-0.36,0-0.72,0.01-1.07,0.04l-1.1-43.78h-18.58l-1.24,43.24
				c-5.19,0.76-9.13,4.44-9.13,8.87c0,0.2,0.01,0.39,0.02,0.59c-0.75-0.1-1.52-0.15-2.31-0.15c-6.8,0-12.52,3.73-14.22,8.81
				c-0.9-0.13-1.81-0.19-2.75-0.19c-5.38,0-10.16,2.11-13.15,5.36c-1.56-0.69-3.35-1.07-5.25-1.07c-3.61,0-6.82,1.39-8.85,3.56
				c-2.92-2.19-6.82-3.52-11.1-3.52c-3.75,0-7.21,1.02-9.97,2.75c-2.02-1.71-4.87-2.78-8.03-2.78c-2.96,0-5.64,0.94-7.63,2.47
				c-2.67-1.53-5.93-2.43-9.44-2.43c-9.05,0-16.39,5.96-16.39,13.31c0,0.03,0,0.07,0,0.1c-0.56,0.6-0.84,1.25-0.84,1.95
				c0,1.17,0.27,2.28,0.79,3.34c0.88,1.79,2.74,2.89,4.74,2.89H322.6c3.65,0,6.77-2.71,7.21-6.33c0.03-0.21,0.04-0.41,0.06-0.62
				c0.01-0.11,0.01-0.23,0.01-0.34c0.01-0.11,0.01-0.23,0.01-0.34C329.89,271.39,322.55,265.43,313.5,265.43z"/>
		</g>
		<g id="rocket_21_">
			<g id="rocket_22_">
				<g>
					<g>
						<g>
							<path className="lnc4" d="M183.96,196.53l7.76,34.94c2.18,9.82,10.55,9.76,12.76-0.08l7.78-34.7L183.96,196.53z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="lnc3" d="M185.8,180.61l6.53,34.94c1.83,9.81,8.91,9.76,10.79-0.09l6.61-34.71L185.8,180.61z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-0" d="M178.39,161.07c0,0-13.54,20.47-13.91,48.8c-0.02,1.64,1.97,2.46,3.1,1.27
							c3.36-3.52,9.25-9.42,16.32-14.5L178.39,161.07z"/>
					</g>
				</g>
				<g className="lnc12">
					<g>
						<path className="lnc68" d="M178.36,161.11c0,0-1.69,16.79,2.08,38.16c0,0,2.91-2.25,3.46-2.62L178.36,161.11z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-0" d="M218.18,161.07c0,0,13.54,20.47,13.91,48.8c0.02,1.64-1.97,2.46-3.1,1.27
							c-3.36-3.52-9.66-9.37-16.73-14.45L218.18,161.07z"/>
					</g>
				</g>
				<g className="lnc12">
					<g>
						<path className="lnc68" d="M218.1,161.19c0,0,1.78,17.04-1.99,38.4c0,0-3.3-2.53-3.85-2.9L218.1,161.19z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M214.3,187.41c-0.72,3.66-1.44,6.92-2.1,9.36l-14.05-0.08l-14.24-0.08c-0.63-2.45-1.3-5.71-1.98-9.38
							L214.3,187.41z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc2-0" d="M197.52,81.96c-2.07,2.32-6.36,7.31-10.52,14.87l23.12,0.14c-4.09-7.64-8.37-12.68-10.47-15.02
							C199.08,81.32,198.08,81.32,197.52,81.96z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="lnc69" d="M186.99,96.83c-5.22,9.74-10.22,23.11-10.32,38.96c-0.07,11.83,2.59,36.34,5.25,51.43l32.37,0.19
							c2.85-15.05,5.6-39.54,5.67-51.36c0.09-15.86-4.73-29.28-9.85-39.08L186.99,96.83z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="lnc70" d="M186.99,96.83c-5.22,9.74-10.22,23.11-10.32,38.96c-0.07,11.83,2.59,36.34,5.25,51.43l32.37,0.19
							c2.85-15.05,5.6-39.54,5.67-51.36c0.09-15.86-4.73-29.28-9.85-39.08L186.99,96.83z"/>
					</g>
				</g>
				<g className="lnc12">
					<g>
						<path className="lnc68" d="M219.97,136.05c0.09-15.86-4.73-29.28-9.85-39.08l-0.11,0c-4.06-7.53-8.27-12.7-10.36-15.02
							c-0.35-0.39-0.85-0.53-1.32-0.45c-1.85-0.03-9.41,12.02-9.41,12.02c0.35,0.09,6.36-5.59,9.86-5.63
							c2.72-0.03,8.71,12.62,10.23,16.17c3.9,9.14,6.38,14.04,7.1,31.4c0.62,15.02-4.1,45.16-6.99,56.54
							c-0.72,2.84-5.13,4.58-5.16,4.77l8.24,0c0.66-2.44,1.38-5.69,2.1-9.36C217.14,172.36,219.9,147.87,219.97,136.05z"/>
					</g>
				</g>
				<g>
					<g>
						
							<ellipse transform="matrix(0.9201 -0.3917 0.3917 0.9201 -33.578 87.947)" className="svgc1-0" cx="198.78" cy="126.28" rx="13.29" ry="13.29"/>
					</g>
				</g>
				<g className="lnc12">
					<g>
						<g>
							<path className="lnc68" d="M208.63,117.37c2.15,2.38,3.46,5.53,3.44,8.98c-0.04,7.33-6.04,13.25-13.37,13.21
								c-3.4-0.02-6.5-1.32-8.84-3.45c2.42,2.67,5.9,4.36,9.77,4.38c7.33,0.04,13.32-5.88,13.37-13.21
								C213.02,123.36,211.33,119.82,208.63,117.37z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc1-0" d="M197.83,211.95L197.83,211.95c-0.89-0.01-1.61-0.74-1.6-1.64c0,0-3.12-17.89-3.09-23.8
							c0.03-5.91,3.36-23.44,3.36-23.44c0.01-0.9,0.73-1.62,1.62-1.62l0,0c0.89,0.01,1.61,0.74,1.6,1.64c0,0,3.22,17.64,3.19,23.55
							c-0.03,5.91-3.46,23.7-3.46,23.7C199.44,211.23,198.72,211.96,197.83,211.95z"/>
					</g>
				</g>
				<g className="lnc12">
					<g>
						<path className="lnc68" d="M199.45,210.33c0,0,3.43-17.78,3.46-23.7c0.03-5.91-3.19-23.55-3.19-23.55c0.01-0.9-0.71-1.63-1.6-1.64
							c0,0,4.9,19.11-0.3,50.5C198.72,211.96,199.44,211.23,199.45,210.33z"/>
					</g>
				</g>
				<g>
					<circle className="lnc2" cx="198.78" cy="126.28" r="10.74"/>
				</g>
				<g className="lnc53">
					<path className="lnc5" d="M202.43,116.17l-13.76,13.76c-0.35-0.97-0.57-2.01-0.62-3.09l11.29-11.29
						C200.42,115.6,201.45,115.82,202.43,116.17z"/>
				</g>
				<g className="lnc53">
					<path className="lnc5" d="M204.44,117.15l-14.79,14.79c-0.16-0.26-0.32-0.54-0.46-0.81l14.44-14.43
						C203.9,116.83,204.17,116.98,204.44,117.15z"/>
				</g>
			</g>
		</g>
		<g id="man_17_">
			<g>
				<path className="svgc3-0" d="M114.43,331.89v5.03c0,0.63,0.42,1.14,0.94,1.14h16.82v-0.63c0-0.5-0.33-0.95-0.82-1.08
					c-2.03-0.57-7.33-2.23-9.95-4.6C117.13,327.87,114.43,331.89,114.43,331.89z"/>
			</g>
			<g>
				<path className="svgc3-0" d="M87.81,324.71l-2.71,4.24c-0.34,0.53-0.26,1.19,0.18,1.47l14.17,9.06l0.34-0.53
					c0.27-0.42,0.23-0.98-0.1-1.35c-1.4-1.58-4.97-5.83-5.9-9.23C92.25,322.78,87.81,324.71,87.81,324.71z"/>
			</g>
			<g>
				<polygon className="svgc3-1" points="127.97,247.62 121.64,331.94 114.4,331.94 110.96,276.74 112.55,242.73 				"/>
			</g>
			<g>
				<path className="svgc3-2" d="M99.11,246.21c-1.57,22.59,7.38,27.56-11.47,78.76l6.29,3.9c0,0,24.83-36.06,24.83-81.24L99.11,246.21z"/>
			</g>
			<g>
				<path className="lnc15" d="M152.79,219.39c-0.53,1.39-13.59,5.67-14.66,5.67c-1.32,0-6.96-9.57-6.96-9.57l-7.35,4.75
					c0,0,10.82,12.12,13.58,12.3c5.01,0.33,18.43-9.13,18.43-9.13c0.53-0.22,4.25,0.31,4.24-1.59c-0.02-2.85,0.53-5.63-4.3-5.16
					c0,0,0.58-3.41-1.87-2.88c0,0,0.71,2.3,0.12,2.88C152.87,217.8,152.79,219.39,152.79,219.39z"/>
			</g>
			<g>
				<path className="svgc1-0" d="M125.17,223.11c0,0-9.67-11.04-5.23-14.01c4.44-2.97,12.62,7.75,12.62,7.75S128.42,221.85,125.17,223.11z"
					/>
			</g>
			<g>
				<path className="lnc15" d="M122.07,188.01c0,0,3.95,5.45,0.26,14.49c-0.29,0.7-5.35-0.16-5.35-0.16s1.97,2.91,3.11,4.25
					c0.81,0.96,3.9,6.74-1.65,4.37c-2.13-0.91-12.5,1.63-10.39-2.71c1.81-3.72,2.91-6.95,2.91-6.95S110.29,182.12,122.07,188.01z"/>
			</g>
			<g>
				<path className="svgc1-2" d="M109.56,205.04c-12.57,8.16-10.67,42.59-10.67,42.59h29.08c0,0,2.32-40.49-7.88-40.91l0,0
					c0,0-1.1,3.06-4.25,2.78C112.68,209.2,109.56,205.04,109.56,205.04z"/>
			</g>
			<g>
				<path className="svgc3-0" d="M124.59,182.1c-0.27,2.12-0.92,5.35-2.39,5.97c-0.01,0-0.01,0-0.02,0c-0.2-0.07-5.19-1.77-8.33,3.58
					c-1.13,1.92-0.97,4.33-1.39,5.87c-0.71,2.59-1.49,3.78-1.49,3.78c-0.29-0.63-6.62-0.33-6.62-11.43c0-2.47,2.21-3.05,2.52-3.49
					c0.33-0.48,0.12-2.29,0.57-2.82c2.76-3.29,9.53-0.53,11.56-2.34C120.48,179.89,124.95,179.28,124.59,182.1z"/>
			</g>
			<g>
				<path className="lnc15" d="M114.61,192.07c0.06-0.18,0.02-0.37-0.11-0.51c-0.43-0.48-1.5-1.43-2.52-0.34
					c-1.12,1.19-0.12,3.14,1.02,3.83c0.29,0.17,0.66,0.02,0.76-0.3L114.61,192.07z"/>
			</g>
			<g>
				<path className="lnc15" d="M106.92,256.35c-1.24-4.41-2.9-13.08-2.58-18.25c0.5-8.01,4.21-14.8,4.21-14.8l-7.88-3.82
					c0,0-3.5,9.42-2.94,18.88c0.39,6.62,5,16.39,5.81,19.58c0.39,1.54-0.24,7.9,5.99,10.28c0.41,0.16,1.76-1.25,2.36-1.83
					c0.16-0.16,0.15-0.41-0.01-0.56c0,0-4.85-3.48-3.58-3.7c0.12-0.02,3.71,2.37,1.46-0.66
					C107.46,258.39,106.92,256.35,106.92,256.35z"/>
			</g>
			<g>
				<path className="svgc1-4" d="M99.74,220.77c0,0,4.06-14.11,8.96-11.97c4.9,2.13,0.3,14.8,0.3,14.8S102.55,222.83,99.74,220.77z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path className="svgc1-0" d="M142.18,80.12c-0.17,0-0.32-0.13-0.33-0.3c-0.02-0.18,0.12-0.34,0.3-0.36l15.22-1.31
						c0.18-0.02,0.34,0.12,0.36,0.3c0.02,0.18-0.12,0.34-0.3,0.36l-15.22,1.31C142.19,80.12,142.19,80.12,142.18,80.12z"/>
				</g>
				<g>
					<path className="svgc1-0" d="M159.93,75.44c-0.03,0-0.06,0-0.09-0.01l-14.76-3.97c-0.18-0.05-0.28-0.23-0.23-0.41
						c0.05-0.18,0.23-0.28,0.41-0.23l14.76,3.97c0.18,0.05,0.28,0.23,0.23,0.41C160.21,75.34,160.08,75.44,159.93,75.44z"/>
				</g>
				<g>
					<path className="svgc1-0" d="M145.89,88.46c-0.13,0-0.25-0.07-0.3-0.19c-0.08-0.17-0.01-0.36,0.16-0.44l13.86-6.44
						c0.17-0.08,0.36-0.01,0.44,0.16c0.08,0.17,0.01,0.36-0.16,0.44l-13.86,6.44C145.99,88.45,145.94,88.46,145.89,88.46z"/>
				</g>
				<g>
					<path className="svgc1-9 lnc0" d="M163.45,79.98c-0.92,1.88-3.18,2.66-5.06,1.74c-1.88-0.92-2.66-3.18-1.74-5.06
						c0.92-1.88,3.18-2.66,5.06-1.74C163.58,75.84,164.36,78.1,163.45,79.98z"/>
					<path className="svgc1-0" d="M160.04,82.44c-0.6,0-1.22-0.13-1.8-0.42c-0.99-0.48-1.73-1.32-2.09-2.36c-0.36-1.04-0.29-2.16,0.19-3.14
						c0.48-0.99,1.32-1.73,2.36-2.09c1.04-0.36,2.16-0.29,3.14,0.19c0.99,0.48,1.73,1.32,2.09,2.36c0.36,1.04,0.29,2.16-0.19,3.14v0
						C163.03,81.59,161.56,82.44,160.04,82.44z M160.05,74.87c-0.38,0-0.76,0.06-1.13,0.19c-0.87,0.3-1.57,0.92-1.98,1.75
						c-0.83,1.71-0.12,3.78,1.59,4.61c0.83,0.4,1.76,0.46,2.64,0.16c0.87-0.3,1.57-0.92,1.98-1.75v0c0.4-0.83,0.46-1.76,0.16-2.64
						c-0.3-0.87-0.92-1.57-1.75-1.98C161.08,74.99,160.56,74.87,160.05,74.87z"/>
				</g>
			</g>
		</g>
		<g id="speech_10_">
			<g>
				<g>
					<path className="svgc2-0" d="M313.2,169.88c-7.03,0.39-11.54,0.63-14,4c-3.23,4.41-1.51,12.18,3,16.4c4.87,4.55,11.52,3.57,14,3.2
						c1.64-0.24,12.56-1.86,14.2-9.6c1.06-5-2.25-10.21-6.2-12.6C321.16,169.44,318.26,169.6,313.2,169.88z"/>
				</g>
			</g>
			<g>
				<g>
					<circle className="lnc5" cx="307.54" cy="181.67" r="1.52"/>
				</g>
			</g>
			<g>
				<g>
					<circle className="lnc5" cx="313.49" cy="181.67" r="1.52"/>
				</g>
			</g>
			<g>
				<g>
					<path className="lnc5" d="M320.97,181.67c0,0.84-0.68,1.52-1.52,1.52c-0.84,0-1.52-0.68-1.52-1.52c0-0.84,0.68-1.52,1.52-1.52
						C320.29,180.15,320.97,180.83,320.97,181.67z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="svgc2-0" d="M305.86,190.92c0,0,1.15,4.56-7.21,4.36c0,0,2.22-3.57,0.82-9.09L305.86,190.92z"/>
				</g>
			</g>
		</g>
		<g>
			<g id="seat_4_">
				<g>
					<g>
						<path className="svgc3-2" d="M286.85,332.65c-0.66,0-1.19-0.53-1.19-1.19v-41.19c0-0.66,0.53-1.19,1.19-1.19
							c0.66,0,1.19,0.53,1.19,1.19v41.19C288.04,332.12,287.51,332.65,286.85,332.65z"/>
					</g>
				</g>
				<g>
					<g>
						<ellipse className="svgc3-4" cx="286.85" cy="288.59" rx="12.98" ry="1.68"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc3-3" d="M296.26,334.89h-18.83c-0.46,0-0.82-0.13-0.79-0.28c0.43-1.76,4.83-3.15,10.2-3.15
							c5.37,0,9.77,1.39,10.2,3.15C297.09,334.76,296.72,334.89,296.26,334.89z"/>
					</g>
				</g>
			</g>
			<g id="girl_11_">
				<g>
					<path className="lnc15" d="M276.63,221.93c0,0,1.71,12.18,5.27,12.76c3.56,0.58,11.05-5.12,11.05-5.12l-6.92-11.76L276.63,221.93z"
						/>
				</g>
				<g>
					<path className="lnc15" d="M291.28,255.69c0,0,0.42,10.31-1.66,12.15c-2.08,1.84-20.84,8.04-20.84,8.04l1.26-3.27
						c0,0,15.18-9.38,15.81-9.88c0.64-0.49-1.85-9.94-1.85-9.94L291.28,255.69z"/>
				</g>
				<g>
					<path className="svgc3-0" d="M295.08,230.94c0,0,4.17-1.27,5.85,1.34c1.68,2.61,2.58,7.89,5.7,8.91c3.12,1.02,4.96,6.7,3.47,10.46
						c-1.48,3.76-2.04,5.26-1.48,7.33c0.56,2.07-5.95,4.87-9.48,0.4c-3.53-4.47-9.81-18.62-7.83-23.67
						C293.29,230.65,295.08,230.94,295.08,230.94z"/>
				</g>
				<g>
					<path className="svgc1-0" d="M292.1,232.01L292.1,232.01c-0.3-0.54-0.1-1.22,0.45-1.52l1.45-0.79c0.54-0.3,1.22-0.1,1.52,0.45l0,0
						c0.3,0.54,0.1,1.22-0.45,1.52l-1.45,0.79C293.07,232.75,292.39,232.55,292.1,232.01z"/>
				</g>
				<g>
					<path className="lnc15" d="M291.08,229.41c0,0,3.96,7.49,5.18,8.12c0.16,0.08-0.91,4.49-4.7,4.85c-5.27,0.51-5.51-2.82-5.51-2.82
						s1.22-1.27,1.27-1.39c0.68-1.5-1-6.34-1-6.34L291.08,229.41z"/>
				</g>
				<g>
					<path className="svgc3-0" d="M252.32,329.28l0.16,4.17c0.02,0.52-0.31,0.96-0.75,0.98l-13.95,0.54l-0.02-0.53
						c-0.02-0.42,0.25-0.79,0.64-0.92c1.66-0.54,5.89-1.96,7.99-4.01C249.84,326.15,252.32,329.28,252.32,329.28z"/>
				</g>
				<g>
					<path className="svgc1-0" d="M302.59,251.62c0.96-10.6-1-13.22-6.33-14.09c0,0-3.81,4.47-7.04,4.12c-1.78-0.19-1.9-3.48-1.9-3.48
						s-0.79,0.75-2.91,2.82c-3.39,3.3-6.81,8.08-2.91,11.4c1.18,1-1.93,17.45-1.93,17.45l18.73,2.02
						C298.31,271.86,301.98,258.39,302.59,251.62z"/>
				</g>
				<g>
					<path className="svgc3-1" d="M279.58,269.84c0,0-20.56,6.3-28.22,9.87c-1.55,0.72-2.74,2.13-2.97,3.82
						c-1.22,8.85-1.99,45.98-1.99,45.98l5.92-0.23l4.17-34.68l37.5-23.21L279.58,269.84z"/>
				</g>
				<g>
					<path className="lnc15" d="M299.26,254.7c0,0-2.84,8.13-4.82,10.07c-1.99,1.94-20.46-4-20.46-4l0.69-3.23c0,0,14.79,2.91,15.41,2.38
						c0.61-0.52,4.21-8.96,4.21-8.96L299.26,254.7z"/>
				</g>
				<g>
					<path className="svgc1-2" d="M299.83,239.93c-2.72,0.48-4.8,5.16-7.14,13.24c-0.05,0.16,0.03,0.32,0.19,0.38l5.86,2.1
						c0.14,0.06,0.32,0,0.38-0.13c0.72-1.56,4.19-9.83,3.34-12.72C302.09,241.55,301.32,239.66,299.83,239.93z"/>
				</g>
				<g>
					<path className="svgc3-0" d="M278.22,215.03c2.15,0.52,2.31-3.49,9.14-2.76c4.74,0.51,6.25,2.69,8.59,8.37
						c3.24,7.84-2.45,11.28-4.46,10.59c-4.92-1.69-4.6-4.66-4.6-4.66s-1.54-3.13-1.86-3.04c-3.75,0.99-8-1.3-8-1.3
						s-0.19,2.81-1.81,2.52c-1.62-0.28-1.47-3.76-1.23-5.27C274.22,217.95,276.04,214.5,278.22,215.03z"/>
				</g>
				<g>
					<path className="lnc15" d="M274.86,260.65c2.31,0.43,2.61-2.36,0.02-2.99c-1.25-0.3-4.1-2.82-8.17,0.72
						c-0.27,0.23,0.22,1.74,0.41,2.38c0.05,0.17,0.24,0.27,0.41,0.2c0,0,2.67-1.78,3.87-0.95
						C273.61,261.54,274,260.49,274.86,260.65z"/>
				</g>
				<g>
					<path className="svgc3-0" d="M277.92,325.25l2.47,3.49c0.31,0.42,0.28,0.97-0.06,1.23l-11.21,8.32l-0.31-0.42
						c-0.25-0.34-0.25-0.79,0.01-1.13c1.07-1.38,3.75-4.95,4.33-7.82C274.08,324.21,277.92,325.25,277.92,325.25z"/>
				</g>
				<g>
					<path className="lnc15" d="M284.78,224.27c-0.05-0.16-0.02-0.34,0.1-0.47c0.39-0.44,1.37-1.32,2.31-0.33
						c1.03,1.09,0.12,2.88-0.92,3.52c-0.26,0.16-0.61,0.02-0.7-0.27L284.78,224.27z"/>
				</g>
				<g>
					<path className="svgc3-2" d="M297.99,271.82c0.19,0.02,0.35,0.16,0.4,0.34c0.57,2.17,3.54,15.01-6.17,15.67
						c-11.77,0.8-23.08,3.42-25.54,4.02c-0.27,0.06-0.41,0.34-0.32,0.6l11.45,32.47c0.07,0.19,0,0.41-0.16,0.53l-4.1,2.99
						c-0.23,0.16-0.54,0.09-0.68-0.15l-18.51-33.65c-0.01-0.02-0.02-0.04-0.03-0.07c-0.19-0.53-2.38-7.16,4.11-10.69
						c6.46-3.51,24.77-12.61,26.4-13.42c0.08-0.04,0.16-0.05,0.25-0.04L297.99,271.82z"/>
				</g>
			</g>
			<g id="table_4_">
				<g>
					<g>
						<g>
							<path className="svgc3-3" d="M257.43,336.95h-31.5c-0.66,0-1.19-0.52-1.19-1.16v-46.36c0-2.73,1.19-5.34,3.27-7.16l16.32-14.31
								c0.49-0.43,1.24-0.39,1.68,0.09c0.44,0.48,0.4,1.21-0.09,1.64l-16.32,14.31c-1.58,1.38-2.48,3.36-2.48,5.43v45.2h30.31
								c0.66,0,1.19,0.52,1.19,1.16C258.62,336.43,258.09,336.95,257.43,336.95z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="svgc3-3" d="M274.86,270.82h-48.93c-0.88,0-1.58-0.71-1.58-1.58c0-0.88,0.71-1.58,1.58-1.58h48.93
								c0.88,0,1.58,0.71,1.58,1.58C276.45,270.11,275.74,270.82,274.86,270.82z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path className="lnc1" d="M271.31,266.29h-5.18c-0.6,0-1.1-0.49-1.1-1.1v-3.2c0-0.6,0.49-1.1,1.1-1.1h5.18c0.6,0,1.1,0.49,1.1,1.1
							v3.2C272.41,265.8,271.91,266.29,271.31,266.29z"/>
					</g>
					<g>
						<path className="lnc14" d="M274.03,267.59h-10.62c-0.46,0-0.83-0.37-0.83-0.83v-0.93c0-0.46,0.37-0.83,0.83-0.83h10.62
							c0.46,0,0.83,0.37,0.83,0.83v0.93C274.86,267.21,274.49,267.59,274.03,267.59z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="svgc3-2" d="M252.23,267.59h-15.4c-0.44,0-0.79-0.35-0.79-0.79c0-0.44,0.35-0.79,0.79-0.79h15.4
							c0.44,0,0.79,0.35,0.79,0.79C253.02,267.23,252.67,267.59,252.23,267.59z"/>
					</g>
					<g>
						<path className="svgc3-2" d="M244.53,267.19c-0.44,0-0.79-0.35-0.79-0.79v-5.6c0-0.44,0.35-0.79,0.79-0.79s0.79,0.35,0.79,0.79v5.6
							C245.32,266.84,244.97,267.19,244.53,267.19z"/>
					</g>
					<g>
						<path className="svgc3-2" d="M257.91,260.9h-25.83c-1.14,0-2.11-0.81-2.29-1.92l-2.8-16.82c-0.23-1.39,0.86-2.66,2.29-2.66h25.83
							c1.14,0,2.11,0.81,2.29,1.92l2.8,16.82C260.44,259.64,259.34,260.9,257.91,260.9z"/>
					</g>
				</g>
				<g className="lnc8">
					<ellipse className="svgc3-0 lnc0" cx="265.27" cy="335.59" rx="46.76" ry="6.65"/>
				</g>
			</g>
		</g>
		<g>
			<path className="svgc1-2" d="M187.03,309.67l-1.16-0.41c-0.31-0.11-0.56-0.34-0.68-0.65c-0.13-0.31-0.12-0.64,0.02-0.94l0.53-1.12
				c0.4-0.85,0.24-1.83-0.43-2.5c-0.67-0.67-1.65-0.83-2.5-0.43l-1.12,0.53c-0.3,0.14-0.63,0.15-0.94,0.02
				c-0.31-0.13-0.54-0.37-0.65-0.68l-0.41-1.16c-0.32-0.89-1.13-1.46-2.07-1.46c-0.94,0-1.75,0.57-2.07,1.46l-0.41,1.16
				c-0.11,0.31-0.34,0.56-0.65,0.68c-0.31,0.13-0.64,0.12-0.94-0.02l-1.12-0.53c-0.85-0.4-1.83-0.24-2.5,0.43
				c-0.67,0.67-0.84,1.65-0.43,2.5l0.53,1.12c0.14,0.3,0.15,0.63,0.02,0.94c-0.13,0.31-0.37,0.54-0.68,0.65l-1.16,0.41
				c-0.89,0.32-1.46,1.13-1.46,2.07c0,0.94,0.57,1.75,1.46,2.07l1.16,0.41c0.31,0.11,0.56,0.34,0.68,0.65
				c0.13,0.31,0.12,0.64-0.02,0.94l-0.53,1.12c-0.4,0.85-0.24,1.83,0.43,2.5c0.67,0.67,1.65,0.83,2.5,0.43l1.12-0.53
				c0.3-0.14,0.63-0.15,0.94-0.02c0.31,0.13,0.54,0.37,0.65,0.68l0.41,1.16c0.32,0.89,1.13,1.46,2.07,1.46
				c0.94,0,1.75-0.57,2.07-1.46l0.41-1.16c0.11-0.31,0.34-0.56,0.65-0.68c0.31-0.13,0.64-0.12,0.94,0.02l1.12,0.53
				c0.85,0.4,1.83,0.24,2.5-0.43c0.67-0.67,0.84-1.65,0.43-2.5l-0.53-1.12c-0.14-0.3-0.15-0.63-0.02-0.94
				c0.13-0.31,0.37-0.54,0.68-0.65l1.16-0.41c0.89-0.32,1.46-1.13,1.46-2.07C188.49,310.79,187.91,309.98,187.03,309.67z
				 M181.78,311.74c0,2.29-1.87,4.16-4.16,4.16c-2.3,0-4.16-1.87-4.16-4.16c0-2.29,1.87-4.16,4.16-4.16
				C179.91,307.57,181.78,309.44,181.78,311.74z"/>
		</g>
	</g>
</g>
</svg>
)
}