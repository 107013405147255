import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Presentation as TPresentation } from 'src/types/api/presentation';

const initialState: TPresentation[] = [];

const slice = createSlice({
  name: 'presentations',
  initialState,
  reducers: {
    setPresentations(state: TPresentation[], action: PayloadAction<TPresentation[]>){
      return action.payload;
    },
    createPresentation(state: TPresentation[], action: PayloadAction<TPresentation>){
      state.push(action.payload);
    },
    updatePresentation(state: TPresentation[], action: PayloadAction<TPresentation>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deletePresentation(state: TPresentation[], action: PayloadAction<TPresentation>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setPresentations = (data: TPresentation[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPresentations(data));
}

export const createPresentation = (data: TPresentation): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createPresentation(data));
}

export const updatePresentation = (data: TPresentation): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePresentation(data));
}

export const deletePresentation = (data: TPresentation): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deletePresentation(data));
}

// export const { setContacts, createContact, updateContact, deleteContact } = slice.actions;

export default slice;