import { Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from 'src/i18n';
import { useSelector } from "src/store";
import { Task } from "src/types/api/project";
import { OriginalLabel } from 'src/components/general/Labels';

interface Props {
  selectedStepId: string | null;
  selectedTask: Task | null;
  disableAllFields: boolean;
  onOpenSelectStep: () => void;
}

const NewDialogSubtitle = ({
  selectedStepId,
  selectedTask,
  disableAllFields,
  onOpenSelectStep
}: Props) => {
  const {t} = useTranslation();

  const projects = useSelector(state => state.lists.projects.projects);
  const allSteps = useMemo(() => 
    projects.reduce((prev, current) => [...prev, ...current.steps], [])
  , [projects]);

  const selectedStep = useMemo(() => 
    allSteps.find(step => step.id === selectedStepId)
  , [allSteps, selectedStepId]);

  const handleLabelClick = () => {
    if(!disableAllFields){
      onOpenSelectStep();
    }
  }

  if(!selectedStep){
    return null;
  }

  return (
    <Typography variant='body2'>
      {selectedTask && (
        <>
          {t('lang.id').toUpperCase()}:
          <span style={{ marginLeft: 5, marginRight: 15, fontWeight: 'bold' }}>
            {selectedTask.identifier}
          </span>
        </>
      )}

      {t('lang.status')}:
      <span style={{ marginLeft: 5, marginRight: 5 }}>
        <OriginalLabel
          onClick={handleLabelClick}
          colorCode={selectedStep?.color ? selectedStep.color : '#000'}
          style={{ cursor: disableAllFields ? 'not-allowed' : 'pointer' }}
        >
          {selectedStep.title}
        </OriginalLabel>
      </span>
    </Typography>
  )

}
export default NewDialogSubtitle;