import CheckIcon from '@mui/icons-material/Check';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'src/i18n';
import { makeStyles } from 'src/theme/makeStyles';

const useStyles = makeStyles()(theme => ({
  movingItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    marginBottom: 5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.custom.backgroundDark
    }
  },
  movingItemStepColor: {
    borderRadius: '50%',
    width: 12,
    height: 12,
    marginRight: 10,
    marginLeft: 5
  }
}));

interface Option {
  id: string;
  name: string;
  color: string;
}

export interface SelectStatusLabelDialogProps {
  open: boolean;
  onClose: () => void;
  onChange: (statusId: string) => void;
  options: Option[];
  selectedOptionId: string | null;
}

const SelectStatusLabelDialog = ({
  open,
  onClose,
  onChange,
  options,
  selectedOptionId
}: SelectStatusLabelDialogProps) => {
  const {t} = useTranslation();
  const { classes } = useStyles();

  const handleChange = (statusId: string) => {
    onChange(statusId);
    onClose();
  }
  
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={() => {
        onClose && onClose();
      }}
      maxWidth='xs'
    >
      <div style={{ padding: 24 }}>
        <Typography
          variant='h3'
          style={{ marginBottom: 12 }}
        >
          {t('lang.select_status')}
        </Typography>
        <Typography
          variant='body2'
          style={{ marginBottom: 12 }}
        >
          {t('description.select_status')}
        </Typography>
        {options.map((option) => (
          <div
            key={option.id}
            className={classes.movingItem}
            onClick={() => handleChange(option.id)}
          >
            <div
              className={classes.movingItemStepColor}
              style={{ backgroundColor: option.color }}
            />
            <Typography>
              {option.name} 
            </Typography>
            {option.id === selectedOptionId &&
              <span style={{ marginLeft: 'auto', marginRight: 5 }}>
                <CheckIcon />
              </span>
            }
          </div>
        ))}
      </div>
    </Dialog>
  )
}

export default SelectStatusLabelDialog;