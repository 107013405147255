import { alpha, Box, Typography } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { useNavigate } from 'react-router';
import { useTimerContext } from 'src/contexts/TimerContext';
import { hhmmss } from 'src/utils/general/secondsToTime';
import useIsMobile from 'src/hooks/general/useIsMobile';
import { useActiveTimer } from 'src/hooks/general/useTimeTracker';

const useStyles = makeStyles()((theme) => ({
  timerRoot: {
    marginLeft: '6px',
    padding: '4px',
    color: theme.palette.text.primary,
    borderRadius: '6px',
    transition: '0.5s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.22),
    }
  },
  timerIcon: {
    color: theme.palette.primary.main,
    animationName: '$blinker',
    animationDuration: '1s',
    animationIterationCount:'infinite',
    animationDirection: 'alternate'
  },
  timerText: {
    color: theme.palette.primary.main,
  },
  '@keyframes blinker': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
}));

const RenderTimer = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const mobile = useIsMobile();
  const {values: timePassed} = useTimerContext();
  const {myActiveTimeTracker: activeTimeTracker} = useActiveTimer();

  if(!activeTimeTracker){ return null; }

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.timerRoot}
      onClick={() => {
        navigate('/time_registration');
      }}
    >
      <TimerOutlinedIcon className={classes.timerIcon} />
      {!mobile &&
      <div style={{marginLeft: '5px', maxWidth: '100px'}}>
        <Typography
          variant="h6"
          style={{
            lineHeight: '1'
          }}
          textOverflow='ellipsis'
          noWrap
        >
          {activeTimeTracker.contact ? activeTimeTracker.contact.name : activeTimeTracker.name}
        </Typography>
        <Typography
          className={classes.timerText}
          variant="body2"
          color="textSecondary"
          style={{
            lineHeight: '1',
            marginTop: 2
          }}
        >
          {activeTimeTracker && activeTimeTracker.id ? hhmmss(timePassed[activeTimeTracker.id]) : hhmmss(0)}
        </Typography>
      </div>
      }
    </Box>
  )
}

export default RenderTimer;