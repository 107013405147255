import { useTheme, lighten, darken } from '@mui/material';



export default function SvgComponent({ color1light, color2light, color3light, color1dark, color2dark, color3dark }) {
  const theme = useTheme();
  const changeColor = theme.name === 'LIGHT' ? lighten : darken;
  
  return (
    <svg id="users" data-name="Users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <style>
        {
            `
			.svgc1-0{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0)}}
			.svgc1-1{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.1)}}
			.svgc1-2{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.2)}}
			.svgc1-3{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.3)}}
			.svgc1-4{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.4)}}
			.svgc1-5{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.5)}}
			.svgc1-6{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.6)}}
			.svgc1-7{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.7)}}
			.svgc1-8{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.8)}}
			.svgc1-9{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9)}}
			.svgc1-10{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 1)}}

			.svgc2-0{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0)}}
			.svgc2-1{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.1)}}
			.svgc2-2{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.2)}}
			.svgc2-3{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.3)}}
			.svgc2-4{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.4)}}
			.svgc2-5{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.5)}}
			.svgc2-6{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.6)}}
			.svgc2-7{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.7)}}
			.svgc2-8{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.8)}}
			.svgc2-9{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.9)}}
			.svgc2-10{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 1)}}

			.svgc3-0{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0)}}
			.svgc3-1{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.1)}}
			.svgc3-2{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.2)}}
			.svgc3-3{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.3)}}
			.svgc3-4{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.4)}}
			.svgc3-5{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.5)}}
			.svgc3-6{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.6)}}
			.svgc3-7{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.7)}}
			.svgc3-8{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.8)}}
			.svgc3-9{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.9)}}
			.svgc3-10{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 1)}}
			
			.usr0{opacity:0.5;}
			.usr1{opacity:0.2;}
			.usr2{fill:#F8BC9E;}
			.usr3{fill:#F6AB86;}
			.usr4{fill:#F4BD9C;}

            `            
          }
         
        </style>
		<g id="background_2_">
	<g>
		<path className="svgc1-9" d="M178.78,282.42c45.15-3.9,57.77-39.59,80.54-45.24c21.73-5.4,13.39,35.65,31.33,18.53
			c22.37-21.34,37.07-2.92,55.95-21.95c19.08-19.23-25.91-76.41-15.52-102.96c12.09-30.89-14.06-80.36-43.47-85.15
			c-31.12-5.07-40.59,52.67-78.35,54.94c-48.48,2.92-61.52-35.71-98.2-26.01c-34.04,9-62.39,52.74-56.8,80.28
			c4.2,20.71,25.38,21.29,28.27,42.24c3.75,27.09-29.81,39.62-28.19,67.31c1.6,27.2,46.46,3.51,75.37,8.44
			C151.34,276.53,140.09,285.77,178.78,282.42z"/>
	</g>
</g>
<g>
	<path className="svgc1-0" d="M75.11,241.93l-0.01-1.41c1.2-0.01,2.43-0.1,3.65-0.27l0.19,1.39C77.66,241.82,76.37,241.92,75.11,241.93z
		 M71.28,241.72c-1.26-0.16-2.53-0.4-3.77-0.72l0.36-1.36c1.18,0.31,2.38,0.54,3.58,0.69L71.28,241.72z M82.69,240.9l-0.35-1.36
		c1.16-0.3,2.34-0.67,3.5-1.11l0.49,1.32C85.12,240.2,83.89,240.59,82.69,240.9z M63.88,239.79c-1.17-0.48-2.33-1.04-3.45-1.67
		l0.69-1.23c1.07,0.6,2.18,1.14,3.29,1.59L63.88,239.79z M57.21,236.06c-1.01-0.73-2.01-1.54-2.97-2.4l0.94-1.05
		c0.92,0.83,1.88,1.6,2.86,2.31L57.21,236.06z M51.53,230.97c-0.85-0.92-1.67-1.9-2.45-2.92l1.12-0.86
		c0.75,0.98,1.55,1.93,2.37,2.82L51.53,230.97z M46.89,224.95c-0.68-1.05-1.33-2.15-1.94-3.27l1.23-0.67
		c0.59,1.09,1.23,2.16,1.88,3.18L46.89,224.95z M43.25,218.28c-0.52-1.14-1-2.32-1.45-3.51l1.32-0.49c0.43,1.16,0.91,2.31,1.41,3.42
		L43.25,218.28z M40.6,211.17c-0.36-1.21-0.69-2.44-0.97-3.67l1.37-0.31c0.27,1.2,0.59,2.4,0.94,3.58L40.6,211.17z M38.91,203.78
		c-0.2-1.25-0.36-2.51-0.48-3.76l1.4-0.13c0.11,1.22,0.27,2.45,0.47,3.67L38.91,203.78z M38.2,196.23
		c-0.01-0.47-0.02-0.94-0.02-1.42c0-0.79,0.02-1.59,0.05-2.38l1.4,0.06c-0.03,0.77-0.05,1.55-0.05,2.32c0,0.46,0.01,0.92,0.02,1.38
		L38.2,196.23z M39.94,188.81l-1.4-0.16c0.15-1.26,0.34-2.53,0.58-3.76l1.38,0.27C40.27,186.35,40.08,187.58,39.94,188.81z
		 M41.35,181.56L40,181.18c0.34-1.23,0.74-2.45,1.17-3.62l1.32,0.49C42.07,179.19,41.68,180.37,41.35,181.56z M43.92,174.66
		l-1.27-0.6c0.55-1.15,1.15-2.28,1.79-3.36l1.21,0.72C45.03,172.46,44.45,173.55,43.92,174.66z M47.67,168.34l-1.14-0.83
		c0.75-1.02,1.55-2.02,2.39-2.97l1.05,0.93C49.17,166.39,48.39,167.35,47.67,168.34z M52.54,162.84l-0.96-1.03
		c0.92-0.86,1.9-1.69,2.91-2.46l0.86,1.11C54.38,161.21,53.43,162.01,52.54,162.84z M58.36,158.36l-0.75-1.19
		c1.06-0.67,2.17-1.31,3.3-1.89l0.65,1.25C60.46,157.09,59.39,157.71,58.36,158.36z M64.9,154.96l-0.54-1.3
		c1.15-0.48,2.35-0.93,3.55-1.34l0.45,1.33C67.18,154.05,66.01,154.49,64.9,154.96z M71.89,152.61l-0.35-1.36
		c1.2-0.31,2.44-0.59,3.7-0.83l0.27,1.38C74.28,152.04,73.06,152.31,71.89,152.61z M79.16,151.2l-0.19-1.39
		c1.22-0.16,2.49-0.3,3.76-0.4l0.11,1.4C81.6,150.91,80.36,151.04,79.16,151.2z M86.55,150.6l-0.05-1.4
		c0.93-0.03,1.9-0.05,2.87-0.05l0,1.41C88.42,150.55,87.47,150.57,86.55,150.6z"/>
</g>
<g>
	<g>
		
			<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -111.7243 81.657)" className="svgc2-0" cx="42.71" cy="175.69" rx="9.96" ry="9.96"/>
	</g>
	<g>
		<path className="svgc3-10" d="M43.13,180.04c-0.21,0-0.41-0.09-0.54-0.26l-4.18-5.11c-0.25-0.3-0.2-0.74,0.1-0.99
			c0.3-0.25,0.74-0.2,0.99,0.1l3.6,4.41l2.63-3.54c0.23-0.31,0.67-0.38,0.98-0.15c0.31,0.23,0.38,0.67,0.15,0.98l-3.17,4.26
			c-0.13,0.17-0.33,0.28-0.55,0.28C43.14,180.04,43.13,180.04,43.13,180.04z"/>
	</g>
</g>
<ellipse className="svgc3-9 usr0" cx="79.16" cy="313.57" rx="30.03" ry="3.52"/>
<g>
	<path className="svgc3-9 usr0" d="M325.63,294.6c0,4.2-57,6.99-127.31,6.99S71.01,298.8,71.01,294.6s57-7.61,127.31-7.61
		S325.63,290.4,325.63,294.6z"/>
</g>
<g>
	<g>
		<path className="svgc1-5" d="M371.69,326.9c0,0-72.67-0.48-93-36.27c-20.33-35.79-15.87-63.31-15.87-63.31s42.06-2.36,71.29,26.39
			c29.24,28.76,30.45,43.08,30.45,43.08l-10.88,1.77l16.44,11.85C370.12,310.41,373.36,316.58,371.69,326.9z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M262.11,227.39c-0.03-0.28,0.11-0.55,0.37-0.7c0.35-0.19,0.78-0.06,0.97,0.28
			c15.58,28.24,107.75,98.65,108.68,99.36c0.31,0.24,0.37,0.69,0.14,1c-0.24,0.31-0.69,0.37-1,0.14
			c-0.23-0.18-23.56-17.98-48.53-39.05c-33.56-28.33-53.93-48.78-60.53-60.76C262.14,227.58,262.12,227.49,262.11,227.39z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M302.2,270.33c-0.02-0.2,0.05-0.41,0.2-0.57c3.29-3.41,13.88-1.78,32.36,4.98
			c13.39,4.9,26.04,10.81,26.17,10.86c0.36,0.17,0.51,0.59,0.34,0.95c-0.17,0.36-0.59,0.51-0.95,0.34
			c-13.89-6.5-51.18-22.06-56.89-16.15c-0.27,0.28-0.73,0.29-1.01,0.02C302.28,270.66,302.21,270.5,302.2,270.33z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M286.61,278.91c-1.3-13.25,1.88-19.03,2.09-19.39c0.2-0.34,0.64-0.46,0.98-0.26c0.34,0.2,0.46,0.63,0.26,0.98
			l0,0c-0.08,0.13-7.48,13.78,5.65,47.62c0.14,0.37-0.04,0.78-0.41,0.93c-0.37,0.14-0.78-0.04-0.93-0.41
			C289.46,295.99,287.34,286.29,286.61,278.91z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M322.94,297.92c-0.3-3.07-0.19-6.24,0.58-9.35c0.09-0.38,0.48-0.62,0.87-0.52c0.38,0.09,0.62,0.48,0.52,0.87
			c-3.89,15.67,9.71,33.41,9.84,33.59c0.24,0.31,0.19,0.76-0.13,1c-0.31,0.24-0.76,0.19-1-0.13c-0.14-0.19-3.58-4.64-6.58-11.17
			C325.27,308.36,323.47,303.3,322.94,297.92z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M315.36,295.57c-0.51-5.19,0.41-9.2,2.77-11.99c0.25-0.3,0.71-0.34,1.01-0.09c0.3,0.25,0.34,0.71,0.09,1.01
			c-8.23,9.75,6.03,34.89,6.17,35.14c0.2,0.34,0.08,0.78-0.26,0.98c-0.34,0.2-0.78,0.08-0.98-0.26c-0.04-0.06-3.75-6.58-6.35-14.27
			C316.48,302.18,315.67,298.67,315.36,295.57z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M282.49,249.77c-0.02-0.19,0.04-0.39,0.18-0.55c4.08-4.51,12.33-6.46,24.51-5.8
			c8.97,0.49,16.89,2.29,16.97,2.31c0.38,0.09,0.62,0.47,0.54,0.86c-0.09,0.38-0.47,0.63-0.86,0.54c-0.31-0.07-31.02-6.98-40.1,3.06
			c-0.27,0.29-0.72,0.32-1.01,0.05C282.59,250.1,282.51,249.94,282.49,249.77z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M276,245.09c-0.02-0.22,0.06-0.46,0.25-0.61c15.93-13.69,30.01-9.51,30.61-9.32
			c0.38,0.12,0.59,0.52,0.47,0.89c-0.12,0.38-0.52,0.59-0.89,0.47c-0.14-0.04-13.95-4.11-29.25,9.04c-0.3,0.26-0.75,0.22-1.01-0.08
			C276.07,245.37,276.02,245.23,276,245.09z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc2-0" d="M115.41,143.11c-0.1,0.04-0.22,0.06-0.34,0.02c-6.43-1.72-15.17-11.11-25.98-27.9
			c-8.01-12.44-14.25-24.55-14.31-24.67c-0.13-0.25-0.03-0.57,0.22-0.7c0.26-0.13,0.56-0.03,0.7,0.22
			c0.25,0.48,24.85,48.08,39.64,52.04c0.28,0.07,0.44,0.36,0.36,0.63C115.66,142.93,115.55,143.05,115.41,143.11z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M75.61,90.85c-0.15,0.06-0.31,0.06-0.46-0.02c-10.52-5.82-7.52-18.23-7.49-18.36
			c0.03-0.14,0.12-0.26,0.25-0.32c0.13-0.07,0.27-0.08,0.41-0.04c10.94,3.44,7.62,18.24,7.59,18.39
			C75.87,90.65,75.76,90.78,75.61,90.85C75.61,90.85,75.61,90.85,75.61,90.85z M68.55,73.27c-0.44,2.34-1.55,11.38,6.49,16.29
			C75.51,86.75,76.68,76.28,68.55,73.27z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M86.5,110.28c-0.1,0.04-0.22,0.05-0.33,0.03c-0.15-0.04-0.28-0.14-0.35-0.28
			c-5.06-10.93,5.53-18.92,5.63-18.99c0.11-0.08,0.25-0.12,0.38-0.1c0.14,0.02,0.26,0.09,0.34,0.2c6.8,9.26-5.44,18.98-5.57,19.08
			C86.58,110.24,86.54,110.26,86.5,110.28z M91.66,92.19c-0.8,0.68-2.61,2.34-4.06,4.73c-2.4,3.95-2.77,8-1.11,12.05
			C88.63,107.09,96.47,99.46,91.66,92.19z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M86.5,110.28c-0.01,0-0.02,0.01-0.03,0.01c-11.28,4.15-18.72-6.23-18.79-6.33c-0.08-0.12-0.11-0.26-0.08-0.4
			c0.03-0.14,0.11-0.26,0.23-0.33c9.75-6.03,18.79,6.16,18.88,6.28c0.1,0.13,0.12,0.3,0.08,0.45
			C86.75,110.1,86.64,110.22,86.5,110.28z M68.87,103.81c1.48,1.87,7.6,8.61,16.57,5.74C83.63,107.36,76.47,99.62,68.87,103.81z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M101.05,131.2c-0.07,0.03-0.15,0.05-0.24,0.04c-0.16-0.01-0.31-0.09-0.4-0.22
			c-6.77-9.95,2.24-20.05,2.34-20.15c0.1-0.1,0.23-0.16,0.37-0.17c0.14,0,0.28,0.05,0.38,0.15c8.22,8.01-2.16,20.1-2.26,20.22
			C101.18,131.12,101.12,131.17,101.05,131.2z M103.16,111.99c-1.51,1.9-7.16,9.89-2.27,17.86
			C102.68,127.54,109.08,118.39,103.16,111.99z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M99.44,132c-9.76,4.23-18.33-3.34-18.42-3.43c-0.11-0.1-0.17-0.24-0.17-0.39c0-0.15,0.07-0.29,0.18-0.38
			c8.62-7.52,20.04,2.43,20.15,2.54c0.12,0.11,0.19,0.27,0.17,0.44c-0.02,0.17-0.11,0.31-0.26,0.39
			C100.54,131.49,99.99,131.76,99.44,132z M82.18,128.19c1.92,1.49,9.48,6.64,17.73,2.45C97.62,128.84,88.98,122.86,82.18,128.19z"
			/>
	</g>
	<g>
		<path className="svgc2-0" d="M121.06,137.75c0.09,0.03,0.19,0.04,0.28,0.01c5.37-1.38,12.61-8.56,21.5-21.34
			c6.59-9.48,11.7-18.68,11.75-18.77c0.11-0.19,0.02-0.43-0.19-0.52c-0.22-0.09-0.47-0.02-0.58,0.18
			c-0.2,0.36-20.34,36.54-32.71,39.71c-0.23,0.06-0.36,0.27-0.3,0.48C120.84,137.62,120.94,137.71,121.06,137.75z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M153.9,97.88c-0.12-0.05-0.22-0.15-0.25-0.26c-0.03-0.11-2.97-11.23,6.17-13.95
			c0.11-0.03,0.24-0.02,0.34,0.03c0.1,0.05,0.18,0.14,0.21,0.24c0.03,0.09,2.68,9.42-6.09,13.93
			C154.16,97.92,154.02,97.92,153.9,97.88C153.9,97.88,153.9,97.88,153.9,97.88z M154.37,96.9c6.69-3.8,5.66-10.6,5.27-12.36
			C152.85,86.91,153.94,94.79,154.37,96.9z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M144.87,112.61c-0.11-0.07-10.49-7.26-4.88-14.32c0.07-0.08,0.17-0.14,0.28-0.16
			c0.11-0.02,0.23,0.01,0.32,0.07c0.09,0.06,9.06,5.95,4.93,14.25c-0.05,0.11-0.16,0.19-0.29,0.22c-0.09,0.02-0.19,0.02-0.27-0.02
			C144.93,112.64,144.9,112.63,144.87,112.61z M144.96,111.66c1.35-3.07,0.99-6.12-1.06-9.07c-1.24-1.78-2.78-3.02-3.46-3.51
			C136.48,104.62,143.14,110.28,144.96,111.66z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M144.72,112.41c-0.04-0.12-0.02-0.24,0.06-0.34c0.07-0.09,7.52-9.39,15.78-4.96c0.1,0.05,0.17,0.15,0.2,0.25
			c0.03,0.1,0,0.21-0.07,0.3c-0.06,0.08-6.19,8-15.7,5c-0.01,0-0.02,0-0.02-0.01C144.85,112.61,144.76,112.52,144.72,112.41z
			 M145.85,112.1c7.56,2.05,12.62-3.1,13.84-4.53C153.27,104.5,147.35,110.42,145.85,112.1z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M132.83,128.5c-0.09-0.09-8.92-9.08-2.12-15.22c0.08-0.07,0.2-0.12,0.31-0.11c0.12,0,0.23,0.05,0.31,0.12
			c0.08,0.08,7.75,7.58,2.18,15.16c-0.07,0.1-0.2,0.17-0.33,0.17c-0.07,0-0.14-0.01-0.2-0.03
			C132.93,128.58,132.87,128.55,132.83,128.5z M133.1,127.58c4.02-6.06-0.81-12.02-2.1-13.44
			C126.11,119.04,131.58,125.87,133.1,127.58z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M132.94,128.58c-0.12-0.06-0.2-0.17-0.22-0.29c-0.02-0.12,0.04-0.25,0.14-0.33
			c0.09-0.08,9.57-7.72,16.89-2.15c0.09,0.07,0.15,0.18,0.15,0.29c0,0.11-0.05,0.22-0.14,0.29c-0.07,0.06-7.18,5.87-15.42,2.8
			C133.88,129.01,133.41,128.81,132.94,128.58z M133.93,128.16c6.97,3.06,13.26-0.91,14.86-2.06
			C143.02,122.17,135.84,126.79,133.93,128.16z"/>
	</g>
	<g>
		<g>
			<path className="svgc2-0" d="M302.95,241.61c-1.4-5.1-1.07-9.9,0.95-13.08c2.66-4.18,7.57-4.75,12.32-5.3c4.62-0.54,8.98-1.04,10.73-4.85
				c1.55-3.36,0.09-7.09-1.32-10.7c-1.54-3.95-2.99-7.67-0.46-10.67c1.97-2.33,4.86-2.41,7.93-2.5c3.22-0.09,6.55-0.19,9.17-2.92
				c1.42-1.48,1.94-3,2.45-4.47c0.68-1.96,1.32-3.82,4.02-4.68c3.12-0.99,7.37-0.16,10.83,2.13l0,0c5.58,3.68,7.7,10.24,6.12,13.82
				c-0.84,1.9-2.44,2.36-3.85,2.77c-1.22,0.35-2.38,0.68-3.1,1.9c-1.26,2.11,0.07,4.78,1.48,7.62c1.42,2.85,2.88,5.8,2.11,8.74
				c-0.89,3.39-3.99,3.99-7.27,4.64c-2.97,0.58-6.04,1.18-8.12,3.78c-2.72,3.4-1.49,7.56-0.19,11.98c1.28,4.33,2.6,8.8,0.46,13.08
				c-1.85,3.69-6.14,6.74-11.5,8.17c-7.33,1.95-15.09,0.7-21.31-3.44c-5-3.33-9.02-8.63-11.01-14.53
				C303.23,242.59,303.08,242.1,302.95,241.61z M328.24,211.49c0.68,2.46,0.9,4.99-0.2,7.39c-2.03,4.43-6.94,5-11.68,5.55
				c-4.47,0.52-9.09,1.06-11.44,4.75c-4.16,6.54-0.28,20.48,10.17,27.44c5.93,3.94,13.34,5.14,20.33,3.28
				c5.03-1.34,9.04-4.16,10.73-7.54c1.93-3.85,0.67-8.1-0.54-12.2c-1.33-4.51-2.71-9.18,0.4-13.07c2.36-2.95,5.8-3.62,8.83-4.21
				c3.15-0.62,5.64-1.1,6.34-3.76c0.66-2.5-0.71-5.24-2.02-7.9c-1.49-3-3.03-6.1-1.43-8.77c0.97-1.62,2.48-2.06,3.81-2.44
				c1.32-0.38,2.47-0.71,3.08-2.1c1.39-3.14-0.75-9.08-5.68-12.33l0.33-0.5l-0.33,0.5c-3.12-2.06-7.06-2.86-9.81-1.98
				c-2.12,0.68-2.59,2.04-3.25,3.93c-0.53,1.52-1.12,3.24-2.72,4.91c-2.96,3.09-6.7,3.19-10,3.29c-2.91,0.08-5.42,0.16-7.04,2.08
				c-2.07,2.45-0.8,5.69,0.66,9.45C327.28,208.6,327.84,210.03,328.24,211.49z"/>
		</g>
		<g>
			<path className="svgc2-0" d="M308.49,274.56c-0.01-0.03-0.01-0.05-0.02-0.08c-1-7.49,6.15-23.39,21.26-47.25
				c11.21-17.7,22.86-33.45,22.98-33.6c0.2-0.27,0.58-0.32,0.84-0.13c0.27,0.2,0.32,0.57,0.12,0.84
				c-0.46,0.63-46.33,62.71-44.01,79.98c0.04,0.33-0.19,0.63-0.52,0.68C308.84,275.04,308.56,274.85,308.49,274.56z"/>
		</g>
		<g>
			<path className="svgc2-0" d="M313,231.78c-0.08-0.31,0.08-0.63,0.39-0.73c0.31-0.11,0.66,0.06,0.76,0.38l4.83,14.22l18.91-3.09
				c0.33-0.05,0.63,0.17,0.69,0.5c0.05,0.33-0.17,0.64-0.5,0.69l-19.42,3.18c-0.29,0.05-0.57-0.12-0.67-0.4l-4.99-14.7
				C313.01,231.8,313.01,231.79,313,231.78z"/>
		</g>
		<g>
			<path className="svgc2-0" d="M333.06,201.28c-0.08-0.29,0.07-0.6,0.36-0.72c0.31-0.12,0.66,0.02,0.78,0.33l5.14,12.72l13.39,0.1
				c0.33,0,0.6,0.27,0.6,0.61c0,0.33-0.27,0.6-0.61,0.6l-13.79-0.1c-0.24,0-0.46-0.15-0.55-0.38l-5.29-13.09
				C333.07,201.32,333.06,201.3,333.06,201.28z"/>
		</g>
	</g>
</g>
<g>
	<g>
		<path className="svgc1-5" d="M101.66,80.37c-4.29,1.1-7.19,5.8-6.42,9.04c0.83,3.47,5.39,3.22,6.04,6.2c1.05,4.83-10.11,9.09-9.16,13.91
			c0.66,3.34,6.3,2.71,8.12,6.88c2.17,4.99-4.51,9.06-3.84,15.26c0.81,7.46,12.1,16.53,23.17,13.73c9-2.27,15.69-7.84,13.99-14.09
			c-1.87-6.89-13.07-10.57-14.16-17.09c-1.09-6.54,9.4-11.66,7.53-15.98c-1.61-3.73-9.86-0.95-13.66-6.39
			c-2.34-3.35-0.53-6.29-3.51-9.22C107.84,80.75,104.68,79.59,101.66,80.37z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M126.07,157.15c-0.22,0.09-0.47,0.03-0.62-0.17c-16.43-22.19-17.03-56.22-17.04-56.56
			c0-0.28,0.22-0.52,0.51-0.52c0.29,0,0.52,0.23,0.52,0.51c0,0.34,0.61,34.04,16.83,55.95c0.17,0.23,0.12,0.55-0.11,0.72
			C126.14,157.12,126.1,157.14,126.07,157.15z"/>
	</g>
</g>
<g id="slider_5_">
	<g id="blocks_19_">
		<g id="_x31__14_">
			<g>
				<path className="svgc3-10" d="M83.43,284.29c-1,0-1.82-0.82-1.82-1.82V141.19c0-1,0.82-1.82,1.82-1.82h79.95c1,0,1.82,0.82,1.82,1.82
					v141.29c0,1-0.82,1.82-1.82,1.82H83.43z"/>
			</g>
			<g>
				<path className="svgc3-9" d="M163.38,140.09c0.6,0,1.1,0.49,1.1,1.1v141.29c0,0.6-0.49,1.1-1.1,1.1H83.43c-0.6,0-1.1-0.49-1.1-1.1
					V141.19c0-0.6,0.49-1.1,1.1-1.1H163.38 M163.38,138.64H83.43c-1.4,0-2.54,1.14-2.54,2.54v141.29c0,1.4,1.14,2.54,2.54,2.54
					h79.95c1.4,0,2.54-1.14,2.54-2.54V141.19C165.92,139.78,164.78,138.64,163.38,138.64L163.38,138.64z"/>
			</g>
		</g>
		<g id="_x33__14_">
			<g>
				<path className="svgc3-10" d="M236.62,284.29c-1,0-1.82-0.82-1.82-1.82V141.19c0-1,0.82-1.82,1.82-1.82h79.95c1,0,1.82,0.82,1.82,1.82
					v141.29c0,1-0.82,1.82-1.82,1.82H236.62z"/>
			</g>
			<g>
				<path className="svgc3-9" d="M316.57,140.09c0.6,0,1.1,0.49,1.1,1.1v141.29c0,0.6-0.49,1.1-1.1,1.1h-79.95c-0.6,0-1.1-0.49-1.1-1.1
					V141.19c0-0.6,0.49-1.1,1.1-1.1H316.57 M316.57,138.64h-79.95c-1.4,0-2.54,1.14-2.54,2.54v141.29c0,1.4,1.14,2.54,2.54,2.54
					h79.95c1.4,0,2.54-1.14,2.54-2.54V141.19C319.11,139.78,317.97,138.64,316.57,138.64L316.57,138.64z"/>
			</g>
		</g>
		<g id="_x32__44_">
			<g>
				<path className="svgc3-10" d="M160.02,276.08c-1,0-1.82-0.82-1.82-1.82V132.97c0-1,0.82-1.82,1.82-1.82h79.95c1,0,1.82,0.82,1.82,1.82
					v141.29c0,1-0.82,1.82-1.82,1.82H160.02z"/>
			</g>
			<g>
				<path className="svgc3-9" d="M239.98,131.88c0.6,0,1.1,0.49,1.1,1.1v141.29c0,0.6-0.49,1.1-1.1,1.1h-79.95c-0.6,0-1.1-0.49-1.1-1.1
					V132.97c0-0.6,0.49-1.1,1.1-1.1H239.98 M239.98,130.43h-79.95c-1.4,0-2.54,1.14-2.54,2.54v141.29c0,1.4,1.14,2.54,2.54,2.54
					h79.95c1.4,0,2.54-1.14,2.54-2.54V132.97C242.52,131.57,241.38,130.43,239.98,130.43L239.98,130.43z"/>
			</g>
		</g>
	</g>
	<g id="_x33__13_">
		<g id="button_28_">
			<g>
				<path className="svgc2-0" d="M293.03,275.63h-31.08c-3.18,0-5.76-2.58-5.76-5.76v0c0-3.18,2.58-5.76,5.76-5.76h31.08
					c3.18,0,5.76,2.58,5.76,5.76v0C298.79,273.05,296.21,275.63,293.03,275.63z"/>
			</g>
		</g>
		<g>
			
				<ellipse transform="matrix(0.1774 -0.9841 0.9841 0.1774 44.3741 425.6929)" className="svgc3-0" cx="276.84" cy="186.3" rx="27.1" ry="27.1"/>
		</g>
	</g>
	<g id="_x32__43_">
		<g>
			<g>
				<g>
					<path className="svgc3-9" d="M230.28,241.11h-60.52c-1.25,0-2.26-1.01-2.26-2.26l0,0c0-1.25,1.01-2.26,2.26-2.26h60.52
						c1.25,0,2.26,1.01,2.26,2.26l0,0C232.54,240.1,231.53,241.11,230.28,241.11z"/>
				</g>
				<g>
					<path className="svgc1-0" d="M218.04,230.27H182c-1.78,0-3.22-1.44-3.22-3.22l0,0c0-1.78,1.44-3.22,3.22-3.22h36.04
						c1.78,0,3.22,1.44,3.22,3.22l0,0C221.26,228.83,219.82,230.27,218.04,230.27z"/>
				</g>
				<g>
					<path className="svgc3-9" d="M201.06,250.03h-31.3c-1.25,0-2.26-1.01-2.26-2.26l0,0c0-1.25,1.01-2.26,2.26-2.26h31.3
						c1.25,0,2.26,1.01,2.26,2.26l0,0C203.32,249.02,202.31,250.03,201.06,250.03z"/>
				</g>
			</g>
		</g>
		<g>
			<circle className="svgc1-0" cx="200" cy="177.27" r="27.1"/>
		</g>
	</g>
	<g id="_x31__13_">
		<g id="nav_11_">
			<g>
				<circle className="svgc1-0" cx="123.15" cy="274.21" r="1.37"/>
			</g>
			<g>
				<circle className="svgc2-0" cx="116.58" cy="274.21" r="1.37"/>
			</g>
			<g>
				<circle className="svgc1-0" cx="129.72" cy="274.21" r="1.37"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc3-9" d="M152.96,247.63H92.44c-1.25,0-2.26-1.01-2.26-2.26v0c0-1.25,1.01-2.26,2.26-2.26h60.52
					c1.25,0,2.26,1.01,2.26,2.26v0C155.21,246.62,154.2,247.63,152.96,247.63z"/>
			</g>
			<g>
				<path className="svgc1-0" d="M140.72,236.79h-36.04c-1.78,0-3.22-1.44-3.22-3.22l0,0c0-1.78,1.44-3.22,3.22-3.22h36.04
					c1.78,0,3.22,1.44,3.22,3.22l0,0C143.93,235.35,142.49,236.79,140.72,236.79z"/>
			</g>
			<g>
				<path className="svgc3-9" d="M123.74,256.55h-31.3c-1.25,0-2.26-1.01-2.26-2.26l0,0c0-1.25,1.01-2.26,2.26-2.26h31.3
					c1.25,0,2.26,1.01,2.26,2.26l0,0C125.99,255.54,124.98,256.55,123.74,256.55z"/>
			</g>
		</g>
		<g>
			
				<ellipse transform="matrix(0.1615 -0.9869 0.9869 0.1615 -80.3082 276.7321)" className="svgc3-0" cx="122.7" cy="185.63" rx="27.1" ry="27.1"/>
		</g>
	</g>
	<g>
		<g>
			<path className="svgc3-9" d="M307.6,248.02h-60.52c-1.25,0-2.26-1.01-2.26-2.26v0c0-1.25,1.01-2.26,2.26-2.26h60.52
				c1.25,0,2.26,1.01,2.26,2.26v0C309.86,247.01,308.84,248.02,307.6,248.02z"/>
		</g>
		<g>
			<path className="svgc1-0" d="M295.36,237.18h-36.04c-1.78,0-3.22-1.44-3.22-3.22l0,0c0-1.78,1.44-3.22,3.22-3.22h36.04
				c1.78,0,3.22,1.44,3.22,3.22l0,0C298.58,235.74,297.13,237.18,295.36,237.18z"/>
		</g>
	</g>
</g>
<g id="boy_6_">
	<g>
		<path className="usr3" d="M274.76,89.02c0,0-1.54,3.72-2.86,3.72c-1.32,0,2.5,3.31,4.88,3.31c2.38,0,4.98-2.76,5.2-3.31
			c0.22-0.55-3.4-0.54-3.44-3.07C278.49,87.15,274.76,89.02,274.76,89.02z"/>
	</g>
	<g>
		<path className="usr2" d="M271.27,80.82c0,0,1.06,10.48,5.39,10.7s7.37-12.29,7.37-12.53c0-0.23-8.8-2.58-8.8-2.58L271.27,80.82z"/>
	</g>
	<g>
		<path className="svgc3-2" d="M283.58,78.25c0,0-0.02,2.9-1.06,5.33c0,0,2.96-0.78,2.15-7.16L283.58,78.25z"/>
	</g>
	<g>
		<path className="svgc3-2" d="M271.53,75.69l4.65,2.55c0,0-1.54,1.91-4.91,2.57l0.26,2.3c0,0-1.95-2.01-1.8-3.62
			C269.88,77.88,271.53,75.69,271.53,75.69z"/>
	</g>
	<g>
		<rect x="258.26" y="126.84" className="svgc3-0" width="29.89" height="4.47"/>
	</g>
	<g>
		<path className="usr2" d="M272.36,82.68c-0.04-0.15-0.15-0.27-0.3-0.31c-0.51-0.15-1.69-0.34-1.92,0.88
			c-0.26,1.33,1.31,2.26,2.41,2.24c0.28-0.01,0.47-0.28,0.4-0.55L272.36,82.68z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M270.64,77.37c0,0,4.07,3.15,9.57,2.64c5.5-0.51,6.89-5.28,6.89-5.28s-5.38-0.9-8.31-3.44
			C276.18,69.01,269.36,74.36,270.64,77.37z"/>
	</g>
	<g>
		<path className="usr2" d="M281.7,82.68c0.04-0.15,0.15-0.27,0.3-0.31c0.51-0.15,1.69-0.34,1.92,0.88c0.26,1.33-1.31,2.26-2.41,2.24
			c-0.28-0.01-0.47-0.28-0.4-0.55L281.7,82.68z"/>
	</g>
	<g>
		<path className="svgc1-0" d="M272.3,92.74c0,0-5.75-0.7-7.21,0.79c-2.29,2.34-0.76,8.21-1.6,16.24c-1.44,13.74,1.59,17.28,1.78,17.56
			c0.57,0.85,21.83,0.61,22.19,0.42c0.73-0.37,2.75-9.46,1.39-18.26c-0.8-5.18,1.73-12.91-1.07-15.36c-1.6-1.41-6.32-2.11-7.14-1.4
			C278.52,94.58,276.55,96.44,272.3,92.74z"/>
	</g>
	<g>
		<path className="usr2" d="M266.79,105.57l-6.21-4.69c0,0-3.51,18.04-2.87,20.25c1.17,4,9.93,5.66,9.93,5.66l1.23-2.85
			c-0.59,0.59-4.81-3.53-5.12-4.37C263.38,118.54,266.79,105.57,266.79,105.57z"/>
	</g>
	<g>
		<path className="svgc1-0" d="M259.56,104.29c0,0,1.8-9.65,5.67-10.83c5.37-1.63,2.34,12.32,2.34,12.32S262.07,105.73,259.56,104.29z"/>
	</g>
	<g>
		<path className="usr2" d="M285.48,106.01l6.21-4.69c0,0,3.51,18.04,2.87,20.25c-1.17,4-10.37,4.78-10.37,4.78l-1.23-2.85
			c0.59,0.59,5.25-2.66,5.56-3.49C288.88,118.98,285.48,106.01,285.48,106.01z"/>
	</g>
	<g>
		<path className="svgc1-0" d="M292.71,104.73c0,0-1.73-10.12-5.67-10.83c-4.37-0.79-1.92,12.18-1.92,12.18S290.2,106.17,292.71,104.73z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M290.55,135.18c0,0,10.25,0.09,10.62,1.02s0.65,3.42-0.14,3.52s-10.09,1.29-11.81,0.64
			C287.51,139.7,290.55,135.18,290.55,135.18z"/>
	</g>
	<g>
		<path className="svgc3-1" d="M249.14,127.06c0.46-1.47,2.07-4.08,5.15-3.36c3.08,0.72,35.84,10.32,36.43,11.64
			c0.59,1.32,0.22,5.35-1.47,5.2c-1.69-0.15-38.31-7.23-39.7-9.14C248.36,129.77,248.83,128.04,249.14,127.06z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M262.64,135.18c0,0-10.17,0.37-10.51,1.31c-0.34,0.94-0.54,3.43,0.24,3.51c0.79,0.08,10.13,0.99,11.82,0.28
			C265.89,139.58,262.64,135.18,262.64,135.18z"/>
	</g>
	<g>
		<path className="svgc3-2" d="M304.05,127.06c-0.46-1.47-2.07-4.08-5.15-3.36c-3.08,0.72-35.84,10.32-36.43,11.64
			c-0.59,1.32-0.22,5.35,1.47,5.2c1.69-0.15,38.31-7.23,39.7-9.14C304.83,129.77,304.36,128.04,304.05,127.06z"/>
	</g>
	<g>
		<path className="svgc3-5" d="M286.79,128.23h-21.82c-0.92,0-1.67-0.75-1.67-1.67v-16.42c0-0.92,0.75-1.67,1.67-1.67h21.82
			c0.92,0,1.67,0.75,1.67,1.67v16.42C288.47,127.48,287.72,128.23,286.79,128.23z"/>
	</g>
</g>
<g id="man_2_">
	<path className="usr4" d="M79.16,209.08l23.62-17.78c0,0,3.46-4.84,5.17-3.94c0,0,2.56-2.21,2.97-2.06c0.41,0.15-1.7,2.18-1.7,2.18
		s2.28,1.81,1.26,2.98c-1.03,1.17-4.35,3.53-4.97,3.35c-0.62-0.18-23.44,21.63-23.44,21.63L79.16,209.08z"/>
	<path className="usr4" d="M72.93,191.57c0,0,6.7,4.92,3.76,12.15c-0.23,0.56-4.77,0.74-4.77,0.74s-0.6,3.03-0.16,4.36
		c0.17,0.5,1.71,1.87,1.57,2.35c-0.52,1.78-10.08,2.41-8.39-1.06c1.45-2.98,1.63-5.36,1.63-5.36S65.88,191.57,72.93,191.57z"/>
	<path className="svgc3-0" d="M58.84,307.86v4.02c0,0.5,0.34,0.91,0.75,0.91h13.45v-0.51c0-0.4-0.27-0.76-0.65-0.87
		c-1.62-0.46-5.86-1.78-7.95-3.68C60.99,304.64,58.84,307.86,58.84,307.86z"/>
	<path className="svgc3-0" d="M84.87,307.86v4.02c0,0.5,0.34,0.91,0.75,0.91h13.45v-0.51c0-0.4-0.27-0.76-0.65-0.87
		c-1.62-0.46-5.86-1.78-7.95-3.68C87.02,304.64,84.87,307.86,84.87,307.86z"/>
	<path className="svgc3-0" d="M73.09,184.4c0.7,0.99,4.21,5.6,1.22,8.45c0,0-0.89-1.17-2.3-0.85c-2.77,0.63-4.62,5.81-4.62,5.81
		c0.3,1.88,0.3,4.16-0.84,6.65c0,0-5.5-0.77-5.5-7.38c0-6.61,7.57-8.78,8.94-9.26c1.17-0.4,2.49-2.72,2.86-3.41
		C72.91,184.33,73.04,184.32,73.09,184.4z"/>
	<polygon className="svgc3-1" points="58.84,240.84 58.84,307.75 64.43,307.75 70.64,255.69 62.6,237.2 	"/>
	<path className="svgc3-2" d="M59.06,241.17c10.07,14.86,25.81,65.23,25.83,67l5.64-0.35c0,0-6.12-53.67-11-69.2
		C79.53,238.61,58.84,240.84,59.06,241.17z"/>
	<path className="svgc1-0" d="M73.41,211.73c0,0,3.64-2.15,5.76-3.03l3.2,6.76l-6.56,5.45L73.41,211.73z"/>
	<path className="svgc1-3" d="M65.56,208.67c0,0-5.18,4.82-6.72,15.62c-1.9,13.31-0.49,16.18,0.22,16.88c0.71,0.71,20.93,0.49,21.28,0.31
		c1.46-0.75-2.9-29.67-7.97-31.8C71.94,209.5,69.65,212.27,65.56,208.67z"/>
	<path className="usr4" d="M68.62,196.98c-0.02-0.15-0.12-0.27-0.26-0.32c-0.48-0.18-1.59-0.47-1.92,0.68
		c-0.36,1.26,1.06,2.28,2.12,2.35c0.27,0.02,0.48-0.23,0.43-0.49L68.62,196.98z"/>
	<path className="usr4" d="M72.11,224.28c0,0,2.61,21.22,5.94,29.46c0.61,1.51,2.95,5.38,1.44,6.6c0,0-2.83,1.76-3.51,0.35
		s-0.69-5.3-1.11-5.79c-4.46-5.18-9.76-30.45-9.76-30.45L72.11,224.28z"/>
	<path className="svgc1-6" d="M64.92,225.71c0,0-2.29-11.51,1.98-11.79c4.26-0.28,5.64,10.41,5.64,10.41S67.68,226.15,64.92,225.71z"/>
</g>
<g>
	<path className="svgc2-0" d="M323.29,131.5L323.29,131.5c-1.05,0-1.95-0.64-2.3-1.63l-0.43-1.2c-0.1-0.27-0.3-0.49-0.57-0.6
		c-0.27-0.11-0.56-0.1-0.82,0.02l-1.15,0.55c-0.95,0.45-2.04,0.26-2.78-0.48c-0.74-0.74-0.93-1.83-0.48-2.78l0.55-1.15
		c0.12-0.26,0.13-0.56,0.02-0.82c-0.11-0.27-0.32-0.47-0.6-0.57l-1.2-0.43c-0.99-0.35-1.63-1.26-1.63-2.3s0.64-1.95,1.63-2.3
		l1.2-0.43c0.27-0.1,0.49-0.3,0.6-0.57c0.11-0.27,0.1-0.56-0.02-0.82l-0.55-1.15c-0.45-0.95-0.26-2.04,0.48-2.78
		c0.74-0.74,1.83-0.93,2.78-0.48l1.15,0.55c0.26,0.12,0.56,0.13,0.82,0.02c0.27-0.11,0.47-0.32,0.57-0.6l0.43-1.2
		c0.35-0.99,1.26-1.63,2.3-1.63c1.05,0,1.95,0.64,2.31,1.63l0.43,1.2c0.1,0.27,0.3,0.49,0.57,0.6c0.27,0.11,0.56,0.1,0.82-0.02
		l1.15-0.55c0.95-0.45,2.04-0.26,2.78,0.48c0.74,0.74,0.93,1.83,0.48,2.78l-0.55,1.15c-0.12,0.26-0.13,0.56-0.02,0.82
		c0.11,0.27,0.32,0.47,0.6,0.57l1.2,0.43c0.99,0.35,1.63,1.26,1.63,2.3s-0.64,1.95-1.63,2.3l-1.2,0.43c-0.27,0.1-0.49,0.3-0.6,0.57
		c-0.11,0.27-0.1,0.56,0.02,0.82l0.55,1.15c0.45,0.95,0.26,2.04-0.48,2.78c-0.74,0.74-1.83,0.93-2.78,0.48l-1.15-0.55
		c-0.26-0.12-0.55-0.13-0.82-0.02c-0.27,0.11-0.47,0.32-0.57,0.6l-0.43,1.2C325.25,130.86,324.34,131.5,323.29,131.5z
		 M319.61,126.91c0.27,0,0.55,0.05,0.8,0.16c0.55,0.23,0.98,0.68,1.17,1.24l0.43,1.2c0.24,0.67,0.82,0.91,1.28,0.91
		c0.46,0,1.05-0.24,1.28-0.91l0.43-1.2c0.2-0.56,0.63-1.01,1.17-1.24c0.55-0.23,1.17-0.21,1.7,0.04l1.15,0.55
		c0.64,0.3,1.22,0.06,1.55-0.27c0.33-0.33,0.57-0.91,0.27-1.55l-0.55-1.15c-0.25-0.53-0.27-1.16-0.04-1.7
		c0.23-0.55,0.68-0.98,1.24-1.17l1.2-0.43c0.67-0.24,0.91-0.82,0.91-1.28s-0.24-1.05-0.91-1.28h0l-1.2-0.43
		c-0.56-0.2-1.01-0.63-1.24-1.17c-0.23-0.55-0.21-1.17,0.04-1.7l0.55-1.15c0.3-0.64,0.06-1.22-0.27-1.55
		c-0.33-0.33-0.91-0.57-1.55-0.27l-1.15,0.55c-0.54,0.25-1.16,0.27-1.7,0.04c-0.55-0.23-0.98-0.68-1.17-1.24l-0.43-1.2
		c-0.24-0.67-0.82-0.91-1.28-0.91c-0.46,0-1.05,0.24-1.28,0.91l-0.43,1.2c-0.2,0.56-0.63,1.01-1.17,1.24
		c-0.55,0.23-1.17,0.21-1.7-0.04l-1.15-0.55c-0.64-0.3-1.22-0.06-1.55,0.27c-0.33,0.33-0.57,0.91-0.27,1.55l0.55,1.15
		c0.25,0.53,0.27,1.16,0.04,1.7s-0.68,0.98-1.24,1.17l-1.2,0.43c-0.67,0.24-0.91,0.82-0.91,1.28s0.24,1.05,0.91,1.28l1.2,0.43
		c0.56,0.2,1.01,0.63,1.24,1.17c0.23,0.55,0.21,1.17-0.04,1.7l-0.55,1.15c-0.3,0.64-0.06,1.22,0.27,1.55
		c0.33,0.33,0.91,0.57,1.55,0.27l1.15-0.55C318.99,126.98,319.3,126.91,319.61,126.91z M323.29,125.3c-2.86,0-5.19-2.33-5.19-5.19
		s2.33-5.19,5.19-5.19c2.86,0,5.19,2.33,5.19,5.19S326.16,125.3,323.29,125.3z M323.29,116c-2.27,0-4.11,1.84-4.11,4.11
		s1.84,4.11,4.11,4.11c2.27,0,4.11-1.84,4.11-4.11S325.56,116,323.29,116z"/>
</g>
<g>
	<path className="svgc1-0" d="M332.96,107.72c-0.85,0-1.58-0.52-1.86-1.31l-0.32-0.9c-0.06-0.17-0.19-0.3-0.35-0.37
		c-0.17-0.07-0.35-0.06-0.51,0.01l-0.87,0.41c-0.77,0.36-1.65,0.21-2.25-0.39c-0.6-0.6-0.75-1.48-0.39-2.25l0.41-0.87
		c0.08-0.16,0.08-0.35,0.01-0.51c-0.07-0.17-0.2-0.29-0.37-0.35l-0.9-0.32c-0.8-0.28-1.31-1.02-1.31-1.86
		c0-0.85,0.52-1.58,1.31-1.86l0.9-0.32c0.17-0.06,0.3-0.19,0.37-0.35c0.07-0.17,0.06-0.35-0.01-0.51l-0.41-0.87
		c-0.36-0.77-0.21-1.65,0.39-2.25c0.6-0.6,1.48-0.75,2.25-0.39l0.87,0.41c0.16,0.08,0.34,0.08,0.51,0.01
		c0.17-0.07,0.29-0.2,0.35-0.37l0.32-0.9c0.28-0.8,1.02-1.31,1.86-1.31s1.58,0.52,1.86,1.31l0.32,0.9c0.06,0.17,0.19,0.3,0.35,0.37
		c0.17,0.07,0.35,0.06,0.51-0.01l0.87-0.41c0.77-0.36,1.65-0.21,2.25,0.39c0.6,0.6,0.75,1.48,0.39,2.25l-0.41,0.87
		c-0.08,0.16-0.08,0.35-0.01,0.51c0.07,0.17,0.2,0.29,0.37,0.35l0.9,0.32c0.8,0.28,1.31,1.02,1.31,1.86c0,0.85-0.52,1.58-1.32,1.86
		l-0.9,0.32c-0.17,0.06-0.3,0.19-0.37,0.35c-0.07,0.17-0.06,0.35,0.01,0.51l0.41,0.87c0.36,0.77,0.21,1.65-0.39,2.25
		c-0.6,0.6-1.48,0.75-2.25,0.39l-0.87-0.41c-0.16-0.08-0.34-0.08-0.51-0.01c-0.17,0.07-0.29,0.2-0.35,0.37l-0.32,0.9
		C334.54,107.2,333.81,107.72,332.96,107.72z M330.18,103.99c0.22,0,0.45,0.04,0.66,0.13c0.45,0.19,0.8,0.55,0.96,1.01l0.32,0.9
		c0.16,0.44,0.54,0.59,0.84,0.59c0.3,0,0.69-0.16,0.84-0.59l0.32-0.9c0.16-0.46,0.51-0.82,0.96-1.01c0.45-0.19,0.95-0.17,1.39,0.04
		l0.87,0.41c0.42,0.2,0.8,0.04,1.02-0.18c0.22-0.22,0.37-0.6,0.18-1.02l-0.41-0.87c-0.21-0.44-0.22-0.94-0.04-1.39
		c0.19-0.45,0.55-0.8,1.01-0.96l0.9-0.32c0.44-0.16,0.59-0.54,0.59-0.84c0-0.3-0.16-0.69-0.59-0.84h0l-0.9-0.32
		c-0.46-0.16-0.82-0.51-1.01-0.96c-0.19-0.45-0.17-0.95,0.04-1.39l0.41-0.87c0.2-0.42,0.04-0.8-0.18-1.02
		c-0.22-0.22-0.6-0.37-1.02-0.17l-0.87,0.41c-0.44,0.21-0.95,0.22-1.39,0.04c-0.45-0.19-0.8-0.55-0.96-1.01l-0.32-0.9
		c-0.16-0.44-0.54-0.59-0.84-0.59c-0.3,0-0.69,0.16-0.84,0.59l-0.32,0.9c-0.16,0.46-0.51,0.82-0.96,1.01
		c-0.45,0.19-0.96,0.17-1.39-0.04l-0.87-0.41c-0.42-0.2-0.8-0.04-1.02,0.17c-0.22,0.22-0.37,0.6-0.18,1.02l0.41,0.87
		c0.21,0.44,0.22,0.94,0.04,1.39c-0.19,0.45-0.55,0.8-1.01,0.96l-0.9,0.32c-0.44,0.16-0.59,0.54-0.59,0.84
		c0,0.3,0.16,0.69,0.59,0.84l0.9,0.32c0.46,0.16,0.82,0.51,1.01,0.96c0.19,0.45,0.17,0.95-0.04,1.39l-0.41,0.87
		c-0.2,0.42-0.04,0.8,0.18,1.02c0.22,0.22,0.6,0.37,1.02,0.18l0.87-0.41C329.68,104.05,329.93,103.99,330.18,103.99z M332.96,103.05
		c-2.23,0-4.05-1.82-4.05-4.05c0-2.23,1.82-4.05,4.05-4.05c2.23,0,4.05,1.82,4.05,4.05C337.01,101.23,335.2,103.05,332.96,103.05z
		 M332.96,96.03c-1.63,0-2.97,1.33-2.97,2.97c0,1.63,1.33,2.97,2.97,2.97c1.64,0,2.97-1.33,2.97-2.97
		C335.93,97.36,334.6,96.03,332.96,96.03z"/>
</g>
<g>
	
		<rect x="199.49" y="112.38" transform="matrix(0.1469 0.9892 -0.9892 0.1469 286.2041 -101.8106)" className="svgc2-0" width="5.26" height="5.26"/>
</g>
<g>
	
		<rect x="240.6" y="48.19" transform="matrix(0.7898 0.6133 -0.6133 0.7898 82.2045 -138.4597)" className="svgc1-0" width="5.06" height="5.06"/>
</g>
<g>
	<path className="svgc3-9 usr0" d="M206.02,306.86c0,2.04-4.95,3.69-11.06,3.69s-11.06-1.65-11.06-3.69c0-2.04,4.95-3.69,11.06-3.69
		S206.02,304.83,206.02,306.86z"/>
</g>
<g id="frower_pot_2_">
	<g>
		<g>
			<path className="svgc1-2" d="M216.69,256.8c2.39,1.48,4.61,4.71,3.83,7.24c-0.99,3.24-6.22,3.25-6.46,5.76
				c-0.22,2.35,4.28,3.33,4.18,5.55c-0.13,2.83-7.7,6.55-13.81,4.28c-4.63-1.72-8.09-6.75-7-9.06c0.98-2.07,5.08-0.8,6.57-3.38
				c1.33-2.31-1.33-4.45-0.33-7.13C205,256.52,212.04,253.9,216.69,256.8z"/>
		</g>
		<g>
			<path className="svgc1-6" d="M186.02,278.12c-6.05,2.19-13.3-1.37-13.26-3.46c0.04-2.05,7.08-2.97,6.92-4.61
				c-0.18-1.89-9.56-1.85-9.8-4.04c-0.16-1.45,3.93-1.87,5.19-5.19c1.19-3.12-1.25-5.79,0-6.92c1.15-1.03,3.2,1.19,7.5,1.15
				c3.37-0.03,4.98-1.42,5.77-0.58c1.24,1.34-2.52,5.18-1.15,7.5c1.27,2.16,5.41,0.33,6.92,2.31
				C196.14,266.95,192.7,275.71,186.02,278.12z"/>
		</g>
		<g>
			<path className="svgc1-0" d="M188.33,288.3c-0.02,0-0.04,0-0.06,0c-0.2-0.03-0.34-0.22-0.31-0.43c1.12-7.31-5.67-23.28-5.74-23.44
				c-0.08-0.19,0.01-0.41,0.2-0.49c0.19-0.08,0.41,0.01,0.49,0.2c0.28,0.66,6.95,16.33,5.79,23.85
				C188.67,288.17,188.51,288.3,188.33,288.3z"/>
		</g>
		<g>
			<path className="svgc1-0" d="M196.39,293.71c-0.2,0-0.38-0.15-0.4-0.36c-0.9-7.32,13.85-27.14,14.48-27.98
				c0.13-0.18,0.39-0.22,0.57-0.08c0.18,0.13,0.22,0.39,0.08,0.57c-0.15,0.2-15.19,20.4-14.33,27.4c0.03,0.22-0.13,0.43-0.35,0.45
				C196.42,293.7,196.4,293.71,196.39,293.71z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M194.32,285.81c-4.08-7.57,5.34-34.37,5.74-35.51l0.77,0.27c-0.1,0.28-9.7,27.6-5.79,34.85L194.32,285.81z"
				/>
		</g>
		<g>
			<path className="svgc3-0" d="M206.21,241.79c0,0-6.34,2.31-5.77,8.65C200.44,250.44,207.94,247.55,206.21,241.79"/>
		</g>
		<g>
			<path className="svgc3-0" d="M206.14,257.36c0,0-6.7-0.83-9.07,5.08C197.07,262.44,205.06,263.29,206.14,257.36"/>
		</g>
		<g>
			<path className="svgc3-0" d="M191.98,252.44c0,0-1.37,6.61,4.33,9.45C196.32,261.89,197.8,253.99,191.98,252.44"/>
		</g>
		<g>
			<path className="svgc3-0" d="M202.57,269.46c0,0-6.74,0.27-8.12,6.49C194.45,275.95,202.47,275.48,202.57,269.46"/>
		</g>
		<g>
			<path className="svgc3-0" d="M187.8,266.92c0,0-0.27,6.75,5.82,8.62C193.62,275.53,193.79,267.5,187.8,266.92"/>
		</g>
	</g>
	<g>
		<path className="svgc2-0" d="M201.21,307.53h-12.49c-0.56,0-1.04-0.4-1.13-0.95l-3.29-18.08h21.34l-3.29,18.08
			C202.24,307.13,201.77,307.53,201.21,307.53z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M205.34,289.08h-20.19c-1.74,0-3.17-1.43-3.17-3.17l0,0c0-1.74,1.43-3.17,3.17-3.17h20.19
			c1.74,0,3.17,1.43,3.17,3.17l0,0C208.52,287.65,207.09,289.08,205.34,289.08z"/>
	</g>
	<g className="usr1">
		<polygon className="svgc3-0" points="184.43,289.23 204.76,293.3 205.53,289.08 184.4,289.08 		"/>
	</g>
</g>
<g id="user_00000133509610097790509760000009298211786297147288_">
	<g>
		<g id="_x32__00000097478672379806256130000012264129439890907315_">
			<g>
				<path className="svgc3-0" d="M200.68,205.39c-1.3,0-2.61-0.09-3.87-0.27c-5.44-0.76-10.5-3.1-14.63-6.77
					c-5.93-5.28-9.33-12.86-9.33-20.79c0-15.34,12.48-27.83,27.83-27.83c15.34,0,27.83,12.48,27.83,27.83
					c0,7.93-3.4,15.51-9.32,20.79c-4.11,3.66-9.14,5.99-14.55,6.76C203.34,205.29,202.01,205.39,200.68,205.39z M200.68,150.96
					c-14.66,0-26.59,11.93-26.59,26.59c0,7.58,3.25,14.82,8.91,19.87c3.94,3.5,8.77,5.74,13.98,6.47c2.44,0.34,5.01,0.34,7.49-0.01
					c5.17-0.73,9.98-2.97,13.9-6.46c5.66-5.04,8.91-12.28,8.91-19.86C227.28,162.89,215.35,150.96,200.68,150.96z"/>
			</g>
			<g>
				<g>
					<path className="svgc3-0" d="M218.78,197.88c-3.9,3.47-8.8,5.84-14.22,6.61l-7.66,0.01c-5.45-0.76-10.38-3.13-14.3-6.62
						c1.74-6.65,6.06-11.98,11.58-14.35c1.8,1.67,4.03,2.64,6.47,2.64c2.45,0,4.69-0.99,6.49-2.66
						C212.69,185.88,217.03,191.21,218.78,197.88z"/>
				</g>
				<g>
					<g>
						<path className="svgc3-0" d="M208.65,174.85c0.01,3.53-2.05,6.55-4.98,7.8c-0.93,0.4-1.94,0.62-3.01,0.62h-0.01
							c-1.07,0-2.08-0.22-3.01-0.62c-2.94-1.24-5.01-4.26-5.01-7.79c-0.01-4.65,3.57-8.42,8-8.43h0.01
							C205.05,166.44,208.64,170.21,208.65,174.85z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
</svg>

)
}
