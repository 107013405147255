import { useTheme, lighten, darken } from '@mui/material';

export default function SvgComponent({ color1light, color2light, color3light, color1dark, color2dark, color3dark }) {
  const theme = useTheme();
  const changeColor = theme.name === 'LIGHT' ? lighten : darken;

  return (
    <svg id="working_hours" data-name="Working hours" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <style>
        {
            `
			.svgc1-0{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0)}}
			.svgc1-1{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.1)}}
			.svgc1-2{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.2)}}
			.svgc1-3{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.3)}}
			.svgc1-4{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.4)}}
			.svgc1-5{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.5)}}
			.svgc1-6{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.6)}}
			.svgc1-7{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.7)}}
			.svgc1-8{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.8)}}
			.svgc1-9{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9)}}
			.svgc1-10{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 1)}}

			.svgc2-0{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0)}}
			.svgc2-1{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.1)}}
			.svgc2-2{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.2)}}
			.svgc2-3{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.3)}}
			.svgc2-4{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.4)}}
			.svgc2-5{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.5)}}
			.svgc2-6{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.6)}}
			.svgc2-7{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.7)}}
			.svgc2-8{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.8)}}
			.svgc2-9{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.9)}}
			.svgc2-10{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 1)}}

			.svgc3-0{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0)}}
			.svgc3-1{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.1)}}
			.svgc3-2{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.2)}}
			.svgc3-3{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.3)}}
			.svgc3-4{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.4)}}
			.svgc3-5{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.5)}}
			.svgc3-6{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.6)}}
			.svgc3-7{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.7)}}
			.svgc3-8{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.8)}}
			.svgc3-9{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.9)}}
			.svgc3-10{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 1)}}
			
            .wh0{opacity:0.5;}
            .wh1{opacity:0.1;}
            .wh2{opacity:0.2;}
            .wh3{fill:#F8BC9E;}
            .wh4{fill:#F3A078;}
			.wh5{fill:#ffffff;}
 
            `            
          }
        </style>
        <g>
	<g id="background_8_">
		<g>
			<path className="svgc1-9" d="M212.01,85.54c-48.66,3.86-44.3-27.86-68.8-22.51c-23.39,5.11-19.34,38.35-38.57,54.26
				c-23.98,19.84-39.94,2.9-60.18,20.59c-20.45,17.87-14.76,59.5-4.05,84.19c12.8,29.49,41.12,54.02,72.85,58.28
				c33.58,4.51,39.44-17.92,80.14-20.24c52.25-2.97,66.56,32.64,106.04,23.47c36.64-8.51,66.92-49.07,60.71-74.47
				c-4.67-19.11-27.5-19.53-30.76-38.85c-4.22-25,31.88-36.76,29.95-62.33c-1.9-25.12-50.71-52.3-81.91-56.69
				C254.1,47.96,253.7,82.23,212.01,85.54z"/>
		</g>
		<g>
			<path className="svgc1-7" d="M74.11,248.34c-0.94-0.5-1.88-1.03-2.79-1.59l0.42-0.68c0.9,0.55,1.82,1.08,2.75,1.57L74.11,248.34z
				 M68.63,244.99c-0.87-0.61-1.74-1.25-2.58-1.92l0.49-0.63c0.83,0.65,1.68,1.29,2.54,1.89L68.63,244.99z M63.59,241
				c-0.79-0.71-1.58-1.45-2.34-2.2l0.56-0.57c0.75,0.74,1.52,1.47,2.31,2.17L63.59,241z M59.04,236.48
				c-0.71-0.79-1.41-1.61-2.08-2.45l0.62-0.5c0.66,0.82,1.35,1.63,2.05,2.41L59.04,236.48z M55.01,231.48
				c-0.62-0.86-1.23-1.76-1.81-2.66l0.67-0.43c0.57,0.89,1.17,1.77,1.78,2.62L55.01,231.48z M51.55,226.07
				c-0.53-0.93-1.03-1.88-1.51-2.84l0.71-0.36c0.47,0.94,0.97,1.88,1.49,2.8L51.55,226.07z M48.69,220.32
				c-0.42-0.98-0.82-1.99-1.18-2.99l0.75-0.27c0.36,0.98,0.75,1.97,1.17,2.94L48.69,220.32z M46.5,214.28
				c-0.31-1.03-0.58-2.08-0.82-3.11l0.78-0.18c0.24,1.01,0.51,2.04,0.81,3.06L46.5,214.28z M45.04,208.03
				c-0.18-1.06-0.32-2.14-0.42-3.19l0.79-0.08c0.1,1.03,0.24,2.09,0.41,3.13L45.04,208.03z M44.43,201.63
				c-0.01-0.34-0.01-0.67-0.01-1.01c0-0.74,0.02-1.49,0.06-2.21l0.8,0.04c-0.04,0.71-0.06,1.44-0.06,2.17c0,0.33,0,0.66,0.01,0.99
				L44.43,201.63z M45.57,195.31l-0.79-0.11c0.15-1.07,0.34-2.14,0.58-3.17l0.78,0.18C45.91,193.22,45.72,194.26,45.57,195.31z
				 M46.98,189.17l-0.76-0.25c0.34-1.02,0.73-2.04,1.16-3.01l0.73,0.32C47.69,187.18,47.31,188.17,46.98,189.17z M49.53,183.43
				l-0.69-0.4c0.53-0.93,1.12-1.85,1.74-2.71l0.65,0.47C50.62,181.63,50.04,182.52,49.53,183.43z M53.19,178.33l-0.59-0.53
				c0.71-0.8,1.47-1.57,2.27-2.29l0.54,0.59C54.63,176.81,53.88,177.56,53.19,178.33z M57.83,174.1l-0.48-0.64
				c0.85-0.63,1.75-1.24,2.67-1.81l0.42,0.68C59.53,172.88,58.66,173.48,57.83,174.1z M63.19,170.8l-0.36-0.71
				c0.94-0.47,1.93-0.92,2.93-1.32l0.3,0.74C65.08,169.91,64.11,170.34,63.19,170.8z M69.03,168.43l-0.25-0.76
				c1-0.32,2.04-0.62,3.09-0.89l0.2,0.77C71.03,167.81,70.01,168.11,69.03,168.43z M75.15,166.86L75,166.07
				c1.03-0.2,2.09-0.38,3.16-0.53l0.11,0.79C77.22,166.48,76.17,166.66,75.15,166.86z M96.41,165.98c-0.78-0.06-1.55-0.12-2.31-0.16
				l0.05-0.8c0.77,0.04,1.54,0.1,2.33,0.16L96.41,165.98z M81.42,165.97l-0.07-0.79c1.04-0.1,2.12-0.17,3.2-0.22l0.04,0.8
				C83.52,165.8,82.45,165.88,81.42,165.97z M90.93,165.69c-1.07-0.03-2.14-0.04-3.17-0.03l-0.01-0.8c1.04-0.01,2.12,0,3.2,0.03
				L90.93,165.69z"/>
		</g>
		<g>
			<g>
				<path className="svgc2-0" d="M261.99,114.64c-3.11,0-6.36-0.92-9.01-2.63c-2.46-1.59-4.06-3.62-4.49-5.71c-0.6-2.91,1.15-5.29,2.85-7.59
					c1.61-2.19,3.14-4.25,2.42-6.53c-0.76-2.42-3.59-3.59-6.08-4.62c-2.44-1.01-4.74-1.97-4.86-3.97c-0.1-1.67,1.42-2.78,3.03-3.95
					c1.82-1.32,3.89-2.82,4.26-5.44c0.19-1.37-0.15-2.36-0.52-3.41c-0.43-1.25-0.88-2.54-0.27-4.35c0.79-2.35,2.81-4.2,5.15-4.71
					l0,0c3.75-0.83,7.53,1.7,8.16,4.34c0.38,1.58-0.42,2.76-1.12,3.8c-0.62,0.92-1.15,1.72-0.91,2.66c0.43,1.66,2.96,2.33,5.4,2.98
					c2.63,0.7,5.35,1.42,5.93,3.49c0.41,1.45-0.49,2.56-1.43,3.74c-0.93,1.16-1.89,2.36-1.79,4.03c0.12,2.03,1.72,3.13,3.57,4.4
					c1.7,1.17,3.63,2.49,4.5,4.79c1.03,2.73,0.48,6.64-1.44,10.2c-2.28,4.23-5.99,7.19-10.18,8.14
					C264.14,114.52,263.08,114.64,261.99,114.64z M254.64,62.49c-2.07,0.46-3.86,2.1-4.57,4.19c-0.52,1.55-0.15,2.61,0.27,3.83
					c0.38,1.1,0.78,2.23,0.55,3.79c-0.42,2.95-2.73,4.63-4.58,5.98c-1.49,1.08-2.78,2.02-2.7,3.25c0.09,1.51,2.08,2.33,4.37,3.28
					c2.66,1.1,5.67,2.35,6.54,5.12c0.84,2.67-0.88,4.99-2.54,7.25c-1.59,2.16-3.24,4.4-2.71,6.96c0.38,1.86,1.89,3.75,4.14,5.2
					c3.39,2.19,7.83,3.02,11.57,2.18c3.97-0.89,7.49-3.72,9.66-7.74c1.81-3.36,2.34-7.02,1.39-9.54c-0.78-2.06-2.52-3.26-4.2-4.41
					c-1.94-1.33-3.77-2.59-3.92-5.01c-0.12-1.98,0.99-3.36,1.97-4.57c0.85-1.06,1.58-1.98,1.29-3.03c-0.46-1.62-2.96-2.29-5.37-2.93
					c-2.66-0.71-5.41-1.44-5.96-3.55c-0.34-1.29,0.35-2.32,1.02-3.31c0.67-1,1.3-1.94,1.01-3.17
					C261.31,63.95,257.87,61.77,254.64,62.49L254.64,62.49z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M265.06,124.09l-0.79-0.13c3.58-21.08-7.94-44.85-8.06-45.09l0.72-0.35
					C257.05,78.76,268.69,102.76,265.06,124.09z"/>
			</g>
		</g>
		<g>
			<path className="svgc1-7" d="M239.07,125.4c-0.04-0.24-0.08-0.48-0.13-0.72l0.78-0.14c0.04,0.24,0.09,0.48,0.13,0.72L239.07,125.4z
				 M238.32,121.57c-0.23-1.04-0.48-2.08-0.75-3.08l0.77-0.2c0.27,1.01,0.52,2.06,0.75,3.11L238.32,121.57z M236.7,115.45
				c-0.32-1.02-0.66-2.03-1.02-3l0.75-0.27c0.36,0.98,0.71,2.01,1.03,3.04L236.7,115.45z M234.51,109.51
				c-0.42-0.98-0.87-1.94-1.33-2.87l0.71-0.36c0.47,0.94,0.93,1.92,1.35,2.91L234.51,109.51z M231.67,103.86
				c-0.54-0.92-1.11-1.82-1.69-2.67l0.66-0.45c0.59,0.87,1.17,1.78,1.72,2.72L231.67,103.86z M228.1,98.65
				c-0.66-0.83-1.36-1.63-2.07-2.38l0.58-0.55c0.73,0.77,1.44,1.58,2.12,2.43L228.1,98.65z M174.23,95.17l-0.47-0.64
				c0.87-0.63,1.76-1.24,2.65-1.82l0.43,0.67C175.96,93.94,175.08,94.54,174.23,95.17z M223.77,94.09c-0.79-0.7-1.61-1.36-2.45-1.98
				l0.47-0.64c0.86,0.63,1.7,1.31,2.51,2.02L223.77,94.09z M179.55,91.74l-0.39-0.7c0.93-0.52,1.89-1.02,2.85-1.49l0.35,0.72
				C181.42,90.73,180.47,91.22,179.55,91.74z M218.69,90.37c-0.9-0.54-1.83-1.03-2.78-1.48l0.34-0.72c0.97,0.46,1.92,0.97,2.85,1.51
				L218.69,90.37z M185.25,88.99l-0.3-0.74c1-0.4,2.01-0.78,3.02-1.11l0.25,0.76C187.23,88.23,186.23,88.6,185.25,88.99z
				 M213.01,87.69c-0.98-0.35-1.99-0.66-3.01-0.92l0.2-0.77c1.04,0.26,2.08,0.58,3.08,0.94L213.01,87.69z M191.25,87.02l-0.2-0.77
				c1.04-0.27,2.1-0.49,3.15-0.68l0.14,0.78C193.32,86.53,192.28,86.75,191.25,87.02z M206.91,86.14c-1.03-0.16-2.08-0.28-3.13-0.35
				l0.05-0.8c1.07,0.07,2.15,0.19,3.2,0.36L206.91,86.14z M197.47,85.91l-0.08-0.79c1.07-0.11,2.15-0.17,3.22-0.19l0.01,0.8
				C199.58,85.74,198.52,85.8,197.47,85.91z"/>
		</g>
		<g>
			<g>
				<path className="svgc1-7" d="M48.93,142.8c3.36-3.71,8.5-4.59,10.92-3.04c2.32,1.48,0.68,4.23,2.76,5.96c3.27,2.7,9.08-2.61,13.32-0.77
					c4.38,1.91,0.63,8.64,4.57,12.92c4.82,5.25,13.61-1.44,19.68,2.87c5.82,4.12,7.37,17.06-0.33,25.49
					c-6.79,7.44-18.52,8.88-23.32,4.68c-5.61-4.91,0.7-15.36-5.21-19.26c-5.31-3.5-13.17,3.08-16.81-1.1
					c-3.04-3.5,1.52-9.18-2.16-13.99c-2.42-3.15-5.73-2.45-6.27-5.94C45.7,148.21,46.84,145.1,48.93,142.8z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M112.67,193.14C112.66,193.14,112.66,193.14,112.67,193.14c-5.96-0.12-17.5-7.61-34.31-22.27
					c-12.48-10.89-23.37-21.88-23.48-21.99c-0.15-0.16-0.15-0.41,0-0.56c0.16-0.15,0.41-0.15,0.56,0
					c0.43,0.44,43.43,43.76,57.23,44.03c0.22,0,0.4,0.19,0.39,0.41C113.06,192.97,112.88,193.14,112.67,193.14z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M79.79,184.46c-0.19,0-0.36-0.14-0.39-0.33c-0.04-0.22,0.11-0.42,0.32-0.46l11.75-2.07l-0.14-15.21
					c0-0.22,0.17-0.4,0.39-0.4c0,0,0,0,0,0c0.22,0,0.4,0.18,0.4,0.39l0.14,15.55c0,0.19-0.14,0.36-0.33,0.4l-12.09,2.13
					C79.84,184.46,79.82,184.46,79.79,184.46z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M58.38,165.13c-0.18,0-0.34-0.12-0.39-0.31c-0.05-0.21,0.08-0.43,0.3-0.48l10.6-2.49l1.7-10.5
					c0.04-0.22,0.24-0.37,0.46-0.33c0.22,0.04,0.36,0.24,0.33,0.46l-1.74,10.76c-0.03,0.16-0.15,0.29-0.3,0.32l-10.86,2.55
					C58.44,165.12,58.41,165.13,58.38,165.13z"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc2-0" d="M84.03,164.75c-1.7,0-3.42-0.3-5-0.89c-2.34-0.89-4.04-2.3-4.78-3.97c-1.03-2.33,0.01-4.63,1.02-6.86
					c0.94-2.09,1.83-4.06,0.85-5.8c-1.05-1.85-3.59-2.31-5.83-2.73c-2.22-0.41-4.32-0.79-4.79-2.47c-0.39-1.4,0.68-2.6,1.82-3.87
					c1.27-1.41,2.71-3.01,2.55-5.23c-0.08-1.15-0.54-1.91-1.03-2.71c-0.59-0.97-1.2-1.97-1.01-3.6c0.24-2.11,1.6-4.02,3.47-4.88l0,0
					c2.99-1.37,6.62,0.07,7.63,2.18c0.6,1.26,0.15,2.4-0.25,3.4c-0.34,0.86-0.64,1.61-0.28,2.32c0.64,1.27,2.83,1.38,4.96,1.48
					c2.33,0.11,4.73,0.23,5.59,1.87c0.61,1.16,0.04,2.31-0.52,3.42c-0.56,1.12-1.14,2.27-0.77,3.62c0.45,1.64,1.96,2.26,3.71,2.98
					c1.63,0.67,3.47,1.43,4.61,3.2c1.35,2.1,1.59,5.46,0.63,8.77c-1.14,3.93-3.7,7.06-7.03,8.6
					C87.92,164.36,85.98,164.75,84.03,164.75z M71.64,122.47c-1.84,0.84-2.84,2.74-3.01,4.24c-0.15,1.36,0.33,2.16,0.9,3.09
					c0.49,0.81,1.05,1.73,1.14,3.07c0.18,2.55-1.45,4.36-2.75,5.82c-1.03,1.15-1.92,2.14-1.65,3.12c0.34,1.2,2.11,1.52,4.17,1.9
					c2.41,0.44,5.15,0.95,6.38,3.12c1.18,2.09,0.17,4.34-0.81,6.52c-0.93,2.06-1.9,4.2-1.01,6.21c0.64,1.46,2.22,2.75,4.33,3.55
					c3.19,1.21,7,1.11,9.94-0.25c3.12-1.44,5.52-4.39,6.6-8.1c0.9-3.1,0.69-6.21-0.54-8.11c-1-1.56-2.65-2.24-4.25-2.89
					c-1.86-0.77-3.62-1.49-4.18-3.5c-0.45-1.64,0.22-2.99,0.82-4.19c0.53-1.06,0.95-1.89,0.53-2.69c-0.65-1.24-2.83-1.34-4.93-1.44
					c-2.35-0.12-4.78-0.23-5.63-1.92c-0.52-1.04-0.13-2.02,0.25-2.98c0.37-0.93,0.72-1.82,0.27-2.76
					C77.37,122.5,74.17,121.32,71.64,122.47L71.64,122.47z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M90.89,172.09c-0.21,0-0.39-0.17-0.4-0.38c-0.78-18.11-14.56-35.76-14.7-35.94
					c-0.14-0.17-0.11-0.42,0.07-0.56c0.17-0.14,0.42-0.11,0.56,0.07c0.14,0.18,14.08,18.04,14.87,36.4
					c0.01,0.22-0.16,0.41-0.38,0.42C90.9,172.09,90.89,172.09,90.89,172.09z"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc1-6" d="M335.72,133.44c-2.72-2.15-7.03-1.73-8.69,0.29c-1.77,2.16,0.55,4.89-1,6.71
					c-2.51,2.95-10.47-1.99-13.02,0.89c-1.77,2,1.3,5.22-0.44,8.33c-2.08,3.72-7.79,1.49-11.34,4.85
					c-4.27,4.05-4.56,15.39,2.44,20.97c5.69,4.53,14.83,4.16,17.92,0.13c3.41-4.44-2.11-11.27,1.44-15.04
					c3.56-3.79,11.73,0.33,13.54-2.89c1.56-2.77-4.09-6.6-2.5-11.55c0.98-3.05,3.67-3.32,4.08-6.57
					C338.42,137.48,337.64,134.96,335.72,133.44z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M299.63,185.17c-0.04,0-0.07-0.01-0.11-0.02c-0.21-0.06-0.33-0.28-0.27-0.49
					c6.03-20.78,26.96-37.31,27.18-37.48c0.17-0.14,0.42-0.1,0.56,0.07c0.14,0.17,0.1,0.42-0.07,0.56
					c-0.21,0.16-20.94,16.54-26.9,37.07C299.97,185.06,299.81,185.17,299.63,185.17z"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc2-0" d="M296.75,213.67c-0.01,0-0.02,0-0.03,0c-0.22-0.02-0.38-0.21-0.36-0.43c0.44-5.19,6.48-13.23,17.94-23.88
					c8.5-7.9,16.98-14.38,17.06-14.44c0.18-0.13,0.43-0.1,0.56,0.08c0.13,0.17,0.1,0.43-0.08,0.56c-0.34,0.26-33.67,25.8-34.7,37.76
					C297.13,213.51,296.96,213.67,296.75,213.67z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M332,175.77c-0.26,0-0.41-0.01-0.43-0.01c-0.12-0.01-0.24-0.07-0.31-0.17c-0.07-0.1-0.09-0.23-0.05-0.35
					c3.04-8.91,13.03-8.31,13.13-8.3c0.11,0.01,0.21,0.06,0.28,0.15c0.07,0.08,0.1,0.19,0.09,0.3
					C343.69,175.31,334.15,175.77,332,175.77z M343.73,167.71c-2.03,0-8.94,0.51-11.57,7.26c2.22-0.02,10.49-0.55,11.69-7.26
					C343.81,167.71,343.77,167.71,343.73,167.71z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M327.54,190.95c-4.94,0-9.63-4.15-9.7-4.2c-0.09-0.08-0.14-0.2-0.13-0.32c0.01-0.12,0.07-0.23,0.17-0.3
					c7.73-5.4,15.35,1.66,15.42,1.73c0.08,0.07,0.12,0.17,0.12,0.28c0,0.11-0.04,0.21-0.11,0.29
					C331.54,190.28,329.52,190.95,327.54,190.95z M318.77,186.48c1.73,1.38,8.72,6.41,13.67,1.68c-0.63-0.52-2.17-1.69-4.21-2.48
					C324.84,184.36,321.66,184.64,318.77,186.48z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M318.1,186.84c-0.02,0-0.03,0-0.05,0c-0.12-0.01-0.23-0.09-0.3-0.19c-4.76-8.13,2.23-15.29,2.3-15.36
					c0.08-0.08,0.18-0.12,0.29-0.12c0.11,0,0.21,0.05,0.29,0.13c5.99,6.69-2.16,15.34-2.24,15.42
					C318.32,186.8,318.21,186.84,318.1,186.84z M320.33,172.16c-1.23,1.39-5.61,7.05-2.15,13.59
					C319.61,184.07,324.62,177.46,320.33,172.16z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M312.56,203.46c-4.43,0-8.74-2.54-8.81-2.58c-0.11-0.06-0.18-0.17-0.19-0.3c-0.01-0.12,0.03-0.25,0.12-0.33
					c6.74-6.59,15.77-1.03,15.86-0.97c0.09,0.06,0.16,0.15,0.18,0.26c0.02,0.11,0,0.22-0.06,0.31
					C317.8,202.6,315.16,203.46,312.56,203.46z M304.64,200.46c2.01,1.06,9.98,4.76,14.11-0.71
					C317.09,198.85,310.12,195.58,304.64,200.46z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M303.96,200.94c-0.12,0-0.23-0.05-0.31-0.14c-6.01-7.23-0.81-15.75-0.76-15.84
					c0.06-0.1,0.16-0.16,0.27-0.18c0.11-0.02,0.23,0.01,0.32,0.08c6.99,5.61,0.88,15.79,0.82,15.89c-0.07,0.11-0.18,0.18-0.31,0.19
					C303.98,200.94,303.97,200.94,303.96,200.94z M303.35,185.79c-0.88,1.68-3.83,8.24,0.56,14.04
					C304.97,197.82,308.41,190.31,303.35,185.79z"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc1-7" d="M290.67,67.75c0,0,19.22,36.36,6.82,56.08s-27.31,24.87-27.31,24.87s-12.44-20.33-5.94-42.61
					c6.5-22.28,13.32-26.72,13.32-26.72l3.79,4.95l1.5-11.37C282.87,72.95,285.08,69.68,290.67,67.75z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M270.18,149.09c-0.07,0-0.15-0.02-0.22-0.06c-0.18-0.12-0.24-0.37-0.12-0.55
					c9.91-15.33,20.33-80.14,20.43-80.79c0.03-0.22,0.24-0.37,0.46-0.33c0.22,0.03,0.37,0.24,0.33,0.46
					c-0.03,0.16-2.65,16.56-6.47,34.65c-5.14,24.32-9.87,39.95-14.08,46.45C270.44,149.03,270.31,149.09,270.18,149.09z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M280.85,117.61c-0.04,0-0.07,0-0.11-0.02c-2.58-0.73-4.6-6.44-6.18-17.46c-1.14-7.99-1.59-15.88-1.59-15.96
					c-0.01-0.22,0.16-0.41,0.38-0.42c0.23-0.01,0.41,0.16,0.42,0.38c0.48,8.66,2.71,31.43,7.19,32.69c0.21,0.06,0.34,0.28,0.28,0.49
					C281.19,117.49,281.03,117.61,280.85,117.61z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M279.36,127.17c-0.03,0-0.05,0-0.06,0c-0.22-0.01-0.39-0.19-0.39-0.41c0.01-0.22,0.18-0.4,0.41-0.39l0,0
					c0.07-0.01,8.86,0.05,22.23-15.57c0.14-0.17,0.4-0.19,0.56-0.04c0.17,0.14,0.19,0.39,0.04,0.56
					C288.95,126.75,280.25,127.17,279.36,127.17z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M284.36,102.04c-0.18,0-0.34-0.12-0.39-0.3c-0.05-0.21,0.07-0.43,0.29-0.48
					c8.86-2.26,14.06-13.79,14.11-13.91c0.09-0.2,0.32-0.29,0.53-0.2c0.2,0.09,0.29,0.32,0.2,0.53c-0.05,0.12-1.35,3.03-3.8,6.27
					c-2.27,3.01-6,6.85-10.84,8.09C284.42,102.04,284.39,102.04,284.36,102.04z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M284.69,106.18c-0.43,0-0.86-0.04-1.27-0.13c-0.22-0.05-0.35-0.26-0.31-0.47c0.05-0.22,0.26-0.35,0.47-0.31
					c7.06,1.49,15.79-12.35,15.87-12.49c0.12-0.19,0.36-0.24,0.55-0.13c0.19,0.12,0.24,0.36,0.13,0.55
					c-0.02,0.04-2.27,3.63-5.41,6.98C290.99,104.17,287.63,106.18,284.69,106.18z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M275.88,132.94c-0.03,0-0.06,0-0.1-0.01c-3.34-0.82-6.52-4.41-9.45-10.67c-2.16-4.6-3.38-9.03-3.39-9.07
					c-0.06-0.21,0.07-0.43,0.28-0.49c0.21-0.06,0.43,0.07,0.49,0.28c0.05,0.17,4.83,17.34,12.27,19.18
					c0.21,0.05,0.34,0.27,0.29,0.48C276.22,132.82,276.06,132.94,275.88,132.94z"/>
			</g>
			<g>
				<path className="svgc3-10" d="M275.28,137.43c-0.05,0-0.1-0.01-0.14-0.03c-11.09-4.27-12.77-12.41-12.84-12.76
					c-0.04-0.22,0.1-0.42,0.32-0.47c0.22-0.04,0.43,0.1,0.47,0.32c0.02,0.08,1.69,8.06,12.34,12.16c0.21,0.08,0.31,0.31,0.23,0.52
					C275.59,137.33,275.44,137.43,275.28,137.43z"/>
			</g>
		</g>
		<g>
			<path className="svgc1-7" d="M297.03,256.82l-0.18-0.78c0.34-0.08,0.67-0.16,1.01-0.24l0.19,0.78
				C297.71,256.66,297.37,256.74,297.03,256.82z M301.14,255.78l-0.21-0.77c1.03-0.28,2.06-0.58,3.05-0.89l0.24,0.76
				C303.22,255.19,302.18,255.5,301.14,255.78z M307.25,253.87l-0.27-0.75c1-0.35,2-0.73,2.97-1.12l0.3,0.74
				C309.27,253.13,308.26,253.51,307.25,253.87z M313.2,251.47l-0.33-0.73c0.97-0.44,1.93-0.91,2.85-1.38l0.37,0.71
				C315.15,250.55,314.18,251.03,313.2,251.47z M318.89,248.52l-0.4-0.69c0.92-0.54,1.82-1.11,2.68-1.69l0.45,0.66
				C320.73,247.4,319.82,247.97,318.89,248.52z M324.21,244.93l-0.49-0.63c0.84-0.65,1.65-1.33,2.42-2.03l0.53,0.59
				C325.89,243.57,325.06,244.26,324.21,244.93z M328.98,240.62l-0.58-0.55c0.73-0.77,1.42-1.57,2.06-2.38l0.63,0.49
				C330.43,239.01,329.72,239.83,328.98,240.62z M332.96,235.56l-0.67-0.43c0.57-0.89,1.1-1.8,1.58-2.72l0.71,0.37
				C334.09,233.71,333.55,234.65,332.96,235.56z M335.91,229.83l-0.74-0.29c0.38-0.98,0.71-1.98,0.99-2.99l0.77,0.21
				C336.64,227.8,336.3,228.83,335.91,229.83z M337.6,223.61l-0.79-0.13c0.17-1.03,0.28-2.08,0.33-3.13l0.8,0.04
				C337.89,221.47,337.77,222.55,337.6,223.61z M337.15,217.21c-0.05-1.04-0.16-2.09-0.33-3.13l0.79-0.12
				c0.17,1.06,0.28,2.14,0.34,3.21L337.15,217.21z M336.17,211c-0.27-1.01-0.59-2.02-0.96-3l0.75-0.28c0.38,1,0.71,2.04,0.99,3.07
				L336.17,211z M333.95,205.12c-0.47-0.94-1-1.86-1.56-2.73l0.67-0.43c0.58,0.89,1.12,1.84,1.61,2.8L333.95,205.12z M330.52,199.87
				c-0.68-0.82-1.4-1.58-2.15-2.28l0.54-0.59c0.78,0.72,1.52,1.51,2.22,2.35L330.52,199.87z M325.93,195.64
				c-0.88-0.6-1.8-1.11-2.72-1.52l0.32-0.73c0.96,0.43,1.92,0.96,2.84,1.59L325.93,195.64z M320.27,193.16
				c-1.03-0.21-2.06-0.3-3.07-0.26l-0.03-0.8c1.08-0.04,2.17,0.05,3.26,0.28L320.27,193.16z"/>
		</g>
		<g>
			<g>
				<circle className="svgc3-0" cx="336.11" cy="214.18" r="6.07"/>
			</g>
		</g>
		<g>
			<g>
				
					<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -126.1531 90.2851)" className="svgc2-0" cx="45.91" cy="197.42" rx="6.07" ry="6.07"/>
			</g>
			<g>
				<path className="svgc3-10" d="M46.16,200.05c-0.12,0-0.23-0.05-0.31-0.15l-2.55-3.11c-0.14-0.17-0.11-0.42,0.06-0.56
					c0.17-0.14,0.42-0.11,0.56,0.06l2.22,2.72l1.62-2.19c0.13-0.18,0.38-0.21,0.56-0.08c0.18,0.13,0.21,0.38,0.08,0.56l-1.93,2.6
					C46.41,199.98,46.3,200.04,46.16,200.05C46.17,200.05,46.17,200.05,46.16,200.05z"/>
			</g>
		</g>
		<g>
			
				<rect x="329.47" y="90.61" transform="matrix(0.8308 0.5566 -0.5566 0.8308 108.2552 -169.1604)" className="svgc2-0" width="5.67" height="5.67"/>
		</g>
		<g>
			
				<rect x="223.71" y="48.19" transform="matrix(0.8308 0.5566 -0.5566 0.8308 66.7432 -117.4669)" className="svgc1-1" width="5.67" height="5.67"/>
		</g>
		<g>
			
				<rect x="60.97" y="90.15" transform="matrix(0.7898 0.6133 -0.6133 0.7898 70.4416 -19.5868)" className="svgc1-3" width="5.67" height="5.67"/>
		</g>
		<g>
			<g>
				<g>
					<path className="svgc1-3" d="M107.81,85.13h-7.15c-0.2,0-0.37-0.15-0.4-0.35c0-0.02-0.3-2.33-0.86-3.45c-0.1-0.2-0.26-0.49-0.46-0.84
						c-1-1.79-2.86-5.11-2.86-7.68c0-3.48,2.13-7.55,8.15-7.55c6.01,0,8.15,4.07,8.15,7.55c0,2.57-1.86,5.9-2.86,7.68
						c-0.2,0.35-0.36,0.64-0.46,0.84c-0.56,1.12-0.85,3.42-0.86,3.45C108.18,84.98,108.01,85.13,107.81,85.13z M101,84.33h6.46
						c0.11-0.72,0.4-2.39,0.88-3.36c0.1-0.21,0.27-0.51,0.48-0.87c0.96-1.72,2.76-4.93,2.76-7.29c0-3.12-1.92-6.75-7.35-6.75
						c-5.43,0-7.35,3.64-7.35,6.75c0,2.36,1.8,5.57,2.76,7.29c0.2,0.37,0.37,0.66,0.48,0.87C100.6,81.95,100.89,83.61,101,84.33z"/>
				</g>
				<g>
					<path className="svgc1-3" d="M107.66,87.01h-6.85c-0.3,0-0.55-0.25-0.55-0.55l0,0c0-0.3,0.25-0.55,0.55-0.55h6.85
						c0.3,0,0.55,0.25,0.55,0.55v0C108.2,86.76,107.96,87.01,107.66,87.01z"/>
				</g>
				<g>
					<path className="svgc1-3" d="M107.66,89.29h-6.85c-0.3,0-0.55-0.25-0.55-0.55l0,0c0-0.3,0.25-0.55,0.55-0.55h6.85
						c0.3,0,0.55,0.25,0.55,0.55l0,0C108.2,89.05,107.96,89.29,107.66,89.29z"/>
				</g>
				<g>
					<path className="svgc1-3" d="M101.32,90.48c0,0,0.89,1.98,2.98,1.98c2.09,0,2.68-1.98,2.68-1.98H101.32z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M104.3,60.94c-0.22,0-0.4-0.18-0.4-0.4v-6.85c0-0.22,0.18-0.4,0.4-0.4c0.22,0,0.4,0.18,0.4,0.4v6.85
						C104.7,60.76,104.52,60.94,104.3,60.94z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M95.77,63.81c-0.11,0-0.23-0.05-0.31-0.14l-4.41-5.25c-0.14-0.17-0.12-0.42,0.05-0.56
						c0.17-0.14,0.42-0.12,0.56,0.05l4.41,5.25c0.14,0.17,0.12,0.42-0.05,0.56C95.95,63.78,95.86,63.81,95.77,63.81z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M91.35,71.61c-0.02,0-0.05,0-0.07-0.01l-6.75-1.19c-0.22-0.04-0.36-0.25-0.32-0.46
						c0.04-0.22,0.24-0.36,0.46-0.32l6.75,1.19c0.22,0.04,0.36,0.25,0.32,0.46C91.71,71.47,91.54,71.61,91.35,71.61z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M117,71.9c-0.19,0-0.36-0.14-0.39-0.33c-0.04-0.22,0.11-0.42,0.32-0.46l6.75-1.19
						c0.22-0.04,0.42,0.11,0.46,0.32c0.04,0.22-0.11,0.42-0.32,0.46l-6.75,1.19C117.04,71.9,117.02,71.9,117,71.9z"/>
				</g>
				<g>
					<path className="svgc2-0" d="M112.75,64c-0.09,0-0.18-0.03-0.26-0.09c-0.17-0.14-0.19-0.39-0.05-0.56l4.41-5.25
						c0.14-0.17,0.39-0.19,0.56-0.05c0.17,0.14,0.19,0.39,0.05,0.56l-4.41,5.25C112.98,63.95,112.87,64,112.75,64z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="svgc1-3" d="M103.62,84.85l-0.79-0.1c0.18-1.52,0.42-4.77,0.14-7.36c-0.89-0.22-1.5-0.65-1.89-1.02
						c-0.63-0.62-1.03-1.46-1.03-2.19c0-0.75,0.42-1.26,1.05-1.26c0.56,0,1.04,0.23,1.44,0.68c0.59,0.67,0.94,1.81,1.13,3.12
						c0.38,0.04,0.84,0.04,1.21-0.01c0.18-1.43,0.5-2.67,1.07-3.29c0.3-0.33,0.65-0.49,1.05-0.49c0.78,0,1.31,0.58,1.31,1.44
						c0,0.74-0.38,1.53-1.02,2.11c-0.35,0.32-0.9,0.69-1.68,0.9c-0.23,2.58-0.06,5.83,0.08,7.39l-0.79,0.07
						c-0.06-0.61-0.36-4.23-0.1-7.32c-0.32,0.03-0.7,0.04-1.03,0.01C104.1,80.77,103.65,84.61,103.62,84.85z M101.11,73.72
						c-0.22,0-0.26,0.29-0.26,0.46c0,0.41,0.21,1.06,0.79,1.62c0.26,0.26,0.66,0.54,1.21,0.73c-0.17-1.04-0.46-1.91-0.9-2.4
						C101.71,73.85,101.43,73.72,101.11,73.72z M107.02,73.72c-0.11,0-0.27,0.02-0.46,0.23c-0.41,0.45-0.68,1.39-0.83,2.55
						c0.47-0.17,0.81-0.41,1.04-0.62c0.56-0.52,0.77-1.13,0.77-1.53C107.53,74.12,107.47,73.72,107.02,73.72z"/>
				</g>
			</g>
		</g>
		<g>
			<path className="svgc3-10" d="M336.37,216.55c-0.12,0-0.23-0.05-0.31-0.15l-2.55-3.11c-0.14-0.17-0.11-0.42,0.06-0.56
				c0.17-0.14,0.42-0.11,0.56,0.06l2.22,2.72l1.62-2.19c0.13-0.18,0.38-0.21,0.56-0.08c0.18,0.13,0.21,0.38,0.08,0.56l-1.93,2.6
				C336.62,216.49,336.51,216.55,336.37,216.55C336.38,216.55,336.38,216.55,336.37,216.55z"/>
		</g>
	</g>
	<g id="shadows_9_">
		<g className="wh1">
			<ellipse className="svgc3-0" cx="74.92" cy="335.11" rx="29.02" ry="3.91"/>
		</g>
		<g className="wh1">
			<ellipse className="svgc3-0" cx="335.28" cy="323.95" rx="19.75" ry="4"/>
		</g>
		<g className="wh1">
			<ellipse className="svgc3-0" cx="255.77" cy="327.05" rx="35.57" ry="4.05"/>
		</g>
		<g className="wh1">
			<ellipse className="svgc3-0" cx="201.24" cy="308.66" rx="116.96" ry="8.3"/>
		</g>
	</g>
	<g id="monitor_4_">
		<g>
			<path className="svgc1-0" d="M298.2,275.87H104.38c-11.05,0-20-8.95-20-20v-122.7c0-11.05,8.95-20,20-20H298.2c11.05,0,20,8.95,20,20
				v122.7C318.2,266.92,309.25,275.87,298.2,275.87z"/>
		</g>
		<g>
			<path className="svgc1-2" d="M238.01,300.38h-73.44c0,0,14.44-14.17,12.4-24.51h48.64C223.78,292.78,238.01,300.38,238.01,300.38z"/>
		</g>
		<g>
			<path className="svgc1-0" d="M235.63,309.54h-68.68c-2.21,0-4-1.79-4-4v-1.16c0-2.21,1.79-4,4-4h68.68c2.21,0,4,1.79,4,4v1.16
				C239.63,307.74,237.84,309.54,235.63,309.54z"/>
		</g>
		<g>
			<path className="svgc3-10" d="M306.55,264.13H96.04c-1.1,0-2-0.9-2-2V126.9c0-1.1,0.9-2,2-2h210.51c1.1,0,2,0.9,2,2v135.23
				C308.55,263.24,307.65,264.13,306.55,264.13z"/>
		</g>
		<g className="wh2">
			<path className="svgc3-0" d="M225.61,275.87h-48.64c0.01,0.05,0.02,0.09,0.02,0.14l49.48,9.9C225.6,283.02,225.2,279.68,225.61,275.87z"
				/>
		</g>
		<g>
			<path className="svgc2-0" d="M129,161.41c-1.2,0-2.24-0.73-2.64-1.86l-0.49-1.39c-0.12-0.32-0.35-0.58-0.67-0.71s-0.66-0.12-0.98,0.02
				l-1.33,0.63c-1.08,0.52-2.33,0.3-3.18-0.55c-0.85-0.85-1.06-2.1-0.55-3.18l0.63-1.33c0.15-0.31,0.16-0.66,0.03-0.98
				c-0.13-0.32-0.38-0.56-0.71-0.67l-1.39-0.49c-1.13-0.4-1.86-1.44-1.86-2.64c0-1.2,0.73-2.24,1.86-2.64l1.39-0.49
				c0.32-0.12,0.58-0.35,0.71-0.67c0.13-0.32,0.12-0.66-0.02-0.98l-0.63-1.33c-0.52-1.08-0.3-2.33,0.55-3.18
				c0.85-0.85,2.1-1.06,3.18-0.55l1.33,0.63c0.31,0.15,0.66,0.16,0.98,0.02c0.32-0.13,0.56-0.38,0.67-0.71l0.49-1.39
				c0.4-1.13,1.44-1.86,2.64-1.86c1.2,0,2.24,0.73,2.64,1.86l0.49,1.39c0.12,0.32,0.35,0.58,0.67,0.71
				c0.32,0.13,0.66,0.12,0.98-0.02l1.33-0.63c1.08-0.51,2.33-0.3,3.18,0.55c0.85,0.85,1.06,2.1,0.55,3.18l-0.63,1.33
				c-0.15,0.31-0.16,0.66-0.02,0.98c0.13,0.32,0.38,0.56,0.71,0.67l1.39,0.49c1.13,0.4,1.86,1.44,1.86,2.64
				c0,1.2-0.73,2.24-1.86,2.64l-1.39,0.49c-0.32,0.12-0.58,0.35-0.71,0.67c-0.13,0.32-0.12,0.66,0.02,0.98l0.63,1.33
				c0.52,1.08,0.3,2.33-0.55,3.18c-0.85,0.85-2.1,1.06-3.18,0.55l-1.33-0.63c-0.31-0.15-0.66-0.16-0.98-0.02
				c-0.32,0.13-0.56,0.38-0.67,0.71l-0.49,1.39C131.23,160.68,130.2,161.41,129,161.41z M124.73,156.17c0.31,0,0.62,0.06,0.92,0.18
				c0.63,0.26,1.11,0.77,1.34,1.41l0.49,1.39c0.28,0.79,0.96,1.07,1.51,1.07c0.55,0,1.23-0.28,1.51-1.07l0.49-1.39
				c0.23-0.64,0.72-1.15,1.34-1.41c0.63-0.26,1.34-0.24,1.95,0.05l1.33,0.63c0.75,0.36,1.44,0.07,1.82-0.31
				c0.39-0.39,0.67-1.07,0.31-1.82l-0.63-1.33c-0.29-0.61-0.31-1.32-0.05-1.95c0.26-0.63,0.77-1.11,1.41-1.34l1.39-0.49
				c0.79-0.28,1.07-0.96,1.07-1.51c0-0.55-0.28-1.23-1.07-1.51l-1.39-0.49c-0.64-0.23-1.15-0.72-1.41-1.34
				c-0.26-0.63-0.24-1.34,0.05-1.95l0.63-1.33c0.36-0.75,0.07-1.44-0.31-1.82s-1.07-0.67-1.82-0.31l-1.33,0.63
				c-0.61,0.29-1.32,0.31-1.95,0.05c-0.63-0.26-1.11-0.77-1.34-1.41l-0.49-1.39c-0.28-0.79-0.96-1.07-1.51-1.07
				c-0.55,0-1.23,0.28-1.51,1.07l-0.49,1.39c-0.23,0.64-0.72,1.15-1.34,1.41c-0.63,0.26-1.34,0.24-1.95-0.05l-1.33-0.63
				c-0.75-0.36-1.44-0.07-1.82,0.31c-0.39,0.39-0.67,1.07-0.31,1.82l0.63,1.33c0.29,0.61,0.31,1.32,0.05,1.95
				c-0.26,0.63-0.77,1.11-1.41,1.34l-1.39,0.49c-0.79,0.28-1.07,0.96-1.07,1.51c0,0.55,0.28,1.23,1.07,1.51l1.39,0.49
				c0.64,0.23,1.15,0.72,1.41,1.34c0.26,0.63,0.24,1.34-0.05,1.95l-0.63,1.33c-0.36,0.75-0.07,1.44,0.31,1.82
				c0.39,0.39,1.07,0.67,1.82,0.31l1.33-0.63C124.03,156.24,124.38,156.17,124.73,156.17z M129,154.25c-3.3,0-5.98-2.68-5.98-5.98
				c0-3.3,2.68-5.98,5.98-5.98c3.3,0,5.98,2.68,5.98,5.98C134.98,151.57,132.29,154.25,129,154.25z M129,143.49
				c-2.64,0-4.78,2.15-4.78,4.78c0,2.64,2.15,4.78,4.78,4.78c2.64,0,4.78-2.15,4.78-4.78C133.78,145.63,131.63,143.49,129,143.49z"
				/>
		</g>
		<g>
			<path className="svgc2-0" d="M125.75,251.56C125.75,251.56,125.75,251.56,125.75,251.56c-1.2,0-2.24-0.73-2.64-1.86l-0.49-1.39
				c-0.12-0.32-0.35-0.58-0.67-0.71c-0.32-0.13-0.66-0.12-0.98,0.02l-1.33,0.63c-1.08,0.52-2.33,0.3-3.18-0.55
				c-0.85-0.85-1.06-2.1-0.55-3.18l0.63-1.33c0.15-0.31,0.16-0.66,0.02-0.98c-0.13-0.32-0.38-0.56-0.71-0.67l-1.39-0.49
				c-1.13-0.4-1.86-1.44-1.86-2.64c0-1.2,0.73-2.24,1.86-2.64l1.39-0.49c0.32-0.12,0.58-0.35,0.71-0.67
				c0.13-0.32,0.12-0.66-0.02-0.98l-0.63-1.33c-0.52-1.08-0.3-2.33,0.55-3.18c0.85-0.85,2.1-1.06,3.18-0.55l1.33,0.63
				c0.31,0.15,0.66,0.16,0.98,0.02c0.32-0.13,0.56-0.38,0.67-0.71l0.49-1.39c0.4-1.13,1.44-1.86,2.64-1.86
				c1.2,0,2.24,0.73,2.64,1.86l0.49,1.39c0.12,0.32,0.35,0.58,0.67,0.71c0.32,0.13,0.66,0.12,0.98-0.02l1.33-0.63
				c1.09-0.52,2.33-0.3,3.18,0.55c0.85,0.85,1.06,2.1,0.55,3.18l-0.63,1.33c-0.15,0.31-0.16,0.66-0.02,0.98
				c0.13,0.32,0.38,0.56,0.71,0.67l1.39,0.49l0,0c1.13,0.4,1.86,1.44,1.86,2.64c0,1.2-0.73,2.24-1.86,2.64l-1.39,0.49
				c-0.32,0.12-0.58,0.35-0.71,0.67c-0.13,0.32-0.12,0.66,0.02,0.98l0.63,1.33c0.52,1.08,0.3,2.33-0.55,3.18
				c-0.85,0.85-2.1,1.06-3.18,0.55l-1.33-0.63c-0.31-0.15-0.66-0.16-0.98-0.03c-0.32,0.13-0.56,0.38-0.67,0.71l-0.49,1.39
				C127.99,250.83,126.95,251.56,125.75,251.56z M121.49,246.31c0.31,0,0.62,0.06,0.92,0.18c0.63,0.26,1.11,0.77,1.34,1.41
				l0.49,1.39c0.28,0.79,0.96,1.07,1.51,1.07c0.55,0,1.23-0.28,1.51-1.07l0.49-1.39c0.23-0.64,0.72-1.15,1.34-1.41
				c0.63-0.26,1.34-0.24,1.95,0.05l1.33,0.63c0.75,0.36,1.44,0.07,1.82-0.31c0.39-0.39,0.67-1.07,0.31-1.82l-0.63-1.33
				c-0.29-0.61-0.31-1.32-0.05-1.95c0.26-0.63,0.77-1.11,1.41-1.34l1.39-0.49c0.79-0.28,1.07-0.96,1.07-1.51
				c0-0.55-0.28-1.23-1.07-1.51v0l-1.39-0.49c-0.64-0.23-1.15-0.72-1.41-1.34c-0.26-0.63-0.24-1.34,0.05-1.95l0.63-1.33
				c0.36-0.75,0.07-1.44-0.31-1.82c-0.39-0.39-1.07-0.67-1.82-0.31l-1.33,0.63c-0.61,0.29-1.32,0.31-1.95,0.05
				c-0.63-0.26-1.11-0.77-1.34-1.41l-0.49-1.39c-0.28-0.79-0.96-1.07-1.51-1.07c-0.55,0-1.23,0.28-1.51,1.07l-0.49,1.39
				c-0.23,0.64-0.72,1.15-1.34,1.41c-0.63,0.26-1.34,0.24-1.95-0.05l-1.33-0.63c-0.75-0.36-1.44-0.07-1.82,0.31
				c-0.39,0.39-0.67,1.07-0.31,1.82l0.63,1.33c0.29,0.61,0.31,1.32,0.05,1.95c-0.26,0.63-0.77,1.11-1.41,1.34l-1.39,0.49
				c-0.79,0.28-1.07,0.96-1.07,1.51c0,0.55,0.28,1.23,1.07,1.51l1.39,0.49c0.64,0.23,1.15,0.72,1.41,1.34
				c0.26,0.63,0.24,1.34-0.05,1.95l-0.63,1.33c-0.36,0.75-0.07,1.44,0.31,1.82c0.39,0.39,1.07,0.67,1.82,0.31l1.33-0.63
				C120.78,246.39,121.13,246.31,121.49,246.31z M125.75,244.39c-3.3,0-5.98-2.68-5.98-5.98c0-3.3,2.68-5.98,5.98-5.98
				c3.3,0,5.98,2.68,5.98,5.98C131.73,241.71,129.05,244.39,125.75,244.39z M125.75,233.63c-2.64,0-4.78,2.15-4.78,4.78
				c0,2.64,2.15,4.78,4.78,4.78c2.64,0,4.78-2.15,4.78-4.78C130.53,235.78,128.39,233.63,125.75,233.63z"/>
		</g>
		<g>
			<path className="svgc2-0M291.34,230.29c-0.97,0-1.8-0" d="M285.96,159.33c-1.2,0-2.24-0.73-2.64-1.86l-0.49-1.39c-0.12-0.32-0.35-0.58-0.67-0.71
				c-0.32-0.13-0.66-0.12-0.98,0.02l-1.33,0.63c-1.08,0.51-2.33,0.3-3.18-0.55c-0.85-0.85-1.06-2.1-0.55-3.18l0.63-1.33
				c0.15-0.31,0.16-0.66,0.02-0.98c-0.13-0.32-0.38-0.56-0.71-0.67l-1.39-0.49c-1.13-0.4-1.86-1.44-1.86-2.64s0.73-2.24,1.86-2.64
				l1.39-0.49c0.32-0.12,0.58-0.35,0.71-0.67c0.13-0.32,0.12-0.66-0.02-0.98l-0.63-1.33c-0.52-1.08-0.3-2.33,0.55-3.18
				c0.85-0.85,2.1-1.06,3.18-0.55l1.33,0.63c0.31,0.15,0.66,0.16,0.98,0.02c0.32-0.13,0.56-0.38,0.67-0.71l0.49-1.39
				c0.4-1.13,1.44-1.86,2.64-1.86c0,0,0,0,0,0c1.2,0,2.24,0.73,2.64,1.86l0.49,1.39c0.12,0.32,0.35,0.58,0.67,0.71
				c0.32,0.13,0.66,0.12,0.98-0.02l1.33-0.63c1.08-0.51,2.33-0.3,3.18,0.55c0.85,0.85,1.06,2.1,0.55,3.18l-0.63,1.33
				c-0.15,0.31-0.16,0.66-0.02,0.98c0.13,0.32,0.38,0.56,0.71,0.67l1.39,0.49c1.13,0.4,1.86,1.44,1.86,2.64s-0.73,2.24-1.86,2.64
				l-1.39,0.49c-0.32,0.12-0.58,0.35-0.71,0.67c-0.13,0.32-0.12,0.66,0.02,0.98l0.63,1.33c0.52,1.08,0.3,2.33-0.55,3.18
				c-0.85,0.85-2.1,1.06-3.18,0.55l-1.33-0.63c-0.31-0.15-0.66-0.16-0.98-0.02c-0.32,0.13-0.56,0.38-0.67,0.71l-0.49,1.39
				C288.19,158.6,287.16,159.33,285.96,159.33z M281.7,154.09c0.31,0,0.62,0.06,0.92,0.18c0.63,0.26,1.11,0.77,1.34,1.41l0.49,1.39
				c0.28,0.79,0.96,1.07,1.51,1.07c0.55,0,1.23-0.28,1.51-1.07l0.49-1.39c0.23-0.64,0.72-1.15,1.34-1.41
				c0.63-0.26,1.34-0.24,1.95,0.05l1.33,0.63c0.75,0.36,1.44,0.07,1.82-0.31c0.39-0.39,0.67-1.07,0.31-1.82l-0.63-1.33
				c-0.29-0.61-0.31-1.32-0.05-1.95c0.26-0.63,0.77-1.11,1.41-1.34l1.39-0.49c0.79-0.28,1.07-0.96,1.07-1.51
				c0-0.55-0.28-1.23-1.07-1.51l-1.39-0.49c-0.64-0.23-1.15-0.72-1.41-1.34c-0.26-0.63-0.24-1.34,0.05-1.95l0.63-1.33
				c0.36-0.75,0.07-1.44-0.31-1.82c-0.39-0.39-1.07-0.67-1.82-0.31l-1.33,0.63c-0.61,0.29-1.32,0.31-1.95,0.05s-1.11-0.77-1.34-1.41
				l-0.49-1.39c-0.28-0.79-0.96-1.07-1.51-1.07l0,0c-0.55,0-1.23,0.28-1.51,1.07l-0.49,1.39c-0.23,0.64-0.72,1.15-1.34,1.41
				c-0.63,0.26-1.34,0.24-1.95-0.05l-1.33-0.63c-0.75-0.36-1.44-0.07-1.82,0.31c-0.39,0.39-0.67,1.07-0.31,1.82l0.63,1.33
				c0.29,0.61,0.31,1.32,0.05,1.95c-0.26,0.63-0.77,1.11-1.41,1.34l-1.39,0.49c-0.79,0.28-1.07,0.96-1.07,1.51
				c0,0.55,0.28,1.23,1.07,1.51l1.39,0.49c0.64,0.23,1.15,0.72,1.41,1.34c0.26,0.63,0.24,1.34-0.05,1.95l-0.63,1.33
				c-0.36,0.75-0.07,1.44,0.31,1.82c0.39,0.39,1.07,0.67,1.82,0.31l1.33-0.63C280.99,154.16,281.34,154.09,281.7,154.09z
				 M285.96,152.17c-3.3,0-5.98-2.68-5.98-5.98c0-3.3,2.68-5.98,5.98-5.98c3.3,0,5.98,2.68,5.98,5.98
				C291.94,149.49,289.26,152.17,285.96,152.17z M285.96,141.41c-2.64,0-4.78,2.15-4.78,4.78c0,2.64,2.15,4.78,4.78,4.78
				c2.64,0,4.78-2.15,4.78-4.78C290.74,143.55,288.6,141.41,285.96,141.41z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M116.46,193.93c-0.97,0-1.8-0.59-2.13-1.5l-0.37-1.05c-0.07-0.2-0.22-0.36-0.42-0.45
				c-0.2-0.08-0.42-0.08-0.62,0.02l-1,0.48c-0.87,0.42-1.88,0.24-2.57-0.44c-0.68-0.68-0.86-1.69-0.44-2.57l0.48-1
				c0.09-0.2,0.1-0.42,0.02-0.62c-0.08-0.2-0.24-0.35-0.45-0.42L107.9,186c-0.91-0.32-1.5-1.16-1.5-2.13c0-0.97,0.59-1.8,1.5-2.13
				l1.05-0.37c0.2-0.07,0.36-0.22,0.45-0.42c0.08-0.2,0.08-0.42-0.02-0.62l-0.48-1c-0.42-0.87-0.24-1.88,0.44-2.57
				c0.68-0.68,1.69-0.86,2.57-0.44l1,0.48c0.2,0.09,0.42,0.1,0.62,0.02c0.2-0.08,0.35-0.24,0.42-0.45l0.37-1.05
				c0.32-0.91,1.16-1.5,2.13-1.5c0.97,0,1.8,0.59,2.13,1.5l0.37,1.05c0.07,0.2,0.22,0.36,0.42,0.45c0.2,0.08,0.42,0.08,0.62-0.02
				l1-0.48c0.87-0.42,1.88-0.24,2.57,0.44c0.68,0.68,0.86,1.69,0.44,2.57l-0.48,1c-0.09,0.2-0.1,0.42-0.02,0.62
				c0.08,0.2,0.24,0.35,0.45,0.42l1.05,0.37c0.91,0.32,1.5,1.16,1.5,2.13c0,0.97-0.59,1.8-1.5,2.13l-1.05,0.37
				c-0.2,0.07-0.36,0.22-0.45,0.42c-0.08,0.2-0.08,0.42,0.02,0.62l0.48,1c0.42,0.87,0.24,1.88-0.44,2.57
				c-0.68,0.68-1.69,0.86-2.57,0.44l-1-0.48c-0.2-0.09-0.41-0.1-0.62-0.02c-0.2,0.08-0.35,0.24-0.42,0.45l-0.37,1.05
				C118.26,193.34,117.42,193.93,116.46,193.93z M113.24,189.68c0.25,0,0.51,0.05,0.75,0.15c0.51,0.21,0.91,0.63,1.09,1.15
				l0.37,1.05c0.19,0.52,0.64,0.71,1,0.71c0.36,0,0.82-0.19,1-0.71l0.37-1.05c0.18-0.52,0.58-0.94,1.09-1.15
				c0.51-0.21,1.09-0.2,1.59,0.04l1,0.48c0.5,0.24,0.95,0.05,1.21-0.21c0.26-0.26,0.45-0.71,0.21-1.21l-0.48-1
				c-0.24-0.5-0.25-1.08-0.04-1.59c0.21-0.51,0.63-0.91,1.15-1.09l1.05-0.37c0.52-0.19,0.71-0.64,0.71-1s-0.19-0.82-0.71-1
				l-1.05-0.37c-0.52-0.18-0.94-0.58-1.15-1.09c-0.21-0.51-0.2-1.09,0.04-1.59l0.48-1c0.24-0.5,0.05-0.95-0.21-1.21
				c-0.26-0.26-0.71-0.45-1.21-0.21l-1,0.48c-0.5,0.24-1.08,0.25-1.59,0.04c-0.51-0.21-0.91-0.63-1.09-1.15l-0.37-1.05
				c-0.19-0.52-0.64-0.71-1-0.71c-0.36,0-0.82,0.19-1,0.71l-0.37,1.05c-0.18,0.52-0.58,0.94-1.09,1.15
				c-0.51,0.21-1.09,0.2-1.59-0.04l-1-0.48c-0.5-0.24-0.95-0.05-1.21,0.21c-0.26,0.26-0.45,0.71-0.21,1.21l0.48,1
				c0.24,0.5,0.25,1.08,0.04,1.59c-0.21,0.51-0.63,0.91-1.15,1.09l-1.05,0.37c-0.52,0.19-0.71,0.64-0.71,1s0.19,0.82,0.71,1
				l1.05,0.37c0.52,0.18,0.94,0.58,1.15,1.09c0.21,0.51,0.2,1.09-0.04,1.59l-0.48,1c-0.24,0.5-0.05,0.95,0.21,1.21
				c0.26,0.26,0.71,0.45,1.21,0.21l1-0.48C112.67,189.74,112.95,189.68,113.24,189.68z M116.46,188.53c-2.57,0-4.65-2.09-4.65-4.65
				c0-2.57,2.09-4.65,4.65-4.65c2.57,0,4.65,2.09,4.65,4.65C121.11,186.44,119.02,188.53,116.46,188.53z M116.46,180.42
				c-1.91,0-3.46,1.55-3.46,3.46s1.55,3.46,3.46,3.46s3.46-1.55,3.46-3.46S118.36,180.42,116.46,180.42z"/>
		</g>
		<g>
			<path className="svgc2-0" d="M291.34,230.29c-0.97,0-1.8-0.59-2.13-1.5l-0.37-1.05c-0.07-0.21-0.22-0.36-0.42-0.45
				c-0.2-0.08-0.42-0.08-0.62,0.02l-1,0.48c-0.87,0.42-1.88,0.24-2.56-0.44c-0.68-0.68-0.86-1.69-0.44-2.57l0.48-1
				c0.09-0.2,0.1-0.41,0.02-0.62c-0.08-0.2-0.24-0.35-0.45-0.42l-1.05-0.37c-0.91-0.32-1.5-1.16-1.5-2.13c0-0.97,0.59-1.8,1.5-2.13
				l1.05-0.37c0.2-0.07,0.36-0.22,0.45-0.42c0.08-0.2,0.08-0.42-0.02-0.62l-0.48-1c-0.42-0.87-0.24-1.88,0.44-2.57
				c0.68-0.68,1.69-0.86,2.57-0.44l1,0.48c0.2,0.09,0.42,0.1,0.62,0.02c0.2-0.08,0.35-0.24,0.42-0.45l0.37-1.05
				c0.32-0.91,1.16-1.5,2.13-1.5c0.97,0,1.8,0.59,2.13,1.5l0.37,1.05c0.07,0.2,0.22,0.36,0.42,0.45c0.2,0.08,0.42,0.08,0.62-0.02
				l1-0.48c0.87-0.42,1.88-0.24,2.57,0.44c0.68,0.68,0.86,1.69,0.44,2.57l-0.48,1c-0.09,0.2-0.1,0.42-0.02,0.62
				c0.08,0.2,0.24,0.35,0.45,0.42l1.05,0.37c0.91,0.32,1.5,1.16,1.5,2.13c0,0.97-0.59,1.8-1.5,2.13l-1.05,0.37
				c-0.2,0.07-0.36,0.22-0.45,0.42c-0.08,0.2-0.08,0.42,0.02,0.62l0.48,1c0.42,0.87,0.24,1.88-0.44,2.57
				c-0.68,0.68-1.69,0.86-2.57,0.44l-1-0.48c-0.2-0.09-0.42-0.1-0.62-0.02c-0.2,0.08-0.35,0.24-0.42,0.45l-0.37,1.05
				C293.14,229.7,292.31,230.29,291.34,230.29z M288.13,226.04c0.25,0,0.51,0.05,0.75,0.15c0.51,0.21,0.91,0.63,1.09,1.15l0.37,1.05
				c0.19,0.52,0.64,0.71,1,0.71c0.36,0,0.82-0.19,1-0.71l0.37-1.05c0.18-0.52,0.58-0.94,1.09-1.15c0.51-0.21,1.09-0.2,1.59,0.04
				l1,0.48c0.5,0.24,0.95,0.05,1.21-0.21c0.26-0.26,0.45-0.71,0.21-1.21l-0.48-1c-0.24-0.5-0.25-1.08-0.04-1.59
				c0.21-0.51,0.63-0.91,1.15-1.09l1.05-0.37c0.52-0.19,0.71-0.64,0.71-1c0-0.36-0.19-0.82-0.71-1l-1.05-0.37
				c-0.52-0.18-0.94-0.58-1.15-1.09c-0.21-0.51-0.2-1.09,0.04-1.59l0.48-1c0.24-0.5,0.05-0.95-0.21-1.21
				c-0.26-0.26-0.71-0.45-1.21-0.21l-1,0.48c-0.5,0.24-1.08,0.25-1.59,0.04c-0.51-0.21-0.91-0.63-1.09-1.15l-0.37-1.05
				c-0.19-0.52-0.64-0.71-1-0.71c-0.36,0-0.82,0.19-1,0.71l-0.37,1.05c-0.18,0.52-0.58,0.94-1.09,1.15
				c-0.51,0.21-1.09,0.2-1.59-0.04l-1-0.48c-0.5-0.24-0.95-0.05-1.21,0.21c-0.26,0.26-0.45,0.71-0.21,1.21l0.48,1
				c0.24,0.5,0.25,1.08,0.04,1.59c-0.21,0.51-0.63,0.91-1.15,1.09l-1.05,0.37c-0.52,0.19-0.71,0.64-0.71,1c0,0.36,0.19,0.82,0.71,1
				l1.05,0.37c0.52,0.19,0.94,0.58,1.15,1.09c0.21,0.51,0.2,1.09-0.04,1.59l-0.48,1c-0.24,0.5-0.05,0.95,0.21,1.21
				c0.26,0.26,0.71,0.45,1.21,0.21l1-0.48C287.55,226.1,287.84,226.04,288.13,226.04z M291.34,224.89c-2.57,0-4.65-2.09-4.65-4.65
				c0-2.57,2.09-4.65,4.65-4.65c2.57,0,4.65,2.09,4.65,4.65C295.99,222.8,293.9,224.89,291.34,224.89z M291.34,216.78
				c-1.91,0-3.46,1.55-3.46,3.46s1.55,3.46,3.46,3.46s3.46-1.55,3.46-3.46S293.25,216.78,291.34,216.78z"/>
		</g>
	</g>
	<g id="girl_13_">
		<g>
			<path className="svgc3-2" d="M168.48,101.87c0,0,1.17-1.11,2.03-2.39c0.86-1.28,16.82,0,16.82,0s0.4,1.41,1.37,2.51
				c1.22,1.37-8.86,7.76-8.86,7.76L168.48,101.87z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M179.84,52.73c0,0-2.92-0.7-4.79,0.7c-1.87,1.4-0.84,1.17-1.97,1.81c-1.13,0.64-4.17,1.93-4.4,4.09
				c-0.23,2.16,0.76,3.45,0.58,5.09c-0.18,1.64-3.52,2.67-3.27,6.02c0.16,2.14,2.34,2.53,2.34,2.53l9.04,0.09L179.84,52.73z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M179.29,57.62c0,0-2.7-5.95,3.07-5.88c5.77,0.08,4.68,7.09,4.83,8.81c0.16,1.72,7.68,6.8,3.44,11.63
				c-0.37,0.42-0.68,0.89-1.25,0.89h-7.82L179.29,57.62z"/>
		</g>
		<g>
			<path className="wh3" d="M168.95,87c0,0-6.1,11.23-8.19,11.98c-2.09,0.75-15.27-4.86-15.27-4.86l1.71-2.12
				c0,0,11.01,2.63,11.63,2.45c0.62-0.18,6.08-10.51,6.08-10.51L168.95,87z"/>
		</g>
		<g>
			<path className="wh3" d="M146.6,91.62c2.68,1.28,0.4,3.3-1.55,2.43c-0.94-0.42-4.54-0.98-5.37-5.22c-0.06-0.28,0.99-0.99,1.43-1.3
				c0.12-0.08,0.28-0.05,0.36,0.07c0,0,1.68,3.48,1.97,2.7c0.03-0.07-1.11-2.63,0.58-0.86C145.74,91.23,146.6,91.62,146.6,91.62z"/>
		</g>
		<g>
			<path className="svgc1-6" d="M175.22,73.9c0,0-4.24,0.21-6.34,2.09c-2.1,1.87-5.69,10.55-5.69,10.55l4.7,2.92l2.17-4.1L175.22,73.9z"/>
		</g>
		<g>
			<path className="wh3" d="M188.98,87c0,0,6.1,11.23,8.19,11.98c2.09,0.75,15.27-4.86,15.27-4.86l-1.71-2.12
				c0,0-11.01,2.63-11.63,2.45c-0.62-0.18-6.08-10.51-6.08-10.51L188.98,87z"/>
		</g>
		<g>
			<path className="wh3" d="M211.33,91.62c-2.68,1.28-0.4,3.3,1.55,2.43c0.94-0.42,4.54-0.98,5.37-5.22c0.06-0.28-0.99-0.99-1.43-1.3
				c-0.12-0.08-0.28-0.05-0.36,0.07c0,0-1.68,3.48-1.97,2.7c-0.03-0.07,1.11-2.63-0.58-0.86C212.19,91.23,211.33,91.62,211.33,91.62
				z"/>
		</g>
		<g>
			<path className="svgc1-6" d="M182.71,73.9c0,0,4.24,0.21,6.34,2.09c2.1,1.87,5.69,10.55,5.69,10.55l-4.7,2.92l-2.17-4.1L182.71,73.9z"/>
		</g>
		<g>
			<path className="svgc1-3" d="M182.63,73.89l-3.69-0.1v0l-0.02,0l-0.02,0v0l-3.69,0.1c-2.11,0-5.82,5.91-5.2,11.13
				c0.62,5.22,0.5,14.45,0.5,14.45h8.39h0.04h8.39c0,0-0.13-9.23,0.5-14.45C188.45,79.8,184.73,73.89,182.63,73.89z"/>
		</g>
		<g>
			<path className="wh4" d="M176.56,70.45c0,0-0.06,3.45-1.34,3.45c0,0,0.99,2.92,3.51,2.75c2.51-0.18,3.98-2.92,3.98-2.92
				s-1.22-0.53-1.69-3.74L176.56,70.45z"/>
		</g>
		<g>
			<path className="wh3" d="M173.52,63.17c0,0,1.2,8.84,5.26,8.84c4.05,0,5.13-7.07,4.4-9.92c-0.61-2.4-4.38-6.17-4.38-6.17
				S175.66,61.97,173.52,63.17z"/>
		</g>
		<g>
			<path className="wh3" d="M174.19,63.58c-0.03-0.13-0.12-0.24-0.25-0.28c-0.45-0.15-1.48-0.36-1.74,0.7
				c-0.28,1.17,1.07,2.05,2.05,2.07c0.25,0.01,0.43-0.23,0.38-0.47L174.19,63.58z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M192.34,109.39c0,0,9.84,0.36,10.17,1.26c0.33,0.91,0.52,3.32-0.23,3.4s-9.8,0.96-11.43,0.27
				C189.2,113.64,192.34,109.39,192.34,109.39z"/>
		</g>
		<g>
			<path className="svgc3-1" d="M152.28,101.53c0.44-1.42,2.01-3.95,4.98-3.25c2.98,0.7,34.67,9.99,35.24,11.26
				c0.57,1.28,0.21,5.18-1.42,5.03c-1.63-0.14-37.05-7-38.4-8.84C151.53,104.16,151.99,102.48,152.28,101.53z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M166.49,109.39c0,0-9.84,0.36-10.17,1.26c-0.33,0.91-0.52,3.32,0.23,3.4c0.76,0.08,9.8,0.96,11.43,0.27
				C169.63,113.64,166.49,109.39,166.49,109.39z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M206.55,101.53c-0.44-1.42-2.01-3.95-4.98-3.25c-2.98,0.7-34.67,9.99-35.24,11.26
				c-0.57,1.28-0.21,5.18,1.42,5.03c1.63-0.14,37.05-7,38.4-8.84C207.31,104.16,206.85,102.48,206.55,101.53z"/>
		</g>
	</g>
	<g id="girl_10_">
		<g>
			<path className="svgc3-0" d="M78.44,227.61c0,0,2.34,4.43-0.55,6.99c-2.89,2.56-4.72-4.03-4.72-4.03l3.04-4.92L78.44,227.61z"/>
		</g>
		<g>
			<path className="wh3" d="M85.5,219.23c0,0-2.29,9.42-5.12,9.59c-2.84,0.18-8.27-4.88-8.27-4.88l6.34-8.68L85.5,219.23z"/>
		</g>
		<g>
			<path className="wh3" d="M71.38,244.57c0,0-1.13,8.05,0.36,9.66c1.49,1.6,16.3-3,16.3-3l-0.74-2.66c0,0-11.76,2.39-12.22,1.95
				c-0.46-0.44,2.22-7.65,2.22-7.65L71.38,244.57z"/>
		</g>
		<g>
			<path className="wh3" d="M73.58,223.97c0,0-4.15,6.24-5.16,6.64c-0.13,0.05,0.83,2.92,3.77,3.5c4.09,0.81,4.54-1.78,4.54-1.78
				s-0.99-0.99-1.02-1.08c-0.41-1.23,1.41-5,1.41-5L73.58,223.97z"/>
		</g>
		<g>
			<path className="svgc3-1" d="M68.79,230.49c0,0,2.73,3.36,5.29,3.34c1.41-0.01,1.63-2.58,1.63-2.58s0.69,0.65,2.19,2.44
				c2.4,2.85,4.71,6.86,1.39,9.16c-1,0.69,0.16,13.84,0.16,13.84l-14.84,0.13c0,0-3.45-11.93-2.34-16.98
				C64.08,231.63,66.72,230.5,68.79,230.49"/>
		</g>
		<g>
			<path className="wh3" d="M61.99,244.59c0,0,0.09,8.2,1.95,9.36c1.85,1.16,15.08-6.51,15.08-6.51l-1.07-2.37
				c0,0-10.91,4.72-11.47,4.41c-0.56-0.3,0.78-8.09,0.78-8.09L61.99,244.59z"/>
		</g>
		<g>
			<path className="svgc3-2" d="M66.52,231.15c1.9,1.07,1.47,5.51,0.53,12.88c-0.02,0.14-0.13,0.26-0.27,0.28l-5.12,0.9
				c-0.13,0.02-0.24-0.08-0.24-0.2c-0.02-1.49-0.39-9.65,1.22-11.88C63.33,232.15,65.48,230.56,66.52,231.15z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M84.66,214.6c-1.72,0.24-1.41-3.82-6.82-3.78c-3.76,0.03-5.9,1.29-7.39,5.89c-0.7,2.15-3.89,2.97-6.44,5.46
				c-1.25,1.22-0.43,3.89-1.69,4.58c-7.02,3.85-5.09,10.85-1.36,11.6c1.04,0.21,4.2-1.01,6.09-3.08c1.18-1.29,0.82-3.64,2.33-3.97
				c2.03-0.45,2.71-3.94,3.99-5.02c3.05-2.58,3.45-3.7,3.72-4.21c0,0,0.71-1.46,0.97-1.46c3.56,0.08,7.1-1.18,7.1-1.18
				s-0.07,2.22,1.23,2.12c1.29-0.1,1.44-2.83,1.38-4.04C87.7,216.3,86.42,214.35,84.66,214.6z"/>
		</g>
		<g>
			<path className="wh3" d="M78.48,247.86c-1.65,0.84-2.58-1.72-0.76-2.77c0.88-0.51,2.48-3.05,6.34-1.27
				c0.26,0.12,0.22,1.36,0.22,1.89c0,0.14-0.12,0.25-0.26,0.25c0,0-3.75-0.66-3.28,0.02c0.04,0.06,2.74,0.63,0.35,0.96
				C78.65,247.26,79.09,247.55,78.48,247.86z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M90.43,251.37l-9.79,0.08c-0.18,0-0.27-0.21-0.15-0.34l12.23-12.13c0.04-0.04,0.09-0.06,0.14-0.06l9.79-0.08
				c0.18,0,0.27,0.21,0.15,0.34l-12.23,12.13C90.54,251.35,90.48,251.37,90.43,251.37z"/>
		</g>
		<g>
			<path className="wh3" d="M93.3,248.91c0.2-0.4-0.31-1.32-0.31-1.32c-0.03-0.08-0.15-0.06-0.27,0.04c-0.45,0.35-1.52,1.17-1.56,1.37
				c-0.16,0.79,0,1.72,0.19,1.67C91.88,250.53,93.13,249.23,93.3,248.91z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M74.9,331.13l0.05,3.37c0,0.41,0.28,0.74,0.62,0.74l11-0.09l0-0.41c0-0.33-0.22-0.62-0.54-0.7
				c-1.33-0.36-4.8-1.42-6.53-2.95C76.66,328.56,74.9,331.13,74.9,331.13z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M50.82,325.83l-1.57,2.9c-0.2,0.36-0.11,0.79,0.19,0.95l9.68,5.23l0.2-0.36c0.16-0.29,0.1-0.65-0.13-0.88
				c-0.99-0.96-3.48-3.44-4.25-5.62C53.66,324.48,50.82,325.83,50.82,325.83z"/>
		</g>
		<g>
			<path className="svgc1-0" d="M79.41,256.68l-7.13,0.06c0,0-5,3.88-4.28,11.27c0.57,5.79,6.9,63.11,6.9,63.11l4.58-0.06L79.41,256.68"/>
		</g>
		<g>
			<path className="svgc1-3" d="M76.78,256.7c0.27,31.72-21.85,71.35-21.85,71.35l-4.11-2.14c0,0,15.31-44.07,11.33-56.58
				c-2.11-6.63,2.55-12.53,2.55-12.53"/>
		</g>
		<g>
			<path className="wh3" d="M78.21,221.12c0.05-0.12,0.04-0.26-0.04-0.37c-0.27-0.38-0.97-1.14-1.79-0.44
				c-0.9,0.77-0.32,2.25,0.45,2.83c0.19,0.15,0.47,0.06,0.57-0.16L78.21,221.12z"/>
		</g>
	</g>
	<g id="flower_6_">
		<g>
			<g>
				<path className="svgc1-3" d="M354.94,262.85c0.17-0.27,0.58-0.17,0.6,0.15c0.22,3.29,0.6,13.7-2.96,19.84
					c-2.45,4.23-9.69,11.65-14.36,13.63c-4.67,1.98-6.92,8.26-6.92,8.26s-3.32-15.78,7.06-26.15c4.99-5.2,6.48-7.84,10.31-9.5
					C351.54,267.85,353.93,264.46,354.94,262.85z"/>
			</g>
			<g>
				<path className="svgc1-5" d="M313.61,262.87c-0.17-0.14-0.41,0.02-0.35,0.23c0.43,1.37,1.23,4.58,0.56,8.2
					c-0.86,4.63-2.05,13.54-0.55,19.44c1.49,5.9,11.24,11.93,17.53,10.37c0,0-5.65-3.9-7.76-10.92c-2.11-7.02-0.56-14.3-3.64-19.91
					C317.11,266.12,314.7,263.79,313.61,262.87z"/>
			</g>
			<g>
				<g>
					<path className="svgc1-7" d="M331.38,257.72c-0.03-0.22,0.26-0.33,0.38-0.14c1.55,2.46,5.62,9.51,5.84,15.77
						c0.27,7.73-9.22,24.59-5.8,31.47c0,0-8.06-5-8.95-14.27c-0.89-9.27,7.08-26.31,8.2-28.94
						C331.61,260.27,331.53,258.72,331.38,257.72z"/>
				</g>
				<g>
					<path className="wh5" d="M331.8,305.02c-0.08,0-0.16-0.05-0.19-0.13c-0.03-0.09-3.26-8.61-3.87-12.99
						c-0.65-4.65,3.84-19.45,4.99-22.86c1.4-4.17-0.96-10.01-0.98-10.06c-0.04-0.1,0.01-0.22,0.11-0.26
						c0.1-0.04,0.22,0.01,0.26,0.11c0.1,0.24,2.44,6.03,0.99,10.34c-1.14,3.4-5.61,18.1-4.97,22.68c0.61,4.34,3.82,12.82,3.85,12.9
						c0.04,0.1-0.01,0.22-0.12,0.26C331.85,305.01,331.82,305.02,331.8,305.02z"/>
				</g>
				<g>
					<path className="wh5" d="M329.64,280.56c-0.02,0-0.05,0-0.07-0.01c-2.19-0.78-3.33-6.45-3.38-6.69c-0.02-0.11,0.05-0.21,0.16-0.23
						c0.11-0.02,0.21,0.05,0.23,0.16c0.01,0.06,1.15,5.69,3.12,6.4c0.1,0.04,0.16,0.15,0.12,0.25
						C329.8,280.51,329.72,280.56,329.64,280.56z"/>
				</g>
				<g>
					<path className="wh5" d="M332.45,271.26c-0.11,0-0.17-0.01-0.19-0.01c-0.11-0.01-0.19-0.11-0.17-0.22
						c0.01-0.11,0.11-0.18,0.22-0.17c0.1,0.01,2.33,0.23,3.7-3.27c0.04-0.1,0.16-0.15,0.26-0.11c0.1,0.04,0.15,0.16,0.11,0.26
						C335.11,270.99,333.08,271.26,332.45,271.26z"/>
				</g>
				<g>
					<path className="wh5" d="M332.21,273.27c-0.34,0-0.54-0.05-0.57-0.05c-0.11-0.03-0.17-0.13-0.15-0.24
						c0.03-0.11,0.14-0.17,0.24-0.15c0.02,0.01,2.32,0.51,4.98-2.42c0.07-0.08,0.2-0.09,0.28-0.01c0.08,0.07,0.09,0.2,0.01,0.28
						C334.96,272.94,333.11,273.27,332.21,273.27z"/>
				</g>
				<g>
					<path className="wh5" d="M328.96,283.62c-0.11,0-0.2-0.08-0.2-0.19c0-0.11,0.08-0.2,0.19-0.21c0.04,0,3.84-0.16,6.59-1.84
						c0.09-0.06,0.22-0.03,0.27,0.07c0.06,0.09,0.03,0.22-0.07,0.27C332.91,283.46,329.13,283.61,328.96,283.62
						C328.96,283.62,328.96,283.62,328.96,283.62z"/>
				</g>
				<g>
					<path className="wh5" d="M328.11,293.27c-0.01,0-0.02,0-0.03,0c-4.27-0.65-5.09-4.99-5.09-5.03c-0.02-0.11,0.05-0.21,0.16-0.23
						c0.11-0.02,0.21,0.05,0.23,0.16c0.03,0.17,0.78,4.1,4.76,4.71c0.11,0.02,0.18,0.12,0.17,0.23
						C328.29,293.2,328.2,293.27,328.11,293.27z"/>
				</g>
				<g>
					<path className="wh5" d="M327.75,290.73c-0.01,0-0.01,0-0.02,0c-2.58-0.2-4.31-5.5-4.38-5.72c-0.03-0.1,0.02-0.22,0.13-0.25
						c0.1-0.03,0.22,0.02,0.25,0.13c0.02,0.05,1.72,5.27,4.04,5.45c0.11,0.01,0.19,0.1,0.18,0.21
						C327.94,290.65,327.85,290.73,327.75,290.73z"/>
				</g>
			</g>
			<g>
				<path className="svgc1-0" d="M331.31,304.93c-0.01,0-0.01,0-0.02,0c-0.11-0.01-0.19-0.11-0.18-0.22c1.05-10.63,10.7-19.04,15.88-23.56
					l0.45-0.39c4.49-3.93,5.82-10.85,5.84-10.92c0.02-0.11,0.12-0.18,0.23-0.16c0.11,0.02,0.18,0.12,0.16,0.23
					c-0.01,0.07-1.37,7.13-5.96,11.15l-0.45,0.39c-5.14,4.48-14.71,12.83-15.75,23.3C331.5,304.86,331.41,304.93,331.31,304.93z"/>
			</g>
			<g>
				<path className="svgc1-3" d="M330.81,301.31c-0.01,0-0.02,0-0.04,0c-0.1-0.02-10.14-1.9-13.09-12.04c-1.47-5.03-1.1-8.57-0.77-11.68
					c0.34-3.25,0.63-6.05-1.11-9.82c-0.05-0.1,0-0.22,0.1-0.26c0.1-0.05,0.22,0,0.26,0.1c1.79,3.87,1.49,6.72,1.14,10.03
					c-0.32,3.08-0.69,6.57,0.76,11.53c2.89,9.91,12.68,11.75,12.78,11.76c0.11,0.02,0.18,0.12,0.16,0.23
					C330.98,301.24,330.9,301.31,330.81,301.31z"/>
			</g>
		</g>
		<g id="flowers_pot_9_">
			<g>
				<path className="svgc2-0" d="M334,323.95h-13.55c-0.6,0-1.12-0.43-1.23-1.03l-3.57-19.62h23.15l-3.57,19.62
					C335.12,323.51,334.6,323.95,334,323.95z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M338.49,303.92h-21.9c-1.89,0-3.44-1.55-3.44-3.44v0c0-1.89,1.55-3.44,3.44-3.44h21.9
					c1.89,0,3.44,1.55,3.44,3.44v0C341.93,302.37,340.38,303.92,338.49,303.92z"/>
			</g>
			<g className="wh2">
				<polygon className="svgc3-0" points="315.79,304.09 337.85,308.5 338.68,303.92 315.76,303.92 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path className="svgc3-6" d="M263.01,327.51c-0.05,0-0.09-0.01-0.14-0.02c-0.32-0.08-0.52-0.4-0.44-0.72l10.81-45.95
					c0.08-0.32,0.39-0.52,0.72-0.44c0.32,0.08,0.52,0.4,0.44,0.72l-10.81,45.95C263.53,327.33,263.28,327.51,263.01,327.51z"/>
			</g>
			<g>
				<path className="svgc3-6" d="M224.01,327.51c-0.04,0-0.08,0-0.13-0.01c-0.32-0.07-0.53-0.39-0.46-0.71l10.23-46.78
					c0.07-0.32,0.39-0.53,0.71-0.46c0.32,0.07,0.53,0.39,0.46,0.71l-10.23,46.78C224.53,327.32,224.28,327.51,224.01,327.51z"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc3-3" d="M246.71,327.1c-0.26,0-0.5-0.17-0.58-0.44l-12.84-46.37c-0.09-0.32,0.1-0.65,0.42-0.74
					c0.32-0.09,0.65,0.1,0.74,0.42l12.84,46.37c0.09,0.32-0.1,0.65-0.42,0.74C246.81,327.09,246.76,327.1,246.71,327.1z"/>
			</g>
			<g>
				<path className="svgc3-3" d="M285.96,327.1c-0.27,0-0.51-0.18-0.58-0.45l-11.94-45.88c-0.08-0.32,0.11-0.65,0.43-0.73
					c0.32-0.08,0.65,0.11,0.73,0.43l11.94,45.88c0.08,0.32-0.11,0.65-0.43,0.73C286.06,327.09,286.01,327.1,285.96,327.1z"/>
			</g>
			<g>
				<path className="svgc3-3" d="M273.72,281.31C273.72,281.31,273.72,281.31,273.72,281.31l-39.5-0.42c-0.38,0-0.69-0.32-0.68-0.7
					c0-0.38,0.31-0.68,0.69-0.68c0,0,0,0,0.01,0l39.49,0.42c0.38,0,0.69,0.32,0.68,0.7C274.41,281.01,274.1,281.31,273.72,281.31z"
					/>
			</g>
			<g>
				<path className="svgc3-3" d="M276.87,294.92h-38.82c-0.38,0-0.69-0.31-0.69-0.69c0-0.38,0.31-0.69,0.69-0.69h38.82
					c0.38,0,0.69,0.31,0.69,0.69C277.55,294.61,277.25,294.92,276.87,294.92z"/>
			</g>
			<g>
				<path className="svgc3-3" d="M280.07,308.54h-38.74c-0.38,0-0.69-0.31-0.69-0.69c0-0.38,0.31-0.69,0.69-0.69h38.74
					c0.38,0,0.69,0.31,0.69,0.69C280.76,308.23,280.45,308.54,280.07,308.54z"/>
			</g>
		</g>
	</g>
	<g id="clock_5_">
		<g>
			<g>
				
					<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -78.5878 199.3073)" className="svgc2-7" cx="201.29" cy="194.52" rx="56.2" ry="56.2"/>
			</g>
		</g>
		<g>
			<g>
				<path className="svgc2-0" d="M200.82,149.36c-1.1,0-1.99-0.89-1.99-1.99v-8.57c0-1.1,0.89-1.99,1.99-1.99c1.1,0,1.99,0.89,1.99,1.99
					v8.57C202.82,148.47,201.93,149.36,200.82,149.36z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M177.25,155.68c-0.69,0-1.36-0.36-1.73-1l-4.28-7.42c-0.55-0.95-0.22-2.17,0.73-2.72
					c0.95-0.55,2.17-0.22,2.72,0.73l4.28,7.42c0.55,0.95,0.22,2.17-0.73,2.72C177.93,155.59,177.59,155.68,177.25,155.68z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M159.99,172.94c-0.34,0-0.68-0.09-0.99-0.27l-7.42-4.28c-0.95-0.55-1.28-1.77-0.73-2.72
					c0.55-0.95,1.77-1.28,2.72-0.73l7.42,4.28c0.95,0.55,1.28,1.77,0.73,2.72C161.35,172.58,160.68,172.94,159.99,172.94z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M153.68,196.51h-8.57c-1.1,0-1.99-0.89-1.99-1.99c0-1.1,0.89-1.99,1.99-1.99h8.57
					c1.1,0,1.99,0.89,1.99,1.99C155.67,195.62,154.78,196.51,153.68,196.51z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M152.58,224.37c-0.69,0-1.36-0.36-1.73-1c-0.55-0.95-0.22-2.17,0.73-2.72l7.42-4.28
					c0.95-0.55,2.17-0.22,2.72,0.73c0.55,0.95,0.22,2.17-0.73,2.72l-7.42,4.28C153.26,224.28,152.91,224.37,152.58,224.37z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M172.97,244.76c-0.34,0-0.68-0.09-0.99-0.27c-0.95-0.55-1.28-1.77-0.73-2.72l4.28-7.42
					c0.55-0.95,1.77-1.28,2.72-0.73c0.95,0.55,1.28,1.77,0.73,2.72l-4.28,7.42C174.32,244.4,173.65,244.76,172.97,244.76z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M200.82,252.22c-1.1,0-1.99-0.89-1.99-1.99v-8.57c0-1.1,0.89-1.99,1.99-1.99c1.1,0,1.99,0.89,1.99,1.99
					v8.57C202.82,251.33,201.92,252.22,200.82,252.22z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M228.68,244.76c-0.69,0-1.36-0.36-1.73-1l-4.28-7.42c-0.55-0.95-0.22-2.17,0.73-2.72
					c0.95-0.55,2.17-0.22,2.72,0.73l4.28,7.42c0.55,0.95,0.22,2.17-0.73,2.72C229.36,244.67,229.02,244.76,228.68,244.76z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M249.07,224.37c-0.34,0-0.68-0.09-0.99-0.27l-7.42-4.28c-0.95-0.55-1.28-1.77-0.73-2.72
					c0.55-0.95,1.77-1.28,2.72-0.73l7.42,4.28c0.95,0.55,1.28,1.77,0.73,2.72C250.43,224.01,249.76,224.37,249.07,224.37z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M256.54,196.51h-8.57c-1.1,0-1.99-0.89-1.99-1.99c0-1.1,0.89-1.99,1.99-1.99h8.57
					c1.1,0,1.99,0.89,1.99,1.99C258.53,195.62,257.64,196.51,256.54,196.51z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M241.66,172.94c-0.69,0-1.36-0.36-1.73-1c-0.55-0.95-0.22-2.17,0.73-2.72l7.42-4.28
					c0.95-0.55,2.17-0.22,2.72,0.73c0.55,0.95,0.22,2.17-0.73,2.72l-7.42,4.28C242.34,172.85,242,172.94,241.66,172.94z"/>
			</g>
			<g>
				<path className="svgc2-0" d="M224.4,155.68c-0.34,0-0.68-0.09-0.99-0.27c-0.95-0.55-1.28-1.77-0.73-2.72l4.28-7.42
					c0.55-0.95,1.77-1.28,2.72-0.73c0.95,0.55,1.28,1.77,0.73,2.72l-4.28,7.42C225.76,155.32,225.09,155.68,224.4,155.68z"/>
			</g>
		</g>
		<g>
			<path className="svgc2-0" d="M201.29,141.66c29.19,0,52.86,23.66,52.86,52.86c0,29.19-23.66,52.86-52.86,52.86
				c-29.19,0-52.86-23.66-52.86-52.86C148.43,165.33,172.1,141.66,201.29,141.66 M201.29,134.98c-15.9,0-30.86,6.19-42.1,17.44
				c-11.25,11.25-17.44,26.2-17.44,42.1s6.19,30.86,17.44,42.1c11.25,11.25,26.2,17.44,42.1,17.44c15.9,0,30.86-6.19,42.1-17.44
				c11.25-11.25,17.44-26.2,17.44-42.1s-6.19-30.86-17.44-42.1C232.15,141.17,217.2,134.98,201.29,134.98L201.29,134.98z"/>
		</g>
		<g>
			<circle className="svgc3-0" cx="200.88" cy="194.52" r="5.43"/>
		</g>
		<g>
			<path className="svgc3-0" d="M214.41,218.75c-0.67,0-1.33-0.34-1.71-0.96l-13.52-22.24c-0.28-0.47-0.36-1.03-0.22-1.56
				c0.14-0.53,0.49-0.97,0.97-1.23l33.79-18.33c0.97-0.53,2.18-0.17,2.7,0.8c0.52,0.97,0.17,2.18-0.8,2.7l-31.95,17.33l12.44,20.45
				c0.57,0.94,0.27,2.17-0.67,2.74C215.12,218.66,214.76,218.75,214.41,218.75z"/>
		</g>
		<g>
			<path className="svgc2-0" d="M200.88,197.43c-1.6,0-2.91-1.3-2.91-2.91c0-1.6,1.3-2.91,2.91-2.91c1.6,0,2.91,1.3,2.91,2.91
				C203.79,196.13,202.48,197.43,200.88,197.43z M200.88,192.42c-1.16,0-2.11,0.95-2.11,2.11c0,1.16,0.95,2.11,2.11,2.11
				c1.16,0,2.11-0.95,2.11-2.11C202.99,193.36,202.04,192.42,200.88,192.42z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M199.23,192.81c-0.28,0-0.54-0.14-0.69-0.4l-18.29-31.84c-0.22-0.38-0.09-0.87,0.29-1.09
				c0.38-0.22,0.87-0.09,1.09,0.29l18.29,31.84c0.22,0.38,0.09,0.87-0.29,1.09C199.5,192.78,199.36,192.81,199.23,192.81z"/>
		</g>
	</g>
	<g id="man_15_">
		<g>
			<path className="wh3" d="M259.18,164.45c0,0-6.35,4.66-3.56,11.52c0.22,0.53,4.52,0.7,4.52,0.7s0.57,2.87,0.15,4.14
				c-0.16,0.47-1.62,1.77-1.49,2.22c0.49,1.69,9.55,2.28,7.95-1.01c-1.37-2.82-1.55-5.08-1.55-5.08S265.86,164.45,259.18,164.45z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M272.53,274.66v3.81c0,0.48-0.32,0.87-0.71,0.87h-12.74v-0.48c0-0.38,0.25-0.72,0.62-0.82
				c1.54-0.43,5.55-1.69,7.54-3.48C270.49,271.62,272.53,274.66,272.53,274.66z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M247.87,274.66v3.81c0,0.48-0.32,0.87-0.71,0.87h-12.74v-0.48c0-0.38,0.25-0.72,0.62-0.82
				c1.54-0.43,5.55-1.69,7.54-3.48C245.82,271.62,247.87,274.66,247.87,274.66z"/>
		</g>
		<g>
			<path className="svgc3-0" d="M259.02,157.66c-0.66,0.94-3.99,5.31-1.16,8.01c0,0,0.85-1.11,2.18-0.8c2.62,0.6,4.38,5.5,4.38,5.5
				c-0.28,1.78-0.28,3.94,0.79,6.3c0,0,5.21-0.73,5.21-7c0-6.27-7.17-8.32-8.47-8.77c-1.11-0.38-2.36-2.58-2.71-3.23
				C259.19,157.59,259.08,157.58,259.02,157.66z"/>
		</g>
		<g>
			<polygon className="svgc3-2" points="272.53,211.15 272.53,274.56 267.23,274.56 261.35,225.22 268.97,207.7 			"/>
		</g>
		<g>
			<path className="svgc3-1" d="M272.33,211.46c-9.54,14.09-24.46,61.82-24.48,63.5l-5.35-0.33c0,0,5.8-50.87,10.43-65.59
				C252.93,209.04,272.53,211.15,272.33,211.46z"/>
		</g>
		<g>
			<g>
				<path className="wh3" d="M260.71,193.83l6.8-0.92c0,0-2.45,13.77-4,15.28c-2.82,2.75-18.24,0.61-18.24,0.61l0.31-2.91
					c0.21,0.76,13.05-1.23,13.69-1.78C260.05,203.44,260.71,193.83,260.71,193.83z"/>
			</g>
			<g>
				<path className="svgc1-0" d="M267.83,194.44c0,0,0.13-11.5-4.03-11c-4.16,0.5-3.56,11.04-3.56,11.04S265.26,195.35,267.83,194.44z"/>
			</g>
			<g>
				<path className="wh3" d="M245.27,208.8c3.46,0.96,2.77-2.16,0.31-2.91c-1.19-0.36-5.19-3.37-9.32-0.17
					c-0.27,0.21,0.12,1.69,0.27,2.32c0.04,0.17,0.22,0.27,0.38,0.22c0,0,1.77-1.22,2.58-0.91c0.09,0.03-0.01,1.24,1.21,1.26
					C243.69,208.67,245.27,208.8,245.27,208.8z"/>
			</g>
		</g>
		<g>
			<path className="svgc1-3" d="M266.16,180.66c0,0,4.91,4.56,6.37,14.8c1.8,12.61,0.46,15.33-0.21,16c-0.67,0.67-19.83,0.47-20.17,0.3
				c-1.38-0.71,2.75-28.12,7.55-30.14C260.11,181.44,262.29,184.07,266.16,180.66z"/>
		</g>
		<g>
			<path className="wh3" d="M263.26,169.58c0.02-0.14,0.12-0.25,0.25-0.3c0.45-0.17,1.51-0.44,1.82,0.64c0.34,1.19-1,2.16-2.01,2.23
				c-0.25,0.02-0.45-0.21-0.41-0.46L263.26,169.58z"/>
		</g>
		<g>
			<path className="wh3" d="M256.01,187.93l4.59,5.1c0,0-12.75,5.74-14.88,5.31c-3.86-0.78-10.79-14.72-10.79-14.72l2.58-1.38
				c-0.51,0.6,8.38,10.08,9.18,10.3C247.7,192.8,256.01,187.93,256.01,187.93z"/>
		</g>
		<g>
			<path className="svgc1-6" d="M259.52,194.15c0,0,9.57-6.37,6.82-9.53c-2.75-3.16-11.13,3.28-11.13,3.28S257.32,192.54,259.52,194.15z"/>
		</g>
		<g>
			<path className="wh3" d="M234.94,183.61c1.15,3.4,3.35,1.08,2.58-1.38c-0.37-1.19-0.14-6.19-5.11-7.8
				c-0.33-0.11-1.33,1.05-1.77,1.53c-0.12,0.13-0.1,0.33,0.03,0.44c0,0,2.01,0.77,2.2,1.62c0.02,0.09-1.03,0.69-0.36,1.71
				C234.16,182.23,234.94,183.61,234.94,183.61z"/>
		</g>
	</g>
</g>
</svg>
)
}
