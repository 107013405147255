import CallEndedComponent from './CallEnded';
import IncomingComponent from './Incoming';
import InProgressComponent from './InProgress';
import OtherPickedUpComponent from './OtherPickedUp';
import 'react-toastify/dist/ReactToastify.css';

const getCompany = (callData: any) => {
  if(callData.caller.relations.length){
    let firstGroup: any = null;
    let firstCompany: any = null;
    
    callData.caller.relations.forEach((relation: any) => {
      if(relation.tag === 'group' && !firstGroup){
        firstGroup = relation;
      }
      if(relation.tag === 'company' && !firstCompany){
        firstCompany = relation;
      }
    });
    if(firstGroup){
      return firstGroup;
    }
    if(firstCompany){
      return firstCompany;
    }
  }
  return null;
}

export const toastOptions = {
  autoClose: false,
  closeButton: true,
  closeOnClick: false,
  draggable: false
};

interface Props { callData: any; }

export const RenderEnded = ({ callData }: Props) => ( <CallEndedComponent callData={callData} getCompany={getCompany} /> );
export const RenderOtherPickedUp = ({ callData }: Props) => ( <OtherPickedUpComponent callData={callData} getCompany={getCompany} /> );
export const RenderIncoming = ({ callData }: Props) => ( <IncomingComponent callData={callData} getCompany={getCompany} /> );
export const RenderInProgress = ({ callData }: Props) => ( <InProgressComponent callData={callData} getCompany={getCompany} /> );
