import type { AppDispatch } from 'src/store/index';
import { createTag, deleteTag, updateTag } from  'src/slices/lists/tags';
import type { Tag } from 'src/types/api/tag'; 
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_tag', (data: any) => {
    const tag = data.data.response as Tag;
    dispatch(createTag(tag));
  });
  channel.listen('.update_tag', (data: any) => {
    const tag = data.data.response as Tag;
    dispatch(updateTag(tag));
  });
  channel.listen('.delete_tag', (data: any) => {
    const tag = data.data.response as Tag;
    dispatch(deleteTag(tag));
  });
  channel.listen('.delete_tags', (data: any) => {
    const tags = data.data.response as Tag[];
    tags.map((tag) => {
      dispatch(deleteTag(tag));
    });
  });
  // refresh or set tags ??
}