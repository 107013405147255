import { createMessageTemplate, deleteMessageTemplate, updateMessageTemplate } from 'src/slices/lists/messageTemplates';
import type { AppDispatch } from 'src/store/index';
import { MessageTemplate } from 'src/types/api/messages';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch
) => {
  channel.listen('.create_message_template', (data: any) => {
    const messageTemplate = data.data.response as MessageTemplate;
    dispatch(createMessageTemplate(messageTemplate));
  });
  channel.listen('.update_message_template', (data: any) => {
    const messageTemplate = data.data.response as MessageTemplate;    
    dispatch(updateMessageTemplate(messageTemplate));
  });
  channel.listen('.delete_message_template', (data: any) => {
    const messageTemplate = data.data.response as MessageTemplate;
    dispatch(deleteMessageTemplate(messageTemplate));
  });
}