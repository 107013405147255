import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'src/i18n';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import { closeForm } from 'src/slices/forms';
import { useDispatch, useSelector } from 'src/store';
import * as yup from 'yup';
import { DEFAULT_VALUES } from './constants';
import { FormValues } from './types';
import FirstUpperField from 'src/components/forms/fields/FirstUpper';
import { Box, FormControlLabel, MenuItem, Switch, TextField } from '@mui/material';
import MoneyField from 'src/components/general/MoneyField';
import NumberFormat from 'react-number-format';
import validations from 'src/components/forms/validations';
import { 
  createTemplateSubscriptionRequest,
  CreateTemplateSubscriptionRequestData,
  updateTemplateSubscriptionRequest,
  UpdateTemplateSubscriptionRequestData
} from 'src/requests/templates';
import { useAxiosOptions } from 'src/hooks/general/useAxios';

const yup_schema = yup.object({
  title: validations.default_string,
  duration: validations.default_number
});

const TemplateSubscription = () => {  
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();

  const currentTemplate = useSelector(state => state.current.template);
  const templates = useSelector(state => state.lists.templates);

  const {
    open: formOpen,
    initialValues,
    updateId
  } = useSelector(state => state.forms.templateSubscription);

  const {
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(yup_schema)
  });

  const watchValues = watch();

  const handleClose = () => {
    dispatch(closeForm('templateSubscription'));
  }

  const handleValidSubmit = async (data: FormValues) => {
    if(!watchValues.template_id){
      console.error('Template id not set !');
      return;
    }
    
    // update
    if(updateId){
      // const putData: UpdateTemplateSubscriptionRequestData = {}
      // call
      const putData: UpdateTemplateSubscriptionRequestData = {
        title: data.title,
        description: data.description,
        duration: data.duration ? data.duration : 1,
        recurrence: data.recurrence,
        price: data.price ? data.price : 0,
        is_default: data.is_default
      };

      try {
        await updateTemplateSubscriptionRequest(
          watchValues.template_id, 
          updateId,
          putData, 
          dispatch, 
          axiosOptions.apiConfig,
          currentTemplate
        );
        handleClose();
      }catch(e){
        console.log(e);
      }
      
      return;
    }

    const postData: CreateTemplateSubscriptionRequestData = {
      title: data.title,
      description: data.description,
      duration: data.duration ? data.duration : 1,
      recurrence: data.recurrence,
      price: data.price ? data.price : 0,
      is_default: data.is_default
    };

    try {
      await createTemplateSubscriptionRequest(
        watchValues.template_id, 
        postData, 
        dispatch, 
        axiosOptions.apiConfig,
        currentTemplate
      );
      handleClose();
    }catch(e){
      console.log(e);
    }
  }

  // on open form reset values if isn't edit 
  useEffect(() => {
    if (formOpen && !updateId) {
      reset({ ...DEFAULT_VALUES, ...initialValues });

      if(!initialValues.template_id && formOpen){
        console.error('Template id not set !');
      }
    }
  }, [formOpen]);

  const templateSubscriptionToUpdate = useMemo(() => {
    if(initialValues.template_id){
      const template = templates.find(template => template.id === initialValues.template_id);
      if(template){
        return template.subscriptions.find(subscription => subscription.id === updateId);
      }
    }
    return undefined;
  }, [templates, updateId, initialValues.template_id]);

  useEffect(() => {
    if(templateSubscriptionToUpdate){
      reset({
        ...DEFAULT_VALUES,
        template_id: templateSubscriptionToUpdate.id,
        title: templateSubscriptionToUpdate.title,
        description: templateSubscriptionToUpdate.description ? templateSubscriptionToUpdate.description : '',
        duration: templateSubscriptionToUpdate.duration,
        price: templateSubscriptionToUpdate.price,
        is_default: templateSubscriptionToUpdate.is_default,
        recurrence: templateSubscriptionToUpdate.recurrence,
        ...initialValues
      });
    }
  }, [templateSubscriptionToUpdate]);

  return (
    <DuotoneDialog
      open={formOpen}
      title={updateId ?
        `${t('lang.update_subscription')}` :
        `${t('lang.new_subscription')}`
      }
      onClose={handleClose}
      // subtitle={(
      //   <Typography variant='body2'>
      //     {t('lang.status')}:
      //     <span style={{ marginLeft: 5, marginRight: 5 }}>
      //       <OriginalLabel
      //         colorCode={selectedAppointment ? selectedAppointment.status_color : theme.palette.error.main}
      //       >
      //         {selectedAppointment ? selectedAppointment.status : t('lang.open')}
      //       </OriginalLabel>
      //     </span>
      //   </Typography>
      // )}
      submitButtonProps={{
        disabled: isSubmitting
      }}
      onSubmit={handleSubmit(handleValidSubmit)}
    >
      <div>
        <Box mt={3}>
          <Controller 
            name='title'
            control={control}
            render={({ field }) => (
              <FirstUpperField
                label={`${t('lang.title')}`}
                fullWidth
                error={!!errors.title}
                helperText={errors.title?.message}
                {...field}
              />
            )}
          />
        </Box>

        <Box mt={3}>
          <Controller 
            name='description'
            control={control}
            render={({ field }) => (
              <TextField
                label={`${t('lang.description')}`}
                fullWidth
                variant='standard'
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={3}>
          <MoneyField
            value={watchValues.price}
            onChange={(value) => setValue(`price`, value)}
            fieldProps={{
              label: `${t('lang.price')}`,
              decimalScale: 2,
              error: !!errors.price,
              helperText: errors.price?.message
            }}
          />
        </Box>
        <Box mt={3} style={{ display: 'flex' }}>
          <NumberFormat
            value={watchValues.duration}
            onValueChange={(values) => {
              const newVal = values.floatValue ? values.floatValue : null;
              setValue(`duration`, newVal);
            }}
            customInput={TextField}
            allowNegative={false}
            variant='standard'
            fullWidth
            label={`${t('lang.duration')}`}
            autoComplete='off'
            error={!!errors.duration}
            helperText={errors.duration?.message}
            style={{ marginRight: 24 }}
          />

          <Controller 
            name='recurrence'
            control={control}
            render={({ field }) => (
              <TextField
                label={`${t('lang.recurrence')}`}
                fullWidth
                variant='standard'
                select
                {...field}
              >
                <MenuItem value='day'>{t('lang.day')}</MenuItem>
                <MenuItem value='month'>{t('lang.month')}</MenuItem>
                <MenuItem value='year'>{t('lang.year')}</MenuItem>
              </TextField>
            )}
          />
        </Box>
        <Box mt={3}>
          <FormControlLabel
            onChange={() => setValue('is_default', !watchValues.is_default)}
            control={(
              <Switch checked={watchValues.is_default} />
            )}
            label={`${t('lang.is_default')}`}
            labelPlacement="end"
          />
        </Box>
      </div>
    </DuotoneDialog>
  )
}
export default TemplateSubscription;