import type { AppDispatch } from 'src/store';
import { setError } from 'src/slices/misc';
import hostNameIsIpv4 from 'src/utils/general/hostnameIsIpv4';

// custom
const CURRENT_APP_VERSION = 1;

export const isDev = () => (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

const storageUrl = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : `https://${window.location.host}/storage/`;
const url = process.env.REACT_APP_BASE_URL ? `${process.env.REACT_APP_BASE_URL}/v${CURRENT_APP_VERSION}` : 
  `https://${window.location.host}/api/v${CURRENT_APP_VERSION}/`;

export const socketUrl = () => {
  if(process.env.REACT_APP_SOCKET_URL){
    return process.env.REACT_APP_SOCKET_URL;
  }

  const hostname = window.location.hostname.split('.').slice(-2).join('.');
  if(hostname === 'zakelijktest.nl' || hostname === 'localhost' || hostNameIsIpv4(window.location.hostname)){
    return 'zakelijktest.nl:22222';
  }
  return 'zakelijkcrm.nl:22222';
};

export const apiConfig = {
  url,
  storage_url: storageUrl,
  // CHANGE ANY TYPE !!!
  getOptions: (axiosOptions: any, dispatch?: AppDispatch) => ({
    baseURL: url,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${axiosOptions.apiToken}`,
      'X-Socket-Id': axiosOptions.socketId
    },
    validateStatus: (status: number) => {
      if(status === 401){
        localStorage.removeItem('API_TOKEN');
        if(axiosOptions.apiToken){
          axiosOptions.setApiToken({
            ...axiosOptions.apiToken,
            token: null
          });
        }
      }

      const success = status >= 200 && status < 300;
      if(!success && !!dispatch){
        // disp
        dispatch(setError({
          message: `Er is iets fout gegaan: ${status}`,
          open: true
        }));
      }
      return success;
    },
  }),
  preLoginOptions: {
    baseURL: url,
    headers: {
      Accept: 'application/json',
    }
  }
};

interface ImageUrls {
  background: string;
  favicon_light: string;
  favicon_dark: string;
  logo_light: string;
  logo_dark: string;
  background_company: string;
  logo_company: string;
  
  root: string;
}

export const imageUrls = (id: string): ImageUrls => ({
  background: `${storageUrl}profiles/${id}/img/background.jpg`,

  favicon_light: `${storageUrl}profiles/${id}/img/favicon_light.png`,
  favicon_dark: `${storageUrl}profiles/${id}/img/favicon_dark.png`,
  logo_light: `${storageUrl}profiles/${id}/img/logo_light.png`,
  logo_dark: `${storageUrl}profiles/${id}/img/logo_dark.png`,

  // new
  background_company: `${storageUrl}profiles/${id}/img/background_company.jpg`,
  logo_company: `${storageUrl}profiles/${id}/img/logo_company.png`,

  root: `${storageUrl}profiles/${id}/img/`,
});

interface ImageThumbnails {
  background: string;
  favicon_light: string;
  favicon_dark: string;
  logo_light: string;
  logo_dark: string;
}

export const imageThumbnails = (id: string): ImageThumbnails => ({
  background: `${storageUrl}profiles/${id}/timg/background.jpg`,
  favicon_light: `${storageUrl}profiles/${id}/timg/favicon_light.png`,
  favicon_dark: `${storageUrl}profiles/${id}/timg/favicon_dark.png`,
  logo_light: `${storageUrl}profiles/${id}/timg/logo_light.png`,
  logo_dark: `${storageUrl}profiles/${id}/timg/logo_dark.png`
});

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};
