import { yupResolver } from '@hookform/resolvers/yup';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from 'react-router';
import Avatar from 'src/components/general/Avatar';
import ConfirmDialog from 'src/components/general/ConfirmDialog';
import ContactPickerDialog, { SelectedContactCard } from 'src/components/general/ContactPickerDialog';
import { DateTimePicker } from 'src/components/general/DateTimePicker_v3';
import CustomDialog from 'src/components/general/Dialog';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import { ActionButton, ActionButtonGroup } from 'src/components/general/DuotoneDialog/ActionButton';
import PermissionChecker from 'src/components/general/PermissionChecker';
import { useAxiosOptions } from "src/hooks/general/useAxios";
import useCalendarOverlap from 'src/hooks/general/useCalendarOverlap';
import useClaim from 'src/hooks/general/useClaim';
import useCurrency from 'src/hooks/general/useCurrency';
import { useTranslation } from 'src/i18n';
import {
  CreateAppointmentData,
  UpdateAppointmentData,
  createAppointment as createAppointmentCall,
  deleteAppointment as deleteAppointmentCall,
  updateAppointment as updateAppointmentCall
} from "src/requests/appointments";
import { closeForm } from 'src/slices/forms';
import { useDispatch, useSelector } from "src/store";
import { Service } from "src/types/api/service";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import ServiceRow from './ServiceRow';
import { DEFAULT_VALUES } from "./constants";
import { FormValues } from "./types";
import StatusLabel from './StatusLabel';
import SendAppointmentConfirmationEmailDialog from './SendAppointmentConfirmationEmailDialog';
import { Appointment as AppointmentType } from 'src/types/api/appointment';
import { ContactSmall } from 'src/types/api/contact';

const yup_schema = yup.object({
  startDate: yup.mixed().required('error.required'),
  services: yup.array().of(
    yup.object().shape({
      id: yup.string(), // to validation from file ??
      employee: yup.string().required('error.required'), // to validation from file ??
      duration: yup.number().required('error.required'), // to validation from file ??
      children: yup.array().of(yup.object().shape({
        id: yup.string(),
        duration: yup.number().required('error.required'),
        afterWorkDuration: yup.number().nullable(),
      }))
    })
  ).min(1, 'required')
}).required();

const Appointment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();
  const navigate = useNavigate();

  const { numberToPriceString } = useCurrency();

  const { overlaps: overlapsCheck } = useCalendarOverlap();
  
  const defaultOverlapsDialog = { open: false, cb: () => {} };
  const [overlapsDialog, setOverlapsDialog] = useState<{
    open: boolean;
    cb: Function;
  }>(defaultOverlapsDialog);

  // For email dialog?
  const [createdAppointment, setCreatedAppointment] = useState<AppointmentType | null>(null);
  const [updatedAppointment, setUpdatedAppointment] = useState<AppointmentType | null>(null);

  const {
    open: formOpen,
    initialValues,
    updateId: selectedId
  } = useSelector(state => state.forms.appointment);

  const handleClose = () => {
    dispatch(closeForm('appointment'));
  }

  const {
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(yup_schema)
  });
  
  const servicesFieldArrayController = useFieldArray({
    control,
    name: 'services',
    keyName: 'rowKey'
  });

  const { append: appendServices } = servicesFieldArrayController;

  const watchValues = watch();

  const appointments = useSelector(state => state.lists.appointments);
  const stateServices = useSelector(state => state.lists.services.services);
  const contacts = useSelector(state => state.lists.contacts);
  const users = useSelector(state => state.lists.users);

  const currentAppointmentDeletedServices = useMemo(() => {
    const selectedAppointment = appointments.find((appointment) => appointment.id === selectedId);
    if (selectedAppointment) {
      let services: Service[] = [];
      selectedAppointment.services.forEach((service) => {
        if (!!service.service.deleted_at) {
          services.push(service.service);
        }
      });
      return services;
    }
    return [];
  }, [appointments, selectedId]);

  const allServices = [...stateServices, ...currentAppointmentDeletedServices];
  const findService = (service_id: string): Service | undefined => {
    return allServices.find((service) => service.id === service_id);
  };

  const resetServices = () => {
    setValue('services', [{ 
      rowKey: uuidv4(), 
      id: null, 
      duration: null, 
      afterWorkDuration: null, 
      price: null,
      employee: null 
    }]);
  }

  // // add empty service if length is 0
  useEffect(() => {
    if (!watchValues.services.length) {
      resetServices();
    }
  }, [watchValues])

  // set values or clear on open
  useEffect(() => {
    if (formOpen && !selectedId) {
      reset({ ...DEFAULT_VALUES, ...initialValues });
    }
  }, [formOpen]); // initialDate ???

  const appointmentToUpdate = useMemo(() => 
    appointments.find(appointment => appointment.id === selectedId)
  , [appointments, selectedId]);
  useEffect(() => {
    if(appointmentToUpdate){
      console.log(appointmentToUpdate);

      const serviceValues: FormValues['services'] = appointmentToUpdate.services.map((service) => ({
        rowKey: service.id,
        id: service.service.id,
        duration: service.duration,
        afterWorkDuration: service.after_work_duration,
        price: service.price,
        employee: service.user_id,
        children: service.children.map(childService => ({
          id: childService.service.id,
          duration: childService.duration,
          afterWorkDuration: childService.after_work_duration
        }))
      }));

      reset({
        ...DEFAULT_VALUES,
        services: serviceValues,
        startDate: dayjs.unix(appointmentToUpdate.start_date),
        contactId: appointmentToUpdate.contact_id,
        description: appointmentToUpdate.note ? appointmentToUpdate.note : '',
        noShow: appointmentToUpdate.no_show,
        ...initialValues
      });
    }
  }, [appointmentToUpdate]);

  const selectedAppointment = useMemo(() => {
    return appointments.find((appointment) => selectedId && appointment.id === selectedId);
  }, [appointments, selectedId])

  const totalPrice = useMemo(() => {
    const services = watchValues.services.map((value) => value.id ? findService(value.id) : undefined);    
    return services.reduce((prev, current) => current ? prev + current.price : prev, 0);
  }, [watchValues]);

  // TODO: validation

  const handleValidSubmit = async (
    values: FormValues, 
    e: any, 
    ignoreOverlaps: boolean = false
  ) => {
    const start = watchValues.startDate;
    if(!start){
      return;
    }


    // overlaps check
    if(!ignoreOverlaps){
      let overlaps = false;

      let nextUnixStart = start.unix();
      values.services.forEach(service => {
        if(!service.duration || !service.employee){
          return;
        }

        const serviceUser = users.find(user => service.employee === user.id);

        if(service.children?.length){
          service.children.forEach(serviceChild => {
            if(
              typeof serviceChild.duration !== 'number' || 
              typeof serviceChild.afterWorkDuration !== 'number'
            ){
              throw new Error("Service child duration or after work duration is not a number !");
            }

            const serviceDurationSeconds = serviceChild.duration * 60;
            const serviceAfterWorkDurationSeconds = serviceChild.afterWorkDuration * 60;

            const result = overlapsCheck(
              nextUnixStart,
              nextUnixStart + serviceDurationSeconds,
              serviceUser ? [serviceUser] : [], 
              service.rowKey ? { appointmentService: [service.rowKey] } : {}
            )

            if(Object.values(result).find(x => x.length)){
              overlaps = true;
            }

            nextUnixStart += serviceDurationSeconds + serviceAfterWorkDurationSeconds;
          });

        }else{
          const serviceDurationSeconds = service.duration * 60;

          const result = overlapsCheck(
            nextUnixStart,
            nextUnixStart + serviceDurationSeconds,
            serviceUser ? [serviceUser] : [], 
            service.rowKey ? { appointmentService: [service.rowKey] } : {}
          );

          nextUnixStart += serviceDurationSeconds;

          if(Object.values(result).find(x => x.length)){
            overlaps = true;
          }
        }
      });

      if(overlaps){
        setOverlapsDialog({ 
          open: true, 
          cb: handleSubmit((values, e) => handleValidSubmit(values, e, true)) 
        })
        return;
      }
    }


    let createData: CreateAppointmentData = {
      start,
      services: [],
      contact_id: values.contactId,
      description: values.description
    };
    values.services.forEach((service) => {
      if(service.id && typeof service.duration === 'number' && service.employee){
        let serviceToPush: any = { 
          id: service.id, 
          duration: service.duration, 
          member: service.employee,
          price: typeof service.price === 'number' ? service.price : 0
        };

        if(service.children?.length){
          serviceToPush.children = service.children.map(childService => ({
            id: childService.id,
            duration: childService.duration, 
            after_work_duration: childService.afterWorkDuration ? childService.afterWorkDuration : 0
          }));

          serviceToPush.duration = service.children.reduce((prev, current) => 
            prev + (current.duration ? current.duration : 0) + (current.afterWorkDuration ? current.afterWorkDuration : 0)
          , 0);
        }

        createData.services.push(serviceToPush); 
      }
    });

    if(!!selectedId){
      const updateData: UpdateAppointmentData = { ...createData, no_show: watchValues.noShow }

      const dateTimeChanged = (watchValues.startDate && appointmentToUpdate) ? 
        !watchValues.startDate.isSame(dayjs.unix(appointmentToUpdate.start_date), 'minute') : false;

      await updateAppointmentCall(selectedId, updateData, dispatch, axiosOptions.apiConfig)
      .then((appointment) => {
        if(appointment.contact_id && dateTimeChanged){
          setUpdatedAppointment(appointment);
        }else{
          handleClose();
        }
      });
    }else{
      await createAppointmentCall(createData, dispatch, axiosOptions.apiConfig)
      .then((appointment) => {
        if(appointment.contact_id){
          setCreatedAppointment(appointment);
        }else{
          handleClose();
        }
      });
    }
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDelete = async () => {
    setDeleteDialogOpen(false);
    if(selectedId){
      await deleteAppointmentCall(selectedId, dispatch, axiosOptions.apiConfig)
      .then(handleClose);
    }
  }

  const { claimUser, currentUserHasFirstClaim } = useClaim(selectedId ? selectedId : null);

  const disableAllFields = !!(selectedId && !currentUserHasFirstClaim);

  const [contactPickerOpen, setContactPickerOpen] = useState(false);

  const selectedContact = useMemo(() => {
    return contacts.find(contact => contact.id === watchValues.contactId);
  }, [contacts, watchValues.contactId]);

  // const handleCheckout = async () => {
  //   if(!selectedAppointment)
  //     return;
  
  //   // Contact is set automatically !

  //   dispatch(resetCheckout());

  //   selectedAppointment.services.forEach((service) => {
  //     dispatch(createCheckoutRow({
  //       id: uuidv4(),
  //       item_id: service.service.id,
  //       type: 'appointment',
  //       count: 1,
  //       discount: 0,
  //       appointment_id: selectedAppointment.id,
  //       divergentPrice: service.price
  //     }));
  //   });

  //   handleClose();

  //   navigate('/register');
  // }

  const handleContactClick = (contact: ContactSmall) => {
    handleClose();
    navigate(`contacts/${contact.id}`);
  }

  return (
    <DuotoneDialog
      open={formOpen}
      title={selectedId ? 
        `${t('lang.update_appointment')}` : 
        `${t('lang.new_appointment')}`
      }
      onClose={handleClose}
      subtitle={(
        <StatusLabel 
          appointment={selectedAppointment ? selectedAppointment : null} 
          isNoShow={watchValues.noShow}  
        />
      )}
      submitButtonProps={{
        disabled: disableAllFields || isSubmitting
      }}
      onSubmit={handleSubmit(handleValidSubmit)}
      alert={selectedId ? {
        open: !currentUserHasFirstClaim && !!claimUser,
        title: claimUser ? `${claimUser.contact.name} ${t('lang.is_editing_task')}` : undefined,
        startComponent: !claimUser ? null : (
          <Avatar
            name={claimUser.contact.name}
            user_id={claimUser.id}
            size='small'
          />
        )
      } : undefined}
    >
      <div style={{ minHeight: 600 }}>
        <Box mt={3}>
          <DateTimePicker
            value={watchValues.startDate}
            onChange={(value) => setValue('startDate', value)}
            error={!!errors.startDate}
            disabled={disableAllFields}
          />
        </Box>
        <Box mt={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HandshakeOutlinedIcon />
            <Typography
              variant='h6'
              color='textPrimary'
              style={{ marginLeft: 10, lineHeight: 1.7 }}
            >
              {t('lang.services')} 
            </Typography>
          </div>
        </Box>
        <Box mt={1.5}>
          {/* Typography Services ??? */}
          <Grid container spacing={3} alignItems={'center'}>
            {/* 
              //TODO: SubServices row !!! 
            */}
            {watchValues.services.map((serviceValue, index) => {
              const service = serviceValue.id ? findService(serviceValue.id) : null;
              return (
                <ServiceRow
                  key={serviceValue.rowKey}
                  index={index}
                  service={service ? service : null}
                  serviceValue={serviceValue}
                  error={!!(errors.services && errors.services[index] && !!errors.services[index])}
                  servicesFieldArrayController={servicesFieldArrayController}
                  setValue={setValue}
                  disabled={disableAllFields}
                  resetServices={resetServices}
                />
              )
            })}
            <Grid item xs={12}>
              <div style={{ width: '100%', display: 'inline-flex' }}>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    // onClick={handleAddServiceRow}
                    onClick={() => appendServices({ 
                      rowKey: uuidv4(), 
                      id: null, 
                      duration: null, 
                      employee: null, 
                      afterWorkDuration: null, 
                      price: null, 
                      children: [] 
                    })}
                    size='small'
                    color='primary'
                    style={{ cursor: 'pointer', width: 'fit-content' }}
                    disabled={disableAllFields}
                  >
                    {`+ ${t('lang.add_service')}`}
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Controller
            name='description'
            control={control}
            render={({ field }) => (
              <TextField
                label={`${t('lang.description')}`}
                variant='standard'
                fullWidth
                disabled={disableAllFields}
                multiline
                {...field}
              />
            )}
          />
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingBottom: '0px'
          // borderBottom: `1px solid ${theme.palette.divider}` 
        }}
      >
        <ActionButtonGroup 
          title={`${t('lang.contact')}`}
          disableMarginTop
        >
          {selectedContact ? (
            <SelectedContactCard 
              contact={selectedContact}
              onDelete={() => setValue('contactId', null)}
              disabled={disableAllFields}
              onTitleClick={() => handleContactClick(selectedContact)}
            />
          ) : (
            <ActionButton
              icon='mdiAccountOutline'
              onClick={() => setContactPickerOpen(true)}
              disabled={disableAllFields}
            >
            {t('lang.select_contact')}
          </ActionButton>
          )}
          {/* 
            // TODO: required error + message?
          */}
        </ActionButtonGroup>
        {selectedId && (
          <ActionButtonGroup
            title={`${t('lang.actions')}`}
          >
            <PermissionChecker 
              permissions={['read_register']}
            >
              <ActionButton
                icon='mdiCartOutline'
                // onClick={() => setDeleteDialogOpen(true)}
                // onClick={handleCheckout}
                onClick={() => {}}
                // disabled={disableAllFields || isSubmitting || appointmentToUpdate?.has_invoice_lines}
                disabled
              >
              {`${t('lang.checkout')}`}
            </ActionButton>
            </PermissionChecker>
            <ActionButton
              icon='mdiCalendarRemoveOutline'
              onClick={() => setValue('noShow', !watchValues.noShow)}
              disabled={disableAllFields || isSubmitting}
            >
              {`${t('lang.no_show')}`}
            </ActionButton>
            <ActionButton
              icon='mdiDeleteOutline'
              onClick={() => setDeleteDialogOpen(true)}
              disabled={disableAllFields || isSubmitting || appointmentToUpdate?.has_invoice_lines}
              isDelete
            >
              {`${t('lang.delete')}`}
            </ActionButton>
          </ActionButtonGroup>
        )}
        <div style={{ marginTop: 'auto', display: 'flex', alignItems: 'center' }}>
          {!!watchValues.startDate && (
            <div>
              <Typography
                variant='caption'
                color='GrayText'
              >
                {t('lang.date')}
              </Typography>
            </div>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <Typography
              variant='caption'
              color='GrayText'
            >
              {t('lang.price')}
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!!watchValues.startDate && (
            <div>
              <Typography variant='h4'>
                {watchValues.startDate.format('DD-MM-YYYY')}
              </Typography>
            </div>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <Typography variant='h4'>
              {numberToPriceString(totalPrice)}
            </Typography>
          </div>
        </div>
        {!!watchValues.startDate && (
          <div>
            <Typography variant='body2'>
              {watchValues.startDate.format('HH:mm')}
            </Typography>
          </div>
        )}
        <ContactPickerDialog
          open={contactPickerOpen}
          onClose={() => setContactPickerOpen(false)}
          onSelect={(value) => setValue('contactId', value.id)}
          selectedContactIds={[]}
          title={`${t('lang.select_contact')}`}
        />
        <CustomDialog
          open={deleteDialogOpen}
          onSubmit={handleDelete}
          onClose={() => setDeleteDialogOpen(false)}
          title={`${t('lang.delete')}`}
          submitButtonText={`${t('lang.delete')}`}
          isDelete
        >
          <Typography>{t('lang.are_you_sure')}</Typography>
        </CustomDialog>
        <ConfirmDialog
          open={overlapsDialog.open}
          onConfirm={() => {
            overlapsDialog.cb();
            setOverlapsDialog(defaultOverlapsDialog);
          }}
          onCancel={() => setOverlapsDialog(defaultOverlapsDialog)}
          titleText={'Overlapt...'}
          contentText={'Let op: als je doorgaat wordt er een ander evenement overlapt'}
        />
        <SendAppointmentConfirmationEmailDialog 
          appointment={createdAppointment || updatedAppointment}
          onClose={() => {
            setCreatedAppointment(null);
            setUpdatedAppointment(null);
            handleClose();
          }}
          isAppointmentMoved={!!updatedAppointment}
        />
      </div>
    </DuotoneDialog>
  )
}
export default Appointment;