import axios from 'axios';
import jsFileDownload from 'js-file-download';
import { useEffect } from 'react';
import { useAxiosOptions } from 'src/hooks/general/useAxios';
import { deleteDownload, updateDownload, setDownloads } from 'src/slices/dataTransfer';
import { useDispatch, useSelector } from 'src/store';
import type { Download } from 'src/types/api/dataTransfer';

export const DownloadHandler = ({children}: any) => {
  useDownloadHandler();
  return children;
}

const useDownloadHandler = () => {
  const axiosOptions = useAxiosOptions();
  const dispatch = useDispatch();
  const { downloads } = useSelector((state) => state.dataTransfer);

  const handleProgressChange = (e: any, download: Download) => {
    const percentage = Math.round(e.loaded / download.size * 100);
    dispatch(updateDownload({
      ...download,
      progress: percentage,
      downloading: true
    }))
  }

  const handleDownload = (download: Download) => {
    dispatch(updateDownload({
      ...download,
      downloading: true
    }));

    axios.get(`files/${download.id}/download`, {
      onDownloadProgress: (e) => handleProgressChange(e, download),
      ...axiosOptions.apiConfig,
      responseType: 'blob'
    })
    .then((response) => {
      jsFileDownload(response.data, download.name);
      dispatch(updateDownload({
        ...download,
        finished: true,
        progress: 100
      }))
    })
    .catch((error) => {
      dispatch(deleteDownload(download));
    });

  }

  useEffect(() => {
    if(downloads.length){
      const anyDownloading = downloads.find((download) => download.downloading && !download.finished);
      // console.log(anyDownloading);

      if(!anyDownloading){
        const download = downloads.find((download) => !download.downloading && !download.finished);
        console.log(download);
        if(download){
          handleDownload(download);
        }else{
          dispatch(setDownloads([])); // MAYBE ALSO IF uploads.length === 0 ??
        }
      }
    }
  }, [downloads]);

}

export default useDownloadHandler;