import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'src/store';
import type { User } from 'src/types/api/user';
import getInitials from 'src/utils/general/getInitials';
import dayjs from 'dayjs';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    minWidth: '340px'
  },
  avatar: {
    height: 32,
    width: 32,
    fontSize: '15px',
    color: theme.palette.primary.contrastText
  }
}));

interface RenderTimeProps {
  created_at: string;
  currentUser: User;
}

const RenderTime = React.memo(({ 
  created_at, 
  currentUser
}: RenderTimeProps) => {
  const [timePassed, setTimePassed] = useState(dayjs(created_at).fromNow());
  useEffect(() => {
    const messageInterval = setInterval(() => {
      setTimePassed(dayjs(created_at).fromNow());
    }, 10000);
    return () => {
      clearInterval(messageInterval);
    }
  }, []);

  return (
    <Typography
      noWrap
      color="textSecondary"
      variant="caption"
    >
      {timePassed}
    </Typography>
  );
});

interface Props {
  target: any;
  receiver_id: string;
  content: string;
  created_at: string;
  currentUser: User;
}

const Message = ({
  target,
  receiver_id,
  content,
  created_at,
  currentUser,
}: Props) => {
  const { classes } = useStyles();

  const avatars = useSelector(state => state.misc.avatars);

  const theme = useTheme();

  return (
    <div
      className={classes.root}
    >
      <Box
        display="flex"
        ml={target.id === receiver_id ? 'auto' : 0}
      >
          <Avatar
            className={classes.avatar}
            style={target.id === receiver_id ? {backgroundColor: theme.palette.primary.main} : {}}
            src={target.id === receiver_id ? 
              (avatars[currentUser.contact.id] && `data:image/jpeg;base64,${avatars[currentUser.contact.id]}`)
              :
              (avatars[target.id] && `data:image/jpeg;base64,${avatars[target.id]}`)
            }
          >
            {/* 
            // @ts-ignore */}
            {target.id === receiver_id ? getInitials(currentUser.contact.name) : getInitials(target.name)}
          </Avatar>
        <Box ml={2}>
          <Box
            bgcolor={target.id === receiver_id ? 'secondary.main' : 'background.default'}
            color={target.id === receiver_id ? 'secondary.contrastText' : 'text.primary'}
            py={1}
            px={2}
            style={{borderRadius: 4}}
            boxShadow={1}
          >
            <Box>
              <Typography
                color="inherit"
                variant="body1"
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}
              >
                {content}
              </Typography>
            </Box>
          </Box>
          <Box
            mt={1}
            display="flex"
            justifyContent="flex-end"
          >
            <RenderTime
              created_at={created_at}
              currentUser={currentUser}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default React.memo(Message);