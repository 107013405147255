import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'src/store';
import type { PresenceUser } from 'src/types/api/presenceUser';

export const usePageUsers = (show_own_user = false) => {
  const myLocation = useLocation();
  const ownId = useSelector(state => state.general.user?.contact.id);
  const presenceUsers = useSelector(state => state.misc.presence);

  const usersOnPage = useMemo(() => {
    let newUsersOnPage: PresenceUser[] = [];
    presenceUsers.map((user) => {
      if(user.id === ownId && !show_own_user){
        return;
      }

      if(typeof(user.location) === 'string'){
        if(user.location === myLocation.pathname){
          newUsersOnPage.push(user);
        }
      }
    });
    return newUsersOnPage;
  }, [presenceUsers]);

  return usersOnPage;
}