import { Grid, TextField, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { UseFieldArrayReturn, UseFormSetValue } from "react-hook-form";
import NumberFormat from "react-number-format";
import AvatarGroupButton from "src/components/general/AvatarGroupButton";
import DotsMenu from "src/components/general/DotsMenu";
import MoneyField from "src/components/general/MoneyField";
import SelectServiceDialog from "src/features/service/SelectServiceDialog";
import { useTranslation } from 'src/i18n';
import { useSelector } from "src/store";
import { Service } from "src/types/api/service";
import { FormValues } from '../types';
import ChildServiceRow from "./ChildServiceRow";

interface Props {
  index: number;
  service: Service | null;
  serviceValue: {
    rowKey: string;
    id: string | null;
    duration: number | null;
    afterWorkDuration: number | null;
    price: number | null;
    employee: string | null;
    children?: {
      id: string | null;
      duration: number | null;
      afterWorkDuration: number | null;
    }[];
  };
  error: boolean;
  servicesFieldArrayController: UseFieldArrayReturn<FormValues, "services", "rowKey">;
  setValue: UseFormSetValue<FormValues>;
  disabled?: boolean;
  resetServices:() => void;
}

const ServiceRow = ({
  index,
  service,
  serviceValue,
  error,
  servicesFieldArrayController,
  setValue,
  disabled = false,
  resetServices
}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const stateServices = useSelector(state => state.lists.services.services);
  // const stateSubServices = useSelector(state => state.lists.services.subServices);

  const stateParentServices = useMemo(() => stateServices.filter(service => !service.is_child), [stateServices]);
  const stateChildServices = useMemo(() => stateServices.filter(service => service.is_child), [stateServices]);

  const { remove, fields } = servicesFieldArrayController;

  const getUserCanPerformService = (user_id: string) => 
    !service || (service.assignable_to_all || service.assignables.includes(user_id));

  // TODO ?!?!?
  const showWarningInTextField = useMemo(() => {
    if(serviceValue.employee){
      return !getUserCanPerformService(serviceValue.employee);
    }
    return false;
  }, [serviceValue.employee, service]);

  const handleDeleteRow = () => {
    if(fields.length > 1){
      remove(index);
      return;
    }
    resetServices();
  }

  const handleServiceChange = (service_id: string) => {
    const service = stateParentServices.find(service => service.id === service_id);  
    if(service){
      setValue(`services.${index}.id`, service.id);
      setValue(`services.${index}.duration`, service.duration);
      setValue(`services.${index}.afterWorkDuration`, service.after_work_duration);
      setValue(`services.${index}.price`, service.price);
      setValue(`services.${index}.children`, []);
    
      const hasSubServices = service.child_ids.length !== 0;
      if(hasSubServices){
        const childServices = service.child_ids.map(child_id => stateChildServices.find(childService => childService.id === child_id));
        const nonEmptyChildServices = childServices.filter(x => x) as Service[];
        nonEmptyChildServices.forEach((childService, childServiceIndex) => {
          setValue(`services.${index}.children.${childServiceIndex}.id`, childService.id);
          setValue(`services.${index}.children.${childServiceIndex}.duration`, childService.duration);
          setValue(`services.${index}.children.${childServiceIndex}.afterWorkDuration`, childService.after_work_duration);
        });
      }
    }
  }

  const getSubServicesTotalDurationStr = () => {
    if(!serviceValue.children){
      return '-';
    }
    const sum = serviceValue.children.reduce((prev, current) => 
      prev + (current.duration ? current.duration : 0) + (current.afterWorkDuration ? current.afterWorkDuration : 0)
    , 0)
    return `${sum}`;
  }

  const [selectServiceDialogOpen, setSelectServiceDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={3}>
        {!!(service && service.deleted_at) ? (
          <TextField
            label={`${t('lang.service')}`}
            value={service.title}
            variant='standard'
            disabled
            fullWidth
            error={error}
          />
        ) : (
          <TextField
            label={`${t('lang.service')}`}
            value={service ? service.title : ''}
            variant='standard'
            // select
            fullWidth
            // onChange={(e) => handleServiceChange(e.target.value)}
            onChange={() => {}}
            error={error}
            disabled={disabled}
            onClick={() => setSelectServiceDialogOpen(true)}
            focused={false}
            InputProps={{
              readOnly: true
            }}
          />
        )}  
      </Grid>
      <Grid item xs={1.5}>
        {!!(serviceValue.children?.length) ? (
          <TextField 
            value={getSubServicesTotalDurationStr()}
            fullWidth
            variant='standard'
            label={`${t('lang.duration')}`}
            disabled
          />
        ) : (
          <NumberFormat 
            value={serviceValue.duration}
            onValueChange={(values) => {
              const newVal = values.floatValue ? values.floatValue : null;
              setValue(`services.${index}.duration`, newVal);
            }}
            customInput={TextField}
            allowNegative={false}
            variant='standard'
            fullWidth
            label={`${t('lang.duration')}`}
            autoComplete='off'
            error={error}
            disabled={disabled}
          />
        )}
      </Grid>
      <Grid item xs={1.5}>
        {!!(serviceValue.children?.length) ? (
          <TextField 
            value={'0'}
            fullWidth
            variant='standard'
            label={`${t('lang.after_work_duration')}`}
            disabled
          />
        ) : (
          <NumberFormat 
            value={0}
            onValueChange={(values) => {
              const newVal = values.floatValue ? values.floatValue : null;
              setValue(`services.${index}.afterWorkDuration`, newVal);
            }}
            customInput={TextField}
            allowNegative={false}
            variant='standard'
            fullWidth
            label={`${t('lang.after_work_duration')}`}
            autoComplete='off'
            error={error}
            // disabled={disabled}
            disabled
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <MoneyField
          value={serviceValue.price ? serviceValue.price : undefined}
          onChange={(value) => setValue(`services.${index}.price`, typeof value === 'number' ? value : null)}
          fieldProps={{
            label: `${t('lang.price')}`,
            error: error,
            disabled: disabled
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <div style={{ height: 48 }}>
          <AvatarGroupButton 
            selectedUserIds={serviceValue.employee ? [serviceValue.employee] : []}
            setSelectedUserIds={(user_ids) => {
              const value = user_ids.length ? user_ids[0] : null;
              setValue(`services.${index}.employee`, value);
            }}
            singleUser
            dark
            error={error}
          />
        </div>
        
        {/* <UserPicker
          value={serviceValue.employee}
          onChange={(val) => setValue(`services.${index}.employee`, val)}
          TextFieldProps={{
            label: `${t('lang.employee')}`,
            error: error,
            disabled: disabled
          }}
          startAdornment={showWarningInTextField ? (
            <Tooltip title={`${t('lang.user_cant_perform_service')}`}>
              <WarningAmberOutlinedIcon 
                fontSize='small'
                color='warning'
                style={{ 
                  marginRight: theme.spacing(1)
                }}
              />
            </Tooltip>
          ) : undefined}
          autocompleteProps={{
            disabled: disabled,
            renderOption: (props, option) => {
              const userCanPerformService = getUserCanPerformService(option.id);
              return (
                <li {...props} key={option.id}>
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    {option.contact.name}
                    {!userCanPerformService && (
                      <span style={{ marginLeft: 'auto' }}>
                        <Tooltip title={`${t('lang.user_cant_perform_service')}`}>
                          <WarningAmberOutlinedIcon 
                            fontSize='small'
                            color='warning'
                            style={{ marginTop: 4 }}
                          />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                </li>  
              )
            }
          }}
        /> */}
      </Grid>
      <Grid item xs={1}>
        <DotsMenu 
          menuItems={[
            {
              icon: 'delete',
              content: `${t('lang.delete')}`,
              onClick: handleDeleteRow,
              disabled: disabled
            }
          ]}
        />
      </Grid>
      {!!serviceValue.children?.length && serviceValue.children.map((childService, l_index) => (
        <ChildServiceRow 
          key={l_index}
          childService={childService}
          onChangeDuration={(value) => setValue(`services.${index}.children.${l_index}.duration`, value)}
          onChangeAfterWorkDuration={(value) => setValue(`services.${index}.children.${l_index}.afterWorkDuration`, value)}
          error={error}
        />
      ))}
      <SelectServiceDialog 
        open={selectServiceDialogOpen}
        onClose={() => setSelectServiceDialogOpen(false)}
        onSelect={(service) => handleServiceChange(service.id)}
        selectedServiceIds={[]}
      />
    </>
  )
}
export default ServiceRow;