import type { User } from 'src/types/api/user';

export function userHasAnyPermissions(user: User, permissionTags: string[]){
  let hasPermission = false;
  user.role.permissions.map((permission) => {
    const result = permissionTags.find((permissionTag) => permissionTag === permission.tag);
    if(!!result){
      hasPermission = true;
    }
  });
  return hasPermission;
}

export function userHasAllPermissions(user: User, permissionTags: string[]){
  let count = 0;
  user.role.permissions.map((permission) => {
    let permissionFound = permissionTags.find((tag) => tag === permission.tag);
    if(permissionFound){
      count++;
    }
  });
  return count === permissionTags.length;
}