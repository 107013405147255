import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, useDispatch } from 'src/store';
import type { Absence } from 'src/types/api/absence';

const initialState: Absence[] = [];

const slice = createSlice({
  name: 'absences',
  initialState,
  reducers: {
    setAbsences(state: Absence[], action: PayloadAction<Absence[]>){
      return action.payload;
    },
    createAbsence(state: Absence[], action: PayloadAction<Absence>){
      state.push(action.payload);
    },
    updateAbsence(state: Absence[], action: PayloadAction<Absence>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteAbsence(state: Absence[], action: PayloadAction<Absence>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setAbsences = (data: Absence[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAbsences(data));
}

export const createAbsence = (data: Absence): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createAbsence(data));
}

export const updateAbsence = (data: Absence): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAbsence(data));
}

export const deleteAbsence = (data: Absence): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteAbsence(data));
}

export default slice;