import { TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from 'src/i18n';
import { DatePicker } from "src/components/general/DateTimePicker_v3";

interface Props {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  textFieldProps?: Partial<TextFieldProps>;
  disabled?: boolean;
}

const Birthday = ({
  value,
  onChange,
  textFieldProps,
  disabled = false
}: Props) => {
  const {t} = useTranslation();
  return (
    <DatePicker
      value={value}
      onChange={onChange}
      textFieldProps={{ 
        label: `${t('lang.birthday')}`, 
        fullWidth: true,
        ...textFieldProps
      }}
      staticDatePickerProps={{
        maxDate: dayjs().startOf('day'),
      }}
      disabled={disabled}
    />
  )
}
export default Birthday;