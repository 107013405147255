import { Button, Grid, Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'src/i18n';
import SvgFinished from 'src/assets/svg/Launch';
import { useSelector } from "src/store";

interface Props {
  submitting: boolean;
  onClose: () => void;
}

const Finished = ({
  submitting,
  onClose
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const group = useSelector(state => state.general.group);

  // Transition right after render
  const [shouldTransition, setShouldTransition] = useState(false);
  useEffect(() => {
    setShouldTransition(true);
  }, []);

  if(!group){
    return null;
  }

  return (
    <Box
      style={{ 
        opacity: shouldTransition ? 1 : 0,
        transition: 'opacity 2s',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        style={{
          textAlign: 'center',
          width: '100%',
          maxWidth: 550
        }}
      >
      <Typography 
        variant='h1'
        style={{
          padding: `${theme.spacing(1.5)} 0px`
        }}
      >
        {t(`lang.stepper_finished`)}

      </Typography>
      <Typography variant='body2'>
        {t(`description.stepper_finished`)}
      </Typography>
        <SvgFinished
            color1light={group.color1_light}
            color2light={group.color2_light}
            color3light={group.color3_light}
            color1dark={group.color1_dark}
          color2dark={group.color2_dark}
            color3dark={group.color3_dark}
          />
        <Button 
          variant='contained'
          disabled={submitting}
          onClick={() => onClose()}
          color='primary'
          style={{ 
            marginTop: 'auto',
            padding: '10px 30px',
            fontSize: '20%'
          }}
        >
        <Typography 
        variant='h4'
        >
        {t(`lang.lets_get_started`)}
        </Typography>
      </Button> 
    </Box>
  </Box>
  )
}
export default Finished;