import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Invoice } from 'src/types/api/financial/invoice';
import type { Vatrate } from 'src/types/api/financial/vatrate';

type InvoiceWorkflow = {
  id: string;
}

type InvoicingState = {
  invoices: {
    data: Invoice[],
    filteredTotalRows: number;
    totalRows: number | null;
  };
  vatrates: Vatrate[];
  workflows: InvoiceWorkflow[];
}

const initialState: InvoicingState = {
  invoices: {
    data: [],
    filteredTotalRows: 0,
    totalRows: null
  },
  vatrates: [],
  workflows: []
};

const slice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    setInvoices(state: InvoicingState, action: PayloadAction<Invoice[]>){
      state.invoices.data = action.payload;
    },
    createInvoice(state: InvoicingState, action: PayloadAction<Invoice>){
      state.invoices.data.push(action.payload);
    },
    updateInvoice(state: InvoicingState, action: PayloadAction<Invoice>){
      state.invoices.data = state.invoices.data.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteInvoice(state: InvoicingState, action: PayloadAction<Invoice>){
      state.invoices.data = state.invoices.data.filter((val) => val.id !== action.payload.id);
    },
    insertInvoice(state: InvoicingState, action: PayloadAction<{ data: Invoice; index: number; }>){
      state.invoices.data.splice(action.payload.index, 0, action.payload.data)
    },

    setInvoiceFilteredTotalRows(state: InvoicingState, action: PayloadAction<number>){
      state.invoices.filteredTotalRows = action.payload;
    },
    setInvoiceTotalRows(state: InvoicingState, action: PayloadAction<number>){
      state.invoices.totalRows = action.payload;
    },

    setVatrates(state: InvoicingState, action: PayloadAction<Vatrate[]>){
      state.vatrates = action.payload;
    },
    createVatrate(state: InvoicingState, action: PayloadAction<Vatrate>){
      state.vatrates.push(action.payload);
    },
    updateVatrate(state: InvoicingState, action: PayloadAction<Vatrate>){
      state.vatrates = state.vatrates.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteVatrate(state: InvoicingState, action: PayloadAction<Vatrate>){
      state.vatrates = state.vatrates.filter((val) => val.id !== action.payload.id);
    },
    
    setInvoiceWorkflows(state: InvoicingState, action: PayloadAction<InvoiceWorkflow[]>){
      state.workflows = action.payload;
    },
    createInvoiceWorkflow(state: InvoicingState, action: PayloadAction<InvoiceWorkflow>){
      state.workflows.push(action.payload);
    },
    updateInvoiceWorkflow(state: InvoicingState, action: PayloadAction<InvoiceWorkflow>){
      state.workflows = state.workflows.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteInvoiceWorkflow(state: InvoicingState, action: PayloadAction<InvoiceWorkflow>){
      state.workflows = state.workflows.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setInvoices = (data: Invoice[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoices(data));
}
export const createInvoice = (data: Invoice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createInvoice(data));
}
export const updateInvoice = (data: Invoice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateInvoice(data));
}
export const deleteInvoice = (data: Invoice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteInvoice(data));
}
export const insertInvoice = (data: { data: Invoice, index: number }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.insertInvoice(data));
}

export const setInvoiceFilteredTotalRows = (data: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoiceFilteredTotalRows(data));
}
export const setInvoiceTotalRows = (data: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoiceTotalRows(data));
}

export const setVatrates = (data: Vatrate[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setVatrates(data));
}
export const createVatrate = (data: Vatrate): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createVatrate(data));
}
export const updateVatrate = (data: Vatrate): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateVatrate(data));
}
export const deleteVatrate = (data: Vatrate): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteVatrate(data));
}

export const setInvoiceWorkflows = (data: InvoiceWorkflow[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoiceWorkflows(data));
}
export const createInvoiceWorkflow = (data: InvoiceWorkflow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createInvoiceWorkflow(data));
}
export const updateInvoiceWorkflow = (data: InvoiceWorkflow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateInvoiceWorkflow(data));
}
export const deleteInvoiceWorkflow = (data: InvoiceWorkflow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteInvoiceWorkflow(data));
}

export default slice;