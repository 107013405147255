import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Activity } from 'src/types/api/activity';

const initialState: Activity[] = [];

const slice = createSlice({
  name: 'global_activity',
  initialState,
  reducers: {
    setGlobalActivity(state: Activity[], action: PayloadAction<Activity[]>){
      return action.payload;
    },
    createGlobalActivity(state: Activity[], action: PayloadAction<Activity>){
      // if any set?
      state.unshift(action.payload);
    },
    updateGlobalActivity(state: Activity[], action: PayloadAction<Activity>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteGlobalActivity(state: Activity[], action: PayloadAction<Activity>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setGlobalActivity = (data: Activity[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGlobalActivity(data));
}

export const createGlobalActivity = (data: Activity): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createGlobalActivity(data));
}

export const updateGlobalActivity = (data: Activity): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateGlobalActivity(data));
}

export const deleteGlobalActivity = (data: Activity): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteGlobalActivity(data));
}

export default slice;