import { 
  createSeveralWorkingHours, 
  createWorkingHours, 
  createWorkingHoursColor, 
  deleteSeveralWorkingHours, 
  deleteWorkingHours, 
  deleteWorkingHoursColor, 
  updateSeveralWorkingHours, 
  updateWorkingHours,
  updateWorkingHoursColor,
  clearWorkingHours
} from 'src/slices/lists/workingHours';
import { WorkingHours, WorkingHoursColor } from 'src/types/api/workingHours';
import type { AppDispatch } from 'src/store/index';
import type { AnyAction, Store } from 'redux';
import type { RootState } from 'src/store';
import dayjs from 'dayjs';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>
) => {
  channel.listen('.create_working_hours', (data: any) => {
    const workingHours = data.data.response as WorkingHours | WorkingHours[];
    if(Array.isArray(workingHours)){
      dispatch(createSeveralWorkingHours(workingHours));      
      return;
    }
    dispatch(createWorkingHours(workingHours));
  });
  channel.listen('.update_working_hours', (data: any) => {
    console.log('TODO: FIRST CHECK IF DATE ALREADY IN STATE, ELSE RETURN !!!');

    const workingHours = data.data.response as WorkingHours | WorkingHours[];
    if(Array.isArray(workingHours)){
      dispatch(updateSeveralWorkingHours(workingHours));      
      return;
    }
    dispatch(updateWorkingHours(workingHours));
  });
  channel.listen('.delete_working_hours', (data: any) => {
    console.log('TODO: FIRST CHECK IF DATE ALREADY IN STATE, ELSE RETURN !!!');

    const workingHours = data.data.response as WorkingHours | WorkingHours[];
    if(Array.isArray(workingHours)){
      dispatch(deleteSeveralWorkingHours(workingHours));      
      return;
    }
    dispatch(deleteWorkingHours(workingHours));
  });

  channel.listen('.clear_working_hours', (data: any) => {
    dispatch(clearWorkingHours());
  });

  // COLORS

  channel.listen('.create_working_hours_color', (data: any) => {
    const workingHoursColor = data.data.response as WorkingHoursColor;
    dispatch(createWorkingHoursColor(workingHoursColor));
  });
  channel.listen('.update_working_hours_color', (data: any) => {
    const workingHoursColor = data.data.response as WorkingHoursColor;
    dispatch(updateWorkingHoursColor(workingHoursColor));
  });
  channel.listen('.delete_working_hours_color', (data: any) => {
    const workingHoursColor = data.data.response as WorkingHoursColor;
    dispatch(deleteWorkingHoursColor(workingHoursColor));
  });

}