import { toast } from 'react-toastify';
import { AnyAction, Store } from 'redux';
import {
  RenderEnded, RenderIncoming, RenderInProgress,
  RenderOtherPickedUp, toastOptions
} from 'src/custom/CallsHandler';
import type { RootState } from 'src/store';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  store: Store<RootState, AnyAction>
) => {
  console.log('call_ringing');
  channel.listen('.call_ringing', (data: any) => {
    data.data.target.map((target: any) => {
      if (data.data.direction === "inbound" && target.account_number == store.getState().general.user?.internal_number) { //INBOUND ONLY
        toast(
          <RenderIncoming
            callData={data.data}
          />, 
          // @ts-ignore
          { ...toastOptions, toastId: data.data.call_id });
      }
    });
  });
  channel.listen('.call_in_progress', (data: any) => {
    console.log('call_in_progress');
    data.data.target.map((target: any) => {
      if (target.number == store.getState().general.user?.internal_number) {
        toast.update(data.data.call_id, {
          render: <RenderInProgress callData={data.data} />,
          type: toast.TYPE.SUCCESS
        });
      } else {
        toast.update(data.data.call_id, {
          render: <RenderOtherPickedUp callData={data.data} />,
          type: toast.TYPE.INFO,
          autoClose: 5000
        });
      }
    });
  });
  channel.listen('.call_ended', (data: any) => {
    console.log('call_ended');
    toast.update(data.data.call_id, {
      render: <RenderEnded callData={data.data} />,
      type: toast.TYPE.ERROR,
      autoClose: 5000
    });
  });
}