import { useTheme, lighten, darken } from '@mui/material';



export default function SvgComponent({ color1light, color2light, color3light, color1dark, color2dark, color3dark }) {
  const theme = useTheme();
  const changeColor = theme.name === 'LIGHT' ? lighten : darken;
  const conChangeColor = theme.name === 'LIGHT' ? darken: lighten;
  return (
    <svg id="salon_type" data-name="Salon type" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <style>
        {
            `
			.svgc1-0{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0)}}
			.svgc1-1{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.1)}}
			.svgc1-2{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.2)}}
			.svgc1-3{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.3)}}
			.svgc1-4{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.4)}}
			.svgc1-5{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.5)}}
			.svgc1-6{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.6)}}
			.svgc1-7{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.7)}}
			.svgc1-8{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.8)}}
			.svgc1-9{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9)}}
			.svgc1-10{fill:${changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 1)}}

			.svgc2-0{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0)}}
			.svgc2-1{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.1)}}
			.svgc2-2{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.2)}}
			.svgc2-3{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.3)}}
			.svgc2-4{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.4)}}
			.svgc2-5{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.5)}}
			.svgc2-6{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.6)}}
			.svgc2-7{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.7)}}
			.svgc2-8{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.8)}}
			.svgc2-9{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.9)}}
			.svgc2-10{fill:${changeColor(theme.name === 'LIGHT' ? color2light : color2dark, 1)}}

			.svgc3-0{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0)}}
			.svgc3-1{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.1)}}
			.svgc3-2{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.2)}}
			.svgc3-3{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.3)}}
			.svgc3-4{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.4)}}
			.svgc3-5{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.5)}}
			.svgc3-6{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.6)}}
			.svgc3-7{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.7)}}
			.svgc3-8{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.8)}}
			.svgc3-9{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0.9)}}
			.svgc3-10{fill:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 1)}}
			
			.st0{opacity:0.5;}
			.st1{opacity:0.3;}
            .st3{fill:#F8BC9E;}
			.st4{fill:#F6AB86;}
			.st5{fill:none;stroke:${changeColor(theme.name === 'LIGHT' ? color3light : color3dark, 0)};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.st6{fill:#D7CD5D;}
            .st7{fill:#C7BF57;}
            .st8{fill:#A5654B;}
            .st9{fill:${conChangeColor(theme.name === 'LIGHT' ? color2light : color2dark, 0.3)}}
			.st10{fill:${conChangeColor(changeColor(theme.name === 'LIGHT' ? color1light : color1dark, 0.9),0.1)}}
            `
          }
         
        </style>
        <g id="background_00000067198468787465549960000004984690848885045387_">
	<g>
		<path className="svgc1-9" d="M209.4,69.08c-48.77,4.22-76.97,51.02-86.74,46.23c-17.53-8.6-2.24-29.83-33.91-35.91
			c-31.81-6.11,0.19,26.84-42.33,42.55c-26.57,9.82,10.43,74.44-0.62,101.35c-12.86,31.31,14.89,52.99,46.7,57.65
			c33.66,4.93,44.35-25.33,85.14-27.86c52.38-3.25,66.72,35.72,106.29,25.69c36.72-9.31,67.07-53.7,60.85-81.51
			c-4.68-20.91-27.57-21.37-30.83-42.52c-4.23-27.36,31.96-40.24,30.02-68.22c-1.9-27.49-50.23-3.26-81.51-8.07
			C239.1,74.87,251.19,65.46,209.4,69.08z"/>
	</g>
</g>
<g>
	<path className="svgc1-4" d="M317.33,279.09l0.12-0.8c1.12,0.15,2.24,0.23,3.35,0.24l-0.01,0.81C319.64,279.32,318.48,279.24,317.33,279.09
		z M324.14,278.35c1.1-0.13,2.21-0.33,3.29-0.6l0.22,0.79c-1.12,0.28-2.26,0.49-3.4,0.62L324.14,278.35z M310.64,277.45l0.3-0.76
		c1.07,0.38,2.15,0.71,3.21,0.97l-0.21,0.79C312.84,278.18,311.73,277.85,310.64,277.45z M330.61,276.74
		c1.02-0.4,2.04-0.87,3.02-1.39l0.42,0.71c-1.01,0.54-2.06,1.02-3.12,1.43L330.61,276.74z M336.46,273.62
		c0.89-0.61,1.77-1.29,2.62-2.01l0.57,0.6c-0.87,0.74-1.77,1.44-2.69,2.07L336.46,273.62z M341.47,269.35
		c0.75-0.77,1.48-1.6,2.17-2.46l0.68,0.5c-0.71,0.88-1.45,1.73-2.22,2.52L341.47,269.35z M345.59,264.25
		c0.6-0.89,1.18-1.82,1.72-2.77l0.75,0.39c-0.55,0.97-1.14,1.92-1.76,2.82L345.59,264.25z M348.82,258.6
		c0.46-0.97,0.9-1.97,1.29-2.98l0.8,0.28c-0.4,1.03-0.84,2.05-1.31,3.03L348.82,258.6z M351.19,252.57
		c0.32-1.02,0.61-2.07,0.86-3.12l0.83,0.18c-0.26,1.06-0.55,2.13-0.88,3.17L351.19,252.57z M352.7,246.28
		c0.18-1.06,0.32-2.13,0.43-3.2l0.85,0.08c-0.11,1.08-0.25,2.18-0.43,3.25L352.7,246.28z M353.32,239.87c0.01-0.4,0.02-0.8,0.02-1.2
		c0-0.68-0.02-1.36-0.05-2.02l0.85-0.04c0.03,0.68,0.05,1.37,0.05,2.06c0,0.41-0.01,0.82-0.02,1.22L353.32,239.87z M352.51,230.25
		l0.84-0.16c0.22,1.06,0.4,2.15,0.53,3.24l-0.85,0.09C352.89,232.36,352.72,231.29,352.51,230.25z M350.69,224.06l0.8-0.28
		c0.4,1.01,0.75,2.06,1.06,3.12l-0.82,0.22C351.43,226.08,351.07,225.05,350.69,224.06z M347.79,218.27l0.73-0.41
		c0.58,0.93,1.13,1.91,1.62,2.9l-0.77,0.35C348.89,220.13,348.36,219.18,347.79,218.27z M343.83,213.09l0.64-0.54
		c0.76,0.82,1.49,1.68,2.16,2.56l-0.69,0.48C345.28,214.73,344.57,213.88,343.83,213.09z M338.91,208.72l0.52-0.64
		c0.91,0.67,1.8,1.38,2.63,2.12l-0.58,0.59C340.66,210.07,339.8,209.37,338.91,208.72z M333.22,205.28l0.39-0.72
		c1.03,0.51,2.03,1.06,2.99,1.63l-0.46,0.69C335.21,206.31,334.22,205.78,333.22,205.28z M327,202.78l0.27-0.77
		c1.1,0.35,2.18,0.74,3.22,1.15l-0.33,0.75C329.14,203.51,328.08,203.13,327,202.78z M320.46,201.16l0.16-0.8
		c1.14,0.21,2.27,0.45,3.35,0.72l-0.21,0.79C322.7,201.61,321.58,201.37,320.46,201.16z M313.75,200.3l0.07-0.81
		c1.15,0.09,2.3,0.21,3.41,0.35l-0.11,0.81C316.03,200.5,314.89,200.39,313.75,200.3z M307.79,200.08v-0.81
		c0.88,0,1.76,0.01,2.61,0.04l-0.03,0.81C309.53,200.09,308.66,200.08,307.79,200.08z"/>
</g>
<g>
	<path className="svgc1-4" d="M279.91,124.4l0.85-0.09c0.17,1.46,0.25,2.59,0.28,3.28l-0.85,0.03C280.15,126.94,280.08,125.84,279.91,124.4z
		 M278.78,118.02l0.83-0.18c0.25,1.07,0.48,2.15,0.67,3.21l-0.84,0.14C279.25,120.14,279.03,119.07,278.78,118.02z M149.15,120.64
		c0.69-0.9,1.4-1.76,2.11-2.58l0.66,0.52c-0.7,0.81-1.4,1.67-2.08,2.55L149.15,120.64z M153.52,115.59c0.8-0.82,1.62-1.6,2.43-2.32
		l0.58,0.59c-0.8,0.71-1.6,1.48-2.39,2.28L153.52,115.59z M276.91,111.81l0.81-0.27c0.37,1.02,0.72,2.07,1.04,3.12l-0.82,0.22
		C277.62,113.85,277.28,112.82,276.91,111.81z M158.56,111.13c0.93-0.7,1.87-1.34,2.81-1.91l0.46,0.68
		c-0.92,0.56-1.84,1.19-2.74,1.87L158.56,111.13z M190.68,110l0.12-0.8c1.08,0.15,2.15,0.19,3.32,0.13l0.05,0.81
		c-0.36,0.02-0.72,0.03-1.06,0.03C192.28,110.16,191.49,110.11,190.68,110z M197.41,108.86c1.07-0.25,2.13-0.6,3.14-1.04l0.35,0.74
		c-1.06,0.46-2.16,0.83-3.29,1.09L197.41,108.86z M184.3,108.04l-0.19-0.08l0.34-0.75l0.19,0.08c0.95,0.39,1.94,0.79,2.95,1.13
		l-0.28,0.77C186.27,108.84,185.27,108.43,184.3,108.04z M274.28,105.87l0.76-0.37c0.51,0.96,0.99,1.96,1.44,2.98l-0.79,0.32
		C275.25,107.8,274.77,106.82,274.28,105.87z M164.38,107.59c1.05-0.49,2.13-0.92,3.2-1.27l0.28,0.77c-1.04,0.34-2.08,0.75-3.1,1.23
		L164.38,107.59z M203.44,106.25c0.87-0.57,1.73-1.22,2.62-1.99l0.57,0.6c-0.92,0.79-1.8,1.46-2.71,2.05L203.44,106.25z
		 M177.74,106.04l0.12-0.8c1.11,0.15,2.22,0.41,3.39,0.78l-0.27,0.77C179.86,106.43,178.8,106.19,177.74,106.04z M170.93,105.47
		c0.62-0.11,1.25-0.21,1.88-0.27c0.53-0.06,1.06-0.1,1.57-0.12l0.03,0.81c-0.49,0.02-1,0.06-1.51,0.11
		c-0.61,0.07-1.22,0.15-1.81,0.26L170.93,105.47z M270.81,100.35l0.7-0.47c0.65,0.88,1.29,1.81,1.88,2.75l-0.73,0.42
		C272.07,102.12,271.45,101.21,270.81,100.35z M208.46,102c0.67-0.68,1.38-1.45,2.26-2.42l0.65,0.53c-0.88,0.98-1.61,1.76-2.28,2.44
		L208.46,102z M266.47,95.43l0.6-0.57c0.8,0.77,1.59,1.58,2.33,2.42l-0.65,0.52C268.02,96.98,267.25,96.18,266.47,95.43z
		 M212.92,97.1c0.66-0.74,1.44-1.61,2.24-2.46l0.64,0.54c-0.79,0.85-1.57,1.71-2.23,2.45L212.92,97.1z M261.3,91.32l0.49-0.67
		c0.94,0.62,1.86,1.29,2.74,1.99l-0.55,0.62C263.12,92.58,262.22,91.93,261.3,91.32z M217.52,92.25c0.86-0.82,1.69-1.55,2.53-2.23
		l0.55,0.62c-0.82,0.66-1.63,1.37-2.48,2.18L217.52,92.25z M258.08,89.42c-0.49-0.26-1.39-0.74-2.63-1.3l0.37-0.73
		c1.27,0.57,2.19,1.06,2.68,1.32l0.37,0.2l-0.42,0.71L258.08,89.42z M222.8,88.04c0.97-0.62,1.97-1.18,2.98-1.67l0.38,0.73
		c-0.98,0.47-1.95,1.02-2.89,1.62L222.8,88.04z M249.14,85.8l0.24-0.78c1.08,0.31,2.18,0.66,3.26,1.06l-0.31,0.76
		C251.28,86.46,250.2,86.11,249.14,85.8z M228.97,85.08c0.41-0.14,0.83-0.27,1.25-0.39c0.68-0.19,1.38-0.37,2.08-0.51l0.18,0.79
		c-0.68,0.14-1.36,0.31-2.02,0.5c-0.41,0.12-0.82,0.24-1.21,0.38L228.97,85.08z M242.53,84.52l0.09-0.81
		c1.12,0.12,2.27,0.29,3.41,0.52l-0.17,0.8C244.75,84.8,243.63,84.63,242.53,84.52z M235.72,83.66c1.04-0.1,2.1-0.15,3.16-0.15
		c0,0,0.01,0,0.01,0l0.28,0l-0.01,0.81l-0.28,0c0,0-0.01,0-0.01,0c-1.03,0-2.07,0.05-3.07,0.15L235.72,83.66z"/>
</g>
<g>
	<g>
		<path className="svgc1-7" d="M173.72,57.12c4.9,1.99,8.24,7.65,7.37,11.03c-0.93,3.62-4.4,5.79-5.12,8.92c-1.17,5.07,9.86,8.48,8.8,13.55
			c-0.73,3.51-5.77,4.5-7.82,8.71c-2.45,5.03,3.8,7.99,3.07,14.62c-0.88,7.98-13.71,15.76-26.35,10.67
			c-10.28-4.14-17.96-15.79-16.06-22.28c2.1-7.16,10.23-7.03,11.43-13.93c1.2-6.92-6.14-12.13-4.04-16.49
			c1.81-3.76,9.08-1.6,13.38-6.82c2.65-3.21,2.73-4.36,6.12-7C166.67,56.4,170.27,55.72,173.72,57.12z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M146.68,136.27c0.1-0.02,0.19-0.08,0.26-0.16c18.63-21.15,19.12-58.11,19.12-58.48c0-0.3-0.23-0.55-0.51-0.56
			c-0.29-0.01-0.52,0.23-0.52,0.52c0,0.37-0.5,36.93-18.86,57.77c-0.19,0.22-0.17,0.56,0.04,0.76
			C146.34,136.27,146.52,136.31,146.68,136.27z"/>
	</g>
</g>
<g>
	<path className="svgc1-4" d="M53.41,178.15l0.79-0.23c0.36,1.04,0.74,2.09,1.15,3.14l-0.78,0.26C54.16,180.27,53.77,179.2,53.41,178.15z
		 M51.45,171.71l0.81-0.17c0.28,1.05,0.58,2.13,0.91,3.21l-0.8,0.2C52.04,173.87,51.73,172.78,51.45,171.71z M50.03,165.15l0.83-0.1
		c0.18,1.07,0.39,2.17,0.62,3.26l-0.82,0.14C50.42,167.34,50.21,166.23,50.03,165.15z M49.31,158.5l0.84,0
		c0.05,1.07,0.14,2.18,0.26,3.28l-0.83,0.05C49.45,160.71,49.36,159.59,49.31,158.5z M49.43,151.84l0.84,0.11
		c-0.1,1.06-0.16,2.16-0.17,3.26l-0.84-0.05C49.27,154.04,49.33,152.93,49.43,151.84z M50.58,145.32l0.81,0.25
		c-0.28,1.02-0.51,2.09-0.7,3.16l-0.83-0.18C50.06,147.46,50.3,146.37,50.58,145.32z M52.91,139.16l0.76,0.39
		c-0.47,0.95-0.91,1.94-1.29,2.95l-0.79-0.32C51.98,141.14,52.42,140.13,52.91,139.16z M55.66,134.69c0.26-0.35,0.54-0.71,0.82-1.05
		l0.66,0.54c-0.28,0.34-0.55,0.68-0.8,1.03c-0.37,0.5-0.74,1.03-1.09,1.56l-0.71-0.47C54.9,135.75,55.27,135.21,55.66,134.69z
		 M58.71,131.21c0.78-0.76,1.62-1.49,2.49-2.16l0.54,0.66c-0.85,0.65-1.66,1.36-2.43,2.1L58.71,131.21z M63.93,127.17
		c0.94-0.57,1.92-1.1,2.93-1.57l0.39,0.75c-0.98,0.46-1.94,0.97-2.85,1.53L63.93,127.17z M69.95,124.36
		c1.05-0.36,2.14-0.67,3.22-0.92l0.23,0.81c-1.06,0.24-2.11,0.54-3.14,0.9L69.95,124.36z M76.49,122.86
		c1.13-0.13,2.26-0.21,3.38-0.22l0.05,0.83c-1.09,0.01-2.19,0.08-3.29,0.21L76.49,122.86z M83.27,122.81
		c1.16,0.13,2.29,0.32,3.38,0.58l-0.16,0.8c-1.05-0.25-2.15-0.44-3.27-0.56L83.27,122.81z M95.27,128.71l0.57-0.58
		c0.87,0.82,1.62,1.72,2.25,2.69l-0.7,0.42C96.8,130.33,96.08,129.48,95.27,128.71z M89.95,124.44c1.1,0.44,2.15,0.97,3.11,1.56
		l-0.42,0.7c-0.92-0.57-1.92-1.07-2.97-1.49L89.95,124.44z"/>
</g>
<g>
	<polygon className="svgc2-0" points="53.07,101.91 58.03,98.74 54.7,94.01 49.74,97.18 	"/>
</g>
<g>
	<g>
		<path className="svgc1-8" d="M100.9,78.09c0,0-6.39,35.86,8.69,49.69c15.08,13.83,28.52,14.56,28.52,14.56s4.95-20.47-6-37.94
			c-10.95-17.47-17.61-19.59-17.61-19.59l-1.83,5.17l-4.11-9.36C108.57,80.62,105.94,78.37,100.9,78.09z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M138.24,142.87c0.1-0.02,0.2-0.08,0.27-0.17c0.18-0.22,0.15-0.57-0.07-0.76
			c-11.92-10.66-36.83-63.54-37.08-64.07c-0.13-0.27-0.44-0.39-0.69-0.26c-0.26,0.13-0.36,0.45-0.23,0.72
			c1.03,2.18,25.26,53.62,37.34,64.43C137.92,142.87,138.09,142.91,138.24,142.87z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M121.57,118.54c0.05-0.01,0.11-0.04,0.16-0.07c4.43-2.92-1.41-27.89-2.1-30.72
			c-0.07-0.29-0.35-0.47-0.63-0.41c0,0,0,0,0,0c-0.28,0.07-0.44,0.36-0.37,0.65c2.5,10.43,5.41,27.67,2.53,29.56
			c-0.24,0.16-0.31,0.49-0.15,0.74C121.13,118.5,121.36,118.59,121.57,118.54z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M125.02,126.4c0.15-0.04,0.24-0.06,0.27-0.07c0.27-0.09,0.41-0.4,0.32-0.68c-0.09-0.28-0.39-0.44-0.66-0.35
			c-0.1,0.03-7.18,2.16-21.87-7.83c-0.24-0.16-0.56-0.1-0.71,0.15c-0.15,0.25-0.08,0.58,0.16,0.75
			C116.32,127.74,123.56,126.75,125.02,126.4z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M114.77,106.08c0.24-0.06,0.4-0.28,0.39-0.54c-0.02-0.3-0.26-0.53-0.54-0.52
			c-7.69,0.25-14.79-8.28-14.86-8.36c-0.19-0.23-0.51-0.26-0.73-0.07c-0.21,0.19-0.24,0.53-0.05,0.76c0.08,0.09,1.88,2.28,4.71,4.46
			c3.78,2.91,7.58,4.4,10.98,4.29C114.7,106.09,114.73,106.08,114.77,106.08z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M115.27,109.78c0.47-0.11,0.92-0.28,1.34-0.52c0.25-0.14,0.34-0.47,0.2-0.73c-0.14-0.26-0.45-0.36-0.7-0.22
			c-5.25,2.96-15.77-6.66-15.87-6.76c-0.22-0.2-0.54-0.18-0.73,0.04c-0.19,0.22-0.16,0.56,0.05,0.76
			C99.98,102.74,109.23,111.2,115.27,109.78z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M129.51,130.44c0.05-0.01,0.1-0.03,0.14-0.06c6.01-3.67,5.54-19.55,5.51-20.23c-0.01-0.3-0.25-0.54-0.53-0.53
			c-0.28,0-0.51,0.25-0.5,0.55c0.01,0.16,0.48,15.93-5.02,19.28c-0.24,0.15-0.32,0.48-0.17,0.74
			C129.06,130.39,129.29,130.49,129.51,130.44z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M131.13,134.14c0.07-0.02,0.14-0.05,0.2-0.1c8-6.45,7.3-13.93,7.26-14.25c-0.03-0.3-0.29-0.52-0.57-0.49
			c-0.29,0.03-0.49,0.29-0.46,0.59c0.01,0.08,0.65,7.23-6.88,13.29c-0.22,0.18-0.26,0.52-0.08,0.76
			C130.73,134.11,130.94,134.18,131.13,134.14z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc2-0" d="M124.61,130.11c-3.91-2.91-6.5-6.85-6.93-10.53c-0.49-4.27,1.87-7.59,4.15-10.81
			c2.33-3.28,4.52-6.37,3.31-10.09c-0.93-2.85-3.23-4.34-5.46-5.78c-2.46-1.59-4.79-3.09-4.53-6.22c0.22-2.71,2.26-4.71,4.23-6.64
			c1.96-1.92,3.8-3.73,3.39-5.91c-0.23-1.25-1.07-1.9-1.96-2.59c-1.02-0.79-2.18-1.69-2.31-3.55c-0.24-3.5,3.34-8.29,8.88-9.6
			c0.02,0,0.03-0.01,0.05-0.01l0,0c3.46-0.8,7.13-0.14,9.36,1.7c1.93,1.58,1.91,3.35,1.89,5.21c-0.01,1.39-0.03,2.84,0.69,4.53
			c1.32,3.12,3.99,4.28,6.56,5.39c2.45,1.06,4.76,2.06,5.68,4.64c1.18,3.32-1.07,5.97-3.45,8.79c-2.18,2.57-4.43,5.23-4.15,8.54
			c0.31,3.75,3.69,5.58,7.26,7.51c3.68,1.99,7.48,4.04,8.42,8.4c0.78,3.63-0.64,8.24-3.8,12.35c-3.32,4.31-8.09,7.46-13.09,8.64
			c0,0,0,0-0.01,0C136.6,135.53,129.97,134.09,124.61,130.11z M127.69,59.43c-0.01,0-0.03,0.01-0.04,0.01
			c-4.9,1.16-8.32,5.43-8.1,8.49c0.09,1.36,0.92,2,1.88,2.74c0.96,0.75,2.05,1.59,2.37,3.26c0.52,2.75-1.62,4.85-3.69,6.89
			c-1.83,1.8-3.72,3.66-3.91,5.96c-0.2,2.45,1.67,3.66,4.03,5.19c2.28,1.47,4.86,3.14,5.91,6.36c1.39,4.26-1.07,7.72-3.45,11.08
			c-2.17,3.05-4.4,6.2-3.96,10.05c0.39,3.38,2.82,7.03,6.49,9.76c5.11,3.8,11.43,5.18,17.35,3.78c0,0,0.01,0,0.01,0
			c10.45-2.47,17.6-12.91,16.13-19.72c-0.83-3.85-4.41-5.78-7.87-7.65c-3.67-1.98-7.47-4.04-7.83-8.4c-0.32-3.77,2.19-6.73,4.4-9.34
			c2.27-2.68,4.23-4.99,3.26-7.7c-0.76-2.13-2.76-2.99-5.09-4c-2.64-1.14-5.63-2.43-7.13-5.97c-0.81-1.91-0.79-3.54-0.78-4.98
			c0.02-1.79,0.03-3.08-1.49-4.33C134.22,59.31,130.8,58.71,127.69,59.43L127.69,59.43z M127.56,58.91L127.56,58.91L127.56,58.91z"
			/>
	</g>
	<g>
		<path className="svgc2-0" d="M142.41,150.36c0.14-0.03,0.26-0.13,0.33-0.27c2.97-5.95,1.79-21.56-3.52-46.41
			c-3.94-18.43-8.8-35.36-8.85-35.53c-0.08-0.29-0.37-0.46-0.64-0.38c-0.27,0.08-0.43,0.38-0.34,0.67
			c0.19,0.67,19.3,67.4,12.45,81.12c-0.13,0.26-0.03,0.59,0.22,0.73C142.16,150.38,142.29,150.39,142.41,150.36z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M142.84,123.79c0.11-0.03,0.21-0.09,0.28-0.18l8.28-10.71c0.18-0.23,0.14-0.57-0.08-0.76
			c-0.22-0.19-0.55-0.16-0.73,0.07l-8,10.36l-14.38-8.66c-0.25-0.15-0.56-0.06-0.7,0.19c-0.14,0.26-0.05,0.59,0.2,0.74l14.76,8.89
			C142.58,123.8,142.71,123.82,142.84,123.79z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M135.68,90.36c0.1-0.02,0.19-0.08,0.26-0.16l8.05-9.27c0.19-0.22,0.17-0.56-0.04-0.76
			c-0.21-0.2-0.54-0.19-0.73,0.03l-7.82,9.01l-10.84-4.21c-0.27-0.1-0.56,0.04-0.66,0.32c-0.09,0.28,0.05,0.59,0.32,0.7l11.17,4.34
			C135.48,90.38,135.58,90.38,135.68,90.36z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc1-9" d="M291.64,185.74c6.92,0,15.69-0.61,26.28-1.84c17.33-2.01,33.34-5.01,33.5-5.04c0.23-0.04,0.38-0.26,0.34-0.48
			c-0.05-0.22-0.27-0.36-0.5-0.32c-0.64,0.12-63.78,11.88-75.98,4.33c-0.2-0.12-0.46-0.07-0.59,0.12c-0.13,0.19-0.07,0.44,0.12,0.56
			C277.68,184.84,283.32,185.74,291.64,185.74z"/>
	</g>
	<g>
		<path className="svgc1-9" d="M308.85,194.45c0.11,0,0.22-0.04,0.31-0.12c0.16-0.16,0.16-0.42-0.01-0.57l-9.02-8.34l9.12-12.56
			c0.13-0.18,0.09-0.44-0.11-0.57s-0.46-0.08-0.59,0.1l-9.33,12.84c-0.12,0.16-0.1,0.38,0.05,0.52l9.28,8.58
			C308.64,194.41,308.75,194.45,308.85,194.45z"/>
	</g>
	<g>
		<path className="svgc1-9" d="M338.98,190.45c0.1,0,0.21-0.04,0.29-0.11c0.17-0.15,0.18-0.41,0.03-0.57l-7.77-8.04l4.73-9.67
			c0.1-0.2,0.01-0.45-0.21-0.54c-0.21-0.1-0.47-0.01-0.57,0.2l-4.85,9.92c-0.07,0.15-0.04,0.33,0.07,0.45l7.97,8.24
			C338.75,190.41,338.86,190.45,338.98,190.45z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc1-7" d="M353.13,129.22c2.1,2.85,1.39,7.15-0.84,8.69c-2.39,1.65-5.11-0.84-7.12,0.61c-3.26,2.35,1.45,10.61-1.74,13
			c-2.21,1.66-5.41-1.61-8.8-0.05c-4.05,1.87-2.04,7.72-5.8,11.08c-4.52,4.04-16.48,3.67-21.92-3.67
			c-4.42-5.97-3.47-15.11,0.96-17.97c4.88-3.16,11.73,2.78,15.92-0.56c4.2-3.35,0.38-11.78,3.87-13.4c3.01-1.4,6.7,4.48,12.01,3.18
			c3.27-0.8,3.71-3.48,7.16-3.7C349.05,126.28,351.65,127.21,353.13,129.22z"/>
	</g>
	<g>
		<path className="svgc3-10" d="M296.89,162.8c0.03,0,0.06,0,0.1-0.01c22.24-4.83,40.93-24.85,41.11-25.05c0.16-0.17,0.14-0.43-0.04-0.57
			c-0.18-0.15-0.45-0.13-0.6,0.04c-0.18,0.2-18.69,20.02-40.66,24.79c-0.23,0.05-0.37,0.27-0.32,0.49
			C296.52,162.67,296.7,162.8,296.89,162.8z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc2-0" d="M300.87,174.61c5.66,0,14.9-3.78,27.5-11.26c10.38-6.16,19.31-12.68,19.4-12.75
			c0.19-0.14,0.22-0.39,0.08-0.57c-0.14-0.18-0.41-0.21-0.6-0.07c-0.35,0.26-35.54,25.87-47.98,23.71c-0.24-0.04-0.45,0.11-0.5,0.33
			c-0.04,0.22,0.11,0.43,0.34,0.47C299.66,174.56,300.24,174.61,300.87,174.61z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M347.05,150.52c-0.09-0.1-0.12-0.23-0.09-0.35c0.03-0.12,2.78-11.77,12.16-10.54
			c0.12,0.02,0.22,0.08,0.29,0.17c0.07,0.09,0.09,0.2,0.07,0.31c-0.02,0.1-2.16,9.94-12.07,10.55c-0.01,0-0.02,0-0.03,0
			C347.25,150.66,347.13,150.61,347.05,150.52z M347.93,149.8c7.73-0.8,10.1-7.6,10.59-9.41
			C351.39,139.79,348.57,147.64,347.93,149.8z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M332.29,160.82c-0.13-0.02-0.24-0.1-0.29-0.22c-0.06-0.11-5.78-11.15,2.57-15.45
			c0.1-0.05,0.22-0.06,0.33-0.03c0.11,0.03,0.2,0.1,0.25,0.2c0.05,0.09,5.14,9.33-2.49,15.41c-0.08,0.06-0.17,0.09-0.27,0.09
			C332.35,160.82,332.32,160.82,332.29,160.82z M332.53,159.75c2.67-2.32,3.82-5.33,3.42-8.95c-0.24-2.18-1-3.98-1.36-4.73
			C328.43,149.64,331.59,157.7,332.53,159.75z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M332.07,160.7c-0.09-0.09-0.14-0.22-0.12-0.34c0.02-0.13,0.1-0.24,0.22-0.3c0.11-0.06,11.16-5.69,16.32,1.87
			c0.06,0.09,0.08,0.21,0.06,0.32c-0.03,0.11-0.1,0.2-0.2,0.26c-0.05,0.03-3.31,1.76-7.41,1.76
			C338.12,164.27,334.91,163.45,332.07,160.7z M333.11,160.53c5.69,5.05,12.64,2.31,14.4,1.48
			C343.31,156.48,335.24,159.58,333.11,160.53z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M313.97,170.8c-0.12-0.05-0.22-0.15-0.25-0.27c-0.04-0.12-3.52-12.21,5.44-15.15
			c0.11-0.04,0.23-0.03,0.33,0.02c0.1,0.05,0.18,0.14,0.21,0.24c0.03,0.1,3.2,10.32-5.36,15.12c-0.07,0.04-0.14,0.06-0.22,0.06
			C314.08,170.83,314.02,170.82,313.97,170.8z M314.4,169.78c6.46-4.03,5.06-11.61,4.6-13.47
			C312.33,158.87,313.88,167.54,314.4,169.78z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M313.78,170.65c-0.08-0.11-0.1-0.24-0.05-0.37c0.05-0.12,0.15-0.22,0.28-0.26
			c0.12-0.03,12.17-3.28,15.95,4.97c0.05,0.11,0.05,0.23,0,0.33c-0.05,0.11-0.15,0.18-0.26,0.22c-0.04,0.01-1.87,0.52-4.39,0.52
			C321.85,176.07,317.08,175.12,313.78,170.65z M314.85,170.67c4.68,5.76,12.15,4.63,14.11,4.22
			C325.76,168.81,317.2,170.16,314.85,170.67z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc2-0" d="M236.98,108.12l-3.38-6.81c-0.2-0.4-0.02-0.88,0.4-1.07l11.68-5.26c0.2-0.09,0.43-0.1,0.65-0.03
			c0.21,0.07,0.38,0.22,0.48,0.41l3.38,6.81c0.2,0.4,0.02,0.88-0.4,1.07l-11.68,5.26c0,0,0,0,0,0c-0.12,0.05-0.24,0.08-0.36,0.08
			C237.43,108.58,237.12,108.41,236.98,108.12z M234.37,100.96l3.38,6.81l11.67-5.25l-3.37-6.81L234.37,100.96z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M245.41,99.04L245.41,99.04L245.41,99.04 M245.92,98.54c-0.04,0-0.08,0.01-0.12,0.03l-1.22,0.55
			c-0.14,0.06-0.2,0.22-0.13,0.35l0.34,0.68c0.05,0.1,0.15,0.15,0.25,0.15c0.04,0,0.08-0.01,0.12-0.03l1.22-0.55
			c0.14-0.06,0.2-0.22,0.13-0.35l-0.34-0.68C246.13,98.59,246.03,98.54,245.92,98.54L245.92,98.54z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M247.05,100.96c0.16,0,0.32,0.09,0.39,0.24l0.01,0.03c0.1,0.21,0.01,0.45-0.21,0.55l-8.74,3.93
			c-0.06,0.03-0.12,0.04-0.18,0.04c-0.16,0-0.32-0.09-0.39-0.24l-0.01-0.03c-0.1-0.21-0.01-0.45,0.21-0.55l8.74-3.93
			C246.93,100.97,246.99,100.96,247.05,100.96L247.05,100.96z"/>
	</g>
</g>
<g>
	<g>
		<ellipse className="svgc2-0" cx="353" cy="245.91" rx="6.4" ry="6.1"/>
	</g>
	<g>
		<path className="svgc3-10" d="M354.66,243.93v3.96c0,0.31-0.34,0.5-0.63,0.36l-3.32-1.68c-0.28-0.14-0.31-0.51-0.05-0.69l3.32-2.28
			C354.26,243.4,354.66,243.6,354.66,243.93z"/>
	</g>
</g>
<g>
	<polygon className="svgc3-2" points="326.55,222.51 330.78,226.54 335,222.51 330.78,218.49 	"/>
</g>
<g>
	<g>
		<g>
			<path className="svgc1-7" d="M66.9,139.98c0.22-0.1,0.47-0.09,0.67,0.07l8.44,6.7c0.07,0.06,0.13,0.13,0.17,0.2
				c0.14,0.3,0.02,0.75-0.4,0.93c-0.24,0.1-0.53,0.08-0.7-0.06l-8.45-6.7c-0.07-0.06-0.12-0.13-0.16-0.2
				C66.28,140.55,66.55,140.13,66.9,139.98z"/>
		</g>
		<g>
			<path className="svgc1-7" d="M67.09,148.24l0.8,1.69c0.56,1.18,1.58,1.25,2.63,0.8c1.06-0.45,1.67-1.23,1.12-2.41l-0.8-1.69
				c-0.56-1.18-1.57-1.26-2.63-0.8C67.16,146.28,66.54,147.06,67.09,148.24z M68.7,146.87c0.39-0.17,0.7-0.08,0.88,0.31l0.8,1.69
				c0.19,0.39,0.04,0.67-0.34,0.83c-0.39,0.17-0.71,0.09-0.89-0.3l-0.8-1.69C68.16,147.31,68.31,147.03,68.7,146.87z"/>
		</g>
		<g>
			<path className="svgc1-7" d="M75.57,139.6l-0.8-1.69c-0.56-1.18-1.57-1.26-2.63-0.8c-1.05,0.45-1.67,1.23-1.12,2.41l0.8,1.69
				c0.56,1.18,1.58,1.25,2.63,0.8C75.51,141.55,76.12,140.78,75.57,139.6z M73.96,140.97c-0.39,0.17-0.71,0.09-0.89-0.3l-0.8-1.69
				c-0.19-0.39-0.03-0.67,0.36-0.84c0.39-0.17,0.7-0.08,0.88,0.31l0.8,1.69C74.49,140.53,74.35,140.81,73.96,140.97z"/>
		</g>
	</g>
</g>
<g>
	<g className="st0" id="shadows_2_">
		<ellipse className="svgc3-9" cx="187.78" cy="295.41" rx="150.62" ry="12.73"/>
	</g>
	<path className="st6" d="M100.62,230.01c-0.63,2.59-1.52,5.12-2.64,7.53c-5.06,10.87-14.18,13.33-21.93,21.38
		c-2-3.28-5.91-4.83-9.63-5.76c-3.73-0.92-7.67-1.48-10.88-3.6c-3.21-2.11-5.4-6.44-3.65-9.86c0.49-0.96,1.28-1.87,1.21-2.95
		c-0.03-0.5-0.25-0.97-0.49-1.41c-1.89-3.39-5.35-5.54-8.21-8.17c-2.85-2.63-5.29-6.51-4.13-10.22c0.89-2.82,3.56-4.61,5.41-6.91
		c6.47-8.03,1.94-20.09,4.56-30.07c0.66-2.5,1.82-4.84,3.35-6.9c3.71-4.99,9.61-8.41,15.85-8.74c8.82-0.47,17.62,5.26,20.76,13.51
		c2.03,5.33,1.81,11.28,3.53,16.72c1.28,4.07,3.6,7.73,5.26,11.66C102.15,213.72,102.54,222.15,100.62,230.01z"/>
	<path className="st7" d="M100.62,230.01c-0.63,2.59-1.52,5.12-2.64,7.53c-5.06,10.87-14.18,13.33-21.93,21.38
		c-2-3.28-5.91-4.83-9.63-5.76c-3.73-0.92-7.67-1.48-10.88-3.6c-3.21-2.11-5.4-6.44-3.65-9.86c0.49-0.96,1.28-1.87,1.21-2.95
		c-0.03-0.5-0.25-0.97-0.49-1.41c-1.89-3.39-5.35-5.54-8.21-8.17c-2.85-2.63-5.29-6.51-4.13-10.22c0.89-2.82,3.56-4.61,5.41-6.91
		c6.47-8.03,1.94-20.09,4.56-30.07c0.66-2.5,1.82-4.84,3.35-6.9c0.6,1.03,1.07,2.32,1.4,3.86c2.02,9.37-0.41,19.06-2.82,28.33
		c-1.31,5.05-2.63,10.1-3.94,15.15c-0.75,2.89-1.21,6.61,1.27,8.26c2.56,1.7,6.26-0.3,9.01,1.08c2.11,1.05,2.86,3.69,2.98,6.04
		c0.12,2.35-0.14,4.82,0.83,6.97c2.58,5.72,11.97,5.69,14.36,11.49c2.91-4.03,5.85-8.09,9.53-11.42
		C90.99,238.55,97.19,235.16,100.62,230.01z"/>
	<path className="st8" d="M74.65,294.51c0.36-7.49,0.61-15.03,0.75-22.54c0.13-7.52,0.16-15.04-0.14-22.53
		c-0.16-3.74-0.39-7.49-0.94-11.14c-0.08-0.45-0.14-0.91-0.24-1.36c-0.1-0.44-0.18-0.9-0.3-1.33c-0.21-0.85-0.56-1.72-0.87-2.59
		c-0.15-0.44-0.35-0.86-0.52-1.29l-0.54-1.28c-0.41-0.84-0.79-1.69-1.21-2.52c-1.69-3.33-3.62-6.55-5.66-9.7
		c-0.5-0.79-1.04-1.57-1.55-2.35c-0.53-0.77-1.06-1.55-1.6-2.32c-1.08-1.54-2.19-3.06-3.33-4.55l0.54-0.44
		c1.21,1.46,2.37,2.95,3.51,4.46c0.58,0.75,1.13,1.52,1.69,2.27c0.55,0.77,1.11,1.53,1.65,2.31c2.16,3.1,4.23,6.29,6.07,9.62
		c0.46,0.83,0.89,1.69,1.33,2.53l0.61,1.3c0.2,0.44,0.42,0.86,0.59,1.31c0.36,0.9,0.75,1.76,1.03,2.74
		c0.15,0.47,0.25,0.95,0.37,1.42c0.12,0.47,0.2,0.94,0.3,1.42c0.71,3.78,1.07,7.56,1.36,11.34c0.57,7.56,0.8,15.11,0.94,22.67
		c0.13,7.56,0.14,15.1,0.04,22.67L74.65,294.51z"/>
	<path className="st8" d="M86.97,213.78c-1.03,2.22-2.01,4.48-2.95,6.74c-0.94,2.26-1.85,4.54-2.71,6.84c-0.85,2.3-1.66,4.61-2.41,6.93
		c-0.73,2.33-1.4,4.68-1.95,7.04l0,0.01c-0.12,0.5-0.62,0.82-1.13,0.7c-0.5-0.12-0.82-0.62-0.7-1.13c0-0.01,0-0.02,0.01-0.02
		c0.63-2.42,1.37-4.79,2.18-7.13c0.82-2.34,1.7-4.65,2.62-6.94c0.93-2.29,1.9-4.56,2.91-6.81c1.01-2.25,2.05-4.49,3.15-6.7
		c0.14-0.27,0.47-0.38,0.74-0.25C86.99,213.18,87.1,213.5,86.97,213.78L86.97,213.78z"/>
	<path className="st8" d="M66.56,179.35c-0.19,1.99-0.31,4-0.36,6.01c-0.05,2.01-0.04,4.01,0.11,6c0.07,0.99,0.18,1.98,0.34,2.95
		c0.16,0.97,0.38,1.91,0.74,2.79c0.73,1.81,1.69,3.59,2.65,5.34c1.95,3.51,4.07,6.95,6.21,10.37c2.15,3.41,4.34,6.81,6.57,10.16l0,0
		c0.28,0.41,0.16,0.97-0.25,1.25c-0.41,0.28-0.97,0.16-1.25-0.25c0,0-0.01-0.01-0.01-0.01c-2.2-3.41-4.35-6.84-6.45-10.3
		c-2.1-3.46-4.17-6.95-6.1-10.53c-0.95-1.8-1.9-3.6-2.66-5.55c-0.39-1-0.61-2.05-0.77-3.07c-0.16-1.03-0.25-2.05-0.31-3.08
		c-0.12-2.05-0.1-4.09-0.02-6.12c0.08-2.04,0.23-4.06,0.45-6.09c0.03-0.3,0.31-0.52,0.61-0.49
		C66.37,178.77,66.58,179.04,66.56,179.35L66.56,179.35z"/>
	<path className="st8" d="M78.25,184.28c0.23,5.31,0.45,10.62,0.61,15.94c0.07,2.66,0.16,5.31,0.11,8.01
		c-0.01,0.35-0.02,0.67-0.06,1.07c-0.03,0.24-0.06,0.36-0.08,0.52c-0.03,0.15-0.05,0.31-0.07,0.47c-0.08,0.64-0.14,1.29-0.2,1.94
		c-0.1,1.31-0.17,2.63-0.22,3.94l0,0.01c-0.02,0.46-0.4,0.82-0.86,0.81c-0.46-0.02-0.82-0.4-0.81-0.86c0,0,0-0.01,0-0.01
		c0.07-1.34,0.16-2.67,0.29-4.01c0.06-0.67,0.14-1.34,0.24-2.02c0.03-0.17,0.05-0.34,0.08-0.51c0.03-0.17,0.07-0.37,0.08-0.46
		c0.03-0.26,0.05-0.6,0.07-0.92c0.09-2.61,0.05-5.28,0.03-7.93c-0.06-5.31-0.18-10.62-0.32-15.93c-0.01-0.3,0.23-0.56,0.54-0.57
		C77.99,183.74,78.24,183.98,78.25,184.28z"/>
	<path className="st8" d="M51.1,209.83c0.5,0.98,1.03,1.95,1.56,2.91c0.53,0.96,1.08,1.92,1.66,2.85c0.58,0.93,1.17,1.85,1.82,2.7
		c0.17,0.21,0.32,0.43,0.5,0.62c0.17,0.19,0.35,0.41,0.5,0.56c0.33,0.32,0.75,0.62,1.19,0.9c0.88,0.56,1.86,1.03,2.84,1.47
		c0.99,0.44,2.01,0.84,3.03,1.22c2.05,0.75,4.15,1.45,6.24,2.06l0.01,0c0.48,0.14,0.75,0.64,0.61,1.12
		c-0.14,0.48-0.64,0.75-1.12,0.61l-0.01,0c-2.16-0.65-4.26-1.37-6.35-2.2c-1.04-0.41-2.08-0.85-3.1-1.33
		c-1.02-0.49-2.04-1-3.01-1.65c-0.49-0.33-0.97-0.68-1.42-1.14c-0.24-0.24-0.4-0.47-0.58-0.71c-0.18-0.23-0.33-0.47-0.5-0.71
		c-0.63-0.95-1.19-1.93-1.71-2.92c-1.05-1.98-1.99-3.99-2.84-6.06c-0.08-0.19,0.01-0.41,0.2-0.49
		C50.8,209.58,51.01,209.66,51.1,209.83L51.1,209.83z"/>
</g>
<path className="st9 st0" d="M108.15,170.14l-9.23,14.72c0,0-0.56,7.24,10.58,8.35L108.15,170.14z"/>
<g>
	<path className="st6" d="M232.19,267.94c-0.02,0.36-0.05,0.73-0.07,1.09c-0.08,1.11-0.27,2.36-1.17,3.02
		c-0.53,0.39-1.2,0.49-1.85,0.58c-4.82,0.67-11.08,1.9-13.01-3.64c-2.12-6.09-1.54-12.79-0.32-19.01c0.25-1.25,0.52-2.52,0.46-3.79
		c-0.1-2.22-1.22-4.36-1.01-6.57c0.25-2.58,2.22-4.61,3.38-6.92c1.68-3.37,1.61-7.31,1.64-11.08c0.01-0.99,0.06-2.07,0.71-2.8
		c0.92-1.04,2.71-0.88,3.75,0.04c0.3,0.26,0.55,0.57,0.76,0.91c0.51,0.83,0.77,1.84,0.89,2.82c0.18,1.38,0.15,2.78,0.49,4.13
		c0.45,1.75,1.5,3.3,1.9,5.06c0.4,1.75,0.14,3.59,0.39,5.37c0.27,1.92,1.12,3.7,1.65,5.56c0.64,2.27,0.81,4.64,0.96,6.99
		C232.15,255.76,232.55,261.86,232.19,267.94z"/>
	<path className="st7" d="M232.19,267.94c-0.02,0.36-0.05,0.73-0.07,1.09c-0.08,1.11-0.27,2.36-1.17,3.02
		c-0.53,0.39-1.2,0.49-1.85,0.58c-4.82,0.67-11.08,1.9-13.01-3.64c-2.12-6.09-1.54-12.79-0.32-19.01c0.25-1.25,0.52-2.52,0.46-3.79
		c-0.1-2.22-1.22-4.36-1.01-6.57c0.25-2.58,2.22-4.61,3.38-6.92c1.68-3.37,1.61-7.31,1.64-11.08c0.01-0.99,0.06-2.07,0.71-2.8
		c0.92-1.04,2.71-0.88,3.75,0.04c0.3,0.26,0.55,0.57,0.76,0.91c-0.05,0.55-2.09-1.42-2.93,0.56c-1.25,2.97,0.15,7.14-0.7,10.25
		c-0.82,2.98-0.79,6.29-2.44,8.9c-0.54,0.85-1.25,1.61-1.61,2.56c-1.09,2.86,1.37,5.77,1.88,8.79c0.55,3.26-1.21,6.42-1.76,9.68
		c-0.55,3.26,1.21,7.57,4.5,7.29c-0.12-1.51,2.1-2.06,3.48-1.44c1.39,0.62,2.58,1.84,4.1,1.87
		C230.73,268.23,231.45,267.95,232.19,267.94z"/>
</g>
<g>
	<path className="st6" d="M304.13,270.47c-0.51,0.63-1.38,1.03-2.24,1.34c-6.57,2.38-14.86,1.45-15.31-5.34
		c-0.17-2.52,0.7-5.01,0.92-7.53c0.45-5.17-1.85-10.46-0.16-15.46c0.51-1.5,1.37-2.94,1.51-4.5c0.11-1.24-0.25-2.48-0.3-3.73
		c-0.05-1.38,0.28-2.75,0.61-4.11c0.77-3.14,1.54-6.29,2.31-9.43c0.25-1.03,1.16-2.36,2.37-1.96c0.01,0,0.02,0.01,0.03,0.01
		c0.54,0.19,0.8,0.69,1.01,1.13c2.12,4.6,4.24,9.21,6.35,13.81c0.74,1.61,1.49,3.26,1.43,4.97c-0.05,1.44-0.66,2.86-0.54,4.3
		c0.09,1.02,0.55,2,0.92,2.99c1.18,3.21,1.28,6.6,1.37,9.95c0.09,3.44,0.19,6.89,0.28,10.33c0.01,0.19,0.01,0.38,0.02,0.57
		C304.75,268.73,304.75,269.71,304.13,270.47z"/>
	<path className="st7" d="M304.13,270.47c-0.51,0.63-1.38,1.03-2.24,1.34c-6.57,2.38-14.86,1.45-15.31-5.34
		c-0.17-2.52,0.7-5.01,0.92-7.53c0.45-5.17-1.85-10.46-0.16-15.46c0.51-1.5,1.37-2.94,1.51-4.5c0.11-1.24-0.25-2.48-0.3-3.73
		c-0.05-1.38,0.28-2.75,0.61-4.11c0.77-3.14,1.54-6.29,2.31-9.43c0.25-1.03,1.16-2.36,2.37-1.96c0.01,0,0.02,0.01,0.03,0.01
		c0.16,0.97,0.08,2.17-0.35,3.56c-0.64,2.08-1.7,4.13-1.51,6.29c0.23,2.72,2.39,5.21,1.82,7.87c-0.31,1.44-1.36,2.58-2.1,3.86
		c-4.16,7.19,2.18,17.01-1.59,24.41c1.59,1.85,4.85-0.42,7.01,0.73c1.14,0.6,1.72,2.06,2.95,2.45c1.6,0.5,3.03-1.09,4.6-1.68
		c0.01,0.19,0.01,0.38,0.02,0.57C304.75,268.73,304.75,269.71,304.13,270.47z"/>	
</g>
<rect x="92.51" y="155.56" className="svgc3-9" width="215.54" height="139.85"/>
<g>
	<g className="st1">
		<path className="svgc1-0" d="M103.2,172.23h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C105.93,171.01,104.71,172.23,103.2,172.23z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M117.7,172.23h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C120.42,171.01,119.2,172.23,117.7,172.23z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M132.19,172.23h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C134.92,171.01,133.69,172.23,132.19,172.23z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M161.18,172.23h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C163.9,171.01,162.68,172.23,161.18,172.23z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M97.98,175.82v2.57c0,0.8-0.65,1.44-1.44,1.44h-4.02v-5.45h4.02C97.33,174.38,97.98,175.02,97.98,175.82z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M109.74,179.83h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C112.47,178.61,111.25,179.83,109.74,179.83z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M124.24,179.83h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C126.96,178.61,125.74,179.83,124.24,179.83z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M138.73,179.83h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C141.46,178.61,140.23,179.83,138.73,179.83z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M153.22,179.83h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C155.95,178.61,154.73,179.83,153.22,179.83z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M167.71,179.83h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C170.44,178.61,169.22,179.83,167.71,179.83z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M103.2,187.77h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C105.93,186.55,104.71,187.77,103.2,187.77z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M117.7,187.77h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C120.42,186.55,119.2,187.77,117.7,187.77z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M132.19,187.77h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C134.92,186.55,133.69,187.77,132.19,187.77z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M109.74,195.37h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C112.47,194.15,111.25,195.37,109.74,195.37z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M124.24,195.37h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C126.96,194.15,125.74,195.37,124.24,195.37z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M103.2,203.31h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C105.93,202.08,104.71,203.31,103.2,203.31z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M117.7,203.31h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C120.42,202.08,119.2,203.31,117.7,203.31z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M132.19,203.31h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C134.92,202.08,133.69,203.31,132.19,203.31z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M109.74,210.91h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C112.47,209.68,111.25,210.91,109.74,210.91z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M124.24,210.91h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C126.96,209.68,125.74,210.91,124.24,210.91z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M114.14,243.16h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C116.87,241.94,115.65,243.16,114.14,243.16z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M106.19,250.76H99.9c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C108.92,249.54,107.7,250.76,106.19,250.76z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M120.68,250.76h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C123.41,249.54,122.19,250.76,120.68,250.76z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M102.38,254.69v2.57c0,0.8-0.65,1.44-1.44,1.44h-8.43v-5.45h8.43C101.73,253.25,102.38,253.89,102.38,254.69
			z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M114.14,258.7h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C116.87,257.48,115.65,258.7,114.14,258.7z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M120.68,266.3h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C123.41,265.08,122.19,266.3,120.68,266.3z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M102.38,270.22v2.57c0,0.8-0.65,1.44-1.44,1.44h-8.43v-5.45h8.43C101.73,268.78,102.38,269.43,102.38,270.22
			z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M114.14,274.24h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C116.87,273.02,115.65,274.24,114.14,274.24z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M128.64,274.24h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C131.36,273.02,130.14,274.24,128.64,274.24z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M143.13,274.24h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C145.86,273.02,144.64,274.24,143.13,274.24z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M172.12,274.24h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C174.84,273.02,173.62,274.24,172.12,274.24z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M106.19,281.84H99.9c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C108.92,280.62,107.7,281.84,106.19,281.84z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M120.68,281.84h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C123.41,280.62,122.19,281.84,120.68,281.84z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M135.18,281.84h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C137.9,280.62,136.68,281.84,135.18,281.84z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M149.67,281.84h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C152.4,280.62,151.18,281.84,149.67,281.84z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M164.16,281.84h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C166.89,280.62,165.67,281.84,164.16,281.84z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M178.66,281.84h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C181.38,280.62,180.16,281.84,178.66,281.84z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M205.29,181.29H199c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C208.02,180.07,206.8,181.29,205.29,181.29z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M219.79,181.29h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C222.51,180.07,221.29,181.29,219.79,181.29z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M234.28,181.29h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C237.01,180.07,235.79,181.29,234.28,181.29z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M263.27,181.29h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C265.99,180.07,264.77,181.29,263.27,181.29z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M277.76,181.29h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C280.49,180.07,279.26,181.29,277.76,181.29z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M226.33,188.89h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C229.05,187.67,227.83,188.89,226.33,188.89z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M240.82,188.89h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C243.55,187.67,242.32,188.89,240.82,188.89z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M269.81,188.89h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C272.53,187.67,271.31,188.89,269.81,188.89z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M284.3,188.89h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C287.03,187.67,285.8,188.89,284.3,188.89z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M234.28,196.82h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C237.01,195.6,235.79,196.82,234.28,196.82z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M211.83,204.42h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C214.56,203.2,213.34,204.42,211.83,204.42z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M226.33,204.42h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C229.05,203.2,227.83,204.42,226.33,204.42z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M240.82,204.42h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C243.55,203.2,242.32,204.42,240.82,204.42z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M255.31,204.42h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C258.04,203.2,256.82,204.42,255.31,204.42z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M205.29,212.36H199c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C208.02,211.14,206.8,212.36,205.29,212.36z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M219.79,212.36h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C222.51,211.14,221.29,212.36,219.79,212.36z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M234.28,212.36h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C237.01,211.14,235.79,212.36,234.28,212.36z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M248.77,212.36h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C251.5,211.14,250.28,212.36,248.77,212.36z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M211.83,219.96h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C214.56,218.74,213.34,219.96,211.83,219.96z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M226.33,219.96h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C229.05,218.74,227.83,219.96,226.33,219.96z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M240.82,219.96h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C243.55,218.74,242.32,219.96,240.82,219.96z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M255.31,219.96h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C258.04,218.74,256.82,219.96,255.31,219.96z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M252.44,232.19h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C255.17,230.97,253.95,232.19,252.44,232.19z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M307.92,226.76v5.41c-0.08,0.01-0.16,0.02-0.24,0.02h-8.86c-0.8,0-1.44-0.65-1.44-1.44v-2.57
			c0-0.8,0.65-1.44,1.44-1.44h8.86C307.77,226.74,307.85,226.75,307.92,226.76z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M258.98,239.79h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C261.71,238.57,260.49,239.79,258.98,239.79z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M298.45,239.79h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C301.18,238.57,299.96,239.79,298.45,239.79z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M307.92,234.34v5.45h-2.55c-0.8,0-1.44-0.65-1.44-1.44v-2.57c0-0.8,0.65-1.44,1.44-1.44H307.92z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M252.44,247.73h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C255.17,246.51,253.95,247.73,252.44,247.73z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M277.42,247.73h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C280.14,246.51,278.92,247.73,277.42,247.73z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M291.91,247.73h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C294.64,246.51,293.42,247.73,291.91,247.73z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M307.92,242.3v5.41c-0.08,0.01-0.16,0.02-0.24,0.02h-8.86c-0.8,0-1.44-0.65-1.44-1.44v-2.57
			c0-0.8,0.65-1.44,1.44-1.44h8.86C307.77,242.28,307.85,242.28,307.92,242.3z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M258.98,255.33h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C261.71,254.11,260.49,255.33,258.98,255.33z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M283.96,255.33h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C286.68,254.11,285.46,255.33,283.96,255.33z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M298.45,255.33h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C301.18,254.11,299.96,255.33,298.45,255.33z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M307.92,249.88v5.45h-2.55c-0.8,0-1.44-0.65-1.44-1.44v-2.57c0-0.8,0.65-1.44,1.44-1.44H307.92z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M252.44,263.27h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C255.17,262.05,253.95,263.27,252.44,263.27z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M277.42,263.27h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C280.14,262.05,278.92,263.27,277.42,263.27z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M291.91,263.27h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C294.64,262.05,293.42,263.27,291.91,263.27z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M307.92,257.83v5.41c-0.08,0.01-0.16,0.02-0.24,0.02h-8.86c-0.8,0-1.44-0.65-1.44-1.44v-2.57
			c0-0.8,0.65-1.44,1.44-1.44h8.86C307.77,257.81,307.85,257.82,307.92,257.83z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M258.98,270.87h-6.29c-1.51,0-2.73-1.22-2.73-2.73v0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73v0C261.71,269.65,260.49,270.87,258.98,270.87z"/>
	</g>
	<g className="st1">
		<path className="svgc1-0" d="M283.96,270.87h-6.29c-1.51,0-2.73-1.22-2.73-2.73l0,0c0-1.51,1.22-2.73,2.73-2.73h6.29
			c1.51,0,2.73,1.22,2.73,2.73l0,0C286.68,269.65,285.46,270.87,283.96,270.87z"/>
	</g>
</g>
<path className="st9 st0" d="M210.37,170.14l9.23,14.72c0,0,0.56,7.24-10.58,8.35L210.37,170.14z"/>
<rect x="88.75" y="287.45" className="svgc2-0" width="223.05" height="7.9"/>
<rect x="88.75" y="287.45" className="svgc1-0" width="223.05" height="7.9"/>
<rect x="92.47" y="154.76" className="svgc1-5" width="215.61" height="7.9"/>
<polygon className="svgc2-0" points="302.26,147.07 98.29,147.07 98.29,135.67 302.26,121.95 "/>
<polygon className="svgc3-2" points="302.26,147.07 98.29,147.07 98.29,135.67 302.26,121.95 "/>
<g>
	<g>
		
			<rect x="109.78" y="181.85" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 318.744 450.1287)" className="svgc1-0" width="99.19" height="86.44"/>
		<g>
			<rect x="113.45" y="185.04" className="st10" width="91.85" height="80.04"/>
			<path className="svgc1-9 st0" d="M142.71,185.04c-1.11,1.49-2.41,2.84-3.89,3.97c-3.36,2.58-7.6,4.25-9.89,7.81
				c-3.85,5.98-0.58,14.75-4.76,20.5c-1.36,1.88-3.37,3.18-5.31,4.45c-1.88,1.22-3.78,2.49-5.41,4v-15.72
				c2.44-1.83,4.97-3.55,6.54-6.13c2.74-4.52,1.89-10.65,5.02-14.91c1.23-1.67,2.93-2.85,4.69-3.98H142.71z"/>
			<path className="svgc1-9 st0" d="M166.98,198.33c-0.81,8.15-10.08,10.19-14.53,16.81c-5.53,8.23-2.56,22.11-9.19,28.73
				c-1.97,1.96-4.36,2.73-6.49,4.3c-4.79,3.53-7.23,9.94-8.48,16.91h-14.84l2.6-19.56c1.68-1.6,3.47-2.97,5.33-4.06
				c4.04-2.36,8.58-3.68,11.78-7.92c4.34-5.75,4.55-14.67,8.52-20.85c3.19-4.97,8.22-7.25,11.52-12.1c2.97-4.37,4.03-10,5.17-15.55
				h6.9c-0.14,1.41-0.12,2.82,0.1,4.21C165.86,192.33,167.31,195.04,166.98,198.33z"/>
			<path className="svgc1-9 st0" d="M195.36,185.04c-1.61,7.56-0.06,15.65-3.34,22.56c-4.19,8.84-14.69,12.12-16.58,21.79
				c-1.19,6.1,1.84,12.36-0.85,17.9c-1.37,2.82-3.81,4.42-5.79,6.57c-2.77,3-4.57,6.93-5.84,11.21h-22.14
				c1.58-5.77,3.09-11.41,7.03-14.44c4.08-3.13,9.42-1.99,12.76-6.5c3.37-4.56,2.2-11.22,2.9-17.08c0.83-6.95,4.97-13.79,10.1-16.66
				c1.88-1.05,3.92-1.65,5.56-3.39c4.63-4.89,2.49-13.59,3.73-20.8c0.07-0.39,0.15-0.79,0.24-1.17H195.36z"/>
		</g>
		<g>
			
				<rect x="139.5" y="181.85" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 281.5671 450.1287)" className="svgc1-0" width="2.56" height="86.44"/>
			
				<rect x="176.68" y="181.85" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 355.9208 450.1287)" className="svgc1-0" width="2.56" height="86.44"/>
		</g>
		
			<rect x="106.81" y="264.63" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 318.744 534.063)" className="svgc1-0" width="105.12" height="4.81"/>
	</g>
	<g>
		<g>
			<path className="svgc2-0" d="M110.18,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C115.4,187.61,113.06,189.95,110.18,189.95z"/>
			<path className="svgc2-8" d="M122.73,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C127.95,187.61,125.61,189.95,122.73,189.95z"
				/>
			<path className="svgc2-0" d="M135.28,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C140.51,187.61,138.17,189.95,135.28,189.95z"
				/>
			<path className="svgc2-8" d="M147.84,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C153.06,187.61,150.72,189.95,147.84,189.95z"
				/>
			<path className="svgc2-0" d="M160.39,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C165.62,187.61,163.28,189.95,160.39,189.95z"
				/>
			<path className="svgc2-8" d="M172.95,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C178.17,187.61,175.83,189.95,172.95,189.95z"
				/>
			<path className="svgc2-0" d="M185.5,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C190.73,187.61,188.39,189.95,185.5,189.95z"/>
			<path className="svgc2-8" d="M198.06,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C203.28,187.61,200.94,189.95,198.06,189.95z"
				/>
			<path className="svgc2-0" d="M210.61,189.95h-2.11c-2.88,0-5.22-2.34-5.22-5.22v0h12.55v0C215.84,187.61,213.5,189.95,210.61,189.95z"/>
		</g>
		<g>
			<polygon className="svgc2-5" points="119.52,170.14 108.15,170.14 102.85,184.66 115.41,184.66 			"/>
			<polygon className="svgc3-10" points="130.9,170.14 119.52,170.14 115.41,184.66 127.96,184.66 			"/>
			<polygon className="svgc2-5" points="142.28,170.14 130.9,170.14 127.96,184.66 140.51,184.66 			"/>
			<polygon className="svgc3-10" points="153.65,170.14 142.28,170.14 140.51,184.66 153.06,184.66 			"/>
			<polygon className="svgc2-5" points="165.03,170.14 153.65,170.14 153.06,184.66 165.61,184.66 			"/>
			<polygon className="svgc3-10" points="176.4,170.14 165.03,170.14 165.61,184.66 178.17,184.66 			"/>
			<polygon className="svgc2-5" points="187.78,170.14 176.4,170.14 178.17,184.66 190.72,184.66 			"/>
			<polygon className="svgc3-10" points="199.15,170.14 187.78,170.14 190.72,184.66 203.27,184.66 			"/>
			<polygon className="svgc2-5" points="210.53,170.14 199.15,170.14 203.27,184.66 215.82,184.66 			"/>
		</g>
	</g>
</g>
<g>
	
		<rect x="236.04" y="181.84" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 519.8852 477.2493)" className="svgc1-0" width="47.8" height="113.58"/>
	
		<rect x="239.82" y="185.93" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 519.8852 481.3394)" className="svgc3-9" width="40.25" height="109.49"/>
	
		<rect x="245.28" y="192.27" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 519.8852 451.1996)" className="svgc1-0" width="29.32" height="66.65"/>
	
		<rect x="246.79" y="193.87" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 519.8852 451.1996)" className="st10" width="26.3" height="63.46"/>
	<path className="svgc3-10" d="M248,245.5h-4.65c-0.86,0-1.55-0.69-1.55-1.55l0,0c0-0.86,0.69-1.55,1.55-1.55H248
		c0.86,0,1.55,0.69,1.55,1.55l0,0C249.55,244.8,248.85,245.5,248,245.5z"/>
	<g>
		<polygon className="svgc3-0" points="250.3,234.1 249.8,233.75 258.95,220.89 267.88,229.4 267.46,229.84 259.04,221.83 		"/>
		<path className="svgc2-0" d="M259.9,221.56c0,0.51-0.41,0.92-0.92,0.92s-0.92-0.41-0.92-0.92c0-0.51,0.41-0.92,0.92-0.92
			S259.9,221.05,259.9,221.56z"/>
		<g>
			
				<rect x="249.3" y="231.22" transform="matrix(0.9733 -0.2297 0.2297 0.9733 -46.9887 65.9525)" className="svgc2-0" width="21.05" height="7.22"/>
			<g>
				<path className="svgc3-10" d="M256.66,235.71c0.08,0.35,0.09,0.66,0.03,0.95c-0.06,0.29-0.19,0.53-0.38,0.73
					c-0.19,0.19-0.44,0.33-0.73,0.4c-0.29,0.07-0.57,0.06-0.83-0.03c-0.26-0.09-0.48-0.24-0.67-0.47c-0.19-0.23-0.32-0.51-0.4-0.85
					l-0.04-0.17c-0.08-0.35-0.09-0.66-0.03-0.96c0.06-0.29,0.19-0.53,0.38-0.73c0.19-0.19,0.44-0.33,0.73-0.39
					c0.29-0.07,0.57-0.06,0.83,0.03c0.26,0.09,0.48,0.25,0.67,0.48c0.19,0.23,0.32,0.52,0.4,0.87L256.66,235.71z M255.89,235.72
					c-0.09-0.37-0.22-0.63-0.4-0.79c-0.18-0.16-0.39-0.21-0.63-0.15c-0.24,0.06-0.41,0.2-0.49,0.42c-0.09,0.22-0.09,0.51,0,0.88
					l0.04,0.17c0.08,0.36,0.22,0.62,0.39,0.79c0.18,0.17,0.39,0.22,0.64,0.16c0.24-0.06,0.41-0.2,0.49-0.42
					c0.09-0.22,0.09-0.51,0-0.88L255.89,235.72z"/>
				<path className="svgc3-10" d="M258.01,235.85l0.29,1.24l-0.72,0.17l-0.83-3.51l1.37-0.32c0.26-0.06,0.51-0.07,0.73-0.02
					c0.22,0.05,0.41,0.15,0.56,0.3c0.15,0.15,0.25,0.34,0.3,0.57c0.08,0.34,0.03,0.64-0.16,0.9c-0.19,0.25-0.49,0.43-0.91,0.53
					L258.01,235.85z M257.87,235.26l0.65-0.15c0.19-0.05,0.33-0.12,0.41-0.24c0.08-0.11,0.1-0.25,0.06-0.42
					c-0.04-0.17-0.12-0.3-0.25-0.38c-0.13-0.08-0.28-0.1-0.46-0.07l-0.66,0.16L257.87,235.26z"/>
				<path className="svgc3-10" d="M262.51,234.49l-1.39,0.33l0.22,0.94l1.63-0.38l0.14,0.58l-2.35,0.55l-0.83-3.51l2.35-0.55l0.14,0.58
					l-1.62,0.38l0.2,0.84l1.39-0.33L262.51,234.49z"/>
				<path className="svgc3-10" d="M266.38,235.18l-0.72,0.17l-1.95-1.97l0.54,2.31l-0.72,0.17l-0.83-3.51l0.72-0.17l1.95,1.98l-0.55-2.31
					l0.72-0.17L266.38,235.18z"/>
			</g>
		</g>
	</g>
</g>
<polygon className="svgc1-0" points="311.8,155.14 88.75,155.14 87.21,145.08 313.35,145.08 "/>
<g>
	<polygon className="svgc2-0" points="240.95,158.09 159.6,158.09 154.09,128.33 246.46,128.33 	"/>
	<polygon className="svgc2-5" points="238.27,155.58 162.29,155.58 157.95,130.83 242.61,130.83 	"/>
</g>
<g>
	<g>
		<path className="svgc2-8" d="M227.32,204.49h-7.93c0.05,2.14,1.81,3.87,3.97,3.87C225.51,208.36,227.27,206.64,227.32,204.49z"/>
		<path className="svgc3-10" d="M228.59,203.51c0,1.08-0.33,2.08-0.88,2.91H219c-0.56-0.83-0.88-1.83-0.88-2.91c0-2.89,2.35-5.24,5.24-5.24
			C226.25,198.28,228.59,200.62,228.59,203.51z"/>
		<path className="svgc2-0" d="M225.85,198.86h-4.98c0.03-1.35,1.14-2.43,2.49-2.43C224.71,196.43,225.81,197.51,225.85,198.86z"/>
	</g>
</g>
<g id="stairs_1_">
	<g>
		<g>
			<path className="svgc3-6" d="M132.32,323.66c-0.29,0-0.54-0.21-0.59-0.5l-11.93-69.12c-0.06-0.33,0.16-0.64,0.49-0.7
				c0.33-0.06,0.64,0.16,0.7,0.49l11.93,69.12c0.06,0.33-0.16,0.64-0.49,0.7C132.39,323.66,132.35,323.66,132.32,323.66z"/>
		</g>
		<g>
			<path className="svgc3-6" d="M166.64,323.66c-0.29,0-0.55-0.21-0.6-0.51l-11.41-69.92c-0.05-0.33,0.17-0.64,0.5-0.69
				c0.33-0.05,0.64,0.17,0.69,0.5l11.41,69.92c0.05,0.33-0.17,0.64-0.5,0.69C166.7,323.66,166.67,323.66,166.64,323.66z"/>
		</g>
	</g>
	<g>
		<g>
			<path className="svgc3-3" d="M143.04,323.66c-0.04,0-0.07,0-0.11-0.01c-0.33-0.06-0.55-0.37-0.49-0.7l12.51-69.92
				c0.06-0.33,0.37-0.55,0.7-0.49c0.33,0.06,0.55,0.37,0.49,0.7l-12.51,69.92C143.58,323.45,143.33,323.66,143.04,323.66z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M108.51,323.66c-0.03,0-0.07,0-0.1-0.01c-0.33-0.06-0.55-0.37-0.49-0.7l11.71-69.45
				c0.06-0.33,0.37-0.55,0.7-0.5c0.33,0.06,0.55,0.37,0.49,0.7l-11.71,69.45C109.05,323.45,108.8,323.66,108.51,323.66z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M120.48,254.3c-0.38,0-0.69-0.31-0.7-0.69c0-0.39,0.3-0.7,0.69-0.71l34.74-0.4c0,0,0.01,0,0.01,0
				c0.38,0,0.69,0.31,0.7,0.69c0,0.39-0.3,0.7-0.69,0.71L120.48,254.3C120.49,254.3,120.49,254.3,120.48,254.3z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M152.68,267.62h-34.15c-0.38,0-0.7-0.31-0.7-0.7c0-0.38,0.31-0.7,0.7-0.7h34.15c0.38,0,0.7,0.31,0.7,0.7
				C153.37,267.31,153.06,267.62,152.68,267.62z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M150.19,280.95h-34.09c-0.39,0-0.7-0.31-0.7-0.7s0.31-0.7,0.7-0.7h34.09c0.39,0,0.7,0.31,0.7,0.7
				S150.58,280.95,150.19,280.95z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M147.93,294.27h-34.17c-0.38,0-0.7-0.31-0.7-0.7c0-0.38,0.31-0.7,0.7-0.7h34.17c0.38,0,0.7,0.31,0.7,0.7
				C148.63,293.96,148.32,294.27,147.93,294.27z"/>
		</g>
		<g>
			<path className="svgc3-3" d="M145.52,307.59h-34.17c-0.38,0-0.7-0.31-0.7-0.7c0-0.38,0.31-0.7,0.7-0.7h34.17c0.38,0,0.7,0.31,0.7,0.7
				C146.21,307.28,145.9,307.59,145.52,307.59z"/>
		</g>
	</g>
</g>
<g id="girl_5_">
	<path className="st3" d="M125.23,167.73c0,0-6.44,10.22-5.53,11.91c0.91,1.69,18.32,2.82,18.32,2.82l-0.37-2.79
		c0,0-12.59-1.52-12.88-1.99c-0.3-0.47,6.01-10.03,6.01-10.03L125.23,167.73z"/>
	<path className="svgc1-0" d="M129.42,162.35c-1.86,0-4.35,2.76-3.8,7.25c0.55,4.49,2.42,14.75,2.42,14.75h12.91c0,0,2-17.91,0.48-19.31
		c-1.52-1.41-3.38-2.69-3.38-2.69H129.42"/>
	<path className="st3" d="M147.13,168.01c0,0,7.94-0.23,8.76-1.96c0.82-1.74,2.28-14.61,2.28-14.61l-3.02,1.68
		c0,0-2.66,9.86-2.87,10.38s-8.62,0.3-8.62,0.3L147.13,168.01z"/>
	<path className="svgc2-0" d="M137.6,163.14c2.12-0.45,5.86-0.34,9.86,0.27l0.37,5.1l-7.92-0.01c-1.7,0.11-3.21-0.95-3.65-2.58l0,0
		C136.01,164.99,136.64,163.34,137.6,163.14z"/>
	<path className="st3" d="M142.95,149.56c0,0,1.4,3.96-0.95,8.18c-0.14,0.26-1.96-0.04-2.58,0.63c-0.61,0.67-1.51,3.59-1.51,3.59
		l-3.25-6.77l4.76-7.38L142.95,149.56z"/>
	<path className="svgc3-0" d="M138.05,144.73c2.35,0,6.4,1.14,6.14,3.76c-0.27,2.62-2.89,1.94-3.72,3.82s-2.76,4.05-2.42,6.44
		c0.35,2.39,1.24,6.44,0.28,8.11c-0.97,1.68-4.56,2.82-4.9,3.69c-0.35,0.87-3.73,5.43-7.53,2.48c-3.8-2.95-6.14-5.98-2.35-9.66
		c4.7-4.56,1.45-8.45,3.38-11C128.87,149.82,132.11,144.73,138.05,144.73z"/>
	<path className="st3" d="M140.38,153.57c0.02-0.12-0.03-0.23-0.12-0.3c-0.33-0.25-1.12-0.73-1.66,0.06c-0.59,0.86,0.27,1.97,1.08,2.28
		c0.2,0.08,0.43-0.06,0.46-0.27L140.38,153.57z"/>
	<path className="svgc3-0" d="M125.7,249.47v2.88c0,0.36,0.25,0.65,0.56,0.65h9.9v-0.36c0-0.29-0.2-0.54-0.48-0.62
		c-1.19-0.33-4.31-1.28-5.86-2.63C127.29,247.17,125.7,249.47,125.7,249.47z"/>
	<path className="svgc3-0" d="M140.95,249.41v2.88c0,0.36,0.25,0.65,0.56,0.65h9.9v-0.36c0-0.29-0.2-0.54-0.48-0.62
		c-1.19-0.33-4.23-1.2-5.77-2.55C142.62,247.19,140.95,249.41,140.95,249.41z"/>
	<path className="svgc3-1" d="M134.46,184.36h-6.42c0,0-4.53,3.36-3.94,9.82c0.47,5.07,1.59,55.29,1.59,55.29l4.12-0.08l4.64-39.72V184.36"
		/>
	<polyline className="st5" points="157.14,147.96 157.14,146.6 163.29,146.49 163.38,143.19 156.83,143.19 	"/>
	<path className="svgc3-2" d="M140.95,184.36l4.2,65.05h-4.2c0,0-10.07-47.68-11.79-54.12c-1.57-5.89,2.39-10.93,2.39-10.93"/>
	<path className="svgc3-0" d="M157.9,151.31h-1.92c-0.09,0-0.15-0.07-0.15-0.15v-3.36c0-0.09,0.07-0.15,0.15-0.15h1.92
		c0.09,0,0.15,0.07,0.15,0.15v3.36C158.05,151.25,157.98,151.31,157.9,151.31z"/>
	<path className="st3" d="M155.01,149.56c0,0-0.59,2.97-0.23,3.52c0.8,1.22,2.74,0.91,2.74,0.91s1.24-0.25,1.4-0.91
		s0.11-3.46-0.48-3.46c-0.26,0-0.56-0.13-0.8-0.04c-0.42,0.17-0.36,0.67-0.93,0.86c-0.95,0.32-0.64-0.4-1.03-0.73
		C155.47,149.53,155.42,149.48,155.01,149.56z"/>
	<path className="svgc1-8" d="M161.24,144.78h-8.59c-0.55,0-1-0.45-1-1v-1.17c0-0.55,0.45-1,1-1h8.59c0.55,0,1,0.45,1,1v1.17
		C162.24,144.33,161.79,144.78,161.24,144.78z"/>
	<path className="svgc2-0" d="M159.21,144.78h-4.8c-0.84,0-1.53-0.69-1.53-1.53v-0.15c0-0.84,0.69-1.53,1.53-1.53h4.8
		c0.84,0,1.53,0.69,1.53,1.53v0.15C160.74,144.09,160.05,144.78,159.21,144.78z"/>
</g>
<ellipse className="svgc3-9 st0" cx="142.38" cy="324.23" rx="40.75" ry="4.03"/>
<g>
	<g>
		<path className="svgc2-0" d="M173.22,145.3c0.21,0.12,0.48,0.23,0.8,0.35c0.32,0.12,0.66,0.21,1.02,0.29c0.36,0.08,0.67,0.11,0.95,0.11
			c0.34,0,0.62-0.05,0.85-0.14c0.23-0.09,0.4-0.21,0.52-0.36c0.12-0.15,0.18-0.29,0.18-0.43c0-0.14-0.09-0.28-0.27-0.43
			s-0.41-0.3-0.69-0.46c-0.28-0.16-0.57-0.32-0.88-0.5c-0.38-0.23-0.78-0.48-1.2-0.73c-0.41-0.26-0.8-0.54-1.16-0.86
			c-0.36-0.31-0.65-0.67-0.88-1.08s-0.33-0.85-0.33-1.36c0.01-0.65,0.2-1.24,0.55-1.75c0.36-0.51,0.87-0.92,1.53-1.22
			c0.66-0.3,1.45-0.45,2.34-0.45c0.59,0,1.21,0.08,1.85,0.25c0.63,0.17,1.19,0.41,1.65,0.73l-0.95,2.15
			c-0.42-0.15-0.77-0.26-1.04-0.33s-0.52-0.12-0.74-0.15c-0.22-0.03-0.47-0.04-0.74-0.04c-0.36,0-0.65,0.08-0.86,0.25
			s-0.31,0.36-0.31,0.59c0,0.16,0.08,0.31,0.25,0.46c0.17,0.14,0.39,0.28,0.66,0.41c0.27,0.13,0.57,0.29,0.88,0.48
			c0.42,0.25,0.84,0.5,1.27,0.77s0.81,0.57,1.16,0.89c0.35,0.33,0.63,0.69,0.85,1.08s0.32,0.83,0.32,1.32
			c0,0.66-0.19,1.26-0.57,1.79c-0.38,0.53-0.92,0.95-1.64,1.25c-0.71,0.3-1.56,0.46-2.54,0.46c-0.3,0-0.63-0.02-0.99-0.07
			c-0.36-0.05-0.71-0.11-1.07-0.2c-0.36-0.09-0.69-0.19-1.01-0.31c-0.32-0.12-0.6-0.25-0.83-0.38L173.22,145.3z"/>
		<path className="svgc2-0" d="M188.99,136.53l4.44,11.76l-3.13,0.31l-0.86-2.52h-4.11l-0.82,2.34h-3.17l4.46-11.9H188.99z M187.34,140.34l-1.14,3.24
			h2.33L187.34,140.34z"/>
		<path className="svgc2-0" d="M203.54,148.43h-8.54v-11.9h3.18v9.4h5.06L203.54,148.43z"/>
		<path className="svgc2-0" d="M210.39,148.67c-1.26,0-2.35-0.27-3.27-0.82c-0.92-0.55-1.63-1.29-2.13-2.22c-0.5-0.93-0.74-1.96-0.74-3.1
			c0-1.22,0.26-2.31,0.79-3.25c0.52-0.94,1.25-1.67,2.18-2.2c0.93-0.53,1.99-0.8,3.18-0.8c1.29,0,2.4,0.27,3.32,0.82
			c0.92,0.55,1.62,1.28,2.11,2.2c0.49,0.92,0.74,1.95,0.74,3.1c0,1.22-0.26,2.31-0.78,3.25c-0.52,0.95-1.24,1.68-2.17,2.21
			C212.67,148.41,211.6,148.67,210.39,148.67z M210.37,146.01c0.55,0,1.04-0.13,1.48-0.4c0.44-0.27,0.78-0.67,1.04-1.21
			c0.26-0.54,0.38-1.2,0.38-1.99c0-0.67-0.11-1.26-0.34-1.78s-0.55-0.93-0.98-1.23c-0.43-0.3-0.94-0.45-1.53-0.45
			c-0.53,0-1.01,0.15-1.45,0.45c-0.44,0.3-0.8,0.71-1.06,1.24s-0.39,1.16-0.39,1.88c0,0.66,0.11,1.26,0.34,1.79
			c0.23,0.53,0.55,0.95,0.98,1.25C209.26,145.86,209.77,146.01,210.37,146.01z"/>
		<path className="svgc2-0" d="M218.47,148.43v-11.9h2.96l3.73,5.25l0.91,1.21l-0.05-6.46h3.18v11.9h-2.97l-3.67-5.04l-0.89-1.22v6.26H218.47z"/>
	</g>
</g>
<g className="st0">
	<ellipse className="svgc3-9" cx="314.99" cy="320.67" rx="55.83" ry="5.08"/>
</g>
<g id="button_26_">
	<g id="button_27_">
		<g>
			<path className="svgc2-0" d="M349.59,281.93h-51.24c-5.25,0-9.5-4.25-9.5-9.5v0c0-5.25,4.25-9.5,9.5-9.5h51.24c5.25,0,9.5,4.25,9.5,9.5v0
				C359.09,277.68,354.84,281.93,349.59,281.93z"/>
		</g>
	</g>
</g>
<g id="girl_00000012444636229131175960000017682300937419944330_">
	<g>
		<path className="st3" d="M292.34,211.97c0,0,1.32,9.4,4.07,9.84c2.75,0.45,8.53-3.95,8.53-3.95l-5.34-9.08L292.34,211.97z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M306.58,218.93c0,0,3.22-0.98,4.51,1.03c1.29,2.01,1.99,6.09,4.4,6.88c2.41,0.79,3.82,5.17,2.68,8.07
			c-1.14,2.9-1.57,4.06-1.14,5.66c0.43,1.6-4.59,3.76-7.32,0.31c-2.72-3.45-7.57-14.37-6.04-18.27
			C305.2,218.7,306.58,218.93,306.58,218.93z"/>
	</g>
	<g>
		
			<rect x="293.46" y="233.43" transform="matrix(0.9831 0.183 -0.183 0.9831 49.3638 -49.8268)" className="st4" width="2.47" height="18.33"/>
	</g>
	<g>
		<path className="svgc1-0" d="M304.28,219.75L304.28,219.75c-0.23-0.42-0.07-0.94,0.35-1.17l1.12-0.61c0.42-0.23,0.94-0.07,1.17,0.35l0,0
			c0.23,0.42,0.07,0.94-0.35,1.17l-1.12,0.61C305.03,220.32,304.51,220.17,304.28,219.75z"/>
	</g>
	<g>
		<path className="st3" d="M303.5,217.75c0,0,3.06,5.78,4,6.27c0.12,0.06-0.7,3.46-3.63,3.75c-4.07,0.39-4.26-2.18-4.26-2.18
			s0.94-0.98,0.98-1.07c0.52-1.16-0.77-4.89-0.77-4.89L303.5,217.75z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M273.58,294.82l0.12,3.22c0.02,0.4-0.24,0.74-0.58,0.75l-10.76,0.41l-0.02-0.41
			c-0.01-0.32,0.19-0.61,0.5-0.71c1.28-0.42,4.55-1.52,6.17-3.1C271.66,292.41,273.58,294.82,273.58,294.82z"/>
	</g>
	<g>
		<path className="svgc1-0" d="M312.17,234.17c0.83-6.37-1.95-9.01-4.68-10.15c0,0-2.94,3.45-5.43,3.18c-1.37-0.15-1.47-2.69-1.47-2.69
			s-0.61,0.58-2.24,2.18c-2.62,2.55-5.25,6.23-2.24,8.8c0.91,0.77-1.49,13.47-1.49,13.47l14.45,1.56
			C309.07,250.5,311.44,239.79,312.17,234.17z"/>
	</g>
	<g>
		<path className="svgc3-1" d="M294.62,248.95c0,0-15.87,4.86-21.78,7.62c-1.19,0.56-2.11,1.64-2.29,2.95c-0.94,6.83-1.53,35.49-1.53,35.49
			l4.57-0.18l3.22-26.76l28.94-17.91L294.62,248.95z"/>
	</g>
	<g>
		<path className="st3" d="M308.79,236.43c0,0-2.73,8.72-4.55,9.84c-1.82,1.12-14.73-6.47-14.73-6.47l0.98-2.14
			c0,0,10.73,4.51,11.28,4.22c0.55-0.29,2.66-8.62,2.66-8.62L308.79,236.43z"/>
	</g>
	<g>
		<path className="svgc2-0" d="M309.02,225.95c-2.12,0.17-2.99,1.56-5.18,8.5c-0.04,0.13,0.01,0.28,0.13,0.36l4.43,2.42
			c0.1,0.07,0.24,0.03,0.3-0.08c0.63-1.31,2.73-5.75,2.21-8.4C310.69,227.6,310.18,225.86,309.02,225.95z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M288.69,237.68l-3.05,0c-0.16,0-0.31-0.08-0.39-0.22l-2.88-4.73c-0.19-0.31,0.03-0.7,0.39-0.7l3.05,0
			c0.16,0,0.31,0.08,0.39,0.22l2.88,4.73C289.27,237.28,289.05,237.68,288.69,237.68z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M293.56,206.64c1.66,0.4,1.78-2.7,7.05-2.13c3.66,0.39,4.82,2.07,6.63,6.46c2.5,6.05-1.89,8.71-3.44,8.17
			c-3.8-1.3-3.55-3.6-3.55-3.6s-1.19-2.41-1.43-2.35c-2.89,0.77-6.18-1.01-6.18-1.01s-0.15,2.17-1.4,1.95
			c-1.25-0.22-1.13-2.9-0.95-4.07C290.48,208.9,291.88,206.24,293.56,206.64z"/>
	</g>
	<g>
		<path className="svgc3-0" d="M293.33,291.72l1.9,2.7c0.24,0.32,0.22,0.75-0.05,0.95l-8.65,6.42l-0.24-0.33c-0.19-0.26-0.19-0.61,0.01-0.87
			c0.83-1.07,2.9-3.82,3.34-6.04C290.37,290.91,293.33,291.72,293.33,291.72z"/>
	</g>
	<g>
		<path className="st3" d="M298.63,213.77c-0.04-0.13-0.01-0.26,0.07-0.36c0.3-0.34,1.06-1.02,1.79-0.25c0.8,0.84,0.1,2.22-0.71,2.71
			c-0.2,0.12-0.47,0.02-0.54-0.21L298.63,213.77z"/>
	</g>
	<g>
		<path className="svgc3-2" d="M308.83,250.48c0.15,0.02,0.27,0.12,0.31,0.26c0.44,1.67,2.73,11.58-4.76,12.09
			c-9.09,0.61-17.82,2.64-19.72,3.1c-0.21,0.05-0.32,0.27-0.25,0.46l8.84,25.06c0.05,0.15,0,0.31-0.13,0.41l-3.16,2.31
			c-0.17,0.13-0.42,0.07-0.52-0.12l-14.29-25.97c-0.01-0.02-0.02-0.03-0.02-0.05c-0.15-0.41-1.84-5.52,3.17-8.25
			c4.98-2.71,19.12-9.73,20.37-10.36c0.06-0.03,0.13-0.04,0.19-0.03L308.83,250.48z"/>
	</g>
	<g>
		<path className="st3" d="M290.49,237.67c0,0-0.58-1.18-1.8-1.15c-1.22,0.03-3.88-0.02-4.13,0.74c-0.25,0.76,2.41,2.72,4.94,2.55
			C292.04,239.65,290.49,237.67,290.49,237.67z"/>
	</g>
</g>
<g>
	<g>
		<path className="svgc1-7" d="M312.03,111.21l-12.94-3.26c-0.37-0.09-0.63-0.42-0.64-0.8l-0.27-17.09l-5.14-1.29
			c-0.45-0.11-0.72-0.57-0.61-1.02c0.11-0.45,0.57-0.72,1.02-0.61l5.76,1.45c0.37,0.09,0.63,0.42,0.64,0.8l0.27,17.09l12.31,3.1
			c0.45,0.11,0.72,0.57,0.61,1.02S312.48,111.33,312.03,111.21z"/>
	</g>
	<g>
		<path className="svgc1-7" d="M313.41,107.53l-14.35-3.61c-0.37-0.09-0.63-0.42-0.64-0.8l-0.15-9.09c0-0.26,0.11-0.51,0.32-0.67
			c0.21-0.16,0.47-0.22,0.73-0.16l19.17,4.82c0.26,0.06,0.47,0.25,0.57,0.49c0.1,0.25,0.08,0.52-0.05,0.75l-4.68,7.88
			C314.15,107.46,313.77,107.62,313.41,107.53z M300.1,102.44l13.11,3.3l3.78-6.37l-17.01-4.28L300.1,102.44z"/>
	</g>
	<g>
		<circle className="svgc1-7" cx="308.19" cy="113.85" r="2.1"/>
	</g>
	<g>
		<circle className="svgc1-7" cx="300.78" cy="111.99" r="2.1"/>
	</g>
	<g>
		
			<rect x="300.33" y="99.1" transform="matrix(0.244 -0.9698 0.9698 0.244 133.4328 371.0586)" className="svgc1-7" width="8.78" height="1.68"/>
	</g>
	<g>
		
			<rect x="306.15" y="100.57" transform="matrix(0.244 -0.9698 0.9698 0.244 136.4149 377.8161)" className="svgc1-7" width="8.78" height="1.68"/>
	</g>
	<g>
		
			<rect x="306.7" y="91.83" transform="matrix(0.244 -0.9698 0.9698 0.244 135.0547 374.1984)" className="svgc1-7" width="1.68" height="17.3"/>
	</g>
</g>
</svg>
)
}
