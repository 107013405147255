import { TableCell, TableRow, TextField } from "@mui/material";
import useCurrency from "src/hooks/general/useCurrency";
import MoneyField from 'src/components/general/MoneyField';

interface Props {
  title: string;
  expectedCentValue: number;
  moneyField?: {
    value: number | undefined;
    onChange: (value: number) => void;
  },
  buttonComponent?: React.ReactNode;
}

const Row = ({
  title,
  expectedCentValue,
  moneyField,
  buttonComponent
}: Props) => {
  const { numberToPriceString } = useCurrency();

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{numberToPriceString(expectedCentValue / 100)}</TableCell>
      <TableCell>
        {/* Moneyfield todo */}
        {!!moneyField && (
          <MoneyField 
            value={moneyField.value}
            onChange={(value) => moneyField.onChange(value ? value : 0)}
            fieldProps={{
              allowNegative: true
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {buttonComponent}
      </TableCell>
      {!!moneyField ? (
        <TableCell>
          {numberToPriceString((moneyField.value ? moneyField.value : 0) - expectedCentValue / 100)}
        </TableCell>
      ) : (
        <TableCell />
      )}
    </TableRow>
  )
}
export default Row;