import type { FormValues } from './types';

export const DEFAULT_VALUES: FormValues = {
  template_id: '',
  subscription_id: '',
  company_name: '',
  alias: '',
  language: 'nl',
  cname: '',
  contact: {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    c_password: ''
  },
  colors: {
    light_1: '',
    light_2: '',
    light_3: '',
    dark_1: '',
    dark_2: '',
    dark_3: ''
  },
  images: {
    background: null,
    logo_light: null,
    favicon_light: null,
    logo_dark: null,
    favicon_dark: null
  }
}