import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars';

interface SuperScrollbarProps extends ScrollbarProps {
  rootRef?: React.LegacyRef<HTMLDivElement>;
}

const SuperScrollbar = ({
  rootRef,
  ...props
}: SuperScrollbarProps) => {
  return (
    <Scrollbars 
      autoHide 
      autoHeight 
      autoHeightMax='100%' 
      renderView={(props) => (
        <div
          {...props}
          ref={rootRef}
        />
      )}
      {...props} 
    />
  )
};
export default SuperScrollbar;