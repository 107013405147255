import { Avatar, Slide, Tooltip } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'src/store';
import { UserSmall } from 'src/types/api/user';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none'
  },
  subRoot: {
    marginTop: 64
  },
  muiAlertRoot: {
    padding: '0px 10px',
    borderRadius: '0px 0px 4px 4px'
  },
  smallAvatar: {
    width: 19,
    height: 19
  }
}))

type CurrentAlert = {
  open: boolean;  
  message: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
  } | null;
}

interface Props {
  currentAlert: CurrentAlert;
  setCurrentAlert: React.Dispatch<React.SetStateAction<CurrentAlert>>;
  timeout: number;
}

const AlertToast = ({ 
  currentAlert, 
  setCurrentAlert, 
  timeout 
}: Props) => {
  const { classes } = useStyles();
  const avatars = useSelector(state => state.misc.avatars);
  const avatar = currentAlert.user ? (avatars[currentAlert.user.id] ? avatars[currentAlert.user.id] : null) : null;

  useEffect(() => {
    if (currentAlert.open) {
      setTimeout(() => {
        setCurrentAlert({
          ...currentAlert,
          open: false
        });
      }, timeout);
    }
  }, [currentAlert]);

  return (
    <Slide
      in={currentAlert.open}
      timeout={{
        enter: 500,
        exit: 800
      }}
    >
      <div className={classes.root}>
        <div className={classes.subRoot}>
          {avatar ?
            <Alert
              severity='info'
              color="warning"
              variant="filled"
              classes={{ root: classes.muiAlertRoot }}
              icon={
                <Tooltip title={currentAlert.user ? `${currentAlert.user.first_name} ${currentAlert.user.last_name}` : ''}>
                  <Avatar
                    className={classes.smallAvatar}
                    src={`data:image/jpeg;base64,${avatar}`}
                  />
                </Tooltip>
              }
            >
              {currentAlert.message}
            </Alert>
            :
            <Alert
              severity='info'
              color="warning"
              variant="filled"
              classes={{ root: classes.muiAlertRoot }}
            >
              {currentAlert.message}
            </Alert>
          }
        </div>
      </div>
    </Slide>
  )
}

export default AlertToast;