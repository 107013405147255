import dayjs, { Dayjs } from "dayjs";
import { ErrorOption } from "react-hook-form";

interface DateInvalidError {
  name: 'start_date' | 'end_date';
  error: ErrorOption;
}

const datesAreInvalid = (start_date: Dayjs | null, end_date: Dayjs | null): DateInvalidError | null => {
  if (start_date && !dayjs(start_date).isValid()) {
    return {
      name: 'start_date',
      error: { type: 'manual', message: 'error.invalid_date' }
    }
  }
  if (end_date && !dayjs(end_date).isValid()) {
    return {
      name: 'end_date',
      error: { type: 'manual', message: 'error.invalid_date' }
    }
  }

  if (start_date || end_date) {
    if (!start_date) {
      return {
        name: 'start_date',
        error: { type: 'manual', message: 'error.both_dates_or_none' }
      }
    }
    if (!end_date) {
      return {
        name: 'end_date',
        error: { type: 'manual', message: 'error.both_dates_or_none' }
      }
    }

    if (dayjs(end_date).isBefore(start_date)) {
      return {
        name: 'end_date',
        error: { type: 'manual', message: 'error.end_date_before_start_date' }
      }
    }
  }
  return null;
}
export default datesAreInvalid;