import { alpha, Box, Typography, LinearProgress } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useSelector } from 'src/store';
import { useTranslation } from 'src/i18n';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import useIsMobile from 'src/hooks/general/useIsMobile';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginLeft: '6px',
    padding: '4px',
    color: theme.palette.text.primary,
    borderRadius: '6px',
    transition: '0.5s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.22)
    }
  },
  primaryText: {
    lineHeight: '1'
  },
  secondaryText: {
    color: theme.palette.primary.main,
    lineHeight: '1'
  },
  icon: {
    color: theme.palette.primary.main,
    animationName: '$blinker',
    animationDuration: '1s',
    animationIterationCount:'infinite',
    animationDirection: 'alternate'
  },
  '@keyframes blinker': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
}));

const ActiveDataTransfer = () => {
  const { classes } = useStyles();
  const { downloads, uploads } = useSelector(state => state.dataTransfer);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobile = useIsMobile();

  const progressValue = useMemo(() => {
    let value = 0;
    downloads.map((download) => value += download.progress);
    uploads.map((upload) => value += upload.progress);
    return value / (downloads.length + uploads.length); 
  }, [downloads, uploads])

  const activeDownUp = useMemo(() => {
    const activeDownloadCount = downloads.filter((val) => !val.finished).length;
    const activeUploadCount = uploads.filter((val) => !val.finished).length;
    return activeDownloadCount + activeUploadCount;
  }, [downloads, uploads]);

  if (!downloads.length && !uploads.length) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.root}
      onClick={() => { navigate('/files'); }}
    >
      <ImportExportIcon className={classes.icon} />
      {!mobile && 
        <div style={{marginLeft: '5px', maxWidth: '100px'}}>
          <Typography
            className={classes.primaryText}
            variant="h6"
            noWrap
            textOverflow='ellipsis'
          >
            {activeDownUp === 1 ?
              downloads.length ? downloads[0].name : uploads[0].file.name
              :
              `${activeDownUp} ${t('lang.files')}`
            }
          </Typography>
          <LinearProgress 
            variant='determinate'
            value={progressValue}
            style={{ marginTop: 6, marginBottom: 8 }}
          />
        </div>
      }
    </Box>

  )
}

export default ActiveDataTransfer;