import type { AppDispatch } from 'src/store/index';
import { setVatrates } from 'src/slices/lists/invoicing';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch
) => {
  console.log('TODO: invoice vatrate type');
  channel.listen('.update_vatrate', (data: any) => {
    const vatrate = data.data.response;
    dispatch(setVatrates(vatrate));
  })
}