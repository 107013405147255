import DatePicker from './components/DatePicker';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material';
import { useTranslation } from 'src/i18n';

interface Props {
  value: {
    start: dayjs.Dayjs | null;
    end: dayjs.Dayjs | null;
  },
  onChange: (value: { start: dayjs.Dayjs | null; end: dayjs.Dayjs | null; }) => void;
  error?: {
    start: boolean;
    end: boolean;
  };
  onError?: React.Dispatch<React.SetStateAction<{ start: boolean; end: boolean; }>>;
  disabled?: boolean;
}

const DateRangePicker = ({
  value,
  onChange,
  error,
  onError,
  disabled = false
}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const handleChange = (val: dayjs.Dayjs | null, position: 'start' | 'end') => {
    if(!val){
      onChange({ start: null, end: null });
      return;
    }
    
    if(position === 'start'){
      let newEndValue = value.end ? value.end : val;
      if(value.end){
        const diffSeconds = (val.isValid() && value.start && value.start.isValid()) ? val.diff(value.start, 'seconds') : 0;
        newEndValue = newEndValue.add(diffSeconds, 'seconds');
      }
      onChange({ start: val, end: newEndValue });
    }
    if(position === 'end'){
      const newStartValue = value.start ? value.start : val;
      onChange({ start: newStartValue, end: val });
    }
  }
  
  return (
    <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        <DatePicker 
          value={value.start}
          onChange={(val) => handleChange(val, 'start')}
          error={error?.start}
          onError={(val) => onError && onError(prev => ({ ...prev, start: val }))}
          textFieldProps={{
            label: `${t('lang.start')}`
          }}
          disabled={disabled}
        />
      </div>
      <div 
        style={{ 
          color: theme.palette.text.primary, 
          margin: `0px ${theme.spacing(3)}`,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <span style={{ marginTop: theme.spacing(1.5) }}>
          &#8212;
        </span>
      </div>
      <div style={{ display: 'inline-flex' }}>
        <DatePicker
          value={value.end}
          onChange={(val) => handleChange(val, 'end')}
          error={error?.end}
          onError={(val) => onError && onError(prev => ({ ...prev, end: val }))}
          // minTime={(value.start && value.start.isValid()) ? value.start : undefined} // ???
          staticDatePickerProps={{
            minDate: (value.start && value.start.isValid()) ? value.start : undefined
          }}
          textFieldProps={{
            label: `${t('lang.end')}`
          }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
export default DateRangePicker;