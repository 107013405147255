import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, useDispatch } from 'src/store';
import type { ChatMessage as TMessage } from 'src/types/api/chatMessage';

type TMessagesState = {
  [uid: string]: TMessage[]
}
const initialState: TMessagesState = {};

const slice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {
    setChatMessages(state: TMessagesState, action: PayloadAction<TMessagesState>){
      return action.payload;
    },
    addChatMessage(state: TMessagesState, action: PayloadAction<{data: TMessage, receiverId: string}>){
      if(state[action.payload.receiverId]){
        state[action.payload.receiverId].push(action.payload.data);
      }else{
        state[action.payload.receiverId] = [action.payload.data];
      }
    },
    removeChatMessage(state: TMessagesState, action: PayloadAction<{receiverId: string, messageId: string}>){
      state[action.payload.receiverId].filter((val) => val.id !== action.payload.messageId);
    },
    prependChatMessages(state: TMessagesState, action: PayloadAction<{receiverId: string, data: TMessage[]}>){
      state[action.payload.receiverId].unshift(...action.payload.data);
    }
  }
})

export const { reducer } = slice;

export const setChatMessages = (data: TMessagesState): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setChatMessages(data));
}

export const addChatMessage = (data: TMessage, receiverId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addChatMessage({data, receiverId}));
}

export const removeChatMessage = (receiverId: string, messageId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeChatMessage({receiverId, messageId}));
}

export const prependChatMessages = (receiverId: string, data: TMessage[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.prependChatMessages({receiverId, data}));
}

export default slice;