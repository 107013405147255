import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { TimeTracker as ITimeTracker } from 'src/types/api/timetracker';

const initialState: ITimeTracker[] = [];

const slice = createSlice({
  name: 'timetrackers',
  initialState,
  reducers: {
    setTimeTrackers(state: ITimeTracker[], action: PayloadAction<ITimeTracker[]>){
      return action.payload;
    },
    createTimeTracker(state: ITimeTracker[], action: PayloadAction<ITimeTracker>){
      state.push(action.payload);
    },
    updateTimeTracker(state: ITimeTracker[], action: PayloadAction<ITimeTracker>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteTimeTracker(state: ITimeTracker[], action: PayloadAction<ITimeTracker>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setTimeTrackers = (data: ITimeTracker[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTimeTrackers(data));
}

export const createTimeTracker = (data: ITimeTracker): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createTimeTracker(data));
}

export const updateTimeTracker = (data: ITimeTracker): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTimeTracker(data));
}

export const deleteTimeTracker = (data: ITimeTracker): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteTimeTracker(data));
}

export default slice;