import { useEffect, useState, useMemo } from "react";
import type { Breakpoint } from '@mui/material';

const useCurrentBreakpoint = (): Breakpoint => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth); 
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const finalWidth = useMemo(() => {
    let finalWidth: Breakpoint = 'xs';
    if(width > 600){ finalWidth = 'sm'; }
    if(width > 960){ finalWidth = 'md'; }
    if(width > 1280){ finalWidth = 'lg'; }
    if(width > 1920){ finalWidth = 'xl'; }
    return finalWidth;
  }, [width])

  return finalWidth;
}

export default useCurrentBreakpoint;