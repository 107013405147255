import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ContactAddress } from 'src/types/api/contact';
import { Event } from 'src/types/api/events/Event';
import { RegisterDayClosingSmall } from 'src/types/api/financial/dayClosing';
import { Invoice } from 'src/types/api/financial/invoice';
import type { Payment, PaymentType } from 'src/types/api/financial/payment';

export interface CheckoutRow {
  id: string;
  item_id: string | null;
  count: number | undefined;
  discount: number | undefined;
  type: 'product' | 'service' | 'custom' | 'giftcard' | 'event';
  event?: Event;
  divergentPrice?: number | undefined;
  customValues?: {
    title: string;
    price: number | undefined;
    vatrate_id: string | null;
  }
}

export interface PaymentRow {
  id: string;
  payment_option_id: PaymentType;
  amount: number;
  giftcard_id?: string;
  previousPayment: boolean;
}

type CheckoutInvoiceToUpdate = { invoice: Invoice; isPayment: boolean; deletedPaymentIds: string[]; };

type CheckoutSelectedContact = {
  contact_id: string | null; // updated value
  address: ContactAddress | null; // updated value
  party: Invoice['parties']['contact'] | null;
}

type RegisterState = {
  checkout: {
    selectedContact: CheckoutSelectedContact;
    openRowId: string | null;
    rows: CheckoutRow[];
    atPayment: boolean;
    paymentRows: PaymentRow[];
    paymentFieldAmount: number | undefined;
    invoiceDiscount: number | undefined;

    invoiceToUpdate: CheckoutInvoiceToUpdate | null;
  },
  dayClosings: RegisterDayClosingSmall[];
  // dayClosings // TODO !!!
}

const initialState: RegisterState = {
  checkout: {
    selectedContact: { contact_id: null, address: null, party: null },
    openRowId: null,
    rows: [],
    atPayment: false,
    paymentRows: [],
    paymentFieldAmount: undefined,
    invoiceDiscount: undefined,

    invoiceToUpdate: null
  },
  dayClosings: []
}

const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setSelectedContact(state: RegisterState, action: PayloadAction<CheckoutSelectedContact>){
      state.checkout.selectedContact = action.payload;
    },
    setCheckoutRows(state: RegisterState, action: PayloadAction<CheckoutRow[]>){
      state.checkout.rows = action.payload;
    },
    createCheckoutRow(state: RegisterState, action: PayloadAction<CheckoutRow>){
      state.checkout.rows.push(action.payload);
    },
    updateCheckoutRow(state: RegisterState, action: PayloadAction<CheckoutRow>){
      state.checkout.rows = state.checkout.rows.map((row) => row.id !== action.payload.id ? row : action.payload);
    },
    deleteCheckoutRow(state: RegisterState, action: PayloadAction<CheckoutRow>){
      state.checkout.rows = state.checkout.rows.filter((row) => row.id !== action.payload.id);
    },
    setOpenRowId(state: RegisterState, action: PayloadAction<string | null>){
      state.checkout.openRowId = action.payload;
    },
    // payment
    setAtPayment(state: RegisterState, action: PayloadAction<boolean>){
      state.checkout.atPayment = action.payload;
    },
    setPaymentFieldAmount(state: RegisterState, action: PayloadAction<number | undefined>){
      state.checkout.paymentFieldAmount = action.payload;
    },
    // payment rows
    setPaymentRows(state: RegisterState, action: PayloadAction<PaymentRow[]>){
      state.checkout.paymentRows = action.payload;
    },
    createPaymentRow(state: RegisterState, action: PayloadAction<PaymentRow>){
      state.checkout.paymentRows.push(action.payload);
    },
    updatePaymentRow(state: RegisterState, action: PayloadAction<PaymentRow>){
      state.checkout.paymentRows = state.checkout.paymentRows.map((row) => row.id !== action.payload.id ? row : action.payload);
    },
    deletePaymentRow(state: RegisterState, action: PayloadAction<PaymentRow>){
      state.checkout.paymentRows = state.checkout.paymentRows.filter((row) => row.id !== action.payload.id);
    },

    setInvoiceDiscount(state: RegisterState, action: PayloadAction<number | undefined>){
      state.checkout.invoiceDiscount = action.payload;
    },

    setInvoiceToUpdate(state: RegisterState, action: PayloadAction<CheckoutInvoiceToUpdate | null>){
      state.checkout.invoiceToUpdate = action.payload;
    },    

    // day closings
    setRegisterDayClosings(state: RegisterState, action: PayloadAction<RegisterDayClosingSmall[]>){
      state.dayClosings = action.payload;
    },
    createRegisterDayClosing(state: RegisterState, action: PayloadAction<RegisterDayClosingSmall>){
      state.dayClosings.push(action.payload);
    },
    updateRegisterDayClosing(state: RegisterState, action: PayloadAction<RegisterDayClosingSmall>){
      state.dayClosings = state.dayClosings.map((row) => row.id !== action.payload.id ? row : action.payload);
    },
    deleteRegisterDayClosing(state: RegisterState, action: PayloadAction<RegisterDayClosingSmall>){
      state.dayClosings = state.dayClosings.filter((row) => row.id !== action.payload.id);
    },

    createRegisterDayClosingPayment(state: RegisterState, action: PayloadAction<Payment>){
      const getUpdatedPayments = (payments: Payment[]) => {
        const foundPayment = payments.find(payment => payment.id === action.payload.id);
        if(foundPayment){
          return [...payments];
        }
        return [action.payload, ...payments];
      }

      state.dayClosings = state.dayClosings.map((dayClosing) => dayClosing.id !== action.payload.day_closing_id ? dayClosing : ({
        ...dayClosing,
        payments: getUpdatedPayments(dayClosing.payments)
      }))
    },

    deleteRegisterDayClosingPayment(state: RegisterState, action: PayloadAction<string>){
      state.dayClosings = state.dayClosings.map((dayClosing) => ({
        ...dayClosing,
        payments: [
          ...dayClosing.payments.filter(payment => payment.id !== action.payload)
        ]
      }))
    },
    
    // == payment rows todo ==
    resetCheckout(state: RegisterState){
      state.checkout = initialState.checkout;
    }
  }
})

export const { reducer } = slice;

export const setSelectedContact = (data: CheckoutSelectedContact): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSelectedContact(data));
}
export const setCheckoutRows = (data: CheckoutRow[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCheckoutRows(data));
}
export const createCheckoutRow = (data: CheckoutRow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createCheckoutRow(data));
}
export const updateCheckoutRow = (data: CheckoutRow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateCheckoutRow(data));
}
export const deleteCheckoutRow = (data: CheckoutRow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteCheckoutRow(data));
}
export const setOpenRowId = (data: string | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setOpenRowId(data));
}

export const setAtPayment = (data: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAtPayment(data));
}
export const setPaymentFieldAmount = (data: number | undefined): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPaymentFieldAmount(data));
}

export const setPaymentRows = (data: PaymentRow[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPaymentRows(data));
}
export const createPaymentRow = (data: PaymentRow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createPaymentRow(data));
}
export const updatePaymentRow = (data: PaymentRow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePaymentRow(data));
}
export const deletePaymentRow = (data: PaymentRow): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deletePaymentRow(data));
}

export const setInvoiceDiscount = (data: number | undefined): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoiceDiscount(data));
}

export const setInvoiceToUpdate = (data: CheckoutInvoiceToUpdate | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoiceToUpdate(data));
}

export const setRegisterDayClosings = (data: RegisterDayClosingSmall[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRegisterDayClosings(data));
}
export const createRegisterDayClosing = (data: RegisterDayClosingSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createRegisterDayClosing(data));
}
export const updateRegisterDayClosing = (data: RegisterDayClosingSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRegisterDayClosing(data));
}
export const deleteRegisterDayClosing = (data: RegisterDayClosingSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteRegisterDayClosing(data));
}

export const createRegisterDayClosingPayment = (data: Payment): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createRegisterDayClosingPayment(data));
}
export const deleteRegisterDayClosingPayment = (data: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteRegisterDayClosingPayment(data));
}

export const resetCheckout = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetCheckout());
}

export default slice;