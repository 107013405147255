import { createLanguage, deleteLanguage, updateLanguage } from 'src/slices/lists/languages';
import { createPermission, deletePermission, updatePermission } from 'src/slices/lists/permissions';
import type { AppDispatch } from 'src/store/index';
import type { Permission } from 'src/types/api/permission';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_permission', (data: any) => {
    const permission = data.data.response as Permission;
    dispatch(createPermission(permission));
  });
  channel.listen('.update_permission', (data: any) => {
    const permission = data.data.response as Permission;
    dispatch(updatePermission(permission));
  });
  channel.listen('.delete_permission', (data: any) => {
    const permission = data.data.response as Permission;
    dispatch(deletePermission(permission));
  });
  channel.listen('.delete_permissions', (data: any) => {
    const permissions = data.data.response as Permission[];
    permissions.map((permission) => {
      dispatch(deletePermission(permission));
    });
  });
  channel.listen('.sort_permissions', (data: any) => {
    const permissions = data.data.response as Permission[];
    permissions.map((permission) => {
      dispatch(updatePermission(permission));
    });
  });
}