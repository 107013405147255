import { Typography, alpha } from "@mui/material";
import { makeStyles } from "src/theme/makeStyles";
import { ServiceCategory } from "src/types/api/service";

const useStyles = makeStyles<{ disableBorderTop: boolean; categoryColor: string; }>()((theme, { disableBorderTop, categoryColor }) => ({
  root: {
    borderTop: disableBorderTop ? 'none' : `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    color: categoryColor
  },
  innerRoot: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderLeft: `4px solid ${categoryColor}`,
    backgroundColor: alpha(categoryColor, 0.1)
  }
}));

interface Props {
  category: ServiceCategory;
  disableBorderTop: boolean;
}

const ServiceCategoryRow = ({
  category,
  disableBorderTop
}: Props) => {
  const { classes } = useStyles({ disableBorderTop , categoryColor: category.color });

  return (
    <div
      className={classes.root}
    >
      <div
        className={classes.innerRoot}
      >
        <Typography variant="body2" fontWeight={500}>
          {category.title}
        </Typography>
      </div>
    </div>
  )
}
export default ServiceCategoryRow;