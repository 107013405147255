import { makeStyles } from "src/theme/makeStyles";

const useStyles = makeStyles()(theme => ({
  st0: {
    display: 'none',
  },
  st1: {
    display: 'inline'
  },
  st2: {
    fill: theme.palette.text.primary
  },
  st3: {
    fill: theme.palette.text.primary
  }
}));

const Scissor = () => {
  const { classes } = useStyles();

  return (
    <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 217.81 217.41">
      <g id="NewGroupe85">
        <g id="NewGroup0-14">
          <g>
            <path className={classes.st0} d="M99.16,179.18c-2.72,0.83-5.16-0.31-6.23-2.9c-1.1-2.67-0.92-5.45,0.57-8.51c1.26-2.59,3.03-4.3,5.43-5.23
              c2.1-0.82,4.04-0.5,5.51,0.89c0.1,0.09,0.19,0.19,0.28,0.29c1.02,1.11,1.55,2.55,1.63,4.38c0.13,3.06-0.85,5.93-2.76,8.07
              C102.02,177.83,100.74,178.7,99.16,179.18L99.16,179.18z M108.52,160.24c-2.94-3.21-7.23-4.15-11.46-2.5
              c-3.61,1.41-6.37,4.02-8.2,7.78c-2.14,4.39-2.37,8.68-0.69,12.73c0.6,1.45,1.45,2.69,2.49,3.67c2.57,2.44,6.25,3.33,10.01,2.19
              c2.45-0.74,4.51-2.11,6.69-4.42c0.02-0.02,0.03-0.03,0.05-0.05c2.83-3.16,4.29-7.33,4.1-11.76
              C111.37,164.83,110.37,162.26,108.52,160.24L108.52,160.24z"/>
            <path className={classes.st0} d="M48.94,132.88c-2.36-0.19-3.89-1.1-4.82-2.85c-0.76-1.43-0.72-2.97,0.11-4.87c1.79-4.07,6.84-6.85,11.25-6.2
              c1.44,0.21,2.63,0.76,3.49,1.57c0.38,0.36,0.7,0.78,0.94,1.24c0.77,1.5,0.67,3.34-0.27,5.19c-0.31,0.61-0.74,1.21-1.13,1.75
              c-0.11,0.15-0.21,0.3-0.31,0.44C55.18,132.01,52.32,133.16,48.94,132.88L48.94,132.88z M62.72,131.71
              c0.44-0.61,1.04-1.45,1.52-2.39c1.7-3.32,1.8-6.93,0.27-9.9c-1.54-2.98-4.55-5.01-8.26-5.56c-6.67-0.99-14.02,3.06-16.73,9.22
              c-1.48,3.36-1.46,6.51,0.05,9.36c0.55,1.03,1.22,1.93,2.02,2.68c1.76,1.67,4.11,2.66,6.93,2.89c4.92,0.4,9.32-1.34,13.46-5.33
              c0.14-0.13,0.26-0.28,0.36-0.43C62.44,132.09,62.57,131.91,62.72,131.71L62.72,131.71z"/>
            <path className={classes.st0} d="M103.43,189.42c-5.32,2.29-10.11,1.99-14.65-0.9c-3.27-2.08-5.37-5.21-6.25-9.28
              c-1.73-8.08-0.2-15.13,4.54-20.94c2.7-3.3,5.59-6.57,8.39-9.72c1.55-1.75,3.15-3.55,4.71-5.35l0.32-0.37
              c1.49-1.72,3.03-3.49,4.37-5.44c1.15-1.67,2.08-3.53,2.69-5.38c1.68-5.07,2.04-10.3,2.39-15.36c0.02-0.26,0.04-0.52,0.05-0.78
              c2.22,1.32,4.8,1.81,7.31,1.49c-1.07,2.69-2.19,5.41-3.43,8c-2.53,5.25-3.24,10.37-2.19,15.66c0.6,3.01,1.28,6.03,1.94,8.96
              c0.46,2.08,0.95,4.23,1.39,6.34c0.2,0.93,0.41,1.86,0.62,2.76c0.6,2.6,1.16,5.06,1.36,7.52l0,0
              C117.78,176.12,112.07,185.71,103.43,189.42L103.43,189.42z M102.46,113.02c-2.88,0.47-5.85,0.97-8.78,1.55
              c-4.15,0.83-7.93,2.97-11.58,6.55c-1.33,1.3-2.66,2.6-3.99,3.9c-3.34,3.26-6.8,6.63-10.14,10.02c-5.38,5.45-11.4,8.22-18.4,8.45
              c-4.38,0.15-7.93-0.72-10.86-2.64c-5.04-3.31-7.14-7.95-6.44-14.2c0.52-4.64,2.79-8.79,6.73-12.33c5-4.5,10.66-6.61,16.82-6.29
              c2.46,0.13,4.9,0.57,7.5,1.04c1.03,0.19,2.1,0.38,3.17,0.55c1.84,0.29,3.68,0.6,5.51,0.91c2.95,0.49,6.01,1,9.03,1.45
              c1.28,0.19,2.52,0.2,3.71,0.21c0.31,0,0.63,0,0.94,0.01c4.29,0.07,7.87-1.58,10.5-3.03c2.76-1.52,5.57-2.93,8.38-4.23
              c-0.26,2.59,0.34,5.23,1.79,7.45c-0.11,0.01-0.23,0.03-0.35,0.05C104.81,112.64,103.64,112.83,102.46,113.02L102.46,113.02z
              M111.54,110.64c-2.38-2.26-2.54-6.01-0.47-8.64c0.4-0.3,0.74-0.66,1-1.03c2.52-2.1,6.19-2.1,8.53,0.12
              c2.23,2.12,2.51,5.57,0.81,8.16c-0.63,0.41-1.12,0.95-1.5,1.64C117.42,112.86,113.84,112.81,111.54,110.64L111.54,110.64z
              M117.64,87.65c2.98-7.37,6.06-15,9.2-22.44c4.91-11.65,10.48-22.51,16.55-32.3c0.64-1.04,1.34-2.04,2.06-3.05l-10.64,33.71
              l-9.02,28.58c-0.29,0.92-0.04,1.92,0.64,2.6c0.68,0.68,1.69,0.92,2.61,0.63c0.13-0.04,0.25-0.08,0.35-0.11
              c0.23-0.07,0.45-0.14,0.68-0.23l16.79-6.28c7.77-2.91,15.54-5.81,23.32-8.72c6.73-2.51,13.46-5.03,20.19-7.54l0.27-0.1
              c-0.08,0.06-0.16,0.12-0.24,0.18c-8.85,6.49-18.87,12.48-32.51,19.43c-10.57,5.38-21.26,10.32-30.23,14.36
              c0.22-3.29-0.94-6.63-3.48-9.05c-2.55-2.42-5.95-3.41-9.23-3.01C115.85,92.09,116.75,89.85,117.64,87.65L117.64,87.65z
              M122.14,166.2L122.14,166.2c-0.23-2.84-0.86-5.59-1.48-8.26c-0.2-0.88-0.41-1.79-0.6-2.68c-0.45-2.14-0.94-4.31-1.41-6.4
              c-0.65-2.9-1.32-5.9-1.91-8.85c-0.83-4.15-0.25-8.21,1.77-12.41c1.67-3.47,3.09-7.09,4.46-10.58c0.4-1.01,0.8-2.04,1.21-3.07
              c0.07-0.07,0.14-0.13,0.2-0.2c0.14-0.15,0.28-0.3,0.42-0.46c9.88-4.39,22.72-10.21,35.4-16.67c13.9-7.08,24.14-13.21,33.22-19.87
              c2.25-1.65,4.4-3.32,6.07-5.71c0.99-1.41,1.29-2.89,0.91-4.41c-0.12-0.47-0.37-0.9-0.72-1.24l-0.25-0.23
              c-0.71-0.68-1.75-0.89-2.68-0.55l-8.21,3.06c-6.73,2.51-13.46,5.02-20.19,7.54c-7.77,2.91-15.55,5.81-23.32,8.72l-12.77,4.78
              l20.6-65.26c0.04-0.12,0.07-0.23,0.1-0.35l0.03-0.12c0.27-0.93,0-1.92-0.7-2.59l-0.25-0.23c-0.35-0.34-0.8-0.57-1.27-0.66
              c-1.59-0.31-3.17,0.12-4.44,1.21c-0.11,0.1-0.22,0.19-0.34,0.28c-0.47,0.38-1.05,0.85-1.54,1.52c-0.41,0.56-0.83,1.11-1.25,1.67
              c-1.43,1.91-2.91,3.88-4.22,5.99c-6.21,10.01-11.9,21.12-16.92,33.01c-3.15,7.48-6.24,15.12-9.23,22.51
              c-1.59,3.94-3.24,8.01-4.88,12.01c-0.05,0.05-0.11,0.1-0.16,0.16c-4.74,1.93-9.5,4.2-14.15,6.76c-3.08,1.7-5.53,2.43-7.93,2.39
              c-0.33-0.01-0.67-0.01-1-0.01c-1.06-0.01-2.05-0.01-2.96-0.15c-2.98-0.45-6.02-0.95-8.95-1.44c-1.85-0.31-3.69-0.61-5.54-0.91
              c-1.01-0.16-2.06-0.35-3.07-0.53c-2.64-0.48-5.36-0.97-8.15-1.12c-7.57-0.4-14.48,2.16-20.54,7.61
              c-4.91,4.42-7.74,9.66-8.41,15.59c-0.92,8.14,2.1,14.74,8.73,19.09c3.85,2.53,8.38,3.67,13.86,3.49
              c8.26-0.27,15.63-3.63,21.9-9.98c3.31-3.35,6.75-6.71,10.08-9.95c1.34-1.3,2.67-2.61,4-3.91c2.91-2.86,5.85-4.55,8.98-5.18
              c2.84-0.57,5.77-1.05,8.61-1.52c0.46-0.08,0.92-0.15,1.39-0.23c-0.31,4.38-0.71,8.55-2.03,12.52c-0.46,1.39-1.17,2.8-2.04,4.07
              c-1.18,1.72-2.56,3.31-4.02,4.99l-0.32,0.37c-1.54,1.78-3.13,3.57-4.67,5.31c-2.83,3.2-5.76,6.5-8.52,9.88
              c-5.79,7.08-7.67,15.59-5.59,25.28c0.88,4.12,2.8,7.62,5.62,10.3c0.88,0.83,1.85,1.59,2.9,2.26c6.01,3.83,12.55,4.26,19.46,1.29
              C116.08,189.6,123.1,177.84,122.14,166.2L122.14,166.2z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe27" className={classes.st1}>
        <g id="NewGroup0-72" className={classes.st2}>
          <g>
            <path className={classes.st0} d="M15.8,120.54c7.89-3.27,15.15-7.36,21.66-12.18c5.08-3.77,8.96-7.42,12.38-10.64
              c6.14-5.78,10.98-10.34,20.18-13.7c8.5-3.11,14.55-2.87,16.76-2.63c8.78,0.97,15.4,5.48,19.66,9.49v16.92
              c-0.26,0.14-0.52,0.28-0.76,0.45c-9.56,6.56-19.97,11.39-30.95,14.34c-1.03,0.28-2.09,0.59-3.21,0.92
              C61.83,126.38,47.41,130.65,15.8,120.54L15.8,120.54z M132.99,81.38c2.21-0.24,8.26-0.47,16.76,2.63
              c9.19,3.36,14.04,7.93,20.18,13.7c3.42,3.22,7.3,6.88,12.38,10.64c6.51,4.83,13.78,8.91,21.66,12.18
              c-31.62,10.11-46.03,5.84-55.73,2.96c-1.12-0.33-2.18-0.64-3.2-0.92c-10.98-2.95-21.39-7.78-30.95-14.35
              c-0.34-0.23-0.7-0.44-1.07-0.61V91.16C117.27,87.08,123.99,82.37,132.99,81.38L132.99,81.38z M50.82,133.47
              c10.3-0.03,17.39-2.13,22.57-3.66c1.08-0.32,2.1-0.62,3.04-0.88c11.7-3.15,22.79-8.29,32.96-15.27c0.18-0.12,0.36-0.15,0.48-0.15
              c0.12,0,0.3,0.03,0.48,0.15c10.17,6.99,21.26,12.13,32.97,15.28c0.95,0.25,1.96,0.56,3.04,0.88c5.18,1.53,12.27,3.64,22.57,3.66
              c0.07,0,0.14,0,0.2,0c11.95,0,25.59-2.71,41.7-8.3c1.94-0.67,3.2-2.43,3.21-4.48c0.01-2.05-1.22-3.82-3.15-4.52
              c-9.11-3.31-17.42-7.72-24.69-13.11c-4.77-3.54-8.34-6.9-11.79-10.15c-6.15-5.79-11.97-11.27-22.43-15.09
              c-9.45-3.45-16.42-3.36-19.74-2.99c-9.84,1.09-17.34,5.8-22.38,10.26c-5.05-4.46-12.54-9.18-22.38-10.26
              c-3.32-0.37-10.29-0.46-19.74,2.99c-10.46,3.82-16.28,9.3-22.43,15.09c-3.45,3.25-7.02,6.61-11.79,10.15
              c-7.27,5.39-15.58,9.8-24.69,13.11c-1.93,0.7-3.16,2.47-3.15,4.52c0.02,2.05,1.28,3.8,3.21,4.48c16.11,5.58,29.75,8.3,41.7,8.3
              C50.68,133.47,50.75,133.47,50.82,133.47L50.82,133.47z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe1_00000018952213710449131980000007713783339870647705_" className={classes.st1}>
        <g id="NewGroup0-38_00000052789901342160448610000001139431479850221711_" className={classes.st2}>
          <g>
            <path className={classes.st3} d="M67.65,130.18l-6.94,5.26l-2.7,1.95c-0.09,0.05-0.19,0.11-0.28,0.16c-0.04,0.02-0.08,0.04-0.11,0.06
              l-0.06,0.03c-0.69,0.35-1.23,0.94-1.5,1.67l-8.43,22.71c-0.08,0.23-0.25,0.34-0.37,0.4c-0.12,0.06-0.31,0.1-0.54,0.02
              l-34.43-12.79c-0.37-0.14-0.56-0.55-0.42-0.91l7.38-19.86c1.01-2.73,1.38-5.66,1.09-8.72c0-0.01,0-0.03,0-0.04
              c-0.13-1.28-0.08-2.58,0.14-3.84c0.01-0.03,0.01-0.06,0.02-0.09c0.15-0.85,0.38-1.7,0.69-2.54l14.43-38.84
              c0.14-0.21,0.26-0.43,0.35-0.67l6.68-17.99c0.22-0.59,0.65-1.06,1.23-1.32l0,0c0.58-0.27,1.24-0.28,1.84-0.05
              c0.81,0.32,1.17,0.97,1.31,1.37l2.01,7L44.57,80.9c-0.18,0.72-0.09,1.45,0.22,2.07c0.38,0.78,1.09,1.4,2.01,1.63
              c1.64,0.41,3.29-0.59,3.7-2.22l5.88-23.54l4.72-12.72c0.22-0.6,0.67-1.08,1.26-1.33c0.59-0.26,1.24-0.27,1.83-0.03
              c1.17,0.47,1.74,1.86,1.28,3.1c-0.04,0.1-0.07,0.21-0.1,0.32L55.4,87.85c-0.18,0.73-0.09,1.46,0.21,2.08
              c0.38,0.78,1.09,1.4,2,1.63c1.64,0.41,3.3-0.58,3.71-2.22l9.95-39.52c0.34-0.98,0.49-1.99,0.47-2.98
              c0.46-1.93,2.53-2.05,3.2-2.04c0.76,0.05,1.44-0.19,2.04-0.66c0.15-0.12,0.32-0.22,0.5-0.31c0.58-0.27,1.24-0.28,1.85-0.04
              c1.17,0.47,1.75,1.86,1.29,3.1c-0.01,0.03-0.02,0.07-0.04,0.1L64.72,94.74c-0.26,0.79-0.18,1.61,0.15,2.3
              c0.35,0.71,0.97,1.29,1.78,1.56c1.6,0.53,3.33-0.34,3.86-1.94l13.3-40.07l4.37-2.14c0.57-0.28,1.19-0.32,1.76-0.13
              c0.6,0.2,1.08,0.63,1.36,1.2c0,0.01,0.01,0.01,0.01,0.02c0.28,0.58,0.3,1.23,0.08,1.83l-16.56,44.6
              c-0.83,2.21,0.3,4.68,2.51,5.51c0.02,0.01,0.04,0.02,0.07,0.02c0.34,0.12,0.7,0.2,1.06,0.23c1.37,0.11,2.7-0.19,3.86-0.88
              l0.84-0.49l21.22-12.48c0.47-0.28,0.93-0.22,1.15-0.16c0.23,0.06,0.65,0.23,0.92,0.71c0.35,0.6,0.24,1.35-0.25,1.82
              c-11.11,10.84-28.95,25.9-36.1,31.87l-2.4,1.99 M59.9,39.21c-2.11,0.93-3.71,2.63-4.52,4.8l-3.25,8.74
              c-0.94-1.66-2.41-2.95-4.2-3.66c-2.16-0.85-4.51-0.79-6.63,0.18c-2.06,0.95-3.63,2.64-4.41,4.75L30.47,71.3
              c-0.14,0.21-0.26,0.43-0.35,0.67L15.44,111.5c-0.43,1.16-0.75,2.34-0.97,3.52c-0.01,0.03-0.01,0.06-0.02,0.09
              c-0.33,1.85-0.41,3.73-0.22,5.6l0,0.03c0.2,2.12-0.04,4.14-0.73,6L6.13,146.6c-0.67,1.82-0.52,3.74,0.26,5.35
              c0.74,1.52,2.05,2.78,3.76,3.41l34.43,12.79c1.71,0.63,3.56,0.57,5.22-0.19c1.66-0.76,2.92-2.12,3.55-3.82l8.04-21.65
              c0.02-0.02,0.05-0.03,0.07-0.05l2.85-2.06c0.02-0.01,0.04-0.03,0.06-0.04l6.7-5.08c0.16-0.1,0.32-0.21,0.47-0.36
              c0.05-0.04,0.1-0.08,0.16-0.13l2.35-1.95c7.21-6.02,25.18-21.2,36.45-32.19c2.48-2.42,3.02-6.21,1.29-9.23
              c-1.02-1.78-2.67-3.05-4.66-3.58c-1.98-0.53-4.05-0.24-5.81,0.8l-19.2,11.29l15.01-40.4c0.8-2.16,0.7-4.5-0.29-6.58
              c-0.01-0.02-0.02-0.05-0.03-0.07c-0.99-2.05-2.73-3.57-4.88-4.31c-1.77-0.6-3.67-0.59-5.43,0.02c1.3-4.21-0.83-8.83-4.92-10.45
              c-2.15-0.85-4.57-0.79-6.65,0.17c-0.28,0.13-0.56,0.28-0.83,0.44c-1.56,0.12-3.54,0.64-5.21,1.88c-0.69-0.62-1.5-1.13-2.4-1.5
              C64.34,38.24,62,38.28,59.9,39.21L59.9,39.21z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe1_00000085965456480988678700000005035736298337336994_" className={classes.st1}>
        <g id="NewGroup0-38_00000071517325349928280380000001260987725609792953_" className={classes.st2}>
          <g>
            <path className={classes.st3} d="M152.03,147.56l-2.4-1.99c-7.15-5.97-24.98-21.03-36.1-31.87c-0.49-0.48-0.6-1.22-0.25-1.82
              c0.27-0.48,0.69-0.65,0.92-0.71c0.23-0.06,0.68-0.12,1.15,0.16l21.22,12.48l0.84,0.49c1.16,0.69,2.49,0.99,3.86,0.88
              c0.36-0.03,0.72-0.11,1.06-0.23c0.02-0.01,0.04-0.02,0.07-0.02c2.21-0.83,3.34-3.3,2.51-5.51l-16.56-44.6
              c-0.22-0.6-0.19-1.25,0.08-1.83c0-0.01,0.01-0.01,0.01-0.02c0.28-0.57,0.76-0.99,1.36-1.2c0.57-0.19,1.19-0.15,1.76,0.13
              l4.37,2.14l13.3,40.07c0.53,1.6,2.26,2.47,3.86,1.94c0.81-0.27,1.44-0.85,1.78-1.56c0.34-0.69,0.41-1.51,0.15-2.3l-15.85-47.74
              c-0.01-0.03-0.02-0.07-0.04-0.1c-0.46-1.24,0.12-2.64,1.29-3.1c0.61-0.24,1.27-0.23,1.85,0.04c0.18,0.08,0.35,0.19,0.5,0.31
              c0.6,0.47,1.28,0.71,2.04,0.66c0.67-0.01,2.73,0.11,3.2,2.04c-0.02,0.99,0.13,2,0.47,2.98l9.95,39.52
              c0.41,1.64,2.07,2.63,3.71,2.22c0.91-0.23,1.62-0.84,2-1.63c0.3-0.62,0.4-1.35,0.21-2.08l-9.98-39.66
              c-0.03-0.11-0.06-0.21-0.1-0.32c-0.46-1.24,0.12-2.63,1.28-3.1c0.6-0.24,1.25-0.23,1.83,0.03c0.59,0.26,1.03,0.73,1.26,1.33
              l4.72,12.72l5.88,23.54c0.41,1.64,2.07,2.63,3.7,2.22c0.91-0.23,1.63-0.84,2.01-1.63c0.3-0.62,0.4-1.35,0.22-2.07l-4.44-17.77
              l2.01-7c0.14-0.4,0.5-1.05,1.31-1.37c0.6-0.24,1.26-0.22,1.84,0.05l0,0c0.57,0.26,1.01,0.73,1.23,1.32l6.68,17.99
              c0.09,0.24,0.21,0.46,0.35,0.67l14.43,38.84c0.31,0.84,0.55,1.7,0.69,2.54c0.01,0.03,0.01,0.06,0.02,0.09
              c0.22,1.27,0.27,2.56,0.14,3.84c0,0.01,0,0.03,0,0.04c-0.29,3.05,0.07,5.99,1.09,8.72l7.38,19.86c0.14,0.37-0.05,0.78-0.42,0.91
              l-34.43,12.79c-0.23,0.08-0.42,0.04-0.54-0.02c-0.12-0.06-0.29-0.17-0.37-0.4l-8.43-22.71c-0.27-0.73-0.81-1.32-1.5-1.67
              l-0.06-0.03c-0.03-0.02-0.07-0.03-0.11-0.06c-0.1-0.05-0.19-0.1-0.28-0.16l-2.7-1.95l-6.94-5.26 M159.87,56.64
              c-2.11-0.93-4.45-0.97-6.58-0.1c-0.9,0.36-1.71,0.88-2.4,1.5c-1.66-1.24-3.64-1.76-5.21-1.88c-0.27-0.16-0.54-0.31-0.83-0.44
              c-2.09-0.96-4.51-1.02-6.65-0.17c-4.09,1.63-6.22,6.24-4.92,10.45c-1.76-0.61-3.65-0.62-5.43-0.02
              c-2.16,0.73-3.89,2.26-4.88,4.31c-0.01,0.02-0.02,0.05-0.03,0.07c-0.99,2.08-1.09,4.42-0.29,6.58l15.01,40.4l-19.2-11.29
              c-1.77-1.04-3.83-1.32-5.81-0.8c-1.98,0.53-3.63,1.8-4.66,3.58c-1.73,3.02-1.19,6.81,1.29,9.23
              c11.27,10.98,29.24,26.16,36.45,32.19l2.35,1.95c0.06,0.05,0.11,0.09,0.16,0.13c0.15,0.14,0.3,0.26,0.47,0.36l6.7,5.08
              c0.02,0.01,0.04,0.03,0.06,0.04l2.85,2.06c0.02,0.02,0.05,0.03,0.07,0.05l8.04,21.65c0.63,1.71,1.9,3.07,3.55,3.82
              c1.66,0.76,3.51,0.83,5.22,0.19l34.43-12.79c1.71-0.63,3.02-1.89,3.76-3.41c0.79-1.62,0.94-3.54,0.26-5.35l-7.38-19.86
              c-0.69-1.86-0.94-3.88-0.73-6l0-0.03c0.18-1.87,0.11-3.75-0.22-5.6c-0.01-0.03-0.01-0.06-0.02-0.09
              c-0.21-1.18-0.54-2.36-0.97-3.52L189.64,89.4c-0.09-0.24-0.21-0.46-0.35-0.67l-6.42-17.28c-0.78-2.12-2.35-3.8-4.41-4.75
              c-2.12-0.97-4.47-1.03-6.63-0.18c-1.79,0.7-3.26,2-4.2,3.66l-3.25-8.74C163.58,59.28,161.98,57.58,159.87,56.64L159.87,56.64z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe32" className={classes.st1}>
        <g id="NewGroup0-67" className={classes.st2}>
          <g>
            <path className={classes.st0} d="M186.98,47.58l-29.95,67.5c-0.55,0.59-0.94,1.76-1.78,5.45c-1.1,4.83-0.94,6.5-0.89,7.05
              c0.26,2.84,1.31,4.41,2.32,5.93c0.54,0.81,1.09,1.64,1.63,2.82c2.07,4.53,2.35,10.38,0.65,13.91c-1.12,2.33-2.7,2.94-5.89,3.98
              c-2.96,0.97-6.65,2.19-10.29,5.8c-5.44,5.4-6.46,9.86-7.53,14.58c-0.65,2.87-1.33,5.83-3.11,9.49c-1.76,3.62-10.1,4.14-17.46,4.6
              c-1.24,0.08-2.53,0.16-3.78,0.25c-1.48,0.11-2.34-0.1-2.66-0.34c-0.06-0.05-0.21-0.16-0.25-0.68c-0.1-1.33,0.82-2.59,2.18-3
              c4.22-1.27,14.2-5.77,15.58-20.2c1.57-16.43,12.34-36.69,12.44-36.89c0.1-0.19,0.18-0.39,0.24-0.6l4.22-15.89c0,0,0-0.01,0-0.01
              l13.3-50.14c0,0,0,0,0-0.01l5.95-22.42c0.97-3.65,3.34-6.62,6.67-8.38c3.34-1.76,7.13-2.03,10.69-0.77
              c3.54,1.26,6.3,3.85,7.78,7.3C188.52,40.35,188.5,44.14,186.98,47.58L186.98,47.58z M109.18,129.44
              c-0.71,0.23-1.25,0.76-2.06,1.56c-0.4,0.39-1.21,1.2-2.16,2.22c-3.37,3.62-4.08,5.14-4.31,5.65c-1.2,2.58-1.08,4.47-0.97,6.29
              c0.06,0.97,0.12,1.97-0.01,3.26c-0.48,4.96-3.19,10.15-6.42,12.35c-2.13,1.45-3.81,1.18-7.09,0.48
              c-3.05-0.65-6.84-1.45-11.81-0.16c-7.42,1.93-10.54,5.28-13.84,8.82c-2.01,2.15-4.08,4.37-7.45,6.64
              c-3.34,2.24-10.82-1.51-17.42-4.82c-1.11-0.56-2.26-1.13-3.38-1.68c-1.34-0.65-1.98-1.27-2.13-1.63
              c-0.03-0.07-0.1-0.24,0.12-0.71c0.58-1.2,2.01-1.83,3.4-1.49c4.29,1.03,15.18,2.14,23.61-9.63
              c9.61-13.42,29.09-25.52,29.29-25.64c0.18-0.11,0.36-0.25,0.51-0.4l60.84-60.8l-10.39,39.19L109.18,129.44L109.18,129.44z
              M181.13,24.34c-4.97-1.77-10.49-1.37-15.16,1.09c-4.66,2.46-8.11,6.79-9.47,11.89l-1.64,6.17c0,0,0,0.01,0,0.01l-4.12,15.52
              l-67.4,67.36c-2.6,1.63-20.88,13.37-30.61,26.96c-6.23,8.7-13.81,8.4-17.78,7.46c-3.92-0.94-8.01,0.95-9.72,4.49
              c-0.69,1.44-2.41,6.38,4.59,9.79c1.09,0.53,2.22,1.1,3.32,1.65c8.35,4.19,16.98,8.52,23.03,4.46c3.9-2.62,6.31-5.19,8.42-7.46
              c3.09-3.31,5.32-5.7,11.17-7.22c3.69-0.96,6.51-0.36,9.24,0.22c3.37,0.72,7.19,1.53,11.39-1.33c4.56-3.1,8.19-9.85,8.84-16.42
              c0.17-1.73,0.09-3.07,0.02-4.14c-0.09-1.53-0.14-2.3,0.46-3.61c0.65-1.39,3.71-4.67,5.29-6.22c0.08-0.04,0.15-0.09,0.22-0.15
              l24.03-17.39l-1.16,4.36l0,0l-0.97,3.66c-1.43,2.72-11.33,22.06-12.92,38.7c-1.02,10.66-7.72,14.21-11.63,15.39
              c-3.86,1.16-6.44,4.84-6.14,8.76c0.11,1.51,1.01,6.21,7.71,6.21c0.37,0,0.76-0.01,1.17-0.04c1.21-0.09,2.48-0.17,3.71-0.25
              c9.32-0.58,18.95-1.17,22.13-7.72c2.06-4.23,2.84-7.66,3.53-10.69c1.01-4.41,1.74-7.6,6.03-11.86c2.71-2.69,5.45-3.59,8.1-4.46
              c3.27-1.08,6.98-2.3,9.18-6.87c2.39-4.98,2.14-12.64-0.61-18.64c-0.72-1.58-1.46-2.69-2.06-3.59c-0.85-1.28-1.28-1.92-1.41-3.35
              c-0.14-1.53,0.86-5.9,1.45-8.03c0.04-0.08,0.09-0.16,0.12-0.24l30.6-68.95c2.13-4.8,2.16-10.32,0.09-15.14
              C190.1,29.88,186.07,26.1,181.13,24.34L181.13,24.34z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe86" className={classes.st1}>
        <g id="NewGroup0-13" className={classes.st2}>
          <g>
            <path className={classes.st0} d="M72.92,43.78c1.02,0.4,2.05,0.87,3.05,1.39c3.13,1.63,5.83,3.68,7.4,5.6c0,0,0,0,0,0
              c0.48,0.59,0.76,1.06,0.92,1.39c-1.72,0.3-5.71-0.32-10.49-2.81c-4.81-2.51-7.61-5.42-8.33-7
              C66.59,42.16,69.16,42.31,72.92,43.78L72.92,43.78z M85.95,85.33h2.47v7.02H59.8v-7.02h2.47c1.81,0,3.28-1.47,3.28-3.28V70.03
              h0.14c1.81,0,3.28-1.47,3.28-3.28V54.17c0.61,0.36,1.21,0.7,1.8,1.01c2.52,1.31,5.53,2.5,8.47,3.14v8.44
              c0,1.81,1.47,3.28,3.28,3.28h0.14v12.02C82.67,83.86,84.14,85.33,85.95,85.33L85.95,85.33z M96.39,102.99v62.74
              c0,3.9-3.18,7.08-7.08,7.08h-30.4c-3.9,0-7.08-3.18-7.08-7.08v-62.74c0-2.24,1.83-4.07,4.07-4.07h0.37
              c0.08,0.01,0.16,0.01,0.25,0.01H91.7c0.08,0,0.17-0.01,0.25-0.01h0.37C94.56,98.92,96.39,100.74,96.39,102.99L96.39,102.99z
              M58.99,69.45v9.33c-3.19,0.12-5.75,2.76-5.75,5.97v7.94c-4.58,1.18-7.97,5.35-7.97,10.29v62.74c0,7.52,6.12,13.64,13.64,13.64
              h30.4c7.52,0,13.64-6.12,13.64-13.64v-62.74c0-4.94-3.39-9.11-7.97-10.29v-7.94c0-3.22-2.56-5.85-5.75-5.97v-9.33
              c0-2.38-1.4-4.44-3.42-5.4v-5.5c0.44-0.09,0.86-0.22,1.26-0.37c1.51-0.57,2.66-1.55,3.33-2.84c0.75-1.45,1.5-4.5-1.94-8.72
              c-2.14-2.63-5.5-5.21-9.45-7.27c-1.21-0.63-2.46-1.2-3.69-1.68c-7.75-3.04-13.85-2.46-15.93,1.52c-0.66,1.26-0.81,2.73-0.45,4.26
              c0.47,1.97,1.76,3.9,3.47,5.67v14.93C60.39,65.01,58.99,67.07,58.99,69.45L58.99,69.45z"/>
            <path className={classes.st0} d="M62.4,120.17c-1.81,0-3.28,1.47-3.28,3.28V160c0,1.81,1.47,3.28,3.28,3.28c1.81,0,3.28-1.47,3.28-3.28
              v-36.55c0-0.91-0.37-1.72-0.96-2.32C64.12,120.54,63.3,120.17,62.4,120.17L62.4,120.17z"/>
            <path className={classes.st0} d="M165.74,150.67c0,12.21-9.93,22.14-22.14,22.14h-0.28c-12.21,0-22.14-9.93-22.14-22.14v-21.64
              c0-2.04,1.66-3.71,3.71-3.71h0.77c0.34,0,0.67-0.05,0.98-0.15h33.65c0.31,0.1,0.64,0.15,0.98,0.15h0.77
              c2.04,0,3.71,1.66,3.71,3.71V150.67L165.74,150.67z M157.99,111.58v7.03h-29.05l0-7.03L157.99,111.58L157.99,111.58z
              M157.98,105.02h-29.05c-3.62,0-6.56,2.94-6.56,6.56v7.49c-4.45,1.12-7.75,5.16-7.75,9.96v21.64c0,15.82,12.87,28.7,28.7,28.7
              h0.28c15.83,0,28.7-12.87,28.7-28.7v-21.64c0-4.79-3.3-8.83-7.75-9.96v-7.49C164.55,107.96,161.6,105.02,157.98,105.02
              L157.98,105.02z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe96" className={classes.st1}>
        <g id="NewGroup0-3" className={classes.st2}>
          <g>
            <path className={classes.st0} d="M125.63,163.55H90.06c-1.71,0-3.1,1.39-3.1,3.1c0,1.71,1.39,3.1,3.1,3.1h35.57c0.85,0,1.63-0.35,2.19-0.91
              s0.91-1.34,0.91-2.19C128.73,164.94,127.34,163.55,125.63,163.55L125.63,163.55z"/>
            <path className={classes.st0} d="M98.57,94.85c0.4,1.54,1.9,2.53,3.46,2.29c0.6-0.09,1.2-0.21,1.81-0.34c9.91-2.15,17.73-8.73,21.84-17.22
              c0.84-1.74-0.11-3.81-1.96-4.32c-1.45-0.4-2.96,0.3-3.62,1.65c-3.3,6.82-9.59,12.11-17.57,13.83c-0.47,0.1-0.94,0.19-1.41,0.26
              C99.3,91.3,98.11,93.06,98.57,94.85L98.57,94.85L98.57,94.85z"/>
            <path className={classes.st0} d="M138.23,69.39c4.5,6.11,6.87,13.34,6.87,21v100.62c0,0.48-0.39,0.87-0.87,0.87H71.74
              c-0.48,0-0.87-0.39-0.87-0.87V90.39c0-7.66,2.37-14.89,6.87-21h1.78v13.42c0,7.6,2.96,14.75,8.34,20.13
              c5.38,5.37,12.52,8.33,20.13,8.33c15.69,0,28.46-12.77,28.46-28.46V69.39H138.23L138.23,69.39z M85.44,34.44
              c0-4.5,3.52-8.13,8.01-8.27l28.8,0l0.26,0c4.5,0.14,8.01,3.77,8.01,8.27v48.37c0,12.43-10.11,22.54-22.54,22.54
              c-6.02,0-11.68-2.34-15.94-6.6c-4.26-4.26-6.6-9.92-6.6-15.94V34.44L85.44,34.44z M132.42,24.54c-2.59-2.66-6.06-4.18-9.76-4.29
              c-0.03,0-0.06,0-0.08,0l-28.86,0l-0.32,0c-0.03,0-0.06,0-0.08,0c-3.71,0.11-7.17,1.63-9.76,4.29c-2.6,2.67-4.03,6.18-4.03,9.9
              v29.03h-3.24c-0.9,0-1.75,0.41-2.31,1.11c-5.82,7.29-9.02,16.45-9.02,25.8v100.62c0,3.74,3.05,6.79,6.79,6.79h72.49
              c3.74,0,6.79-3.04,6.79-6.79V90.39c0-9.35-3.2-18.51-9.02-25.8c-0.56-0.7-1.41-1.11-2.31-1.11h-3.24V34.44
              C136.44,30.72,135.01,27.2,132.42,24.54L132.42,24.54z"/>
            <path className={classes.st0} d="M93.75,72.84c1.71,0,3.1-1.39,3.1-3.1v-16.6V34.17c0-0.85-0.35-1.63-0.91-2.19s-1.34-0.91-2.19-0.91
              c-1.71,0-3.1,1.39-3.1,3.1v21.12v14.45C90.65,71.45,92.04,72.84,93.75,72.84L93.75,72.84z"/>
          </g>
        </g>
      </g>
      <g id="NewGroupe11" className={classes.st1}>
        <g id="NewGroup0-88" className={classes.st2}>
          <g>
            <path className={classes.st0} d="M148.17,101.27l-0.13-0.39c-0.18-0.53-0.36-1.08-0.59-1.64c-0.29-0.77-0.55-1.4-0.82-2
              c-0.57-1.27-2.06-1.84-3.34-1.27c-1.27,0.57-1.84,2.06-1.27,3.34c0.23,0.52,0.46,1.08,0.72,1.76c0,0.01,0.01,0.03,0.01,0.04
              c0.18,0.44,0.34,0.91,0.5,1.4l0.13,0.38c0.21,0.63,0.4,1.29,0.56,1.83c0.67,2.37,1.19,4.92,1.55,7.6
              c0.06,0.45,0.11,0.91,0.16,1.36l0.07,0.6c0.04,0.4,0.07,0.81,0.1,1.25c0.02,0.23,0.03,0.46,0.05,0.7
              c0.05,0.62,0.09,1.25,0.1,1.89l0.03,0.99l0.01,1.01c0.01,0.54,0,1.12-0.02,1.89l-0.07,1.98l-0.15,1.96
              c-0.04,0.52-0.1,1.04-0.15,1.58l-0.16,1.31l-0.32,2.13c-0.04,0.25-0.07,0.49-0.11,0.68c0,0.02-0.01,0.04-0.01,0.06l-0.4,1.99
              c-0.13,0.6-0.27,1.23-0.44,1.88c-0.7,2.76-1.49,5.18-2.42,7.42c-0.01,0.01-0.01,0.03-0.02,0.05c-0.25,0.62-0.49,1.19-0.75,1.74
              l-0.1,0.22c-0.25,0.54-0.49,1.06-0.75,1.56c-0.01,0.02-0.02,0.04-0.03,0.05c-0.07,0.15-0.16,0.32-0.26,0.5
              c-0.06,0.1-0.11,0.2-0.17,0.31c0,0,0,0.01-0.01,0.01c-0.01,0.02-0.02,0.04-0.04,0.07l-0.02,0.03c-0.13,0.24-0.25,0.47-0.39,0.7
              c-0.01,0.01-0.01,0.02-0.02,0.03l-0.09,0.15c-0.3,0.52-0.58,1.01-0.88,1.47c-0.13,0.2-0.26,0.4-0.39,0.59
              c-0.24,0.37-0.46,0.71-0.69,1.05c-0.39,0.54-0.77,1.05-1.15,1.54c-0.87,1.09-0.69,2.68,0.41,3.55c0.46,0.37,1.02,0.55,1.57,0.55
              c0.74,0,1.48-0.33,1.98-0.96c0.46-0.57,0.88-1.16,1.3-1.73c0.01-0.02,0.03-0.04,0.04-0.06c0.28-0.4,0.54-0.8,0.79-1.19
              c0.12-0.19,0.24-0.38,0.36-0.56c0.39-0.6,0.73-1.19,1.05-1.76l0.08-0.13c0.17-0.29,0.32-0.57,0.47-0.84l0.03-0.05
              c0.01-0.01,0.01-0.02,0.02-0.03c0.01-0.02,0.02-0.04,0.03-0.06c0.04-0.08,0.09-0.16,0.13-0.25c0.12-0.22,0.25-0.46,0.38-0.72
              c0.29-0.56,0.55-1.13,0.81-1.68l0.1-0.2c0.3-0.63,0.59-1.29,0.88-2.02c1.03-2.46,1.89-5.12,2.65-8.1
              c0.18-0.72,0.35-1.43,0.49-2.12l0.39-1.97c0.08-0.37,0.13-0.72,0.18-1.03l0.32-2.15c0-0.02,0-0.03,0.01-0.05l0.13-1.01
              c0-0.02,0-0.04,0.01-0.06l0.04-0.38c0.06-0.55,0.12-1.13,0.17-1.72l0.15-2.03c0-0.03,0-0.07,0.01-0.1l0.07-2.03
              c0.03-0.86,0.04-1.53,0.02-2.15l-0.01-1.01c0-0.01,0-0.03,0-0.04l-0.03-0.99c-0.02-0.72-0.06-1.45-0.12-2.17
              c-0.02-0.21-0.03-0.43-0.05-0.64c-0.03-0.47-0.07-0.95-0.12-1.47l-0.07-0.59c-0.06-0.49-0.11-0.98-0.18-1.47
              c-0.39-2.91-0.96-5.7-1.7-8.31c-0.18-0.61-0.39-1.32-0.62-2.03C148.18,101.29,148.18,101.28,148.17,101.27L148.17,101.27z"/>
            <path className={classes.st0} d="M150.72,54.62c-5.37,17.31-0.87,31.95,3.1,44.87c1.63,5.32,3.18,10.35,3.91,15.16
              c2.57,16.83-4.42,35.85-20.76,56.55c-0.02,0.02-0.04,0.05-0.06,0.07c-2.39,3.03-5.03,6.15-7.85,9.27
              c-1.06,1.18-2.22,2.44-3.44,3.73c-0.02,0.02-0.04,0.04-0.06,0.07c-0.12,0.12-0.23,0.25-0.35,0.37c0.27-1.41,0.53-2.81,0.78-4.19
              c0.14-0.34,0.21-0.72,0.2-1.09c0.49-2.79,0.96-5.63,1.39-8.45c0-0.01,0-0.03,0.01-0.04c0.55-3.59,1.05-7.24,1.48-10.86
              c0.14-1.13,0.27-2.26,0.39-3.39c0.08-0.28,0.11-0.57,0.1-0.87c0.47-4.37,0.86-8.74,1.16-13.06c0.27-3.83,0.47-7.74,0.61-11.64
              c0.24-6.84,0.24-13.1,0.23-18.44l-0.01-2.63c-0.01-2.79-0.02-5.42,0-7.99c0.11-16.99,1.52-28.39,8.59-39.99
              c3.55-5.82,8.17-11.05,13.8-15.61C152.66,49.11,151.58,51.84,150.72,54.62L150.72,54.62z M87.71,147.33
              c-5.71-5.11-11.13-9.6-15.91-13.57c-15.14-12.55-26.08-21.62-31.46-37.43c-2.2-6.46-3.25-13.35-3.15-20.59
              c1.21,2.69,2.61,5.26,4.2,7.7c9.76,15.05,29.33,23.18,43.62,29.11c2.25,0.94,4.39,1.82,6.3,2.66c0.13,0.24,0.27,0.48,0.4,0.73
              c0.46,0.83,0.92,1.67,1.39,2.52c2.98,5.38,6.35,11.48,9.75,18.22c1.71,3.38,3.37,6.83,4.98,10.3c-3.4-2.98-6.82-6.06-7.94-7.27
              c-3.67-3.98-7.97-7.97-13.53-12.54c-2.3-1.9-4.76-3.79-7.29-5.62c-1.23-0.9-2.5-1.8-3.79-2.67c-1.14-0.77-2.49-1.67-3.92-2.52
              c-1.2-0.72-2.75-0.33-3.46,0.87c-0.72,1.2-0.33,2.75,0.87,3.46c1.33,0.79,2.61,1.65,3.69,2.37c1.23,0.83,2.45,1.69,3.64,2.57
              c2.46,1.78,4.83,3.6,7.05,5.43c5.38,4.43,9.52,8.26,13.02,12.07c1.66,1.8,7.11,6.6,9.38,8.58c1.82,1.59,3.68,3.19,5.1,4.39
              c0.7,0.59,1.27,1.06,1.67,1.39c0.08,0.06,0.15,0.12,0.21,0.17c0.96,2.27,1.9,4.58,2.82,6.9l0,0c0.01,0.01,0.01,0.03,0.02,0.04
              c0.29,0.75,2.19,5.82,3.71,9.91c0.8,2.15,1.53,4.09,1.92,5.14c-0.04,0.25-0.09,0.49-0.13,0.74
              C108.94,167.06,94.73,153.61,87.71,147.33L87.71,147.33z M81.85,69.65c0.31-6.81,1.85-13.61,4.58-20.32
              c0.15,2.94,0.51,5.84,1.1,8.7c3.58,17.49,17.39,30.45,28.5,40.87c3.93,3.69,7.66,7.19,10.47,10.45c0,0.24,0,0.48,0,0.73
              l0.01,2.62c0.01,5.29,0.01,11.5-0.23,18.25c-0.13,3.84-0.34,7.7-0.6,11.47c-0.02,0.32-0.05,0.64-0.07,0.96l-0.96-2.96
              c-0.84-2.64-1.89-5.47-3.18-8.66c-2.12-5.3-4.74-10.73-8.01-16.59c-1.48-2.64-3.07-5.3-4.73-7.89c-0.8-1.27-1.66-2.56-2.55-3.87
              c-1.04-1.51-1.9-2.71-2.73-3.78c-0.85-1.11-2.44-1.31-3.54-0.46c-1.11,0.85-1.31,2.44-0.46,3.54c0.77,1,1.58,2.13,2.57,3.56
              c0.86,1.25,1.68,2.49,2.45,3.72c1.62,2.51,3.16,5.08,4.58,7.63c3.16,5.68,5.69,10.92,7.74,16.03c1.26,3.08,2.26,5.8,3.06,8.32
              l4.6,14.19c-0.12,1.11-0.25,2.22-0.38,3.33c-0.41,3.39-0.87,6.82-1.38,10.19c-1.27-3.39-2.41-6.45-2.61-6.94l0,0
              c-0.01-0.02-0.01-0.03-0.02-0.05c-1.65-4.17-3.38-8.3-5.13-12.27c-2.37-5.38-4.91-10.76-7.55-16
              c-3.44-6.82-6.84-12.97-9.84-18.39c-0.47-0.85-0.93-1.69-1.39-2.51C86.77,96.56,81.15,85.16,81.85,69.65L81.85,69.65z
              M164.29,39.22c0.72-0.96,0.67-2.3-0.13-3.2c-0.8-0.9-2.12-1.11-3.16-0.51c-10.86,6.29-19.33,14.34-25.18,23.93
              c-8.44,13.84-9.23,27.44-9.33,42.5c-2.16-2.18-4.55-4.42-7.02-6.73c-11.13-10.44-23.75-22.28-27-38.2
              c-1.25-6.12-1.41-12.52-0.46-19.02c0.17-1.19-0.52-2.34-1.65-2.74c-1.13-0.4-2.39,0.04-3.01,1.07
              C80.87,47.07,77.32,58.2,76.81,69.42c-0.64,14.14,3.63,25.26,10.66,38.69c-0.17-0.07-0.35-0.14-0.53-0.22
              c-13.68-5.68-32.41-13.46-41.32-27.2c-3.4-5.24-5.88-11.14-7.37-17.54c-0.27-1.17-1.33-1.99-2.53-1.95
              c-1.2,0.04-2.21,0.91-2.41,2.1c-2.12,12.37-1.36,24.03,2.25,34.66c5.84,17.16,17.24,26.61,33.01,39.69
              c4.75,3.94,10.13,8.4,15.77,13.44c7.48,6.69,23.22,21.63,35.49,35.77c0.07,0.76,0.33,1.48,0.77,2.12
              c0.06,0.09,0.13,0.17,0.2,0.25l0,0c0.03,0.03,0.05,0.06,0.08,0.09l0,0c0.05,0.06,0.1,0.13,0.16,0.18
              c0.85,0.9,2.05,1.42,3.28,1.42c0.01,0,0.02,0,0.03,0c1.22,0,2.36-0.49,3.22-1.38l0.04-0.05c0.14-0.14,0.27-0.28,0.4-0.42
              c0.43-0.45,0.86-0.9,1.29-1.35c0.02-0.03,0.05-0.05,0.07-0.08c1.22-1.3,2.38-2.56,3.45-3.75c2.9-3.22,5.63-6.45,8.11-9.58
              c0.02-0.02,0.04-0.05,0.06-0.07c9.53-12.08,16.08-23.76,19.48-34.74c2.74-8.85,3.5-17.47,2.25-25.63
              c-0.79-5.18-2.39-10.38-4.08-15.88c-3.94-12.82-8.01-26.07-3.1-41.89C157.39,50.14,160.33,44.46,164.29,39.22L164.29,39.22z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Scissor;