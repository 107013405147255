import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Avatar, Button, Checkbox, Chip, Dialog, FormControlLabel, TextField, Tooltip, Typography
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useTranslation } from 'src/i18n';
import { useSelector } from 'src/store';
import { useUsersNoCustomer } from 'src/store/customSelectors';
import { makeStyles } from 'src/theme/makeStyles';
import type { UserSmall } from 'src/types/api/user';
import getInitials from 'src/utils/general/getInitials';

const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: 42,
    width: 42,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatarTextWrapper: {
    color: theme.palette.primary.contrastText
  }
}));

interface WrapperProps { 
  children: React.ReactNode; 
  dialog: boolean;
  dialogOpen: boolean; // todo
  onClose?: () => void;
  dialogTitle?: string;
}

const Wrapper = ({
  children, 
  dialog, 
  dialogOpen,
  onClose,
  dialogTitle
}: WrapperProps) => {
  const {t} = useTranslation();

  if(dialog){
    return (
      <Dialog
        open={dialogOpen}
        fullWidth={true}
        onClose={() => {
          onClose && onClose();
        }}
        maxWidth='xs'
      >
        <div style={{ padding: 24 }}>
          <Typography 
            variant='h3'
            style={{ marginBottom: 12 }}
          >
            {dialogTitle}
          </Typography>
          <Typography
            variant='body2'
            style={{ marginBottom: 12 }}
          >
            {t('description.select_members')}
          </Typography>
          {children}
        </div>
      </Dialog>
    )
  }

  return (
    <>
      {children}
    </>
  );
}

interface Props {
  options?: UserSmall[];
  values: {
    users: UserSmall[],
    allUsers?: boolean;
  };
  onChange: (values: {
    users: UserSmall[];
    allUsers?: boolean;
  }) => void;
  label?: string;
  dialog?: boolean;
  dialogOpen?: boolean;
  onDialogClose?: () => void;
  dialogTitle?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  allUsersCheckbox?: boolean;
  error?: boolean;
  helperText?: string;
}

const UsersPicker = ({
  options: userOptions,
  values,
  onChange,
  label,
  dialog = false,
  dialogOpen = false,
  onDialogClose,
  dialogTitle,
  autoFocus = false,
  disabled = false,
  allUsersCheckbox = false,
  error = false,
  helperText
}: Props) => {
  const {t} = useTranslation();
  const { classes } = useStyles();
  const all_users = useUsersNoCustomer();
  
  const options = userOptions ? userOptions : all_users;

  const avatars = useSelector(state => state.misc.avatars);

  const handleChange = (e: any, value: UserSmall[]) => {
    onChange({ 
      ...values, 
      users: value 
    });
  }

  const handleAllUsersChange = () => {
    onChange({
      ...values,
      allUsers: !values.allUsers
    });
  }

  if(allUsersCheckbox && typeof values.allUsers !== 'boolean'){
    console.error('allUsers value should be set when allUsersCheckbox is enabled');
    return null;
  }

  const allUsersSelected = allUsersCheckbox && values.allUsers;

  return (
    <Wrapper 
      dialog={dialog} 
      dialogOpen={dialogOpen}
      onClose={onDialogClose}
      dialogTitle={dialogTitle}
    >
      <Autocomplete 
        multiple
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.contact.name}
        value={allUsersSelected ? [] : values.users}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            variant='standard'
            label={label ? label : t('lang.users')}
            autoFocus={autoFocus}
            {...params}
            disabled={disabled || params.disabled}
            error={error}
            helperText={helperText}
          />
        )}
        style={dialog ? { marginBottom: 24 } : { }}
        fullWidth
        renderTags={(value: UserSmall[], getTagProps: AutocompleteRenderGetTagProps) => value.map((val, index) => (
          <Chip 
            variant="outlined" 
            size="small" 
            avatar={(
              <Avatar 
                className={classes.avatar} 
                src={avatars[val.contact.id] && `data:image/jpeg;base64,${avatars[val.contact.id]}`}
              >
                <span className={classes.avatarTextWrapper}>
                  {getInitials(val.contact.name)}
                </span>
              </Avatar>
            )}
            label={val.contact.name}
            {...getTagProps({index})}
          />
        ))}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.contact.name}
          </li>
        )}
        disabled={disabled || allUsersSelected}
      />
      
      <div>
        {allUsersCheckbox && (
          <FormControlLabel
            label={`${t("lang.all_users")}`}
            control={
              <Checkbox checked={!!values.allUsers} />
            }
            disabled={disabled}
            onChange={handleAllUsersChange}
          />
        )}
      </div>

      {dialog && 
        <>
          <AvatarGroup
            max={10} 
            style={{ float: 'left' }}
          >
            {!allUsersSelected && values.users.map((user) => (
              <Tooltip
                key={user.id}
                title={user.contact.name}
              >
                <Avatar 
                  className={classes.avatar} 
                  src={`data:image/jpeg;base64,${avatars[user.contact.id] && avatars[user.contact.id]}`}
                >
                  {getInitials(user.contact.name)}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
          <div
            style={{
              float: 'right',
              marginTop: 10
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onDialogClose}
            >
              {t('lang.close')}
            </Button>
          </div>
        </>
      }
    </Wrapper>
  );
}

export default UsersPicker;