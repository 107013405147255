import axios from 'axios';
import { useState } from 'react';

interface GeoDataNationaalGeoRegisterResponse {
  response: {
    numFound: number;
    start: number;
    maxScore?: number;
    docs: {
      bron: string;
      woonplaatscode: string;
      type: string;
      woonplaatsnaam: string;
      huis_nlt: string;
      openbareruimtetype: string;
      gemeentecode: string;
      weergavenaam: string;
      straatnaam_verkort: string;
      id: string;
      gemeentenaam: string;
      identificatie: string;
      openbareruimte_id: string;
      provinciecode: string;
      postcode: string;
      provincienaam: string;
      centroide_ll: string;
      nummeraanduiding_id: string;
      adresseerbaarobject_id: string;
      huisnummer: number; // OR STRING ???
      provincieafkorting: string;
      centroide_rd: string;
      straatnaam: string;
      gekoppeld_perceel: any; // ??? (VAAG IN DE DOCS !)
    }[]
  }
}

const extractNumericPart = (nr: string): string => {
  const match = nr.match(/^\d+/); // Regular expression to match the numeric part at the start
  return match ? match[0] : ''; // Return the matched numeric part, or an empty string if not found
};


export const geoDataCall = async (zip: string, nr: string) => {
  let numericNr = extractNumericPart(nr); // Extract numeric part from nr
  let url = `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fq=postcode=${zip}`;
  if(nr.length){
    url += `&fq=huisnummer=${numericNr}`;
  }

  const response = await axios.get<GeoDataNationaalGeoRegisterResponse>(url);
  
  if (response.data.response.docs.length) {
    return {
      street: response.data.response.docs[0].straatnaam,
      city: response.data.response.docs[0].woonplaatsnaam,
      province: response.data.response.docs[0].provincienaam
    }
  }

  return null;
}

const initialNewAddressValues: {
  street: string | null,
  city: string | null,
  province: string | null
} = {
  street: null,
  city: null,
  province: null
};

const useAddressAutofillController = () => {
  const [newValues, setNewValues] = useState(initialNewAddressValues);
  
  const handleNewZip = async (zip: string, nr: string) => {
    // setNewValues({ street: null, city: null, province: null });
    // geoDataCall(zip);
  
    if(zip.length && nr.length){
      const values = await geoDataCall(zip, nr);
      if(values){
        setNewValues(values);
      }else{
        setNewValues({ street: null, city: null, province: null });
      }
    }else{
      setNewValues({ street: null, city: null, province: null });
    }
  }

  return {
    handleNewZip,
    newValues
  }
}

export type AddressAutofillController = ReturnType<typeof useAddressAutofillController>;

export default useAddressAutofillController;