import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { File } from 'src/types/api/file';

const initialState: File[] = [];

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFiles(state: File[], action: PayloadAction<File[]>){
      return action.payload;
    },
    createFile(state: File[], action: PayloadAction<File>){
      state.push(action.payload);
    },
    updateFile(state: File[], action: PayloadAction<File>){
      return state.map(file => file.id !== action.payload.id ? file : action.payload);
    },
    deleteFile(state: File[], action: PayloadAction<File>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setFiles = (data: File[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFiles(data));
}

export const createFile = (data: File): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createFile(data));
}

export const updateFile = (data: File): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFile(data));
}

export const deleteFile = (data: File): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteFile(data));
}

export default slice;