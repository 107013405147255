import { TextField, TextFieldProps } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from 'src/i18n';
import { AddressAutofillController } from "./useAddressAutofillController";

interface Props {
  value: string;
  onChange: (value: string) => void;
  addressAutofill?: AddressAutofillController;
  textFieldProps?: TextFieldProps;
}

const Province = ({
  value,
  onChange,
  addressAutofill,
  textFieldProps
}: Props) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (addressAutofill) {
      const newProvinceValue = addressAutofill.newValues.province;
      if (newProvinceValue !== value) { 
        onChange(newProvinceValue ?? '');
      }
    }
  }, [addressAutofill?.newValues.province, value, onChange]);

  return (
    <TextField 
      variant='standard'
      label={`${t('lang.province')}`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      {...textFieldProps}
    />
  )
}
export default Province;