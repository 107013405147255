import TimePicker from './components/TimePicker';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material';
import { useTranslation } from 'src/i18n';

interface Props {
  value: {
    start: dayjs.Dayjs | null;
    end: dayjs.Dayjs | null;
  },
  onChange: (value: { start: dayjs.Dayjs | null; end: dayjs.Dayjs | null; }) => void;
  error?: {
    start: boolean;
    end: boolean;
  };
  onError?: React.Dispatch<React.SetStateAction<{ start: boolean; end: boolean; }>>;
  noLabels?: boolean;
}

const TimeRangePicker = ({
  value,
  onChange,
  error,
  onError,
  noLabels = false
}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const handleChange = (val: dayjs.Dayjs | null, position: 'start' | 'end') => {
    if(position === 'start'){
      const newEndValue = value.end ? value.end : val;
      onChange({ start: val, end: newEndValue });
    }
    if(position === 'end'){
      const newStartValue = value.start ? value.start : val;
      onChange({ start: newStartValue, end: val });
    }
  }
  
  return (
    <div style={{ display: 'flex' }}>
      <TimePicker 
        value={value.start}
        onChange={(val) => handleChange(val, 'start')}
        error={error?.start}
        onError={(val) => onError && onError(prev => ({ ...prev, start: val }))}
        textFieldProps={{
          label: !noLabels ? `${t('lang.start')}` : undefined
        }}
      />
      <div 
        style={{ 
          color: theme.palette.text.primary, 
          margin: `0px ${theme.spacing(3)}`,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <span style={{ marginTop: theme.spacing(1.5) }}>
          &#8212;
        </span>
      </div>
      <TimePicker 
        value={value.end}
        onChange={(val) => handleChange(val, 'end')}
        error={error?.end}
        onError={(val) => onError && onError(prev => ({ ...prev, end: val }))}
        minTime={(value.start && value.start.isValid()) ? value.start : undefined} // ???
        textFieldProps={{
          label: !noLabels ? `${t('lang.end')}` : undefined
        }}
      />
    </div>
  )
}
export default TimeRangePicker;