import { yupResolver } from '@hookform/resolvers/yup';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Box, Divider, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ActivityList } from 'src/components/general/ActivityList';
import Avatar from 'src/components/general/Avatar';
import ConfirmDialog from 'src/components/general/ConfirmDialog';
import ContactPickerDialog, { SelectedContactCard } from 'src/components/general/ContactPickerDialog';
import { DateRangePicker, DateTimeRangePicker } from 'src/components/general/DateTimePicker_v3';
import CustomDialog from 'src/components/general/Dialog';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import { ActionButton, ActionButtonGroup } from 'src/components/general/DuotoneDialog/ActionButton';
import NormalizationTextField from "src/components/general/NormalizationTextField";
import CTags from 'src/components/general/Tags';
import UsersPicker from 'src/components/general/UsersPicker';
import { useAxiosOptions } from "src/hooks/general/useAxios";
import useCalendarOverlap from 'src/hooks/general/useCalendarOverlap';
import useClaim from 'src/hooks/general/useClaim';
import useCurrentBreakpoint from "src/hooks/general/useCurrentBreakpoint";
import { useTranslation } from 'src/i18n';
import {
  CreateTaskRequestData,
  UpdateTaskRequestData,
  createTaskRequest,
  deleteTaskRequest, updateTaskRequest
} from "src/requests/projects";
import { closeForm, setFormUpdateIdAndInitialValues } from 'src/slices/forms';
import { useDispatch, useSelector } from "src/store";
import * as yup from 'yup';
import PriorityPicker from "./PriorityPicker";
import FinishedByText from "./components/FinishedByText";
import NewDialogAvatarComponent from "./components/NewDialogAvatarComponent";
import NewDialogSubtitle from './components/NewDialogSubtitle';
import SelectStepDialog from './components/SelectStepDialog';
import Subtasks from "./components/Subtasks";
import TaskFiles from './components/TaskFiles';
import { DEFAULT_VALUES } from './constants';
import datesAreInvalid from "./datesAreInvalid";
import { FormValues } from './types';

const getFormDateUnix = (date: Dayjs | null, isAllDay: boolean, addDay = false) => {
  if (!date) {
    return null;
  }
  if (isAllDay) {
    // return dayjs(date).add(1, 'day').format('YYYY-MM-DD 00:00:00');
    return date
      
      .add(addDay ? 1 : 0, 'day')
      .startOf('day')
      .unix();
  }

  return date.set('seconds', 0).unix();
}

const yupSchema = yup.object().shape({
  title: yup.string().required('error.required'),
  // todo
});

const Task = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bp = useCurrentBreakpoint();
  const axiosOptions = useAxiosOptions();

  const contacts = useSelector(state => state.lists.contacts);

  const {
    open: formOpen,
    updateId,
    initialValues
  } = useSelector(state => state.forms.task);

  const { 
    overlaps: overlapsCheck
  } = useCalendarOverlap();

  const projects = useSelector(state => state.lists.projects.projects);
  const tasks = useSelector(state => state.lists.projects.tasks);
  const activity = useSelector(state => state.activity);
  const users = useSelector(state => state.lists.users);

  const selectedTask = useMemo(() => tasks.find(task => task.id === updateId), [tasks, updateId]);

  const handleClose = () => {
    dispatch(closeForm('task'));
  }

  const {
    watch,
    setValue,
    control,
    formState: {
      errors,
      isSubmitting,
      isDirty: formIsDirty
    },
    handleSubmit,
    reset,
    setError
  } = useForm<FormValues>({
    defaultValues: { ...DEFAULT_VALUES, ...initialValues },
    resolver: yupResolver(yupSchema)
  });
  const subtaskFieldArray = useFieldArray({ control, name: 'subtasks' });
  const watchValues = watch();

  // const claim = useClaim(selectedTask ? selectedTask.id : `unused_claim-${uuidv4()}`);
  const claim = useClaim(selectedTask ? selectedTask.id : null);

  const disableAllFields = 
    !!watchValues.archived_at ? true :
    (!!selectedTask?.archived_at) ? true : 
    ((claim && claim.claimUser) ? !claim.currentUserHasFirstClaim : false);

  const [otherDirty, setOtherDirty] = useState(false);

  const DateTimePickerComponent = watchValues.all_day ? DateRangePicker : DateTimeRangePicker;

  // const [selectedStepId, setSelectedStepId] = useState<string | null>(null);

  const [selectStepDialogOpen, setSelectStepDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [membersDialogOpen, setMembersDialogOpen] = useState(false);

  const allSteps = useMemo(() => projects.reduce((prev, current) => [...prev, ...current.steps], []), [projects]);
  const selectedStep = useMemo(() => allSteps.find(step => step.id === watchValues.stepId), [allSteps, watchValues.stepId]);

  const currentProject = useMemo(() => 
    projects.find(project => !!project.steps.find(step => step.id === watchValues.stepId))
  , [projects, watchValues.stepId]);

  // isEdit = updateId !!!
  // otherdirty vervangen door controllers ???

  const defaultOverlapsDialog = { open: false, cb: () => {} };
  const [overlapsDialog, setOverlapsDialog] = useState<{
    open: boolean;
    cb: Function;
  }>(defaultOverlapsDialog);

  const onSubmit = async (data: FormValues, e: any, ignoreOverlaps: boolean = false) => {
    const should_archive = archiveDialogOpen;
    setArchiveDialogOpen(false);

    if(
      data.requireMemberSelect && 
      !data.member_ids.length && 
      !data.all_users
    ){
      setError('requireMemberSelect', new Error('lang.required'))
      return;
    }

    const dateError = datesAreInvalid(data.start_date, data.end_date);
    if(dateError){
      setError(dateError.name, dateError.error);
      return;
    }

    // Check if is update, then only prompt for overlaps if start or end date has changed
    if(data.start_date && data.end_date && !ignoreOverlaps){
      const overlapsResult = overlapsCheck(
        data.start_date.unix(), 
        data.end_date.unix(), 
        data.all_users ? null : members,
        updateId ? { task: [updateId] } : {}
      );

      console.log(overlapsResult);
      
      const overlaps = Object.values(overlapsResult).find(res => res.length);
      const callback = handleSubmit((data, e) => onSubmit(data, e, true));

      if(overlaps){
        if(taskToUpdate){
          const updateDateTimeChanged = (taskToUpdate.start_date !== data.start_date.unix() || taskToUpdate.end_date !== data.end_date.unix())  
          if(updateDateTimeChanged){
            setOverlapsDialog({ open: true, cb: callback });
            return;
          }
        }else{
          setOverlapsDialog({ open: true, cb: callback });
          return;
        }
      }

    }

    const createValues: CreateTaskRequestData = {
      title: data.title,
      all_day: data.all_day,
      contact_id: data.contact ? data.contact.id : null,
      description: data.description,
      start_date: getFormDateUnix(data.start_date, data.all_day),
      end_date: getFormDateUnix(data.end_date, data.all_day, true),
      members: data.member_ids,
      all_users: data.all_users,
      priority: data.priority,
      project_step: data.stepId,
      sub_tasks: data.subtasks.map(subtask => ({
        id: subtask.id,
        title: subtask.title,
        finished: subtask.finished
      })),
      tag_ids: data.tags,
      file_ids: data.file_ids
    };

    if(updateId){
      const updateValues: UpdateTaskRequestData = {
        ...createValues,
        comment_ids: data.activity.filter((x) => !!x.comment).map((x) => x.id), // where object_id == null ??
        archive: should_archive
      };

      await updateTaskRequest(updateId, updateValues, dispatch, axiosOptions.apiConfig);
      handleClose();
      return;
    }

    await createTaskRequest(createValues, dispatch, axiosOptions.apiConfig);
    handleClose();
  }

  const handleDelete = async () => {
    setDeleteDialogOpen(false);
    if(selectedTask){
      await deleteTaskRequest(selectedTask.id, dispatch, axiosOptions.apiConfig)
      .catch(err => console.log(err));
      handleClose();
    }
  }

  const handleCopyTask = () => {
    dispatch(setFormUpdateIdAndInitialValues({
      key: 'task',
      initialValues: {},
      updateId: null,
      callback: null
    }));

    const initialValues: Partial<FormValues> = {
      ...watchValues,
      activity: [],
      file_ids: [],
      isCopy: true
    };
    reset({ ...DEFAULT_VALUES, ...initialValues });
  }

  // onOpen create dialog useEffect 
  useEffect(() => {
    if (formOpen && !updateId) {
      reset({ ...DEFAULT_VALUES, ...initialValues });
    }
  }, [formOpen]);

  useEffect(() => {
    if(formOpen){
      setOtherDirty(false);
    }
  }, [formOpen]);

  // before open update dialog useEffect ( on selected task change )
  const taskToUpdate = useMemo(() => tasks.find(task => task.id === updateId), [tasks, updateId]);
  useEffect(() => {
    if(taskToUpdate){

      let newEndDate = taskToUpdate.end_date ? dayjs.unix(taskToUpdate.end_date) : null;
      if(newEndDate && taskToUpdate.all_day){
        newEndDate = newEndDate.subtract(1, 'second');
      }

      const foundContact = contacts.find(c => c.id === taskToUpdate.contact_id);

      reset({
        ...DEFAULT_VALUES,
        title: taskToUpdate.title,
        description: taskToUpdate.description ? taskToUpdate.description : '',
        subtasks: taskToUpdate.sub_tasks.map((task) => ({
          id: task.id,
          title: task.title,
          finished: !!task.finished_by
        })),
        member_ids: taskToUpdate.member_ids,
        all_users: taskToUpdate.all_users,
        all_day: taskToUpdate.all_day,
        start_date: taskToUpdate.start_date ? dayjs.unix(taskToUpdate.start_date) : null,
        end_date: newEndDate,
        priority: taskToUpdate.priority,
        contact: foundContact ? foundContact : null,
        activity: activity[taskToUpdate.id] ? activity[taskToUpdate.id] : [],
        tags: taskToUpdate.tags,
        stepId: taskToUpdate.step_id,
        file_ids: taskToUpdate.file_ids,
        ...initialValues // ???
      })
    }
  }, [taskToUpdate]);

  const { claimUser, currentUserHasFirstClaim } = useClaim(updateId ? updateId : null);

  const [contactPickerOpen, setContactPickerOpen] = useState(false);

  const getContactPrimaryPhone = (contact_id: string) => {
    const contact = contacts.find(c => c.id === contact_id);
    if(contact){
      const defaultContactPhone = contact.phone.find(c => c.default);
      if(defaultContactPhone){
        return defaultContactPhone.phone;
      }
    }
    return undefined;
  }

  const members = useMemo(() => 
    users.filter(user => watchValues.member_ids.includes(user.id))
  , [users, watchValues.member_ids]);

  return (
    <DuotoneDialog
      open={formOpen}
      title={watchValues.title ? watchValues.title : (updateId ? t('lang.update_task') : t('lang.new_task'))}
      subtitle={(
        // TODO !!!!
        <NewDialogSubtitle
          selectedStepId={watchValues.stepId}
          disableAllFields={disableAllFields}
          onOpenSelectStep={() => setSelectStepDialogOpen(true)}
          selectedTask={selectedTask ? selectedTask : null}
        />
      )}
      onClose={handleClose}
      // dialogProps: { transitionDuration: DIALOG_TRANSITION_DURATION },
      onSubmit={handleSubmit(onSubmit)}
      submitButtonProps={{
        disabled: disableAllFields || isSubmitting || (!formIsDirty && !otherDirty 
          // && !isCopy
        )
      }}
      cancelButtonProps={{ disabled: isSubmitting }}
      avatarComponent={watchValues.all_users ? null : <NewDialogAvatarComponent members={members} />}
      disableOutsideClick
      // infoBar={getInfoBarProp(t, selectedTask ? selectedTask : null, null, false, selectedTask ? !!selectedTask.archived_at : false, false)} // TODO: FIX !!!
      // onCopy and archived todo (see getInfoBarProp)
      alert={!!watchValues.isCopy ? {
        open: true,
        title: `${t('lang.is_copy')}`,
        startComponent: (
          <FileCopyOutlinedIcon />
        )
      } : updateId ? {
        open: !currentUserHasFirstClaim && !!claimUser,
        title: claimUser ? `${claimUser.contact.name} ${t('lang.is_editing_task')}` : undefined,
        startComponent: !claimUser ? null : (
          <Avatar
            name={claimUser.contact.name}
            user_id={claimUser.id}
            size='small'
          />
        )
      } : undefined}
    >
      <div>
        <FinishedByText selectedTask={selectedTask} />
        <Box>
          <CTags
            tagIds={watchValues.tags ? watchValues.tags : []}
            onChange={(newTagIds) => {
              setOtherDirty(true);
              setValue('tags', newTagIds);
            }}
            category='task'
            disableCreate={disableAllFields}
            disableRemove={disableAllFields}
          />
        </Box>
        <Box mt={3}>
          <Controller
            name='title'
            control={control}
            render={({ field }) => (
              <NormalizationTextField
                label={`${t("lang.title") + '*'}`}
                variant='standard'
                fullWidth
                error={!!errors.title}
                helperText={errors.title?.message}
                normalization='first_to_upper'
                disabled={disableAllFields}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={3}>
          <Controller
            name='description'
            control={control}
            render={({ field }) => (
              <NormalizationTextField
                label={`${t("lang.description")}`}
                variant='standard'
                fullWidth
                error={!!errors.description}
                helperText={errors.description?.message}
                multiline
                normalization='first_to_upper'
                disabled={disableAllFields}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={3}>
          <DateTimePickerComponent
            value={{
              start: watchValues.start_date,
              end: watchValues.end_date
            }}
            onChange={(val) => {
              setOtherDirty(true);
              setValue('start_date', val.start);
              setValue('end_date', val.end);
            }}
            error={{
              start: !!errors.start_date,
              end: !!errors.end_date
            }}
            disabled={disableAllFields}
          />
        </Box>
        <Box mt={3}>
          <div style={{ display: 'flex' }}>
            <FormGroup>
              <Controller
                name='all_day'
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <FormControlLabel
                    label={`${t("lang.all_day")}`}
                    control={
                      <Switch
                        checked={value}
                        {...rest}
                      />
                    }
                    disabled={disableAllFields}
                  />
                )}
              />
            </FormGroup>
            {/* Deze weghalen ??? */}
            <FormGroup>
              <Controller
                name='recurring'
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <FormControlLabel
                    label={(
                      <span style={{ display: 'flex' }}>
                        {t("lang.repeat")}{': '}
                        <Typography style={{ marginLeft: 10, fontWeight: 'bold' }}>
                          {!watchValues.recurring ? t('lang.none') : 'datum todo'}
                        </Typography>
                      </span>
                    )}
                    control={
                      <Switch
                        disabled
                        checked={value}
                        {...rest}
                      />
                    }
                  />
                )}
              />
            </FormGroup>
          </div>
        </Box>
        <Box mt={3}>
          <PriorityPicker
            value={watchValues.priority}
            onChange={(value) => { setValue('priority', value); setOtherDirty(true); }}
            disabled={disableAllFields}
          />
        </Box>
        <Box mt={3}>
          <Subtasks
            subtaskFieldarray={subtaskFieldArray}
            disableAllFields={disableAllFields}
          />
        </Box>
        <Box mt={3}>
          <Divider />
        </Box>

        <Box mt={3}>
          <TaskFiles 
            task_id={updateId ? updateId : undefined} 
            file_ids={watchValues.file_ids}
            // HIER NAAR KIJKEN !!!
            // setFileIds={(file_ids) => { setValue('file_ids', file_ids); setOtherDirty(true); }}
            setFileIds={(file_ids) => { setValue('file_ids', file_ids); setOtherDirty(true); }}
            disabled={disableAllFields}
          />
        </Box>
        <Box mt={3}>
          <Divider />
        </Box>

        <Box mt={3}>
          {bp !== 'xs' && updateId && (
            <Box mt={3}>
              {selectedTask && (
                <ActivityList
                  // category='task'
                  objectId={selectedTask.id}
                  // urlPrefix={`tasks/${selectedTask.id}`}
                  disabled={disableAllFields}
                />
              )}
            </Box>
          )}
        </Box>
      </div>
      <div style={{ width: '100%', }}>
        <ActionButtonGroup 
          title={`${t('lang.contact')}`}
          disableMarginTop
        >
          {watchValues.contact ? (
            <SelectedContactCard 
              contact={watchValues.contact}
              onDelete={() => {
                setValue('contact', null);
                setOtherDirty(true);
              }}
              replaceClientNumber={watchValues.contact ? getContactPrimaryPhone(watchValues.contact.id) : undefined}
              disabled={disableAllFields}
            />
          ) : (
            <ActionButton
              icon='mdiAccountOutline'
              onClick={() => setContactPickerOpen(true)}
              disabled={disableAllFields}
            >
              {t('lang.select_contact')}
            </ActionButton>
          )}
        </ActionButtonGroup>
        <ActionButtonGroup title={`${t('lang.task')}`}>
          <ActionButton
            icon={(
              <span
                style={{
                  backgroundColor: selectedStep?.color ? selectedStep.color : '#000',
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  marginRight: 3,
                  marginLeft: 5
                }}
              />
            )}
            disabled={disableAllFields}
            onClick={() => setSelectStepDialogOpen(true)}
          >
            {selectedStep && selectedStep.title}
          </ActionButton>
          <ActionButton
            icon='mdiAccountMultipleOutline'
            disabled={disableAllFields}
            onClick={() => setMembersDialogOpen(true)}
            error={!!errors.requireMemberSelect}
          >
            {watchValues.all_users ? t('lang.all_members') : !watchValues.member_ids.length ? t('lang.no_members')
              :
              watchValues.member_ids.length === 1 ?
                `1 ${t('lang.member')}`
                :
                `${watchValues.member_ids.length} ${t('lang.members')}`
            }
          </ActionButton>
          
        </ActionButtonGroup>
        {updateId && (
        <ActionButtonGroup title={`${t('lang.actions')}`}>
          <ActionButton
            icon='mdiFileMultipleOutline'
            disabled={disableAllFields || isSubmitting || (formIsDirty || otherDirty)}
            onClick={handleCopyTask}
          >
            {t('lang.copy')}
          </ActionButton>
          <ActionButton
            icon='mdiArchiveOutline'
            disabled={disableAllFields || isSubmitting}
            onClick={() => setArchiveDialogOpen(true)}
          >
            {t('lang.archive')}
          </ActionButton>
          <ActionButton
            icon='mdiDeleteOutline'
            disabled={disableAllFields}
            onClick={() => setDeleteDialogOpen(true)}
            isDelete
          >
            {`${t('lang.delete')}`}
          </ActionButton>
        </ActionButtonGroup>
        )}
        {bp === 'xs' && (
          <ActionButtonGroup title={`${t('lang.activity')}`}>
            <Box sx={{ mt: 3 }}>
              {selectedTask &&
                <ActivityList
                  // category='task'
                  objectId={selectedTask.id}
                  // urlPrefix={`tasks/${selectedTask.id}`}
                />
              }
            </Box>
          </ActionButtonGroup>
        )}
        <ContactPickerDialog 
          open={contactPickerOpen}
          onClose={() => setContactPickerOpen(false)}
          onSelect={(value) => {
            setValue('contact', value);
            setOtherDirty(true);
          }}
          selectedContactIds={[]}
          title={`${t('lang.select_contact')}`}
        />
        <SelectStepDialog
          open={selectStepDialogOpen}
          onClose={() => setSelectStepDialogOpen(false)}
          project={currentProject}
          selectedStepId={watchValues.stepId}
          onChange={(step) => { setValue('stepId', step.id); setOtherDirty(true); }}
        />
        <UsersPicker
          label={`${t('lang.members')}`}
          values={{ 
            users: members, 
            allUsers: watchValues.all_users
          }}
          onChange={(val) => { 
            setValue('member_ids', val.users.map(user => user.id));
            setValue('all_users', !!val.allUsers);
            setOtherDirty(true); 
          }}
          dialog
          dialogOpen={membersDialogOpen}
          onDialogClose={() => setMembersDialogOpen(false)}
          dialogTitle={`${t('lang.members')}`}
          autoFocus
          allUsersCheckbox
        // dialogTitle={`Selecteer leden`}
        />
        <CustomDialog
          open={deleteDialogOpen}
          onSubmit={handleDelete}
          onClose={() => setDeleteDialogOpen(false)}
          title={`${t('lang.delete')}`}
          submitButtonText={`${t('lang.delete')}`}
          isDelete
        >
          <Typography>{t('lang.are_you_sure')}</Typography>
        </CustomDialog>
        <CustomDialog
          open={archiveDialogOpen}
          onSubmit={handleSubmit(onSubmit)}
          onClose={() => setArchiveDialogOpen(false)}
          title={`${t('lang.archive')}`}
          submitButtonText={`${t('lang.archive')}`}
        >
          <Typography>{t('lang.are_you_sure')}</Typography>
        </CustomDialog>
        <ConfirmDialog
          open={overlapsDialog.open}
          onConfirm={() => {
            overlapsDialog.cb();
            setOverlapsDialog(defaultOverlapsDialog);
          }}
          onCancel={() => setOverlapsDialog(defaultOverlapsDialog)}
          titleText={'Overlapt...'}
          contentText={'Let op: als je doorgaat wordt er een ander evenement overlapt'}
        />
      </div>
    </DuotoneDialog>
  )
}
export default Task;