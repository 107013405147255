import * as yup from 'yup';

export const default_string = yup.string().max(255, 'error.too_many_characters').required('error.required');
export const default_number = yup.number().required('error.required');
export const email = yup.string().email('error.incorrect_email').max(255, 'error.too_many_characters').required('error.required');
export const new_password = yup.string().min(8, 'error.too_few_characters').max(255, 'error.too_many_characters').required('error.required');
export const alias = (min: number = 2, max: number = 30) => yup.string().min(min, 'error.too_few_characters').max(max, 'error.too_many_characters');

// DEZE TESTEN !!! & NAAR MATCHES !!!
// export const numeric_string = yup.string().test('numeric_string', 'error.should_be_numeric', (value: string) => /^\d+$/.test(value));

export const numeric_string = yup.string().required('error.required').matches(/^\d+$/, 
  { message: 'error.invalid_url', excludeEmptyString: true }
)

export const phone_number = yup.string().required('error.required')
  .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 
  { message: 'error.invalid_number', excludeEmptyString: true }
);

export const website_url = yup.string().required('error.required')
  .matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, 
  { message: 'error.invalid_url', excludeEmptyString: true }
);

export const confirm_new_password  = (ref: string = 'password') => {
  return new_password.oneOf([yup.ref(ref), null], 'error.does_not_match')
}

export const coc_number = yup.string().required('error.required')
  .matches(/^\d{8}$/, 
  { message: 'error.invalid_coc_number', excludeEmptyString: true }
);

// ! Dutch Vat number only a.t.m.!
export const vat_number = yup.string().required('error.required')
  .matches(/^(NL|nl)?[0-9]{9}B[0-9]{2}$/, 
  { message: 'error.invalid_vat_number', excludeEmptyString: true }
);

export default { 
  default_string,
  default_number,
  email, 
  new_password,
  alias,
  numeric_string,
  phone_number,
  website_url,
  confirm_new_password,
  coc_number,
  vat_number
};