import React, { useContext } from 'react';
import { CircularProgress, Grid, useTheme } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import { AppContext } from 'src/App';
import { imageUrls } from 'src/config';

const useStyles = makeStyles()((theme) => ({
  logo: {
    width: '150px'
  }
}));

interface IProps {
  paper?: boolean;
  hideBackground?: boolean;
}

export default function Loading({
  paper = true,
  hideBackground = false
}: IProps){
  const { classes } = useStyles();
  const theme: any = useTheme();
  const appContext: any = useContext(AppContext);

  if(!appContext.group.id){
    return (
      <CircularProgress 
        style={{
          color: 'primary',
          margin: 10,
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        // height: 'calc(100vh - 64px)',
        height: 'calc(100vh - 113px)',
        overflow: 'hidden',
        backgroundColor: hideBackground ? 'transparent' : theme.custom.backgroundDark,
        alignItems: 'center'
      }}
    >
      <div style={{width: '150px', textAlign: 'center'}}>
        <img
          className={classes.logo}
          // @ts-ignore
          src={imageUrls(appContext.group.contact.id)[`logo_${theme.name.toLowerCase()}`]}
        />
        <CircularProgress 
          style={{
            color: 'primary',
            margin: 10,
          }}
        />
      </div>
    </div>
  );
}