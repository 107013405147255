import { alpha, IconButton, LinearProgress, Typography, useTheme } from "@mui/material";
import { Upload } from "src/types/api/dataTransfer";
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { makeStyles } from "src/theme/makeStyles";

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex', 
    alignItems: 'center', 
    width: '100%',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    cursor: 'grab',
    '&:hover': {
      backgroundColor: alpha('#000', 0.15),
      '& .subtask-iconbuttons': {
        opacity: 1,
        pointerEvents: 'auto',
        position: 'static',
        display: 'flex'
      }
    }
  }
}));

interface Props {
  upload: Upload;
  onDelete: () => void;
  disabled?: boolean;
}

const UploadFileRow = ({
  upload,
  onDelete,
  disabled = false
}: Props) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <div
      className={classes.root}
    >
      <UploadOutlinedIcon 
        style={{
          marginRight: theme.spacing(1)
        }}
      />
      <div style={{ width: 400, marginRight: theme.spacing(1) }}>
        <Typography
          color={theme.palette.text.primary}
          variant='h6'
          textOverflow={'ellipsis'}
          noWrap
        >
          {upload.file.name}
        </Typography>
        <LinearProgress 
          variant="determinate"
          value={upload.progress}
        />
      </div>
      <div
        style={{ marginLeft: 'auto' }}
      >
        <IconButton
          size="small"
          onClick={() => onDelete()}
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  )
}
export default UploadFileRow;