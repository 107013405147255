import { Box, Button } from "@mui/material"
import { useTranslation } from 'src/i18n';

interface Props {
  onBack: () => void;
  onNext: () => void;
  disableBack?: boolean;
  disableNext?: boolean;
}

const BackNextButtons = ({
  onBack,
  onNext,
  disableBack = false,
  disableNext = false
}: Props) => {
  const {t} = useTranslation();
  return (
    <Box my={1.5}>
      <Button
        disabled={disableBack}
        onClick={onBack}
        sx={{ mt: 1, mr: 1 }}
      >
        {t('lang.back')}
      </Button>
      <Button
        variant="contained"
        onClick={onNext}
        sx={{ mt: 1, mr: 1 }}
        disabled={disableNext}
      >
        {t('lang.next')}
        {/* {index === steps.length - 1 ? 'Finish' : 'Continue'} */}
      </Button>
    </Box>
  )
}

export default BackNextButtons;