import { Grid, Typography, TextField, useTheme, MenuItem } from "@mui/material";
import { useMemo } from "react";
import NumberFormat from "react-number-format";
import AvatarGroupButton from "src/components/general/AvatarGroupButton";
import { useTranslation } from "src/i18n";
import { useSelector } from "src/store";

interface Props {
  childService: {
    id: string | null;
    duration: number | null;
    afterWorkDuration: number | null;
  };
  onChangeDuration: (newVal: number | null) => void;
  onChangeAfterWorkDuration: (newVal: number | null) => void;
  error?: boolean;
  // onChange
}

const ChildServiceRow = ({
  childService,
  onChangeDuration,
  onChangeAfterWorkDuration,
  error = false
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const stateServices = useSelector(state => state.lists.services.services);
  const stateChildServices = useMemo(() => stateServices.filter(service => service.is_child), [stateServices]);

  const getChildServiceById = (id: string) => {
    const found = stateChildServices.find(s => s.id === id);
    return found ? found : null;
  }

  if (!childService.id) {
    return null;
  }

  const foundChildService = getChildServiceById(childService.id);
  if (!foundChildService) {
    return null;
  }

  return (
    <>
      <Grid item xs={3}>
        <TextField
          // label={`${t('lang.service')}`}
          value={foundChildService.title}
          variant='standard'
          disabled
          fullWidth
          // error={error}
          style={{ paddingLeft: theme.spacing(3) }}
        />
      </Grid>
      <Grid item xs={1.5}>
        <NumberFormat
          value={childService.duration}
          onValueChange={(values) => {
            const newVal = values.floatValue ? values.floatValue : null;
            onChangeDuration(newVal);
          }}
          customInput={TextField}
          allowNegative={false}
          variant='standard'
          fullWidth
          // label={`${t('lang.duration')}`}
          autoComplete='off'
          error={error}
          // disabled={disabled}
        />
      </Grid>
      <Grid item xs={1.5}>
        <NumberFormat
          value={childService.afterWorkDuration}
          onValueChange={(values) => {
            const newVal = values.floatValue ? values.floatValue : null;
            onChangeAfterWorkDuration(newVal);
          }}
          customInput={TextField}
          allowNegative={false}
          variant='standard'
          fullWidth
          // label={`${t('lang.after_work_duration')}`}
          autoComplete='off'
          error={error}
          // disabled={disabled}
        />
      </Grid>
      <Grid item xs={2}>
        {/* Price */}
        <TextField 
          // label={`${t('lang.price')}`}
          value='-'
          variant='standard'
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        {/* <div style={{ height: 48 }}>
          <AvatarGroupButton
            selectedUserIds={[]}
            setSelectedUserIds={(user_ids) => {
              // const value = user_ids.length ? user_ids[0] : null;
              // setValue(`services.${index}.employee`, value);
            }}
            singleUser
            dark
          />
        </div> */}
        {/* Employee */}
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );

}
export default ChildServiceRow;