import { AnyAction, Store } from 'redux';
import type { AppDispatch } from 'src/store/index';
import type { ContactSmall } from 'src/types/api/contact';
import { createContact, updateContact, deleteContact } from 'src/slices/lists/contacts';
import { setCurrentContact } from 'src/slices/current';
import { AxiosOptions, useAxios } from 'src/hooks/general/useAxios';
import type { RootState } from 'src/store';
import { AxiosInstance } from 'axios';
import { EchoChannel as Channel } from '../index';
import { updateGroup } from 'src/slices/lists/groups';
import { setGeneralGroup } from 'src/slices/general/group';

export default (
  channel: Channel, 
  dispatch: AppDispatch, 
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions,
  axios: AxiosInstance
) => {

  channel.listen('.create_contact', (data: any) => {
    const contacts = data.data.response as ContactSmall[];
    contacts.map((contact) => {
      dispatch(createContact(contact));
    });
  });

  channel.listen('.update_contact', (data: any) => { //
    console.log('Check what to do with own contact !!!');

    const currentContactId = store.getState().current.contact?.id;
    const ownGroup = store.getState().general.group;

    const contacts = data.data.response as ContactSmall[];
    contacts.map((contact) => {
      const matchesCurrentContact = currentContactId && currentContactId === contact.id;
      const matchesOwnGroupContact = ownGroup && ownGroup.contact.id === contact.id;

      if(matchesCurrentContact || matchesOwnGroupContact){
        axios.get(`contacts/${contact.id}`, axiosOptions.apiConfig)
        .then((response) => {
          if(matchesCurrentContact){
            console.log('TODO: current alert');
            // setCurrentAlert({
            //   open: true,
            //   message: t("messages.current_contact_updated"),
            //   user: data.data.user
            // });
            dispatch(setCurrentContact(response.data));
          }

          if(matchesOwnGroupContact){
            dispatch(setGeneralGroup({
              ...ownGroup, 
              contact: response.data 
            }));
          }

        })
        .catch((error) => {
          console.log(error);
        });
      }

      dispatch(updateContact(contact));
    });
  });

  channel.listen('.delete_contact', (data: any)=>{
    const contacts = data.data.response as ContactSmall[];

    const currentContactId = store.getState().current.contact?.id;
    contacts.map((contact) => {
      if(currentContactId && currentContactId === contact.id){
        dispatch(setCurrentContact(null));
      }
      dispatch(deleteContact(contact));
    });
  });

  // channel.listen('.create_contact', (data: any)=>{
  //   const contacts = data.data.response as ContactSmall[];
  //   contacts.map((contact) => {
  //     dispatch(createContact(contact));
  //   });
  // });

  // channel.listen('.update_contact', (data: any)=>{//
  //   console.log('Check what to do with own contact !!!');

  //   const currentContactId = store.getState().current.contact?.id;
  //   const contacts  = data.data.response as ContactSmall[];
  //   contacts.map((contact) => {
  //     if(currentContactId && currentContactId === contact.id){
  //       // Fetch own contact
  //       axios.get(`contacts/${contact.id}`, axiosOptions.apiConfig)
  //       .then((response) => {
  //         console.log('TODO: current alert');
  //         // setCurrentAlert({
  //         //   open: true,
  //         //   message: t("messages.current_contact_updated"),
  //         //   user: data.data.user
  //         // });
  //         dispatch(setCurrentContact(response.data));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     }
  //     dispatch(updateContact(contact));
  //   });
  // });

  // channel.listen('.delete_contact', (data: any)=>{
  //   const contacts = data.data.response as ContactSmall[];

  //   const currentContactId = store.getState().current.contact?.id;
  //   contacts.map((contact) => {
  //     if(currentContactId && currentContactId === contact.id){
  //       dispatch(setCurrentContact(null));
  //     }
  //     dispatch(deleteContact(contact));
  //   });
  // });

}