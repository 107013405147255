import { useEffect, useState } from "react";
import { useTranslation } from 'src/i18n';
import DuotoneDialog from "src/components/general/DuotoneDialog";
import MoneyField from "src/components/general/MoneyField";
import { useAxiosOptions } from "src/hooks/general/useAxios";
import { dayClosingsOpenRequest } from "src/requests/dayClosings";
import { closeForm } from "src/slices/forms";
import { useDispatch, useSelector } from "src/store";
import { FormValues } from "./types";

const OpenRegister = () => {
  const { 
    open: formOpen, 
    initialValues,
    callback
  } = useSelector(state => state.forms.openRegister);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const axiosOptions = useAxiosOptions();

  const { dayClosings } = useSelector(state => state.register);

  const [values, setValues] = useState<FormValues>({ balance: null });

  const handleClose = () => {
    dispatch(closeForm('openRegister'));
  }

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    const result =await dayClosingsOpenRequest({ 
      balance: typeof values.balance === 'number' ? values.balance : 0
    }, dispatch, axiosOptions.apiConfig);

    callback && callback(result);

    setSubmitting(false);
    handleClose();
  }

  useEffect(() => {
    if(formOpen){
      // reset
      // const initialValue = 

      const sortedDayClosings = [...dayClosings].sort((a,b) => {
        if(a.number < b.number){ return 1; }
        if(a.number > b.number){ return -1; }
        return 0;
      });
      
      // BE SURE LAST IS SELECTED !!!
      // console.log(sortedDayClosings);

      // // TODO: maybe change closing_balance on back-end


      // let balance = 0;
      // if(!!sortedDayClosings[0]){
      //   sortedDayClosings[0].payments.map(payment => {
      //     if(payment.type === 'cash'){
      //       balance += payment.price;
      //     }
      //   });
      // }
      // console.log(sortedDayClosings[0]);

      let balance = 0;
      if(!!sortedDayClosings[0]?.closing_balance){
        balance = sortedDayClosings[0].closing_balance;
      }

      // TODO: balance
      setValues({ 
        balance,
        ...initialValues
      });
    }
  }, [formOpen]);

  return (
    <DuotoneDialog
      open={formOpen}
      title={`${t('lang.open_register')}`}
      onClose={handleClose}
      onSubmit={() => handleSubmit()}
      submitButtonProps={{
        disabled: submitting
      }}
      cancelButtonProps={{
        disabled: submitting
      }}
      disableOutsideClick={submitting}
    >
      <div>
        <MoneyField
          value={values.balance ? values.balance : undefined}
          onChange={(e) => setValues({ balance: e ? e : null })}
          fieldProps={{
            decimalScale: 2,
            label: 'Wisselgeld in de kassa' // Change in the checkout
          }}
        />
      </div>
    </DuotoneDialog>
  )
}
export default OpenRegister;