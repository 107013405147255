import { MenuItem, StandardTextFieldProps, TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from 'src/i18n';
import { TemplateSubscription, TemplateSubscriptionSmall } from "src/types/api/templateSubscription";

interface Props {
  subscriptions: TemplateSubscription[] | TemplateSubscriptionSmall[];
  value: string | null;
  onChange: (template_id: string) => void;
  textFieldProps: Partial<StandardTextFieldProps>
}

const SubscriptionSelect = ({
  subscriptions,
  value,
  onChange,
  textFieldProps
}: Props) => {
  const {t} = useTranslation();

  const selectedValue = useMemo(() => {
    const selectedSubscription = subscriptions.find(subscription => subscription.id === value);
    if(selectedSubscription){
      return selectedSubscription.id;
    }
    return '';
  }, [subscriptions, value]);

  if(!subscriptions){
    // console.error('NO SUBSCRIPTIONS !!!');
    return null;
  }

  return (
    <TextField
      label={`${t('lang.subscription')}`}
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      variant='standard'
      select
      {...textFieldProps}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {subscriptions.map((subscription) => (
        <MenuItem key={subscription.id} value={subscription.id}>{subscription.title}</MenuItem>
      ))}
    </TextField>
  )
}
export default SubscriptionSelect;