import { combineReducers } from '@reduxjs/toolkit';

import { reducer as contactsReducer } from 'src/slices/lists/contacts';
import { reducer as groupReducer } from 'src/slices/general/group';
import { reducer as userReducer } from 'src/slices/general/user';
import { reducer as miscReducer } from 'src/slices/misc';
import { reducer as menuReducer } from 'src/slices/general/menu';
import { reducer as settingsReducer } from 'src/slices/general/settings';
import { reducer as timetrackerReducer} from 'src/slices/lists/timetrackers';
import { reducer as usersReducer } from 'src/slices/lists/users';
import { reducer as presentationsReducer } from 'src/slices/lists/presentations';
import { reducer as chatMessagesReducer } from 'src/slices/chatMessages';
import { reducer as generalLanguagesReducer } from 'src/slices/general/languages';
import { reducer as customfieldsReducer } from 'src/slices/general/customfields';
import { reducer as permissionsReducer } from 'src/slices/lists/permissions';
import { reducer as groupsReducer } from 'src/slices/lists/groups';
import { reducer as languagesReducer } from 'src/slices/lists/languages';
import { reducer as rolesReducer } from 'src/slices/lists/roles';
import { reducer as templatesReducer } from 'src/slices/lists/templates';
import { reducer as notesReducer } from 'src/slices/lists/notes';
import { reducer as devicesReducer } from 'src/slices/lists/devices';
import { reducer as currentReducer } from 'src/slices/current';
import { reducer as filtersReducer } from 'src/slices/filters';
import { reducer as invoicingReducer } from 'src/slices/lists/invoicing';
import { reducer as ledgerAccountsReducer } from 'src/slices/lists/ledgerAccounts';
import { reducer as dataTransferReducer } from 'src/slices/dataTransfer';
import { reducer as filesReducer } from 'src/slices/lists/files';
import { reducer as foldersReducer } from 'src/slices/lists/folders';
import { reducer as appointmentReducer } from 'src/slices/lists/appointments';
import { reducer as projectsReducer } from 'src/slices/lists/projects';
import { reducer as activityReducer } from 'src/slices/activity';
import { reducer as tagsReducer } from 'src/slices/lists/tags';
import { reducer as publicwidgetsReducer } from 'src/slices/lists/publicwidgets';
import { reducer as globalActivityReducer } from 'src/slices/lists/globalActivity';
import { reducer as archivesReducer } from 'src/slices/archives';
import { reducer as systemSettingsReducer } from 'src/slices/lists/systemSettings';
import { reducer as productsReducer } from 'src/slices/lists/products';
import { reducer as servicesReducer } from 'src/slices/lists/services';
import { reducer as topbarReducer } from 'src/slices/topbar';
import { reducer as registerReducer } from 'src/slices/register';
import { reducer as workingHoursReducer } from 'src/slices/lists/workingHours';
import { reducer as holidaysReducer } from 'src/slices/lists/holidays';
import { reducer as absencesReducer } from 'src/slices/lists/absences';
import { reducer as formsReducer } from 'src/slices/forms';
import { reducer as messageTemplatesReducer } from 'src/slices/lists/messageTemplates';
import { reducer as giftcardsReducer } from 'src/slices/lists/giftcards';
import { reducer as eventsReducer } from 'src/slices/lists/events';
import { reducer as messagesReducer } from 'src/slices/lists/messages';
import { reducer as eventProjectsReducer } from 'src/slices/lists/eventProjects';

const generalReducer = combineReducers({
  menu: menuReducer,
  user: userReducer,
  customfields: customfieldsReducer,
  group: groupReducer,
  languages: generalLanguagesReducer,
  settings: settingsReducer
});

const listReducer = combineReducers({
  contacts: contactsReducer,
  users: usersReducer,
  permissions: permissionsReducer,
  groups: groupsReducer,
  languages: languagesReducer,
  roles: rolesReducer,
  templates: templatesReducer,
  invoicing: invoicingReducer,
  notes: notesReducer,
  ledger_accounts: ledgerAccountsReducer,
  products: productsReducer,
  services: servicesReducer,
  time_trackers: timetrackerReducer,
  presentations: presentationsReducer,
  devices: devicesReducer,
  files: filesReducer,
  folders: foldersReducer,
  appointments: appointmentReducer,
  projects: projectsReducer,
  tags: tagsReducer,
  public_widgets: publicwidgetsReducer,
  global_activity: globalActivityReducer,
  systemSettings: systemSettingsReducer,
  workingHours: workingHoursReducer,
  holidays: holidaysReducer,
  absences: absencesReducer,
  messageTemplates: messageTemplatesReducer,
  giftcards: giftcardsReducer,
  events: eventsReducer,
  messages: messagesReducer,
  eventProjects: eventProjectsReducer 
});

const rootReducer = combineReducers({
  general: generalReducer,
  lists: listReducer,
  current: currentReducer,
  chatMessages: chatMessagesReducer,
  misc: miscReducer,
  filters: filtersReducer,
  dataTransfer: dataTransferReducer,
  activity: activityReducer,
  archives: archivesReducer,
  topbar: topbarReducer,
  register: registerReducer,
  forms: formsReducer
});

export default rootReducer;
