import {
  TextField
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'src/i18n';

interface Props {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const Searchbar = ({
  searchQuery, 
  setSearchQuery
}: Props) => {
  const {t} = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }

  return (
    <TextField
      value={searchQuery}
      onChange={handleChange}
      fullWidth
      placeholder={`${t('lang.search')}`}
      variant="standard"
    />
  )
}

export default Searchbar;