import { AxiosRequestConfig } from "axios";
import { AppDispatch } from "src/store";
import axios from 'axios';
import { createFile, updateFile } from "src/slices/lists/files";
import { File as TFile } from "src/types/api/file";

interface CreateFileRequestData {
  type: string;
  folder_id: string | null;
  object_id: string | null;
  readonly: boolean;
  private: boolean;
  members: {id: string; readonly: boolean}[];
}

// // Gets file with data
// export const getFileRequest = (
//   config: AxiosRequestConfig
// ): Promise<{ file: TFile; data: string; }> => {
//   return new Promise<{ file: TFile; data: string; }>((resolve, reject) => {
//     axios.get('files', config)
//     .then((response) => {
//       console.log(response.data.file);
//       resolve(response.data);
//     }).catch(() => reject());
//   });
// }

export const createFileRequest = (
  data: CreateFileRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<TFile> => {
  return new Promise<TFile>((resolve, reject) => {
    axios.post<TFile>(`files`, data, config)
    .then((response) => {
      dispatch(createFile(response.data));
      resolve(response.data);
    })
    .catch((error) => reject(error));
  });
}

export const uploadFileRequest = (
  file_id: string,
  file: File,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<TFile> => {
  const formData = new FormData();
  formData.append('file', file);

  return new Promise<TFile>((resolve, reject) => {
    axios.post<TFile>(`files/${file_id}/upload`, formData, config)
    .then(response => {
      dispatch(updateFile(response.data));
      resolve(response.data);
    })
    .catch(err => reject(err));
  });
}

export default createFileRequest;