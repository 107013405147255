import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'react-virtualized/styles.css';
import 'react-resizable/css/styles.css';
import 'src/assets/css/root.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { createRoot } from 'react-dom/client';

// import ReactDOM from 'react-dom';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ReduxProvider store={store}>
    <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SettingsProvider>
    </HelmetProvider>
  </ReduxProvider>
);

serviceWorker.unregister();
reportWebVitals();