import {
  colors,
  responsiveFontSizes,
  createTheme as createMuiTheme,
  ThemeOptions
} from '@mui/material';
import _ from 'lodash';
import type { GroupColors } from 'src/App';
import type { Settings } from 'src/contexts/SettingsContext';
import { THEMES } from '../config';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      backgroundDefaultduo: string;
      backgroundDark: string;
      dominant: string; // ???
      tableHead: string;
    };
  }
  interface ThemeOptions {
    custom?: {
      backgroundDefaultduo?: string;
      backgroundDark?: string;
      dominant?: string; // ???
      tableHead?: string;
    }
  }
}

export const MUI_PICKER_STATIC_WRAPPER_WIDTH = 290;
// const MUI_PICKER_STATIC_WRAPPER_WIDTH = 310;

const getBaseConfig = (primaryColor: string, contrastText: string): ThemeOptions => ({
  direction: 'ltr',
  typography,
  components: {
    MuiFormHelperText:{
      styleOverrides: {
        root: {
          marginBottom: '-18px',
          fontSize: '0.6rem'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    // @ts-ignore
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          width: MUI_PICKER_STATIC_WRAPPER_WIDTH,
          '& > div': {
            '& > div': {
              width: MUI_PICKER_STATIC_WRAPPER_WIDTH,
              '& > div': {
                width: MUI_PICKER_STATIC_WRAPPER_WIDTH
              }
            }
          }
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: MUI_PICKER_STATIC_WRAPPER_WIDTH,
          '& .PrivatePickersFadeTransitionGroup-root .MuiTypography-root': {
            width: 32
          },
          '& .PrivatePickersSlideTransition-root': {
            fontSize: '0.9rem',
            minHeight: 220,
            'div[role=cell]': {
              width: 36,
              height: 34
            }
          },
          '& .PrivatePickersSlideTransition-root button': {
            borderRadius: 4,
            fontSize: '0.9rem',
            width: 32,
            height: 32,
            '&:hover': {
              color: contrastText,
              backgroundColor: primaryColor
            },
            '&:focus': {
              backgroundColor: primaryColor
            }
          },
          '& .MuiYearPicker-root': {
            margin: '0px 15px'
          },
          '& .MuiYearPicker-root button': {
            borderRadius: 4,
            fontSize: '0.9rem',
            width: 58,
            '&:hover': {
              color: contrastText,
              backgroundColor: primaryColor
            },
            '&:focus': {
              backgroundColor: primaryColor
            },
            // '&:disabled': {
            //   display: 'none'
            // }
          }

        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderWidth: 2,
          minWidth: 0,
          padding: '6px 12px 6px 12px',
          '&:hover': {
            borderWidth: 2,
          },
          textTransform: 'none'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  }
});

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiInput:{
        styleOverrides: {
          root: {
            '&:before' :{
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
            }
          }
        }
      },
    },

    custom: {
      // backgroundDefaultduo: '#f4f6f8',
      backgroundDefaultduo: '#edf0f3',
      backgroundDark: '#f4f6f8',
      dominant: '#fff',
      tableHead: '#efefef'
    },

    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        // dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#ff6200',
      },
      secondary: {
        main: '#ff6200'
      },
      disabled: {
        main: '#b0b0b0'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.DARK,
    components: {
      MuiInput:{
        styleOverrides: {
          root: {
            '&:before' :{
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
            }
          }
        }
      },
    },

    custom: {
      backgroundDefaultduo: '#191919',
      backgroundDark: '#101010',
      dominant: '#000',
      tableHead: '#333'
    },

    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#222',//'#282C34',
        // dark: '#101010', //'#1c2025',
        paper: '#222', //'#282C34'
      },
      primary: {
        main: '#ff6200',
      },
      secondary: {
        main: '#ff6200'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      },
      disabled: {
        main: '#b0b0b0'
      }
    },
    shadows: strongShadows
  }
];

export function createTheme(settings: Settings, colors: GroupColors) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if(themeConfig && themeConfig.name.toLowerCase() === 'light'){
    themeConfig.palette.primary.main = colors.color1_light;
    themeConfig.palette.secondary.main = colors.color1_light;
  }

  if(themeConfig && themeConfig.name.toLowerCase() === 'dark'){
    themeConfig.palette.primary.main = colors.color1_dark;
    themeConfig.palette.secondary.main = colors.color1_dark;
  }
  
  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }


  const emptyMuiTheme = createMuiTheme();
  const contrastText = emptyMuiTheme.palette.getContrastText(themeConfig.palette.primary.main);

  const mergedThemeOptions: ThemeOptions = _.merge(
    {},
    getBaseConfig(themeConfig.palette.primary.main, contrastText),
    themeConfig
  );

  let theme = createMuiTheme(mergedThemeOptions);
  theme = responsiveFontSizes(theme);

  return theme;
}