import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Avatar, darken, alpha } from "@mui/material";
import clsx from 'clsx';
import { useState } from "react";
import { makeStyles } from "src/theme/makeStyles";
import getInitials from "src/utils/general/getInitials";
import ImagePicker from "../ImagePicker";

const AVATAR_HEIGHT = 50;

const useStyles = makeStyles<{ 
  avatarHovering: boolean; 
}>()((theme, { 
  avatarHovering,
}) => ({
  avatar: {
    height: AVATAR_HEIGHT,
    width: AVATAR_HEIGHT,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatarNoName: {
    backgroundColor: darken(theme.palette.background.default, 0.05),
    border: `1px dashed ${theme.palette.text.primary}`
  },
  fixedIconWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: AVATAR_HEIGHT,
    width: AVATAR_HEIGHT,
    zIndex: 2,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.25s, opacity 0.25s',
    color: theme.palette.text.primary,
    '&:hover': {
      opacity: 0.75,
      backgroundColor: theme.palette.background.default
    }
  },
  hoverIconWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: AVATAR_HEIGHT,
    width: AVATAR_HEIGHT,
    zIndex: 2,
    opacity: avatarHovering ? 1 : 0,
    transition: 'opacity 0.25s',
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  rootDisabled: {
    cursor: 'auto',
    pointerEvents: 'none' 
  }
}));

interface Props {
  name: string;
  avatarDataUrl?: string;
  onAvatarChange?: (dataUrl: string | null) => void;
  disabled?: boolean;
}

const DialogTitleAvatar = ({
  name,
  avatarDataUrl,
  onAvatarChange,
  disabled = false
}: Props) => {
  const [avatarHovering, setAvatarHovering] = useState(false);
  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);
  const [avatarPickerInitial, setAvatarPickerInitial] = useState<string | null>(null);

  const { classes, theme } = useStyles({ avatarHovering });

  const handleAvatarChange = (dataUrl: string | null) => {
    onAvatarChange && onAvatarChange(dataUrl);
    setAvatarPickerOpen(false);
    setAvatarPickerInitial(null);
  }

  const handleExistingAvatarChange = () => {
    if (avatarDataUrl) {
      setAvatarPickerInitial(avatarDataUrl);
      setAvatarPickerOpen(true);
    }
  }

  const handleImagePickerClose = () => {
    setAvatarPickerInitial(null);
    setAvatarPickerOpen(false);
  }

  const emptyAvatar = (!name.length && !avatarDataUrl) || (!avatarDataUrl && avatarHovering);

  return (
    <>
      <div
        className={disabled ? classes.rootDisabled : undefined}
        style={{
          position: 'relative',
          borderRadius: '50%',
          height: AVATAR_HEIGHT,
          width: AVATAR_HEIGHT
        }}
        onMouseEnter={() => setAvatarHovering(true)}
        onMouseLeave={() => setAvatarHovering(false)}
      >
        <Avatar
          className={clsx(
            classes.avatar,
            emptyAvatar && classes.avatarNoName
          )}
          src={avatarDataUrl ? `${avatarDataUrl}` : avatarDataUrl}
        >
          {!avatarHovering && getInitials(name)}
        </Avatar>

        

        {!!avatarDataUrl ? (
          <div
            className={classes.hoverIconWrapper}
            onClick={handleExistingAvatarChange}
          >
            {/* <ModeEditOutlinedIcon style={{ color: '#fff' }} /> */}
            <ModeEditOutlinedIcon style={{ color: '#fff' }} />
          </div>
        ) : (
          <div
            className={name.length ? classes.hoverIconWrapper : classes.fixedIconWrapper}
            onClick={() => setAvatarPickerOpen(true)}
          >
            <AddIcon
              style={{ 
                color: theme.palette.text.primary
              }}
            />
          </div>
        )}
      </div>
      <ImagePicker
        open={avatarPickerOpen}
        onChange={handleAvatarChange}
        onClose={handleImagePickerClose}
        initialImageSrc={avatarPickerInitial ? avatarPickerInitial : undefined}
      />
    </>
  )
}
export default DialogTitleAvatar;