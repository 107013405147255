import { useEffect } from "react";
import { 
  derenderForm, 
  FormsStateKey, 
  openForm, 
  closeForm, 
  renderForm
} from "src/slices/forms";
import { useDispatch } from "src/store";

const useForm = (
  form: FormsStateKey, 
  disabled: boolean = false
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if(!disabled){
      dispatch(renderForm(form));
    }
    return () => {
      if(!disabled){
        dispatch(derenderForm(form));
      }
    }
  }, []);

  const open = (
    updateId: string | null = null, 
    initialValues: any = {}, 
    callback: ((values: any) => void) | null = null
  ) => {
    if(disabled){
      return;
    }

    dispatch(openForm(form, updateId, initialValues, callback));
  }
  const close = () => {
    if(disabled){
      return;
    }

    dispatch(closeForm(form));
  }

  return {
    open,
    close
  }
}
export default useForm;