import { Box, Button, Dialog, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import FileSelectDialog from "src/components/general/FileSelectDialog";
import { useAxiosOptions } from "src/hooks/general/useAxios";
import createFileRequest from "src/requests/files";
import { createDownload, createUpload } from "src/slices/dataTransfer";
import { useDispatch, useSelector } from "src/store";
import { v4 as uuidv4 } from 'uuid';
import { File as TFile } from 'src/types/api/file';
import TaskFileRow from "./TaskFileRow";
import UploadFileRow from "./UploadFileRow";
import { useTranslation } from 'src/i18n';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface Props {
  task_id?: string;
  file_ids: string[];
  setFileIds: (file_ids: string[]) => void;
  disabled?: boolean;
}

const TaskFiles = ({
  task_id,
  file_ids,
  setFileIds,
  disabled = false
}: Props) => {
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();
  const {t} = useTranslation();
  
  const [dialogOpen, setDialogOpen] = useState(false);

  const uploads = useSelector(state => state.dataTransfer.uploads)
  const getUpload = (file_id: string) => {
    const foundUpload = uploads.find(upload => upload.stateFile.id === file_id);
    return foundUpload ? foundUpload : null;
  }

  const files = useSelector(state => state.lists.files);
  const filteredFiles = useMemo(() => 
    files.filter(file => file_ids.includes(file.id))
  , [files, file_ids]);

  const handleRemoveFile = (file_id: string) => {
    setFileIds(file_ids.filter(l_file_id => l_file_id !== file_id))
  }

  const handleSubmit = (files: File[]) => {
    const promises = files.map((file) => {
      const createdFile = createFileRequest({
        folder_id: null,
        members: [],
        object_id: task_id ? task_id : null,
        type: 'task',
        private: false,
        readonly: false
      }, dispatch, axiosOptions.apiConfig);
      return createdFile;
    });

    Promise.allSettled(promises).then(promiseResults => {
      let addedFileIds: string[] = [];
      promiseResults.map((promiseResult, index) => {
        if(promiseResult.status === 'fulfilled' && promiseResult.value){
          const file = files[index];
          if(file){
            dispatch(createUpload({
              id: promiseResult.value.id,
              file: file,
              finished: false,
              progress: 0,
              stateFile: promiseResult.value, // IS COPY OF STATE FILE!!!
              uploading: false
            }));
            addedFileIds.push(promiseResult.value.id);
          }
        }
      });
      setFileIds([...file_ids, ...addedFileIds]);
      setDialogOpen(false);
    });

  }
  
  const handleDownloadFile = (file: TFile) => {
    if(file.filename && file.size !== null){
      dispatch(createDownload({
        id: file.id,
        downloading: false,
        name: file.filename,
        size: file.size,
        progress: 0,
        finished: false
      }))
    }
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {/*  */}
        {/* <InsertDriveFileOutlinedIcon /> */}
        <AttachFileIcon />
        <Typography
          variant='h6'
          color='textPrimary'
          style={{ marginLeft: 10, lineHeight: 1.7 }}
        >
          {t('lang.files')} {!!filteredFiles.length && `(${filteredFiles.length})`}
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            onClick={() => setDialogOpen(true)}
            size='small'
            color='primary'
            style={{ cursor: 'pointer', width: 'fit-content' }}
            disabled={disabled}
          >
            {`+ ${t('lang.add_file')}`}
          </Button>
        </div>
      </div>
      <div>
        {filteredFiles.map((file, index) => {
          const upload = getUpload(file.id);
          if(upload){
            return (
              <Box mt={1}>
                <UploadFileRow 
                  key={index} 
                  upload={upload} 
                  onDelete={() => handleRemoveFile(file.id)}
                  disabled={disabled}
                />
              </Box>
            )
          }
          return (
            <Box mt={1}>
              <TaskFileRow 
                key={index} 
                file={file} 
                onDelete={() => handleRemoveFile(file.id)}
                onDownload={() => handleDownloadFile(file)}
                disabled={disabled}
              />
            </Box>
          )
        })}
      </div>
      <FileSelectDialog 
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
}
export default TaskFiles;