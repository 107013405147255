import { Box, IconButton } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'src/i18n';
import Avatar from 'src/components/general/Avatar';
import NormalizationTextField from 'src/components/general/NormalizationTextField';
import { useSelector } from 'src/store';
import type { User } from 'src/types/api/user';

const useStyles = makeStyles()((theme) => ({
  textfield: {
    paddingLeft: 10
  }
}));

interface Props {
  onCreate: (value: string) => void;
  generalUser: User;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const CreateComment = ({
  onCreate,
  generalUser,
  style = {},
  disabled = false
}: Props) => {
  const { classes } = useStyles();

  const {t} = useTranslation();
  const [message, setMessage] = useState('');
  
  const handleCreate = () => {
    if(message){
      onCreate(message);
      setMessage('');
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.code === 'Enter'){
      handleCreate();
    }
  };

  if(!generalUser){ return null; }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex'
      }}
      style={style}
    >
      <Avatar 
        name={generalUser.contact.name}
        user_id={generalUser.id}
      />
      <NormalizationTextField
        className={classes.textfield}
        normalization='first_to_upper'
        fullWidth
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder={`${t('lang.write_a_comment')}`}
        value={message}
        variant='standard'
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <IconButton 
              size='small'
              onClick={handleCreate}
              disabled={disabled}
            >
              <SendIcon />
            </IconButton>
          )
        }}
      />
    </Box>
  );
};

export default CreateComment;