import { yupResolver } from '@hookform/resolvers/yup';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import ToggleButton from "src/components/forms/buttons/ToggleButton";
import ToggleButtonGroup from "src/components/forms/buttons/ToggleButtonGroup";
import EmailField from "src/components/forms/fields/Email";
import FirstUpperField from "src/components/forms/fields/FirstUpper";
import LastNameField from 'src/components/forms/fields/LastName';
import PhoneField from "src/components/forms/fields/Phone";
import validations from "src/components/forms/validations";
import DialogTitleAvatar from 'src/components/general/DialogTitleAvatar';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import Tags from "src/components/general/Tags";
import { useAxiosOptions } from 'src/hooks/general/useAxios';
import useClientNumber from "src/hooks/general/useClientNumber";
import { useTranslation } from 'src/i18n';
import { CreateContactRequestData, createContactRequest } from 'src/requests/contacts';
import { closeForm } from 'src/slices/forms';
import { useDispatch, useSelector } from 'src/store';
import { makeStyles } from "src/theme/makeStyles";
import * as yup from 'yup';
import { DEFAULT_FORM_VALUES } from './constants';
import { FormValues } from './types';

const yupSchema = yup.object().shape({
  type: yup.string(),
  email: validations.email.notRequired(),
  companyName: yup.string().when('type', {
    is: 'company',
    then: validations.default_string
  }),
  firstName: yup.string().when('type', {
    is: 'person',
    then: validations.default_string
  }),
  lastName: yup.string().when('type', {
    is: 'person',
    then: validations.default_string
  }),
  phone: validations.phone_number.notRequired()
});

const useStyles = makeStyles()((theme) => ({
  buttonLabel: {
    fontSize: '0.8rem',
    // @ts-ignore
    color: theme.name === 'DARK' ? '#adb0bb' : '#546e7a',
    marginBottom: 5
  }
}));

const AddSimpleContactDialog = () => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();

  const {
    open: formOpen,
    callback
  } = useSelector(state => state.forms.contactSimple);

  const handleClose = () => {
    dispatch(closeForm('contactSimple'));
  }

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors
  } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(yupSchema)
  })
  const watchValues = watch();

  const [submitting, setSubmitting] = useState(false);

  const { refreshClientNumber } = useClientNumber();

  const handleValidSubmit = async (values: FormValues) => {
    let postData: CreateContactRequestData = {
      company_name: values.companyName,
      client_number: values.clientNumber,
      avatar: values.avatar,
      phone: values.phone.length ? [{
        phone: values.phone,
        label: 'general'
      }] : undefined,
      email: values.email.length ? [{
        email: values.email,
        label: 'general'
      }] : undefined,
      first_name: null,
      last_name: null,
      gender: null,
      birthday: null,
      bic: null,
      coc: null,
      vat: null,
      sepa: false,
      iban: null,
      mandatedate: null,
      tag_ids: values.tagIds
    };

    if (values.type === 'person') {
      postData = {
        ...postData,
        company_name: null,
        first_name: values.firstName,
        last_name: values.lastName
      }
    }

    // post 
    setSubmitting(true);
    try {
      const createdContacts = await createContactRequest(postData, dispatch, axiosOptions.apiConfig);
      callback && callback(createdContacts);
      handleClose();
    }catch(e: any){
      console.error(e);
    }
    setSubmitting(false);
  }

  const getFormTitle = () => {
    if (watchValues.type === 'company' && watchValues.companyName) {
      return watchValues.companyName;
    }
    if (watchValues.type === 'person' && (watchValues.firstName || watchValues.lastName)) {
      return `${watchValues.firstName} ${watchValues.lastName}`;
    }
    // if (updateId) {
    //   return `${t('lang.update_contact')}`;
    // }
    return `${t('lang.new_contact')}`;
  }

  useEffect(() => {
    reset({
      ...DEFAULT_FORM_VALUES,
      clientNumber: `${refreshClientNumber()}`
    });

    clearErrors();
    setSubmitting(false);
  }, [formOpen]);

  return (
    <DuotoneDialog
      title={getFormTitle()}
      open={formOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(handleValidSubmit)}
      cancelButtonProps={{
        disabled: submitting
      }}
      submitButtonProps={{
        disabled: submitting
      }}
      titleLeftComponent={(
        <div style={{ marginRight: theme.spacing(1.5) }}>
          <DialogTitleAvatar
            name={
              watchValues.type === 'company' ?
              watchValues.companyName :
                (watchValues.firstName || watchValues.lastName) ? `${watchValues.firstName} ${watchValues.lastName}` : ''
            }
            onAvatarChange={(val) => {
              setValue('avatar', val)
            }}
            avatarDataUrl={watchValues.avatar ? watchValues.avatar : undefined}
          />
        </div>
      )}
      subtitle={(
        <Typography variant='body2'>
          {t('lang.client_number')}: {watchValues.clientNumber}
        </Typography>
      )}
      disableMobileFullscreen
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Tags
            category={'contact'}
            tagIds={watchValues.tagIds}
            onChange={(values) => setValue('tagIds', values)}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography
              className={classes.buttonLabel}
              variant='body2'
            >
              {t('lang.contacttype')}
            </Typography>
            <ToggleButtonGroup
              value={watchValues.type}
              exclusive
              onChange={(e, newValue) => setValue('type', newValue)}
              size='small'
              fullWidth
            >
              <ToggleButton value="person">
                <PersonOutlineIcon fontSize="small" style={{ marginRight: 4 }} />
                {t('lang.person')}
              </ToggleButton>
              <ToggleButton value="company">
                <HomeWorkOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
                {t('lang.company')}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        {watchValues.type === 'person' ? (
          <>
            <Grid item xs={6}>
              <Controller
                name='firstName'
                control={control}
                render={({ field }) => (
                  <FirstUpperField
                    label={`${t("lang.first_name")}*`}
                    fullWidth
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message ? t(errors.firstName.message) : undefined}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
            <Controller
              name='lastName'
              control={control}
              render={({ field }) => (
                <LastNameField
                  label={`${t("lang.last_name")}*`}
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message ? t(errors.lastName.message) : undefined}
                  {...field}
                />
              )}
            />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Controller
              name='companyName'
              control={control}
              render={({ field }) => (
                <FirstUpperField
                  label={`${t("lang.company_name")}*`}
                  fullWidth
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message ? t(errors.companyName.message) : undefined}
                  {...field}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <EmailField
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message ? t(errors.email.message) : undefined}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name='phone'
            control={control}
            render={({ field }) => (
              <PhoneField
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message ? t(errors.phone.message) : undefined}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <Alert severity='info'>
              {t('description.small_contact_dialog')}
            </Alert>
          </Box>
        </Grid>
      </Grid>
    </DuotoneDialog>
  );
}
export default AddSimpleContactDialog;