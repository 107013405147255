import { Alert, Box, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DuotoneDialog from "src/components/general/DuotoneDialog";
import { useAxiosOptions } from "src/hooks/general/useAxios";
import { useTranslation } from "src/i18n";
import { sendAppointmentConfirmationEmailRequest, sendAppointmentMovedEmailRequest } from "src/requests/appointments";
import { useSelector } from "src/store";
import { Appointment } from "src/types/api/appointment";

interface Props {
  appointment: Appointment | null;
  onClose: () => void;
  isAppointmentMoved?: boolean;
}

const SendAppointmentConfirmationEmailDialog = ({
  appointment,
  onClose,
  isAppointmentMoved = false
}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const axiosOptions = useAxiosOptions();

  const contacts = useSelector(state => state.lists.contacts);

  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const contact = useMemo(() => {
    const found = contacts.find(contact => appointment && appointment.contact_id === contact.id);
    return found ? found : null;
  }, [appointment]);

  useEffect(() => {
    if (appointment) {
      if(contact?.email.length){
        setEmail(contact.email[0].email);
      }else{
        setEmail('');
      }
      setEmailError(false);
    }
  }, [appointment]);

  const handleSubmit = async () => {
    if(!appointment){
      return;
    }

    if(!email.length){
      setEmailError(true);
      return;
    }else{
      setEmailError(false);
    }

    try {
      setSubmitting(true);
      if(isAppointmentMoved){
        await sendAppointmentMovedEmailRequest(appointment.id, email, axiosOptions.apiConfig);
      }else{
        await sendAppointmentConfirmationEmailRequest(appointment.id, email, axiosOptions.apiConfig);
      }
      setSubmitting(false);
      onClose();
    }catch(e){
      setSubmitting(false);
      console.error(e);
    }
  }

  return (
    <DuotoneDialog
      open={!!appointment}
      title={isAppointmentMoved ? t('lang.send_appointment_moved') : t('lang.send_appointment_confirmation')}
      onSubmit={handleSubmit}
      onClose={onClose}
      cancelButtonProps={{
        disabled: submitting
        // label: niet versturen
      }}
      submitButtonProps={{
        disabled: submitting,
        // label: versturen
      }}
      submitButtonTitle={`${t('lang.send')}`}
      cancelButtonTitle={`${t('lang.dont_send')}`}
    >
      <Box>
        {contact && !contact.email.length && (
          <Alert severity="warning" style={{border: `1px solid ${theme.palette.warning.light}`}}>
            {t('message.contact_has_no_email')}
          </Alert>
        )}
        <Box mt={3}>
          {isAppointmentMoved ? (
            <Typography>{t('description.send_appointment_moved', { name: contact ? contact.name : '' })}</Typography>
          ) : (
            <Typography>{t('description.send_appointment_confirmation', { name: contact ? contact.name : '' })}</Typography>
          )}
        </Box>
        <Box mt={3}>
          <TextField 
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={`${t('lang.email')}`}
            error={emailError}
          />
        </Box>
      </Box>
    </DuotoneDialog>
  )
}
export default SendAppointmentConfirmationEmailDialog;