import axios, { AxiosRequestConfig } from "axios";
import { createTask, updateTask, deleteTask } from "src/slices/lists/projects";
import { AppDispatch } from "src/store";
import { Task } from "src/types/api/project";

export interface CreateTaskRequestData {
  project_step: string | null;
  title: string;
  description: string;
  sub_tasks: {
    id: string | null; // TODO: CHECKEN !!!
    title: string;
    finished: boolean;
  }[];
  all_users: boolean;
  members: string[];
  start_date: number | null;
  end_date: number | null;
  all_day: boolean;
  priority: number;
  contact_id: string | null;
  tag_ids: string[];
  file_ids: string[];
}

export const createTaskRequest = (
  data: CreateTaskRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Task> => {
  return new Promise<Task>((resolve, reject) => {
    axios.post<Task>(`tasks`, data, config)
    .then((response) => {
      dispatch(createTask(response.data))
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export interface UpdateTaskRequestData extends CreateTaskRequestData {
  comment_ids: string[];
  archive: boolean;
}
export const updateTaskRequest = (
  id: string,
  data: UpdateTaskRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Task> => {
  return new Promise<Task>((resolve, reject) => {
    axios.put<Task>(`tasks/${id}`, data, config)
    .then((response) => {
      dispatch(updateTask(response.data))
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const deleteTaskRequest = (
  id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Task> => {
  return new Promise<Task>((resolve, reject) => {
    axios.delete<Task>(`tasks/${id}`, config)
    .then((response) => {
      dispatch(deleteTask(response.data))
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}