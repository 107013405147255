import { AnyAction, Store } from 'redux';
import {
  createActivity,
  updateActivity,
  deleteActivity
} from 'src/slices/activity';
import {
  createGlobalActivity,
  updateGlobalActivity,
  deleteGlobalActivity
} from 'src/slices/lists/globalActivity';
import type { RootState } from 'src/store';
import type { AppDispatch } from 'src/store/index';
import type { Activity } from 'src/types/api/activity';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>
) => {
  // Projects
  channel.listen('.create_activity', (data: any) => {
    const activity = data.data.response as Activity;
    if(activity.object_id && store.getState().activity[activity.object_id]){
      dispatch(createActivity({
        object_id: activity.object_id,
        activity: activity
      }));
    }
  });
  channel.listen('.update_activity', (data: any) => {
    const activity = data.data.response as Activity;
    if(activity.object_id && store.getState().activity[activity.object_id]){
      dispatch(updateActivity({
        object_id: activity.object_id,
        activity: activity
      }));
    }
  });
  channel.listen('.delete_activity', (data: any) => {
    const activity = data.data.response as Activity;
    if(activity.object_id && store.getState().activity[activity.object_id]){
      dispatch(deleteActivity({ 
        object_id: activity.object_id,
        activity: activity
      }));
    }
  });

  // global activity
  channel.listen('.create_global_activity', (data: any) => {
    const activity = data.data.response as Activity;
    if(store.getState().lists.global_activity.length){
      dispatch(createGlobalActivity(activity));
    }
  });
  channel.listen('.update_global_activity', (data: any) => {
    const activity = data.data.response as Activity;
    dispatch(updateGlobalActivity(activity));
  });
  channel.listen('.delete_global_activity', (data: any) => {
    const activity = data.data.response as Activity;
    dispatch(deleteGlobalActivity(activity));
  });

}