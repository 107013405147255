import { FormValues } from "./types";

export const DEFAULT_VALUES: FormValues = {
  type: 'person',
  firstName: '',
  lastName: '',
  companyName: '',
  birthday: null,
  emails: [{
    email: '',
    label: 'general'
  }],
  phones: [{
    phone: '',
    label: 'general'
  }],
  gender: '',
  addresses: [],
  clientNumber: '',
  tagIds: [],
  relationIds: [],

  coc: '',
  vat: '',
  iban: '',
  sepa: false,
  bic: '',
  accountname: '',
  mandateId: '',
  mandateDate: null,
  avatar: null,
  avatarChanged: false
}