import { Close } from "@mui/icons-material";
import { ClickAwayListener, Dialog, DialogContent, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from "react";
// import useArrowSelect from "src/hooks/general/useArrowSelect";
import useIsMobile from "src/hooks/general/useIsMobile";
import { useTranslation } from 'src/i18n';
import { useSelector } from "src/store";
import { makeStyles } from "src/theme/makeStyles";
import { Service } from "src/types/api/service";
import ServiceRow from "./ServiceRow";
import ServiceCategoryRow from "./ServiceCategoryRow";

const useStyles = makeStyles()(theme => ({
  dialogPaperRoot: {
    minHeight: 467
  },
  dialogPaperRootMobile: {
    width: 'calc(100% - 24px)',
    margin: 12 
  }
}))

interface Props {
  open: boolean;
  onSelect: (service: Service) => void;
  onClose: () => void;
  selectedServiceIds: string[];
}

const SelectServiceDialog = ({
  open,
  onSelect,
  onClose,
  selectedServiceIds
}: Props) => {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();
  const isMobile = useIsMobile();

  const [searchQuery, setSearchQuery] = useState('');
  
  const serviceCategories = useSelector(state => state.lists.services.categories);

  const stateServices = useSelector(state => state.lists.services.services);
  const stateParentServices = useMemo(() => stateServices.filter(service => !service.is_child), [stateServices]);

  // filters for search query and if already selected
  const filteredServices = useMemo(() => {
    return stateParentServices.filter(service =>
      service.title.toLowerCase().includes(searchQuery.toLowerCase()) && 
      !selectedServiceIds.includes(service.id)
    );
  }, [stateParentServices, searchQuery, selectedServiceIds]);

  // const {
  //   containerRef,
  //   onKeyDown,
  //   selectedIndex,
  //   reset: resetArrowSelectState
  // } = useArrowSelect({
  //   rows: filteredServices,
  //   onSelect: (index) => {
  //     const service = filteredServices.find((x, i) => i === index);
  //     service && handleClick(service);
  //   }
  // });

  const handleClick = (service: Service) => {
    onSelect(service);
    onClose();
  }

  useEffect(() => {
    setSearchQuery('');
    // resetArrowSelectState();
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={'xs'}
      fullWidth
      onClose={onClose}
      classes={{
        paper: clsx(
          classes.dialogPaperRoot,
          isMobile && classes.dialogPaperRootMobile
        )
      }}
      // onKeyDown={onKeyDown}
    >
      <div style={{ padding: '12px 16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h4'>
            {t('lang.select_service')}
          </Typography>
          <IconButton
            onClick={onClose}
            size='small'
            style={{ marginLeft: 'auto' }}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label={`${t('lang.search')}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant='standard'
              fullWidth
              autoFocus
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            {filteredServices.length ? (
              <ClickAwayListener
                onClickAway={() => {}}
                // onClickAway={() => resetArrowSelectState()}
              >
                <div
                  // ref={containerRef}
                  style={{
                    overflow: 'auto',
                    maxHeight: 250,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 4
                  }}
                >
                  {serviceCategories.map((serviceCategory, categoryIndex) => {
                    const services = filteredServices.filter(s => s.category_id === serviceCategory.id);
                    if(!services.length)
                      return null;
                    return (
                      <React.Fragment key={categoryIndex}>
                        <ServiceCategoryRow 
                          category={serviceCategory}
                          disableBorderTop={categoryIndex === 0}
                        />
                        {services.map((service, index) => (
                          <ServiceRow 
                            key={index}
                            service={service}
                            onClick={() => handleClick(service)}
                            disableBorderTop={index === 0}
                            // selected={selectedIndex === index}
                          />
                        ))}
                      </React.Fragment>
                    )
                  })}
                </div>
              </ClickAwayListener>
            ) : (
              <Typography variant='h4'>
                {t('lang.no_results')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default SelectServiceDialog;