import { AnyAction, Store } from 'redux';
import { AxiosOptions } from 'src/hooks/general/useAxios';
import { setCurrentTemplate } from 'src/slices/current';
import { createTemplate, deleteTemplate, updateTemplate } from 'src/slices/lists/templates';
import type { RootState } from 'src/store';
import type { AppDispatch } from 'src/store/index';
import type { Template } from 'src/types/api/template';
import { EchoChannel as Channel } from '../index';
import { templateToSmall } from 'src/custom/resourceConverter';


export default (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>
) => {
  console.log('CHECK WHAT API GIVES BACK HERE ( SMALL OR FULL ) !!!');

  channel.listen('.create_template', (data: any) => {
    const template = data.data.response as Template;
    dispatch(createTemplate(templateToSmall(template)));
  });

  channel.listen('.update_template', (data: any) => {
    console.log('FIX THIS');
    // const template = data.data.response as TemplateSmall;
    const template = data.data.response;

    dispatch(updateTemplate(templateToSmall(template)));

    if (store.getState().current.template?.id === data.data.response.id) {
      // setCurrentAlert({
      //   open: true,
      //   message: t("messages.current_template_updated"),
      //   user: data.data.user
      // });
      // probably fetch here
      dispatch(setCurrentTemplate(template));
    }
  });
  channel.listen('.delete_template', (data: any) => {
    const template = data.data.response as Template;

    dispatch(deleteTemplate(templateToSmall(template)));
    if (store.getState().current.template?.id == data.data.response.id) {
      dispatch(setCurrentTemplate(null));
    }
  });

  // .bind('update_template_permissions', (data) => {
  //   if(store.getState().current.template && store.getState().current.template.id == data.data.response.id){
  //     setCurrentAlert({
  //       open: true,
  //       message: t("messages.current_template_updated"),
  //       user: data.data.user
  //     });
  //     dispatch(setCurrentTemplatePermissions(data.data.response));
  //   }
  // })
  // deprecated ???
  // channel.listen('.update_template_permissions', (data: any) => {
  //   if(store.getState().current.template?.id == data.data.response.id){
  //     // setCurrentAlert({
  //     //   open: true,
  //     //   message: t("messages.current_template_updated"),
  //     //   user: data.data.user
  //     // });
  //     dispatch(setCurrentTemplatePermissions(data.data.response));
  //   }
  // });

}