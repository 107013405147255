import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { SystemSetting, SystemSettingCategory } from 'src/types/api/setting';

interface State {
  settings: SystemSetting[];
  categories: SystemSettingCategory[];
}

const initialState: State = {
  settings: [],
  categories: []
};

const slice = createSlice({
  name: 'systemSettings',
  initialState,
  reducers: {
    setSystemSettings(state: State, action: PayloadAction<SystemSetting[]>){
      state.settings = action.payload;
    },
    createSystemSetting(state: State, action: PayloadAction<SystemSetting>){
      state.settings.push(action.payload);
    },
    updateSystemSetting(state: State, action: PayloadAction<SystemSetting>){
      return {
        ...state,
        settings: state.settings.map((val) => val.id !== action.payload.id ? val : action.payload)
      };
    },
    deleteSystemSetting(state: State, action: PayloadAction<SystemSetting>){
      return {
        ...state,
        settings: state.settings.filter((val) => val.id !== action.payload.id)
      }
    },

    setSystemSettingCategories(state: State, action: PayloadAction<SystemSettingCategory[]>){
      state.categories = action.payload;
    },
    createSystemSettingCategory(state: State, action: PayloadAction<SystemSettingCategory>){
      state.categories.push(action.payload);
    },
    updateSystemSettingCategory(state: State, action: PayloadAction<SystemSettingCategory>){
      return {
        ...state,
        categories: state.categories.map((val) => val.id !== action.payload.id ? val : action.payload)
      };
    },
    deleteSystemSettingCategory(state: State, action: PayloadAction<SystemSettingCategory>){
      return {
        ...state,
        categories: state.categories.filter((val) => val.id !== action.payload.id)
      }
    }
    

    // categories stuff todo
  }
})

export const { reducer } = slice;

export const setSystemSettings = (data: SystemSetting[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSystemSettings(data));
}
export const createSystemSetting = (data: SystemSetting): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createSystemSetting(data));
}
export const updateSystemSetting = (data: SystemSetting): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSystemSetting(data));
}
export const deleteSystemSetting = (data: SystemSetting): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteSystemSetting(data));
}

export const setSystemSettingCategories = (data: SystemSettingCategory[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSystemSettingCategories(data));
}
export const createSystemSettingCategory = (data: SystemSettingCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createSystemSettingCategory(data));
}
export const updateSystemSettingCategory = (data: SystemSettingCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSystemSettingCategory(data));
}
export const deleteSystemSettingCategory = (data: SystemSettingCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteSystemSettingCategory(data));
}

// categories stuff todo

export default slice;