import {
  Hidden
} from '@mui/material';
import React from 'react';
import DesktopDrawer from './DesktopDrawer';
import MobileDrawer from './MobileDrawer';
import type { UserSmall } from 'src/types/api/user';

interface Props {
  presenceOpen: boolean;
  setPresenceOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: UserSmall | {};
  setSelectedUser: React.Dispatch<React.SetStateAction<UserSmall | null>>;
  menuInactive: boolean;
}

const PresenceBar = ({ 
  presenceOpen, 
  setPresenceOpen, 
  selectedUser, 
  setSelectedUser,
  menuInactive
}: Props) => {
  return (
    <>
      <Hidden mdDown>
        <DesktopDrawer
          presenceOpen={presenceOpen}
          setPresenceOpen={setPresenceOpen}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          menuInactive={menuInactive}
        />
      </Hidden>
      <Hidden mdUp>
        <MobileDrawer
          presenceOpen={presenceOpen}
          setPresenceOpen={setPresenceOpen}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          // menuInactive={menuInactive}
        />
      </Hidden>
    </>
  )
}

export default PresenceBar;