import { forwardRef } from 'react';
import { useTheme } from '@mui/material';
import useIsMobile from 'src/hooks/general/useIsMobile';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  innerStyle?: React.CSSProperties;
  [x: string]: any;
}

const ContentContainer = forwardRef<HTMLDivElement, Props>(({ 
  children,
  innerStyle,
  ...rest
}, ref) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const spacing = isMobile ? theme.spacing(1.5) : theme.spacing(3);

  return (
    <div
      ref={ref}
      {...rest}
      style={{ 
        height: '100%', 
        overflow: 'auto', 
        ...rest.style 
      }}
    >
      <div 
        style={{ 
          height: '100%', 
          paddingTop: spacing, 
          paddingLeft: spacing,
          paddingRight: spacing,
          paddingBottom: spacing, 
          ...innerStyle
        }}
      >
        {children}        
      </div>
    </div>
  )
});

export default ContentContainer;