import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, useDispatch } from 'src/store';
import type { UserSmall as TUser } from 'src/types/api/user';

const initialState: TUser[] = [];

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state: TUser[], action: PayloadAction<TUser[]>){
      return action.payload;
    },
    createUser(state: TUser[], action: PayloadAction<TUser>){
      state.push(action.payload);
    },
    updateUser(state: TUser[], action: PayloadAction<TUser>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteUser(state: TUser[], action: PayloadAction<TUser>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setUsers = (data: TUser[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setUsers(data));
}

export const createUser = (data: TUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createUser(data));
}

export const updateUser = (data: TUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateUser(data));
}

export const deleteUser = (data: TUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteUser(data));
}

export default slice;