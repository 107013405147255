import { Menu, MenuItem, Typography, Button } from '@mui/material';
import { useSelector } from 'src/store';
import { makeStyles } from 'src/theme/makeStyles'
import Avatar from 'src/components/general/Avatar';
import { useUsersNoCustomer } from 'src/store/customSelectors';
import { useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import SuperScrollbar from 'src/components/general/SuperScrollbar';
import { useTranslation } from 'src/i18n';

const MENU_ITEM_HEIGHT = 43;

const useStyles = makeStyles()((theme) => ({
  menuListRoot: {
    paddingTop: 0,
    paddingBottom: 0
  },
  menuItemRoot: {
    paddingLeft: 8,
    height: MENU_ITEM_HEIGHT
  },
  checkbox: {
    marginRight: 5
  },
  checkboxRoot: {
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },
  userName: {
    marginLeft: 10
  }
}));

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  selected: string[];
  setSelected: (value: string[]) => void;
  singleUser: boolean;
}

const MoreDropdown = ({
  anchorEl,
  handleClose,
  selected,
  setSelected,
  singleUser
}: Props) => {
  const { classes, theme } = useStyles();
  const {t} = useTranslation();

  const generalUser = useSelector(state => state.general.user);
  const users = useUsersNoCustomer();

  const sortedUsers = useMemo(() => {
    if(!generalUser){
      return [];
    }

    return [...users].sort((a,b) => {
      if(a.id === generalUser.id){ return -1; }
      if(b.id === generalUser.id){ return 1; }

      if(a.contact.name.toLowerCase() > b.contact.name.toLowerCase()){ return 1; }
      if(a.contact.name.toLowerCase() < b.contact.name.toLowerCase()){ return -1; }
      return 0;
    });

  }, [users, generalUser])

  const allSelected = selected.length === users.length;

  const handleClick = (e: React.MouseEvent<HTMLLIElement>, user_id: string) => {
    e.stopPropagation();

    if(singleUser){
      const userAlreadySelected = selected.find((l_user_id) => l_user_id === user_id);
      setSelected(userAlreadySelected ? [] : [user_id]);
      handleClose();
      return;
    }

    if(selected.includes(user_id)){
      setSelected(selected.filter((x) => x !== user_id));
      return;
    }
    setSelected([...selected, user_id]);
  }

  const handleLeftButtonClick = () => {
    if(allSelected){
      setSelected([]);
      return;
    }
    setSelected(sortedUsers.map(u => u.id));
  }


  return (
    <Menu
      classes={{ list: classes.menuListRoot }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 0.5,
        },
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <SuperScrollbar
        autoHeight
        autoHeightMax={MENU_ITEM_HEIGHT * 5}
      >
        {sortedUsers.map((user) => {
          const checked = selected.includes(user.id);
          return (
            <MenuItem 
              key={user.id}
              classes={{ root: classes.menuItemRoot }} 
              onClick={(e) => handleClick(e, user.id)}
            >
              <Avatar 
                name={user.contact.name}
                user_id={user.id}
                size='small'
              />
              <Typography
                className={classes.userName}
                variant='body2'
              >
                {user.contact.name}
              </Typography>

              <div style={{ marginLeft: 'auto', visibility: checked ? 'visible' : 'hidden' }}>
                <CheckIcon style={{ marginLeft: 12 }} />
              </div>
            </MenuItem>
          )
        })}
      </SuperScrollbar>

      {!singleUser && (
        <div 
          style={{ 
            display: 'flex', 
            padding: theme.spacing(1) 
          }}
        >
          <Button 
            size='small' 
            onClick={handleLeftButtonClick}
            style={{ marginRight: theme.spacing(1.5) }}
          >
            {allSelected ? `${t('lang.deselect_all')}` : `${t('lang.select_all')}`}
          </Button>
          <Button 
            variant='contained'
            size='small'
            style={{ marginLeft: 'auto' }}
          >
            Kiezen
          </Button>
        </div>
      )}
    </Menu>
  );
}
export default MoreDropdown;