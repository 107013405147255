import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Task } from 'src/types/api/project';

interface Archives {
  // tasks: Task[] | null;
  tasks: {
    [project_id: string]: Task[] | null;
  }
}

const initialState: Archives = {
  tasks: {}
}

const slice = createSlice({
  name: 'archives',
  initialState: initialState,
  reducers: {
    setArchivedTasks(state: Archives, action: PayloadAction<{ project_id: string; data: Task[] | null; }>){
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.project_id]: action.payload.data
        }
      };
    },
    createArchivedTask(state: Archives, action: PayloadAction<{ project_id: string; data: Task; }>){
      if(typeof state.tasks[action.payload.project_id] === 'undefined')
        state.tasks[action.payload.project_id] = null;
      if(state.tasks[action.payload.project_id] === null)
        return state;
      state.tasks[action.payload.project_id]?.unshift(action.payload.data)
    },
    deleteArchivedTask(state: Archives, action: PayloadAction<{ project_id: string; data: Task; }>){
      if(typeof state.tasks[action.payload.project_id] === 'undefined')
        state.tasks[action.payload.project_id] = null;
      const value = state.tasks[action.payload.project_id];
      if(value === null)
        return state;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          // state.tasks[action.payload.project_id]?.filter((t) => t.id !== action.payload.data.id)
          [action.payload.project_id]: value.filter((t) => t.id !== action.payload.data.id)
        }
      }

    }
  }
})

export const { reducer } = slice;

export const setArchivedTasks = (payload: { project_id: string; data: Task[] | null; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setArchivedTasks(payload));
}
export const createArchivedTask = (payload: { project_id: string; data: Task; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createArchivedTask(payload));
}
export const deleteArchivedTask = (payload: { project_id: string; data: Task; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteArchivedTask(payload));
}

export default slice;