import { Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'src/i18n';

interface Props {
  show: boolean;
  onChange: (show: boolean) => void;
  hideButton?: boolean;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

const Header = ({
  show,
  onChange,
  hideButton = false,
  isLoading = false,
  style = {}
}: Props) => {
  const {t} = useTranslation();

  const handleChange = () => {
    onChange(!show);
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <Typography
        color="textPrimary"
        variant="h6"
        style={{ lineHeight: 1 }}
      >
        {t('lang.activity')}
      </Typography>
      {isLoading &&
        <CircularProgress size={20} style={{ marginLeft: 15 }} />
      }

      {!hideButton &&
        <div style={{ marginLeft: 'auto' }}>
          <Button
            onClick={handleChange}
            size='small'
            color='primary'
          >
            {show ? t('lang.hide_details') : t('lang.show_details')}
          </Button>
        </div>
      }
    </div>
  )
}

export default Header;