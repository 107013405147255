import { FormValues } from "./types";

export const DEFAULT_FORM_VALUES: FormValues = {
  company_name: '',
  street: '',
  number: '',
  addition: '',
  zipcode: '',
  phone_number: '',
  city: '',
  coc_number: '',
  vat_number: ''
}