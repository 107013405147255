import { DefaultFilter } from "src/slices/filters";
import { PostCollationData } from "src/types/request/collation";

export const getSortDirection = (value: string) => 
  value.charAt(0) === '-' ? 'desc' : 'asc';
  
export const getSortWithoutDirection = (value: string) => 
  value.charAt(0) === '-' ? value.substring(1) : value;

export const createSortFilterUrlParams = (
  sort: string | null,
  filters: { [name: string]: string; },
  search?: string,
  start?: number,
  limit?: number
) => {
  const urlSearchParams = new URLSearchParams();
  if(sort)
    urlSearchParams.append('sort', sort);

  Object.entries(filters).forEach(([name, value]) => {
    urlSearchParams.append(`filter[${name}]`, value);
  });
  if(search)
    urlSearchParams.append('search', search);
  if(start)
    urlSearchParams.append('start', `${start}`);
  if(limit)
    urlSearchParams.append('limit', `${limit}`);

  return urlSearchParams;
}

export const convertDefaultFilterToPostCollationData = (filter: DefaultFilter): PostCollationData => {
  const result: PostCollationData = {};
  if(filter.search)
    result.search = filter.search;
  if(filter.sort)
    result.sort = filter.sort;
  if(Object.keys(filter.filters).length)
    result.filter = filter.filters;

  return result;
}