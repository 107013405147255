import {
  Alert, Snackbar
} from '@mui/material';
import { useEffect } from 'react';
import { setError } from 'src/slices/misc';
import { useDispatch, useSelector } from 'src/store';

// dispatch error on axios error for easy error

const ErrorHandler = () => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.misc.error);

  const handleClose = () => {
    dispatch(setError({
      ...error,
      open: false
    }))
  }

  useEffect(() => {
    if(error.open){
      setTimeout(() => handleClose(), 2500);
    }
  }, [error.open]);

  return (
    <Snackbar 
      open={error.open} 
      anchorOrigin={{ 
        vertical: 'top', 
        horizontal: 'center' 
      }}
    >
      <Alert 
        onClose={handleClose}
        severity='error'
      >
        {error.message}
      </Alert>
    </Snackbar>
  )
}

export default ErrorHandler;