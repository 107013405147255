import { createPermissionCategory, deletePermissionCategory, setPermissionCategories, updatePermissionCategory } from 'src/slices/lists/permissions';
import type { AppDispatch } from 'src/store/index';
import { PermissionCategory } from 'src/types/api/permission';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_permission_category', (data: any) => {
    const permissionCategory = data.data.response as PermissionCategory;
    dispatch(createPermissionCategory(permissionCategory));
  });
  channel.listen('.update_permission_category', (data: any) => {
    const permissionCategory = data.data.response as PermissionCategory;
    dispatch(updatePermissionCategory(permissionCategory));
  });
  channel.listen('.delete_permission_category', (data: any) => {
    const permissionCategory = data.data.response as PermissionCategory;
    dispatch(deletePermissionCategory(permissionCategory));
  });
  channel.listen('.sort_permission_categories', (data: any) => {
    const permissionCategories = data.data.response as PermissionCategory[];
    dispatch(setPermissionCategories(permissionCategories));
  });
}