import { useEffect, useRef, useState } from "react";
import { AlertType, TRANSITION_SECS } from ".";

const useAlertTransitionState = (alert: AlertType | undefined) => {
  const [alertCopy, setAlertCopy] = useState(alert);
  
  const alertDelayTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if(alertDelayTimer.current){
      clearTimeout(alertDelayTimer.current);
    }
    if(alert){
      if(alert.open){
        setAlertCopy(alert);
      }else{
        setAlertCopy(prev => ({ ...prev, open: false }));
        alertDelayTimer.current = setTimeout(() => {
          setAlertCopy(alert);
        }, TRANSITION_SECS * 1000);
      }
    }else{
      setAlertCopy(undefined);
    }
  }, [alert]);

  return alertCopy;
}
export default useAlertTransitionState;