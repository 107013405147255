import type { AppDispatch } from 'src/store/index';
import { createLanguage, deleteLanguage, updateLanguage } from 'src/slices/lists/languages';
import { setGeneralLanguages } from 'src/slices/general/languages';
import { deleteSupportedLanguagePercentage, updateSupportedLanguagePercentage } from 'src/slices/misc';
import { EchoChannel as Channel } from '../index';

// TODO: type this file !
export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  console.log('TODO: TYPED LANGUAGES !!!');
  channel.listen('.create_language', (data: any) => {
    const language = data.data.response;
    dispatch(createLanguage(language));
  });
  channel.listen('.update_language', (data: any) => {
    const language = data.data.response;
    dispatch(updateLanguage(language));
  });
  channel.listen('.delete_language', (data: any) => {
    const language = data.data.response;
    dispatch(deleteLanguage(language));
  });
  channel.listen('.set_supported_languages', (data: any) => {
    // language, percentage, create
    console.log('TODO: Check if this is call is right..');
    dispatch(setGeneralLanguages(data.data.response));
  });

  channel.listen('.supported_language_progress', (data: any) => {
    const parsedData = data.data.response as { language: any; percentage: number; create: boolean }; 
    if(parsedData.create == true){
      if(parsedData.percentage === 100){
        dispatch(deleteSupportedLanguagePercentage(parsedData));
      }else{
        dispatch(updateSupportedLanguagePercentage(parsedData)); 
      }
    }
    if(parsedData.create == false){
      if(parsedData.percentage === 0){
        dispatch(deleteSupportedLanguagePercentage(parsedData));
      }else{
        dispatch(updateSupportedLanguagePercentage(parsedData)); 
      }
    }
  });

}