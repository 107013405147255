import { AutoSizer } from 'react-virtualized';
import Avatar, { avatar_sizes } from 'src/components/general/Avatar';
import { makeStyles } from 'src/theme/makeStyles';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import MoreDropdown from './MoreDropdown';
import { useSelector } from 'src/store';

const AVATAR_OFFSET = 8;
const AVATAR_BORDER_SIZE = 2;
const SEPARATED_AVATAR_MARGIN = 16;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'inline-flex'
  },
  avatarWrapper: {
    position: 'relative',
    top: 0,
    transition: 'top 0.2s',
    marginLeft: -AVATAR_OFFSET,
    cursor: 'pointer',
    '&:hover': {
      zIndex: 9,
      top: -4
    }
  },
  avatarWrapperSelected: {
    border: `${AVATAR_BORDER_SIZE}px solid ${theme.palette.text.primary}`,
    borderRadius: '50%',
    top: -AVATAR_BORDER_SIZE,
    marginLeft: -(AVATAR_OFFSET + AVATAR_BORDER_SIZE), 
    marginRight: -AVATAR_BORDER_SIZE,
    marginBottom: -(AVATAR_BORDER_SIZE * 2)
  },
  avatarWrapperDisableSelect: {
    cursor: 'default'
  },
  avatarRoot: {
    border: `${AVATAR_BORDER_SIZE}px solid ${theme.palette.background.default}`,
    borderRadius: '50%'
  }
}));

export const getMaxContainerLength = (
  avatar_count: number, 
  size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium',
  generalUserSeparated: boolean = false
) => {
  const avatar_size = avatar_sizes[size] - AVATAR_OFFSET;

  return (avatar_size * avatar_count) + AVATAR_OFFSET + (generalUserSeparated ? SEPARATED_AVATAR_MARGIN : 0);
}

export interface GroupAvatarAvatar {
  name: string;
  user_id: string;
}

interface Props {
  avatars: GroupAvatarAvatar[];
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  selectedUserIds?: string[];
  setSelectedUserIds?: (value: string[]) => void;
  disableSelect?: boolean;
  style?: React.CSSProperties;
  generalUserSeparated?: boolean;
}

const AvatarGroup = ({
  avatars: unsortedAvatars,
  size = 'medium',
  selectedUserIds = [],
  setSelectedUserIds = () => {},
  disableSelect = false,
  style,
  generalUserSeparated = false
}: Props) => {
  const { classes } = useStyles();

  const generalUser = useSelector(state => state.general.user);

  const avatars = useMemo(() => {
    return unsortedAvatars.sort((a,b) => {
      if(generalUser && generalUserSeparated){
        if(a.user_id === generalUser.id){ return -1; }
        if(b.user_id === generalUser.id){ return 1; }
      }

      if(a.name > b.name){ return 1; }
      if(a.name < b.name){ return -1; }
      return 0;
    });
  }, [unsortedAvatars, generalUser, generalUserSeparated]);

  const handleAvatarClick = (user_id: string) => {
    if(disableSelect)
      return;

    if (selectedUserIds.includes(user_id)) {
      setSelectedUserIds(selectedUserIds.filter((x) => x !== user_id));
      return;
    }
    setSelectedUserIds([...selectedUserIds, user_id]);
  }

  const calculateMaxAvatars = (width: number) => {
    const avatar_width = avatar_sizes[size];
    const avatar_width_wo_offset = avatar_width - AVATAR_OFFSET;
    // -8 for 0 marginLeft on first item
    const calculated = Math.floor((width - 8) / avatar_width_wo_offset);

    return calculated;
  }

  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    if(disableSelect)
      return;
    setMoreAnchorEl(event.currentTarget);
  };

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        const max_avatars = calculateMaxAvatars(width);
        const has_more_avatars = max_avatars <= avatars.length - 1;
        const hidden_avatars = avatars.slice(max_avatars - 1);
        const sliced_avatars = avatars.slice(0, max_avatars - (has_more_avatars ? 1 : 0));

        // change number 2 maybe ??
        if(max_avatars < 1){
          return null;
        }

        return (
          <>
            <div className={classes.root} style={style}>
              {sliced_avatars.map((avatar, index) => {
                const is_selected = selectedUserIds.includes(avatar.user_id);
                return (
                  <div
                    key={index}
                    className={clsx(
                      classes.avatarWrapper, 
                      is_selected && classes.avatarWrapperSelected,
                      disableSelect && classes.avatarWrapperDisableSelect,
                    )}
                    style={!index ? { marginLeft: 0, marginRight: generalUserSeparated ? SEPARATED_AVATAR_MARGIN : 0 } : {}}

                    onClick={() => handleAvatarClick(avatar.user_id)}
                  >
                    <Avatar
                      className={classes.avatarRoot}
                      name={avatar.name}
                      tooltip={avatar.name}
                      user_id={avatar.user_id}
                      size={size}
                    />
                  </div>
                )
              })}

              {has_more_avatars && 
                <div
                  className={clsx(
                    classes.avatarWrapper,
                    disableSelect && classes.avatarWrapperDisableSelect
                  )}
                  onClick={handleMoreClick}
                  style={!sliced_avatars.length ? { marginLeft: 0 } : {}}
                >
                  <Avatar
                    className={classes.avatarRoot}
                    name={`+${avatars.length - max_avatars + 1}`}
                    tooltip={`${hidden_avatars.map((x) => x.name)}`}
                    // tooltip={avatar.name}
                    size={size}
                    fullname
                  />
                </div>
              }
            </div>
            <MoreDropdown
              anchorEl={moreAnchorEl}
              handleClose={() => setMoreAnchorEl(null)}
              userIdWhitelist={hidden_avatars.map((avatar) => avatar.user_id)}
              selected={selectedUserIds}
              setSelected={setSelectedUserIds}
            />
          </>
        )
      }}
    </AutoSizer>
  )
}
export default AvatarGroup;