import { useMemo } from "react";
import { useSelector } from "src/store";
import type { Task } from 'src/types/api/project';
import CheckIcon from '@mui/icons-material/Check';
import { Typography, colors } from "@mui/material";
import { useTranslation } from 'src/i18n';

interface Props {
  selectedTask?: Task;
}

const FinishedByText = ({
  selectedTask = undefined
}: Props) => {
  const {t} = useTranslation();
  const users = useSelector(state => state.lists.users);
  
  const finishedByUser = useMemo(() => {
    if (selectedTask?.finished_by) {
      const user = users.find((user) => user.id === selectedTask.finished_by);
      if (user)
        return user;
    }
    return null;
  }, [users, selectedTask]);

  if(!finishedByUser){
    return null;
  }

  return (
    <div style={{ display: 'flex', color: colors.lightGreen[500], marginTop: 15 }}>
      <CheckIcon fontSize='small' style={{ marginRight: 5 }} />
      <Typography variant='body2'>
        {t('lang.finished_by')}: {finishedByUser.contact.name}
      </Typography>
    </div>
  )
}
export default FinishedByText;