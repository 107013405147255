import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import { useTranslation } from 'src/i18n';
import Page from 'src/components/general/Containers/Page';
import ContentContainer from 'src/components/general/Containers/ContentContainer';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

function Error404View() {
  const { classes } = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const {t} = useTranslation();

  return (
    <Page
      className={classes.root}
      title={t("lang.404_title")}
    >
      <ContentContainer>
        <div
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}
        >
          <Typography
            align="center"
            variant={mobileDevice ? 'h4' : 'h1'}
            color="textPrimary"
          >
            {t("lang.404_header")}
          </Typography>
          <Typography
            align="center"
            variant="subtitle2"
            color="textSecondary"
          >
            {t("lang.404_subheader")}
          </Typography>
          <Box
            mt={6}
            display="flex"
            justifyContent="center"
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => window.history.back()}
            >
              {t("lang.404_button")}
            </Button>
          </Box>
        </div>
      </ContentContainer>
    </Page>
  );
}

export default Error404View;
