import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';

type LedgerAccount = {
  id: string
}
type LedgerCategory = {
  id: string
}
type InitialState = {
  accounts: LedgerAccount[],
  categories: LedgerCategory[]
}

const initialState: InitialState = {
  accounts: [],
  categories: []
};

const slice = createSlice({
  name: 'ledgerAccounts',
  initialState,
  reducers: {
    setLedgerAccounts(state: InitialState, action: PayloadAction<LedgerAccount[]>){
      state.accounts = action.payload;
    },
    setLedgerAccountCategories(state: InitialState, action: PayloadAction<LedgerCategory[]>){
      state.categories = action.payload;
    }
  }
})

export const { reducer } = slice;

export const setLedgerAccounts = (data: LedgerAccount[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLedgerAccounts(data));
}

export const setLedgerAccountCategories = (data: LedgerCategory[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLedgerAccountCategories(data));
}

export default slice;