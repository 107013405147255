import type { AppDispatch, RootState } from 'src/store/index';
import { EchoChannel as Channel } from '../index';
import { AnyAction, Store } from 'redux';
import { createCheckoutRow, updateCheckoutRow } from 'src/slices/register';
import { v4 as uuidv4 } from 'uuid';

const registerMisc = (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
) => {
  channel.listen('.scan_barcode', (data: any) => {
    const typedData = data.data.response as { code: string; };
    console.log(typedData);

    const products = store.getState().lists.products.products;
    const checkoutRows = store.getState().register.checkout.rows;

    // handleScanProduct
    const foundProduct = products.find(product => product.ean_code && product.ean_code.toLowerCase() === typedData.code.toLowerCase());
    console.log(foundProduct);

    if(foundProduct){
      const foundCheckoutRow = checkoutRows.find((checkoutRow) => checkoutRow.item_id === foundProduct.id);
      if(foundCheckoutRow){
        dispatch(updateCheckoutRow({
          ...foundCheckoutRow,
          count: (foundCheckoutRow.count ? foundCheckoutRow.count : 0) + 1
        }));
        return;
      }
      dispatch(createCheckoutRow({
        id: uuidv4(),
        item_id: foundProduct.id,
        count: 1,
        type: 'product',
        discount: 0
      }));

    }
  })
}
export default registerMisc;