import { useContext } from 'react';
import { AppContext } from 'src/App';
import { BroadcastContext } from 'src/custom/DataListener';
import { apiConfig } from 'src/config';
import { useDispatch } from 'src/store';
import { createFetching, deleteFetching } from 'src/slices/misc';
import baseAxios from 'axios';

export type AxiosOptions = ReturnType<typeof useAxiosOptions>;

export const useAxiosOptions = () => {
  const appContext = useContext(AppContext);
  const broadcastContext = useContext(BroadcastContext);
  const dispatch = useDispatch();

  const credentials = {
    apiToken: appContext && appContext.apiToken ? appContext.apiToken.token : null,
    setApiToken: appContext && appContext.setApiToken,
    
    // @ts-ignore (NEVER RETURN TYPE CHECK LATER !!!!)
    socketId: broadcastContext && broadcastContext.echo ? broadcastContext.echo.socketId() : null
    // socketId: (broadcastContext && broadcastContext.echo && broadcastContext.echo.connection.socket_id) ? broadcastContext.echo.connection.socket_id : null
  }

  return {
    ...credentials,
    apiConfig: apiConfig.getOptions(credentials, dispatch)
  }
}

export const useAxios = () => {
  const dispatch = useDispatch();
  const axios = baseAxios.create();

  // already set axiosOptions ?

  axios.interceptors.request.use((config) => {
    const url = config.url;
    if(url){
      dispatch(createFetching({ url }));
    }
    return config;
  });
  axios.interceptors.response.use((response) => {
    const url = response.config.url
    if(url){
      dispatch(deleteFetching({ url }));
    }
    return response;
  })
  return axios;
}

// export const axios = baseAxios;