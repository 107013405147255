import { LinearProgress, Typography, useTheme } from '@mui/material'
import { useSelector } from 'src/store';
import bytesToSize from 'src/utils/general/bytesToSize';
import { useTranslation } from 'src/i18n';

const StorageBar = () => {
  const {t} = useTranslation();
  const theme = useTheme();

  const group = useSelector(state => state.general.group);
  if(!group || !group.max_storage){ return null; }

  const percentage = group.storage / group.max_storage * 100;
  
  // console.log(`Max storage: ${bytesToSize(group.max_storage)}`);
  // console.log(`Used storage: ${bytesToSize(group.storage)}`);
  // console.log(percentage);

  return (
    <div style={{position: 'absolute', width: '100%', bottom: 0, left: 0, padding: '10px 15px'}}>
      <div
        style={{position: 'relative'}}
      >
        <LinearProgress 
          style={{height: 6, borderRadius: '4px'}}
          value={percentage < 100 ? percentage: 100}
          variant='determinate' 
        />
        <div
          style={{position: 'absolute', top: -14, left: 0}}
        >
          <Typography
            style={{fontSize: 11}}
            variant='body1'
          >
            {t('lang.storage')}
            {/* Opslag */}
          </Typography>
        </div>
        <div
          style={{position: 'absolute', top: -14, right: 0}}
        >
          <Typography
            style={{fontSize: 11}}
            variant='body1'
          >
            {`${bytesToSize(group.storage)} / ${bytesToSize(group.max_storage)}`}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default StorageBar;