import { useEffect, useRef } from "react";

const useAutofocus = (autoFocus: boolean, value: unknown) => {
  const focusRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if(autoFocus && focusRef.current){
      focusRef.current.focus();
      if(value && typeof(value) === 'string'){
        focusRef.current.setSelectionRange(value.length, value.length);
      }
    }
  }, [focusRef.current]);
  return focusRef;
}
export default useAutofocus;