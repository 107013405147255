import { StandardTextFieldProps, TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslation } from 'src/i18n';
import mergeRefs from "react-merge-refs";
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';
import useAutofocus from '../logic/useAutofocus';
import InputMask from 'react-input-mask';
import { style } from '@mui/system';

const Iban = forwardRef<HTMLDivElement, StandardTextFieldProps>(({ disabled, ...props }, forwardedRef) => {
  const {t} = useTranslation();
  
  return (
    <InputMask
      mask='**** **** **** **** **'
      maskChar={null}
      // value={typeof props.value === 'string' ? props.value : ''}
      // onChange={props.onChange}
      // onBlur={props.onBlur}
      
      // @ts-ignore
      beforeMaskedValueChange={(state: any) => {
        const val = state.value as string;
        state.value = val.toUpperCase();
        return state;
      }}

      // error={props.error}
      // helperText={props.helperText}

      disabled={disabled}
      {...props}
    >
      {(props: StandardTextFieldProps) => (
        <TextField 
          ref={forwardedRef}
          variant='standard'
          label={`${t('lang.iban')}`}
          fullWidth
          disabled={disabled}
          {...props}
          // inputProps={{
          //   style: {
          //     textTransform: 'uppercase',
          //     ...props.inputProps?.style
          //   },
          //   ...props.inputProps
          // }}
          // inputRef={combinedRefs}
        />
      )}
    </InputMask>

  )
});

export default Iban;