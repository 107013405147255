import { AxiosRequestConfig } from "axios";
import { AppDispatch } from "src/store";
import { Group, GroupSmall } from "src/types/api/group";
import axios from 'axios';
import { createGroup, updateGroup } from "src/slices/lists/groups";

export interface CreateGroupRequestData {
  company_name: string;
  first_name: string;
  last_name: string;
  template_id: string;
  subscription_id: string;
  alias: string;
  cname?: string;
  color1_light?: string;
  color2_light?: string;
  color3_light?: string;
  color1_dark?: string;
  color2_dark?: string;
  color3_dark?: string;
  language: string;
  email: string;
  password: string;
  c_password: string;
  images: {
    background: string | null; // (dataUrl)
    logo_light: string | null; // (dataUrl)
    favicon_light: string | null; // (dataUrl)
    logo_dark: string | null; // (dataUrl)
    favicon_dark: string | null; // (dataUrl)
  }
}

export const createGroupRequest = (
  data: CreateGroupRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<GroupSmall> => {
  return new Promise<GroupSmall>((resolve, reject) => {
    axios.post<GroupSmall>('system/groups', data, config)
    .then((response) => {
      dispatch(createGroup(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export interface UpdateGroupRequestData {
  template_id: string;
  subscription_id: string;
  alias: string;
  color1_light?: string;
  color2_light?: string;
  color3_light?: string;
  color1_dark?: string;
  color2_dark?: string;
  color3_dark?: string;
  language: string;
  cname?: string;
  images: {
    background: string | null; // (dataUrl)
    logo_light: string | null; // (dataUrl)
    favicon_light: string | null; // (dataUrl)
    logo_dark: string | null; // (dataUrl)
    favicon_dark: string | null; // (dataUrl)
  },
  clear_images: boolean;
}

export const updateGroupRequest = (
  group_id: string,
  data: UpdateGroupRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<GroupSmall> => {
  return new Promise<GroupSmall>((resolve, reject) => {
    axios.put<GroupSmall>(`system/groups/${group_id}`, data, config)
    .then((response) => {
      dispatch(updateGroup(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// if(!Object.keys(errors).length){
//   let data = {
//     company_name: formValues.company_name,
//     first_name: formValues.first_name,
//     last_name: formValues.last_name,
//     template_id: formValues.template,
//     alias: formValues.alias,
//     // color1: formValues.color01,
//     // color2: formValues.color02,

//     ...formValues.colors.light,
//     ...formValues.colors.dark,

//     language: formValues.language.toLowerCase(),
//     email: formValues.email,
//     password: formValues.password,
//     c_password: formValues.c_password,
//     images: {
//       background: files['background'],
//       logo_light: files['logo_light'],
//       favicon_light: files['favicon_light'],
//       logo_dark: files['logo_dark'],
//       favicon_dark: files['favicon_dark']
//     }
//   };

//   if(formValues.cname){
//     data.cname = formValues.cname;
//   }

//   setIsSubmitting(true);

//   axios.post('system/groups', data, axiosOptions.apiConfig)
//   .then((response) => {
//     dispatch(createGroup(response.data));
//     window.history.back();
//   })
//   .catch((error) => {
//     setIsSubmitting(false);
//     console.log(error);
//   });