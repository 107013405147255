import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppContext, TAppContext } from 'src/App';
import { useAxios } from 'src/hooks/general/useAxios';
import Loading from 'src/pages/other/misc/Loading';
import { getContactsRequest } from 'src/requests/contacts';
import { coreCallRequest } from 'src/requests/system';
import { setChatMessages } from 'src/slices/chatMessages';
import { setEventStatuses } from 'src/slices/lists/events';
import { setFiles } from 'src/slices/lists/files';
import { setFolders } from 'src/slices/lists/folders';
import { setGiftcards } from 'src/slices/lists/giftcards';
import { setGroups } from 'src/slices/lists/groups';
import { setInvoiceWorkflows } from 'src/slices/lists/invoicing';
import { setLanguages } from 'src/slices/lists/languages';
import { setNotes } from 'src/slices/lists/notes';
import { setPermissionCategories, setPermissions } from 'src/slices/lists/permissions';
import { setPresentations } from 'src/slices/lists/presentations';
import { setProductCategories, setProducts } from 'src/slices/lists/products';
import { setPublicWidgets } from 'src/slices/lists/publicwidgets';
import { setServiceCategories, setServices } from 'src/slices/lists/services';
import { setTags } from 'src/slices/lists/tags';
import { setTemplates } from 'src/slices/lists/templates';
import { setTimeTrackers } from 'src/slices/lists/timetrackers';
import { setWorkingHoursColors } from 'src/slices/lists/workingHours';
import { setAvatars, setFullMenu } from 'src/slices/misc';
import { AppDispatch, useDispatch, useSelector } from 'src/store';
import { EventStatus } from 'src/types/api/events/Event';
import type { File as TFile } from 'src/types/api/file';
import type { Folder } from 'src/types/api/folder';
import { Giftcard } from 'src/types/api/giftcard';
import type { GroupSmall } from 'src/types/api/group';
import type { Language } from 'src/types/api/language';
import type { Menu } from 'src/types/api/menu';
import type { Note } from 'src/types/api/note';
import type { Permission, PermissionCategory } from 'src/types/api/permission';
import type { Presentation } from 'src/types/api/presentation';
import type { Product, ProductCategory } from 'src/types/api/product';
import type { PublicWidget } from 'src/types/api/publicwidget';
import type { Service, ServiceCategory } from 'src/types/api/service';
import type { Tag } from 'src/types/api/tag';
import type { TemplateSmall } from 'src/types/api/template';
import type { TimeTracker } from 'src/types/api/timetracker';
import type { User } from 'src/types/api/user';
import { WorkingHoursColor } from 'src/types/api/workingHours';
import { apiConfig } from '../config';

// interface CoreData {
//   general: {
//     group: Group;
//     user: User;
//     menu: any;
//     custom_fields: any;
//     languages: GeneralLanguage[];
//     settings: EntitySetting[];
//   },
//   lists: {
//     users: UserSmall[];
//     permissions: {
//       categories: PermissionCategory[];
//     },
//     roles: RoleSmall[];
//     projects: Project[];
//     vatrates: Vatrate[];
//     system_settings: {
//       categories: SystemSettingCategory[];
//     },
//   }
// }

const handleAsyncCalls = (generalUser: User, dispatch: AppDispatch, appContext: TAppContext | null, axios: AxiosInstance) => {
  const axiosOptions = apiConfig.getOptions({ ...appContext, apiToken: appContext?.apiToken?.token});

  const handleAsyncErr = (error: any) => {
    console.log(error);
  }

  const permissions = generalUser.role.permissions.map(permission => permission.tag);
  // const permissions = data.general.user.role.permissions.map((permission) => permission.tag);

  if(permissions.includes('read_contact')){
    getContactsRequest(dispatch, axiosOptions).catch(handleAsyncErr);
  }
  if(permissions.includes('read_group')){
    axios.get<GroupSmall[]>('system/groups', axiosOptions)
    .then((response) => { dispatch(setGroups(response.data)); })
    .catch(handleAsyncErr);
  }
  if(permissions.includes('read_permission')){
    axios.get<Permission[]>('system/permissions', axiosOptions)
    .then((response) => { dispatch(setPermissions(response.data)); })
    .catch(handleAsyncErr);
  }
  if(permissions.includes('read_permission_category')){
    axios.get<PermissionCategory[]>('system/permissions/categories', axiosOptions)
    .then((response) => { dispatch(setPermissionCategories(response.data)); })
    .catch(handleAsyncErr);
  }
  if(permissions.includes('read_language')){
    axios.get<Language[]>('system/languages', axiosOptions)
    .then((response) => { dispatch(setLanguages(response.data)); })
    .catch(handleAsyncErr);
  }
  // if(permissions.includes('read_role')){
  //   axios.get<RoleSmall[]>('roles', axiosOptions)
  //   .then((response) => { dispatch(setRoles(response.data)); })
  //   .catch(handleAsyncErr);
  // }
  if(permissions.includes('read_template')){
    axios.get<TemplateSmall[]>('system/templates', axiosOptions)
    .then((response) => { dispatch(setTemplates(response.data)); })
    .catch(handleAsyncErr);
  }
  // invoice workflows

  if(permissions.includes('read_invoice')){
    console.log('TODO: invoice workflow type & change route??');
    axios.get<any[]>('invoicing/workflows', axiosOptions)
    .then((response) => { dispatch(setInvoiceWorkflows(response.data)); })
    .catch(handleAsyncErr);
  }

  if(permissions.includes('read_general_note')){
    console.log('todo: read_general_note to read_note?');
    axios.get<Note[]>('notes', axiosOptions)
    .then((response) => { dispatch(setNotes(response.data)); })
    .catch(handleAsyncErr);
  }
  // ledger accounts
  // ledger account categories
  if(permissions.includes('read_time_registration')){
    axios.get<TimeTracker[]>('timetracker', axiosOptions)
    .then((response) => { dispatch(setTimeTrackers(response.data)); })
    .catch(handleAsyncErr);
  }
  if(permissions.includes('read_presentation')){
    axios.get<Presentation[]>('presentations', axiosOptions)
    .then((response) => { dispatch(setPresentations(response.data)); })
    .catch(handleAsyncErr);
  }
  // devices
  // if(permissions.includes('read_file')){ // CHECK THIS PERMISSION !!!
    axios.get<TFile[]>('files', axiosOptions)
    .then((response) => { dispatch(setFiles(response.data)); });
    // .catch(handleAsyncErr);
  // }

  // permission to read_file ???
  // if(permissions.includes('read_folder')){ // CHECK THIS PERMISSION !!!
    axios.get<Folder[]>('folders', axiosOptions)
    .then((response) => { dispatch(setFolders(response.data)); });
    // .catch(handleAsyncErr);
  // }

  if(permissions.includes('read_menu_item')){
    // full menu  
    axios.get<Menu>('menu', axiosOptions)
    .then((response) => { dispatch(setFullMenu(response.data)); })
    .catch(handleAsyncErr);
  }
  
  if(permissions.includes('read_product')){
    axios.get<Product[]>('products', axiosOptions)
    .then((response) => { dispatch(setProducts(response.data)); })
    .catch(handleAsyncErr);
  }

  if(permissions.includes('read_product_category')){
    axios.get<ProductCategory[]>('products/categories', axiosOptions)
    .then((response) => { dispatch(setProductCategories(response.data)); })
    .catch(handleAsyncErr);
  }

  if(permissions.includes('read_service')){
    axios.get<Service[]>('services', axiosOptions)
    .then((response) => { dispatch(setServices(response.data)); })
    .catch(handleAsyncErr);
  }

  if(permissions.includes('read_service_category')){
    axios.get<ServiceCategory[]>('services/categories', axiosOptions)
    .then((response) => { dispatch(setServiceCategories(response.data)); })
    .catch(handleAsyncErr);
  }

  axios.get<WorkingHoursColor[]>('workinghours/colors', axiosOptions)
  .then((response) => { dispatch(setWorkingHoursColors(response.data)); })
  .catch(handleAsyncErr);

  // if(permissions.includes('read_invoice')){
  //   axios.get<Invoice[]>('invoices', axiosOptions)
  //   .then((response) => { dispatch(setInvoices(response.data)); })
  //   .catch(handleAsyncErr);
  // }

  // non-permission

  axios.get<Tag[]>('tags', axiosOptions)
  .then((response) => { dispatch(setTags(response.data)); })
  .catch(handleAsyncErr);

  axios.get<PublicWidget[]>('publicwidgets', axiosOptions)
  .then((response) => { dispatch(setPublicWidgets(response.data)); })
  .catch(handleAsyncErr);

  axios.get<Giftcard[]>('giftcards', axiosOptions)
  .then((response) => { dispatch(setGiftcards(response.data)); })
  .catch(handleAsyncErr);
}

// export const handleCoreCall = async (dispatch: AppDispatch, appContext: TAppContext | null, axios: AxiosInstance, navigate: NavigateFunction) => {
//   const contextToUse = {
//     ...appContext,
//     apiToken: appContext?.apiToken?.token
//   };
  
//   axios.get('messages', apiConfig.getOptions(contextToUse))
//   .then((response) => {
//     dispatch(setMessages(response.data));
//   });

//   axios.get('avatars', apiConfig.getOptions(contextToUse))
//   .then((response) => {
//     dispatch(setAvatars(response.data));
//   });
  
//   await axios.get<CoreData>('general/core', apiConfig.getOptions(contextToUse))
//   .then((response) => {
//     const data = response.data;
//     console.log(data);
    
//     dispatch(setGeneralGroup(data.general.group));
//     dispatch(setGeneralUser(data.general.user));
//     dispatch(setGeneralLanguages(data.general.languages));
//     dispatch(setMenu(data.general.menu))
//     dispatch(setCustomFields(data.general.custom_fields));
//     dispatch(setUsers(data.lists.users));
//     dispatch(setPermissionCategories(data.lists.permissions.categories));
//     dispatch(setRoles(data.lists.roles));
//     dispatch(setProjects(data.lists.projects));
//     dispatch(setVatrates(data.lists.vatrates));
//     dispatch(setSystemSettingCategories(data.lists.system_settings.categories));
//     dispatch(setSettings(data.general.settings));

//     // subscription check (created hook does not work here yet !!!)
//     const subscription_expiry_date_dayjs = dayjs(response.data.general.group.subscription_expiry_date).startOf('day');
//     const now_dayjs = dayjs().startOf('day');
//     const subscriptionExpired = subscription_expiry_date_dayjs.isSameOrBefore(now_dayjs);
//     if(!subscriptionExpired){
//       handleAsyncCalls(data, dispatch, appContext, axios);
//     }else{
//       navigate('/dashboard');
//     }
//     // asyncCalls(data);
//     // setLoaded(true);
//   })
//   .catch((error) => {
//     // onError(error);
//   });
// }


// interface DataLoaderProps {
//   children: JSX.Element
// }
// export default function DataLoader({ children }: DataLoaderProps) {
//   const axios = useAxios();
//   const [loaded, setLoaded] = useState(false);
//   const dispatch = useDispatch();
//   const appContext = useContext(AppContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const corecallPromise = handleCoreCall(dispatch, appContext, axios, navigate);
//     corecallPromise.then(() => {
//       setLoaded(true);
//     });
//   }, []);

//   return (
//     loaded ? children : <Loading />
//   );
// }

interface CoreCallComponentProps {
  children: JSX.Element;
}
export const CoreCallComponent = ({ children }: CoreCallComponentProps) => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [finished, setFinished] = useState(false);

  const appContext = useContext(AppContext);
  const contextToUse = {
    ...appContext,
    apiToken: appContext?.apiToken?.token
  };

  useEffect(() => {
    axios.get('chatmessages', apiConfig.getOptions(contextToUse))
    .then((response) => {
      dispatch(setChatMessages(response.data));
    });

    axios.get('avatars', apiConfig.getOptions(contextToUse))
    .then((response) => {
      dispatch(setAvatars(response.data));
    });

    coreCallRequest(dispatch, apiConfig.getOptions(contextToUse))
    .then((response) => {
      const subscription_expiry_date_dayjs = dayjs(response.general.group.subscription.expiry_date).startOf('day');
      const now_dayjs = dayjs().startOf('day');
      const subscriptionExpired = subscription_expiry_date_dayjs.isSameOrBefore(now_dayjs);
      if(subscriptionExpired){
        navigate('/dashboard');
      }
      setFinished(true);
    }).catch(err => console.log(err));
  }, [])

  if(!finished){
    return <Loading />;
  }

  return children;
}

interface AsyncCallsComponentProps {
  children: JSX.Element;
}
export const AsyncCallsComponent = ({ children }: AsyncCallsComponentProps) => {
  const dispatch = useDispatch();
  const axios = useAxios();
  const appContext = useContext(AppContext);
  const user = useSelector(state => state.general.user);

  const [callInitiated, setCallInitiated] = useState(false);

  useEffect(() => {
    if(user && !callInitiated){
      setCallInitiated(true);
      handleAsyncCalls(user, dispatch, appContext, axios);
    }
  }, [user]);

  return children;
}

// const otherCalls