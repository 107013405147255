import { yupResolver } from "@hookform/resolvers/yup";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'src/i18n';
import ContactTypeLabel from "src/components/contacts/TypeLabel";
import ToggleButton from "src/components/forms/buttons/ToggleButton";
import ToggleButtonGroup from "src/components/forms/buttons/ToggleButtonGroup";
import BirthdayField from "src/components/forms/fields/Birthday";
import EmailField from "src/components/forms/fields/Email";
import BicField from "src/components/forms/fields/financial/Bic";
import IbanField from "src/components/forms/fields/financial/Iban";
import FirstUpperField from "src/components/forms/fields/FirstUpper";
import GenderField from "src/components/forms/fields/Gender";
import LastNameField from "src/components/forms/fields/LastName";
import NumericField from "src/components/forms/fields/Numeric";
import PhoneField from "src/components/forms/fields/Phone";
import validations from "src/components/forms/validations";
import AddAddressDialog, { AddressValues } from "src/components/general/AddAddressDialog";
import AddressCard from "src/components/general/AddressCard";
import { DatePicker } from "src/components/general/DateTimePicker_v3";
import DialogTitleAvatar from 'src/components/general/DialogTitleAvatar';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import { ActionButton, ActionButtonGroup } from 'src/components/general/DuotoneDialog/ActionButton';
import Tags from "src/components/general/Tags";
import { useAxiosOptions } from "src/hooks/general/useAxios";
import useClaim from "src/hooks/general/useClaim";
import {
  createContactRequest,
  CreateContactRequestData,
  getContactRequest,
  updateContactRequest,
  UpdateContactRequestData
} from "src/requests/contacts";
import { closeForm } from 'src/slices/forms';
import { useDispatch, useSelector } from "src/store";
import { makeStyles } from "src/theme/makeStyles";
import * as yup from 'yup';
import CollapseRow from './CollapseRow';
import ContactPickerDialog, { SelectedContactCard } from "src/components/general/ContactPickerDialog";
import { DEFAULT_VALUES } from './constants';
import getBicFromIban from './getBicFromIban';
import type { FormValues } from './types';
import useClientNumber from "src/hooks/general/useClientNumber";
import { ActivityList } from "src/components/general/ActivityList";
import Avatar from 'src/components/general/Avatar';
import { Activity } from "src/types/api/activity";
import { setCurrentContact } from "src/slices/current";

const useStyles = makeStyles()((theme) => ({
  buttonLabel: {
    fontSize: '0.8rem',
    // @ts-ignore
    color: theme.name === 'DARK' ? '#adb0bb' : '#546e7a',
    marginBottom: 5
  }
}));



const yupSchema = yup.object().shape({
  type: yup.string(),
  companyName: yup.string().when('type', {
    is: 'company',
    then: validations.default_string
  }),
  firstName: yup.string().when('type', {
    is: 'person',
    then: validations.default_string
  }),
  lastName: yup.string().when('type', {
    is: 'person',
    then: validations.default_string
  }),
  sepa: yup.boolean(),
  mandateId: yup.string().when('sepa', {
    is: true,
    then: validations.default_string
  }),
  mandateDate: yup.number().nullable().when('sepa', {
    is: true,
    then: validations.default_number.nullable()
  }),
  coc: yup.string().when('type', {
    is: 'company',
    then:
      validations.default_string.notRequired()
        .min(8, 'error.too_short')
        .max(8, 'error.too_long')
        .nullable()
        .transform(value => !!value ? value : null)
  }),
  emails: yup.array().of(yup.object().shape({
    email: validations.email.notRequired()
  })),
  phones: yup.array().of(yup.object().shape({
    phone: validations.phone_number.notRequired()
  })),

  iban: yup.string().test('valid_iban', `error.invalid_iban`, (value) => {
    if (!value || !value.length) {
      return true;
    }
    const eIban = electronicFormatIBAN(value);
    if (!eIban) {
      return false;
    }
    return isValidIBAN(eIban);
  }),
  bic: yup.string().test('valid_bic', `error.invalid_bic`, (value) => {
    if (!value || !value.length) {
      return true;
    }
    return isValidBIC(value);
  })

  // address validation ???

});

const Contact = () => {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();
  const dispatch = useDispatch();
  const {
    open: formOpen,
    updateId,
    initialValues,
    callback: afterCreateCallback
  } = useSelector(state => state.forms.contact);
  const contacts = useSelector(state => state.lists.contacts);
  const avatars = useSelector(state => state.misc.avatars);

  const currentContact = useSelector(state => state.current.contact);

  const axiosOptions = useAxiosOptions();

  const { refreshClientNumber } = useClientNumber();

  const [relationPickerOpen, setRelationPickerOpen] = useState(false);

  const handleClientNumberRefresh = () => {
    setValue('clientNumber', `${refreshClientNumber()}`);
  }

  // automatically refreshes client_number on contacts change
  useEffect(() => {
    handleClientNumberRefresh();
  }, [contacts]);

  const handleClose = () => {
    dispatch(closeForm('contact'));
  }

  const {
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(yupSchema)
  });

  const watchValues = watch();

  // claim todo
  const { claimUser, currentUserHasFirstClaim } = useClaim(updateId ? updateId : null);
  // useEffect(() => {
  //   console.log(claimUser);
  //   console.log(currentUserHasFirstClaim);
  // }, [claimUser, currentUserHasFirstClaim]);

  const handleValidSubmit = async (values: FormValues) => {
    console.log('NOT FINISHED YET !!!');

    let postData: CreateContactRequestData = {
      company_name: values.companyName,
      first_name: null,
      last_name: null,
      bic: values.bic,
      gender: null,
      birthday: null,
      // cbs: null, // ????
      // oin: null, // ?????
      client_number: values.clientNumber,
      coc: '',
      vat: '',
      iban: values.iban,

      // language: 'NL', // ????
      avatar: values.avatar ? values.avatar : null,
      accountname: values.accountname,

      address: values.addresses.map(address => ({
        label: address.type,
        zip: address.zipcode,
        city: address.city,
        street: address.street,
        number: address.houseNumber,
        state: address.province
      })),

      phone: values.phones.filter(p => !!p.phone.length).map(phone => ({
        phone: phone.phone,
        label: phone.label
      })),
      email: values.emails.filter(e => !!e.email.length).map(email => ({
        email: email.email,
        label: email.label
      })),
      relation: values.relationIds.map(id => ({ id })),
      tag_ids: values.tagIds,
      sepa: values.sepa,
      unconfirmed_activities: unconfirmedActivities.map(a => a.id)
    };

    if (values.type === 'person') {
      postData = {
        ...postData,
        company_name: null,
        first_name: values.firstName,
        last_name: values.lastName,
        gender: values.gender !== '' ? values.gender : null,
        birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
      }
    }

    if (values.type === 'company') {
      postData = {
        ...postData,
        coc: values.coc,
        vat: values.vat
      }
    }

    if (values.sepa) {
      postData.mandateid = values.mandateId;
      postData.mandatedate = values.mandateDate;
    }else{
      postData.mandateid = null;
      postData.mandatedate = null;
    }

    if (updateId) {
      const putData: UpdateContactRequestData = {
        ...postData,
        avatar_changed: values.avatarChanged
      }

      try {
        const contactUpdateResult = await updateContactRequest(updateId, putData, dispatch, axiosOptions.apiConfig);
        if(currentContact?.id === updateId){
          const updatedContact = contactUpdateResult.find(c => c.id === updateId);
          if(updatedContact){
            dispatch(setCurrentContact(updatedContact));
          }
        }

        handleClose();
      } catch (e: any) {
        console.error(e);
      }
      return;
    }

    try {
      const contactCreateResult = await createContactRequest(postData, dispatch, axiosOptions.apiConfig);
      contactCreateResult.forEach(contact => {
        afterCreateCallback && afterCreateCallback(contact);
      });
      handleClose();
    } catch (e: any) {
      console.error(e);
    }
  }

  // set values or clear on open
  const [fetchingContact, setFetchingContact] = useState(false);
  useEffect(() => {
    setUnconfirmedActivities([]);
    
    if (!formOpen) {
      // TODO: delay
      reset(DEFAULT_VALUES);
    }

    if (formOpen && !updateId) {
      reset({
        ...DEFAULT_VALUES,
        clientNumber: `${refreshClientNumber()}`,
        ...initialValues
      });
    }

    if (formOpen && updateId) {
      setFetchingContact(true);

      getContactRequest(updateId, axiosOptions.apiConfig)
        .then(contact => {
          const firstEmailAddress = contact.email[0];
          const firstPhone = contact.phone[0];

          reset({
            ...DEFAULT_VALUES,
            type: (contact.tag === 'person' || contact.tag === 'user') ? 'person' : 'company',
            firstName: contact.first_name ? contact.first_name : '',
            lastName: contact.last_name ? contact.last_name : '',
            accountname: contact.accountname ? contact.accountname : '',

            // TODO: type veranderen zodat zip, city etc. niet meer null mag zijn?
            addresses: contact.address.map(address => ({
              type: address.label,
              zipcode: address.zip ? address.zip : '',
              city: address.city ? address.city : '',
              street: address.street ? address.street : '',
              houseNumber: address.number ? `${address.number}` : '',
              province: address.state ? address.state : ''
            })),

            emails: [{
              email: firstEmailAddress ? firstEmailAddress.email : '',
              label: firstEmailAddress ? firstEmailAddress.label : 'general'
            }],
            phones: [{
              phone: firstPhone ? firstPhone.phone : '',
              label: firstPhone ? firstPhone.label : 'general'
            }],
            avatar: avatars[contact.id] ? `data:image/jpeg;base64,${avatars[contact.id]}` : null,
            companyName: contact.company_name ? contact.company_name : '',
            bic: contact.bic ? contact.bic : '',
            birthday: contact.birthday ? dayjs(contact.birthday) : null,
            clientNumber: `${contact.client_number}`,
            coc: contact.coc ? contact.coc : '',
            gender: contact.gender ? contact.gender : '',
            iban: contact.iban ? contact.iban : '',
            mandateDate: contact.mandatedate ? contact.mandatedate : null,
            mandateId: contact.mandateid ? contact.mandateid : '',
            relationIds: contact.relations,
            sepa: contact.sepa ? contact.sepa : false,
            vat: contact.vat ? contact.vat : '',
            tagIds: contact.tags,
            ...initialValues
          });
        })
        .catch(err => console.error(err))
        .finally(() => setFetchingContact(false))
    }

  }, [formOpen]); // initialDate ???

  const relationContacts = useMemo(() => {
    return contacts.filter(contact => watchValues.relationIds.includes(contact.id))
  }, [contacts, watchValues.relationIds]);

  const handleIbanBlur = () => {
    const ibanValue = electronicFormatIBAN(watchValues.iban);
    if (ibanValue && isValidIBAN(ibanValue)) {
      const newBic = getBicFromIban(ibanValue);
      setValue('bic', newBic ? newBic : '');
    } else {
      setValue('bic', '');
    }
  }

  const getFormTitle = () => {
    if (watchValues.type === 'company' && watchValues.companyName) {
      return watchValues.companyName;
    }
    if (watchValues.type === 'person' && (watchValues.firstName || watchValues.lastName)) {
      return `${watchValues.firstName} ${watchValues.lastName}`;
    }
    if (updateId) {
      return `${t('lang.update_contact')}`;
    }
    return `${t('lang.new_contact')}`;
  }

  const updateContact = useMemo(() => {
    return contacts.find(contact => contact.id === updateId);
  }, [contacts, updateId]);

  const [addressDialogOpen, setAddressDialogOpen] = useState<{
    open: boolean;
    initialValues: AddressValues | null;
    editIndex: number | null;
  }>({ open: false, initialValues: null, editIndex: null });

  // !TEMP
  const handleInvalidSubmit = (errors: any) => {
    console.log(errors);
  }

  const [unconfirmedActivities, setUnconfirmedActivities] = useState<Activity[]>([]);

  const disableAllFields = !!(updateId && !currentUserHasFirstClaim);

  return (
    <DuotoneDialog
      open={formOpen}
      title={getFormTitle()}
      onClose={handleClose}
      onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      submitButtonProps={{
        disabled: isSubmitting || disableAllFields
      }}
      subtitle={(
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Typography variant='body2'>
            {t('lang.client_number')}: {watchValues.clientNumber}
          </Typography>
          <IconButton
            onClick={handleClientNumberRefresh}
            size='small'
            style={{ marginLeft: 6 }}
            disabled={disableAllFields}
          >
            <RefreshIcon color="primary" style={{ fontSize: 20 }} />
          </IconButton>
        </div>
      )}
      titleLeftComponent={(
        <div style={{ marginRight: theme.spacing(1.5) }}>
          <DialogTitleAvatar
            name={
              watchValues.type === 'company' ?
                watchValues.companyName :
                (watchValues.firstName || watchValues.lastName) ? `${watchValues.firstName} ${watchValues.lastName}` : ''
            }
            onAvatarChange={(val) => {
              setValue('avatar', val);
              setValue('avatarChanged', true);
            }}
            // avatarDataUrl={}
            avatarDataUrl={watchValues.avatar ? watchValues.avatar : undefined}
            disabled={disableAllFields}
            // TODO: ?? disabled ??
          />
        </div>
      )}
      isLoading={fetchingContact}
      avatarComponent={updateContact ? (
        <div style={{ height: '100%' }}>
          <ContactTypeLabel
            contact={updateContact}
          />
        </div>
      ) : undefined}

      alert={updateId && formOpen && !fetchingContact ? {
        open: !currentUserHasFirstClaim && !!claimUser,
        title: claimUser ? `${claimUser.contact.name} ${t('lang.is_editing_contact')}` : undefined,
        startComponent: !claimUser ? null : (
          <Avatar
            name={claimUser.contact.name}
            user_id={claimUser.id}
            size='small'
          />
        )
      } : undefined}
    >
      <div
        style={{ minHeight: 600 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tags
              category={'contact'}
              tagIds={watchValues.tagIds}
              onChange={(values) => setValue('tagIds', values)}
              disableCreate={disableAllFields}
              // TODO: ?? disabled ??
            />
          </Grid>
          {!updateId && (
            <>
              <Grid item xs={6}>
                <Box mb={3}>
                  <Typography
                    className={classes.buttonLabel}
                    variant='body2'
                  >
                    {t('lang.contacttype')}
                  </Typography>
                  <ToggleButtonGroup
                    value={watchValues.type}
                    exclusive
                    onChange={(e, newValue) => newValue && setValue('type', newValue)}
                    size='small'
                    fullWidth
                  >
                    <ToggleButton value="person" disabled={disableAllFields}>
                      <PersonOutlineIcon fontSize="small" style={{ marginRight: 4 }} />
                      {t('lang.person')}
                    </ToggleButton>
                    <ToggleButton value="company" disabled={disableAllFields}>
                      <HomeWorkOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
                      {t('lang.company')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6} />
            </>
          )}
        </Grid>

        <CollapseRow
          icon={watchValues.type === 'person' ? <PersonOutlineIcon /> : <HomeWorkOutlinedIcon />}
          title={watchValues.type === 'person' ?
            `${t('lang.person_details')}` :
            `${t('lang.company_details')}`
          }
          mt={1.5}
        >
          <Box mt={3}>
            <Grid
              container
              spacing={3}
            >
              {watchValues.type === 'person' ? (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name='firstName'
                      control={control}
                      render={({ field }) => (
                        <FirstUpperField
                          label={`${t("lang.first_name")}*`}
                          fullWidth
                          error={!!errors.firstName}
                          helperText={errors.firstName?.message ? t(errors.firstName.message) : undefined}
                          {...field}
                          disabled={disableAllFields}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name='lastName'
                      control={control}
                      render={({ field }) => (
                        <LastNameField
                          label={`${t("lang.last_name")}*`}
                          fullWidth
                          error={!!errors.lastName}
                          helperText={errors.lastName?.message ? t(errors.lastName.message) : undefined}
                          {...field}
                          disabled={disableAllFields}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Controller
                    name='companyName'
                    control={control}
                    render={({ field }) => (
                      <FirstUpperField
                        label={`${t("lang.company_name")}*`}
                        fullWidth
                        error={!!errors.companyName}
                        helperText={errors.companyName?.message ? t(errors.companyName.message) : undefined}
                        {...field}
                        disabled={disableAllFields}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <Controller
                  name='emails.0.email'
                  control={control}
                  render={({ field }) => (
                    <EmailField
                      fullWidth
                      error={!!(errors.emails && errors.emails[0]?.email)}
                      helperText={errors.emails && errors.emails[0]?.email?.message && t(errors.emails[0].email.message)}
                      {...field}
                      disabled={disableAllFields}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='phones.0.phone'
                  control={control}
                  render={({ field }) => (
                    <PhoneField
                      fullWidth
                      error={!!(errors.phones && errors.phones[0]?.phone)}
                      helperText={errors.phones && errors.phones[0]?.phone?.message && t(errors.phones[0].phone.message)}
                      {...field}
                      disabled={disableAllFields}
                    />
                  )}
                />
              </Grid>
              {watchValues.type === 'person' && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name='gender'
                      control={control}
                      render={({ field }) => (
                        <GenderField
                          value={field.value ? field.value : ''}
                          onChange={field.onChange}
                          textFieldProps={{
                            disabled: disableAllFields
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name='birthday'
                      control={control}
                      render={({ field }) => (
                        <BirthdayField
                          value={field.value}
                          onChange={field.onChange}
                          disabled={disableAllFields}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </CollapseRow>
        <CollapseRow
          icon={<CreditCardIcon />}
          title={`${t('lang.financial')}`}
        >
          <Box mt={3}>
            <Grid container spacing={3}>

              {watchValues.type === 'company' && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name='coc'
                      control={control}
                      render={({ field }) => (
                        <NumericField
                          value={field.value}
                          onChange={(val) => setValue('coc', val)}
                          textFieldProps={{
                            label: `${t("lang.coc_number")}`,
                            error: !!errors.coc,
                            helperText: errors.coc?.message ? t(errors.coc.message) : undefined,
                            disabled: disableAllFields,
                            ...field
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name='vat'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={`${t("lang.vat_number")}`}
                          fullWidth
                          variant='standard'
                          error={!!errors.vat}
                          helperText={errors.vat?.message ? t(errors.vat.message) : undefined}
                          disabled={disableAllFields}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <Controller
                  name='iban'
                  control={control}
                  render={({ field }) => (
                    <IbanField
                      {...field}
                      onBlur={handleIbanBlur}
                      error={!!errors.iban}
                      helperText={errors.iban?.message ? t(errors.iban.message) : undefined}
                      disabled={disableAllFields}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='bic'
                  control={control}
                  render={({ field }) => (
                    <BicField
                      error={!!errors.bic}
                      helperText={errors.bic?.message ? t(errors.bic.message) : undefined}
                      disabled={disableAllFields}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='accountname'
                  control={control}
                  render={({ field }) => (
                    <FirstUpperField
                      label={`${t("lang.in_the_name_of")}`}
                      fullWidth
                      variant='standard'
                      error={!!errors.accountname}
                      helperText={errors.accountname?.message ? t(errors.accountname.message) : undefined}
                      disabled={disableAllFields}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <FormControlLabel
                  onChange={() => setValue('sepa', !watchValues.sepa)}
                  control={<Checkbox checked={watchValues.sepa} />}
                  label={`S€PA`}
                  labelPlacement="end"
                  disabled={disableAllFields}
                />
              </Grid>
              <Grid item xs={6} />

              {watchValues.sepa && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name='mandateId'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={`${t('lang.mandate')}*`}
                          fullWidth
                          variant='standard'
                          error={!!errors.mandateId}
                          helperText={errors.mandateId?.message ? t(errors.mandateId.message) : undefined}
                          disabled={disableAllFields}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      value={typeof watchValues.mandateDate === 'number' ? dayjs.unix(watchValues.mandateDate) : null}
                      onChange={(date) => setValue('mandateDate', date ? date.unix() : null)}
                      textFieldProps={{
                        label: `${t('lang.mandate_date')}*`,
                        fullWidth: true,
                        error: !!errors.mandateDate,
                        helperText: errors.mandateDate?.message ? t(errors.mandateDate.message) : undefined
                      }}
                      staticDatePickerProps={{
                        maxDate: dayjs().startOf('day')
                      }}
                      disabled={disableAllFields}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </CollapseRow>
        <Divider />
        <Box sx={{ mt: 3 }}>
          {updateId && (
            <ActivityList
              // category='task'
              objectId={updateId}
              // urlPrefix={`contacts/${updateId}`}
              disabled={disableAllFields}
              unconfirmedActivities={unconfirmedActivities}
              onNewUnconfirmedActivity={(activity) => setUnconfirmedActivities(prev => ([...prev, activity]))}
            />
          )}
        </Box>
      </div>
      <div>
        <ActionButtonGroup
          title={`${t('lang.addresses')}`}
          disableMarginTop
        >
          <>
            {watchValues.addresses.map((address, index) => (
              <Box key={index} mb={1.5}>
                <AddressCard
                  key={index}
                  values={address}
                  onUpdate={() => setAddressDialogOpen({ open: true, initialValues: address, editIndex: index })}
                  onDelete={() => setValue('addresses', watchValues.addresses.filter((address, l_index) => l_index !== index))}
                />
              </Box>
            ))}
            {watchValues.addresses.length < 3 && (
              // <AddAddressButton
              //   onClick={() => setAddressDialogOpen({ open: true, initialValues: null, editIndex: null })}
              //   isSecondaryButton={!watchValues.addresses.length}
              //   disabled={disableAllFields}
              // />
              <>
                {watchValues.addresses.length ? (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Button
                      onClick={() => setAddressDialogOpen({ open: true, initialValues: null, editIndex: null })}
                      size='small'
                      color='primary'
                      style={{ 
                        cursor: 'pointer', 
                        width: 'fit-content',
                        marginLeft: 'auto' 
                      }}
                      disabled={disableAllFields}
                    >
                      {`+ ${t('lang.add_address')}`}
                    </Button>
                  </div>
                ) : (
                  <ActionButton
                    icon='mdiMapMarkerOutline'
                    onClick={() => setAddressDialogOpen({ open: true, initialValues: null, editIndex: null })}
                    disabled={disableAllFields}
                  >
                    {`${t('lang.add_address')}`}
                  </ActionButton>
                )}

              </>
            )}
          </>
        </ActionButtonGroup>
        <ActionButtonGroup
          title={`${t('lang.relations')}`}
        >
          <>
            {relationContacts.map((relation, index) => (
              <Box
                mb={1.5}
                key={index}
              >
                <SelectedContactCard 
                  contact={relation}
                  onDelete={() => {
                    setValue("relationIds", [
                      ...watchValues.relationIds.filter(id => id !== relation.id)
                    ]);
                  }}
                  disabled={disableAllFields}
                />
              </Box>
            ))}
            {/* <AddRelationButton
              onClick={() => setRelationPickerOpen(true)}
              isSecondaryButton={!relationContacts.length}
              disabled={disableAllFields}
            /> */}

            {relationContacts.length ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <Button
                  onClick={() => setRelationPickerOpen(true)}
                  size='small'
                  color='primary'
                  style={{ 
                    cursor: 'pointer', 
                    width: 'fit-content',
                    marginLeft: 'auto' 
                  }}
                  disabled={disableAllFields}
                >
                  {`${t('lang.add_relation')}`}
                </Button>
              </div>
            ) : (
              <ActionButton
                icon='mdiAccountOutline'
                onClick={() => setRelationPickerOpen(true)}
                disabled={disableAllFields}
              >
                {`${t('lang.add_relation')}`}
              </ActionButton>
            )}

          </>
        </ActionButtonGroup>

        <ContactPickerDialog
          open={relationPickerOpen}
          selectedContactIds={watchValues.relationIds}
          onSelect={(contact) => {
            setValue("relationIds", [
              ...watchValues.relationIds,
              contact.id
            ]);
          }}
          onClose={() => setRelationPickerOpen(false)}
        />

        <AddAddressDialog
          open={addressDialogOpen.open}
          onClose={() => setAddressDialogOpen({ open: false, initialValues: null, editIndex: null })}
          onChange={(values) => {
            if (addressDialogOpen.editIndex !== null) {
              setValue(`addresses.${addressDialogOpen.editIndex}`, values);
            } else {
              setValue('addresses', [
                ...watchValues.addresses,
                values
              ])
            }
          }}
          values={addressDialogOpen.initialValues ? addressDialogOpen.initialValues : undefined}
        />
      </div>
    </DuotoneDialog>
  )
}
export default Contact;