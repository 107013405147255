import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent } from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
  textFieldProps?: TextFieldProps;
  dontAllowLeadingZeros?: boolean;
}

const normalizeNoLeadingZeros = (value: string) => 
  value.replace(/\D/g, '').replace(/^0+/, '');

const normalize = (value: string) => 
  value.replace(/\D/g, '');

const Numeric = ({
  value,
  onChange,
  textFieldProps,
  dontAllowLeadingZeros = false
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if(dontAllowLeadingZeros){
      onChange(normalizeNoLeadingZeros(e.target.value));
    }else{
      onChange(normalize(e.target.value));
    }
  }

  return (
    <TextField 
      variant='standard'
      fullWidth
      {...textFieldProps}
      value={value}
      onChange={handleChange}
    />
  )
}
export default Numeric;