import { StandardTextFieldProps, TextField } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'src/i18n';
import mergeRefs from "react-merge-refs";
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';
import useAutofocus from './logic/useAutofocus';

// const isValidPhonenumber = (value: string) => {
//   return (/^\d{7,}$/).test(value.replace(/[\s()+\-\.]|ext/gi, ''));
// }

const normalize = (value: string): string => {
  return value.replace(/[^0-9+-]/g, '');
}

const Phone = forwardRef<HTMLDivElement, StandardTextFieldProps>((props, forwardedRef) => {
  const { t } = useTranslation();
  const { ref: caretRef, updateCaret } = useCaretPosition();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    updateCaret();
    e.target.value = normalize(e.target.value);
    props.onChange && props.onChange(e);
  }

  const focusRef = useAutofocus(!!props.autoFocus, props.value);

  const combinedRefs = mergeRefs(props.inputRef ? [props.inputRef, focusRef, caretRef] : [focusRef, caretRef]);
  return (
    <TextField
      ref={forwardedRef}
      variant='standard'
      label={`${t('lang.phone_number')}`}
      {...props}
      inputRef={combinedRefs}
      onChange={handleChange}
    />
  )
});

export default Phone;