import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  useStore as useReduxStore
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { AnyAction, configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

// const rootReducer = (state: any, action: any) => {
//   if (action.type === 'RESET_STATE') {
//     state = {};
//   }
//   return actualRootReducer(state, action);
// };

const store = configureStore({
  reducer: rootReducer,
  // devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true'
  devTools: true,
  middleware: (getDefaultMiddleware) => 
  getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useStore = () => useReduxStore<RootState>();

export default store;