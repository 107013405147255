import { Autocomplete, TextField, Typography, alpha, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useAxios, useAxiosOptions } from 'src/hooks/general/useAxios';
import { useTranslation } from 'src/i18n';
import { createTag, updateTag } from 'src/slices/lists/tags';
import { useDispatch, useSelector } from 'src/store';
import { makeStyles } from 'src/theme/makeStyles';
import type { Tag } from 'src/types/api/tag';
import ColorPicker from '../ColorPicker';
import CTag from './Tag';

const useStyles = makeStyles()((theme) => ({
  root: {
    '& > *': {
      marginRight: 10,
      marginBottom: 10
    }
  },
  createTag: {
    cursor: 'pointer',
    width: 'fit-content',
    color: theme.palette.primary.main
  },
  createTagDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
    color: theme.palette.text.disabled
  },
  newTagRoot: {
    position: 'relative',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0.5,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    zIndex: 999,
    color: theme.palette.primary.contrastText,
    margin: 0,
    width: '50%'
  },
  newTagSuggestion: {
    padding: 8, 
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: alpha('#000', 0.2)
    }
  },
  suggestionWrapper: {
    position: 'absolute',
    top: 25,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    minWidth: 220,
    maxHeight: 240,
    overflowY: 'auto'
  },
  tagColorIndicator: {
    width: 10, 
    height: 10, 
    borderRadius: '50%',
    marginRight: 5
  },
  autocompleteInputRoot: {
    padding: '0px !important'
  },
  autocompleteNoOptions: {
    display: 'none'
  }
}));

interface Props {
  tagIds: string[];
  onChange?: (tagIds: string[]) => void;
  category: string;
  disableCreate?: boolean;
  disableRemove?: boolean;
  disableColorPicker?: boolean;
  hideCreate?: boolean;
  state_tags?: Tag[];
}

const Tags = ({
  tagIds,
  onChange,
  category,
  disableCreate = false,
  disableRemove = false,
  disableColorPicker = false,
  hideCreate = false,
  state_tags
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();
  const {t} = useTranslation();
  const theme = useTheme();
  const axios = useAxios();

  // state tags for iframe compatibility
  const selected_tags = useSelector(state => state.lists.tags)
  const all_tags = state_tags ? state_tags : selected_tags;
  const all_tags_sorted = useMemo(() => {
    return [...all_tags].sort((a,b) => {
      if(a.title > b.title){ return 1; }
      if(a.title < b.title){ return -1; }
      return 0;
    });
  }, [all_tags]);

  const categorized_tags = useMemo(() => {
    return all_tags_sorted.filter((tag) => tag.category === category);
  }, [all_tags_sorted, category]);

  const tags = useMemo(() => {
    return categorized_tags.filter((tag) => tagIds.includes(tag.id));
  }, [categorized_tags, tagIds]);

  const handleDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tag_id: string) => {
    e.stopPropagation();
    
    const newTagIds = tagIds.filter((tag) => tag !== tag_id);
    onChange && onChange(newTagIds);
  }

  const [newTitle, setNewTitle] = useState<string | null>(null);
  const handleCreate = () => {
    if(newTitle){
      const categoryWithExistingTitle = categorized_tags.find((tag) => tag.title.toLowerCase() === newTitle?.toLowerCase())
      if(categoryWithExistingTitle){
        onChange && onChange([...tagIds, categoryWithExistingTitle.id]);
        setNewTitle(null);
        return;
      }

      const data = {
        title: newTitle,
        category: category
      };
      axios.post<Tag>('tags', data, axiosOptions.apiConfig)
      .then((response) => {
        dispatch(createTag(response.data));
        onChange && onChange([...tagIds, response.data.id]);
      })
      .catch((err) => console.log(err));
    }
    setNewTitle(null);
  }

  const handleExistingTagClick = (e: any, tag_id: string) => {
    e.stopPropagation();
    onChange && onChange([...tagIds, tag_id]);
    setNewTitle(null);
  }

  const filteredSuggestions = useMemo(() => {
    const availableTags = categorized_tags.filter((tag) => !tagIds.find((id) => id === tag.id));
    if(newTitle === null)
      return [];
    return availableTags;
  }, [categorized_tags, newTitle]);

  const [openColorPicker, setOpenColorPicker] = useState<string | null>(null);
  const handleOpenColorPicker = (tag_id: string) => {
    setOpenColorPicker(tag_id);
  }

  const handleColorClick = (color: string) => {
    if(openColorPicker){
      const data = { _method: 'PUT', color };
      axios.post<Tag>(`tags/${openColorPicker}`, data, axiosOptions.apiConfig)
      .then((response) => {
        dispatch(updateTag(response.data));
      })
      .catch((err) => console.log(err));
      setOpenColorPicker(null);
    }
  }

  return (
    <div className={classes.root}>
      {tags.map((tag, index) => (
        <CTag
          key={index}
          color={tag.color ? tag.color : undefined}
          style={!disableColorPicker ? { cursor: 'pointer' } : {}}
          onClick={() => !disableColorPicker && openColorPicker === null && handleOpenColorPicker(tag.id)}
          handleDelete={!disableRemove ? (e) => handleDelete(e, tag.id) : undefined}
        >
          {tag.title}
          {!disableColorPicker &&
            <ColorPicker 
              onClick={handleColorClick} 
              handleClose={() => setOpenColorPicker(null)}
              open={openColorPicker === tag.id}
            />
          }
        </CTag>
      ))}
      {!!tags.length && <br/>}

      {newTitle !== null ?
        <div
          className={classes.newTagRoot}
          style={{ height: 20 }}
        >
          <Autocomplete
            onChange={(e, value) => value && handleExistingTagClick(e, value.id)}
            inputValue={newTitle}
            onInputChange={(e, value) => setNewTitle(value)}
            getOptionLabel={(option) => option.title}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <div 
                  className={classes.tagColorIndicator}
                  style={{ 
                    backgroundColor: option.color ? option.color : theme.palette.primary.main
                  }}
                />
                {option.title}
              </li>
            )}
            options={filteredSuggestions}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                variant='standard'
                autoFocus
                inputProps={{
                  maxLength: 25, // to 30
                  ...inputProps
                }}
                {...params}
              />
            )}
            popupIcon={null}
            onBlur={() => setNewTitle(null)}
            classes={{ input: classes.autocompleteInputRoot, noOptions: classes.autocompleteNoOptions }}
            onKeyPress={(e) => e.key === 'Enter' && handleCreate()}
            fullWidth
            openOnFocus
          />
        </div>
        : !hideCreate && (
          <div style={{ height: 20 }}>
            <Typography
              className={clsx(classes.createTag, disableCreate && classes.createTagDisabled)}
              onClick={() => setNewTitle('')}
              fontSize='0.8rem'
              fontWeight={500}
            >
              {`+ ${t('lang.add_tag')}`}
            </Typography>
          </div>
        ) 
      }
    </div>
  )
}

export default Tags;