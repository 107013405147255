import { MenuItem, StandardTextFieldProps, TextField } from "@mui/material";
import { ChangeEvent } from 'react';
import { useTranslation } from 'src/i18n';

type GenderType = 'male' | 'female' | 'other' | '';

interface Props {
  value: GenderType;
  onChange: (value: GenderType) => void;
  textFieldProps?: Partial<StandardTextFieldProps>;
}

const Gender = ({
  value,
  onChange,
  textFieldProps
}: Props) => {
  const {t} = useTranslation();

  const genders: GenderType[] = ['male', 'female', 'other'];

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value as GenderType;    
    onChange(val);
  }

  return (
    <TextField
      label={`${t('lang.gender')}`}
      value={value}
      onChange={handleChange}
      fullWidth
      variant='standard'
      select
      {...textFieldProps}
    >
      <MenuItem value=''>
        <em>{t('lang.none')}</em>
      </MenuItem>
      {genders.map((gender, index) => (
        <MenuItem key={index} value={gender}>{t(`lang.${gender}`)}</MenuItem>
      ))}
    </TextField>
  )
}
export default Gender;