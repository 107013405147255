import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, FocusEvent, useState } from "react";
import mergeRefs from "react-merge-refs";
import useCaretPosition from "src/hooks/general/useNewCaretPosition";
import { useTranslation } from 'src/i18n';
import useAutofocus from "../logic/useAutofocus";
import { AddressAutofillController } from "./useAddressAutofillController";

export const validateZipcode = (zipCode: string, countryCode: string = 'nl') => {
  if(countryCode.toLowerCase() === 'nl'){
    const pattern = /^\d{4}\s?[A-Z]{2}$/;
    return pattern.test(zipCode);
  }
  return true;
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  addressAutofillController?: AddressAutofillController;
  textFieldProps?: Partial<TextFieldProps>;
  nrValue?: string;
}

const ZipCode = ({
  value,
  onChange,
  addressAutofillController,
  textFieldProps,
  nrValue = ''
}: Props) => {
  const {t} = useTranslation();
  const focusRef = useAutofocus(!!textFieldProps?.autoFocus, textFieldProps?.value);
  const { ref: caretRef, updateCaret } = useCaretPosition();

  const [changedAfterLastFocus, setChangedAfterLastFocus] = useState(false);
  
  const handleFocus = () => {
    setChangedAfterLastFocus(false);
  }
  
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateCaret();
    if(!changedAfterLastFocus){
      setChangedAfterLastFocus(true);
    }
    onChange(e.target.value.toUpperCase().replace(' ', ''));
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(!changedAfterLastFocus){
      return;
    }

    if(addressAutofillController){
      addressAutofillController.handleNewZip(value, nrValue);
    }

    if(textFieldProps?.onBlur){
      textFieldProps.onBlur(e);
    }

    setChangedAfterLastFocus(false);
  }

  const combinedRefs = mergeRefs(textFieldProps?.inputRef ? [textFieldProps.inputRef, caretRef, focusRef] : [focusRef, caretRef]);

  return (
    <TextField
      variant='standard'
      label={`${t('lang.postal_code')}`}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      fullWidth
      {...textFieldProps}
      inputProps={{
        maxLength: '6',
        ...textFieldProps?.inputProps
      }}
      inputRef={combinedRefs}
      onBlur={handleBlur}
    />
  )
}
export default ZipCode;