import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { useMemo } from 'react';
import useSubscriptionExpired from "src/hooks/general/useSubscriptionExpired";
import SubscriptionExpiryAlert from 'src/layouts/DashboardLayout/NavBar/SubscriptionExpiryAlert';
import { setPaywallOpen } from "src/slices/misc";
import { useDispatch, useSelector } from "src/store";
import { makeStyles } from "src/theme/makeStyles";

const useStyles = makeStyles()(theme => ({
  dialogPaper: {
    height: '100%'
  }
}));

const PayWall = () => {
  const { classes, theme } = useStyles();
  const dispatch = useDispatch();
  const { subscriptionExpired, daysUntilExpiry } = useSubscriptionExpired();
  const paywallOpen = useSelector(state => state.misc.paywallOpen);

  const subscriptions = useSelector(state => state.general.group?.template.subscriptions);
  const filteredSubscriptions = useMemo(() => {
    return subscriptions ? subscriptions.filter(sub => sub.upgradable) : []
  }, [subscriptions]);

  const handleClose = () => {
    dispatch(setPaywallOpen(false)); 
  }

  return (
    <Dialog
      open={subscriptionExpired || paywallOpen}
      fullWidth
      maxWidth='lg'
      classes={{ paper: classes.dialogPaper }}
      onClose={handleClose}
    >
      <DialogContent style={{ height: '100%' }}>
        
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <div style={{ display: 'flex', width: '100%' }}>
            {daysUntilExpiry !== null ? (
              <Typography variant='h2'>
                Nog {daysUntilExpiry} dagen proefperiode!
              </Typography>
            ) : (
              <Typography variant='h2'>
                Upgrade jouw abonnement
              </Typography>
            )}
            {!subscriptionExpired && (
              <IconButton 
                onClick={handleClose} 
                style={{ marginLeft: 'auto' }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
          <SubscriptionExpiryAlert fullyOpen={true} />

          <div style={{ display: 'flex', width: '100%', flexGrow: 1 }}>
            {/* Subscription options */}
            {/* {[
              {
                title: 'Afspraken',
                price: `${currencySymbol}14,95`,
                features: [
                  'Online agenda',
                  'Online afspraken',
                  'Automatische herinneringen',
                  'Klantbeheer'
                ]
              },
              {
                title: 'Compleet',
                price: `${currencySymbol}24,95 (+ ${currencySymbol}5,- per extra medewerker)`,
                features: [
                  'Online agenda',
                  'Online afspraken',
                  'Werkroosters',
                  'Automatische herinneringen',
                  'Klantbeheer',
                  'Kassasysteeem',
                  'Behandelnotities',
                  'Online betalingen',
                  'Voorraadbeheer',
                  'Financiële rapportages'
                ]
              },
              {
                title: 'Maatwerk',
                price: `Prijs op maat`,
                features: ['In overleg']
              }
            ] */}
            {filteredSubscriptions.map((value, index) => (
              <div
                key={index}
                style={{
                  flexGrow: 1,
                  padding: 12,
                  height: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 12,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 4
                  }}
                >
                  <Typography style={{ marginBottom: theme.spacing(3) }}>
                    {value.title}
                  </Typography>
                  
                  {value.lines.map((line, index) => (
                    <Typography variant='body2' key={index}>
                      - {line}
                    </Typography>
                  ))}

                  <div style={{ marginTop: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      &euro; {value.price}
                    </Typography>
                    <Button
                      variant='contained'
                      color="primary"
                      style={{ marginLeft: 'auto' }}
                      disabled
                    >
                      Kies nu!
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default PayWall;