import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Menu } from 'src/types/api/menu';

const initialState: Menu = [];

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu(state: Menu, action: PayloadAction<Menu>){
      return action.payload;
    },
  }
})

export const { reducer } = slice;

export const setMenu = (payload: Menu): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMenu(payload));
}

export default slice;