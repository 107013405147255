import { Fade, ListItem, ListItemButton, ListItemText, Paper, TextField, alpha, TextFieldProps, Popover } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/i18n';
import useIsMobile from 'src/hooks/general/useIsMobile';
import { makeStyles } from 'src/theme/makeStyles';
import clsx from 'clsx';

// const OPTION_HEIGHT = 44;
const OPTION_HEIGHT = 40;

const useStyles = makeStyles()(theme => ({
  listItemBtnRoot: {
    padding: 0
  },
  scrollBox: {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  pickerRoot: {
    // position: 'absolute',
    // top: 48,
    // left: '50%',
    width: 105,
    height: 5 * OPTION_HEIGHT,
    display: 'flex',
    position: 'relative'
    // transform: 'translateX(-50%)',
    // zIndex: theme.zIndex.modal
  },
  hourMinuteValue: {
    borderRadius: 4, 
    padding: 4,
    // height: 36,
    // width: 36,
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hourMinuteValueSelected: {
    background: theme.palette.primary.main
  }
}));

interface Props {
  value: dayjs.Dayjs | null;
  onChange: (value: dayjs.Dayjs | null) => void;
  error?: boolean;
  onError?: (value: boolean) => void;
  minTime?: dayjs.Dayjs;
  maxTime?: dayjs.Dayjs;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
}

const TimePicker = ({
  value,
  onChange,
  error = false,
  onError,
  minTime,
  maxTime,
  textFieldProps,
  disabled = false
}: Props) => {
  const {t} = useTranslation();
  const { classes, theme } = useStyles();
  const isMobile = useIsMobile();

  const [pickerOpen, setPickerOpen] = useState(false);

  // 
  const handleSetHour = (hour: number) => {
    let newValue = (value && value.isValid()) ? value.clone().hour(hour) : dayjs().hour(hour);
    if(!value){
      newValue = newValue.minute(0);
    }
    onChange(newValue);
  }
  const handleSetMinute = (minute: number) => {
    const newValue = (value && value.isValid()) ? value.clone().minute(minute) : dayjs().minute(minute);
    onChange(newValue);
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const str = e.target.value;
    setTextFieldValue(str);
    
    // if(!str.length){
    //   onChange(null);
    //   return;
    // }

    const hmDayObj1 = dayjs(str, 'HH:mm', true);
    const hmDayObj2 = dayjs(str, 'HHmm', true);

    const hmDayObj = hmDayObj1.isValid() ? hmDayObj1 : hmDayObj2;

    if(hmDayObj.isValid()){
      const newValue = (value && value.isValid()) ? 
        value.clone().hour(hmDayObj.hour()).minute(hmDayObj.minute()) : 
        dayjs().hour(hmDayObj.hour()).minute(hmDayObj.minute());
      onChange(newValue)
    }
  }

  const handleBlur = () => {
    if(textFieldValue.length === 0){
      onChange(null);
    }
    setTextFieldValue((value && value.isValid()) ? value.format('HH:mm') : '');
  }

  const [textFieldValue, setTextFieldValue] = useState((value && value.isValid()) ? value.format('HH:mm') : '');
  useEffect(() => {
    setTextFieldValue((value && value.isValid()) ? value.format('HH:mm') : '');
  }, [value]);

  // scroll into view logic
  const hourScrollRef = useRef<HTMLDivElement | null>(null);
  const minuteScrollRef = useRef<HTMLDivElement | null>(null);
  
  
  const handleScroll = (smooth = true) => {
    if(pickerOpen){ // & is valid ???
      const hScrRef = hourScrollRef.current;
      const mScrRef = minuteScrollRef.current;

      if(hScrRef){
        const top = value ? value.hour() * OPTION_HEIGHT : dayjs().hour() * OPTION_HEIGHT;
        hScrRef.scrollTo({ top, behavior: smooth ? 'smooth' : 'auto' });
      }
      if(mScrRef){
        const top = value ? (value.minute() / 5 * OPTION_HEIGHT) : 0;
        mScrRef.scrollTo({ top, behavior: smooth ? 'smooth' : 'auto' });
      }
    }
  }
  
  useEffect(() => {
    setTimeout(() => handleScroll(false), 50);
  }, [pickerOpen]);

  useEffect(() => {
    setTimeout(() => handleScroll(), 50);
  }, [value]);

  const getIsDisabledHour = (hour: number) => {
    if(value){
      const dayjsObj = value && value.isValid() ? value.clone().hour(hour) : dayjs().hour(hour);
      if(minTime && dayjsObj.isBefore(minTime, 'hour')){
        return true;
      }
      if(maxTime && dayjsObj.isAfter(maxTime, 'hour')){
        return true;
      }
    }
    return false;
  }
  const getIsDisabledMinute = (minute: number) => {
    if(value){
      const dayjsObj = value && value.isValid() ? value.clone().minute(minute) : dayjs().minute(minute).hour(value.hour());
      if(minTime && dayjsObj.isBefore(minTime, 'minute')){
        return true;
      }
      if(maxTime && dayjsObj.isAfter(maxTime, 'minute')){  
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if(!onError){
      return;
    }

    if(value){
      if(!value.isValid()){
        onError(true);
        return;
      }
      if(getIsDisabledHour(value.hour())){
        onError(true);
        return;
      }
      if(getIsDisabledMinute(value.minute())){
        onError(true);
        return;
      }
    }
    // check times
    onError(false);
  }, [value, minTime, maxTime]); // minTime, maxTime

  const textFieldRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      <TextField 
        ref={textFieldRef}
        label={`${t('lang.time')}`}
        variant='standard'
        value={textFieldValue}
        onChange={handleChange}
        // onFocus={() => setPickerOpen(true)} // onClick ???
        onClick={() => !disabled && setPickerOpen(true)}
        onBlur={handleBlur}
        // InputLabelProps={{
        //   shrink: true
        // }}
        // placeholder={`${t('lang.select_time')}`}
        autoComplete='off'
        style={{ width: 45 }}
        error={error}
        disabled={disabled}
        {...textFieldProps}
      />
      <Popover 
        open={pickerOpen}
        anchorEl={textFieldRef.current}
        onClose={() => setPickerOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableAutoFocus
      >
        <div
          className={classes.pickerRoot}
          onMouseDown={(e) => e.preventDefault()}
        >
          <div 
            ref={hourScrollRef}
            className={classes.scrollBox}
            style={{ 
              overflow: 'auto',
              width: '50%',
              padding: `${OPTION_HEIGHT * 2}px 0px`,
              scrollSnapType: isMobile ? 'y mandatory' : 'none'
            }}
          >
            {new Array(24).fill(undefined).map((x, index) => {
              const isSelected = value && value.hour() === index;
              const isDisabled = getIsDisabledHour(index);

              return (
                <ListItem
                  key={index}
                  disablePadding
                  style={{ 
                    // background: isSelected ? alpha(theme.palette.primary.main, 0.75) : theme.palette.background.paper,
                    scrollSnapAlign: isMobile ? 'center' : 'none'
                  }}
                >
                  <ListItemButton 
                    onClick={() => handleSetHour(index)}
                    classes={{ root: classes.listItemBtnRoot }}
                    disabled={isDisabled}
                  >
                    <ListItemText 
                      style={{ textAlign: 'center' }}
                      primaryTypographyProps={{
                        fontSize: '0.9rem',
                        fontWeight: 400,
                        style: {
                          display: 'flex',
                          justifyContent: 'center',
                          color: isSelected ? theme.palette.getContrastText(theme.palette.primary.main) : theme.palette.text.primary
                        }
                      }}
                    >
                      <span className={clsx(classes.hourMinuteValue, isSelected && classes.hourMinuteValueSelected)}>
                        {index < 10 && '0'}{index}
                      </span>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              )
            })}
          </div>
          <div
            ref={minuteScrollRef}
            className={classes.scrollBox}
            style={{ 
              overflow: 'auto', 
              width: '50%', 
              padding: `${OPTION_HEIGHT * 2}px 0px`,
              scrollSnapType: isMobile ? 'y mandatory' : 'none'
            }}
          >
            {new Array(12).fill(undefined).map((x, index) => {
              const minuteValue = index * 5;
              const isSelected = value && value.minute() === minuteValue;
              
              // const isDisabled = (minTime && minTime.minute() > minuteValue) || (maxTime && maxTime.minute() < minuteValue);
              const isDisabled = getIsDisabledMinute(minuteValue);

              return (
                <ListItem
                  key={index}
                  disablePadding
                  style={{ 
                    // background: isSelected ? alpha(theme.palette.primary.main, 0.75) : theme.palette.background.paper,
                    scrollSnapAlign: isMobile ? 'center' : 'none'
                  }}
                >
                  <ListItemButton
                    onClick={() => handleSetMinute(minuteValue)}
                    classes={{ root: classes.listItemBtnRoot }}
                    disabled={isDisabled}
                  >
                    <ListItemText 
                      style={{ textAlign: 'center' }}
                      primaryTypographyProps={{
                        fontSize: '0.9rem',
                        fontWeight: 400,
                        style: {
                          display: 'flex',
                          justifyContent: 'center',
                          color: isSelected ? theme.palette.getContrastText(theme.palette.primary.main) : theme.palette.text.primary
                        }
                      }}
                    >
                      <span
                        className={clsx(
                          classes.hourMinuteValue, 
                          isSelected && classes.hourMinuteValueSelected
                        )}>
                        {minuteValue < 10 && '0'}{minuteValue}
                      </span>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              )
            })}
          </div>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              color: theme.palette.text.primary,
              transform: 'translate(-50%, -50%)',
              cursor: 'default'
            }}
          >
            :
          </div>
        </div>
      </Popover>
      {/* {pickerOpen && (
        <div
          style={{
            position: 'absolute',
            top: 137,
            left: '50%',
            color: theme.palette.text.primary,
            transform: 'translateX(-50%)'
          }}
        >
          :
        </div>
      )} */}
    </div>
  )
}
export default TimePicker;