import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Group } from 'src/types/api/group';

const initialState: Group | null = null;

const slice = createSlice({
  name: 'group',
  initialState: initialState as (Group | null),
  reducers: {
    setGeneralGroup(state: Group, action: PayloadAction<Group>){
      return action.payload;
    },
    setGeneralGroupStorage(state: Group, action: PayloadAction<number>){
      state.storage = action.payload;
    }
  }
})

export const { reducer } = slice;

export const setGeneralGroup = (payload: Group): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGeneralGroup(payload));
}

export const setGeneralGroupStorage = (payload: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGeneralGroupStorage(payload));
}

export default slice;