import { ThemeProvider, Theme } from '@mui/material';
// import jssPreset from '@mui/styles/jssPreset';
// import StylesProvider from '@mui/styles/StylesProvider';
import axios from 'axios';
// import { create } from 'jss';
import type { FC } from 'react';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { apiConfig, imageUrls } from 'src/config';
import { TimerContextProvider } from 'src/contexts/TimerContext';
import DataListener from 'src/custom/DataListener';
import { CoreCallComponent, AsyncCallsComponent } from 'src/custom/DataLoader';
import IdleChecker from 'src/custom/IdleChecker';
import LanguageCheck from 'src/custom/LanguageCheck';
import DashboardLayout from 'src/layouts/DashboardLayout';
import GlobalStyles from 'src/components/general/GlobalStyles';
import useScrollReset from 'src/hooks/general/useScrollReset';
import useLocalSettings from 'src/hooks/general/useLocalSettings';
import { restoreSettings } from 'src/contexts/SettingsContext';
import { deviceRoutes, preLoginRoutes, frameRoutes, routes } from 'src/routes';
import { createTheme } from 'src/theme';
import OfflineOverlay from 'src/custom/OfflineOverlay';
import { UploadHandler } from 'src/hooks/general/useUploadHandler';
import { DownloadHandler } from 'src/hooks/general/useDownloadHandler';
import ErrorHandler from 'src/components/general/ErrorHandler';
import createEmotionCache from "@emotion/cache";
import { CacheProvider as EmotionCacheProvider } from "@emotion/react";
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from 'src/components/general/DateTimePicker_v3';
import PreLoginBackground from 'src/custom/PreLoginBackground';
import ContactForm from 'src/dialogs/forms/Contact';
import AppointmentForm from 'src/dialogs/forms/Appointment';
import TaskForm from 'src/dialogs/forms/Task';
import AbsenceForm from 'src/dialogs/forms/Absence';
import { useSelector } from 'src/store';
import TemplateSubscriptionForm from 'src/dialogs/forms/TemplateSubscription';
import GroupForm from 'src/dialogs/forms/Group';
import ContactSimpleForm from 'src/dialogs/forms/ContactSimple';
import OpenRegisterForm from 'src/dialogs/forms/Register/OpenRegister';
import CloseRegisterForm from 'src/dialogs/forms/Register/CloseRegister';
import 'src/i18n';
import WebIconProvider from './WebIconProvider';
import SupportListener from './features/support/SupportListener';

import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import dayjsWeekOfYear from 'dayjs/plugin/weekOfYear';
import dayjsWeekday from 'dayjs/plugin/weekday';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsWeekOfYear);
dayjs.extend(dayjsIsBetween);
dayjs.extend(dayjsWeekday);
dayjs.extend(dayjsAdvancedFormat);

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

// const jss = create({ plugins: [...jssPreset().plugins] });
const isDev = () => (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const muiEmotionCache = createEmotionCache({
  "key": "mui",
  "prepend": true
});

interface LayoutGroup {
  id: string;
  contact: {
    id: string;
    company_name: string;
  };
  color1_light: string;
  color2_light: string;
  color3_light: string;
  color1_dark: string;
  color2_dark: string;
  color3_dark: string;
  language: string;
  is_active: boolean;
  maintenance: boolean;
  template: {
    id: string;
    prefix: string;
  };
  can_sign_up: boolean;
  terms_and_conditions_url: string | null;
  timezone: string;
  created_at: string;
  updated_at: string;
}

export type GroupColors = {
  color1_light: string;
  color2_light: string;
  color3_light: string;
  color1_dark: string;
  color2_dark: string;
  color3_dark: string;
}

type TApiToken = {
  token: string | null;
  is_device: boolean;
}

export type TAppContext = {
  group: LayoutGroup;
  apiToken: TApiToken | null;
  setApiToken: React.Dispatch<React.SetStateAction<TApiToken>>;
  theme: {
    light: Theme;
    dark: Theme;
  }
}

export const AppContext = createContext<TAppContext | null>(null);

const App: FC = () => {
  useScrollReset();

  const content = useRoutes(routes);
  const preLoginContent = useRoutes(preLoginRoutes);
  const deviceContent = useRoutes(deviceRoutes);
  const frameContent = useRoutes(frameRoutes);

  // const generalGroup = useSelector(state => state.general.group);

  const userId = useSelector(state => state.general.user?.id);

  const settingsRestored = useRef(false);
  const {
    settings,
    saveSettings
  } = useLocalSettings();

  // const { protectedRoute } = useCurrentProtectedRoute(true);

  const [groupColors, setGroupColors] = useState<GroupColors | null>(null);
  const [group, setGroup] = useState<LayoutGroup | null>(null);
  const [error, setError] = useState(false);

  let preferredTheme: 'LIGHT' | 'DARK' = 'LIGHT';
  const [apiToken, setApiToken] = useState({
    token: localStorage.getItem('API_TOKEN'),
    is_device: false
  });

  const linkRedirect = useRef<string | null>(null);
  useEffect(() => {
    const linkBlacklist = ['/', '/logout', '/login'];
    if (!apiToken.token) {
      const linkBlacklisted = !!linkBlacklist.find((val) => val === window.location.pathname);
      if (!linkBlacklisted) {
        linkRedirect.current = window.location.pathname;
      }
    }
  }, [apiToken]);

  useEffect(() => {
    if (group !== null) {
      // REMOVE ANY LATER !!!
      const favicon: any = document.getElementById('favicon');
      if (preferredTheme === 'DARK') {
        favicon.href = imageUrls(group.id).favicon_dark;
      } else {
        favicon.href = imageUrls(group.id).favicon_light;
      }
      if (settings.automaticTheme === true && settingsRestored.current) {
        console.log('setting theme 1');
        saveSettings({
          ...settings,
          theme: preferredTheme
        });
      }
    }
  }, [preferredTheme]);

  //
  useEffect(() => {
    if (settings.automaticTheme === true && settingsRestored.current) {
      console.log('setting theme 2');
      saveSettings({
        ...settings,
        theme: preferredTheme
      });
    }
  }, [settings.automaticTheme]);

  useEffect(() => {
    const settings = restoreSettings();
    if (settings && !settingsRestored.current) {
      saveSettings(settings);
      settingsRestored.current = true;
    }
  }, [])

  useEffect(() => {
    if (settings.theme) {
      document.body.style.colorScheme = settings.theme.toLowerCase();
    }
  }, [settings.theme]);

  useEffect(() => {
    let cname = window.location.host;

    if (isDev()) {
      console.log('===============IS DEVELOPMENT===============');
      cname = 'crm.zakelijkcrm.nl';
      // cname = 'testsalon.zakelijkcrm.nl';
    }

    axios.post<{ group: LayoutGroup }>('general/layout', { cname }, apiConfig.preLoginOptions)
      .then((response) => {
        setGroup(response.data.group);

        // CHANGE ANY TYPE !!!
        const favicon: any = document.getElementById('favicon');
        if (preferredTheme === 'DARK') {
          if (favicon != null && typeof favicon === 'object') {
            favicon.href = imageUrls(response.data.group.contact.id).favicon_dark;
          }
        } else if (favicon != null) {
          favicon.href = imageUrls(response.data.group.contact.id).favicon_light;
        }


        if (response.data.group.color1_light) {
          setGroupColors({
            color1_light: response.data.group.color1_light,
            color2_light: response.data.group.color2_light,
            color3_light: response.data.group.color3_light,
            color1_dark: response.data.group.color1_dark,
            color2_dark: response.data.group.color2_dark,
            color3_dark: response.data.group.color3_dark
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            localStorage.removeItem('API_TOKEN');
            setError(true);
          }
        }
      });
  }, [window.location.host]);

  useEffect(() => {
    if (settings.theme === 'DARK') {
      // document.body.style.backgroundColor = '#1c2025';
      document.body.style.backgroundColor = '#101010';
    }
    if (settings.theme === 'LIGHT') {
      document.body.style.backgroundColor = '#f4f6f8';
    }
  }, [settings.theme]);

  useEffect(() => {
    window.name = window.name ? window.name : `window-${uuidv4()}`;
    localStorage.setItem(window.name, window.location.pathname);
  }, [window.location.pathname]);
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      localStorage.removeItem(window.name)
    });
  }, []);
  
  // Set the default timezone of dayjs (tz) from the group. 
  useEffect(() => {
    if(group){
      dayjs.tz.setDefault(group.timezone);
    }
  }, [group]);

  if (error) {
    if (!isDev()) {
      const domain = window.location.hostname.split('.').slice(-2).join('.');
      window.location.replace(`https://www.${domain}`);
    } else {
      return (
        <h1>An error has occured, try to refresh the page!</h1>
      );
    }
  }
  
  if (!groupColors || !group) {
    return null;
  }

  const theme = createTheme(settings, groupColors);
  const light_theme = createTheme({ ...settings, theme: 'LIGHT' }, groupColors);
  const dark_theme = createTheme({ ...settings, theme: 'DARK' }, groupColors);

  const route_is_frame = window.location.pathname.split('/')[1] === 'frame';

  return (
    <AppContext.Provider
      value={{
        group: group,
        apiToken: apiToken,
        setApiToken: setApiToken,
        theme: {
          light: light_theme,
          dark: dark_theme
        }
      }}
    >
      <WebIconProvider group_id={group.contact.id} group_name={group.contact.company_name} />
      <GlobalStyles />
      <EmotionCacheProvider value={muiEmotionCache}>
        <ThemeProvider theme={theme}>
          {/* <SettingsDrawer /> */}
          {apiToken.is_device && apiToken.token !== null ?
            <CoreCallComponent>
              <DataListener>
                <AsyncCallsComponent>
                  <LocalizationProvider>
                    <LanguageCheck />
                    {deviceContent}
                  </LocalizationProvider>
                </AsyncCallsComponent>
              </DataListener>
            </CoreCallComponent>
            : apiToken.token !== null ?
              route_is_frame ? frameContent : (
                <CoreCallComponent>
                  <DataListener>
                    <AsyncCallsComponent>
                      <LocalizationProvider>
                        <ErrorHandler />
                        <UploadHandler>
                          <DownloadHandler>
                            
                            {!!userId && (
                              <SupportListener userId={userId} />
                            )}
                            
                            <LanguageCheck />
                            <IdleChecker minutes={90} />
                            <OfflineOverlay />
                            <Forms />

                            <TimerContextProvider>
                              <DashboardLayout darkTheme={dark_theme}>
                                {content}
                              </DashboardLayout>
                              <ToastContainer />
                            </TimerContextProvider>
                          </DownloadHandler>
                        </UploadHandler>
                      </LocalizationProvider>
                    </AsyncCallsComponent>
                  </DataListener>
                </CoreCallComponent>
              ) : (
                <>
                  <PreLoginBackground />
                  {preLoginContent}
                </>
              )
          }
        </ThemeProvider>
      </EmotionCacheProvider>
    </AppContext.Provider>
  );
};

const Forms = () => {
  const formsState = useSelector(state => state.forms);

  return (
    <>  
      {formsState.contact.render && <ContactForm />}
      {formsState.appointment.render && <AppointmentForm />}
      {formsState.task.render && <TaskForm />}
      {formsState.absence.render && <AbsenceForm />}
      {formsState.templateSubscription.render && <TemplateSubscriptionForm />}
      {formsState.group.render && <GroupForm />}
      {formsState.openRegister.render && <OpenRegisterForm />}
      {formsState.closeRegister.render && <CloseRegisterForm />}
      {formsState.contactSimple.render && <ContactSimpleForm />}
    </>
  );
}

export default App;