import { KeyboardEvent, useEffect, useRef, useState } from "react";

interface Props {
  rows: any[];
  onSelect: (index: number) => void;
}

const useArrowSelect = ({
  rows,
  onSelect,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  useEffect(() => {
    // on change of index selected, select row and scrollIntoView

    if(selectedIndex !== null){
      if(containerRef.current){
        const selectedChild = containerRef.current.children[selectedIndex];
        if(selectedChild){
          selectedChild.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center', 
            inline: 'center' 
          });
        }
      }
    }

  }, [selectedIndex]);

  useEffect(() => {
    if(selectedIndex && !rows[selectedIndex]){
      setSelectedIndex(null);
    }
  }, [rows]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter'){
      e.preventDefault();
    }

    if(e.key === 'Enter'){
      if(typeof selectedIndex === 'number' && rows[selectedIndex]){
        // handleSelectContact(rows[indexSelected]);
        onSelect(selectedIndex);
      }
    }
    if(e.key === 'ArrowDown'){
      if(selectedIndex === null){
        setSelectedIndex(0);
      }else if (selectedIndex + 1 < rows.length){
        setSelectedIndex(prev => prev! + 1);
      }
    }
    if(e.key === 'ArrowUp'){
      if(selectedIndex === null){
        setSelectedIndex(0);
      }else if (selectedIndex > 0){
        setSelectedIndex(prev => prev! - 1);
      }
    }
  }

  const reset = () => {
    setSelectedIndex(null);
  }

  return {
    onKeyDown: handleKeyDown,
    containerRef: containerRef,
    reset,
    selectedIndex
  }

}
export default useArrowSelect;