import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { CustomField as TCustomField } from 'src/types/api/customfield'; 

const initialState: TCustomField[] = [];

const slice = createSlice({
  name: 'customfields',
  initialState,
  reducers: {
    setCustomFields(state: TCustomField[], action: PayloadAction<TCustomField[]>){
      return action.payload;
    },
  }
})

export const { reducer } = slice;

export const setCustomFields = (payload: TCustomField[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCustomFields(payload));
}

export default slice;