import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, useDispatch } from 'src/store';
import type { ContactSmall as TContact } from 'src/types/api/contact';

// type TContact = {
//   id: string
// }

const initialState: TContact[] = [];

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts(state: TContact[], action: PayloadAction<TContact[]>){
      return action.payload;
    },
    createContact(state: TContact[], action: PayloadAction<TContact>){
      state.push(action.payload);
    },
    updateContact(state: TContact[], action: PayloadAction<TContact>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteContact(state: TContact[], action: PayloadAction<TContact>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setContacts = (data: TContact[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setContacts(data));
}

export const createContact = (data: TContact): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createContact(data));
}

export const updateContact = (data: TContact): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateContact(data));
}

export const deleteContact = (data: TContact): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteContact(data));
}

// Maybe axios calls in actions ???
// export const fetchContacts = (): AppThunk => async (dispatch) => {
//   axios.get('contacts').then((response) => dispatch(setContacts(response.data)))
// }

export default slice;