import { StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextField, Fade, Paper, TextFieldProps, Popover, Button } from '@mui/material';
import { useState, useRef } from 'react';
import { makeStyles } from 'src/theme/makeStyles';
import { useTranslation } from 'src/i18n';
import { roundTimeToNearestXMinutes } from './utils';
import StaticDatePickerPopover from './StaticDatePickerPopover';

const useStyles = makeStyles()(theme => ({
  pickerRoot: {
    position: 'absolute',
    top: 48,
    left: '50%',
    width: 105,
    // height: 500,
    display: 'flex',
    transform: 'translateX(-50%)',
    zIndex: theme.zIndex.modal
  }
}));

interface Props {
  value: dayjs.Dayjs | null;
  onChange: (value: dayjs.Dayjs | null) => void;
  error?: boolean;
  onError?: (value: boolean) => void;

  staticDatePickerProps?: Partial<StaticDatePickerProps<any, any>>;
  textFieldProps?: Partial<TextFieldProps>;
  disabled?: boolean;
}

const DatePicker = ({
  value,
  onChange,
  error = false,
  onError,
  staticDatePickerProps,
  textFieldProps,
  disabled = false
}: Props) => {
  const { classes, theme } = useStyles();
  const {t} = useTranslation();

  const [pickerOpen, setPickerOpen] = useState(false);

  const textFieldRef = useRef<HTMLDivElement | null>(null);

  const handleClear = () => {
    onChange(null);
    setPickerOpen(false);
  }

  const handleChange = (val: dayjs.Dayjs) => {
    let newValue = val.clone();
    if(!value){
      newValue = roundTimeToNearestXMinutes(newValue);
    }
    onChange(newValue);
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%'
      }}
    >
      <TextField 
        ref={textFieldRef}
        value={value && value.isValid() ? value.format('ddd ll') : ''}
        label={`${t('lang.date')}`}
        variant='standard'
        // onFocus={() => setPickerOpen(true)}
        onClick={() => !disabled && setPickerOpen(true)}
        autoComplete='off'
        // InputLabelProps={{
        //   shrink: true
        // }}
        focused={pickerOpen}
        inputProps={{
          readOnly: true,
          placeholder: ``,
          style: { cursor: disabled ? 'default' : 'pointer' }
        }}
        error={error}
        style={(textFieldProps && !textFieldProps.fullWidth) ? { width: 128 } : {}}
        disabled={disabled}
        {...textFieldProps}
      />
      <StaticDatePickerPopover
        open={pickerOpen}
        anchorEl={textFieldRef.current}
        onClose={() => setPickerOpen(false)}
        value={value}
        onChange={handleChange}
        onClear={handleClear}
        onError={onError}
        staticDatePickerProps={staticDatePickerProps}
      />
    </div>
  )
}
export default DatePicker;