import { useAxiosOptions } from "src/hooks/general/useAxios";
import { deleteAvatarRequest, uploadAvatarRequest } from "src/requests/avatars";
import { useDispatch, useSelector } from "src/store";
import { ContactSmall } from "src/types/api/contact";
import { UserSmall } from "src/types/api/user";
import BaseAvatarEntityDetails from "./BaseAvatarEntityDetails";
import { dataUrlToBlob } from '../ImagePicker';

type Entity = {
  contact?: ContactSmall;
  user?: UserSmall;
}

interface Props {
  entity: Entity;
  secondaryProperties: (string | undefined)[];
  enableAvatarUpdate?: boolean;
  showTags?: boolean;
}

const AvatarEntityDetails = ({
  entity,
  secondaryProperties,
  enableAvatarUpdate = false,
  showTags = false
}: Props) => {
  const avatars = useSelector(state => state.misc.avatars);
  const dispatch = useDispatch();
  const axiosOptions = useAxiosOptions();

  const contact = entity.contact ? entity.contact : entity.user ? entity.user.contact : null;;

  if (!contact) {
    console.error('No entity passed!');
    return null;
  }

  const handleAvatarChange = (dataUrl: string | null) => {
    if(dataUrl){
      const imageFilename = `avatar-${contact.id}.jpeg`;
      const imageBlob = dataUrlToBlob(dataUrl, imageFilename);
      if(imageBlob){
          uploadAvatarRequest({ 
            id: contact.id, 
            image: imageBlob, 
            imageName: imageFilename 
          }, dispatch, axiosOptions.apiConfig)
          .catch(err => console.log(err));
      }
    }else{
      deleteAvatarRequest(contact.id, dispatch, axiosOptions.apiConfig)
      .catch(err => console.log(err));
    }
  }

  const avatar = avatars[contact.id] ? avatars[contact.id] : undefined;

  return (
    <BaseAvatarEntityDetails 
      enableAvatarUpdate={enableAvatarUpdate}
      name={contact.name}
      secondaryProperties={secondaryProperties}
      onAvatarChange={handleAvatarChange}
      avatarDataUrl={avatar ? `data:image/jpeg;base64,${avatar}` : undefined}
      tags={showTags ? {
        ids: contact.tags,
        category: 'contact'
      } : undefined}
    />
  )
}
export default AvatarEntityDetails;