import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';
import { useTranslation } from 'src/i18n';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    color: theme.palette.text.primary
  }
}));

const Logout = () => {
  const { classes } = useStyles();
  const {t} = useTranslation();

  return (
    <Tooltip title={`${t("lang.logout")}`}>
      <IconButton
        className={classes.iconButton}
        component={RouterLink}
        to="/logout"
        size="large">
        <SvgIcon>
          <ExitToAppIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export default Logout;