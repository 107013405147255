import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Permission, PermissionCategory } from 'src/types/api/permission';

type TState = {
  categories: PermissionCategory[],
  rules: Permission[]
}

const initialState: TState = {
  categories: [],
  rules: []
};

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions(state: TState, action: PayloadAction<Permission[]>){
      state.rules = action.payload;
    },
    createPermission(state: TState, action: PayloadAction<Permission>){
      state.rules.push(action.payload);
    },
    updatePermission(state: TState, action: PayloadAction<Permission>){
      state.rules = state.rules.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deletePermission(state: TState, action: PayloadAction<Permission>){
      state.rules = state.rules.filter((val) => val.id !== action.payload.id);
    },

    updatePermissions(state: TState, action: PayloadAction<Permission[]>){
      let newRules = [...state.rules].map((statePermission) => {
        let newOne = statePermission;
        action.payload.map((permission) => {
          if(statePermission.id === permission.id){
            newOne = permission;
          }
        });
        return newOne;
      });
      state.rules = newRules;
    },

    setPermissionCategories(state: TState, action: PayloadAction<PermissionCategory[]>){
      state.categories = action.payload;
    },
    createPermissionCategory(state: TState, action: PayloadAction<PermissionCategory>){
      state.categories.push(action.payload);
    },
    updatePermissionCategory(state: TState, action: PayloadAction<PermissionCategory>){
      state.categories = state.categories.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deletePermissionCategory(state: TState, action: PayloadAction<PermissionCategory>){
      state.categories = state.categories.filter((val) => val.id !== action.payload.id);
    },
  }
})

export const { reducer } = slice;

export const setPermissions = (data: Permission[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPermissions(data));
}
export const createPermission = (data: Permission): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createPermission(data));
}
export const updatePermission = (data: Permission): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePermission(data));
}
export const deletePermission = (data: Permission): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deletePermission(data));
}

export const updatePermissions = (data: Permission[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePermissions(data));
}

export const setPermissionCategories = (data: PermissionCategory[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPermissionCategories(data));
}
export const createPermissionCategory = (data: PermissionCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createPermissionCategory(data));
}
export const updatePermissionCategory = (data: PermissionCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePermissionCategory(data));
}
export const deletePermissionCategory = (data: PermissionCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deletePermissionCategory(data));
}


export default slice;