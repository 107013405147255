import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';

export type TabValue = {
  id: string;
  title: string;
  urlParam: string;
  permissions?: string[];
  alert?: {
    text: string;
    type: 'warning' | 'error'
  }
}

export type TopbarState = {
  title: string;
  tabs: TabValue[];
  routePattern?: string;
  edit?: {
    onClick: () => void;
    disabled?: boolean;
    permissions?: string[];
  },
  delete?: {
    onClick: () => void;
    disabled?: boolean;
    permissions?: string[];
  }
  merge?: {
    onClick: () => void;
    disabled?: boolean;
    permissions?: string[];
  },
  hidden: boolean;
}

const DEFAULT_TOPBAR_VALUES: TopbarState = {
  title: '',
  tabs: [],
  routePattern: undefined,
  hidden: false
}

const initialState: TopbarState = DEFAULT_TOPBAR_VALUES;

const slice = createSlice({
  name: 'topbar',
  initialState,
  reducers: {
    updateTopbarValue(state: TopbarState, action: PayloadAction<Partial<TopbarState>>){
      return {
        ...state,
        ...action.payload
      }
    },
    resetTopbarValues(){
      return DEFAULT_TOPBAR_VALUES;
    }
  }
})

export const { reducer } = slice;

export const updateTopbarValue = (data: Partial<TopbarState>): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTopbarValue(data));
}

export const resetTopbarValues = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetTopbarValues());
}

export default slice;