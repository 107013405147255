import { MenuItem, StandardTextFieldProps, TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from 'src/i18n';
import { useSelector } from "src/store";

interface Props {
  value: string | null;
  onChange: (template_id: string) => void;
  textFieldProps: Partial<StandardTextFieldProps>
}

const TemplateSelect = ({
  value,
  onChange,
  textFieldProps
}: Props) => {
  const {t} = useTranslation();
  const templates = useSelector(state => state.lists.templates);

  const selectedValue = useMemo(() => {
    const selectedTemplate = templates.find(template => template.id === value);
    if(selectedTemplate){
      return selectedTemplate.id;
    }
    return '';
  }, [templates, value]);

  if(!templates){
    // console.error('NO TEMPLATES !!!');
    return null;
  }

  return (
    <TextField
      label={`${t('lang.template')}`}
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      variant='standard'
      select
      {...textFieldProps}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {templates.map((template) => (
        <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
      ))}
    </TextField>
  )
}
export default TemplateSelect;