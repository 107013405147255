import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { WorkingHours, WorkingHoursColor } from 'src/types/api/workingHours';
import dayjs from 'dayjs';

// const initialState: WorkingHours[] = [];

// YYYY-MM-DD

interface WorkingHoursState {
  workingHours: {
    [startOfWeek: string]: WorkingHours[];
  },
  workingHoursColors: WorkingHoursColor[];
}

const initialState: WorkingHoursState = {
  workingHours: {},
  workingHoursColors: []
};

const slice = createSlice({
  name: 'workingHours',
  initialState,
  reducers: {
    addWorkingHoursWeek(state: WorkingHoursState, action: PayloadAction<dayjs.Dayjs[]>){
      action.payload.map((dayjsObj) => {
        const startOfWeekStr = dayjsObj.clone().startOf('week').format('YYYY-MM-DD');
        if(typeof state.workingHours[startOfWeekStr] === 'undefined'){
          state.workingHours[startOfWeekStr] = [];
        }
      });
    },
    removeWorkingHoursWeek(state: WorkingHoursState, action: PayloadAction<dayjs.Dayjs[]>){
      action.payload.map((dayjsObj) => {
        const startOfWeekStr = dayjsObj.clone().startOf('week').format('YYYY-MM-DD');
        delete(state.workingHours[startOfWeekStr]);
      });
    },
    createWorkingHours(state: WorkingHoursState, action: PayloadAction<WorkingHours> ){
      const startOfWeekStr = dayjs(action.payload.start_date).startOf('week').format('YYYY-MM-DD');
      if(Array.isArray(state.workingHours[startOfWeekStr])){
        const found = state.workingHours[startOfWeekStr].find((val) => val.id === action.payload.id);
        if(!found){
          state.workingHours[startOfWeekStr].push(action.payload);
        }
      }
    },
    createSeveralWorkingHours(state: WorkingHoursState, action: PayloadAction<WorkingHours[]>){
      action.payload.map((workingHours) => {
        const startOfWeekStr = dayjs(workingHours.start_date).startOf('week').format('YYYY-MM-DD');
        if(Array.isArray(state.workingHours[startOfWeekStr])){
          const found = state.workingHours[startOfWeekStr].find((val) => val.id === workingHours.id);
          if(!found){
            state.workingHours[startOfWeekStr].push(workingHours);
          }
        }
      })
    },
    updateWorkingHours(state: WorkingHoursState, action: PayloadAction<WorkingHours> ){
      const startOfWeekStr = dayjs(action.payload.start_date).startOf('week').format('YYYY-MM-DD');
      if(Array.isArray(state.workingHours[startOfWeekStr])){
        state.workingHours[startOfWeekStr] = state.workingHours[startOfWeekStr].map((val) => val.id !== action.payload.id ? val : action.payload);
      }
    },
    updateSeveralWorkingHours(state: WorkingHoursState, action: PayloadAction<WorkingHours[]> ){
      action.payload.map((workingHours) => {
        const startOfWeekStr = dayjs(workingHours.start_date).startOf('week').format('YYYY-MM-DD');
        if(Array.isArray(state.workingHours[startOfWeekStr])){
          state.workingHours[startOfWeekStr] = state.workingHours[startOfWeekStr].map((val) => val.id !== workingHours.id ? val : workingHours);        
        }
      });
    },
    deleteWorkingHours(state: WorkingHoursState, action: PayloadAction<WorkingHours> ){
      const startOfWeekStr = dayjs(action.payload.start_date).startOf('week').format('YYYY-MM-DD');
      if(Array.isArray(state.workingHours[startOfWeekStr])){
        state.workingHours[startOfWeekStr] = state.workingHours[startOfWeekStr].filter((val) => val.id !== action.payload.id);
      }
    },
    deleteSeveralWorkingHours(state: WorkingHoursState, action: PayloadAction<WorkingHours[]> ){
      action.payload.map((workingHours) => {
        const startOfWeekStr = dayjs(workingHours.start_date).startOf('week').format('YYYY-MM-DD');
        if(Array.isArray(state.workingHours[startOfWeekStr])){
          state.workingHours[startOfWeekStr] = state.workingHours[startOfWeekStr].filter((val) => val.id !== workingHours.id);
        }
      });
    },
    clearWorkingHours(state: WorkingHoursState){
      state.workingHours = initialState.workingHours;
    },
    // WorkingHoursColors
    setWorkingHoursColors(state: WorkingHoursState, action: PayloadAction<WorkingHoursColor[]> ){
      state.workingHoursColors = action.payload;
    },
    createWorkingHoursColor(state: WorkingHoursState, action: PayloadAction<WorkingHoursColor> ){
      state.workingHoursColors.push(action.payload);
    },
    updateWorkingHoursColor(state: WorkingHoursState, action: PayloadAction<WorkingHoursColor> ){
      state.workingHoursColors = state.workingHoursColors.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteWorkingHoursColor(state: WorkingHoursState, action: PayloadAction<WorkingHoursColor> ){
      state.workingHoursColors = state.workingHoursColors.filter((val) => val.id !== action.payload.id);
    }
    

  }
})

export const { reducer } = slice;

export const addWorkingHoursWeek = (data: dayjs.Dayjs[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addWorkingHoursWeek(data));
}
export const removeWorkingHoursWeek = (data: dayjs.Dayjs[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeWorkingHoursWeek(data));
}

export const createWorkingHours = (data: WorkingHours): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createWorkingHours(data));
}
export const createSeveralWorkingHours = (data: WorkingHours[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createSeveralWorkingHours(data));
}
export const updateWorkingHours = (data: WorkingHours): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateWorkingHours(data));
}
export const updateSeveralWorkingHours = (data: WorkingHours[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSeveralWorkingHours(data));
}
export const deleteWorkingHours = (data: WorkingHours): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteWorkingHours(data));
}
export const deleteSeveralWorkingHours = (data: WorkingHours[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteSeveralWorkingHours(data));
}
export const clearWorkingHours = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearWorkingHours());
}

// WorkingHoursColors

export const setWorkingHoursColors = (data: WorkingHoursColor[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setWorkingHoursColors(data));
}
export const createWorkingHoursColor = (data: WorkingHoursColor): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createWorkingHoursColor(data));
}
export const updateWorkingHoursColor = (data: WorkingHoursColor): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateWorkingHoursColor(data));
}
export const deleteWorkingHoursColor = (data: WorkingHoursColor): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteWorkingHoursColor(data));
}

export default slice;