import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "src/i18n";
import { OriginalLabel } from 'src/components/general/Labels';
import { Appointment } from "src/types/api/appointment";
import { useMemo } from "react";

const NO_SHOW_COLOR = '#000000';

interface Props {
  appointment: Appointment | null;
  isNoShow: boolean;
}

const StatusLabel = ({
  appointment,
  isNoShow
}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const colorCode = useMemo(() => {
    if(isNoShow)
      return NO_SHOW_COLOR;
    return appointment ? appointment.status_color : theme.palette.error.main;
  }, [appointment, isNoShow]);

  return (
    <Typography variant='body2'>
      {t('lang.status')}:
      <span style={{ marginLeft: 5, marginRight: 5 }}>
        <OriginalLabel
          colorCode={colorCode}
        >
          {isNoShow ? t('lang.no_show') : appointment ? appointment.status : t('lang.open')}
        </OriginalLabel>
      </span>
    </Typography>
  )
}
export default StatusLabel;