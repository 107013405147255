import { Alert, AlertColor, LinearProgress, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from 'src/i18n';
import useSubscriptionExpired from 'src/hooks/general/useSubscriptionExpired';
import { setPaywallOpen } from "src/slices/misc";
import { useDispatch } from "src/store";
import { makeStyles } from "src/theme/makeStyles";

const useStyles = makeStyles()(theme => ({
  alertRoot: {
    padding: `0px 6px`,
    textAlign: 'center',
    cursor: 'pointer'
  },
  textRoot: {
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

interface Props {
  fullyOpen: boolean;
}

const SubscriptionExpiryAlert = ({
  fullyOpen
}: Props) => {
  const { classes } = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { daysUntilExpiry } = useSubscriptionExpired();

  const handleAlertClick = () => {
    dispatch(setPaywallOpen(true));    
  }

  const severity: AlertColor | undefined = useMemo(() => {
    if(daysUntilExpiry){
      if(daysUntilExpiry > 7){
        return 'success';
      }
      if(daysUntilExpiry > 3){
        return 'warning';
      }
      return 'error';
    }
  }, [daysUntilExpiry]);

  if(!daysUntilExpiry){
    return null;
  }

  return (
    <Alert 
      classes={{ root: classes.alertRoot }}
      severity={severity}
      // onClick={handleAlertClick}
    >
      {fullyOpen ? (
        <div className={classes.textRoot}>
          {/* Nog {daysUntilExpiry} dagen proefperiode */}
          {daysUntilExpiry <= 1 ? (
            <>{t('lang.still_day_trial', { day: `${daysUntilExpiry}` })}</>
          ) : (
            <>{t('lang.still_days_trial', { days: `${daysUntilExpiry}` })}</>
          )}
        </div>
      ) : (
        <>{daysUntilExpiry}d</>
      )}
    </Alert>
  )
}
export default SubscriptionExpiryAlert;