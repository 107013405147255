import { createTimeTracker, deleteTimeTracker, updateTimeTracker } from 'src/slices/lists/timetrackers';
import type { AppDispatch } from 'src/store/index';
import type { TimeTracker } from 'src/types/api/timetracker';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  //TIME_TRACKER
  channel.listen('.create_time_tracker', (data: any) => {
    const timeTracker = data.data.response as TimeTracker;
    dispatch(createTimeTracker(timeTracker));
  });
  channel.listen('.update_time_tracker', (data: any) => {
    const timeTracker = data.data.response as TimeTracker;
    dispatch(updateTimeTracker(timeTracker));
  });
  channel.listen('.delete_time_tracker', (data: any) => {
    const timeTracker = data.data.response as TimeTracker;
    dispatch(deleteTimeTracker(timeTracker));
  });
}