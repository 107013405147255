import dayjs from 'dayjs';
import { SPLIT_BY_MINUTES } from './constants';

export const roundTimeToNearestXMinutes = (val: dayjs.Dayjs, split_by_minutes = SPLIT_BY_MINUTES): dayjs.Dayjs => {
  if(!split_by_minutes){
    return val;
  }
  
  let newValue = val.clone();

  let minute_options_array: number[] = [];
  let loop_mins = 0;
  while(loop_mins < 60){
    minute_options_array.push(loop_mins);
    loop_mins += split_by_minutes;
  }

  const setToMinute = minute_options_array.find((minute) => {
    if(newValue.minute() <= minute){
      return true;
    }
    return false
  });

  if(typeof setToMinute !== 'undefined'){
    newValue = newValue.minute(setToMinute);
  }else{
    if(newValue.hour() < 23){
      newValue = newValue.minute(0);
      newValue = newValue.add(1, 'hour');
    }else{
      newValue = newValue.minute(minute_options_array[minute_options_array.length - 1]);
    }
  }

  return newValue;
}