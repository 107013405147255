import TimePicker from './components/TimePicker';
import DatePicker from './components/DatePicker';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material';

interface Props {
  value: dayjs.Dayjs | null;
  onChange: (value: dayjs.Dayjs | null) => void;
  error?: boolean;
  onError?: (value: boolean) => void;
  disabled?: boolean;
}

const DateTimePicker = ({
  value,
  onChange,
  error = false,
  onError,
  disabled = false
}: Props) => {
  const theme = useTheme();

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <DatePicker 
          value={value}
          onChange={onChange}
          error={error}
          onError={onError}
          disabled={disabled}
        />
      </div>
      <div style={{ marginLeft: theme.spacing(3) }}>
        <TimePicker 
          value={value}
          onChange={onChange}
          error={error}
          onError={onError}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default DateTimePicker;