import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { MessageTemplate } from 'src/types/api/messages';

const initialState: MessageTemplate[] = [];

const slice = createSlice({
  name: 'messageTemplates',
  initialState,
  reducers: {
    setMessageTemplates(state: MessageTemplate[], action: PayloadAction<MessageTemplate[]>){
      return action.payload;
    },
    createMessageTemplate(state: MessageTemplate[], action: PayloadAction<MessageTemplate>){
      state.push(action.payload);
    },
    updateMessageTemplate(state: MessageTemplate[], action: PayloadAction<MessageTemplate>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteMessageTemplate(state: MessageTemplate[], action: PayloadAction<MessageTemplate>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setMessageTemplates = (data: MessageTemplate[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMessageTemplates(data));
}

export const createMessageTemplate = (data: MessageTemplate): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createMessageTemplate(data));
}

export const updateMessageTemplate = (data: MessageTemplate): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateMessageTemplate(data));
}

export const deleteMessageTemplate = (data: MessageTemplate): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteMessageTemplate(data));
}

export default slice;