import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from 'src/i18n';
import { makeStyles } from "src/theme/makeStyles";

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'inline-flex', 
    alignItems: 'center'
  },
  innerRoot: {
    marginLeft: 'auto'
  },
  submitButton: {
    marginLeft: 5
  },
  submitButtonDelete: {
    backgroundColor: theme.palette.error.main
  }
}));

interface Props {
  onClose?: () => void;
  onSubmit?: () => void;
  submitButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  isDelete?: boolean;
}

const SubmitDeleteButtons = ({
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps,
  submitButtonTitle,
  cancelButtonTitle,
  isDelete = false
}: Props) => {
  const {t} = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <Button
          color='inherit'
          variant="text"
          onClick={onClose}
          {...cancelButtonProps}
        >
          {cancelButtonTitle ? cancelButtonTitle : t('lang.cancel')}
        </Button>
        <Button
          className={clsx(classes.submitButton, isDelete && classes.submitButtonDelete)}
          color="primary"
          variant="contained"
          onClick={onSubmit}
          {...submitButtonProps}
        >
          {submitButtonTitle ? submitButtonTitle : isDelete ? `${t('lang.delete')}` : t('lang.save') }
        </Button>
      </div>
    </div>
  )
}
export default SubmitDeleteButtons;