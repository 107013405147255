import { StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers';
import { Button, Popover, TextField, useTheme, PopoverProps } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from 'src/i18n';

interface Props {
  open: boolean;
  onClose: () => void;
  value: dayjs.Dayjs | null;
  onChange: (date: dayjs.Dayjs) => void;
  anchorEl: Element | null;
  onError?: (value: boolean) => void;
  onClear?: () => void;
  // enableAllYears?: boolean;
  popoverProps?: Partial<PopoverProps>;
  staticDatePickerProps?: Partial<StaticDatePickerProps<any, any>>;
  secondaryContent?: JSX.Element;
}

const StaticDatePickerPopover = ({
  open,
  onClose,
  value,
  onChange,
  anchorEl,
  onError,
  onClear,
  // enableAllYears = false,
  popoverProps,
  staticDatePickerProps,
  secondaryContent
}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...popoverProps}
    >
      <div>
        <StaticDatePicker
          value={value}
          onChange={onChange}
          onAccept={onClose}
          renderInput={(params) => <TextField {...params} />}
          // onClose={onClose} // CHECKEN !!!
          displayStaticWrapperAs='desktop'
          onError={(reason, value) => onError && onError(!!value)}
          // shouldDisableYear={(dayjsObj: dayjs.Dayjs) => {
          //   if(enableAllYears){
          //     return false;
          //   }
          //   return dayjsObj.year() < 2005 || dayjsObj.year() > 2050
          // }}
          {...staticDatePickerProps}
        />
        {!!onClear && (
          <div 
            style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              width: '100%',
              marginBottom: theme.spacing(1)
            }}
          >
            <Button onClick={onClear}>
              {t('lang.clear')}
            </Button>
          </div>
        )}
        {!!secondaryContent && secondaryContent}
      </div>
    </Popover>
  )
}
export default StaticDatePickerPopover;