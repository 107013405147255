import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'src/i18n';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  titleText?: string;
  contentText?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideSubmitButton?: boolean;
}

const ConfirmDialog = ({
  open,
  onCancel,
  onConfirm,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  hideSubmitButton = false
}: Props) => {
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>
        {titleText ? titleText : t('lang.are_you_sure')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText ? contentText : t('lang.are_you_sure')}
        </DialogContentText>

        <Box mt={3} style={{ width: '100%', display: 'flex' }}>
          <div style={{ marginLeft: 'auto' }}>
            
            <Button autoFocus onClick={onCancel} color='inherit'>
              {cancelButtonText ? cancelButtonText : t('lang.cancel')}
            </Button>
            {!hideSubmitButton && (
              <Button onClick={onConfirm} variant='contained' color='error'>
                {confirmButtonText ? confirmButtonText : t('lang.confirm')}
              </Button>
            )}
          </div>
        </Box>

      </DialogContent>
    </Dialog>
  )
}
export default ConfirmDialog;