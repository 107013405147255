import { AxiosRequestConfig } from "axios";
import axios from 'axios';

interface GetPositionResponseItem {
  id: string
  position: number | null;
  total_row_count: number;
  filtered_row_count: number;
  metadata: { [key: string]: string } | null;
}

export interface GetPositionRequestData {
  values: {
    id: string;
    type: string;
    filters?: { [key: string]: string; };
    sort?: string;
    metadata?: { [key: string]: string };
  }[];
}

export const getPositionRequest = (
  data: GetPositionRequestData,
  config: AxiosRequestConfig
): Promise<GetPositionResponseItem[]> => {
  return new Promise<GetPositionResponseItem[]>((resolve, reject) => {
    axios.post<GetPositionResponseItem[]>('position', data, config)
    .then(response => resolve(response.data))
    .catch(err => reject(err));
  });
}
