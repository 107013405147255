import { Icon as MDIconReact } from "@mdi/react";
import type { IconProps } from "@mdi/react/dist/IconProps";
import * as MDIcons from "@mdi/js";



export type MDIcon = keyof typeof MDIcons;

interface Props {
  icon: MDIcon;
  iconProps?: Partial<IconProps>;
  className?: string;
  style?: React.CSSProperties;
}

const DynamicIcon = ({
  icon: iconname,
  iconProps,
  style,
  className
}: Props) => {
  const icon = MDIcons[iconname];

  if(!icon){ return null; }

  return (
    <div style={style}>
      <MDIconReact 
        className={className}
        path={icon}
        {...iconProps}
      />
    </div>
  )
}

export default DynamicIcon;