import { useEffect, useRef, useState } from "react";
import { emailCheck } from "src/requests/system";
import { apiConfig } from "src/config";

const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const useEmailAvailable = (email: string, maxSecondsBetweenKeystrokes = 0.25): boolean => {
  const [emailAvailable, setEmailAvailable] = useState(true);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // check if email is valid email addre

    if(email.length && validateEmail(email)){
      if(timeoutRef.current){
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        emailCheck(email, apiConfig.preLoginOptions)
          .then(() => setEmailAvailable(true))
          .catch(() => setEmailAvailable(false));
      }, maxSecondsBetweenKeystrokes * 1000);
    }else{
      setEmailAvailable(true);
    }
  }, [email])

  return emailAvailable;
}
export default useEmailAvailable;