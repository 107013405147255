import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { GeneralLanguage } from 'src/types/api/language';

const initialState: GeneralLanguage[] = [];

const slice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setGeneralLanguages(state: GeneralLanguage[], action: PayloadAction<GeneralLanguage[]>){
      return action.payload;
    },
    createGeneralLanguage(state: GeneralLanguage[], action: PayloadAction<GeneralLanguage>){
      state.push(action.payload);
    },
    deleteGeneralLanguage(state: GeneralLanguage[], action: PayloadAction<GeneralLanguage>){
      return state.filter((val) => val.locale !== action.payload.locale); //locale ?!?!??!
    },
  }
})

export const { reducer } = slice;

export const setGeneralLanguages = (payload: GeneralLanguage[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGeneralLanguages(payload));
}

export const createGeneralLanguages = (payload: GeneralLanguage): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createGeneralLanguage(payload));
}

export const deleteGeneralLanguage = (payload: GeneralLanguage): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteGeneralLanguage(payload));
}

export default slice;