import { createFile, deleteFile, updateFile } from 'src/slices/lists/files';
import type { AppDispatch } from 'src/store/index';
import type { File } from 'src/types/api/file';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_file', (data: any) => {
    const file = data.data.response as File;
    dispatch(createFile(file));
  });
  channel.listen('.update_file', (data: any) => {//
    const file = data.data.response as File;
    dispatch(updateFile(file));
  });
  channel.listen('.delete_file', (data: any) => {//
    const file = data.data.response as File;
    dispatch(deleteFile(file));
  });
}