
import CloseIcon from '@mui/icons-material/Close';
import ShrinkIcon from '@mui/icons-material/CloseFullscreen';
import {
  Alert,
  AlertProps,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ButtonProps as MuiButtonProps,
  DialogProps as MuiDialogProps,
  Typography,
  darken
} from '@mui/material';
import { Children, ReactChild, ReactNode } from 'react';
import useIsMobile from 'src/hooks/general/useIsMobile';
import { useTranslation } from 'src/i18n';
import { makeStyles } from 'src/theme/makeStyles';
import SubmitDeleteButtons from './SubmitDeleteButtons';
import useAlertTransitionState from './useAlertTransitionState';
import StatusLabel, { StatusLabelProps } from './StatusLabel';

export const TRANSITION_SECS = 0.5;

const ALERT_OPEN_HEIGHT = 50;
const SIDEBAR_OPEN_WIDTH = 250;

const useStyles = makeStyles<{ 
  ALERT_HEIGHT: number; 
  SIDEBAR_WIDTH: number; 
  alertOpen: boolean; 
  defaultSpacing: number;
}>()((theme, { ALERT_HEIGHT, SIDEBAR_WIDTH, alertOpen, defaultSpacing }) => ({
  duotoneRoot: {
    height: `calc(100% - ${ALERT_HEIGHT}px)`, 
    position: 'absolute', 
    right: 0, 
    top: ALERT_HEIGHT, 
    width: SIDEBAR_WIDTH,
    transition: `height ${TRANSITION_SECS}s, top ${TRANSITION_SECS}s`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.custom.backgroundDefaultduo
  },
  alertRoot: {
    marginTop: alertOpen ? 0 : -ALERT_OPEN_HEIGHT,
    minHeight: ALERT_OPEN_HEIGHT,
    maxHeight: ALERT_OPEN_HEIGHT, 
    // background: theme.custom.backgroundDark,
    transition: `margin-top ${TRANSITION_SECS}s`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogContentRoot: {
    padding: 0
  },
  dialogTitleRoot: {
    padding: theme.spacing(defaultSpacing)
  },
  closeBtn: {
    position: 'absolute',
    right: 0, 
    top: 0
  },
  paperRootMobile: {
    width: 'calc(100% - 24px)', // - theme.spacing(3) ?
    margin: 12 // theme.spacing (1.5) ??
  },
  paperRootMobileFullscreen: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    margin: 0,
    borderRadius: 0
  }
}

));

export interface AlertType {
  open: boolean;
  startComponent?: ReactNode;
  title?: string;
  props?: Partial<AlertProps>;
}

export interface DuotoneDialogProps {
  open: boolean;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  alert?: AlertType | undefined;
  children: ReactChild | ReactChild[];
  onClose?: () => void;
  onSubmit?: () => void;
  submitButtonProps?: MuiButtonProps;
  cancelButtonProps?: MuiButtonProps;
  disableOutsideClick?: boolean;
  dialogProps?: Partial<MuiDialogProps>
  avatarComponent?: ReactNode | ReactNode[];
  titleLeftComponent?: ReactNode;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  isDelete?: boolean;
  duotoneWidth?: number;
  isLoading?: boolean;
  disableMobileFullscreen?: boolean;
  statusLabel?: StatusLabelProps;
}

const DuotoneDialog = ({
  open,
  title,
  subtitle,
  alert,
  children,
  onClose,
  onSubmit,
  submitButtonProps,
  cancelButtonProps,
  disableOutsideClick = false,
  dialogProps,
  avatarComponent,
  titleLeftComponent,
  submitButtonTitle,
  cancelButtonTitle,
  isDelete,
  duotoneWidth = 350,
  isLoading = false,
  disableMobileFullscreen = false,
  statusLabel
}: DuotoneDialogProps) => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  const delayedAlert = useAlertTransitionState(alert);
  const alertOpen = delayedAlert ? delayedAlert.open : false;

  const childCount = Children.count(children);
  // const duotone = childCount === 2 && !isMobile;
 const duotone = childCount === 2 && !isMobile;

  const SIDEBAR_WIDTH = duotone ? (duotoneWidth ? duotoneWidth : SIDEBAR_OPEN_WIDTH) : 0;
  const ALERT_HEIGHT = alertOpen ? ALERT_OPEN_HEIGHT : 0;

  const defaultSpacing = isMobile ? 2 : 3;
  const { classes, theme } = useStyles({ ALERT_HEIGHT, SIDEBAR_WIDTH, alertOpen, defaultSpacing });

  const submitDeleteButtonProps = { onClose, onSubmit, cancelButtonProps, isDelete, submitButtonProps, submitButtonTitle, cancelButtonTitle };

  const getDialogClasses = () => {
    if(isMobile){
      if(!disableMobileFullscreen){
        return { paper: classes.paperRootMobileFullscreen };
      }
      return { paper: classes.paperRootMobile };
    }
    return;
  }

  if(childCount !== 1 && childCount !== 2){
    console.error(`Component should have 1 (normal) or 2(duo-toner) children!\nComponent has ${childCount} children...`);
    return null;
  }

  return (
    <Dialog
      // classes={{ paper: (isMobile && !disableMobileFullscreen) ? classes.paperRootMobile : undefined}}
      classes={getDialogClasses()}
      
      open={open}
      onClose={() => {
        if(!disableOutsideClick){
          onClose && onClose();
        }
      }}
      maxWidth={duotone ? 'lg' : 'sm'}
      fullWidth
      {...dialogProps}
    >
      {duotone && (
        <Box 
          className={classes.duotoneRoot} 
          style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
        >
          <div className={classes.closeBtn} style={{padding: theme.spacing(1.5)}}>
            <div style={{ float: 'right' }}>
              <IconButton
                onClick={onClose} 
                size='small'
                {...cancelButtonProps}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div 
            style={{ 
              padding: `${theme.spacing(defaultSpacing)}`,
              paddingBottom: 0,
              height: '100%'
            }}
          >
            {Array.isArray(children) && children[1]}
          </div>
          <div style={{ marginTop: 'auto', padding: theme.spacing(defaultSpacing) }}>
            <div style={{ float: 'right' }}>
              <SubmitDeleteButtons {...submitDeleteButtonProps} />
            </div>
          </div>
        </Box>
      )}
      <Alert 
        severity='warning' 
        icon={false} 
        className={classes.alertRoot}
        {...delayedAlert?.props}
      >
        <div style={{ display: 'inline-flex' }}>
          {delayedAlert?.startComponent && delayedAlert.startComponent}
          {delayedAlert?.title && (
            <Typography style={{ marginLeft: 10 }}>
              {delayedAlert.title}
            </Typography>
          )}
        </div>
      </Alert>
      <DialogTitle
        style={{
          marginRight: SIDEBAR_WIDTH,
          display: 'inline-flex',
          alignItems: 'center',
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          pointerEvents: isLoading ? 'none' : 'auto'
        }}
        classes={{ root: classes.dialogTitleRoot }}
      >
        {titleLeftComponent && titleLeftComponent}
        <div>
          {typeof(title) === 'string' ? (
            <Typography variant='h3'>
              {title}
            </Typography>
          ) : title}

          {typeof subtitle === 'string' ? (
            <Typography variant='body2'>{subtitle}</Typography>
          ) : subtitle}
          
          {!!statusLabel && (
            <StatusLabel {...statusLabel} />
          )}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {(!!duotone && !!avatarComponent) && avatarComponent}
        </div>
        {!duotone && (
          <div className={classes.closeBtn} style={{padding: theme.spacing(1.5), paddingBottom: 0, background: theme.palette.background.paper}}>
            <div style={{ float: 'right' }}>
              <IconButton
                onClick={onClose} 
                size='small'
                {...cancelButtonProps}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        )}
      {/* 
        <div style={{ marginLeft: 'auto' }}>
          {(!!duotone && !!avatarComponent) && avatarComponent}
          {!duotone && (
            <IconButton
              onClick={onClose} 
              size='small'
              {...cancelButtonProps}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div> */}
      </DialogTitle>
      <DialogContent 
        dividers 
        style={{ marginRight: SIDEBAR_WIDTH, pointerEvents: isLoading ? 'none' : 'auto' }}
        classes={{ root: classes.dialogContentRoot }}
      >
        <div style={{ padding: theme.spacing(defaultSpacing) }}>
          {Array.isArray(children) ? children[0] : children}
        </div>
        {(isMobile && Array.isArray(children)) && (
          <div 
            style={{ 
              backgroundColor: theme.custom.backgroundDefaultduo,
              padding: theme.spacing(defaultSpacing) 
            }}
          >
            {children[1]}
          </div>
        )}
      </DialogContent>
      {!duotone && (
        <div 
          style={{ 
            marginRight: SIDEBAR_WIDTH,
            marginLeft: 'auto',
            padding: theme.spacing(defaultSpacing)
          }}
        >
          <SubmitDeleteButtons {...submitDeleteButtonProps} />
        </div>
      )}


      {isLoading && (
        <div
          style={{ 
            position: 'absolute',
            top: 0, 
            left: '50%',
            width: 275,
            transform: 'translateX(-50%)',
            backgroundColor: darken(theme.palette.background.paper, 0.3),
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(1.5)
          }}
        >
          <CircularProgress style={{ width: 24, height: 24 }} />
          <Typography style={{ marginLeft: theme.spacing(1.5) }}>
            {`${t('lang.loading')}...`}
          </Typography>
        </div>
      )}

      {/* {isLoading && (
        <div
          style={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0,
            background: alpha('#f00', 0.5),
            pointerEvents: 'none',
            zIndex: 99999
          }}
        >
        </div>
      )} */}
    </Dialog>
  )
}
export default DuotoneDialog;