import { AppBar, Box, Hidden, IconButton, SvgIcon, Toolbar, Tooltip, Badge, Typography, Divider } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { useTranslation } from 'src/i18n';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/general/Logo';
import { useHasAllPermissions } from 'src/hooks/general/useHasPermissions';
import { useTimeTracker } from 'src/hooks/general/useTimeTracker';
import type { UserSmall } from 'src/types/api/user';
import Logout from './Logout';
import ThemeButton from './ThemeButton';
import { useSelector } from 'src/store';
import ActiveTimeTracker from './ActiveTimeTracker';
import ActivePresentations from './ActivePresentations';
import ActiveDataTransfer from './ActiveDataTransfer';
import { useDispatch } from 'src/store';
import { updateTopbarValue } from 'src/slices/topbar';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MergeIcon from '@mui/icons-material/GroupRemoveOutlined';
import { userHasAllPermissions } from 'src/utils/general/userHasPermissions';
import Tabs from './Tabs';
import DotsMenu, { DotsMenuProps } from 'src/components/general/DotsMenu';
import useIsMobile from 'src/hooks/general/useIsMobile';
import Notifications from './Notifications';
import { isDev } from 'src/config';


const DEFAULT_TOPBAR_HEIGHT = 64;
const TABS_HEIGHT = 48;

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: 5,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      marginRight: 75,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 295
    }),
  },
  toolbar: {
    position: 'relative',
    minHeight: DEFAULT_TOPBAR_HEIGHT,
    paddingRight: 10,
    paddingLeft: 10,
    // paddingLeft: 24,
    // paddingRight: 10,
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbarInactive: {
    opacity: '0.2 !important',
    pointerEvents: 'none'
  },
  iconButton: {
    color: theme.palette.text.primary
  },
  header_title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
        marginLeft: 14
    }
  }
}));

interface TopBarProps {
  className?: string;
  onMobileNavOpen: () => void;
  onMobileNavOpenChat: () => void;
  navPinned: boolean;
  fullyOpen: boolean;
  setFullyOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: UserSmall | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<UserSmall | null>>;
  setPresenceOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menuInactive: boolean;
}

const DynamicTopbar = ({
  className,
  onMobileNavOpen,
  onMobileNavOpenChat,
  navPinned,
  fullyOpen,
  setFullyOpen,
  selectedUser,
  setSelectedUser,
  setPresenceOpen,
  menuInactive,
  ...rest
}: TopBarProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const users = useSelector(state => state.lists.users);
  const currentUser = useSelector(state => state.general.user);
  const topbarValues = useSelector(state => state.topbar);

  const canViewChat = useHasAllPermissions(['read_chat']) && users.length > 1;

  useTimeTracker();

  const getRootStyle = () => {
    let widthAdditive = 0;
    let marginRightAdditive = 0;
    if (!canViewChat) {
      widthAdditive = 75;
      marginRightAdditive = -75;
    }

    const chatPinned = false;
    if (navPinned && !chatPinned) {
      return {
        width: `calc(100% - ${331 - widthAdditive}px)`,
        marginLeft: `256px`,
        marginRight: `${75 + marginRightAdditive}px`,
      }
    }
    if (!navPinned && !chatPinned) {
      return {
        width: `calc(100% - ${150 - widthAdditive}px)`,
        marginLeft: `75px`,
        marginRight: `${75 + marginRightAdditive}px`
      }
    }

  }

  const getDotsMenuMenuItemValues = () => {
    let menuItems: DotsMenuProps['menuItems'] = [];

    if(!!topbarValues.edit){
      menuItems.push({
        content: `${t('lang.update')}`, 
        permissions: ['update_contact'], 
        icon: 'update', 
        onClick: () => {}
      });
    }

    if(!!topbarValues.delete){
      menuItems.push({
        content: `${t('lang.delete')}`, 
        permissions: ['delete_contact'], 
        icon: 'delete',
        onClick: () => {}
      });
    }

    if(!!topbarValues.merge){
      menuItems.push({
        content: `${t('lang.merge')}`, 
        permissions: ['merge_contact'], 
        icon: 'merge',
        onClick: () => {}
      })
    }

    return menuItems;
  }

  const content = (
    <Toolbar
      className={clsx(classes.toolbar, menuInactive && classes.toolbarInactive)}
      disableGutters
    >
      <Hidden mdUp>
        <IconButton
          className={classes.iconButton}
          color="inherit"
          onClick={onMobileNavOpen}
          size="large">
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
      </Hidden>

      {/* TITLE HERE */}
      <Typography 
        variant="h3"
        className={classes.header_title}
       // To get 24 in total from left
      >
        {/* Contacten */}
        {!topbarValues.hidden ? topbarValues.title : ''}
        {!isMobile ?
        <>
          {!topbarValues.hidden && !!topbarValues.edit && (
          <Tooltip title={`${t("lang.edit")}`}>
            <span>
            <IconButton 
              size='small' 
              onClick={topbarValues.edit.onClick}
              style={{ marginLeft: theme.spacing(1) }}
              disabled={
                (!!currentUser && topbarValues.edit.permissions && 
                  !userHasAllPermissions(currentUser, topbarValues.edit.permissions)) 
                || topbarValues.edit.disabled
              }
            >
              <EditIcon fontSize='small' />
            </IconButton>
            </span>
          </Tooltip>
          )}
          {!topbarValues.hidden && !!topbarValues.delete && (
            <Tooltip title={`${t("lang.delete")}`}>
              <span>
              <IconButton 
                size='small' 
                onClick={topbarValues.delete.onClick}
                style={{ marginLeft: 2 }}
                disabled={
                  (!!currentUser && topbarValues.delete.permissions && 
                    !userHasAllPermissions(currentUser, topbarValues.delete.permissions)) 
                  || topbarValues.delete.disabled
                }
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
              </span>
            </Tooltip>
          )}
          {!topbarValues.hidden && !!topbarValues.merge && (
            <Tooltip title={`${t("lang.merge")}`}>
              <span>
              <IconButton 
                size='small' 
                onClick={topbarValues.merge.onClick}
                style={{ marginLeft: 2 }}
                disabled={
                  (!!currentUser && topbarValues.merge.permissions && 
                    !userHasAllPermissions(currentUser, topbarValues.merge.permissions)) 
                  || topbarValues.merge.disabled
                }
              >
                <MergeIcon fontSize='small' />
              </IconButton>
              </span>
            </Tooltip>
          )}
          </>
        :
        <>
          {/* //! Function call done twice */}
          {!!getDotsMenuMenuItemValues().length && (
            <DotsMenu
              menuItems={getDotsMenuMenuItemValues()}
            />
          )}
      </>
      }
        
      </Typography>

      <Box
        flexGrow={1}
      />
      
      <Hidden mdDown>
        <div 
          style={{ 
            position: 'absolute',
            left: '50%',
            zIndex: 9,
            transform: 'translate(-50%)'
          }}
        >
          <RouterLink to="/">
            <Logo 
              grayscale={menuInactive}
            />
          </RouterLink>
        </div>
      </Hidden>

      {/* Trackers */}
      <ActiveTimeTracker />
      {/* <ActivePresentations /> */}
      <ActiveDataTransfer />

      {isDev() && (
        <Notifications />
      )}

      <ThemeButton />
      <Logout />

      {canViewChat &&
        <Hidden mdUp>
          <Tooltip title={`${t("lang.chat")}`}>
            <IconButton
              className={classes.iconButton}
              color="inherit"
              onClick={() => {
                if (selectedUser?.id) {
                  setSelectedUser(null);
                }
                setPresenceOpen((prevState) => !prevState);
              }}
              size="large"
            >
              <RenderBadge>
                <SvgIcon>
                  <ChatOutlinedIcon />
                </SvgIcon>
              </RenderBadge>
            </IconButton>
          </Tooltip>
        </Hidden>
      }
    </Toolbar>
  );


  return <>
    <Hidden mdDown>
      <AppBar
        className={clsx(classes.root, className)}
        // style={canViewChat ? {} : {marginRight: 0}}
        {...rest}
        style={{
          ...getRootStyle(),
        }}
      >
        <div style={{ maxHeight: DEFAULT_TOPBAR_HEIGHT }}>
          {content}
        </div>
        {!topbarValues.hidden && !!topbarValues.tabs.length && 
          <>
            <Divider />
            <div style={{ maxHeight: TABS_HEIGHT }}>
              <Tabs />
            </div>
          </>
        }
        </AppBar>
    </Hidden>
    <Hidden mdUp>
      <AppBar
        className={clsx(classes.root, className)}
        {...rest}
        // style={{ minHeight: DEFAULT_TOPBAR_HEIGHT * 2 }}
      >
        <div style={{ maxHeight: DEFAULT_TOPBAR_HEIGHT }}>
          {content}
        </div>
        {!topbarValues.hidden && !!topbarValues.tabs.length && 
          <>
            <Divider />
            <Box style={{ maxHeight: TABS_HEIGHT }}>
              <Tabs />
            </Box>
          </>
        }
      </AppBar>
    </Hidden>
  </>;
}

interface RenderBadgeProps {
  children: React.ReactNode | React.ReactNode[];
}

const RenderBadge = ({
  children
}: RenderBadgeProps) => {
  const { classes, theme } = useStyles();

  const all_messages = useSelector(state => state.chatMessages);
  const general_user = useSelector(state => state.general.user);

  const unreadMessageCount = useMemo(() => {
    let finalCount = 0;
    if (general_user) {
      Object.values(all_messages).map((messages) => messages.map((message) => {
        if (message.receiver_id === general_user.contact.id && message.read === false)
          finalCount++;
      }));
    }
    return finalCount;
  }, [all_messages]);

  return (
    <Badge
      overlap="circular"
      badgeContent={unreadMessageCount}
      color='primary'
    >
      {children}
    </Badge>
  )
}

export default DynamicTopbar;