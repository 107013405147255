import { AxiosInstance } from 'axios';
import { AnyAction, Store } from 'redux';
import { AxiosOptions } from 'src/hooks/general/useAxios';
import { setCurrentRole } from 'src/slices/current';
import { setMenu } from 'src/slices/general/menu';
import { updateUserRole } from 'src/slices/general/user';
import { createRole, deleteRole, updateRole } from 'src/slices/lists/roles';
import type { RootState } from 'src/store';
import type { AppDispatch } from 'src/store/index';
import type { Permission } from 'src/types/api/permission';
import type { RoleSmall } from 'src/types/api/role';
import { fetchData, removeData } from '../permissionChangeHandler';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions,
  axios: AxiosInstance
) => {
  channel.listen('.create_role', (data: any) => {
    const role = data.data.response as RoleSmall;
    dispatch(createRole(role));
  });
  channel.listen('.update_role', (data: any) => {//
    const role = data.data.response as RoleSmall;
    dispatch(updateRole(role));

    if (store.getState().current.role?.id === role.id) {
      axios.get(`roles/${role.id}`, axiosOptions.apiConfig)
        .then((response) => {
          console.log('TODO: ALERT');
          // setCurrentAlert({
          //   open: true,
          //   message: t("messages.current_role_updated"),
          //   user: data.data.user
          // });
          dispatch(setCurrentRole(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const isUserRole = store.getState().general.user?.role.id === role.id;
    if (isUserRole) {
      axios.get('general/menu', axiosOptions.apiConfig)
        .then((response) => {
          dispatch(setMenu(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      axios.get(`general/role`, axiosOptions.apiConfig)
        .then((response) => {
          dispatch(updateUserRole(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });
  channel.listen('.delete_role', (data: any) => {//
    // Expects array ????
    const roles = data.data.response as RoleSmall[];
    roles.map((role) => {
      dispatch(deleteRole(role));
      if (store.getState().current.role?.id === role.id) {
        dispatch(setCurrentRole(null));
      }
    });
  });
  channel.listen('.enable_role_permission', (data: any)=>{
    const response = data.data.response as {
      role_id: string;
      permissions: Permission[];
    };

    response.permissions.map((permission) => {
      fetchData(permission.tag, dispatch, axiosOptions, axios, store);
    });

    const userRole = store.getState().general.user?.role;
    const isUserRole = userRole?.id === response.role_id;

    if(userRole && isUserRole){
      let roleToUpdateClone = { ...userRole };
      roleToUpdateClone.permissions = [
        ...roleToUpdateClone.permissions,
        ...response.permissions
      ];
      dispatch(updateUserRole(roleToUpdateClone));
    }
    if(store.getState().current.role?.id === response.role_id){
      const currentRole = store.getState().current.role;
      if(currentRole){
        dispatch(setCurrentRole({
          ...currentRole,
          permissions: [...currentRole.permissions, ...response.permissions]
        }));
      }
    }
  });

  // deze code ooit fixen
  channel.listen('.disable_role_permission', (data: any)=>{
    const response = data.data.response as {
      role_id: string;
      permissions: Permission[];
    };

    response.permissions.map((permission) => {
      // pass store ?
      removeData(permission.tag, dispatch, store);
    });

    const userRole = store.getState().general.user?.role;
    const isUserRole = userRole?.id === response.role_id;

    if(userRole && isUserRole){
      // let roleToUpdate = store.getState().general.user.role;
      const getNewUserRole = async () => {
        return await userRole.permissions.filter((permission) => {
          let shouldRemove = false;
          response.permissions.map((perm2) => {
            if(perm2.id === permission.id){
              shouldRemove = true;
            }
          });
          if(shouldRemove){
            return false;
          }
          return true;
        });
      }

      getNewUserRole().then((response) => {
        dispatch(updateUserRole({
          ...userRole,
          permissions: [...response]
        }));
      });
      
    }

    const currentRole = store.getState().current.role;
    if(currentRole?.id === response.role_id){

      const updateCurrentRole = async () => {
        let newRole = Object.assign({}, currentRole);
        newRole.permissions = await newRole.permissions.filter((permission) => {
          let shouldRemove = false;
          response.permissions.map((perm2) => {
            if(perm2.id === permission.id){
              shouldRemove = true;
            }
          });
          if(shouldRemove){
            return false;
          }
          return true;
        });
        return newRole;
      }

      updateCurrentRole().then((response) => {
        dispatch(setCurrentRole(response));
      })
    }
  })
}