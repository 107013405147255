import { useSelector } from "src/store";
import { Absence } from "src/types/api/absence";
import { AppointmentService } from "src/types/api/appointment";
import { Task } from "src/types/api/project";
import { UserSmall } from "src/types/api/user";

const unixOverlaps = (
  startA: number, 
  endA: number, 
  startB: number, 
  endB: number, 
  edgeOverlap = true
) => {
  if(edgeOverlap){
    return startA < endB && endA > startB;
  }
  return startA <= endB && endA >= startB;
}
// if(start.unix() <= lEnd.unix() && end.unix() >= lStart.unix()){
//   return true;
// }

interface OverlapsResult {
  absences: Absence[];
  tasks: Task[];
  appointmentServices: AppointmentService[];
}

/**
 * This hook checks whether any absence, task or appointment overlaps with the given time (and users) in the returned function
 */
const useCalendarOverlap = (edgeOverlap: boolean = true) => {
  const { 
    absences, 
    projects: { tasks }, 
    appointments
  } = useSelector(state => state.lists);

  const settings = useSelector(state => state.general.settings);

  // baseOverlaps ?
  const baseOverlaps = (
    start: number, 
    end: number
  ): OverlapsResult => {
    const absenceOverlaps = absences.filter(absence =>
      unixOverlaps(start, end, absence.start_date, absence.end_date, edgeOverlap)
    );

    // const absenceOverlaps = absences.filter(absence => {
    //   console.log(`absence: ${absence.id}`);
    //   console.log(`start: ${dayjs.unix(absence.start_date).format('HH:mm:ss')}`);
    //   console.log(`end: ${dayjs.unix(absence.end_date).format('HH:mm:ss')}`);
    //   return unixOverlaps(start, end, absence.start_date, absence.end_date, edgeOverlap)
    // });

    const taskOverlaps = tasks.filter(task =>
      unixOverlaps(start, end, task.start_date, task.end_date, edgeOverlap)
    );

    const appointmentServicesOverlaps = appointments.reduce((prev, current) => {
      const servicesThatOverlap = current.services.filter(service => {
        if(service.children.length){
          const serviceChildrenThatOverlap = service.children.filter(appointmentServiceChild => 
            unixOverlaps(start, end, appointmentServiceChild.start, appointmentServiceChild.start + (appointmentServiceChild.duration * 60), edgeOverlap)
          );
          return !!serviceChildrenThatOverlap.length;
        }
        return unixOverlaps(start, end, service.start, service.start + (service.duration * 60), edgeOverlap)
      });

      return [...prev, ...servicesThatOverlap];
    }, []);

    console.log(appointmentServicesOverlaps);

    return {
      absences: absenceOverlaps,
      tasks: taskOverlaps,
      appointmentServices: appointmentServicesOverlaps
    }
  }

  // checks if baseOverlapsResult contains the user
  const userOverlaps = (
    users: UserSmall[],
    baseOverlapsResult: OverlapsResult
  ): OverlapsResult => {
    // returns true if absence has no users or has given user
    const absenceOverlaps = baseOverlapsResult.absences.filter(absence => 
      absence.all_users || absence.users.find(u => users.find(user => user.id === u.id))
    );

    // returns true if task has no members or has given user
    const taskOverlaps = baseOverlapsResult.tasks.filter(task => 
      task.all_users || task.member_ids.find(member_id => users.find(user => user.id === member_id))
    );

    const appointmentServiceOverlaps = baseOverlapsResult.appointmentServices.filter(appointmentService => 
      !!users.find(u => u.id === appointmentService.user_id) // negate ??
    );

    return {
      absences: absenceOverlaps,
      tasks: taskOverlaps,
      appointmentServices: appointmentServiceOverlaps
    }

  }


  /**
   * Checks if any absence, task or appointment(service) overlaps with the given times for the given user(s)
   * @param start start unix of event
   * @param end end unix of event
   * @param users users array to check against or null to ignore user check and return all overlaps
   * @param ignoreIds ids to ignore and dont return in the result
   */
  const overlaps = (
    start: number, 
    end: number,
    users: UserSmall[] | null,
    ignoreIds: { task?: string[], absence?: string[], appointmentService?: string[] } = {}
  ): OverlapsResult => {

    const foundSetting = settings.find(s => s.type === 'group' && s.tag === 'calendar_overlap');
    if(!foundSetting){
      console.error('Setting calendar_overlap not found');
    }

    // setting calendarOverlap
    if(foundSetting && foundSetting.value === 'false'){
      return {
        absences: [],
        appointmentServices: [],
        tasks: []
      }
    }

    // console.log(`start time of given: ${dayjs.unix(start).format('HH:mm:ss')}`)
    // console.log(`end time of given: ${dayjs.unix(end).format('HH:mm:ss')}`)

    let baseOverlapsResult = baseOverlaps(start, end);

    // filter out ignore ids
    if(ignoreIds.task)
      baseOverlapsResult.tasks = 
        baseOverlapsResult.tasks.filter(x => !ignoreIds.task!.includes(x.id)); // NEGATE?!?!
    if(ignoreIds.absence)
      baseOverlapsResult.absences = 
        baseOverlapsResult.absences.filter(x => !ignoreIds.absence!.includes(x.id)); // NEGATE?!?!
    if(ignoreIds.appointmentService)
      baseOverlapsResult.appointmentServices = 
        baseOverlapsResult.appointmentServices.filter(x => !ignoreIds.appointmentService!.includes(x.id)); // NEGATE?!?!

    if(Array.isArray(users))
    {
      return userOverlaps(users, baseOverlapsResult);
    }
    return baseOverlapsResult;
  }

  return {
    overlaps
  }
}
export default useCalendarOverlap;