import { createContext, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "src/App";
import AlertToast from "../AlertToast";
import Echo from 'laravel-echo';
// @ts-ignore
import io from 'socket.io-client';
import { useDispatch, useSelector, useStore } from "src/store";
import { PresenceUser } from "src/types/api/presenceUser";
import { addNoteTyping, addPresence, addPublicPresence, addTyping, createEditingTask, deleteEditingTask, removeNoteTyping, removePresence, removePublicPresence, removeTyping, setPresence, setPublicPresence } from "src/slices/misc";
import { updateUser } from "src/slices/lists/users";
import { socketUrl } from 'src/config';
import initializeContacts from './handlers/contacts';
import initializeGroups from './handlers/groups';
import initializePermissions from './handlers/permissions';
import initializeUsers from './handlers/users';
import initializeRoles from './handlers/roles';
import initializeAvatars from './handlers/avatars';
import initializeVatrates from './handlers/vatrates';
import initializeNotes from './handlers/notes';
import initializeLanguages from './handlers/languages';
import initializeInvoiceWorkflows from './handlers/invoiceWorkflows';
import initializeTemplates from './handlers/templates';
import initializeTimeTrackers from './handlers/timeTrackers';
import initializePresentations from './handlers/presentations';
import initializeFiles from './handlers/files';
import initializeFolders from './handlers/folders';
import initializePermissionCategories from './handlers/permissionCategories';
import initializeCalls from './handlers/calls';
import initializeOther from './handlers/other';
import initializeProjects from './handlers/projects';
import initializeActivity from './handlers/activity';
import initializeTags from './handlers/tags';
import initializePublicwidgets from './handlers/publicwidgets';
import initializeSystemSettings from './handlers/systemSettings';
import initializeProducts from './handlers/products';
import initializeServices from './handlers/services';
import initializeWorkingHours from './handlers/workingHours';
import initializeHolidays from './handlers/holidays';
import initializeAbsences from './handlers/absences';
import initializeAppointments from './handlers/appointments';
import initializeSettings from './handlers/settings';
import initializeInvoices from './handlers/invoices';
import initializeMessageTemplates from './handlers/messageTemplates';
import initializeGiftcards from './handlers/giftcards';
import initializeRegisterMisc from './handlers/registerMisc';
import initializeDayClosings from './handlers/dayClosings';
import intitializeMessages from './handlers/messages';
import intitializeEvents from './handlers/events';
import intitializeEventStatuses from './handlers/eventStatuses';
import intitializeMisc from './handlers/misc';
import { useAxios, useAxiosOptions } from "src/hooks/general/useAxios";
import { useLocation, useNavigate } from "react-router";

interface EchoType {
  join: (channel: string) => EchoPresenceChannel;
  leaveChannel: (channel: string) => void;
  private: (channel: string) => EchoChannel;
  disconnect: () => void;
  connector: any; // Differs
}

export interface EchoChannel {
  listen: (event: string, cb: (data: any) => void) => void;
  stopListening: (event: string, cb: (data: any) => void) => void;
  whisper: (event: string, data: any) => void;
  listenForWhisper: (event: string, cb: (data: any) => void) => void;
}

interface EchoPresenceChannel extends EchoChannel {
  here: (members: any) => void;
  joining: (member: any) => void;
  leaving: (member: any) => void;
}

export const BroadcastContext = createContext<{
  echo?: EchoType;
  presenceChannel?: EchoPresenceChannel;
  privateChannel?: EchoChannel;
  personalChannel?: EchoChannel;
  publicPresenceChannel?: EchoChannel; // ???
}>({});

interface Props {
  children: React.ReactChild | React.ReactChild[];
}

const DataListener = ({
  children
}: Props) => {
  const appContext = useContext(AppContext);
  const echo = useRef<EchoType>();
  const location = useLocation();

  const dispatch = useDispatch();
  const store = useStore();
  const axios = useAxios();
  const axiosOptions = useAxiosOptions();
  const navigate = useNavigate();

  const group_id = useSelector(state => state.general.group?.contact.id);
  const own_id = useSelector(state => state.general.user?.contact.id);
  const offersSupport = !!useSelector(state => state.general.user?.support);

  const [privateChannel, setPrivateChannel] = useState<EchoChannel>();
  const [presenceChannel, setPresenceChannel] = useState<EchoPresenceChannel>();
  const [personalChannel, setPersonalChannel] = useState<EchoChannel>();
  const [publicPresenceChannel, setPublicPresenceChannel] = useState<EchoPresenceChannel>();

  const timer = useRef<{ [key: string]: NodeJS.Timeout }>({});

  const [currentAlert, setCurrentAlert] = useState({
    open: false,
    message: 'test',
    user: null
  });

  // const [connected, setConnected] = useState(false);
  // useEffect(() => {
  //   if(pusherJs.current){
  //     pusherJs.current.connector.socket.on('connect', () => {
  //       setConnected(true);
  //     });
  //     pusherJs.current.connector.socket.on('disconnect', () => {
  //       setConnected(false);
  //     });
  //   }
  // }, [pusherJs.current]);

  useEffect(() => {
    if (!echo.current && appContext?.apiToken) {
      const l_echo: EchoType = new Echo({
        broadcaster: 'socket.io',
        host: socketUrl(),
        client: io,
        // authEndpoint, process.env.REACT_APP_BASE_URL
        auth: {
          headers: {
            Authorization: `Bearer ${appContext.apiToken.token}`
          }
        }
      }) as any;
      echo.current = l_echo;
    }

    return () => {
      if(echo.current){
        echo.current.disconnect();
      }
    }
  }, []);

  const [presenceHereInitialized, setPresenceHereInitialized] = useState(false);

  // location change listener
  useEffect(() => {
    if(presenceChannel){
      presenceChannel.whisper('action-set_location', location.pathname);
    }
  }, [location, presenceChannel, presenceHereInitialized]);

  //Presence
  useEffect(() => {
    if(!group_id){
      console.error(new Error('Group id not set at DataListener!!'));
      return;
    }

    if (echo.current && !presenceChannel) {
      const presenceTemp = echo.current.join(group_id);
      // const presenceTemp = pusherJs.current.join(group_id)

      presenceTemp.here((data: any) => {
        setPresenceHereInitialized(true);

        // console.log(data);
        const members = data as PresenceUser[];
        dispatch(setPresence(members));
      })

      presenceTemp.joining((data: any) => {
        const member = data as PresenceUser;
        dispatch(addPresence(member));
      })

      presenceTemp.leaving((data: any) => {
        const member = data as PresenceUser;
        dispatch(removePresence(member));

        const user = store.getState().lists.users.find((user) => user.id === member.id);
        if (user?.id) {
          dispatch(updateUser({
            ...user,
            last_seen: new Date().toISOString()
          }));
        }
      })

      setPresenceChannel(presenceTemp);
    }
  }, [echo.current]);

  useEffect(() => {
    if (echo.current && !publicPresenceChannel && own_id && offersSupport) {
      console.log(`Joining channel: public_${group_id}`);

      // const publicPresenceTemp = pusherJs.current.subscribe(`private-public_${group_id}`) as PusherTypes.PresenceChannel;
      const publicPresenceTemp = echo.current.join(`public_${group_id}`);

      publicPresenceTemp.here((data: any) => {
        console.log(data);
        if (!data) {
          return;
        }

        // const members = Object.values(data.members) as PresenceUser[];
        // dispatch(setPublicPresence(members));`

        const members = data as PresenceUser[];
        dispatch(setPublicPresence(members));
      });

      publicPresenceTemp.joining((data: any) => {
        console.log(data);
        if (!data) {
          return;
        }

        const member = data as PresenceUser;
        dispatch(addPublicPresence(member));

        // const member = data.info as PresenceUser;
        // dispatch(addPublicPresence(member));
      });

      publicPresenceTemp.leaving((data: any) => {
        console.log(data);
        if (!data) {
          return;
        }

        const member = data as PresenceUser;
        dispatch(removePublicPresence(member));

        // const member = data.info as PresenceUser;
        // dispatch(removePublicPresence(member));
      });

      setPublicPresenceChannel(publicPresenceTemp);
      // publicPresenceInitialized.current = true;
    }

    if (publicPresenceChannel && !offersSupport) {
      console.log(`Leaving channel: public_${group_id}`);
      // pusher unsubscribe TODO
      // publicPresenceChannel.unsubscribe();
      // unbind all ??

      // publicPresenceChannel.leave(`public_${group_id}`);
      echo.current?.leaveChannel(`public_${group_id}`);

      setPublicPresenceChannel(undefined);
    }

  }, [echo.current, offersSupport]); //??

  useEffect(() => {
    if (echo.current && !personalChannel) {
      // const personalTemp = pusherJs.current.subscribe(`private-personal.${own_id}`);
      const personalTemp = echo.current.private(`personal.${own_id}`);

      // personalTemp.bind('test', (data: any) => { console.log(data); })

      initializeContacts(personalTemp, dispatch, store, axiosOptions, axios);
      initializeUsers(personalTemp, dispatch, store, axiosOptions, axios);
      initializeGroups(personalTemp, dispatch, store, axiosOptions, axios);
      initializePermissions(personalTemp, dispatch);
      initializeRoles(personalTemp, dispatch, store, axiosOptions, axios);
      initializeVatrates(personalTemp, dispatch);
      initializeAvatars(personalTemp, dispatch);
      initializeNotes(personalTemp, dispatch);
      initializeLanguages(personalTemp, dispatch);
      initializeInvoiceWorkflows(personalTemp, dispatch);
      initializeTemplates(personalTemp, dispatch, store);
      initializeTimeTrackers(personalTemp, dispatch);
      initializePresentations(personalTemp, dispatch);
      initializeFiles(personalTemp, dispatch);
      initializeFolders(personalTemp, dispatch);
      initializePermissionCategories(personalTemp, dispatch);
      initializeCalls(personalTemp, store);
      initializeOther(personalTemp, dispatch, store, axiosOptions, appContext, axios);
      initializeProjects(personalTemp, dispatch, axiosOptions, axios, store);
      initializeActivity(personalTemp, dispatch, store);
      initializeTags(personalTemp, dispatch);
      initializePublicwidgets(personalTemp, dispatch);
      initializeSystemSettings(personalTemp, dispatch);
      initializeProducts(personalTemp, dispatch);
      initializeServices(personalTemp, dispatch);
      initializeWorkingHours(personalTemp, dispatch, store);
      initializeHolidays(personalTemp, dispatch);
      initializeAbsences(personalTemp, dispatch);
      initializeAppointments(personalTemp, dispatch);
      initializeSettings(personalTemp, dispatch, store);
      initializeInvoices(personalTemp, dispatch, store, axiosOptions);
      initializeMessageTemplates(personalTemp, dispatch);
      initializeGiftcards(personalTemp, dispatch);
      initializeRegisterMisc(personalTemp, dispatch, store);
      initializeDayClosings(personalTemp, dispatch);
      intitializeMessages(personalTemp, dispatch, store);
      intitializeEvents(personalTemp, dispatch, store, axiosOptions);
      intitializeEventStatuses(personalTemp, dispatch);
      intitializeMisc(personalTemp);


      setPersonalChannel(personalTemp);

      // personalInitialized.current = true;
    }
  }, [echo.current]);

  useEffect(() => {
    if(!group_id){
      console.error(new Error('Group id not set at DataListener!!'));
      return;
    }

    if (echo.current && !privateChannel) {

      const newPrivate = echo.current.private(group_id);

      newPrivate.listen('.logoff_user', (data: any) => {
        if (data.data.user.id === own_id) {
          navigate('/logout');
        }
      })
      newPrivate.listen('.login_user', (data: any) => {
        if (data.data.user.id === own_id) {
          navigate('/logout');
        }
      })

      newPrivate.listenForWhisper('typing', (data: any) => {
        if (data.receiver_id === own_id) {
          let shouldAdd = true;
          store.getState().misc.typing.forEach((val) => {
            if (val === data.sender_id) {
              shouldAdd = false;
            }
          });
          if (shouldAdd) {
            dispatch(addTyping({ sender_id: data.sender_id }));
          }
          clearTimeout(timer.current[data.sender_id]);
          timer.current[data.sender_id] = setTimeout(() => {
            dispatch(removeTyping({ sender_id: data.sender_id }));
          }, 1000);
        }
      })
      newPrivate.listenForWhisper('note_typing', ({ note_id, user_id }: any) => {
        const alreadyExists = !!Object.keys(store.getState().misc.noteTyping).find((key, index) => key === note_id);
        if (!alreadyExists) {
          dispatch(addNoteTyping({ note_id, user_id }));
        }
        clearTimeout(timer.current[note_id]);
        timer.current[note_id] = setTimeout(() => {
          dispatch(removeNoteTyping({ note_id }));
        }, 5000);
      })
      newPrivate.listenForWhisper('create_task_editing', (data: { task_id: string; user_id: string; }) => {
        dispatch(createEditingTask(data));
      })
      newPrivate.listenForWhisper('delete_task_editing', (data: { task_id: string; user_id: string; }) => {
        deleteEditingTask(data);
      })

      setPrivateChannel(newPrivate);
    }
  }, [privateChannel]);

  return (
    <BroadcastContext.Provider
      value={{
        echo: echo.current,
        privateChannel: privateChannel,
        personalChannel: personalChannel,
        presenceChannel: presenceChannel,
        publicPresenceChannel: publicPresenceChannel
      }}
    >
      <AlertToast
        currentAlert={currentAlert}
        setCurrentAlert={setCurrentAlert}
        timeout={4000}
      />
      {children}
    </BroadcastContext.Provider>
  )
}
export default DataListener;