import { createGiftcard, deleteGiftcard, updateGiftcard } from 'src/slices/lists/giftcards';
import type { AppDispatch } from 'src/store/index';
import { Giftcard } from 'src/types/api/giftcard';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_giftcard', (data: any) => {
    const giftcard = data.data.response as Giftcard;
    dispatch(createGiftcard(giftcard));
  });
  channel.listen('.update_giftcard', (data: any) => {//
    const giftcard = data.data.response as Giftcard;
    dispatch(updateGiftcard(giftcard));
  });
  channel.listen('.delete_giftcard', (data: any) => {//
    const giftcard = data.data.response as Giftcard;
    dispatch(deleteGiftcard(giftcard));
  });
}