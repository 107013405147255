import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { TemplateSmall } from  'src/types/api/template';

const initialState: TemplateSmall[] = [];

const slice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates(state: TemplateSmall[], action: PayloadAction<TemplateSmall[]>){
      return action.payload;
    },
    createTemplate(state: TemplateSmall[], action: PayloadAction<TemplateSmall>){
      state.push(action.payload);
    },
    updateTemplate(state: TemplateSmall[], action: PayloadAction<TemplateSmall>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteTemplate(state: TemplateSmall[], action: PayloadAction<TemplateSmall>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setTemplates = (data: TemplateSmall[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTemplates(data));
}

export const createTemplate = (data: TemplateSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createTemplate(data));
}

export const updateTemplate = (data: TemplateSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTemplate(data));
}

export const deleteTemplate = (data: TemplateSmall): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteTemplate(data));
}

export default slice;