import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { PresenceUser as TPresenceUser } from 'src/types/api/presenceUser';
import type { Menu } from 'src/types/api/menu';

type TAvatar = string;

type TMiscState = {
  typing: string[],
  avatars: {
    [key: string]: TAvatar
  },
  presence: TPresenceUser[],
  publicPresence: TPresenceUser[],
  supportedLanguagePercentage: any[],
  clientReload: boolean,
  noteTyping: {
    [note_id: string]: string | null; // user id
  },
  usedGroupStorage: number;
  fullMenu: Menu;
  error: { message: string; open: boolean; };
  editingTasks: { task_id: string; user_id: string; }[];
  fetching: string[];
  paywallOpen: boolean;
  createAppointmentPreparation: { contact_id: string } | null;
}

// disconnected & clientReload ??? (bools)

const initialState: TMiscState = {
  typing: [],
  avatars: {},
  presence: [],
  publicPresence: [],
  supportedLanguagePercentage: [],
  clientReload: false,
  noteTyping: {},
  usedGroupStorage: 0,
  fullMenu: [],
  error: { message: '', open: false },
  editingTasks: [],
  fetching: [],
  paywallOpen: false,
  createAppointmentPreparation: null
};

const slice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    addTyping(state: TMiscState, action: PayloadAction<{sender_id: string}>){
      state.typing.filter((val) => val !== action.payload.sender_id);
      state.typing.push(action.payload.sender_id);
    },
    removeTyping(state: TMiscState, action: PayloadAction<{sender_id: string}>){
      return {
        ...state,
        typing: [
          ...state.typing.filter((val) => val !== action.payload.sender_id)
        ]
      }
    },
    setAvatars(state: TMiscState, action: PayloadAction<{ [id: string]: TAvatar; }>){
      return {
        ...state,
        avatars: action.payload
      }
    },
    createAvatar(state: TMiscState, action: PayloadAction<{id: string, avatar: TAvatar}>){
      state.avatars[action.payload.id] = action.payload.avatar;
    },
    deleteAvatar(state: TMiscState, action: PayloadAction<string>){
      // state.avatars = state.avatars.filter((val) => val.id !== action.payload.id);
      delete(state.avatars[action.payload]);
    },
    setPresence(state: TMiscState, action: PayloadAction<TPresenceUser[]>){
      state.presence = action.payload
    },
    // WAAROM WERKT DIT ????
    addPresence(state: TMiscState, action: PayloadAction<TPresenceUser>){
      state.presence.push(action.payload);
    },
    removePresence(state: TMiscState, action: PayloadAction<TPresenceUser>){
      state.presence = state.presence.filter((val) => val.id !== action.payload.id);
    },

    setPublicPresence(state: TMiscState, action: PayloadAction<TPresenceUser[]>){
      state.publicPresence = action.payload
    },
    addPublicPresence(state: TMiscState, action: PayloadAction<TPresenceUser>){
      state.publicPresence.push(action.payload);
    },
    removePublicPresence(state: TMiscState, action: PayloadAction<TPresenceUser>){
      state.publicPresence = state.publicPresence.filter((val) => val.id !== action.payload.id);
    },

    setClientReload(state: TMiscState, action: PayloadAction<boolean>){
      state.clientReload = action.payload;
    },

    // // maybe change later ?!?!? && TEST!!!
    // updateSupportedLanguagePercentage(state: TMiscState, action: PayloadAction<any>){      
    //   state.supportedLanguagePercentage = state.supportedLanguagePercentage.map((val) => val.language.locale !== action.payload.language.locale ? val : action.payload)
    // },

    updateSupportedLanguagePercentage(state: TMiscState, action: PayloadAction<any>){      
      state.supportedLanguagePercentage = [
        ...state.supportedLanguagePercentage.filter((val) => val.language.locale !== action.payload.language.locale),
        action.payload
      ]
    },

    deleteSupportedLanguagePercentage(state: TMiscState, action: PayloadAction<any>){      
      state.supportedLanguagePercentage = state.supportedLanguagePercentage.filter((val) => val.language.locale !== action.payload.language.locale)
    },
    addNoteTyping(state: TMiscState, action: PayloadAction<{note_id: string, user_id: string}>){      
      state.noteTyping[action.payload.note_id] = action.payload.user_id;
    },
    removeNoteTyping(state: TMiscState, action: PayloadAction<{note_id: string}>){      
      delete state.noteTyping[action.payload.note_id];
    },
    setFullMenu(state: TMiscState, action: PayloadAction<Menu>){
      state.fullMenu = action.payload;
    },
    // error
    setError(state: TMiscState, action: PayloadAction<{ message: string, open: boolean}>){
      state.error = action.payload;
    },

    createEditingTask(state: TMiscState, action: PayloadAction<{ task_id: string, user_id: string }>){
      state.editingTasks.push(action.payload);
    },
    deleteEditingTask(state: TMiscState, action: PayloadAction<{ task_id: string }>){
      state.editingTasks = state.editingTasks.filter((value) => value.task_id === action.payload.task_id);
    },

    // to fetching
    createFetching(state: TMiscState, action: PayloadAction<{ url: string }>){
      state.fetching.push(action.payload.url);
    },
    deleteFetching(state: TMiscState, action: PayloadAction<{ url: string }>){
      state.fetching = state.fetching.filter((x) => x !== action.payload.url);
    },
    setPaywallOpen(state: TMiscState, action: PayloadAction<boolean>){
      state.paywallOpen = action.payload;
    },

    setCreateAppointmentPreparation(state: TMiscState, action: PayloadAction<TMiscState['createAppointmentPreparation']>){
      state.createAppointmentPreparation = action.payload;
    }
    
  
  }
})

export const { reducer } = slice;

export const addTyping = (data: {sender_id: string}): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addTyping(data));
}
export const removeTyping = (data: {sender_id: string}): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeTyping(data));
}
export const setAvatars = (data: {[id: string]: TAvatar }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAvatars(data));
}
export const createAvatar = (data: {id: string, avatar: TAvatar}): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createAvatar(data));
}
export const deleteAvatar = (data: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteAvatar(data));
}

// PRESENCE
export const setPresence = (data: TPresenceUser[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPresence(data));
}
export const addPresence = (data: TPresenceUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addPresence(data));
}
export const removePresence = (data: TPresenceUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removePresence(data));
}

// PUBLIC PRESENCE
export const setPublicPresence = (data: TPresenceUser[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPublicPresence(data));
}
export const addPublicPresence = (data: TPresenceUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addPublicPresence(data));
}
export const removePublicPresence = (data: TPresenceUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removePublicPresence(data));
}

export const setClientReload = (data: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setClientReload(data));
}

// CHANGE ANY !!!
export const updateSupportedLanguagePercentage = (data: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSupportedLanguagePercentage(data));
}
export const deleteSupportedLanguagePercentage = (data: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteSupportedLanguagePercentage(data));
}

export const addNoteTyping = (data: { note_id: string, user_id: string }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNoteTyping(data));
}

export const removeNoteTyping = (data: { note_id: string }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeNoteTyping(data));
}

export const setFullMenu = (data: Menu): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFullMenu(data));
}

export const setError = (data: { message: string, open: boolean}): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setError(data));
}

export const createEditingTask = (data: { task_id: string, user_id: string }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createEditingTask(data));
}

export const deleteEditingTask = (data: { task_id: string, user_id: string }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteEditingTask(data));
}

export const createFetching = (data: { url: string }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createFetching(data));
}

export const deleteFetching = (data: { url: string }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteFetching(data));
}

export const setPaywallOpen = (data: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPaywallOpen(data));
}

export const setCreateAppointmentPreparation = (data: TMiscState['createAppointmentPreparation']): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCreateAppointmentPreparation(data));
}

export default slice;