import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'src/i18n';
import mergeRefs from "react-merge-refs";
import useAutofocus from './logic/useAutofocus';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

interface Props extends StandardTextFieldProps {
  showVisibilityToggle?: boolean;
}

const Password = forwardRef<HTMLDivElement, Props>(({
  showVisibilityToggle = false,
  ...props
}, forwardedRef) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => setIsVisible(false), [showVisibilityToggle]);

  const focusRef = useAutofocus(!!props.autoFocus, props.value);

  const combinedRefs = mergeRefs(props.inputRef ? [props.inputRef, focusRef] : [focusRef]);

  const VisibilityAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        tabIndex={-1}
        onClick={() => setIsVisible(prev => !prev)}
        size='small'
      >
        {isVisible ? <VisibilityOutlinedIcon color="primary" /> : <VisibilityOffOutlinedIcon />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      ref={forwardedRef}
      variant='standard'
      label={`${t('lang.password')}`}
      type={isVisible ? 'text' : 'password'}
      {...props}
      InputProps={{
        endAdornment: showVisibilityToggle ? <VisibilityAdornment /> : undefined,
        ...props.InputProps
      }}
      helperText={props.helperText}
      inputRef={combinedRefs}
    />
  )
});

export default Password;