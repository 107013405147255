import { useEffect } from 'react';
import { useAxiosOptions } from 'src/hooks/general/useAxios';
import { uploadFileRequest } from 'src/requests/files';
import { setUploads, updateUpload } from 'src/slices/dataTransfer';
import { updateFile } from 'src/slices/lists/files';
import { useDispatch, useSelector } from 'src/store';
import type { Upload } from 'src/types/api/dataTransfer';

export const UploadHandler = ({children}: any) => {
  useUploadHandler();
  return children;
}

const useUploadHandler = () => {
  const axiosOptions = useAxiosOptions();
  const dispatch = useDispatch();
  const { uploads } = useSelector((state) => state.dataTransfer);

  const handleProgressChange = (e: any, upload: Upload) => {
    const percentage = Math.round(e.loaded / e.total * 100);
    dispatch(updateUpload({
      ...upload,
      progress: percentage,
      uploading: true
    }));
  }

  const handleUpload = (upload: Upload) => {
    dispatch(updateUpload({
      ...upload,
      uploading: true
    }));

    uploadFileRequest(upload.stateFile.id, upload.file, dispatch, {
      onUploadProgress: (e) => handleProgressChange(e, upload),
      ...axiosOptions.apiConfig
    }).then(responseData => {
      dispatch(updateFile(responseData));
      dispatch(updateUpload({
        ...upload,
        finished: true,
        progress: 100
      }))
    })
  }

  useEffect(() => {
    if(!uploads.length){
      return;
    }

    const uploadsNotUploading = uploads.filter(upload => !upload.uploading && !upload.finished);
    uploadsNotUploading.map(upload => handleUpload(upload));

    // if all uploads in uploads are finished, clear uploads
    if(!uploads.find(upload => !upload.finished)){ 
      dispatch(setUploads([]));
    }
  }, [uploads]);

  // useEffect(() => {
  //   if(uploads.length){
  //     const anyUploadUploading = uploads.find((upload) => upload.uploading && !upload.finished);
  //     if(!anyUploadUploading){
  //       const upload = uploads.find((upload) => !upload.uploading && !upload.finished);
  //       if(upload){
  //         handleUpload(upload);
  //       }else{
  //         dispatch(setUploads([]));
  //       }
  //     }
  //   }
  // }, [uploads]);
}

export default useUploadHandler;