import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Service, ServiceCategory } from 'src/types/api/service';
import { reorder } from 'src/utils/general/arrayUtils';

interface State {
  services: Service[];
  categories: ServiceCategory[];
}

const initialState: State = {
  services: [],
  categories: [],
};

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices(state: State, action: PayloadAction<Service[]>){
      return {
        ...state,
        services: action.payload
      };
    },
    createService(state: State, action: PayloadAction<Service>){
      return {
        ...state,
        services: [
          ...state.services,
          action.payload
        ]
      };
    },
    updateService(state: State, action: PayloadAction<Service>){
      const foundService = state.services.find(service => service.id === action.payload.id);
      if(foundService){
        if(foundService.category_id !== action.payload.category_id){
          return {
            ...state,
            services: [
              ...state.services.filter((service) => service.id !== action.payload.id),
              action.payload
            ]
          }
        }
      }
      
      return {
        ...state,
        services: state.services.map((service) => service.id === action.payload.id ? action.payload : service)
      };
    },
    deleteService(state: State, action: PayloadAction<Service>){
      return {
        ...state,
        services: state.services.filter((service) => service.id !== action.payload.id)
      };
    },
    moveService(state: State, action: PayloadAction<{ sourceId: string; destinationId: string; }>){
      const sourceIndex = state.services.findIndex(cat => cat.id === action.payload.sourceId);
      const desinationIndex = state.services.findIndex(cat => cat.id === action.payload.destinationId);

      const reordered = reorder(state.services, sourceIndex, desinationIndex);
      return {
        ...state,
        services: reordered
      }
    },

    // Categories
    setServiceCategories(state: State, action: PayloadAction<ServiceCategory[]>){
      return {
        ...state,
        categories: action.payload
      };
    },
    createServiceCategory(state: State, action: PayloadAction<ServiceCategory>){
      return {
        ...state,
        categories: [
          ...state.categories,
          action.payload
        ]
      };
    },
    updateServiceCategory(state: State, action: PayloadAction<ServiceCategory>){
      return {
        ...state,
        categories: state.categories.map((category) => category.id === action.payload.id ? action.payload : category)
      };
    },
    deleteServiceCategory(state: State, action: PayloadAction<ServiceCategory>){
      return {
        ...state,
        categories: state.categories.filter((category) => category.id !== action.payload.id)
      };
    },
    moveServiceCategory(state: State, action: PayloadAction<{ sourceId: string; destinationId: string; }>){
      const sourceIndex = state.categories.findIndex(cat => cat.id === action.payload.sourceId);
      const desinationIndex = state.categories.findIndex(cat => cat.id === action.payload.destinationId);

      const reordered = reorder(state.categories, sourceIndex, desinationIndex);
      return {
        ...state,
        categories: reordered
      }
    }
  }
})

export const { reducer } = slice;

export const setServices = (data: Service[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setServices(data));
}
export const createService = (data: Service): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createService(data));
}
export const updateService = (data: Service): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateService(data));
}
export const deleteService = (data: Service): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteService(data));
}
export const moveService = (data: { sourceId: string; destinationId: string; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.moveService(data));
}

export const setServiceCategories = (data: ServiceCategory[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setServiceCategories(data));
}
export const createServiceCategory = (data: ServiceCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createServiceCategory(data));
}
export const updateServiceCategory = (data: ServiceCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateServiceCategory(data));
}
export const deleteServiceCategory = (data: ServiceCategory): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteServiceCategory(data));
}
export const moveServiceCategory = (data: { sourceId: string; destinationId: string; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.moveServiceCategory(data));
}

export default slice;