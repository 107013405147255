import { AnyAction, Store } from 'redux';
import type { AppDispatch } from 'src/store/index';
import type { UserSmall } from 'src/types/api/user';
import { AxiosOptions, useAxios } from 'src/hooks/general/useAxios';
import { createUser, updateUser, deleteUser } from 'src/slices/lists/users';
import { setCurrentUser } from 'src/slices/current';
import { setGeneralUser } from 'src/slices/general/user';
import { setMenu } from 'src/slices/general/menu';
import type { RootState } from 'src/store';
import { AxiosInstance } from 'axios';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch, 
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions,
  axios: AxiosInstance
) => {
  channel.listen('.create_user', (data: any)=>{
    const users = data.data.response as UserSmall | UserSmall[];

    if(Array.isArray(users)){
      users.map((user) => 
        dispatch(createUser(user))
      );
      return;
    }
    dispatch(createUser(users));
  });
  channel.listen('.update_user', (data: any)=>{
    const currentUserId = store.getState().current.user?.contact.id;
    const generalUserId = store.getState().general.user?.contact.id;

    const user = data.data.response as UserSmall;
    
      dispatch(updateUser(user));

      if(currentUserId && currentUserId === user.contact.id){
        axios.get(`users/${user.contact.id}`, axiosOptions.apiConfig)
        .then((response) => {
          console.log('TODO: current alert');
          // setCurrentAlert({
          //   open: true,
          //   message: t("messages.current_user_updated"),
          //   user: data.data.user
          // });
          dispatch(setCurrentUser(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      }
      if(user.contact.id === generalUserId){
        console.log('CHECK WHAT TO DO HERE !!!!');

        axios.get(`users/${user.contact.id}`, axiosOptions.apiConfig)
        .then((response) => {
          dispatch(setGeneralUser(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

        axios.get('general/menu', axiosOptions.apiConfig)
        .then((response) => {
          dispatch(setMenu(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      }
    
  })
  channel.listen('.delete_user', (data: any)=>{ //MOET NOG ALS EIGEN GEBRUIKER VERWIJDERD WORDT
    const currentUserId = store.getState().current.user?.contact.id;
    
    const users = data.data.response as UserSmall[];
    users.map((user) => {
      if(currentUserId && currentUserId === user.contact.id){
        dispatch(setCurrentUser(null));
      }
      dispatch(deleteUser(user));
    });
  });

}