import { useContext } from 'react';
import { useTheme, Hidden } from '@mui/material';
import { AppContext } from 'src/App';
import { imageUrls } from 'src/config'; 

interface LogoProps {
  grayscale?: boolean;
  [key: string]: any;
}

export default function Logo({grayscale, ...rest}: LogoProps){
  const theme = useTheme();
  const appContext = useContext(AppContext);

  if(!appContext?.group.id){
    return null;
  }

  return <>
    <Hidden mdDown>
      <img
        alt="Logo"
        // @ts-ignore (Index met string kan niet gecheckt worden))
        src={imageUrls(appContext.group.contact.id)[`logo_${theme.name.toLowerCase()}`]}
        {...rest}
        style={{
          height: '44px',
          filter: grayscale ? 'grayscale(100%)' : 'grayscale(0%)',
          transition: `filter ${theme.transitions.duration.enteringScreen}ms`
        }}
      />
    </Hidden>
    <Hidden mdUp>
    <img
      alt="Logo"
        // @ts-ignore (Index met string kan niet gecheckt worden))
      src={imageUrls(appContext.group.contact.id)[`logo_${theme.name.toLowerCase()}`]}
      {...rest}
      style={{
        height: '34px'
      }}
    />
    </Hidden>
  </>;
}