import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import { useTranslation } from 'src/i18n';
import Page from 'src/components/general/Containers/Page';
import ContentContainer from 'src/components/general/Containers/ContentContainer';
import { setPaywallOpen } from 'src/slices/misc';
import { useDispatch } from 'src/store';

const useStyles = makeStyles()((theme: any) => ({
  // root: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)'
  // },
  container: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    height: '100vh'
  },
  square: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '5px',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  svg: {
    width: 'min(70vw, 70vh)',
    height: 'min(70vw, 70vh)',
    maxWidth: '600px',
    maxHeight: '450px',
    marginBottom: '10px',
  },
  content: {
    width: 'calc(min(100vw, 100vh) - 36px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordWrap: 'break-word',/* Enable text wrapping */
    wordBreak: 'break-word' /* Alternative property for better browser support */
    // maxWidth: '800px',
    // margin: '0px auto'
  }, 
  title: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(1.5),
    }
  },
  description: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1.5),
    }
  },
  button:{
    textAlign: 'center'
  }
}));

interface Props {
  pageTitle: string;
  title: string;
  subtitle: string;
  svgComponent: JSX.Element;
}

function UpgradeSubscriptionView({
  pageTitle,
  title,
  subtitle,
  svgComponent
}: Props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onUpgradeNow = () => {
    dispatch(setPaywallOpen(true)); 
  }

  return (
    <Page
      title={pageTitle}
    >
      <ContentContainer className={classes.container}>
        <Box className={classes.square}>
          <Box className={classes.svg}>
          {svgComponent}
          </Box>
          <Box className={classes.content}>
            <Typography
              className={classes.title}
              align="center"
              variant={mobileDevice ? 'h2' : 'h1'}
              color="textPrimary"
            >
              {title}
            </Typography>
            <Typography
              className={classes.description}
              align="center"
              variant="subtitle2"
              color="textSecondary"
            >
              {subtitle}
            </Typography>   
          </Box>
          <Box className={classes.button}>
            <Button
              color="secondary"
              variant="contained"
              onClick={onUpgradeNow}
            >
              {t("lang.upgrade_your_subscription")}
            </Button>  
          </Box>  
        </Box>     
      </ContentContainer>
    </Page>
  );
}

export default UpgradeSubscriptionView;
