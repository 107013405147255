import { createNote, deleteNote, updateNote } from 'src/slices/lists/notes';
import { removeNoteTyping } from 'src/slices/misc';
import type { AppDispatch } from 'src/store/index';
import type { Note } from 'src/types/api/note';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_note', (data: any) => {
    const note = data.data.response as Note;
    dispatch(createNote(note));
  });
  channel.listen('.update_note', (data: any) => {
    const note = data.data.response as Note;

    dispatch(updateNote(note));
    dispatch(removeNoteTyping({ note_id: note.id }));
  });
  channel.listen('.delete_note', (data: any) => {
    const note = data.data.response as Note;
    dispatch(deleteNote(note));
  });
}