import type { AppDispatch } from 'src/store/index';
import { createInvoiceWorkflow, updateInvoiceWorkflow, deleteInvoiceWorkflow } from 'src/slices/lists/invoicing';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel, 
  dispatch: AppDispatch
) => {
  console.log('TODO: Invoice workflow type');
  channel.listen('.create_invoice_workflow', (data: any) => {
    const invoiceWorkflow = data.data.response;
    dispatch(createInvoiceWorkflow(invoiceWorkflow));
  });
  channel.listen('.update_invoice_workflow', (data: any) => {//
    const invoiceWorkflow = data.data.response;
    dispatch(updateInvoiceWorkflow(invoiceWorkflow));
  });
  channel.listen('.delete_invoice_workflow', (data: any) => {//
    const invoiceWorkflow = data.data.response;
    dispatch(deleteInvoiceWorkflow(invoiceWorkflow));
  });
}