import TimePicker from './components/TimePicker';
import DatePicker from './components/DatePicker';
import dayjs from 'dayjs';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'src/i18n';
import { DEFAULT_TIME_DISTANCE } from './components/constants';
import { useEffect, useRef, useState } from 'react';

interface Props {
  value: {
    start: dayjs.Dayjs | null;
    end: dayjs.Dayjs | null;
  },
  onChange: (value: { start: dayjs.Dayjs | null; end: dayjs.Dayjs | null; }) => void;
  error?: {
    start: boolean;
    end: boolean;
  };
  onError?: React.Dispatch<React.SetStateAction<{ start: boolean; end: boolean; }>>;
  disabled?: boolean;
}

const DateTimeRangePicker = ({
  value,
  onChange,
  error,
  onError,
  disabled = false
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChange = (val: dayjs.Dayjs | null, position: 'start' | 'end') => {
    if (!val) {
      onChange({ start: null, end: null });
      return;
    }

    if (position === 'start') {
      let newEndValue = value.end ? value.end : val.isValid() ? val.add(DEFAULT_TIME_DISTANCE, 'minutes') : null;
      if (value.end && newEndValue) {
        const diffSeconds = (val.isValid() && value.start && value.start.isValid()) ? val.diff(value.start, 'seconds') : 0;
        newEndValue = newEndValue.add(diffSeconds, 'seconds');
      }
      onChange({ start: val, end: newEndValue });
    }
    if (position === 'end') {
      let newStartValue = value.start ? value.start : val.isValid() ? val.subtract(DEFAULT_TIME_DISTANCE, 'minutes') : null;
      onChange({ start: newStartValue, end: val });
    }
  }

  // detect wrap
  const [isWrapped, setIsWrapped] = useState(false);
  const checkIsWrapped = () => {
    const ref = rootRef.current;
    if(ref){
      const firstWrappableChild = ref.lastChild as HTMLDivElement;
      const offset = firstWrappableChild.offsetTop - ref.offsetTop;

      if(offset){
        if(!isWrapped){
          setIsWrapped(true);
        }
      }else{
        if(isWrapped){
          setIsWrapped(false);
        }
      }
    }
  }
  const rootRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    window.addEventListener('resize', checkIsWrapped);
    return () => {
      window.removeEventListener('resize', checkIsWrapped);
    }
  }, [isWrapped]);

  useEffect(() => {
    checkIsWrapped();
  }, [rootRef]);

  return (
    <div ref={rootRef} style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        <div>
          <DatePicker
            value={value.start}
            onChange={(val) => handleChange(val, 'start')}
            textFieldProps={{
              label: `${t('lang.start_date')}`
            }}
            error={error?.start}
            onError={(val) => onError && onError(prev => ({ ...prev, start: val }))}
            disabled={disabled}
          />
        </div>
        <div style={{ marginLeft: theme.spacing(3) }}>
          <TimePicker
            value={value.start}
            onChange={(val) => handleChange(val, 'start')}
            error={error?.start}
            onError={(val) => onError && onError(prev => ({ ...prev, start: val }))}
            disabled={disabled}
          />
        </div>
      </div>
      <div
        style={{
          color: theme.palette.text.primary,
          margin: `0px ${theme.spacing(3)}`,
          display: 'flex',
          alignItems: 'center',
          visibility: isWrapped ? 'hidden' : 'visible'
        }}
      >
        <span style={{ marginTop: theme.spacing(1.5) }}>
          &#8212;
        </span>
      </div>
      <div 
        style={isWrapped ? { 
          display: 'inline-flex', 
          paddingTop: theme.spacing(3) 
        } : { 
          display: 'inline-flex' 
        }}
      >
        <div>
          <DatePicker
            value={value.end}
            onChange={(val) => handleChange(val, 'end')}
            staticDatePickerProps={{
              minDate: (value.start && value.start.isValid()) ? value.start : undefined,
            }}
            textFieldProps={{
              label: `${t('lang.end_date')}`
            }}
            error={error?.end}
            onError={(val) => onError && onError(prev => ({ ...prev, end: val }))}
            disabled={disabled}
          />
        </div>
        <div style={{ marginLeft: theme.spacing(3) }}>
          <TimePicker
            value={value.end}
            onChange={(val) => handleChange(val, 'end')}
            error={error?.end}
            onError={(val) => onError && onError(prev => ({ ...prev, end: val }))}
            minTime={(value.start && value.start.isValid()) ? value.start : undefined}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}

export default DateTimeRangePicker;