import { Typography } from "@mui/material";
import { makeStyles } from "src/theme/makeStyles";

const useStyles = makeStyles()(theme => ({
  root: {
    height: 80,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5)
  },
  titleRoot: {
    display: 'inline-flex', 
    width: '100%', 
    alignItems: 'center'
  },
  time: {
    marginLeft: 'auto'
  }
}));

interface Props {
  title: string;
  content: string;
  time: string;
}

const Notification = ({
  title,
  content,
  time
}: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleRoot}>
        <Typography>
          {title}
        </Typography>
        <Typography 
          className={classes.time}
          variant='caption'
        >
          {time}
        </Typography>
      </div>
      <Typography variant='body2'>
        {content}
      </Typography>
    </div>
  )
}
export default Notification;