import { useEffect } from 'react';
import { useTranslation } from 'src/i18n';
import { useSelector } from 'src/store';

export default function LanguageCheck(){
  const {t, i18n} = useTranslation();
  const user_language = useSelector(state => state.general.user?.language);

  useEffect(() => {
    if(user_language){
      i18n.changeLanguage(user_language);
    }
  }, [user_language]);
  return null;
}