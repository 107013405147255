import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Folder } from 'src/types/api/folder';

const initialState: Folder[] = [];

const slice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setFolders(state: Folder[], action: PayloadAction<Folder[]>){
      return action.payload;
    },
    createFolder(state: Folder[], action: PayloadAction<Folder>){
      state.push(action.payload);
    },
    updateFolder(state: Folder[], action: PayloadAction<Folder>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteFolder(state: Folder[], action: PayloadAction<Folder>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setFolders = (data: Folder[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFolders(data));
}

export const createFolder = (data: Folder): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createFolder(data));
}

export const updateFolder = (data: Folder): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFolder(data));
}

export const deleteFolder = (data: Folder): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteFolder(data));
}

export default slice;