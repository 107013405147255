import { deleteInvoice, insertInvoice } from 'src/slices/lists/invoicing';
import type { AppDispatch, RootState } from 'src/store/index';
import { EchoChannel as Channel } from '../index';
import { AxiosOptions } from 'src/hooks/general/useAxios';
import { AnyAction, Store } from 'redux';
import { createSortFilterUrlParams } from 'src/utils/general/collationUtils';
import { getInvoiceRequest } from 'src/requests/invoices';

const invoiceHandler = (
  channel: Channel, 
  dispatch: AppDispatch,
  store: Store<RootState, AnyAction>,
  axiosOptions: AxiosOptions
) => {
  channel.listen('.create_invoice', (data: any) => {
    const socketResponse = data.data.response as { id: string; };

    const invoicesState = store.getState().lists.invoicing.invoices.data;
    const filtersState = store.getState().filters.invoices;

    getInvoiceRequest(
      socketResponse.id,
      dispatch,
      axiosOptions.apiConfig, 
      createSortFilterUrlParams(filtersState.sort, filtersState.filters, filtersState.search, 0, invoicesState.length)
    ).then(response => {
      if(response.data && response.meta.position !== null){
        dispatch(insertInvoice({ data: response.data, index: response.meta.position }));
      }
    });
  });

  channel.listen('.update_invoice', (data: any) => {
    const socketResponse = data.data.response as { id: string; };

    const invoicesState = store.getState().lists.invoicing.invoices.data;
    const filtersState = store.getState().filters.invoices;

    getInvoiceRequest(
      socketResponse.id, 
      dispatch,
      axiosOptions.apiConfig,
      createSortFilterUrlParams(filtersState.sort, filtersState.filters, filtersState.search, 0, invoicesState.length)
    ).then(response => {
      if(response.data){
        dispatch(deleteInvoice(response.data));
        if(response.meta.position !== null){
          dispatch(insertInvoice({ data: response.data, index: response.meta.position }))
        }
      }
    });
  });

  channel.listen('.delete_invoice', (data: any) => {
    const socketResponse = data.data.response as { id: string; };

    const invoicesState = store.getState().lists.invoicing.invoices.data;
    const filtersState = store.getState().filters.invoices;

    const invoice = invoicesState.find(invoice => invoice.id === socketResponse.id);

    const urlParams = createSortFilterUrlParams(filtersState.sort, filtersState.filters, filtersState.search, 0, invoicesState.length);
    urlParams.append('ignoreNotFound', 'true');

    getInvoiceRequest(
      socketResponse.id, 
      dispatch,
      axiosOptions.apiConfig,
      urlParams
    ).then(() => {
      if(invoice)
        dispatch(deleteInvoice(invoice));
    });

  });
}
export default invoiceHandler;