import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Unstable_Grid2 as Grid2, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ProgressiveImage from "react-progressive-graceful-image";
import EmailField from "src/components/forms/fields/Email";
import FirstUpperField from "src/components/forms/fields/FirstUpper";
import LastNameField from 'src/components/forms/fields/LastName';
import PasswordField from 'src/components/forms/fields/Password';
import SubscriptionSelect from 'src/components/forms/fields/SubscriptionSelect';
import TemplateSelect from 'src/components/forms/fields/TemplateSelect';
import validations from 'src/components/forms/validations';
import ColorPicker from 'src/components/general/ColorPicker';
import ColorPickerWithHex from 'src/components/general/ColorPickerWithHex';
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import ImagePicker, { commonAspectRatios } from 'src/components/general/ImagePicker';
import { apiConfig } from 'src/config';
import { useAxiosOptions } from "src/hooks/general/useAxios";
import useClaim from 'src/hooks/general/useClaim';
import { useTranslation } from 'src/i18n';
import { CreateGroupRequestData, UpdateGroupRequestData, createGroupRequest, updateGroupRequest } from 'src/requests/system/groups';
import { closeForm } from 'src/slices/forms';
import { useDispatch, useSelector } from "src/store";
import * as yup from 'yup';
import { DEFAULT_VALUES } from "./constants";
import { FormValues } from "./types";

type GroupColor = 'light_1' | 'light_2' | 'light_3' | 'dark_1' | 'dark_2' | 'dark_3';

const yup_schema_create = yup.object({
  template_id: validations.default_string,
  subscription_id: validations.default_string,
  company_name: validations.default_string,
  alias: validations.alias(),
  contact: yup.object({
    email: validations.email,
    first_name: validations.default_string,
    last_name: validations.default_string,
    password: validations.new_password,
    c_password: validations.confirm_new_password()
  }),
  colors: yup.object({
    light_1: validations.default_string,
    light_2: validations.default_string,
    light_3: validations.default_string,
    dark_1: validations.default_string,
    dark_2: validations.default_string,
    dark_3: validations.default_string
  })
});

const yup_schema_update = yup.object({
  template_id: validations.default_string,
  subscription_id: validations.default_string,
  company_name: validations.default_string,
  alias: validations.alias(),
  colors: yup.object({
    light_1: validations.default_string,
    light_2: validations.default_string,
    light_3: validations.default_string,
    dark_1: validations.default_string,
    dark_2: validations.default_string,
    dark_3: validations.default_string
  })
});

const Group = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const axiosOptions = useAxiosOptions();

  const groups = useSelector(state => state.lists.groups);
  const templates = useSelector(state => state.lists.templates);

  const {
    open: formOpen,
    initialValues,
    updateId
  } = useSelector(state => state.forms.group);

  const handleClose = () => {
    dispatch(closeForm('group'));
  }

  const {
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(!!updateId ? yup_schema_update : yup_schema_create )
  });

  const watchValues = watch();

  // set values or clear on open
  useEffect(() => {
    if (formOpen && !updateId) {
      reset({ ...DEFAULT_VALUES, ...initialValues });
    }
  }, [formOpen]);

  const groupToUpdate = useMemo(() =>
    groups.find(group => group.id === updateId)
  , [groups, updateId]);

  useEffect(() => {
    if (groupToUpdate) {
      reset({
        ...DEFAULT_VALUES,
        // data from groupToUpdate
        // ...groupToUpdate,
        ...{
          // company_name, subscription, tempalate, alias, language, cname, colors, images?
          company_name: groupToUpdate.contact.name,
          template_id: groupToUpdate.template.id,
          subscription_id: groupToUpdate.subscription ? groupToUpdate.subscription.subscription.id : '', // TODO
          alias: groupToUpdate.alias,
          language: groupToUpdate.language as 'nl' | 'en',
          cname: groupToUpdate.cname,
          colors: {
            light_1: groupToUpdate.color1_light,
            light_2: groupToUpdate.color2_light,
            light_3: groupToUpdate.color3_light,
            dark_1: groupToUpdate.color1_dark,
            dark_2: groupToUpdate.color2_dark,
            dark_3: groupToUpdate.color3_dark
          },
        },
        ...initialValues
      });
    }
  }, [groupToUpdate]);

  const handleValidSubmit = async (values: FormValues) => {
    console.log('valid submit');
    console.log(values);


    if(!values.template_id){
      return;
    }

    if (!!updateId && groupToUpdate) {
      // update
      let data: UpdateGroupRequestData = {
        template_id: values.template_id,
        subscription_id: values.subscription_id,
        alias: values.alias,
        color1_light: values.colors.light_1,
        color2_light: values.colors.light_2,
        color3_light: values.colors.light_3,
        color1_dark: values.colors.dark_1,
        color2_dark: values.colors.dark_2,
        color3_dark: values.colors.dark_3,
        language: values.language,
        images: { ...values.images },
        clear_images: false
      }
      if(values.cname){
        data.cname = values.cname;
      }

      try {
        await updateGroupRequest(groupToUpdate.contact.id, data, dispatch, axiosOptions.apiConfig)
        .then(handleClose);
      }catch(err){
        console.log(err);
      }

    } else {
      const data: CreateGroupRequestData = {
        company_name: values.company_name,
        first_name: values.contact.first_name,
        last_name: values.contact.last_name,
        template_id: values.template_id,
        subscription_id: values.subscription_id,
        alias: values.alias,
        cname: values.cname,
        color1_light: values.colors.light_1,
        color2_light: values.colors.light_2,
        color3_light: values.colors.light_3,
        color1_dark: values.colors.dark_1,
        color2_dark: values.colors.dark_2,
        color3_dark: values.colors.dark_3,
        language: values.language,
        email: values.contact.email,
        password: values.contact.password,
        c_password: values.contact.c_password,
        images: { ...values.images }
      }

      try {
        await createGroupRequest(data, dispatch, axiosOptions.apiConfig)
        .then(handleClose);
      }catch(err){
        console.log(err);
      }
    }
  }

  // const handleDelete = async () => {
  //   if (updateId) {
  //     console.log('todo: delete');
  //   }
  // }

  const { currentUserHasFirstClaim } = useClaim(updateId ? updateId : null);
  const disableAllFields = !!(updateId && !currentUserHasFirstClaim);

  useEffect(() => {
    if (!templates || !templates.length) {
      console.error('No templates!');
    }
  }, [templates]);


  const [colorPickerOpen, setColorPickerOpen] = useState<GroupColor | null>(null)

  const groupColorsLight: GroupColor[] = ['light_1', 'light_2', 'light_3'];
  const groupColorsDark: GroupColor[] = ['dark_1', 'dark_2', 'dark_3'];

  const [imagePickerOpen, setImagePickerOpen] = useState<keyof FormValues['images'] | null>(null);
  const handleImageChange = (value: string | null) => {
    if (!imagePickerOpen) {
      return;
    }

    setValue(`images.${imagePickerOpen}`, value);
    setImagePickerOpen(null);
  }

  const imagePickerAspectRatio = useMemo(() => {
    if (imagePickerOpen === 'background') {
      return commonAspectRatios.screen;
    }
    if (imagePickerOpen === 'logo_light' || imagePickerOpen === 'logo_dark') {
      return commonAspectRatios.logo;
    }
    if (imagePickerOpen === 'favicon_light' || imagePickerOpen === 'favicon_dark') {
      return commonAspectRatios.avatar;
    }

    return undefined;
  }, [imagePickerOpen]);

  const getImageSources = (image: keyof FormValues['images'], extension: 'png' | 'jpg') => {
    const selectedImage = watchValues.images[image];
    if(selectedImage){
      return {
        src: selectedImage,
        placeholder: selectedImage
      }
    }

    if(groupToUpdate){
      return {
        src: `${apiConfig.storage_url}profiles/${groupToUpdate.contact.id}/img/${image}.${extension}`,
        placeholder: `${apiConfig.storage_url}profiles/${groupToUpdate.contact.id}/timg/${image}.${extension}`,
      }
    }

    const template = watchValues.template_id ? watchValues.template_id : 'default';
    
    return {
      src: `${apiConfig.storage_url}templates/${template}/img/${image}.${extension}`,
      placeholder: `${apiConfig.storage_url}templates/${template}/timg/${image}.${extension}`
    }
  }

  useEffect(() => {
    if(watchValues.template_id){
      const template = templates.find(template => template.id === watchValues.template_id);
      if(template){
        setValue('colors', { 
          light_1: template.color1_light,
          light_2: template.color2_light,
          light_3: template.color3_light,
          dark_1: template.color1_dark,
          dark_2: template.color2_dark,
          dark_3: template.color3_dark
        })
      }
    }
  }, [watchValues.template_id]);

  const subscriptionsToRender = useMemo(() => {
    const template = templates.find(template => template.id === watchValues.template_id);
    if(template){
      return template.subscriptions;
    }
    return null;
  }, [templates, watchValues.template_id]);

  useEffect(() => {
    setValue('subscription_id', '');
  }, [watchValues.template_id]);

  return (
    <DuotoneDialog
      open={formOpen}
      title={updateId ?
        `${t('lang.update_group')}` :
        `${t('lang.new_group')}`
      }
      onClose={handleClose}
      submitButtonProps={{
        disabled: disableAllFields || isSubmitting
      }}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      onSubmit={handleSubmit(handleValidSubmit, (e) => console.log(e))}
      dialogProps={{
        maxWidth: 'lg'
      }}
    // infoBar={updateId ? {
    //   open: !currentUserHasFirstClaim,
    //   title: claimUser ? `${claimUser.contact.name} ${t('lang.is_editing_task')}` : undefined,
    //   startComponent: !claimUser ? null : (
    //     <Avatar
    //       name={claimUser.contact.name}
    //       user_id={claimUser.id}
    //       size='small'
    //     />
    //   )
    // } : undefined}
    >
      <>
        <Grid2 container spacing={3}
        // style={{ paddingTop: theme.spacing(3) }}
        >
          <Grid2 xs={6}>
            {/* Here ? */}
            <Grid2
              container
              spacing={3}
            // style={{ 
            //   border: `1px solid ${theme.palette.divider}`,
            //   borderRadius: 4 
            // }}
            >
              <Grid2 xs={12} style={{ paddingBottom: 0 }}>
                <Typography>{t('lang.group')}</Typography>
              </Grid2>
              <Grid2 xs={6} style={{ paddingTop: 0 }}>
                <TemplateSelect 
                  value={watchValues.template_id}
                  onChange={(template_id) => setValue('template_id', template_id)}
                  textFieldProps={{
                    error: !!errors.template_id,
                    helperText: errors.template_id?.message
                  }}
                />
              </Grid2>
              <Grid2 xs={6} style={{ paddingTop: 0 }}>
                <SubscriptionSelect 
                  subscriptions={subscriptionsToRender ? subscriptionsToRender : []}
                  value={watchValues.subscription_id}
                  onChange={(subscription_id) => setValue('subscription_id', subscription_id)}
                  textFieldProps={{
                    error: !!errors.subscription_id,
                    helperText: errors.subscription_id?.message
                  }}
                />
              </Grid2>
              <Grid2 xs={6}>
                <Controller
                  control={control}
                  name='company_name'
                  render={({ field }) => (
                    <TextField
                      variant='standard'
                      label={`${t('lang.company_name')}`}
                      fullWidth
                      error={!!errors.company_name}
                      helperText={errors.company_name?.message}
                      disabled={!!updateId}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  variant='standard'
                  name="language"
                  label={t("lang.language")}
                  value={watchValues.language}
                  fullWidth
                  onChange={(e) => {
                    const value = e.target.value as 'nl' | 'en';
                    setValue('language', value)
                  }}
                  select
                // error={errors.cname && errors.cname}
                // helperText={errors.prefix && errors.prefix}
                >
                  <MenuItem value={"nl"}>{t("lang.dutch")}</MenuItem>
                  <MenuItem value={"en"}>{t("lang.english")}</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={6}>
                <Controller
                  control={control}
                  name='alias'
                  render={({ field }) => (
                    <TextField
                      variant='standard'
                      label={`${t('lang.alias')}`}
                      fullWidth
                      error={!!errors.alias}
                      helperText={errors.alias?.message}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={6}>
                <Controller
                  control={control}
                  name='cname'
                  render={({ field }) => (
                    <TextField
                      variant='standard'
                      label={`${t('lang.cname')}`}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 xs={6}>
            {/* Contact */}
            {!updateId && (
              <Grid2 container spacing={3}>
                <Grid2 xs={12} style={{ paddingBottom: 0 }}>
                  <Typography>{t('lang.contact')}</Typography>
                </Grid2>
                <Grid2 xs={12} style={{ paddingTop: 0 }}>
                  {/* Email */}
                  <Controller
                    name='contact.email'
                    control={control}
                    render={({ field }) => (
                      <EmailField
                        fullWidth
                        error={!!errors.contact?.email}
                        helperText={errors.contact?.email?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Controller
                    control={control}
                    name='contact.first_name'
                    render={({ field }) => (
                      <FirstUpperField
                        variant='standard'
                        label={`${t('lang.first_name')}`}
                        fullWidth
                        error={!!errors.contact?.first_name}
                        helperText={errors.contact?.first_name?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Controller
                    control={control}
                    name='contact.last_name'
                    render={({ field }) => (
                      <LastNameField
                        variant='standard'
                        label={`${t('lang.last_name')}`}
                        fullWidth
                        error={!!errors.contact?.last_name}
                        helperText={errors.contact?.last_name?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Controller
                    control={control}
                    name='contact.password'
                    render={({ field }) => (
                      <PasswordField
                        variant='standard'
                        label={`${t('lang.password')}`}
                        fullWidth
                        error={!!errors.contact?.password}
                        helperText={errors.contact?.password?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Controller
                    control={control}
                    name='contact.c_password'
                    render={({ field }) => (
                      <PasswordField
                        variant='standard'
                        label={`${t('lang.confirm_password')}`}
                        fullWidth
                        error={!!errors.contact?.c_password}
                        helperText={errors.contact?.c_password?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Divider style={{ margin: `${theme.spacing(3)} 0px` }} />
        <Box mt={3}>
          <Grid2 container spacing={3}>
            <Grid2 xs={6}>
              <Typography>{t('lang.colors')}</Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {groupColorsLight.map((color, index) => (
                  <Box key={index} mt={1} mr={3}>
                    <ColorPickerWithHex
                      label={
                        color.startsWith('dark') ?
                          `${t('lang.dark')} ${color.replace('dark_', '')}` :
                          `${t('lang.light')} ${color.replace('light_', '')}`
                      }
                      value={watchValues.colors[color]}
                      onChange={(val) => setValue(`colors.${color}`, val)}
                      inputBaseProps={{
                        error: !!errors.colors ? !!errors.colors[color] : false
                      }}
                    />
                  </Box>
                ))}
              </div>
              <Box mt={3} style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <Typography>Logo (light mode)</Typography>
                  <ProgressiveImage
                    {...getImageSources('logo_light', 'png')}
                  >
                    {(src: string) => (
                      <img
                        style={{ width: '100%', cursor: 'pointer', backgroundColor: '#fff' }}
                        src={src}
                        onClick={() => setImagePickerOpen('logo_light')}
                        alt='logo_light'
                      ></img>
                    )}
                  </ProgressiveImage>
                </div>
                <div style={{ paddingLeft: theme.spacing(3) }}>
                  <div style={{ width: 180 }}>
                    <Typography>Favicon (light mode)</Typography>
                    <ProgressiveImage
                      {...getImageSources('favicon_light', 'png')}
                    >
                      {(src: string) => (
                        <img
                          style={{ width: '100%', cursor: 'pointer', backgroundColor: '#fff' }}
                          src={src}
                          onClick={() => setImagePickerOpen('favicon_light')}
                          alt='favicon_light'
                        ></img>
                      )}
                    </ProgressiveImage>
                  </div>
                </div>
              </Box>
            </Grid2>
            <Grid2 xs={6}>
            <Typography>{t('lang.colors')}</Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {groupColorsDark.map((color, index) => (
                  <Box key={index} mt={1} mr={3}>
                    <ColorPickerWithHex
                      label={
                        color.startsWith('dark') ?
                          `${t('lang.dark')} ${color.replace('dark_', '')}` :
                          `${t('lang.light')} ${color.replace('light_', '')}`
                      }
                      value={watchValues.colors[color]}
                      onChange={(val) => setValue(`colors.${color}`, val)}
                      inputBaseProps={{
                        error: !!errors.colors ? !!errors.colors[color] : false
                      }}
                    />
                  </Box>
                ))}
              </div>
              <Box mt={3} style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <Typography>Logo (dark mode)</Typography>
                  <ProgressiveImage
                    {...getImageSources('logo_dark', 'png')}
                  >
                    {(src: string) => (
                      <img
                        style={{ width: '100%', cursor: 'pointer', backgroundColor: '#282C34' }}
                        src={src}
                        onClick={() => setImagePickerOpen('logo_dark')}
                        alt='logo_dark'
                      ></img>
                    )}
                  </ProgressiveImage>
                </div>
                <div style={{ paddingLeft: theme.spacing(3) }}>
                  <div style={{ width: 180 }}>
                    <Typography>Favicon (dark mode)</Typography>
                    <ProgressiveImage
                      {...getImageSources('favicon_dark', 'png')}
                    >
                      {(src: string) => (
                        <img
                          style={{ width: '100%', cursor: 'pointer', backgroundColor: '#282C34' }}
                          src={src}
                          onClick={() => setImagePickerOpen('favicon_dark')}
                          alt='favicon_dark'
                        ></img>
                      )}
                    </ProgressiveImage>
                  </div>
                </div>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
        <Box mt={3}>
          <Grid2 container spacing={3}>
            <Grid2 xs={6}>
              <Typography>{t('lang.background')}</Typography>
              <ProgressiveImage
                {...getImageSources('background', 'jpg')}
              >
                {(src: string) => (
                  <img
                    style={{ aspectRatio: '16/9', width: '100%', cursor: 'pointer' }}
                    src={src}
                    onClick={() => setImagePickerOpen('background')}
                    alt='background'
                  ></img>
                )}  
              </ProgressiveImage>
            </Grid2>
            <Grid2 xs={6}>
            </Grid2>
          </Grid2>
        </Box>

        <ColorPicker
          open={!!colorPickerOpen}
          handleClose={() => setColorPickerOpen(null)}
          onClick={(value) => {
            if (!!colorPickerOpen) {
              setValue(`colors.${colorPickerOpen}`, value);
            }
            setColorPickerOpen(null);
          }}
        />
      
        <ImagePicker
          open={!!imagePickerOpen}
          onChange={handleImageChange}
          onClose={() => setImagePickerOpen(null)}
          aspectRatio={imagePickerAspectRatio}
          initialImageSrc={
            (imagePickerOpen && watchValues.images[imagePickerOpen]) ? watchValues.images[imagePickerOpen]! :
              undefined
          }
        />
      </>
    </DuotoneDialog>
  )
}
export default Group;