import { FormValues } from './types';

export const DEFAULT_VALUES: FormValues = {
  template_id: null,
  title: '',
  description: '',
  price: undefined,
  duration: 1,
  recurrence: 'month',
  is_default: false
};