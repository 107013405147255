import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "src/theme/makeStyles";
import { ContactAddress, ContactSmall } from "src/types/api/contact";
import BusinessIcon from '@mui/icons-material/HomeWorkOutlined';
import { useTranslation } from "src/i18n";
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';

const useStyles = makeStyles()(theme => ({
  addressRow: {
    display: 'flex',
    padding: theme.spacing(1.5),
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    '&:hover': {
      background: theme.custom.backgroundDark
    }
  },
  addressContent: {
    display: 'inline-flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1.5),
    overflow: 'hidden'
  }
}));

interface Props {
  contact: ContactSmall;
  onAddressClick: (addres: ContactAddress | null) => void;
  loading: boolean;
  addresses: ContactAddress[] | null;
  allowNoAddress?: boolean;
}

const AddressPickerContent = ({
  contact,
  onAddressClick,
  loading,
  addresses,
  allowNoAddress = false
}: Props) => {
  const { classes, theme } = useStyles();
  const {t} = useTranslation();

  if(loading){
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <Typography>{t('lang.select_address_for')}: {contact.name}</Typography>
      <div
        style={{
          // width: selectedContact ? '50%' : '0%',
          // marginLeft: selectedContact ? theme.spacing(3) : 0,
          // transition: 'width 0.5s, margin-left 0.5s',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 4,
          marginTop: theme.spacing(1.5)
        }}
      >
        {allowNoAddress && !!addresses && (
          <div
            className={classes.addressRow}
            style={{
              borderBottom: addresses.length ? `1px solid ${theme.palette.divider}` : undefined
            }}
            onClick={() => onAddressClick(null)}
          >
            <DomainDisabledIcon />
            <div className={classes.addressContent}>
              <Typography variant='body2' textOverflow='ellipsis'>Geen adres gebruiken</Typography>
            </div>
          </div>
        )}

        {addresses?.map((address, index) => (
          <div
            key={index}
            className={classes.addressRow}
            style={{
              borderBottom: index + 1 === addresses.length ? undefined : `1px solid ${theme.palette.divider}`
              // borderBottom: `1px solid ${theme.palette.divider}`
            }}
            onClick={() => onAddressClick(address)}
          >
            <BusinessIcon />
            <div className={classes.addressContent}>
              <Typography variant='body2' textOverflow='ellipsis'>{address.street} {address.number}</Typography>
              <Typography variant='body2' textOverflow='ellipsis'>{address.zip} {address.city}</Typography>
              <Typography variant='body2' textOverflow='ellipsis'>Nederland, {address.state}</Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
export default AddressPickerContent;