  
import { useState, useRef, useEffect, useCallback } from 'react';

function useCaretPosition<
  T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>() {
  const node = useRef<T>(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const updateCaret = useCallback(() => {
    // Get the updated caret postions from the ref passed in
    if (node && node.current) {
      const { selectionStart, selectionEnd } = node.current;

      setStart(selectionStart!);
      setEnd(selectionEnd!);
    }
  }, []);


  useEffect(() => {
    const isFocusedNode = node.current == document.activeElement;
    if(node && node.current && isFocusedNode) {
      node.current.setSelectionRange(start, end);
    }
  });

  return { start, end, ref: node, updateCaret };
}

export default useCaretPosition;