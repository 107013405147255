import { useTheme, ThemeProvider } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import { useMemo, useState, useEffect } from 'react';
import StackAvatars from 'src/custom/MyStackAvatars';
import { useHasAllPermissions } from 'src/hooks/general/useHasPermissions';
import useIsMobile from 'src/hooks/general/useIsMobile';
import { usePageUsers } from 'src/hooks/general/usePageUsers';
import { useSelector } from 'src/store';
import type { UserSmall } from 'src/types/api/user';
import NavBar from './NavBar';
import PresenceBar from './PresenceBar';
import WelcomeStepper from './WelcomeStepper';
import Topbar from './Topbar';
import PayWall from './PayWall';

interface DashboardLayoutProps {
  children: React.ReactNode;
  darkTheme: any; // Theme
}

const useStyles = makeStyles()((theme: any) => ({
  mobileNavbar: {
    width: 0
  },
  navbarRootClosed: {
    width: 75,
    '&:hover': {
      width: 256
    },
    transition: '0.5s'
  },
  avatarRoot: {
    border: 'none'
  }
}));

interface RenderStackAvatarsProps {
  limit: number;
}

const RenderStackAvatars = ({ limit }: RenderStackAvatarsProps) => {
  const pageUsers = usePageUsers();
  const avatars = useSelector(state => state.misc.avatars);
  const { classes } = useStyles();

  const memberAvatars = useMemo(() => {
    return pageUsers.map((pageUser) => {
      if (!avatars[pageUser.id]) {
        return {
          url: null,
          name: pageUser.name
        }
      }
      return {
        url: `data:image/jpeg;base64,${avatars[pageUser.id]}`,
        name: pageUser.name
      }
    });
  }, [pageUsers, avatars]);

  return (
    <div style={{
      position: 'absolute',
      bottom: 15,
      left: 15,
      zIndex: 9
    }}>
      <StackAvatars
        classes={{ avatar: classes.avatarRoot }}
        limit={limit}
        avatars={memberAvatars}
        noBorder
      />
    </div>
  )
}

const DashboardLayout = ({ children, darkTheme }: DashboardLayoutProps) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const topbarData = useSelector(state => state.topbar);
  const users = useSelector(state => state.lists.users);

  const canViewChat = useHasAllPermissions(['read_chat']) && users.length > 1;

  const [selectedUser, setSelectedUser] = useState<UserSmall | null>(null);
  const [presenceOpen, setPresenceOpen] = useState(false);

  const [navbarOpen, setNavbarOpen] = useState(true);
  const [navHovering, setNavHovering] = useState(false);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileNavOpenChat, setMobileNavOpenChat] = useState(false);

  const [shouldTransition, setShouldTransition] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShouldTransition(true);
    }, 295);
  }, []);

  return (
    <>
      {canViewChat &&
        <PresenceBar
          presenceOpen={presenceOpen}
          setPresenceOpen={setPresenceOpen}
          selectedUser={selectedUser ? selectedUser : {}}
          setSelectedUser={setSelectedUser}
          menuInactive={false}
        />
      }
      {/* 
      // @ts-ignore */}
        <ThemeProvider theme={darkTheme}>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            fullyOpen={navHovering || navbarOpen}
            setFullyOpen={setNavHovering}
            navPinned={navbarOpen}
            setNavPinned={setNavbarOpen}
            menuInactive={false}
          />
        </ThemeProvider>

        <Topbar 
          onMobileNavOpen={() => setMobileNavOpen(true)}
          onMobileNavOpenChat={() => setMobileNavOpenChat(!mobileNavOpenChat)}
          navPinned={navbarOpen}
          fullyOpen={navHovering}
          setFullyOpen={setNavHovering}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setPresenceOpen={setPresenceOpen}
          menuInactive={false}
        />
      <WelcomeStepper/>
      <div
        style={{
          position: 'absolute',
          top: topbarData.tabs.length ? 64 + 48 : 64,
          // top: 64,
          bottom: 0,
          right: canViewChat && !isMobile ? 75 : 0,
          left: isMobile ? 0 : navbarOpen ? 256 : 75,
          transition: shouldTransition ? theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: 295
          }) : 'none'
        }}
      >

        <div
          style={{
            height: '100%',
            opacity: 1,
            transition: theme.transitions.create('opacity', { easing: theme.transitions.easing.sharp, duration: 295 }),
          }}
        >
          {children}
        </div>
        <RenderStackAvatars limit={navbarOpen ? 20 : 5} />
      </div>
      <PayWall />
    </>
  );
}

export default DashboardLayout;