import type { ButtonProps } from '@mui/material';
import { alpha, Button, useTheme, Typography } from '@mui/material';
import clsx from 'clsx';
import type {
  ReactNode
} from 'react';
import DynamicIcon, { MDIcon } from 'src/components/general/DynamicIcon';
import { makeStyles } from 'src/theme/makeStyles';

const useStyles = makeStyles()(theme => ({
  button: {
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.paper
  },
  primaryDisabled: {
    // backgroundColor: `${theme.palette.background.default} !important`
  },
  buttonDark: {
    color: theme.palette.getContrastText(theme.palette.background.default),
    // backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.default, 0.4)
    }
  },
  buttonRootDarkDisabled: {
  },
  buttonDelete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  },
  rootError: {
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 4
  }
}));

export interface ActionButtonProps extends ButtonProps {
  icon?: MDIcon | JSX.Element;
  children?: ReactNode;
  disabled?: boolean;
  isDelete?: boolean;
  primaryColor?: boolean;
  disableMarginBottom?: boolean;
  error?: boolean;
}

// type ?: primary | delete | grey/default

const ActionButton = ({
  icon,
  children,
  disabled = false,
  isDelete = false,
  primaryColor = false,
  disableMarginBottom = false,
  error = false,
  ...other
}: ActionButtonProps) => {
  const theme = useTheme();
  const { classes } = useStyles();

  // @ts-ignore
  const isDarkTheme = theme.name === 'DARK';

  return (
    <div 
      style={{ 
        position: 'relative',
        marginBottom: disableMarginBottom ? 0 : 18,
        cursor: disabled ? 'not-allowed' : 'pointer'
      }}
    >
      <Button
        color={primaryColor ? 'primary' : 'inherit'}
        variant="contained"
        startIcon={icon && typeof icon === 'string' ? (
          <DynamicIcon 
            icon={icon}
            iconProps={{ size: '1.3rem' }}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        ) : icon}
        classes={isDarkTheme && !primaryColor ? { disabled: classes.buttonRootDarkDisabled } : {}}
        className={clsx(
          classes.button, 
          isDarkTheme && !primaryColor && classes.buttonDark,
          isDelete && classes.buttonDelete,
          primaryColor && disabled && isDarkTheme && classes.primaryDisabled,
          error && classes.rootError
        )}
        fullWidth
        disabled={disabled}
        {...other}
      >
        {children}
      </Button>
    </div>
  );
};

export default ActionButton;