import { AxiosRequestConfig } from "axios";
import { AppDispatch } from "src/store";
import axios from 'axios';
import { EntitySetting } from "src/types/api/setting";
import { updateSetting } from "src/slices/general/settings";
import { SalonType } from 'src/layouts/DashboardLayout/WelcomeStepper/steps/SelectSalonType/types';

interface CompanyInformationData {
  company_name: string;
  street: string;
  number: string; // number ???
  addition: string | null; // empty string is null for backend !
  zipcode: string;
  phone_number: string;
  coc_number: string;
  vat_number: string;
}

interface OpeningHoursData {
  day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
  enabled: boolean;
  times: { start: string; end: string; }[]
}

interface UserData {
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
  language: string;
  avatar: string | null;
}

export interface StepperRequestData {
  company_information?: CompanyInformationData;
  opening_hours?: OpeningHoursData[];
  users?: UserData[];
  salon_type?: SalonType;
}

export const stepperRequest = (
  data: StepperRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<EntitySetting[]> => {
  return new Promise<EntitySetting[]>((resolve, reject) => {
    axios.post<EntitySetting[]>(`stepper`, data, config)
    .then((response) => {
      response.data.map(setting => 
        dispatch(updateSetting(setting))
      );
      resolve(response.data);
    })
    .catch((error) => reject(error));
  });
}