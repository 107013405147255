import { AxiosRequestConfig } from "axios";
import { AnyAction, Store } from "redux";
import { getEventRequest, getEventsRequest } from "src/requests/events";
import { GetPositionRequestData, getPositionRequest } from "src/requests/position";
import { EventState, EventStateViewKey, createOrUpdateEvent, deleteEventById, setEventView, setEvents } from "src/slices/lists/events";
import { AppDispatch, RootState } from "src/store";
import { Event } from "src/types/api/events/Event";
import { createSortFilterUrlParams } from "src/utils/general/collationUtils";

const getCurrentInitializedViews = (store: Store<RootState, AnyAction>) => {
  const currentViews = store.getState().lists.events.events.views;
  const currentInitializedViews = { ...currentViews }

  Object.entries(currentViews).forEach(([key, value]) => {
    const view = key as EventStateViewKey;
    if (!value.initialized) {
      delete currentInitializedViews[view];
    }
  });

  return currentInitializedViews;
}

const handlePositionRequestFromInitializedViews = (
  eventId: string,
  currentInitializedViews: EventState['events']['views'],
  apiConfig: AxiosRequestConfig
) => {
  // Build the data for the position request
  let positionRequestData: GetPositionRequestData = { values: [] };
  Object.entries(currentInitializedViews).forEach(([key, value]) => {
    const view = key as EventStateViewKey;
    positionRequestData.values.push({
      id: eventId,
      type: 'events',
      filters: value.filters,
      sort: value.sort,
      metadata: { 
        view: typeof view === 'number' ? `${view}` : view
      }
    });
  });

  return getPositionRequest(positionRequestData, apiConfig);
}

const createUpdateEventWithPositionsAndViews = async (
  eventId: string,
  store: Store<RootState, AnyAction>,
  dispatch: AppDispatch,
  apiConfig: AxiosRequestConfig,
  initialEvent: Event | null = null
) => {
  const currentInitializedViews = getCurrentInitializedViews(store);

  // Get event & position data from the API
  const positionResponse = await handlePositionRequestFromInitializedViews(eventId, currentInitializedViews, apiConfig);

  // Push the event into the shared event state if any of the positions is not null
  const anyPositionNotNull = !!positionResponse.find(position => position.position !== null);
  if (anyPositionNotNull) {
    const event = initialEvent ? initialEvent : await getEventRequest(eventId, apiConfig);
    dispatch(createOrUpdateEvent(event));
  }

  // Loop through all the position response values and update state accordingly
  positionResponse.forEach((positionValue) => {
    const positionView = positionValue.metadata?.view;
    if (!positionView)
      return;

    Object.entries(currentInitializedViews).forEach(async ([viewKey, viewValue]) => {
      const view = viewKey as EventStateViewKey;
      if (view !== positionView)
        return;

      const newIds = viewValue.data.filter(id => id !== eventId);
      if (positionValue.position !== null) {
        newIds.splice(positionValue.position, 0, eventId);
      }

      dispatch(setEventView({
        view,
        data: {
          ...viewValue,
          data: newIds
        }
      }));

    });
  });

}

const refetchEvents = (
  store: Store<RootState, AnyAction>,
  dispatch: AppDispatch,
  apiConfig: AxiosRequestConfig
) => {
  console.log('refetch events called...');

  const currentInitializedViews = getCurrentInitializedViews(store);
  Object.entries(currentInitializedViews).forEach(async ([viewKey, viewValue]) => {
    const view = viewKey as EventStateViewKey;
    const urlParams = createSortFilterUrlParams(
      viewValue.sort, 
      viewValue.filters, 
      viewValue.search
    );

    const start = 0; // TODO: data length (but with calendar always 0 ??) ??? !!!
    const response = await getEventsRequest(start, apiConfig, urlParams);

    // SET THE EVENTS DATA

    dispatch(setEvents(response.data));
    dispatch(setEventView({
      view,
      data: {
        ...viewValue,
        data: response.data.map(event => event.id)
      }
    }));
  });
}

const deleteEventAndFromViews = (
  eventId: string, 
  store: Store<RootState, AnyAction>,
  dispatch: AppDispatch  
) => {
  dispatch(deleteEventById(eventId));

  const currentEventsState = store.getState().lists.events.events;
  Object.entries(currentEventsState.views).forEach(async ([key, value]) => {
    const view = key as EventStateViewKey;
    dispatch(setEventView({
      view,
      data: {
        ...value,
        data: value.data.filter(id => id !== eventId)
      }
    }));
  });
}

export { createUpdateEventWithPositionsAndViews, deleteEventAndFromViews, refetchEvents };