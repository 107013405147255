import { InputBase, InputBaseProps, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import isValidHex from 'src/utils/general/isValidHex';
import ColorPicker from '../ColorPicker';

interface Props {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  inputBaseProps?: Partial<InputBaseProps>;
}

const ColorPickerWithHex = ({
  value,
  onChange,
  label,
  inputBaseProps
}: Props) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {!!label && (
          <Typography variant='caption'>{label}</Typography>
        )}
        <div style={{ display: 'inline-flex' }}>
          <div
            onClick={() => setOpen(true)}
            style={{
              minWidth: 39,
              minHeight: 39,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              cursor: 'pointer',
              border: `1px solid ${theme.palette.divider}`,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div 
              style={{
                background: isValidHex(value) ? value : 'none',
                height: `calc(100% - 12px)`,
                width: `calc(100% - 12px)`,
                borderRadius: 2
              }}
            />
          </div>
          <InputBase
            value={`${value}`.replace('#', '')}
            onChange={(e) => onChange(`#${e.target.value}`)}
            style={{
              border: `1px solid ${theme.palette.divider}`,
              borderLeft: 0,
              height: 39,
              paddingLeft: theme.spacing(1.5),
              width: 100,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4
            }}
            startAdornment={'#'}
            error={true}
            {...inputBaseProps}
          />
        </div>
      </div>
      <ColorPicker 
        onClick={(value) => {
          onChange(value);
          setOpen(false);
        }}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}
export default ColorPickerWithHex;