import dayjs from 'dayjs';


// export const hhmmss = (seconds: number) => {
//   console.log('ERROR: THIS DOES NOT WORK');

//   if(!seconds){
//     return '00:00:00';
//   }

//   return dayjs().startOf('day')
//     .set('seconds', seconds)
//     .format('HH:mm:ss');
// }

// export const hhmm = (seconds: number) => {
//   console.log('ERROR: THIS DOES NOT WORK');
  
//   if(!seconds){
//     return '00:00';
//   }
  
//   return dayjs().startOf('day')
//   .set('seconds', seconds)
//   .format('HH:mm');
// }

// export const singleToDoubleDigits = (time: number): string => {
//   return String(time).padStart(2, '0');
// }

export const getHmsObj = (seconds: number) => {
  let totalSeconds = seconds;
  let l_hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let l_minutes = Math.floor(totalSeconds / 60);
  let l_seconds = totalSeconds % 60;

  return {
    h: l_hours,
    m: l_minutes,
    s: l_seconds
  }
}

export const hhmmss = (seconds: number) => {
  let totalSeconds = seconds;

  const { h, m, s } = getHmsObj(totalSeconds);

  const minutes_str = String(m).padStart(2, '0');
  const hours_str = String(h).padStart(2, '0');
  const seconds_str = String(s).padStart(2, '0');

  return `${hours_str}:${minutes_str}:${seconds_str}`;
}

export const hhmm = (seconds: number) => {
  let totalSeconds = seconds;
  let l_hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let l_minutes = Math.floor(totalSeconds / 60);

  const minutes_str = String(l_minutes).padStart(2, '0');
  const hours_str = String(l_hours).padStart(2, '0');

  return `${hours_str}:${minutes_str}`;
}