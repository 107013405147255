import { Close } from "@mui/icons-material";
import { Avatar, AvatarGroup, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'src/i18n';
import { contactToSmall } from "src/custom/resourceConverter";
import useForm from "src/hooks/general/useForm";
import { useSelector } from "src/store";
import { makeStyles } from "src/theme/makeStyles";
import { Contact, ContactAddress, ContactSmall } from "src/types/api/contact";
import getInitials from "src/utils/general/getInitials";
// import AddSimpleContactDialog from "./AddSimpleContactDialog";
import ContactRow from './ContactRow';
import SelectedContactCard from './SelectedContactCard';
import useArrowSelect from "src/hooks/general/useArrowSelect";
import useIsMobile from "src/hooks/general/useIsMobile";
import clsx from 'clsx';
import AddressPickerContent from "./AddressPickerContent";
import { getContactRequest } from "src/requests/contacts";
import { useAxiosOptions } from "src/hooks/general/useAxios";

const useStyles = makeStyles()(theme => ({
  dialogPaperRoot: {
    minHeight: 467
  },
  dialogPaperRootMobile: {
    width: 'calc(100% - 24px)', // - theme.spacing(3) ?
    margin: 12 // theme.spacing (1.5) ??
  },
  avatar: {
    height: 42,
    width: 42,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
}))

interface Props {
  open: boolean;
  onSelect: (contact: ContactSmall, address: ContactAddress | null) => void;
  onClose: () => void;
  selectedContactIds: string[];
  disableNewContact?: boolean;
  title?: string;
  newContactButtonText?: string;
  multiple?: boolean;
  address?: {
    withAddress: boolean;
    autopick?: boolean;
    initialContactId?: string | null;
    onAddressLoadingChange?: (loading: boolean) => void;
  }
}

const ContactPickerDialog = ({
  open,
  onSelect,
  onClose,
  selectedContactIds,
  disableNewContact = false,
  title,
  newContactButtonText,
  multiple = false,
  address = {
    withAddress: false,
    autopick: false,
    initialContactId: null,
    onAddressLoadingChange: () => {}
  }
}: Props) => {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();
  const avatars = useSelector(state => state.misc.avatars);
  const isMobile = useIsMobile();
  const axiosOptions = useAxiosOptions();

  if (multiple && address.withAddress)
    throw new Error('With address is not allowed with multiple contacts!');

  const {
    open: openContactSimpleForm,
    close: closeContactSimpleForm
  } = useForm('contactSimple', disableNewContact);

  const handleOpenSimpleContactDialog = () => {
    openContactSimpleForm(null, {}, (values) => {
      const contacts = values as Contact[];
      contacts.forEach(contact => {
        const smallContact = contactToSmall(contact);
        onSelect(smallContact, null);
      })
      closeContactSimpleForm();
      if (!multiple) {
        onClose();
      }
    });
  }

  const [searchQuery, setSearchQuery] = useState('');

  const contacts = useSelector(state => state.lists.contacts);

  // const selectedContacts = useMemo(() => {
  //   if(selectedContactIds){
  //     return contacts.filter(contact => selectedContactIds.includes(contact.id));
  //   }
  //   return [];
  // }, [contacts, selectedContactIds]);

  // filters for search query and if already selected
  const filteredContacts = useMemo(() => {
    const filtered = contacts.filter(contact =>
      contact.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !selectedContactIds.includes(contact.id)
    );

    const sorted = [...filtered].sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      return 0;
    });

    return sorted;
  }, [contacts, searchQuery, selectedContactIds]);

  const {
    containerRef,
    onKeyDown,
    selectedIndex,
    reset: resetArrowSelectState
  } = useArrowSelect({
    rows: filteredContacts,
    onSelect: (index) => {
      const contact = filteredContacts.find((x, i) => i === index);
      contact && handleClick(contact);
    }
  });

  const [selectedContactForAddress, setSelectedContactForAddress] = useState<ContactSmall | null>(null);
  const handleClick = (contact: ContactSmall) => {
    if (address.withAddress) {
      setSelectedContactForAddress(contact);
      return;
    }

    onSelect(contact, null);
    if (!multiple) {
      onClose();
    }
  }

  const handleAddressClick = (address: ContactAddress | null) => {
    if(selectedContactForAddress){
      onSelect(selectedContactForAddress, address);
      onClose();
    }
  }

  useEffect(() => {
    setSearchQuery('');
    resetArrowSelectState();
    setSelectedContactForAddress(null);
    setContactAddresses([]);
    setAddressesLoading(false);

    if(open && address.initialContactId){
      const initialContact = contacts.find(contact => contact.id === address.initialContactId);
      if(initialContact){
        setSelectedContactForAddress(initialContact);
      }
    }
  }, [open]);


  // ====
  const [contactAddresses, setContactAddresses] = useState<ContactAddress[]>([]);
  const [addressesLoading, setAddressesLoading] = useState(false);
  useEffect(() => {
    if(selectedContactForAddress){
      setAddressesLoading(true);
      address.onAddressLoadingChange && address.onAddressLoadingChange(true);

      setTimeout(() => {
        getContactRequest(selectedContactForAddress.id, axiosOptions.apiConfig)
          .then((contact) => {
              if(address.autopick){
                if(contact.address.length === 0){
                  handleAddressClick(null);
                  return;
                }
                if(contact.address.length === 1){
                  handleAddressClick(contact.address[0]);
                  return;
                }
              }
              setContactAddresses(contact.address);
          }).catch(err => console.error(err))
          .finally(() => {
            address.onAddressLoadingChange && address.onAddressLoadingChange(false);
            setAddressesLoading(false)
          });
      }, 500);
    }
  }, [selectedContactForAddress]);
  // ====

  return (
    <Dialog
      open={open && ((address.withAddress && address.autopick && addressesLoading) ? !!contactAddresses.length : true)} // ! GLITCHY !
      // open={open && ((address.withAddress && address.autopick) ? !!contactAddresses.length : true)} // ! This straight up bugs!
      maxWidth={'xs'}
      fullWidth
      onClose={onClose}
      classes={{
        paper: clsx(
          classes.dialogPaperRoot,
          isMobile && classes.dialogPaperRootMobile
        )
      }}
      onKeyDown={onKeyDown}
    >
      <div style={{ padding: '12px 16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h4'>
            {title ? title : `${t('lang.add_contact')}`}
          </Typography>
          <IconButton
            onClick={onClose}
            size='small'
            style={{ marginLeft: 'auto' }}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider />
      {selectedContactForAddress ? (
        <DialogContent>
          <AddressPickerContent 
            contact={selectedContactForAddress} 
            onAddressClick={handleAddressClick}
            loading={addressesLoading}
            addresses={contactAddresses}
            allowNoAddress={false}
          />
        </DialogContent>
      ) : (
        <DialogContent>
          {multiple && selectedContactIds.length && (
            <AvatarGroup
              max={10}
              style={{ float: 'left' }}
            >
              {selectedContactIds.map((contactId, index) => {
                const contact = contacts.find(contact => contact.id === contactId);
                if (!contact) {
                  return null;
                }

                return (
                  <Tooltip
                    key={index}
                    title={contact.name}
                  >
                    <Avatar
                      className={classes.avatar}
                      src={`data:image/jpeg;base64,${avatars[contact.id] && avatars[contact.id]}`}
                    >
                      {getInitials(contact.name)}
                    </Avatar>
                  </Tooltip>
                )
              })}
            </AvatarGroup>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label={`${t('lang.search')}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                variant='standard'
                fullWidth
                autoFocus
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              {filteredContacts.length ? (
                <ClickAwayListener
                  onClickAway={() => resetArrowSelectState()}
                >
                  <div
                    ref={containerRef}
                    style={{
                      overflow: 'auto',
                      maxHeight: 250,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 4
                    }}
                  >
                    {filteredContacts.map((contact, index) => (
                      <ContactRow
                        key={index}
                        contact={contact}
                        onClick={() => handleClick(contact)}
                        disableBorderTop={index === 0}
                        selected={selectedIndex === index}
                      />
                    ))}
                  </div>
                </ClickAwayListener>
              ) : (
                <Typography variant='h4'>
                  {t('lang.no_results')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <div style={{ display: 'flex', width: '100%' }}>
          {!disableNewContact && (
            <Button
              onClick={handleOpenSimpleContactDialog}
              size='small'
              color='primary'
              disabled={!!selectedContactForAddress}
            >
              {newContactButtonText ? `+ ${newContactButtonText}` : `+ ${t('lang.new_contact')}`}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default ContactPickerDialog;

export { SelectedContactCard };