import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Device as TDevice } from 'src/types/api/device';

const initialState: TDevice[] = [];

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices(state: TDevice[], action: PayloadAction<TDevice[]>){
      return action.payload;
    },
    createDevice(state: TDevice[], action: PayloadAction<TDevice>){
      state.push(action.payload);
    },
    updateDevice(state: TDevice[], action: PayloadAction<TDevice>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteDevice(state: TDevice[], action: PayloadAction<TDevice>){
      return state.filter((val) => val.id !== action.payload.id);
    }
  }
})

export const { reducer } = slice;

export const setDevices = (data: TDevice[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDevices(data));
}

export const createDevice = (data: TDevice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createDevice(data));
}

export const updateDevice = (data: TDevice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateDevice(data));
}

export const deleteDevice = (data: TDevice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteDevice(data));
}

export default slice;