import { createPresentation, deletePresentation, updatePresentation } from 'src/slices/lists/presentations';
import type { AppDispatch } from 'src/store/index';
import type { Presentation } from 'src/types/api/presentation';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch,
) => {
  channel.listen('.create_presentation', (data: any) => {
    const presentation = data.data.response as Presentation;
    dispatch(createPresentation(presentation));
  });
  channel.listen('.update_presentation', (data: any) => {
    const presentation = data.data.response as Presentation;
    dispatch(updatePresentation(presentation));
  });
  channel.listen('.delete_presentation', (data: any) => {
    const presentation = data.data.response as Presentation;
    dispatch(deletePresentation(presentation));
  });
}