import { AxiosOptions } from 'src/hooks/general/useAxios';
import { setContacts } from 'src/slices/lists/contacts';
import { setDevices } from 'src/slices/lists/devices';
import { setFiles } from 'src/slices/lists/files';
import { setGroups } from 'src/slices/lists/groups';
import { setLanguages } from 'src/slices/lists/languages';
import { setNotes } from 'src/slices/lists/notes';
import { setPermissionCategories, setPermissions } from 'src/slices/lists/permissions';
import { setPresentations } from 'src/slices/lists/presentations';
import { setRoles } from 'src/slices/lists/roles';
import { setTemplates } from 'src/slices/lists/templates';
import { setTimeTrackers } from 'src/slices/lists/timetrackers';
import { setUsers } from 'src/slices/lists/users';
import { setFullMenu } from 'src/slices/misc';
import type { AppDispatch } from 'src/store/index';
import { ContactSmall } from 'src/types/api/contact';
import type { File as TFile } from 'src/types/api/file';
import type { GroupSmall } from 'src/types/api/group';
import type { Language } from 'src/types/api/language';
import { Menu } from 'src/types/api/menu';
import type { Note } from 'src/types/api/note';
import type { Permission, PermissionCategory } from 'src/types/api/permission';
import type { Presentation } from 'src/types/api/presentation';
import type { RoleSmall } from 'src/types/api/role';
import type { TemplateSmall } from 'src/types/api/template';
import type { UserSmall } from 'src/types/api/user';
import type { TimeTracker } from 'src/types/api/timetracker';
import { AxiosInstance } from 'axios';
import { AnyAction, Store } from 'redux';
import type { RootState } from 'src/store';

const handleCatch = (error: any) => {
  console.log(error);
}

export const fetchData = (
  permission_tag: string, 
  dispatch: AppDispatch, 
  axiosOptions: AxiosOptions, 
  axios: AxiosInstance,
  store: Store<RootState, AnyAction>
) => {
  if(!permission_tag.startsWith('read')){ return; }
  switch(permission_tag){
    case 'read_contact': {
      axios.get<ContactSmall[]>('contacts', axiosOptions.apiConfig)
      .then((response) => { dispatch(setContacts(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_file': {
      axios.get<TFile[]>('files', axiosOptions.apiConfig)
      .then((response) => { dispatch(setFiles(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_time_registration': {
      axios.get<TimeTracker[]>('timetracker', axiosOptions.apiConfig)
      .then((response) => { dispatch(setTimeTrackers(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_general_note': {
      axios.get<Note[]>('notes', axiosOptions.apiConfig)
      .then((response) => { dispatch(setNotes(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_presentation': {
      axios.get<Presentation[]>('presentations', axiosOptions.apiConfig)
      .then((response) => { dispatch(setPresentations(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_device': {
      console.log('todo');
      break;
    }
    // case 'read_user': {
    //   axios.get<UserSmall[]>('users', axiosOptions.apiConfig)
    //   .then((response) => { dispatch(setUsers(response.data)); })
    //   .catch(handleCatch);
    //   break;
    // }
    case 'read_customer_user': {
      const stateUsers = store.getState().lists.users;
      const stateUserIds = stateUsers.map((user) => user.id);

      axios.get<UserSmall[]>('users', axiosOptions.apiConfig)
      .then((response) => { 
        // merge response.data with stateUsers
        const reducedResponse = response.data.filter((user) => !stateUserIds.includes(user.id));
        dispatch(setUsers([
          ...stateUsers,
          ...reducedResponse
        ]));
      })
      .catch(handleCatch);
      break;
    }
    case 'read_role': {
      // axios.get<RoleSmall[]>('roles', axiosOptions.apiConfig)
      // .then((response) => { dispatch(setRoles(response.data)); })
      // .catch(handleCatch);

      const stateRoles = store.getState().lists.roles;
      const stateRoleIds = stateRoles.map((role) => role.id);

      axios.get<RoleSmall[]>('roles', axiosOptions.apiConfig)
      .then((response) => { 
        // merge response.data with stateUsers
        const reducedResponse = response.data.filter((role) => !stateRoleIds.includes(role.id));
        dispatch(setRoles([
          ...stateRoles,
          ...reducedResponse
        ]));
      }).catch(handleCatch);
      break;
    }
    case 'read_customer_role': {
      axios.get<RoleSmall[]>('roles', axiosOptions.apiConfig)
      .then((response) => { dispatch(setRoles(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_template': {
      axios.get<TemplateSmall[]>('system/templates', axiosOptions.apiConfig)
      .then((response) => { dispatch(setTemplates(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_group': {
      axios.get<GroupSmall[]>('system/groups', axiosOptions.apiConfig)
      .then((response) => { dispatch(setGroups(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_permission': {
      axios.get<Permission[]>('system/permissions', axiosOptions.apiConfig)
      .then((response) => { dispatch(setPermissions(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_permission_category': {
      axios.get<PermissionCategory[]>('system/permissions/categories', axiosOptions.apiConfig)
      .then((response) => { dispatch(setPermissionCategories(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_language': {
      axios.get<Language[]>('system/languages', axiosOptions.apiConfig)
      .then((response) => { dispatch(setLanguages(response.data)); })
      .catch(handleCatch);
      break;
    }
    case 'read_menu_item': {
      axios.get<Menu>('menu', axiosOptions.apiConfig)
      .then((response) => { dispatch(setFullMenu(response.data)); })
      .catch(handleCatch);
      break;
    }
  }
}

export const removeData = (permission_tag: string, dispatch: AppDispatch, store: Store<RootState, AnyAction>) => {
  if(!permission_tag.startsWith('read')){ return; }

  switch(permission_tag){
    case 'read_contact': {
      dispatch(setContacts([]));
      break;
    }
    case 'read_file': {
      dispatch(setFiles([]));
      break;
    }
    case 'read_time_registration': {
      dispatch(setTimeTrackers([]));
      break;
    }
    case 'read_general_note': {
      dispatch(setNotes([]));
      break;
    }
    case 'read_presentation': {
      dispatch(setPresentations([]));
      break;
    }
    case 'read_device': {
      dispatch(setDevices([]));
      break;
    }
    // case 'read_user': {
    //   dispatch(setUsers([]));
    //   break;
    // }

    case 'read_customer_user': {
      const users = store.getState().lists.users;
      dispatch(setUsers(users.filter((x) => x.type !== 'customer')))
      break;
    }

    // case 'read_role': {
    //   dispatch(setRoles([]));
    //   break;
    // }

    case 'read_customer_role': {
      const roles = store.getState().lists.roles;
      dispatch(setRoles(roles.filter((role) => role.type !== 'customer')))
      break;
    }
    
    case 'read_template': {
      dispatch(setTemplates([]));
      break;
    }
    case 'read_group': {
      dispatch(setGroups([]));
      break;
    }
    case 'read_permission': {
      dispatch(setPermissions([]));
      break;
    }
    // case 'read_permission_category': {
    //   dispatch(setPermissionCategories([]));
    //   break;
    // }
    case 'read_language': {
      dispatch(setLanguages([]));
      break;
    }
    case 'read_menu_item': {
      dispatch(setFullMenu([]));
      break;
    }
  }

}