import { Avatar, Link, Typography } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { useTranslation } from 'src/i18n';
import { useStore } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  avatarIncomingCall: {
    width: 40,
    height: 40,
    background: theme.palette.primary.main
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default'
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  linkBusinessWhite: {
    color: '#fff', 
    '&:hover': {
      color: '#ececec'
    }
  },
}));

interface Props {
  callData: any;
  getCompany: any;
}

const InProgress = ({
  callData,
  getCompany
}: Props) => {
  const relation = getCompany(callData);
  const { classes } = useStyles();
  const store = useStore();
  const {t} = useTranslation();
  
  return (
    <>
    <div>
      <Typography variant="h5" style={{marginBottom: '5px'}}>
        {t("lang.active_call")}
      </Typography>
    </div>
    <div className={classes.profile}>
      <Avatar
        className={classes.avatarIncomingCall}
        src={callData.caller.id && store.getState().misc.avatars[callData.caller.id] && `data:image/jpeg;base64,${store.getState().misc.avatars[callData.caller.id]}`}
      >
        <PhoneInTalkIcon/>
      </Avatar>
      <div className={classes.details}>
        <Link
          component={RouterLink}
          to={"/contacts/"+callData.caller.id}
          variant="h5"
          underline="none"
          style={{color: '#ffffff'}}
        >
          {callData.caller.name ? callData.caller.name : (callData.caller.number ? callData.caller.number : t("lang.unknown"))}
        </Link>
        {relation &&
          <Typography variant="body2">
            <Link
              component={RouterLink}
              to={"/contacts/"+callData.caller.id}
              variant="h5"
              underline="none"
              className={classes.linkBusinessWhite}
            >
              <HomeWorkOutlinedIcon fontSize="small" style={{verticalAlign: 'sub'}}/>
              &nbsp;{relation.name}
            </Link>
          </Typography>
        }

        <div style={{
          position: 'absolute',
          right: '12px',
          bottom: '5px',
        }}>
          <PhoneInTalkIcon fontSize="small"/> 
        </div>
      </div>
    </div>
    </>
  )
}

export default InProgress;