import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { User } from 'src/types/api/user';
import type { Role } from 'src/types/api/role';

const initialState: User | null = null;

const slice = createSlice({
  name: 'contact',
  initialState: initialState as (User | null),
  reducers: {
    setGeneralUser(state: User, action: PayloadAction<User>){
      return action.payload;
    },
    updateUserRole(state: User, action: PayloadAction<Role>){
      state.role = action.payload;
      return state;
    }
  }
})

export const { reducer } = slice;

export const setGeneralUser = (payload: User): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGeneralUser(payload));
}

export const updateUserRole = (payload: Role): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateUserRole(payload));
}

export default slice;