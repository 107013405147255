import { Controller, useForm } from "react-hook-form";
import { FormValues } from "./types";
import { DEFAULT_FORM_VALUES } from './constants';
import { Grid, TextField, useTheme } from "@mui/material";
import FirstUpperField from "src/components/forms/fields/FirstUpper";
import EmailField from "src/components/forms/fields/Email";
import { useTranslation } from 'src/i18n';
import LanguageSelect from "src/components/forms/fields/LanguageSelect";
import DuotoneDialog from 'src/components/general/DuotoneDialog';
import { useEffect, useMemo, useState } from "react";
import validations from "src/components/forms/validations";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import LastName from "src/components/forms/fields/LastName";
import useEmailAvailable from "src/components/forms/fields/Email/useEmailAvailable";
import DialogTitleAvatar from "src/components/general/DialogTitleAvatar";
import { useSelector } from "src/store";
import RoleSelectDialog from "src/features/role/RoleSelectDialog";

const yup_schema = yup.object({
  first_name: validations.default_string,
  last_name: validations.default_string,
  email: validations.email,
  role_id: validations.default_string,
  language: validations.default_string
});

interface Props {
  open: boolean;
  initialValues?: FormValues;
  onClose: () => void;
  addValue: (value: FormValues) => void;
  alreadySelectedEmails: string[];
}

const UserForm = ({
  open,
  initialValues,
  onClose,
  addValue,
  alreadySelectedEmails
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [fileToUpload, setFileToUpload] = useState<{ url: string; base64: string; } | null>(null);

  const {
    setValue,
    watch,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<FormValues>({
    defaultValues: initialValues ? initialValues : DEFAULT_FORM_VALUES,
    resolver: yupResolver(yup_schema)
  });
  const watchValues = watch();

  const emailAvailableInApi = useEmailAvailable(watchValues.email);
  const emailAvailableLocally = !alreadySelectedEmails.find(email => email.toLowerCase() === watchValues.email);

  const emailAvailable = emailAvailableInApi && emailAvailableLocally;

  useEffect(() => {
    if (open) {
      reset(initialValues ? initialValues : DEFAULT_FORM_VALUES);
      setFileToUpload(null);
    }
  }, [open]);

  const onSubmit = (values: FormValues) => {
    if (!emailAvailable) {
      return;
    }

    addValue({
      ...values,
      avatar: fileToUpload ? fileToUpload.url : null
    });
    onClose();
  }

  const roles = useSelector(state => state.lists.roles);
  const filteredRoles = useMemo(() => {
    const isCustomer = false;
    return roles.filter((role) => role.type === (isCustomer ? 'customer' : 'user'))
  }, [roles]);
  const [roleSelectOpen, setRoleSelectOpen] = useState(false);
  const selectedRole = useMemo(() => {
    const role = filteredRoles.find(role => role.id === watchValues.role_id);
    return role ? role : null;
  }, [watchValues, filteredRoles]);

  return (
    <DuotoneDialog
      open={open}
      title={`${t('lang.add_user')}`}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      disableOutsideClick

      titleLeftComponent={(
        <div style={{ marginRight: theme.spacing(3) }}>
          <DialogTitleAvatar
            name={watchValues.first_name || watchValues.last_name ?
              `${watchValues.first_name} ${watchValues.last_name}` :
              ''
            }
            onAvatarChange={(dataUrl) => {
              if (!dataUrl) {
                setFileToUpload(null);
                // setClearAvatarOnPost(true);
                return;
              }
              // setClearAvatarOnPost(false);
              setFileToUpload({
                url: dataUrl,
                base64: dataUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
              })
            }}
            avatarDataUrl={fileToUpload?.url}
          />
        </div>
      )}

    >
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              name='first_name'
              control={control}
              render={({ field }) => (
                <FirstUpperField
                  label={`${t("lang.first_name")}*`}
                  fullWidth
                  error={!!errors.first_name}
                  helperText={errors.first_name?.message ? t(errors.first_name.message) : ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='last_name'
              control={control}
              render={({ field }) => (
                <LastName
                  label={`${t("lang.last_name")}*`}
                  fullWidth
                  error={!!errors.last_name}
                  helperText={errors.last_name?.message ? t(errors.last_name.message) : ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <EmailField
                  fullWidth
                  error={!!errors.email || !emailAvailable}
                  helperText={!emailAvailable ? `${t('lang.email_already_in_use')}` : errors.email?.message ? t(errors.email?.message) : ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <RoleSelect 
            value={watchValues.role_id}
            onChange={(role_id) => setValue('role_id', role_id)}
            textFieldProps={{
              error: !!errors.role_id,
              helperText: errors.role_id?.message
            }}
          /> */}
            <TextField
              label={`${t('lang.role')}`}
              value={selectedRole ? selectedRole.name : ''}
              variant='standard'
              // select
              fullWidth
              // onChange={(e) => handleServiceChange(e.target.value)}
              onChange={() => { }}
              error={!!errors.role_id}
              helperText={errors.role_id?.message ? t(errors.role_id.message) : ''}
              // disabled={disabled}
              // onClick={() => setSelectServiceDialogOpen(true)}
              onClick={() => setRoleSelectOpen(true)}
              focused={false}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LanguageSelect
              value={watchValues.language}
              onChange={(language_locale) => setValue('language', language_locale)}
              textFieldProps={{
                error: !!errors.language,
                helperText: errors.language?.message
              }}
            />
          </Grid>
        </Grid>
        <RoleSelectDialog
          rolesToUse={filteredRoles}
          open={roleSelectOpen}
          onChange={(role) => {
            setValue('role_id', role.id);
            setRoleSelectOpen(false);
          }}
          onClose={() => setRoleSelectOpen(false)}
          initialRoleId={watchValues.role_id ? watchValues.role_id : null}
        />
      </>
    </DuotoneDialog>
  )
}
export default UserForm;