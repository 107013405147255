import axios, { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { 
  setAppointments, 
  updateAppointment as updateAppointmentState,
  createAppointment as createAppointmentState,
  deleteAppointment as deleteAppointmentState
} from 'src/slices/lists/appointments';
import { AppDispatch } from 'src/store';
import { Appointment } from 'src/types/api/appointment';

export const getAppointments = (
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Appointment[]> => {
  return new Promise<Appointment[]>((resolve, reject) => {
    axios.get<Appointment[]>('appointments', config)
    .then((response) => {
      dispatch(setAppointments(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export interface CreateAppointmentData {
  services: { 
    id: string; 
    duration: number; 
    member: string;
    price: number;
    sub_services?: { 
      id: string; 
      duration: number; 
      after_work_duration: number 
    }[];
  }[]; // member is user.contact_id
  start: dayjs.Dayjs;
  contact_id: string | null; // string | undefined OR string | null ??
  description: string;
}

export interface UpdateAppointmentData extends CreateAppointmentData {
  no_show: boolean;
}

export const createAppointment = (
  data: CreateAppointmentData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Appointment> => {
  return new Promise<Appointment>((resolve, reject) => {
    axios.post<Appointment>('appointments', {
      ...data, 
      start: data.start.unix()
    }, config)
    .then((response) => {
      dispatch(createAppointmentState(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const updateAppointment = (
  appointment_id: string,
  data: UpdateAppointmentData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Appointment> => {
  return new Promise<Appointment>((resolve, reject) => {
    axios.put<Appointment>(`appointments/${appointment_id}`, {
      ...data, 
      start: data.start.unix() 
    }, config)
    .then((response) => {
      dispatch(updateAppointmentState(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const deleteAppointment = (
  appointment_id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Appointment> => {
  return new Promise<Appointment>((resolve, reject) => {
    axios.delete<Appointment>(`appointments/${appointment_id}`, config)
    .then((response) => {
      dispatch(deleteAppointmentState(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const sendAppointmentConfirmationEmailRequest = (
  appointment_id: string,
  email: string,
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.post(`appointments/${appointment_id}/confirmationEmail`, { email }, config)
    .then((response) => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const sendAppointmentMovedEmailRequest = (
  appointment_id: string,
  email: string,
  config: AxiosRequestConfig
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axios.post(`appointments/${appointment_id}/movedEmail`, { email }, config)
    .then((response) => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
  });
}