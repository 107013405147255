import { StandardTextFieldProps, TextField } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'src/i18n';
import mergeRefs from "react-merge-refs";
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';
import useAutofocus from './logic/useAutofocus';
import lastNamePrefixes from 'src/assets/misc/lastNamePrefixes.json';

const getLastNamePrefixes = (locale: keyof typeof lastNamePrefixes = 'nl') => {
  return lastNamePrefixes[locale];
}

const normalize = (value: string) => {
  let str = value.split(' ').map(item => item.replace(item.charAt(0), item.charAt(0).toUpperCase())).join(' ');
  getLastNamePrefixes().forEach((lid) => {
    if(str.toLowerCase().startsWith(lid + ' ')){
      str = lid.toLowerCase() + ' ' + str.substr(lid.length + 1); 
    }
  });
  return str;
}

const LastName = forwardRef<HTMLDivElement, StandardTextFieldProps>((props, forwardedRef) => {
  const { t } = useTranslation();

  const { ref: caretRef, updateCaret} = useCaretPosition();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    updateCaret();
    e.target.value = normalize(e.target.value);
    props.onChange && props.onChange(e);
  }

  const focusRef = useAutofocus(!!props.autoFocus, props.value);

  const combinedRefs = mergeRefs(props.inputRef ? [props.inputRef, caretRef, focusRef] : [caretRef, focusRef]);
  return (
    <TextField
      ref={forwardedRef}
      variant='standard'
      label={`${t('lang.last_name')}`}
      {...props}
      helperText={props.helperText}
      inputRef={combinedRefs}
      onChange={handleChange}
    />
  )
});

export default LastName;