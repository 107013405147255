import { TextField as MuiTextField } from '@mui/material';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import mergeRefs from "react-merge-refs";
import { forwardRef, useEffect, useRef } from 'react';
import useCaretPosition from 'src/hooks/general/useNewCaretPosition';

type Normalization = 'first_to_upper' | 'firstName' | 'lastName' | 'email' | 'none';

const norm_firstToUpper = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);
      
const norm = {
  firstName: (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
  lastName: (value: string) =>{
    let startValues = ['de','het','van','van de','van het','van den', 'van der'];
    let str = value.split(' ').map(item => item.replace(item.charAt(0), item.charAt(0).toUpperCase())).join(' ');
    startValues.map((lid) => {
      if(str.toLowerCase().startsWith(lid + ' ')){
        str = lid.toLowerCase() + ' ' + str.substr(lid.length + 1); 
      }
    });
    return str;
  },
  email : (value: string) => value.split(' ').join('').toLowerCase()

}



interface Props {
  normalization: Normalization;
}

type CombinedProps = MuiTextFieldProps & Props;

const NormalizationTextField = forwardRef<HTMLDivElement, CombinedProps>(({
  normalization,
  ...props
}, forwardedRef) => {
  const {ref: caretRef, updateCaret } = useCaretPosition();

  const focusRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if(props.autoFocus && focusRef.current){
      focusRef.current.focus();
      if(props.value && typeof(props.value) === 'string'){
        focusRef.current.setSelectionRange(props.value.length, props.value.length);
      }
    }
  }, [focusRef.current]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateCaret();
    if(normalization){
      switch(normalization){
        case 'first_to_upper':
        case 'firstName': {
          e.target.value = norm.firstName(e.target.value);
          break;
        }
        case 'lastName': {
          e.target.value = norm.lastName(e.target.value);
          break;
        }
        case 'email': {
          e.target.value = norm.email(e.target.value);
          break;
        }
        

      }
    }
    props.onChange && props.onChange(e);
  }

  const combinedRefs = props.inputRef ? mergeRefs([props.inputRef, caretRef, focusRef]) : mergeRefs([caretRef, focusRef]);
  return (
    <MuiTextField
      ref={forwardedRef}
      {...props}
      inputRef={combinedRefs}
      onChange={handleChange}
    />
  )
});

export default NormalizationTextField;