import { FormValues } from "./types";

export const DEFAULT_FORM_VALUES: FormValues = {
  type: 'person',
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: '',
  avatar: null,
  clientNumber: '',
  tagIds: []
}