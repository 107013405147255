import { useEffect, useState } from "react";
import BackNextButtons from "../components/BackNextButtons";
import { FormValues } from './UserForm/types';
import UserForm from './UserForm';
import { Button, Grid, useTheme, Box, Typography, IconButton } from "@mui/material";
import { useTranslation } from 'src/i18n';
import { useSelector } from "src/store";
import AvatarEntityDetails from "src/components/general/AvatarEntityDetails";
import BaseAvatarEntityDetails from "src/components/general/AvatarEntityDetails/BaseAvatarEntityDetails";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface Props {
  stepIndex: number;
  onChange: (users: FormValues[]) => void;
  onBack: () => void;
  onNext: () => void;
}

const Users = ({
  stepIndex,
  onChange,
  onBack,
  onNext
}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const roles = useSelector(state => state.lists.roles);
  const ownUser = useSelector(state => state.general.user);

  const [formOpen, setFormOpen] = useState(false); 
  const [values, setValues] = useState<FormValues[]>([])

  useEffect(() => {
    console.log(values);
  }, [values]);


  const handleNext = () => {
    onChange(values);
    onNext();
  }

  const addValue = (value: FormValues) => {
    setValues(prev => [
      ...prev,
      value
    ]);
  }

  const removeValue = (index: number) => {
    setValues(values.filter((value, l_index) => l_index !== index));
  }

  const getRolenameById = (role_id: string) => {
    const foundRole = roles.find((role) => role.id === role_id);
    return foundRole ? foundRole.name : undefined;
  }

  const handleAvatarChange = (avatarDataUrl: string | null, index: number) => {
    setValues((prev) => (
      prev.map((value, l_index) => l_index !== index ? value : { 
        ...value, 
        avatar: avatarDataUrl
      })
    ));
  }

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">{t(`description.stepper_users`)}</Typography>
      </Grid>
      <Grid item xs={12}>
       {ownUser && (
        <Box py={theme.spacing(1)}>
          <div 
            style={{ 
              border: `1px solid ${theme.palette.divider}`, 
              borderRadius: 4,
              padding: theme.spacing(1)
            }}
          >
            <AvatarEntityDetails
              entity={{ user: ownUser }}
              secondaryProperties={[
                ownUser.email, 
                ownUser.role.name
              ]}
              enableAvatarUpdate
            />
          </div>
        </Box>
      )}

      {values.map((value, index) => (
        <Box
          key={index}
          py={theme.spacing(1)}
        >
          <div 
            style={{ 
              border: `1px solid ${theme.palette.divider}`, 
              borderRadius: 4,
              padding: theme.spacing(1)
            }}
          >
            <BaseAvatarEntityDetails 
              name={`${value.first_name} ${value.last_name}`}
              secondaryProperties={[
                value.email,
                getRolenameById(value.role_id)
              ]}
              enableAvatarUpdate
              onAvatarChange={(avatarDataUrl) => handleAvatarChange(avatarDataUrl, index)}
              avatarDataUrl={value.avatar ? value.avatar : undefined}
              rightComponent={(
                <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => removeValue(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              )}
            />
          </div>
        </Box>
      ))}
      {!!values.length && (
        <Box>
          <Typography variant='caption' style={{ float: 'right' }}>
            {t('description.users_will_receive_password_setup_email')}
          </Typography>
        </Box>
      )}

      <Box py={theme.spacing(1)}>
        <Button
          onClick={() => setFormOpen(true)}
          size='small'
          color='primary'
        >
          {`+ ${t('lang.add_new_user')}`}
        </Button>
      </Box>
      </Grid>
      <Grid item xs={12}>
      <BackNextButtons
        disableBack={stepIndex === 0}
        onBack={onBack}
        onNext={handleNext} // handle local submit ??
      />
      <UserForm 
        open={formOpen}
        onClose={() => setFormOpen(false)}
        addValue={addValue}
        alreadySelectedEmails={values.map(value => value.email)}
      />
      </Grid>
    </Grid>
  )
}
export default Users;