import {
  Avatar,
  Badge,
  colors,
  Divider,
  Drawer,
  Fade,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'src/i18n';
import { useSelector } from 'react-redux';
import getInitials from 'src/utils/general/getInitials';
import Chat from '../Chat';
import Searchbar from '../components/Searchbar';
import SuperScrollbar from 'src/components/general/SuperScrollbar';
import dayjs from 'dayjs';
import { useUsersNoCustomer } from 'src/store/customSelectors';

// const drawerWidthSmall = 380;
const drawerWidth = '100%';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },

  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  unreadMessagesBadgeLight: {
    backgroundColor: '#263238',
    color: '#e0e0e0'
  },
  unreadMessagesBadgeDark: {
    backgroundColor: '#e0e0e0',
    color: '#263238'
  },
  details: {
    marginLeft: '17px'
  },
  chatTransition: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const RenderRow = ({ user, selectedUser, handleSelectUser, isOnline, unreadMessages }) => {
  const { classes } = useStyles();
  const avatars = useSelector(state => state.misc.avatars);
  const { t } = useTranslation();
  const theme = useTheme();

  const typingUsers = useSelector(state => state.misc.typing);
  const activity = useMemo(() => {
    let activity = "lang.online";
    typingUsers.forEach((user_id) => {
      if (user_id === user.id) {
        activity = "lang.typing";
      }
    });
    return activity;
  }, [typingUsers]);

  const status = useMemo(() => {
    if (isOnline) { return 'online'; }
    return 'offline';
  }, []);

  return (
    <div style={{ padding: '5px 16px' }}>
      <div className={classes.profile}
        onClick={() => handleSelectUser(user)}
        style={{
          cursor: 'pointer',
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.onlineBadge]: status === 'online',
              [classes.awayBadge]: status === 'away',
              [classes.busyBadge]: status === 'busy',
              [classes.offlineBadge]: status === 'offline'
            })
          }}
          variant="dot"
        >
          <Badge
            overlap="circular"
            badgeContent={unreadMessages.length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              dot: classes.badgeDot,
              badge: clsx({
                [classes.badge]: true,
                [classes.unreadMessagesBadgeLight]: theme.name === "LIGHT" && true, //??
                [classes.unreadMessagesBadgeDark]: theme.name === "DARK" && true, //??
              })
            }}
          >
            <Avatar
              className={!selectedUser.id ? classes.avatar : null}
              src={avatars[user.id] && `data:image/jpeg;base64,${avatars[user.id]}`}
            >
              {getInitials(user.name)}
            </Avatar>
          </Badge>
        </Badge>
        <div className={classes.details}>
          <Typography
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
            variant="h5"
            color="textPrimary"
            underline="none">
            {user.name}
          </Typography>

          <Typography variant="body2" style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: '#b0b0b0'
          }}>
            {isOnline ? t(activity) : user.last_seen ? dayjs(user.last_seen).fromNow() : '-'}
          </Typography>
        </div>
      </div>
    </div>
  );
}

const RenderSelectedUser = ({selectedUser, setSelectedUser, setPresenceOpen}) => {
  const { classes } = useStyles();
  const avatars = useSelector(state => state.misc.avatars);
  const presence = useSelector(state => state.misc.presence);
  const {t} = useTranslation();
  const typingUsers = useSelector(state => state.misc.typing);

  const selectedUserOnline = useMemo(() => {
    if (selectedUser.id) {
      let finalUser = presence.find((user) => user.id === selectedUser.id);
      if (finalUser) {
        return true;
      }
    }
    return false;
  }, [selectedUser, presence]);

  const status = useMemo(() => {
    if(!selectedUserOnline){
      return 'lang.offline'
    }
    let activity = "lang.online";
    typingUsers.forEach((user_id) => {
      if (user_id === selectedUser.id) {
        activity = "lang.typing";
      }
    });
    return activity;
  }, [typingUsers, selectedUser, selectedUserOnline]);

  const handleClose = () => {
    setPresenceOpen(false);
    setTimeout(() => {
      setSelectedUser({});
    }, 500);
  }

  return (
    <div className={classes.profile} style={{
      padding: '8px 16px',
    }}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={selectedUserOnline ? {
          dot: classes.badgeDot,
          badge: clsx({
            [classes.badge]: true,
            [classes.onlineBadge]: true,
          })
        } : {
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.offlineBadge]: true,
            })
          }}
        variant="dot"
      >
        <Avatar
          className={classes.avatar}
          src={avatars[selectedUser.id] && `data:image/jpeg;base64,${avatars[selectedUser.id]}`}
          style={{
            margin: '4px 0px',
          }}
        >
          {getInitials(selectedUser.name)}
        </Avatar>
      </Badge>
      <div className={classes.details} style={{
        width: '100%'
      }}>
        <Typography
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
          variant="h5"
          color="textPrimary"
          underline="none">
          {selectedUser.name}
        </Typography>

        <Typography variant="body2" style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color: '#b0b0b0'
        }}>
          {selectedUserOnline ? t(status) : selectedUser.last_seen ? dayjs(selectedUser.last_seen).fromNow() : '-'}
        </Typography>
      </div>
      <IconButton
        onClick={handleClose}
        style={{
          float: 'right',
          zIndex: 99,
        }}
        size="large">
        <CloseOutlinedIcon />
      </IconButton>
    </div>
  );
}

export default function MobileDrawer({ presenceOpen, setPresenceOpen, selectedUser, setSelectedUser }){
  const { classes } = useStyles();
  // const avatars = useSelector(state => state.misc.avatars);
  const currentUser = useSelector(state => state.general.user);
  const presence = useSelector(state => state.misc.presence);
  const allUsersState = useUsersNoCustomer();
  const messageLists = useSelector(state => state.chatMessages);
  const allUsers = useMemo(() => allUsersState.map((user) => ({
    ...user,
    name: user.contact.name
  })), [allUsersState]);


  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if(selectedUser.id && !presenceOpen){
      setPresenceOpen(true);
    }
  }, [selectedUser]);

  const filteredPresenceUsers = useMemo(() => {
    return presence.filter((user) => {
      let userExists = false;
      allUsers.forEach((val) => {
        if(val.id === user.id){
          userExists = true;
        }
      });

      if(!userExists){return false;}

      if (user.id === currentUser.contact.id) { return false; }
      if (searchQuery.length) {
        if (!user.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          return false;
        }
      }
      return true;
    });
  }, [searchQuery, presence, currentUser, selectedUser]);

  // if (user.id === currentUser.contact.id) { return null };
  // if (user.id === selectedUser.id) { return null };

  const sortedPresenceUsers = useMemo(() => filteredPresenceUsers.sort((a, b) => {
    if (a.name > b.name) { return 1; }
    if (a.name < b.name) { return -1; }
    return 0;
  }), [filteredPresenceUsers]);

  const filteredUsers = useMemo(() => {
    return allUsers.filter((user) => {
      if (user.id === currentUser.id) { return false; }
      let userPresent = false;
      filteredPresenceUsers.forEach((presenceUser) => {
        if (user.id === presenceUser.id) {
          userPresent = true;
        }
      });
      if (userPresent) { return false; }
      if (searchQuery.length) {
        if (!user.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          return false;
        }
      }
      return true;
    });
  }, [searchQuery, allUsers, filteredPresenceUsers]);
  const sortedUsers = useMemo(() => filteredUsers.sort((a, b) => {
    if (a.name > b.name) { return 1; }
    if (a.name < b.name) { return -1; }
    return 0;
  }), [filteredUsers]);

  const unreadMessages = useMemo(() => {
    let tmpUnreadMessages = {};
    Object.keys(messageLists).forEach((msgListKey) => {
      messageLists[msgListKey].forEach((msg) => {
        if (!msg.read && msg.sender_id !== currentUser.contact.id) {
          if (!tmpUnreadMessages[msg.sender_id]) {
            tmpUnreadMessages[msg.sender_id] = [];
          }
          tmpUnreadMessages[msg.sender_id].push(msg);
        }
      })
    });
    return tmpUnreadMessages;
  }, [messageLists]);


  const handleDrawerToggle = () => {
    if(selectedUser.id){
      setSelectedUser({});
    }
    setPresenceOpen((prevState) => !prevState);
  }
  
  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setPresenceOpen(true);
  }

  return (
    <div className={classes.root}>
      <Drawer
        anchor="right"
        variant="temporary"
        className={clsx(classes.drawer, classes.drawerOpen)}
        classes={{
          paper: clsx(classes.drawerOpen),
        }}
        open={presenceOpen}
        transitionDuration={{
          enter: 400,
          exit: 400
        }}
      >
        {!selectedUser.id ?
          <div className={classes.toolbar}>
            {/* <IconButton onClick={handleDrawerToggle}>
              {presenceOpen ? <ChevronRightIcon /> : <ChatOutlinedIcon /> }
            </IconButton> */}
            <Fade
              in={presenceOpen}
              unmountOnExit={true}
            >
              <div style={{
                display: 'inline-flex',
                width: '100%',
                padding: 16
              }}>
                <Searchbar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </div>
            </Fade>
            <IconButton
              onClick={handleDrawerToggle}
              style={{
                float: 'right',
                zIndex: 99
              }}
              size="large">
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        :
          <RenderSelectedUser
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setPresenceOpen={setPresenceOpen}
          />
        }
        <Divider />

        {!selectedUser.id ?
          // <PerfectScrollbar options={{suppressScrollX: true}}>
          <SuperScrollbar>
            <div style={{
              marginTop: '10px',
              marginBottom: '20px'
            }}>

              {sortedPresenceUsers.map((user) => (
                <RenderRow
                  key={user.id}
                  user={user}
                  selectedUser={selectedUser}
                  handleSelectUser={handleSelectUser}
                  isOnline={true}
                  unreadMessages={unreadMessages[user.id] ? unreadMessages[user.id] : []}
                />
              ))}
              {sortedUsers.map((user) => (
                <RenderRow
                  key={user.id}
                  user={user}
                  selectedUser={selectedUser}
                  handleSelectUser={handleSelectUser}
                  isOnline={false}
                  unreadMessages={unreadMessages[user.id] ? unreadMessages[user.id] : []}
                />
              ))}
            </div>
          </SuperScrollbar>
        :
          <Chat
            receiver={selectedUser} 
            presenceOpen={presenceOpen}
          />
        }
      </Drawer>
    </div>
  );
}