import { Avatar, darken, lighten, Typography } from "@mui/material";
import { useSelector } from "src/store";
import { makeStyles } from "src/theme/makeStyles";
import { ContactSmall } from "src/types/api/contact";
import getInitials from "src/utils/general/getInitials";
import clsx from 'clsx';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    // borderTop: disableBorder ? 'none' : `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    transition: 'background-color 0.5s',
    // '&:hover': {
    //   backgroundColor: lighten(theme.palette.background.paper, 0.1),
    // }
    '&:hover': {
      background: theme.custom.backgroundDark
    }
  },
  rootSelected: {
    // backgroundColor: lighten(theme.palette.background.paper, 0.1)
    backgroundColor:
      // @ts-ignore
      theme.name === 'LIGHT' ? darken(theme.palette.background.paper, 0.05) : lighten(theme.palette.background.paper, 0.1)
  },
  rootNoTopBorder: {
    borderTop: 'none'
  },
  avatar: {
    height: 24,
    width: 24,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12
  }
}));

interface RowProps {
  contact: ContactSmall;
  onClick: () => void;
  disableBorderTop?: boolean;
  selected?: boolean;
}

const ContactRow = ({
  contact,
  onClick,
  disableBorderTop = false,
  selected = false
}: RowProps) => {
  const { classes, theme } = useStyles();

  const avatars = useSelector(state => state.misc.avatars);

  return (
    <div
      className={clsx(
        classes.root, 
        selected && classes.rootSelected,
        disableBorderTop && classes.rootNoTopBorder  
      )}
      onClick={onClick}
    >
      <Avatar
        className={classes.avatar}
        src={avatars[contact.id] && `data:image/jpeg;base64,${avatars[contact.id]}`}
      >
        {getInitials(contact.name)}
      </Avatar>

      <div style={{ marginLeft: theme.spacing(1) }}>
        <Typography
          color="inherit"
          variant="h6"
        >
          {contact.name}
        </Typography>
      </div>
    </div>
  )
}
export default ContactRow;