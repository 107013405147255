import type { Contact, ContactSmall } from 'src/types/api/contact';
import { RegisterDayClosing, RegisterDayClosingSmall } from 'src/types/api/financial/dayClosing';
import { Template, TemplateSmall } from 'src/types/api/template';
import type { User, UserSmall } from 'src/types/api/user';

export const contactToSmall = (contact: Contact): ContactSmall => ({
  id: contact.id,
  name: contact.name,
  first_name: contact.first_name,
  last_name: contact.last_name,
  client_number: contact.client_number,
  tag: contact.tag,
  email: contact.email,
  phone: contact.phone,
  tags: contact.tags,
  created_at: contact.created_at,
  updated_at: contact.updated_at
});

export const userToSmall = (user: User): UserSmall => ({
  id: user.id,
  email: user.email,
  contact: user.contact,
  is_active: user.is_active,
  status: user.status,
  last_seen: user.last_seen,
  role: user.role,
  type: user.type,
  language: user.language,
  support: user.support,
  inherit_working_hours: user.inherit_working_hours,
  event_overlaps: user.event_overlaps
});

export const dayClosingToSmall = (dayClosing: RegisterDayClosing): RegisterDayClosingSmall => ({
  id: dayClosing.id,
  balance: dayClosing.balance,
  cash: dayClosing.cash,
  closed_at: dayClosing.closed_at,
  closed_by: dayClosing.closed_by,
  closing_balance: dayClosing.closing_balance,
  number: dayClosing.number,
  opened_at: dayClosing.opened_at,
  opened_by: dayClosing.opened_by,
  opening_balance: dayClosing.opening_balance,
  payments: dayClosing.payments,
  created_at: dayClosing.created_at,
  updated_at: dayClosing.updated_at
});

export const templateToSmall = (template: Template): TemplateSmall => ({
  id: template.id,
  prefix: template.prefix,
  name: template.name,
  description: template.description,
  deletable: template.deletable,
  groups: template.groups,
  subscriptions: template.subscriptions,
  has_default_subscription: !!template.subscriptions.find(sub => sub.is_default),
  has_default_role: !!template.roles.find(role => role.default),
  created_at: template.created_at,
  updated_at: template.updated_at,
  
  color1_dark: template.color1_dark,
  color2_dark: template.color2_dark,
  color3_dark: template.color3_dark,
  color1_light: template.color1_light,
  color2_light: template.color2_light,
  color3_light: template.color3_light
});