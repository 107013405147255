import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps
} from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';

const useStyles = makeStyles()(theme => ({
  toggleButtonGroupRoot: {
    height: 35
  }
}));

const ToggleButtonGroup = (props: MuiToggleButtonGroupProps) => {
  const { classes } = useStyles();

  return (
    <MuiToggleButtonGroup
      classes={{ root: classes.toggleButtonGroupRoot }}
      {...props}
    />
  )
}
export default ToggleButtonGroup;