import { useMemo } from "react";
import { useSelector } from "src/store";

interface Currency {
  symbol: string;
  delimiter: string;
  thousandDelimiter: string;
  denominations: number[];
}

const CURRENCIES: { [key: string]: Currency; } = {
  eur: {
    symbol: '€',
    delimiter: ',',
    thousandDelimiter: '.',
    denominations: [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500]
  },
  usd: {
    symbol: '$',
    delimiter: '.',
    thousandDelimiter: ',',
    denominations: [0.01, 0.05, 0.1, 0.25, 0.5, 1, 2, 5, 10, 20, 50, 100, 500, 1000, 5000]
  }
}

const DEFAULT_CURRENCY = CURRENCIES.eur;

const useCurrency = () => {
  const settings = useSelector(state => state.general.settings);
  const currency = useMemo(() => {
    const currencySetting = 
      settings.find(setting => setting.type === 'group' && setting.tag === 'currency');
    if(currencySetting && CURRENCIES[currencySetting.value.toLowerCase()]){
      return CURRENCIES[currencySetting.value.toLowerCase()]; 
    }
    return DEFAULT_CURRENCY;
  }, [settings]);

  const numberToPriceString = (price: number, withSymbol = true) => {
    return `${withSymbol ? currency.symbol : ''}${price.toFixed(2).replace('.', currency.delimiter)}`;
  }

  return {
    ...currency,
    numberToPriceString
  };
}

export default useCurrency;