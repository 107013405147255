import { Button, Paper, Typography, useTheme } from "@mui/material";
import Peer, { DataConnection, MediaConnection } from "peerjs";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "src/i18n";
import translatePercentageToScreenPixels from "./translatePercentageToScreenPixels";

// should only be used once

const RenderFakePointer = ({ x, y }: { x: number; y: number; }) => {
  return ReactDOM.createPortal(
    <div 
      style={{ 
        position: 'absolute', 
        width: 10, 
        height: 10, 
        background: '#ffffff', 
        left: x, 
        top: y, 
        zIndex: 9999, 
        pointerEvents: 'none',
        borderRadius: '50%',
        border: '1px solid #000000'
      }}
    >
    </div>,
    document.body
  );
}

interface Props {
  userId: string;
}

const SupportListener = ({ 
  userId 
}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const peer = useRef<Peer | null>(null);

  const [initialized, setInitialized] = useState(false);

  const [currentCall, setCurrentCall] = useState<MediaConnection | null>(null);
  const [currentConnection, setCurrentConnection] = useState<DataConnection | null>(null);
  
  
  const [callAnswered, setCallAnswered] = useState(false);

  useEffect(() => {
    if(!currentCall){
      setCallAnswered(false);
    }
  }, [currentCall])

  const destroyCall = (call: MediaConnection) => {    
    // console.log('DESTROYING !!!');

    call.close();
    setCurrentCall(null);

    currentConnection?.close();
    setCurrentConnection(null);

    if(peer.current){
      peer.current?.destroy();
      // peer.current = new Peer(userId, { debug: 3 });
      peer.current = new Peer(userId);
    }
  }

  const [shouldShowPointer, setShouldShowPointer] = useState(false);

  const [mouseLocation, setMouseLocation] = useState({ x: 0, y: 0 });
  const mouseLocationRef = useRef(mouseLocation);
  useEffect(() => {
    mouseLocationRef.current = mouseLocation;
  }, [mouseLocation]);

  const handleFakeMouseClick = (x: number, y: number) => {
    const el = document.elementFromPoint(x, y);
    if(el){
      var ev = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true,
        'screenX': x,
        'screenY': y
      });
      
      if(el){
        el.dispatchEvent(ev);
      }
    }
  }

  useEffect(() => {
    if(initialized){
      return;
    }

    // peer.current = new Peer(userId, { debug: 3 });
    peer.current = new Peer(userId);

    peer.current.on('call', async (call: MediaConnection) => {
      // const ownMediaStream = await navigator.mediaDevices.getDisplayMedia();
      // call.answer(ownMediaStream);
    
      call.on('iceStateChanged', (state) => {
        // console.log(state);
        if(state === 'disconnected'){ destroyCall(call); }
        if(state === 'failed'){ destroyCall(call); }
        if(state === 'closed'){ destroyCall(call); }
      })

      call.on('error', (err) => {
        console.error(err);
        destroyCall(call);
      });

      call.on('close', () => {
        // console.log('CLOSE');
        destroyCall(call);
      });

      setCurrentCall(call);
    });

    // Initialized mouse pointer
    peer.current.on('connection', (dataConnection: DataConnection) => {
      // if already has connection ???

      dataConnection.on('open', () => {
        setShouldShowPointer(true);
        dataConnection.on('data', (data) => {
          const parsedData: { type: string; data: unknown; } = JSON.parse(data as string);
          if(parsedData.type === 'click'){
            const x = mouseLocationRef.current.x;
            const y = mouseLocationRef.current.y;
            handleFakeMouseClick(x, y);
          }

          if(parsedData.type === 'move'){
            const mouseData = parsedData.data as { x: number, y: number; } 
            const result = translatePercentageToScreenPixels(mouseData.x, mouseData.y);
            setMouseLocation(result);
          }
        });

        // maybe set data connection
      });

      setCurrentConnection(dataConnection);
    });


    setInitialized(true);
  }, [userId]);

  const handleAnswerCall = async () => {
    if(!currentCall){
      return;
    }
    setCallAnswered(true);
    
    try {
      const ownMediaStream = await navigator.mediaDevices.getDisplayMedia({ 
        // @ts-ignore
        preferCurrentTab: true 
      });
      currentCall.answer(ownMediaStream);
    }catch(ex){
      console.error(ex);
      destroyCall(currentCall);
    }
  }

  const handleRejectCall = () => {
    if(!currentCall){
      return;
    }
    destroyCall(currentCall);
  }

  if(!currentCall){
    return null;
  }
  
  if(!!callAnswered){
    return (
      <>
        {shouldShowPointer && (
          <RenderFakePointer x={mouseLocation.x} y={mouseLocation.y} />
        )}
      </>
    );
  }

  return ReactDOM.createPortal(
    <>
      <Paper
        style={{ 
          position: 'absolute', 
          top: 50, 
          left: 50, 
          width: 250, 
          height: 150,
          zIndex: 9999,
          padding: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography>{t('description.live_support')}</Typography>
        <div style={{ marginLeft: 'auto', marginTop: 'auto' }}>
          <Button onClick={handleRejectCall}>Afwijzen</Button>
          <Button onClick={handleAnswerCall} variant='contained'>Toestaan</Button>
        </div>
      </Paper>
    </>, 
    document.body
  );
}

export default SupportListener;