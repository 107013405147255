import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { IconButton, Popover, Tooltip, Paper } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'src/i18n';
import { makeStyles } from 'src/theme/makeStyles';
import Notification from './Notification';

const useStyles = makeStyles()(theme => ({
  iconButton: {
    color: theme.palette.text.primary
  }
}));

const Notifications = () => {
  const { classes } = useStyles();
  const {t} = useTranslation();

  // const [unreadNotifications, setUnreadNotifications] = useState(0);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => { 
    setAnchorEl(e.currentTarget);
  }
  const handleClose = () => { setAnchorEl(null); }
  
  return (
    <>
      <Tooltip title={`${t('lang.notifications')}`}>
        <IconButton
          className={classes.iconButton}
          onClick={handleOpen}
        >
          <NotificationsNoneOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Paper
          style={{
            width: 320,
            maxHeight: 600,
            overflow: 'auto'
          }}
        >
          {new Array(9).fill(null).map((x, index) => (
            <Notification 
              key={index} 
              title={'Test notificatie'}
              content={'Dit is een test notificatie!'}
              time={'13:50'}
            />
          ))}
        </Paper>
      </Popover>
    </>
  )
}
export default Notifications;