import { alpha, Button, Grid, Typography } from "@mui/material";
import clsx from 'clsx';
import { useTranslation } from 'src/i18n';
import SvgPedicure from 'src/assets/svg/salonTypes/Feet';
import SvgMassageSalon from 'src/assets/svg/salonTypes/Hands';
import SvgOther from 'src/assets/svg/salonTypes/Leaves';
import SvgNailSalon from 'src/assets/svg/salonTypes/Nail';
import SvgBeautySalon from 'src/assets/svg/salonTypes/Parfume';
import SvgBarbershop from 'src/assets/svg/salonTypes/Razor';
import SvgHairDresser from 'src/assets/svg/salonTypes/Scissor';
import { makeStyles } from 'src/theme/makeStyles';
import BackNextButtons from "../components/BackNextButtons";
import { SalonType } from './types';

const svgMap = {
  hair_dresser: SvgHairDresser,
  barbershop: SvgBarbershop,
  nail_salon: SvgNailSalon,
  pedicure: SvgPedicure,
  beauty_salon: SvgBeautySalon,
  massage_salon: SvgMassageSalon,
  other: SvgOther
}

const useStyles = makeStyles()(theme => ({
  button: {
    justifyContent: 'left',
    background: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.4)
    },
    height: 60,
    border: `1px solid ${theme.palette.divider}`
  },
  buttonSelected: {
    background: alpha(theme.palette.primary.main, 0.4)
  }
}));

const SALON_TYPES: SalonType[] = ['hair_dresser', 'barbershop', 'nail_salon', 'pedicure', 'beauty_salon', 'massage_salon', 'other'];

interface Props {
  stepIndex: number;
  onBack: () => void;
  value: SalonType | null;
  onNext: () => void;
  onChange: (salonType: SalonType) => void;
}

const SelectSalonType = ({
  stepIndex,
  onBack,
  onNext,
  value,
  onChange
}: Props) => {
  const {t} = useTranslation();
  const { classes } = useStyles();

  const handleNext = () => {
    if(value){
      onNext();
    }
  }

  return (
    <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">{t(`description.stepper_salon_type_choice`)}</Typography>
      </Grid>
          {SALON_TYPES.map((salonType, index) => {
            const SvgComponent = svgMap[salonType];
            return (
              <Grid item xs={12} md={6} key={index}>
                <Button
                  className={clsx(
                    classes.button, 
                    salonType === value && classes.buttonSelected
                  )}
                  onClick={() => onChange(salonType)}
                  variant='contained'
                  fullWidth
                  startIcon={
                    <div style={{ width: 50, height: 50 }}>
                      <SvgComponent />
                    </div>
                  }
                >
                  {t(`lang.${salonType}`)}
                </Button>
              </Grid>
            )
          })}
      </Grid>         
      <BackNextButtons
        disableBack={stepIndex === 0}
        disableNext={!value}
        onBack={onBack}
        onNext={handleNext} // handle local submit ??
      />
    </>
  )
}
export default SelectSalonType;