import { Grid, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import BackNextButtons from "../components/BackNextButtons";
import { FormValues } from "./types";
import { DEFAULT_FORM_VALUES } from './constants';
import FirstUpperField from 'src/components/forms/fields/FirstUpper';
import HouseNumberField from 'src/components/forms/fields/Address/HouseNumber';
import ZipCodeField, { validateZipcode } from 'src/components/forms/fields/Address/ZipCode';
import StreetField from 'src/components/forms/fields/Address/Street';
import CityField from 'src/components/forms/fields/Address/City';
import PhoneField from 'src/components/forms/fields/Phone';
import validations from 'src/components/forms/validations';
import useAddressAutofillController from 'src/components/forms/fields/Address/useAddressAutofillController';
import { useTranslation } from 'src/i18n';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "src/store";
import { useEffect } from "react";
import UpperCaseField from "src/components/forms/fields/UpperCase";

const yup_schema = yup.object({
  company_name: validations.default_string,
  street: validations.default_string,
  number: validations.default_string,
  // addition: validations.default_string,
  zipcode: validations.default_string,
  city: validations.default_string,
  phone_number: validations.phone_number, // ???
  coc_number: validations.coc_number.notRequired(),
  vat_number: validations.vat_number.notRequired()
});

interface Props {
  stepIndex: number;
  onBack: () => void;
  onNext: () => void;
  onChange: (values: FormValues) => void;
  initialValues?: FormValues;
}

const CompanyInformation = ({
  stepIndex,
  onBack,
  onNext,
  onChange,
  initialValues
}: Props) => {
  const {t} = useTranslation();

  const generalGroup = useSelector(state => state.general.group);

  const addressAutofillController = useAddressAutofillController();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch
  } = useForm<FormValues>({
    defaultValues: initialValues ? initialValues : DEFAULT_FORM_VALUES,
    resolver: yupResolver(yup_schema)
  });

  const { 
    zipcode: zipcodeValue, 
    number: housenumberValue 
  } = watch(); 

  const onSubmit = (values: FormValues) => {
    const zipcodeValid = validateZipcode(values.zipcode)
    if(!zipcodeValid){
      setError('zipcode', { message: 'error.invalid_zip' });
      return;
    }
    
    onChange(values);
    onNext();
  }

  useEffect(() => {
    if(generalGroup){
      setValue('company_name', generalGroup.contact.name);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">{t(`description.stepper_company_information`)}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Controller 
          name='company_name'
          control={control}
          render={({ field }) => (
            <FirstUpperField 
              label={t('lang.company_name')}
              variant='standard'
              fullWidth
              error={!!errors.company_name}
              helperText={errors.company_name?.message ? t(errors.company_name.message) : ' '}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name='zipcode'
          control={control}
          render={({ field }) => (
            <ZipCodeField 
              value={field.value}
              onChange={field.onChange}
              addressAutofillController={addressAutofillController}
              textFieldProps={{
                error: !!errors.zipcode,
                helperText: errors.zipcode?.message ? t(errors.zipcode.message) : ''
              }}
              nrValue={housenumberValue}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller 
          name='number'
          control={control}
          render={({ field }) => (
            <HouseNumberField 
              textFieldProps={{
                label: `${t('lang.number')}`,
                error: !!errors.number
              }}
              value={field.value}
              onChange={field.onChange}
              zipValue={zipcodeValue}
              addressAutofillController={addressAutofillController}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller 
          name='addition'
          control={control}
          render={({ field }) => (
            <TextField 
              label='Toevg.'
              fullWidth
              variant='standard'
              error={!!errors.addition}
              helperText={errors.addition?.message ? t(errors.addition.message) : ' '}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={6}>
        <Controller
          name='street'
          control={control}
          render={({ field }) => (
            <StreetField 
              value={field.value}
              onChange={field.onChange}
              addressAutofill={addressAutofillController}
              textFieldProps={{
                error: !!errors.street,
                helperText: errors.street?.message ? t(errors.street.message) : ' '
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name='city'
          control={control}
          render={({ field }) => (
            <CityField 
              value={field.value}
              onChange={field.onChange}
              addressAutofill={addressAutofillController}
              textFieldProps={{
                error: !!errors.city,
                helperText: errors.city?.message ? t(errors.city.message) : ' '
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='phone_number'
          control={control}
          render={({ field }) => (
            <PhoneField 
              fullWidth
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message ? t(errors.phone_number.message) : ' '}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        {/* Naar formField component ??? */}
        <Controller
          name='coc_number'
          control={control}
          render={({ field }) => (
            <TextField 
              label='KvK-nummer' // Translation !!!
              variant='standard'
              fullWidth
              error={!!errors.coc_number}
              helperText={errors.coc_number?.message ? t(errors.coc_number.message) : ' '}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name='vat_number'
          control={control}
          render={({ field }) => (
            <UpperCaseField 
              label='BTW-nummer' // Translation !!!
              fullWidth
              variant='standard'
              error={!!errors.vat_number}
              helperText={errors.vat_number?.message ? t(errors.vat_number.message) : ' '}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <BackNextButtons
          disableBack={stepIndex === 0}
          onBack={onBack}
          onNext={handleSubmit(onSubmit)} // handle local submit ??
        />
      </Grid>
    </Grid>
     
  )
}
export default CompanyInformation;