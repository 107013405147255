import { Grid, IconButton, Switch, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'src/i18n';
import { TimeRangePicker } from 'src/components/general/DateTimePicker_v3';
import { FormValues, TimesError, TimeValue } from './types';
import BackNextButtons from '../components/BackNextButtons';
import { useForm } from 'react-hook-form';
import { getDefaultFormValues, getDefaultTimes } from './constants';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import timeOrderCorrect from 'src/utils/general/timeOrderCorrect';

// const ROW_HEIGHT = 52;

interface Props {
  stepIndex: number;
  onBack: () => void;
  onNext: () => void;
  onChange: (values: FormValues) => void;
  initialValues?: FormValues;
}

const OpeningHours = ({
  stepIndex,
  onBack,
  onNext,
  onChange,
  initialValues
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // INITIALVALUES WITH HOOK FORM TODO

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: initialValues ? initialValues : getDefaultFormValues()
  });
  const watchValues = watch();

  const handleSwitchClick = (key: keyof FormValues) => {
    setValue(key, { 
      ...watchValues[key],
      enabled: !watchValues[key].enabled
    });
  }

  const handleTimeChange = (key: keyof FormValues, rowIndex: number, newValue: TimeValue) => {
    setValue(key, { 
      ...watchValues[key],
      times: watchValues[key].times.map((time, index) => index !== rowIndex ? time : newValue)
    });
  }

  const handleAddRow = (key: keyof FormValues) => {
    setValue(key, { 
      ...watchValues[key],
      times: [
        ...watchValues[key].times,
        getDefaultTimes()
      ]
    });
  }

  const handleRemoveRow = (key: keyof FormValues, rowIndex: number) => {
    setValue(key, { 
      ...watchValues[key],
      times: watchValues[key].times.filter((time, index) => index !== rowIndex)
    });
  }
  
  const [timesError, setTimesError] = useState<TimesError>({})
  const onSubmit = (values: FormValues) => {
    let l_timesError: {
      monday?: boolean;
      tuesday?: boolean;
      wednesday?: boolean;
      thursday?: boolean;
      friday?: boolean;
      saturday?: boolean;
      sunday?: boolean;
    } = {};

    const objectKeys = Object.keys(values) as (keyof FormValues)[];
    objectKeys.forEach(key => {
      const times = values[key].times.map(times => ({ start_time: times.start, end_time: times.end }));
      const isTimeOrderCorrect = timeOrderCorrect(times);
      if(!isTimeOrderCorrect){
        l_timesError[key] = true;
      }
    });
    setTimesError(l_timesError);

    if(Object.keys(l_timesError).length){
      return;
    }

    onChange(values);
    onNext();
  }


  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">{t(`description.stepper_working_hours`)}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <table style={{ width: '100%' }}>
          {Object.keys(watchValues).map((strKey, index) => {
            const key = strKey as keyof FormValues;
            const value = watchValues[key];

            if (!value.times.length) {
              console.error('No times set !!!');
              return null;
            }

            return (
              <Fragment key={index}>
                <tr style={{ minHeight: 56 }}>
                  <td><Typography>{t(`lang.${key}`)}</Typography></td>
                  <td>
                    <Switch 
                      checked={value.enabled} 
                      onChange={() => handleSwitchClick(key)}
                    />
                  </td>
                  {value.enabled ? (
                    <>
                      <td>
                        {value.times[0] && (
                          <div style={value.times.length === 1 ? { paddingBottom: theme.spacing(1) } : {}}>
                            <TimeRangePicker
                              value={value.times[0]}
                              onChange={(val) => handleTimeChange(key, 0, val)}
                              error={{ start: !!timesError[key], end: !!timesError[key] }}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        <IconButton 
                          size='small'
                          onClick={() => handleAddRow(key)}
                          color='primary'
                        >
                          <AddIcon />
                        </IconButton>
                      </td>
                    </>
                  ) : (
                    <td colSpan={2}></td>
                  )}
                </tr>
                {value.enabled && value.times.map((time, rowIndex) => {
                  if (rowIndex === 0) {
                    return null;
                  }
                  return (
                    <Fragment key={rowIndex}>
                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <div style={rowIndex === value.times.length - 1 ? { paddingBottom: theme.spacing(1) } : {}}>
                            <TimeRangePicker
                              value={time}
                              onChange={(val) => handleTimeChange(key, rowIndex, val)}
                              noLabels
                              error={{ start: !!timesError[key], end: !!timesError[key] }}
                            />
                          </div>
                        </td>
                        <td>
                          <IconButton 
                            size='small'
                            onClick={() => handleRemoveRow(key, rowIndex)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })}
              </Fragment>
            )
          })}
        </table>
      </Grid>
      <Grid item xs={12}>
      <BackNextButtons
        disableBack={stepIndex === 0}
        onBack={onBack}
        onNext={handleSubmit(onSubmit)} // handle local submit ??
      />
      </Grid>
      </Grid>
  )
}
export default OpeningHours;