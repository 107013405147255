import { Avatar, colors, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'src/theme/makeStyles';
import clsx from 'clsx';
import { memo } from 'react';
import getInitials from 'src/utils/general/getInitials';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    paddingLeft: 20
  },
  avatar: {
    marginLeft: -20,
    '&:hover': {
      zIndex: 2
    },
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  more: {
    backgroundColor: theme.palette.primary.main,
    color: colors.common.white
  },
  avatarBorder: {
    border: `3px solid ${theme.palette.background.default}`
  }
}));

interface Props {
  avatars: { name: string; url: string | null; }[],
  limit: number;
  className?: string;
  noBorder?: boolean;
  [rest: string]: any;
}

const StackAvatars = ({
  avatars,
  limit,
  className = '',
  noBorder = false,
  ...rest
}: Props) => {
  const { classes } = useStyles();
  let lastIndex = 0;
  const avatarNodes = avatars.slice(0, limit).map((item) => (
    <Tooltip
      key={++lastIndex}
      title={item.name}
    >
      <Avatar
        className={classes.avatar}
        src={item.url ? item.url : ''}
      >
        {getInitials(item.name)}
      </Avatar>
    </Tooltip>
  ));

  if (avatars.length > limit) {
    avatarNodes.push(
      <Tooltip 
        key={++lastIndex}
        title="test"
      >
        <Avatar
          className={clsx(classes.avatar, classes.more, !noBorder && classes.avatarBorder)}
        >
          <Typography
            color="inherit"
            variant="subtitle2"
          >
            +
            {avatars.length - limit}
          </Typography>
        </Avatar>
      </Tooltip>
    );
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {avatarNodes}
    </div>
  );
}

export default memo(StackAvatars);