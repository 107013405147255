import axios, { AxiosRequestConfig } from "axios";
import { deleteInvoice, insertInvoice, setInvoiceFilteredTotalRows, setInvoiceTotalRows } from "src/slices/lists/invoicing";
import { createRegisterDayClosingPayment, deleteRegisterDayClosingPayment } from "src/slices/register";
import { AppDispatch } from "src/store";
import { Invoice, InvoiceDirection, InvoiceLine, InvoiceStatus } from "src/types/api/financial/invoice";
import { PaymentType } from "src/types/api/financial/payment";
import CollatedResponse from "src/types/api/general/collated";
import { PostCollationData } from "src/types/request/collation";

export const getInvoicesPaginatedRequest = (
  start: number,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  urlParams?: URLSearchParams,
  setTotalRows: boolean = true
): Promise<CollatedResponse<Invoice[]>> => {
  return new Promise<CollatedResponse<Invoice[]>>((resolve, reject) => {
    let url = `invoices?start=${start}`;
    if(urlParams){
      url += `&${urlParams.toString()}`;
    }

    axios.get<CollatedResponse<Invoice[]>>(url, config)
    .then((response) => {
      if(setTotalRows){
        dispatch(setInvoiceFilteredTotalRows(response.data.meta.filteredTotal));
        dispatch(setInvoiceTotalRows(response.data.meta.total));
      }
      resolve(response.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const getInvoiceRequest = (
  invoice_id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  urlParams?: URLSearchParams,
  setTotalRows: boolean = true
): Promise<CollatedResponse<Invoice | null>> => {
  return new Promise<CollatedResponse<Invoice | null>>((resolve, reject) => {
    let url = `invoices/${invoice_id}`;
    if(urlParams){
      url += `?${urlParams.toString()}`;
    }

    axios.get<CollatedResponse<Invoice>>(url, config)
    .then((response) => {
      if(setTotalRows){
        dispatch(setInvoiceFilteredTotalRows(response.data.meta.filteredTotal));
        dispatch(setInvoiceTotalRows(response.data.meta.total));
      }
      resolve(response.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}


type CreateContactAddressPair = {
  contact_id: string;
  address_id: string | null;
} | {
  contact_id: null,
  address_id: null,
  copy_from_party_id?: string;
}

type UpdateContactAddressPair = {
  contact_id: string;
  address_id: string | null;
}

export interface CreateInvoiceRequestData {
  parties: {
    contact?: CreateContactAddressPair;
    contact_person?: CreateContactAddressPair;
  },
  quotation_id: string | null;
  workflow_id: string;
  direction: InvoiceDirection;
  status: InvoiceStatus;
  currency: 'eur';
  discount: number | null;
  including_tax: boolean;
  lines: InvoiceLine[];
  payments: {
    price: number;
    description: string | null;
    type: PaymentType;
    giftcard_id: string | null;
  }[];
}

export const createInvoiceRequest = (
  data: {
    data: CreateInvoiceRequestData,
    collationData?: PostCollationData;
  },
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  addPaymentsToDayClosing: boolean = true,
  setTotalRows: boolean = true
): Promise<Invoice> => {
  return new Promise<Invoice>((resolve, reject) => {
    axios.post<CollatedResponse<Invoice>>('invoices', { ...data.data, ...data.collationData }, config)
    .then((response) => {
      if(response.data.meta.position !== null){
        dispatch(insertInvoice({ data: response.data.data, index: response.data.meta.position }))
      }

      if(setTotalRows){
        dispatch(setInvoiceFilteredTotalRows(response.data.meta.filteredTotal));
        dispatch(setInvoiceTotalRows(response.data.meta.total));
      }

      // dispatch(createInvoice(response.data.data));
      
      // add payments to dayclosing
      if(addPaymentsToDayClosing){
        response.data.data.payments.map(payment => 
          dispatch(createRegisterDayClosingPayment(payment))
        );
      }
      resolve(response.data.data);
    })
    .catch((error) => {
      reject();
    });
  });
}

export interface UpdateInvoiceRequestData {
  parties: {
    contact?: UpdateContactAddressPair;
    contact_person?: UpdateContactAddressPair;
  };
  quotation_id: string | null;
  workflow_id: string;
  direction: InvoiceDirection;
  status: InvoiceStatus;
  currency: 'eur';
  discount: number | null;
  including_tax: boolean;
  lines: InvoiceLine[];
  payments: {
    price: number;
    description: string | null;
    type: PaymentType;
    giftcard_id: string | null;
  }[];
  forceOverwrite: boolean;
  deletedPaymentIds: string[];
}

export const updateInvoiceRequest = (
  id: string,
  data: {
    data: UpdateInvoiceRequestData,
    collationData?: PostCollationData;
  },
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  updateDayClosingPayments: boolean = true,
  setTotalRows: boolean = true
): Promise<Invoice> => {
  return new Promise<Invoice>((resolve, reject) => {
    axios.put<CollatedResponse<Invoice>>(`invoices/${id}`, { ...data.data, ...data.collationData }, config)
    .then((response) => {
      dispatch(deleteInvoice(response.data.data));
      if(response.data.meta.position !== null){
        dispatch(insertInvoice({ data: response.data.data, index: response.data.meta.position }))
      }

      if(setTotalRows){
        dispatch(setInvoiceFilteredTotalRows(response.data.meta.filteredTotal));
        dispatch(setInvoiceTotalRows(response.data.meta.total));
      }
      
      if(updateDayClosingPayments){
        data.data.deletedPaymentIds.forEach(payment_id => {
          dispatch(deleteRegisterDayClosingPayment(payment_id));
        });
        
        response.data.data.payments.forEach(payment => {
          dispatch(createRegisterDayClosingPayment(payment))
        });
      }
      resolve(response.data.data);
    })
    .catch((error) => {
      reject();
    });
  });
}

export const deleteInvoiceRequest = (
  id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig,
  collationData?: PostCollationData,
  setTotalRows: boolean = true
): Promise<Invoice> => {
  return new Promise<Invoice>((resolve, reject) => {
    axios.delete<CollatedResponse<Invoice>>(`invoices/${id}`, { ...config, data: collationData })
    .then((response) => {
      dispatch(deleteInvoice(response.data.data));
      
      if(setTotalRows){
        dispatch(setInvoiceFilteredTotalRows(response.data.meta.filteredTotal));
        dispatch(setInvoiceTotalRows(response.data.meta.total));
      }
      
      resolve(response.data.data);
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const downloadInvoiceRequest = (
  id: string,
  config: AxiosRequestConfig
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    axios.get<string>(`invoices/${id}/pdf`, config) //?download=true
    .then((response) => resolve(response.data))
    .catch((error) => reject());
  });
}

export const sendInvoiceRequest = (
  id: string,
  email: string,
  config: AxiosRequestConfig
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    axios.post<string>(`invoices/${id}/send`, { email }, config)
    .then((response) => resolve(response.data))
    .catch((error) => reject());
  });
}