import axios, { AxiosRequestConfig } from 'axios';
import { createAbsence, deleteAbsence, updateAbsence } from 'src/slices/lists/absences';
import { AppDispatch } from 'src/store';
import { Absence } from "src/types/api/absence";

interface CreateAbsenceRequestData {
  title: string;
  start: number;
  end: number;
  all_day: boolean;
  members: string[];
  all_users: boolean;
}
export const createAbsenceRequest = (
  data: CreateAbsenceRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Absence> => {
  return new Promise<Absence>((resolve, reject) => {
    axios.post<Absence>(`absences`, data, config)
    .then((response) => {
      dispatch(createAbsence(response.data));
      resolve(response.data);
    }).catch(err => reject(err));
  });
}

type UpdateAbsenceRequestData = CreateAbsenceRequestData; 
export const updateAbsenceRequest = (
  updateId: string,
  data: UpdateAbsenceRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Absence> => {
  return new Promise<Absence>((resolve, reject) => {
    axios.put<Absence>(`absences/${updateId}`, data, config)
    .then((response) => {
      dispatch(updateAbsence(response.data));
      resolve(response.data);
    }).catch(err => reject(err));
  });
}

export const deleteAbsenceRequest = (
  id: string,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Absence> => {
  return new Promise<Absence>((resolve, reject) => {
    axios.delete<Absence>(`absences/${id}`, config)
    .then(response => {
      dispatch(deleteAbsence(response.data));
      resolve(response.data);
    }).catch(err => reject(err));
  });
}