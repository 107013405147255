import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Appointment } from 'src/types/api/appointment';
import type { Service } from 'src/types/api/service';

const initialState: Appointment[] = [];

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setAppointments(state: Appointment[], action: PayloadAction<Appointment[]>){
      return action.payload;
    },
    createAppointment(state: Appointment[], action: PayloadAction<Appointment>){
      state.push(action.payload);
    },
    updateAppointment(state: Appointment[], action: PayloadAction<Appointment>){
      return state.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteAppointment(state: Appointment[], action: PayloadAction<Appointment>){
      return state.filter((val) => val.id !== action.payload.id);
    },

    updateAppointmentService(state: Appointment[], action: PayloadAction<Service>){
      return state.map((appointment) => ({
        ...appointment,
        services: [
          ...appointment.services.map((service) => service.service.id === action.payload.id ? 
            { ...service, service: action.payload } :
            { ...service }
          )
        ]
      }))
    }
  }
})

export const { reducer } = slice;

export const setAppointments = (data: Appointment[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAppointments(data));
}
export const createAppointment = (data: Appointment): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createAppointment(data));
}
export const updateAppointment = (data: Appointment): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAppointment(data));
}
export const deleteAppointment = (data: Appointment): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteAppointment(data));
}

export const updateAppointmentService = (data: Service): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAppointmentService(data));
}

export default slice;