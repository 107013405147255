import { Tabs as MuiTabs, Tab as MuiTab, Tooltip } from '@mui/material';
import { useSelector } from 'src/store';
import { makeStyles } from 'src/theme/makeStyles';
import { useLocation, useMatch, useNavigate } from 'react-router';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AlertProps } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  tab: {
    fontWeight: 400,
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px',
    }
  },
}));

const Tabs = () => {
  const { classes, theme } = useStyles();

  const topbarValues = useSelector(state => state.topbar);
  const generalUser = useSelector(state => state.general.user);

  const match = useMatch(topbarValues.routePattern ? topbarValues.routePattern : '');
  const tabParam = match ? match.params['tab'] : '';

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // const handleTabChange = (tabId: string) => {
  //   dispatch(updateTopbarValue({ selectedTabId: tabId }));
  // }



  const handleTabChange = (tabUrl: string) => {
    if(tabParam){
      const new_path = pathname.split(tabParam);
      navigate(new_path[0]+tabUrl);
    }else{
      if(pathname.endsWith('/')){
        navigate(pathname + tabUrl);
      }else{
        navigate(pathname + '/' + tabUrl);
      }
    }
  }

  // console.log(tabParam);
  // if(typeof tabParam !== 'string'){
  //   return null;
  // }

  return (
    <MuiTabs
      variant="scrollable"
      scrollButtons={false}
      value={tabParam} // or false ??
      onChange={(e, value) => handleTabChange(value)}
    >
      {topbarValues.tabs.map((tab) => {
        let AlertComponent = null;
        let alertColor: AlertProps['color'] = undefined;
        if(tab.alert){
          if(tab.alert.type === 'error'){
            AlertComponent = ErrorOutlineIcon;
            alertColor = 'error';
          }
          if(tab.alert.type === 'warning'){
            AlertComponent = WarningAmberIcon;
            alertColor = 'warning';
          }
        }

        const permissionsToCheck = tab.permissions ? tab.permissions.map(perm => perm.toLowerCase()) : [];
        if(generalUser && permissionsToCheck.length)
        {
          const matchingPermissions = generalUser.role.permissions.filter((perm) => permissionsToCheck.includes(perm.tag));
          if(matchingPermissions.length !== permissionsToCheck.length)
            return null;
        }

        return (
          <MuiTab 
            key={tab.id}
            value={tab.urlParam}
            onClick={() => {
              console.log(tab.urlParam, tabParam);
              if(tab.urlParam === tabParam){
                handleTabChange(tab.urlParam);
              }
            }}
            label={(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {tab.title}
                {/* {tab.alert?.type === 'error' && (
                  <div>
                    <ErrorOutlineIcon  />
                  </div>
                )}
                {tab.alert?.type === 'warning' && (
                  <div>
                    <WarningAmberIcon 
                      color={'warning'} 
                      style={{ fontSize: 18, marginLeft: theme.spacing(1) }} 
                    />
                  </div>
                )} */}

                {!!AlertComponent && (
                  <div style={{ marginLeft: theme.spacing(1) }}>
                    <Tooltip title={tab.alert!.text}>
                      <AlertComponent 
                        color={alertColor}
                        style={{ fontSize: 18 }}
                      />
                    </Tooltip>
                  </div>
                )}

              </div>
            )}
            classes={{ root: classes.tab }}
          />
        )
      })}
    </MuiTabs>
  )
}
export default Tabs;