import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Event, EventStatus } from 'src/types/api/events/Event';

interface EventStateView {
  data: string[]
  sort: string;
  filters: {
    [key: string]: string;
  },
  search: string; // Not useful here !!
  totalRows: number | null; // Maybe rows: { filtered, total }
  initialized: boolean;
}

export interface EventState {
  events: {
    data: Event[];
    views: {
      calendar: EventStateView;
      checkout: EventStateView;
      schedule: EventStateView;
      contact: EventStateView;
      widget: EventStateView;
      [key: string]: EventStateView;
    };
  };
  statuses: EventStatus[];
}

export type EventStateViewKey = keyof EventState['events']['views'];

export const initialEventsState: EventState = {
  events: {
    data: [],
    views: {
      calendar: { data: [], sort: '', filters: {}, search: '', totalRows: null, initialized: false },
      checkout: { data: [], sort: '-start_date', filters: { type: 'appointment' }, search: '', totalRows: null, initialized: false },
      schedule: { data: [], sort: '', filters: { types: 'absence,holiday' }, search: '', totalRows: null, initialized: false },
      contact: { data: [], sort: '-start_date', filters: { type: 'appointment' }, search: '', totalRows: null, initialized: false },
      widget: { data: [], sort: 'start_date', filters: { type: 'task', project_id: '-' }, search: '', totalRows: null, initialized: false },
    }
  },
  statuses: []
};

const slice = createSlice({
  name: 'events',
  initialState: initialEventsState,
  reducers: {
    setEvents(state: EventState, action: PayloadAction<Event[]>){
      state.events.data = action.payload;
    },
    createEvent(state: EventState, action: PayloadAction<Event>){
      state.events.data.push(action.payload);
    },
    updateEvent(state: EventState, action: PayloadAction<Event>){
      state.events.data = state.events.data.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    createOrUpdateEvent(state: EventState, action: PayloadAction<Event>){
      state.events.data = [
        ...state.events.data.filter((val) => val.id !== action.payload.id),
        action.payload
      ]
    },
    deleteEvent(state: EventState, action: PayloadAction<Event>){
      state.events.data = state.events.data.filter((val) => val.id !== action.payload.id);
    },
    deleteEventById(state: EventState, action: PayloadAction<string>){
      state.events.data = state.events.data.filter((val) => val.id !== action.payload);
    },
    
    // insertEvent(state: EventState, action: PayloadAction<{ data: Event; index: number; }>){
    //   state.events.data.splice(action.payload.index, 0, action.payload.data)
    // },
    
    setEventView(state: EventState, action: PayloadAction<{ view: EventStateViewKey, data: EventStateView }>){
      state.events.views[action.payload.view] = action.payload.data;
    },
    deleteEventView(state: EventState, action: PayloadAction<EventStateViewKey>){
      delete state.events.views[action.payload];
    },

    setEventStatuses(state: EventState, action: PayloadAction<EventStatus[]>){
      state.statuses = action.payload;
    },
    createEventStatus(state: EventState, action: PayloadAction<EventStatus>){
      state.statuses.push(action.payload);
    },
    updateEventStatus(state: EventState, action: PayloadAction<EventStatus>){
      state.statuses = state.statuses.map((val) => val.id !== action.payload.id ? val : action.payload);
    },
    deleteEventStatus(state: EventState, action: PayloadAction<EventStatus>){
      state.statuses = state.statuses.filter((val) => val.id !== action.payload.id);
    }

  }
})

export const { reducer } = slice;

export const setEvents = (data: Event[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setEvents(data));
}
export const createEvent = (data: Event): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createEvent(data));
}
export const updateEvent = (data: Event): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateEvent(data));
}
export const createOrUpdateEvent = (data: Event): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createOrUpdateEvent(data));
}
export const deleteEvent = (data: Event): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteEvent(data));
}
export const deleteEventById = (data: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteEventById(data));
}

// export const insertEvent = (data: { data: Event, index: number }): AppThunk => async (dispatch) => {
//   dispatch(slice.actions.insertEvent(data));
// }

export const setEventView = (data: { view: EventStateViewKey, data: EventStateView }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setEventView(data));
}
export const deleteEventView = (data: EventStateViewKey): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteEventView(data));
}

export const setEventStatuses = (data: EventStatus[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setEventStatuses(data));
}
export const createEventStatus = (data: EventStatus): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createEventStatus(data));
}
export const updateEventStatus = (data: EventStatus): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateEventStatus(data));
}
export const deleteEventStatus = (data: EventStatus): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteEventStatus(data));
}

export default slice;