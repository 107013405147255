import axios, { AxiosRequestConfig } from 'axios';
import { AxiosOptions } from 'src/hooks/general/useAxios';
import { createContact, setContacts, updateContact } from 'src/slices/lists/contacts';
import { Contact, ContactAddressLabel, ContactEmailAddressLabel, ContactPhoneLabel, ContactSmall, ContactWebsiteLabel } from 'src/types/api/contact';
import { AppDispatch } from 'src/store';

export const getContactsRequest = (
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<ContactSmall[]> => {
  return new Promise<ContactSmall[]>((resolve, reject) => {
    axios.get<ContactSmall[]>('contacts', config)
    .then((response) => {
      dispatch(setContacts(response.data));
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    });
  });
}

export const getContactRequest = (
  contact_id: string,
  config: AxiosRequestConfig
): Promise<Contact> => {
  return new Promise<Contact>((resolve, reject) => {
    axios.get<Contact>(`contacts/${contact_id}`, config)
    .then(response => resolve(response.data))
    .catch(err => reject(err));
  });
}

// TODO 
interface CreateContactRequestBaseData {
  coc?: string | null;
  // oin?: string | null;
  vat?: string | null;
  // cbs?: string | null;
  sepa?: boolean;
  iban?: string | null;
  bic?: string | null;
  mandateid?: string | null;
  mandatedate?: number | null;
  // language?: string | null;
  function?: string;
  client_number?: string | null;
  accountname?: string;
  address?: { 
    label: ContactAddressLabel,
    [key: string]: any; // TODO 
  }[]; 
  email?: { email: string, label: ContactEmailAddressLabel }[];
  phone?: { phone: string, label: ContactPhoneLabel }[];
  website?: { website: string, label: ContactWebsiteLabel }[];
  relation?: any[];
  custom_fields?: { id: string; value: string; }[];
  tag_ids?: string[];
  avatar?: string | null;
  unconfirmed_activities?: string[];
}
interface CreateContactRequestCompanyData extends CreateContactRequestBaseData {
  company_name: string;
  first_name: null;
  last_name: null;
  gender: null;
  birthday: null;
}

interface CreateContactRequestPersonData extends CreateContactRequestBaseData {
  company_name: null;
  first_name: string;
  last_name: string;
  gender: 'male' | 'female' | 'other' | null;
  birthday: string | null;
}
export type CreateContactRequestData = CreateContactRequestCompanyData | CreateContactRequestPersonData;

export const createContactRequest = (
  data: CreateContactRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Contact[]> => {
  return new Promise<Contact[]>((resolve, reject) => {
    axios.post<Contact[]>('contacts', data, config)
    .then((response) => {
      response.data.map(contact => {
        dispatch(createContact(contact))
      });
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

interface UpdateContactRequestPersonData extends CreateContactRequestPersonData {
  avatar_changed: boolean;
}

interface UpdateContactRequestCompanyData extends CreateContactRequestCompanyData {
  avatar_changed: boolean;
}

export type UpdateContactRequestData = UpdateContactRequestPersonData | UpdateContactRequestCompanyData;

export const updateContactRequest = (
  contact_id: string,
  data: UpdateContactRequestData,
  dispatch: AppDispatch,
  config: AxiosRequestConfig
): Promise<Contact[]> => {
  return new Promise<Contact[]>((resolve, reject) => {
    axios.put<Contact[]>(`contacts/${contact_id}`, data, config)
    .then((response) => {
      response.data.map(contact => {
        dispatch(updateContact(contact))
      });
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const getFirstAvailableClientNumberRequest = (
  config: AxiosRequestConfig
): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    axios.get<number>('contacts/clientnumber/available', config)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    })
  });
}

export const checkClientNumberRequest = (
  client_number: string,
  config: AxiosRequestConfig
): Promise<{ available: boolean }> => {
  return new Promise<{ available: boolean }>((resolve, reject) => {
    axios.get<{ available: boolean }>(`contacts/clientnumber/check/${client_number}`, config)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject();
    })
  });
}