import {
  createService,
  updateService,
  deleteService,
  createServiceCategory,
  updateServiceCategory,
  deleteServiceCategory,
  moveServiceCategory,
  moveService
} from 'src/slices/lists/services';
import type { AppDispatch } from 'src/store/index';
import type { Service, ServiceCategory } from 'src/types/api/service';
import { updateAppointmentService } from 'src/slices/lists/appointments';
import { EchoChannel as Channel } from '../index';

export default (
  channel: Channel,
  dispatch: AppDispatch
) => {
  channel.listen('.create_service', (data: any) => {
    const service = data.data.response as Service;
    dispatch(createService(service));
  });
  channel.listen('.update_service', (data: any) => {
    const service = data.data.response as Service;
    dispatch(updateService(service));
  });
  channel.listen('.delete_service', (data: any) => {
    const service = data.data.response as Service;
    dispatch(deleteService(service));
    dispatch(updateAppointmentService(service));
  });
  channel.listen('.move_service', (data: any) => {
    const { source_id, destination_id } = data.data.response as { source_id: string; destination_id: string; }
    dispatch(moveService({ sourceId: source_id, destinationId: destination_id }))
  });

  channel.listen('.create_service_category', (data: any) => {
    const serviceCategory = data.data.response as ServiceCategory;
    dispatch(createServiceCategory(serviceCategory));
  });
  channel.listen('.update_service_category', (data: any) => {
    const serviceCategory = data.data.response as ServiceCategory;
    dispatch(updateServiceCategory(serviceCategory));
  });
  channel.listen('.delete_service_category', (data: any) => {
    const serviceCategory = data.data.response as ServiceCategory;
    dispatch(deleteServiceCategory(serviceCategory));
  });

  channel.listen('.move_service_category', (data: any) => {
    const { source_id, destination_id } = data.data.response as { source_id: string; destination_id: string; }
    dispatch(moveServiceCategory({ sourceId: source_id, destinationId: destination_id }));
  });

}