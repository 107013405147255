import { DeleteOutline } from "@mui/icons-material";
import { alpha, Avatar, Typography, Paper, IconButton } from "@mui/material";
import Tags from "src/components/general/Tags";
import { useSelector } from 'src/store';
import { makeStyles } from "src/theme/makeStyles";
import { ContactSmall } from "src/types/api/contact";
import getInitials from "src/utils/general/getInitials";
import React, { CSSProperties } from "react";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    // padding: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.4),
      transition: 'background-color 0.5s'
    }
  },
  innerRootBox: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5)
  },
  avatarWrapper: {
    position: 'relative',
    borderRadius: '50%',
    height: 42,
    width: 42
  },
  avatar: {
    height: 42,
    width: 42,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));


interface Props {
  contact: {
    id: ContactSmall['id'];
    name: ContactSmall['name'];
    client_number: ContactSmall['client_number'];
    tags: ContactSmall['tags'];
  };
  onDelete: () => void;
  replaceClientNumber?: string;
  disabled?: boolean;
  style?: CSSProperties;
  disablePaper?: boolean;
  disableTags?: boolean;
  secondary?: React.ReactNode | React.ReactNode[];
  disableDeleteButton?: boolean;
  avatarNoInitials?: boolean;
  onTitleClick?: () => void;
}

const SelectedContactCard = ({
  contact,
  onDelete,
  replaceClientNumber = undefined,
  disabled = false,
  style = {},
  disablePaper = false,
  disableTags = false,
  secondary = null,
  disableDeleteButton = false,
  avatarNoInitials = false,
  onTitleClick
}: Props) => {
  const { classes, theme } = useStyles();

  const avatars = useSelector(state => state.misc.avatars);

  const RootWrapper = ({ 
    paper, 
    children 
  }: { 
    paper: boolean; 
    children: React.ReactChild | React.ReactChild[]; 
  }) => {
    if(paper){
      return (
        <Paper style={style}>
          {children}
        </Paper>
      )
    }
    return (
      <div style={style}>
        {children}
      </div>
    )
  }

  return (
    <RootWrapper paper={!disablePaper}>
      <div
        className={classes.root}
      >
        <div className={classes.innerRootBox}>
          <div className={classes.avatarWrapper}>
            <Avatar
              className={classes.avatar}
              src={avatars[contact.id] && `data:image/jpeg;base64,${avatars[contact.id]}`}
            >
              {avatarNoInitials ? <PersonOutlineOutlinedIcon /> : getInitials(contact.name)}
            </Avatar>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: theme.spacing(1) }}>

            {!!onTitleClick ? (
              <Typography
                color="inherit"
                variant="h6"
                sx={{ 
                  textDecoration: 'none',
                  cursor: 'pointer', 
                  '&:hover': { 
                    textDecoration: 'underline' 
                  } 
                }}
                onClick={onTitleClick}
              >
                {contact.name}
              </Typography>  
            ) : (
              <Typography
                color="inherit"
                variant="h6"
              >
                {contact.name}
              </Typography>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {replaceClientNumber ? replaceClientNumber : contact.client_number}
            </Typography>
          </div>
        </div>

        {secondary && secondary}

        {!disableDeleteButton && (
          <div className={classes.innerRootBox} style={{ marginLeft: 'auto' }}>
            <IconButton
              onClick={onDelete}
              disabled={disabled}
              size='small'
            >
              <DeleteOutline />
            </IconButton>
          </div>
        )}
      </div>
      <>
        {!disableTags && (
          <div style={{ marginLeft: theme.spacing(1.5) }}>
            <Tags
              tagIds={contact.tags}
              category={'contact'}
              disableRemove
              disableColorPicker
              hideCreate
            />
          </div>
        )}
      </>
    </RootWrapper>
  )
}
export default SelectedContactCard;